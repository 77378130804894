import React from 'react';
import Icon from '@mjcloud/icon';
import WorkbenchPart from './part';
import ControlBase from '../../base';
import styled from 'styled-components';
import globalData from '@mjcloud/global-data';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { Skeleton, Avatar, Dropdown, Menu, Row, Col } from 'antd';
import { IWorkbenchState, ICutoverMenu } from '../typings';

import styles from './index.less';

const Container = styled.div`
  width: 100%;

  & > .ant-row {
    & > .ant-col {
      padding: 0 4px;
    }
  }
`;

const PageHeaderContent: React.FC<{
  workbenchId?: string;
  menus: ICutoverMenu[];
  onCutoverClick: MenuClickEventHandler;
}> = ({ menus, workbenchId, onCutoverClick }) => {
  const currentUser = globalData.rbCore.userInfo;

  if (currentUser) {
    let title = '';
    const now = new Date(),
      hour = now.getHours();
    if (hour < 6) {
      title = `凌晨好，${currentUser.name}，祝你开心每一天！`;
    } else if (hour < 9) {
      title = `早上好，${currentUser.name}，祝你开心每一天！`;
    } else if (hour < 12) {
      title = `上午好，${currentUser.name}，祝你开心每一天！`;
    } else if (hour < 14) {
      title = `中午好，${currentUser.name}，祝你开心每一天！`;
    } else if (hour < 17) {
      title = `下午好，${currentUser.name}，祝你开心每一天！`;
    } else if (hour < 19) {
      title = `傍晚好，${currentUser.name}，祝你开心每一天！`;
    } else if (hour < 22) {
      title = `晚上好，${currentUser.name}，祝你开心每一天！`;
    } else {
      title = `夜里好，${currentUser.name}，祝你开心每一天！`;
    }
    const menu = workbenchId ? (
      <Menu selectedKeys={[workbenchId]} onClick={onCutoverClick}>
        {menus.map(m => (
          <Menu.Item key={m.id}>{m.name}</Menu.Item>
        ))}
        <Menu.Divider />
        <Menu.Item key="_setting">
          <Icon type="setting" />
          配置
        </Menu.Item>
      </Menu>
    ) : (
      <Menu onClick={onCutoverClick}>
        <Menu.Item key="_setting">
          <Icon type="setting" />
          配置
        </Menu.Item>
      </Menu>
    );
    return (
      <div className={styles.pageHeaderContent}>
        <div className={styles.avatar}>
          {currentUser.avatar ? (
            <Avatar size="large" src={currentUser.avatar} alt="avatar" />
          ) : (
            <Avatar size="large" className={styles.avatarText} alt="avatar">
              {currentUser.name.charAt(0)}
            </Avatar>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.contentTitle}>{title}</div>
          {/* <div>交互专家 |蚂蚁金服－某某某事业群－某某平台部－某某技术部－UED</div> */}
          <div>业精于勤而荒于嬉 | 行成于思而毁于随</div>
        </div>
        <div>
          <Dropdown overlay={menu}>
            <Icon type="cutover" className={styles.cutover} onClick={e => e.stopPropagation()} />
          </Dropdown>
        </div>
      </div>
    );
  } else {
    return <Skeleton avatar paragraph={{ rows: 1 }} active />;
  }
};

export default class WorkbenchControl extends ControlBase<IWorkbenchState> {
  private handleCutoverClick: MenuClickEventHandler = param => {
    const { cutoverControllerId, settingControllerId } = this.state;
    if (param.key === '_setting') {
      this.instance.page.executeController(settingControllerId);
      return;
    }
    this.instance.page.executeController(cutoverControllerId, {
      workbenchId: param.key,
      workbenchName: param.item,
    });
  };

  private renderRightParts() {
    const { rightParts } = this.state;
    return (
      <div className={styles.dyWorkbenchParts}>
        {rightParts.map(part => (
          <WorkbenchPart
            id={part.id}
            key={part.id}
            name={part.name}
            controlId={this.props.id}
            parentId={this.props.parentId}
            pageKey={this.props.pageKey}
          />
        ))}
      </div>
    );
  }

  private renderLeftParts() {
    const { leftParts = [] } = this.state;
    return leftParts.map(parts => (
      <Row gutter={24} style={{ marginLeft: 0, marginRight: 0 }}>
        {parts.map(({ span, parts }, k) => (
          <Col span={12 * span} key={k} className={styles.dyWorkbenchParts}>
            {parts.map(part => (
              <WorkbenchPart
                id={part.id}
                key={part.id}
                name={part.name}
                controlId={this.props.id}
                parentId={this.props.parentId}
                pageKey={this.props.pageKey}
              />
            ))}
          </Col>
        ))}
      </Row>
    ));
  }

  private renderParts() {
    const { isFetching } = this.state;
    return isFetching ? (
      <div className={styles.dyWorkbenchSkeleton}>
        {Array.from({ length }, (_, i) => (
          <Skeleton key={i} active avatar />
        ))}
      </div>
    ) : (
      <Row gutter={24}>
        <Col span={16}>{this.renderLeftParts()}</Col>
        <Col span={8}>{this.renderRightParts()}</Col>
      </Row>
    );
  }

  private renderStaticMode() {
    const { sections } = this.state;
    return (
      <Container>
        {sections.map(section => {
          const { cols = 2, items } = section;
          return (
            <Row>
              {items.map(item => {
                const { colspan, ...part } = item;
                let span = (24 / cols) * colspan;
                span = span > 24 ? 24 : span;
                return (
                  <Col key={item.id} span={span}>
                    <WorkbenchPart
                      id={part.id}
                      key={part.id}
                      name={part.name}
                      controlId={this.props.id}
                      parentId={this.props.parentId}
                      pageKey={this.props.pageKey}
                    />
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </Container>
    );
  }

  renderContent() {
    const { isShowOverview, isStaticMode, workbenchId, cutoverMenus } = this.state;
    return (
      <React.Fragment>
        {isShowOverview ? (
          <PageHeaderContent
            menus={cutoverMenus}
            workbenchId={workbenchId}
            onCutoverClick={this.handleCutoverClick}
          />
        ) : null}
        <div className={styles.dyWorkbench}>
          {isStaticMode ? this.renderStaticMode() : this.renderParts()}
        </div>
      </React.Fragment>
    );
  }
}
