import Service from '@mjcloud/service';
import globalData from '@mjcloud/global-data';
import { ExceptionHelper } from '@mjcloud/exception';
import { ExtendStoreBase } from '@mjcloud/extend-store';

export default class ModuleExtendStore extends ExtendStoreBase {
  async handleStartLoadAfter(e) {
    this.instance.page.increaseLock(this.instance.id);
    try {
      const result = await Service.appInfo(globalData.appId, true);
      e.newState.dispatch('loaded', {
        ...e.params,
        dataSource: result && result.menus,
      });
    } catch (reason) {
      e.newState.dispatch('loadError', {
        ...e.params,
        errorMessage: '数据加载错误',
      });
      if (!ExceptionHelper.isException(reason)) {
        reason.message = `控件 ${this.instance.id} 数据加载错误`;
      }
      ExceptionHelper.dispose(reason);
    }
  }

  handleLoadedAfter(e) {
    this.instance.page.decreaseLock(this.instance.id);
    this.instance.eventManager.trigger('ready', e.newState.state);
  }

  handleLoadErrorAfter(e) {
    this.instance.page.decreaseLock(this.instance.id);
    this.instance.eventManager.trigger('ready', e.newState.state);
  }
}
