import { IBuildFileParamsItem, XmlParamList, IDictionary } from '@mjcloud/types';
import { ControllerBase, IControllerInitData, IRequest } from './base';
import { InstanceBase, ContainerInstanceBase } from '@mjcloud/instance';
import PageHelper from '@mjcloud/page-helper';
import JsApiHelper from '@mjcloud/jsapi';
import Service, { IFileInfo } from '@mjcloud/service';

interface IExportListItem {
  title: string;
  controlId: string;
}

interface IBuildExportListItem {
  title: string;
  control: ContainerInstanceBase;
}

interface IExportInfo {
  fileName: string;
  list: IExportListItem[];
}

interface IBuildFileParams {
  title: string;
  list: Array<IBuildFileParamsItem>;
}

export interface ExportFileControllerInitData extends IControllerInitData {
  service?: XmlParamList;
  exportInfo?: IExportInfo;
}

/**
 * 执行脚本请求对象类型
 */
export interface IExportFileRequest extends IRequest {}

/**
 * 执行脚本控制器
 */
export default class ExportFileController extends ControllerBase<ExportFileControllerInitData> {
  private list: Array<IBuildExportListItem> | null = null;
  private _isDownloadFile = true;
  constructor(sender: InstanceBase, initData: ExportFileControllerInitData) {
    super(sender, initData);
    if (initData.exportInfo) {
      const { list } = initData.exportInfo;
      this.list = list.map(({ controlId, title }) => {
        return { title, control: sender.page.findControl(controlId) } as IBuildExportListItem;
      });
    }
  }

  /**
   * 创建执行请求对象
   */
  public async createRequest(args: IDictionary): Promise<IExportFileRequest> {
    const values: IDictionary<string> = {};
    if (this.initData.service && this.initData.service.items)
      this.initData.service.items.forEach(item => {
        values[item.name] = item.value;
      });
    const params = await PageHelper.getDynamicParamValues(this.page, values, args);
    if (args._isDownloadFile != null) {
      this._isDownloadFile = args._isDownloadFile;
    }
    return { ...params };
  }

  /**
   * 执行控制器
   * @param request 执行时的请求对象
   */
  public async execute(request: IExportFileRequest) {
    const { exportInfo } = this.initData,
      pageAddress = this.page.address;
    let tmpId: string | undefined = undefined;
    let tmpFile: IFileInfo | undefined;
    if (exportInfo && this.list) {
      const title = exportInfo.fileName,
        data: IBuildFileParams = { title, list: [] };
      for (const { control, title } of this.list) {
        if (control && control.buildExportFileConfig) {
          const config = await control.buildExportFileConfig(title);
          if (config) data.list.push(config);
        }
      }
      tmpId = await Service.requestService<string>('/control/buildExcel/', {
        pageAddress,
        data,
      });
      // TODO: 打开临时文件应该统一,  文件后缀名需动态。
      if (tmpId) {
        const url = `/file/downloadTmp?tmpId=${tmpId}`;
        tmpFile = {
          url,
          size: 0,
          type: '',
          id: tmpId,
          thumbUrl: url,
          previewUrl: url,
          name: exportInfo.fileName + '.xls',
        };
      }
      // console.log('带exportInfo配置返回值：', tmpId);
    } else {
      const result = await Service.executeController<string>({
        ...this.page.getControllerExecParams(),
        params: request,
        controllerId: this.id,
        workbenchPartAddress: this.sender.workbenchPart && this.sender.workbenchPart.address,
      });
      if (result && typeof result === 'string') tmpId = result;
      if (tmpId) {
        tmpFile = await Service.getFileDetail(tmpId);
      }
      // console.log('无exportInfo配置返回值：', result);
    }
    if (tmpFile) {
      if (this._isDownloadFile) {
        JsApiHelper.downloadFile({ url: Service.getUrl(tmpFile.url), name: tmpFile.name });
      } else {
        return tmpFile as any;
      }
    }

    return undefined;
  }
}
