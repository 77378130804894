import React from 'react';
import ControlBase from '../../base';
import globalData from '@mjcloud/global-data';
import { IToolbarState, IToolbarStateArea } from '../typings';

import styles from './index.less';

class ToolBarControl extends ControlBase<IToolbarState> {
  private renderToolbarArea(
    prefix: 'left' | 'center' | 'right',
    area: IToolbarStateArea,
    className: string,
  ) {
    const { ControlFactory } = globalData;
    const { padding, ChildrenControls } = area;
    const style: React.CSSProperties = {};
    if (prefix === 'left') style.marginLeft = padding;
    if (prefix === 'right') style.marginRight = padding;
    return (
      <div key={prefix} style={style} className={className}>
        {ChildrenControls.map((controlProps, i) => {
          return <ControlFactory key={i} {...controlProps} />;
        })}
      </div>
    );
  }

  renderContent() {
    const { theme, areas } = this.state;
    const { left, right } = areas;
    const blank = right ? !left : false;
    return (
      <div id={this.props.id} className={`${styles.toolbar} ${styles[theme]}`}>
        {left && this.renderToolbarArea('left', left, styles.left)}
        {blank && <div className={styles.blank} />}
        {right && this.renderToolbarArea('right', right, styles.right)}
      </div>
    );
  }
}

export default ToolBarControl;
