import { ControllerBase, IControllerInitData, IRequest } from './base';
import { IDictionary, XmlParamList } from '@mjcloud/types';
import PageHelper from '@mjcloud/page-helper';
import Service from '@mjcloud/service';
import { ExceptionHelper } from '@mjcloud/exception';

export interface ExecuteServiceControllerInitData extends IControllerInitData {
  service: XmlParamList;
}

/**
 * 执行服务请求对象类型
 */
export interface IExecuteServiceRequest extends IRequest {}

/**
 * 执行服务控制器
 */
export default class ExecuteServiceController extends ControllerBase<
  ExecuteServiceControllerInitData
> {
  /**
   * 创建执行请求对象
   */
  public async createRequest(args: IDictionary): Promise<IExecuteServiceRequest> {
    const values: IDictionary<string> = {};
    if (this.initData.service && this.initData.service.items)
      this.initData.service.items.forEach(item => {
        values[item.name] = item.value;
      });
    const params = await PageHelper.getDynamicParamValues(this.page, values, args);
    if (args._reviseId != null) {
      params._reviseId = args._reviseId;
    }
    return { ...params };
  }

  /**
   * 执行控制器
   * @param request 执行时的请求对象
   */
  public async execute<T>(request: IExecuteServiceRequest) {
    try {
      const result = await Service.executeController<T>({
        ...this.page.getControllerExecParams(),
        params: request,
        controllerId: this.id,
        workbenchPartAddress: this.sender.workbenchPart && this.sender.workbenchPart.address,
      });
      return result;
    } catch (exception) {
      if (exception.name === 'CheckException') {
        const { message, controlId, itemId, rowId } = exception;
        const control = this.page.findControl<any>(controlId);
        if (control && control.triggervValid) {
          control.triggervValid(message, itemId, rowId);
        }
        throw ExceptionHelper.passException();
      }
      throw exception;
    }
  }
}
