import Tabs from '.';
import { ContainerExtendStoreBase } from '@mjcloud/extend-store';
import ControllerHelper from '@mjcloud/controller';
import { ITabsItem } from './typings';

export default class TabsExtendStore extends ContainerExtendStoreBase<Tabs> {
  handleInitialStateBefore(e) {
    const { initConfig } = e.params,
      address = this.instance.page.address,
      items: ITabsItem[] = [],
      texts: string[] = [];
    let activeIndex = 0;
    let isShowBottomBlank = true;
    if (initConfig) {
      if (initConfig.activeIndex) {
        activeIndex = initConfig.activeIndex;
      }
      if (initConfig.isShowBottomBlank === false) {
        isShowBottomBlank = false;
      } else {
        isShowBottomBlank = true;
      }
    }
    if (initConfig && initConfig.items) {
      initConfig.items.forEach(item => {
        let display = true;
        if (item.code) {
          display = ControllerHelper.hasRights(address, item.code);
        }
        if (display) {
          texts.push(item.title);
          const instance = this.instance.createControl(item.id);
          if (instance) {
            items.push({
              display,
              controlId: instance.id,
              parentId: instance.parent.id,
              pageKey: instance.page.id,
            });
          }
        }
      });
    }
    e.params.items = items;
    e.params.texts = texts;
    e.params.activeIndex = activeIndex;
    e.params.isShowBottomBlank = isShowBottomBlank;
  }
}
