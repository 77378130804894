import React from 'react';
import Store from '@mjcloud/redux';
import Revise from './Revise';
import CellTitle from './cellTitle';
import JsApiHelper from '@mjcloud/jsapi';
import { NumberHelper } from '@mjcloud/utils';
import globalData from '@mjcloud/global-data';
import { DataReduceBase } from '@mjcloud/reduce';
import OperationTitle from './operationTitle';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { Omit, PageModeEnum, IDictionary } from '@mjcloud/types';
import { IColumnProps } from '../../../components/VirtualizedTable/interface';
import { OPERATIONWIDTH, AUTOCELLWIDTH, SERIALNUMBERWIDTH } from './constant';
import {
  IColumnFn,
  ITableBaseState,
  ITableBaseConfigItem,
  ITableBaseConfigParent,
  ITableBaseConfigItemSet,
  IBatchRemoveColumnParams,
  ITableBaseUpdateOperationWidthParams,
  IDownMergeColumn,
  ITableBatchSetCellTitleItem,
  ITabsSetColumn2DisplayItem,
  IBatchAddColumnParams,
  ITableBaseReviseConfig,
} from './typings';

interface IGetColumnParams<TDataSource, Tecord> extends Omit<IColumnFn<Tecord>, 'onOperation'> {
  store: Store<ITableBaseState<TDataSource, Tecord>>;
  pageMode: PageModeEnum;
  isModify: boolean;
  allowSort?: boolean;
  render?: (
    cell: React.ReactPortal,
    item: ITableBaseConfigItem,
    text: any,
    record: Tecord,
    index: number,
  ) => React.ReactPortal;
}

interface IGetColumnsParams<TDataSource, Tecord>
  extends IGetColumnParams<TDataSource, Tecord>,
    IColumnFn<Tecord> {
  isInit?: boolean;
  collapse: boolean;
  showOrdinal?: boolean;
  showOperation: boolean;
  fastnessCount?: number;
  revise?: ITableBaseReviseConfig;
  items: ITableBaseConfigItemSet[];
  // TODO: 针对操作列样式异常临时处理
  isCollapse?: boolean;
}

export function updateColumns<TItem>(columns: IColumnProps<TItem>[]) {
  const _columns = columns
    .map(column => {
      if (column.display) {
        if (column.children) {
          column.children = updateColumns(column.children);
        }
        return column;
      }
      return undefined;
    })
    .filter(column => !!column) as IColumnProps<TItem>[];
  return _columns;
}

function updateEntireColumns<TItem>(
  columns: IColumnProps<TItem>[],
  cells: ITabsSetColumn2DisplayItem[],
) {
  let isUpdate: boolean = false,
    _cells: ITabsSetColumn2DisplayItem[] = [];
  const _columns = columns.map(column => {
    for (const cell of cells) {
      const { cellId, display } = cell;
      if (column.key === cellId && column.display != display) {
        column.display = display;
        isUpdate = true;
      } else {
        _cells.push(cell);
      }
    }
    if (column.children) {
      const { columns: __columns, isUpdate: _isUpdate } = updateEntireColumns(
        column.children,
        _cells,
      );
      column.children = __columns;
      if (_isUpdate) {
        isUpdate = true;
      }
    }
    return column;
  });
  return { columns: _columns, isUpdate };
}

export default abstract class TableReduceBase<
  TDataSource,
  TItem,
  S extends ITableBaseState<TDataSource, TItem> = ITableBaseState<TDataSource, TItem>
> extends DataReduceBase<S> {
  private isSelfAdapt = true;
  private getColumnParent(
    parent: ITableBaseConfigParent,
    isParent: boolean,
    params: IGetColumnParams<TDataSource, TItem>,
  ) {
    const { store, refs } = params,
      { id, title, tip, tipIcon, items } = parent;
    let totalWidth: number = 0;
    const children: IColumnProps<TItem>[] = [];
    if (items) {
      for (const _key in items) {
        const { column, width } = this.getColumn(items[_key], isParent, params);
        if (column) {
          children.push(column);
          if (width != null) {
            totalWidth += width;
          } else {
            this.isSelfAdapt = false;
            totalWidth += AUTOCELLWIDTH;
            const render = () => ({ props: { colSpan: 0 } });
            children.push({
              // key: `${column.key}_sub`,
              width: AUTOCELLWIDTH,
              colSpan: 0,
              display: true,
              fixed: column.fixed,
              render,
            });
          }
        }
      }
    }
    const column: IColumnProps<TItem> = {
      title: (
        <CellTitle
          ref={refs ? instance => refs(id, instance) : undefined}
          tip={tip}
          title={title}
          tipIcon={tipIcon}
          isModify
        />
      ),
      key: id,
      dataIndex: id,
      children,
      display: true,
    };
    return { column, width: totalWidth };
  }

  private getColumnItem(item: ITableBaseConfigItem, params: IGetColumnParams<TDataSource, TItem>) {
    const { render, refs, allowSort: _allowSort = true, pageMode, isModify, onCell } = params,
      {
        id,
        field,
        width,
        title,
        allowSort,
        tip,
        tipIcon,
        control,
        colorCommand,
        modifyMode,
      } = item,
      controlConfig: any = item.control ? item.control : {},
      controlType = controlConfig.nodeName;
    let isRight = controlType === 'number';
    if (controlType === 'hyperlink' || controlType === 'label') {
      if (controlConfig.format2Number) isRight = true;
    }
    const column: IColumnProps<TItem> = {
      title: (
        <CellTitle
          ref={refs ? instance => refs(id, instance) : undefined}
          tip={tip}
          title={title}
          tipIcon={tipIcon}
          requiredType={item.requiredType}
          isModify={isModify ? isModify : PageModeHelper.modifyMode2boolean(pageMode, modifyMode)}
        />
      ),
      width,
      control,
      key: id,
      colorCommand,
      display: true,
      sorter: !!allowSort,
      dataIndex: field || id,
      ellipsis: { showTitle: false },
      align: isRight ? 'right' : 'left',
      // TODO: 这里通过看源码发现有该方法时，text一直已此为准，
      // 需要注意的是版本升级后源码的变动情况：https://github.com/react-component/table/blob/04de08decdebc20041f65879862a31e775ae6d2a/src/TableCell.tsx#L65
      render: (text, record, index) => {
        let obj: React.ReactPortal = { children: '', props: {} } as any;
        if (render) obj = render(obj, item, text, record, index);
        return obj;
      },
      summaryRender: (text, record, index) => {
        const { format: numberFormat = '#,##0.00', format2Number = '#,##0.00' } = controlConfig;
        if (controlType === 'number') {
          return NumberHelper.format(text, numberFormat);
        } else if (controlType === 'label' || controlType === 'hyperlink') {
          return NumberHelper.format(text, format2Number);
        }
        return text;
      },
    };
    if (!_allowSort) column.sorter = false;
    column.onCell = record => onCell(record, column);
    if (width == null) {
      column.render = (text, record, index) => {
        let obj: React.ReactPortal = { children: '', props: { colSpan: 2 } } as any;
        if (render) obj = render(obj, item, text, record, index);
        return obj;
      };
      column.colSpan = 2;
    }
    return column;
  }

  private getColumn(
    row: ITableBaseConfigItemSet,
    isParent: boolean,
    params: IGetColumnParams<TDataSource, TItem>,
  ) {
    let item: ITableBaseConfigItem | undefined, parent: ITableBaseConfigParent | undefined;
    if (row.nodeName === 'item') {
      item = row;
    } else if (row.nodeName === 'parent') {
      parent = row;
    } else {
      console.error('gridEdit 下的 items只能具备 item和parent 节点');
    }
    let column: IColumnProps<TItem> | undefined;
    let width: number | undefined;
    if (item) {
      width = item.width;
      column = this.getColumnItem(item, params);
    } else if (parent) {
      isParent = true;
      const _parent = this.getColumnParent(parent, isParent, params);
      column = _parent.column;
      width = _parent.width;
    }
    return { column, width };
  }

  /**
   * 获取表格列的配置描述
   */
  protected getColumns(params: IGetColumnsParams<TDataSource, TItem>, isTree: boolean = false) {
    let {
        store,
        items,
        revise,
        collapse,
        showOrdinal,
        onOperation,
        showOperation,
        isInit = true,
        fastnessCount = 0,
        isCollapse = true,
        ...columnParams
      } = params,
      isParent = false,
      totalWidth: number = 0,
      columns: IColumnProps<TItem>[] = [],
      reviseEnable = revise && !!revise.enable;
    if (revise && !!revise.enable) {
      columns.push({
        title: '',
        key: '_revise',
        dataIndex: '_revise',
        width: SERIALNUMBERWIDTH,
        fixed: 'left',
        align: 'center',
        display: true,
        onCell: (record: TItem, rowIndex?: number) => ({ record, cellId: '_revise' } as any),
        render: (text, record: any, index) => {
          if (revise) {
            const { approveField, statusField } = revise,
              {
                _revise: isAuthority = true,
                [approveField]: isApprove,
                [statusField]: reviseStatus,
              } = record,
              isLock = reviseStatus == 0,
              handClick = () => {
                store.dispatch('reviseClick', {
                  record,
                  isLock,
                  isApprove: isApprove != null,
                  isAuthority,
                });
              };
            return <Revise record={record} isLock={isLock} onClick={handClick} />;
          }
          return null;
        },
      });
      totalWidth += SERIALNUMBERWIDTH;
    }
    if (showOrdinal) {
      columns.push({
        title: '序号',
        key: '_ordinalId',
        dataIndex: '_ordinalId',
        width: SERIALNUMBERWIDTH,
        fixed: 'left',
        align: 'center',
        display: true,
        onCell: (record: TItem, rowIndex?: number) => ({ record, cellId: '_ordinalId' } as any),
        render: (text, record, index) => index + 1,
      });
      totalWidth += SERIALNUMBERWIDTH;
    }
    this.isSelfAdapt = true;
    for (const item of items) {
      const { column, width } = this.getColumn(item, isParent, { ...columnParams, store });
      if (column) {
        if (isInit && fastnessCount > 0) {
          fastnessCount--;
          column.fixed = 'left';
        }
        columns.push(column);
        if (width != null) {
          totalWidth += width;
        } else {
          this.isSelfAdapt = false;
          totalWidth += AUTOCELLWIDTH;
          const render = () => ({ props: { colSpan: 0 } });
          columns.push({
            // key: `${column.key}_sub`,
            width: AUTOCELLWIDTH,
            colSpan: 0,
            display: true,
            fixed: column.fixed,
            render,
          });
        }
      }
    }
    if (globalData.debugger && this.isSelfAdapt) {
      JsApiHelper.showToast({
        type: 'fail',
        content: `该页面中Id为${store['_id']}的控件，配置中的列(items)节点中缺少自适应列`,
      });
    }

    // 操作区
    const handleOperationMenuClick = () => store.dispatch('updateOperationCollapse', {});
    const handleOperationSettingClick = () => store.dispatch('openOperationSetting', {});
    const column: IColumnProps<TItem> = {
      width: OPERATIONWIDTH,
      fixed: 'right',
      align: 'center',
      display: showOperation && params.isModify,
      key: '_operation',
      dataIndex: '_operation',
      title: () => {
        const { collapse, mayCustom, operationWidth } = store.state;
        return (
          <OperationTitle
            isCollapse={isCollapse}
            collapse={collapse}
            mayCustom={mayCustom}
            menuClick={isCollapse ? handleOperationMenuClick : undefined}
            settingClick={handleOperationSettingClick}
            showTitle={isCollapse ? operationWidth > 100 : true}
          />
        );
      },
    };
    column.onCell = record => onOperation(record, store.state.collapse, column);
    columns.push(column);

    if (isTree) {
      let treeColumn: IColumnProps<TItem>;
      if (showOrdinal && reviseEnable) {
        treeColumn = columns[2];
      } else if (showOrdinal || reviseEnable) {
        treeColumn = columns[1];
      } else {
        treeColumn = columns[0];
      }
      if (treeColumn.children && treeColumn.children.length > 0) {
        this.setTreeColumn(treeColumn.children);
      } else {
        treeColumn.isTree = true;
      }
    }
    return { isParent, columns, totalWidth };
  }

  private setTreeColumn(columns: IColumnProps<TItem>[]) {
    if (columns[0] && columns[0].children && columns[0].children.length > 0) {
      this.setTreeColumn(columns[0].children);
    } else {
      columns[0].isTree = true;
    }
  }

  private getDownMergeColumnParent(parent: ITableBaseConfigParent) {
    const { items } = parent;
    if (items) {
      return this.getDownMergeColumns(items);
    }
    return undefined;
  }

  private getDownMergeColumnItem(item: ITableBaseConfigItem): IDownMergeColumn | undefined {
    const { downMerge, downMergeControl } = item;
    if (downMerge && downMergeControl) {
      return { downMerge, downMergeControl };
    }
    return undefined;
  }

  private getDownMergeColumn(row: ITableBaseConfigItemSet) {
    let item: ITableBaseConfigItem | undefined, parent: ITableBaseConfigParent | undefined;
    if (row.nodeName === 'item') {
      item = row;
    } else if (row.nodeName === 'parent') {
      parent = row;
    } else {
      console.error('gridEdit 下的 items只能具备 item和parent 节点');
    }
    let downMergeColumns: IDictionary<IDownMergeColumn> | undefined;
    if (item) {
      const column = this.getDownMergeColumnItem(item);
      if (column) downMergeColumns = { [item.id]: column };
    } else if (parent) {
      downMergeColumns = this.getDownMergeColumnParent(parent);
    }
    return downMergeColumns;
  }

  protected getDownMergeColumns(items: ITableBaseConfigItemSet[]) {
    let downMergeColumns: IDictionary<IDownMergeColumn> = {};
    for (const item of items) {
      const columns = this.getDownMergeColumn(item);
      if (columns) downMergeColumns = { ...downMergeColumns, ...columns };
    }
    return downMergeColumns;
  }

  startLoad(store: Store<ITableBaseState<TDataSource, TItem>>, params: any) {
    const state = super.startLoad(store, params) as ITableBaseState<TDataSource, TItem>,
      { orderBy = [], aggs = [] } = params;
    if (aggs.length === 0) params.aggs = state.aggs;
    if (orderBy.length === 0) params.orderBy = state.orderby;
    state.orderby = params.orderBy || [];
    state.filterCondition = params;
    return state;
  }

  updateSummaryDataSource(store: Store<ITableBaseState<TDataSource, TItem>>, params) {
    return { ...store.state, summaryDataSource: params.summaryDataSource };
  }

  updateOperationWidth(
    store: Store<ITableBaseState<TDataSource, TItem>>,
    params: ITableBaseUpdateOperationWidthParams,
  ) {
    const { operations = {} } = params,
      { showOperation, collapse, columns: nextColumns } = store.state;
    if (showOperation) {
      let width = OPERATIONWIDTH;
      if (collapse) {
        // TODO: 需要算法优化，以防止行过多导致性能问题
        for (const key in operations) {
          let _width = 24;
          const { ChildrenControls } = operations[key].state;
          for (let i = 0; i < ChildrenControls.length; i++) {
            const { display, title } = ChildrenControls[i];
            if (display) {
              _width += title.length * 14 + 30;
              // if (i !== 0) _width += 17;
            }
          }
          width = width > _width ? width : _width;
        }
        width = width > OPERATIONWIDTH ? width : OPERATIONWIDTH;
      }
      if (nextColumns[nextColumns.length - 1].width === width) {
        return store.state;
      }
      nextColumns[nextColumns.length - 1].width = width;
      return { ...store.state, columns: nextColumns, operationWidth: width };
    }
    return store.state;
  }

  updateScrollHeight(
    store: Store<ITableBaseState<TDataSource, TItem>>,
    params: { scrollHeight?: number },
  ) {
    const { scrollHeight } = params;
    if (scrollHeight === store.state.scrollHeight) {
      return store.state;
    }
    return { ...store.state, scrollHeight };
  }

  updateOperationCollapse(store: Store<ITableBaseState<TDataSource, TItem>>, params: {}) {
    return {
      ...store.state,
      collapse: !store.state.collapse,
    };
  }

  updateColumns(
    store: Store<ITableBaseState<TDataSource, TItem>>,
    params: { cells: ITabsSetColumn2DisplayItem[] },
  ): any {
    const state = store.state,
      { cells } = params;
    const { columns, isUpdate } = updateEntireColumns(state.entireColumns, cells);
    state.entireColumns = columns;
    state.columns = updateColumns(state.entireColumns);
    return isUpdate ? { ...state } : state;
  }

  batchAddColumn(
    store: Store<ITableBaseState<TDataSource, TItem>>,
    params: IBatchAddColumnParams<TItem>,
  ) {
    const state = store.state;
    if (params.isClear) {
      const { isGridEdit, modify, config } = state,
        { pageMode, refs, onCell, onOperation } = params,
        { buttons, showOrdinal, fastnessCount, collapseMenu: collapse = true } = config,
        showOperation = buttons ? buttons.items.length > 0 : false,
        { columns } = this.getColumns({
          refs,
          store,
          isInit: false,
          items: params.columns.map(item => item.config),
          pageMode,
          collapse,
          isModify: modify,
          showOrdinal,
          showOperation,
          fastnessCount,
          isCollapse: !isGridEdit,
          onCell,
          onOperation,
        });
      state.oldAggs = state.aggs;
      state.aggs = params.aggs;
      state.columns = columns;
      return { ...state };
    }
    if (globalData.debugger) {
      JsApiHelper.showToast({
        type: 'fail',
        content: `batchAddColumn 暂不支持不清空原有的列下的添加功能`,
      });
    }
    return state;
  }

  batchRemoveColumn(
    store: Store<ITableBaseState<TDataSource, TItem>>,
    params: IBatchRemoveColumnParams,
  ) {
    const { columns } = params;
  }

  batchSetCellTitle(
    store: Store<ITableBaseState<TDataSource, TItem>>,
    params: { cells: ITableBatchSetCellTitleItem[] },
  ) {
    const state = store.state;
    return { ...state };
  }

  reviseClick(store: Store<ITableBaseState<TDataSource, TItem>>, params) {
    if (params.notAuthority) {
      return store.state;
    }
    return { ...store.state, isFetching: true };
  }

  reviseError(store: Store<ITableBaseState<TDataSource, TItem>>, params) {
    return { ...store.state, isFetching: false };
  }
}
