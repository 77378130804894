import Page from '@mjcloud/page';
import { EventManager } from '@mjcloud/utils';
// export { WorkbenchPart } from '@mjcloud/workbench';
import { Size, ControlConfig } from '@mjcloud/types';
import ContainerInstanceBase from './containerInstanceBase';
import Store, { IState, ReduceDefaultActionType, ReduceConfigActionType } from '@mjcloud/redux';

export type ControlEventType = '';
export type ControlActionType =
  | ReduceDefaultActionType
  | ReduceConfigActionType
  | 'updateSize'
  | 'updateDisplay';

export interface IControlComponentProps {
  __ref?: (instance: any) => any;
}

export default class InstanceBase<
  S extends IState = IState,
  TEventType extends string = string,
  TDispatchType extends string = string
> {
  private _id: string;
  private _eventManager: EventManager<TEventType>;
  private _parent?: ContainerInstanceBase;
  private _workbenchPart?: any;

  private _store?: Store<S, TDispatchType>;

  initialState?(initConfig: any): void;

  constructor(id: string, parent: ContainerInstanceBase, workbenchPart?: any) {
    this._id = id;
    this._parent = parent;
    this._workbenchPart = workbenchPart;
    this._eventManager = new EventManager(this);
    const store = this.__createStore();
    if (store) {
      this.setStore(store);
      store.setParent(parent.store);
    }
  }

  public __createStore(): Store<S> {
    throw new Error('__createStore not implemented.');
  }

  public initialStore(initConfig: ControlConfig) {
    this.parent.__controlInitStart(this.id);
    if (this._workbenchPart) this._workbenchPart.__controlInitStart(this.id);
    if (this.initialState && typeof this.initialState === 'function') {
      // 通过延时执行initialState 让通过 bindExtendStore 方法添加的ExtendStore 可以监听到 handleInitialStateAfter
      setTimeout(() => this.initialState && this.initialState(initConfig), 66);
    } else {
      this.controlInitComplete();
    }
  }

  /**
   * 控件唯一Id
   */
  public get id() {
    return this._id;
  }

  /**
   * 当前控件的事件管理器
   */
  public get eventManager() {
    return this._eventManager;
  }

  /**
   * 当前控件的父级控件
   *
   * 若控件为页面类型,则是当前页面的父级页面
   */
  public get parent(): ContainerInstanceBase {
    return this._parent as ContainerInstanceBase;
  }

  /**
   * 当前控件的工作台部件实例
   *
   * 若该控件通过工作台部件实例创建则有值
   */
  public get workbenchPart() {
    return this._workbenchPart;
  }

  /**
   * 当前控件的页面实例
   *
   * 若控件为页面类型则指向自己本身
   */
  public get page(): Page {
    return this.parent.page;
  }

  /**
   * 设置控件大小
   */
  public set size(size: Size) {
    const store = this.store as Store<S, ControlActionType>;
    store.dispatch('updateSize', { size });
  }

  /**
   * 当前控件是否隐藏
   */
  public get display() {
    return !!this.store.state.display;
  }

  public set display(display: boolean) {
    const store = this.store as Store<S, ControlActionType>;
    store.dispatch('updateDisplay', { display });
  }

  public get store(): Store<S, TDispatchType> {
    return this._store as Store<S, TDispatchType>;
  }

  public setStore(newStore: Store<S, TDispatchType>) {
    this._store = newStore;
  }

  public get isPage(): boolean {
    return this.id === this.page.id;
  }

  public controlInitComplete() {
    if (this.isPage) return;
    this.parent.__controlInitComplete(this.id);
    if (this._workbenchPart) {
      this._workbenchPart.__controlInitComplete(this.id);
    }
  }

  /**
   * 重置当前控件配置，不改变数据模型
   */
  public resetConfig() {
    throw new Error('Method not implemented.');
  }

  public destroy() {}
}
