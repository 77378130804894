import { ITextAreaConfig } from './typings';

const config: ITextAreaConfig = {
  title: '',
  maxLength: 400,
  autoFocus: false,
  minLength: undefined,
  allowChars: undefined,
  placeholder: undefined,
  modifyMode: undefined,
  displayMode: undefined,
  disabledMode: undefined,
};

export default config;
