import { Modal } from 'antd';
import { ENV } from '@mjcloud/jsapi';
import RbCore from '@mjcloud/core-helper';
import run from './globaldata_mobile';
import globalData from '@mjcloud/global-data';
import { formatMessage } from 'umi-plugin-locale';
import { IRequestNavigationBarParams } from '@mjcloud/jsapi';
import { controlDefaults, controlTypes, ControlFactory } from '../controls';
import { alert, message, inputPlain, importFile, previewFile, downloadFile } from './utils';

globalData.debugger = false;
globalData.appName = '加载中……';
globalData.formatMessage = formatMessage;
window['__dingyun_global_data__'] = globalData;

let breadcrumbList: any[] = [];
globalData.__setDraftTip = function() {};
globalData.__setBreadcrumbList = function(list) {
  breadcrumbList = list;
};
globalData.__getBreadcrumbList = () => breadcrumbList;
globalData.__pushBreadcrumbList = function(...items: any[]) {
  breadcrumbList.push(...items);
};
globalData.__updateLastBreadcrumb = function(item: any) {
  (item: any) => {
    const index = breadcrumbList.length - 1;
    breadcrumbList[index] = { ...breadcrumbList[index], ...item };
  };
};

if (ENV.isMobile) {
  run();
} else {
  globalData.rbCore = new RbCore();
  globalData.__controlDefaults = controlDefaults;
  globalData.controlTypes = controlTypes;
  globalData.ControlFactory = ControlFactory;
}

globalData.__setNavigationBar = ({ title }: IRequestNavigationBarParams) => {
  if (title) {
    document.title = title;
    globalData._title = title;
  }
};
globalData.__alert = alert;
globalData.__confirm = Modal.confirm;
globalData.__message = message;
globalData.__inputPlain = inputPlain;
globalData.__downloadFile = downloadFile;
globalData.__previewFile = previewFile;
globalData.__importFile = importFile;
