import { ContainerDataInstanceBase } from '@mjcloud/instance';
import { IDataState } from '@mjcloud/instance/dist/dataInstanceBase';

export default abstract class FormInstanceBase<
  S extends IDataState = IDataState,
  TEventType extends string = string,
  TDispatchType extends string = string
> extends ContainerDataInstanceBase<S, TEventType, TDispatchType> {
  // hideItem(id: string) {
  //   throw new Error('Method not implemented.');
  // }
  // showItem(id: string) {
  //   throw new Error('Method not implemented.');
  // }

  // setItem(id: string, config: IFormItem) {
  //   throw new Error('Method not implemented.');
  // }
  // resetFields() {
  //   throw new Error('Method not implemented.');
  // }
  // setFieldsValue(values: IDictionary) {
  //   throw new Error('Method not implemented.');
  // }

  /**
   * @description: 校验并获取Error
   * @return: true
   */
  abstract valid(): Promise<boolean>;
  async getData(isValid: boolean): Promise<any> {
    throw new Error('Method not implemented.');
  }
}
