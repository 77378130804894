import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { ArrayHelper, ConsoleHelper } from '@mjcloud/utils';
import { IControlBaseProps, PageStatusEnum } from '@mjcloud/types';
import {
  IContentControlProps,
  IWorkbenchPartState,
  IWorkbenchPartInitialStateParams,
} from './typings';

let partControlId = 0;
function pageStatusEnum2string(status: PageStatusEnum): string | undefined {
  switch (status) {
    case PageStatusEnum.configLoading:
      return 'configLoading';
    case PageStatusEnum.configLoaded:
      return 'configLoaded';
    case PageStatusEnum.initing:
      return 'initing';
    case PageStatusEnum.inited:
      return 'inited';
    case PageStatusEnum.dataLoading:
      return 'dataLoading';
    case PageStatusEnum.dataLoaded:
      return 'dataLoaded';
    case PageStatusEnum.done:
      return 'done';
    default:
      break;
  }
}
class WorkbenchPartReduce extends ReduceBase<IWorkbenchPartState> {
  initialState(store: Store<IWorkbenchPartState>, params: IWorkbenchPartInitialStateParams) {
    const { initConfig, pageMode, controlProps, createControl } = params,
      { displayMode, set, areas } = initConfig,
      { title, showTitle } = set,
      { titleRight, newRow, content } = areas,
      contentCols = content.cols || 1,
      titleRightList: IControlBaseProps[] = [],
      newRowList: IControlBaseProps[] = [],
      contentList: IContentControlProps[] = [];

    if (newRow && newRow.items.length > 0) {
      newRow.items.forEach(item => {
        if (item.control.nodeName === 'radioButtons') item.control['theme'] = 'default';
        const instance = createControl(item);
        if (instance) {
          newRowList.push({ ...controlProps, controlId: instance.id });
        }
      });
    }

    if (titleRight && titleRight.items.length > 0) {
      titleRight.items.forEach(item => {
        if (item.nodeName === 'item') {
          if (item.control.nodeName === 'radioButtons') item.control['theme'] = 'text';
          const instance = createControl(item);
          if (instance) {
            titleRightList.push({ ...controlProps, controlId: instance.id });
          }
        } else {
          // 兼容老版本的配置模式
          if (item.nodeName === 'radioButtons') item['theme'] = 'text';
          const instance = createControl({ id: item.id, control: item });
          if (instance) {
            titleRightList.push({ ...controlProps, controlId: instance.id });
          }
        }
      });
    }

    content.items.forEach(item => {
      item.control['size'] = 750 / contentCols;
      const instance = createControl(item);
      const { colspan = 1 } = item;
      if (instance) {
        contentList.push({ ...controlProps, colspan, controlId: instance.id });
      }
    });

    const state: IWorkbenchPartState = {
      title,
      showTitle,
      contentCols,
      tabIndex: -1,
      config: initConfig,
      controlInitCount: 0,
      controlLoadCount: 0,
      configIsFetching: false,
      status: PageStatusEnum.initing,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      titleRight: titleRightList,
      newRow: newRowList,
      content:
        contentCols > 1 ? ArrayHelper.oneArray2twoArray(contentList, contentCols) : [contentList],
    };
    return state;
  }

  increaseControlInitCount(store: Store<IWorkbenchPartState>, params) {
    let state = store.state;
    if (state.status <= PageStatusEnum.initing) {
      state.controlInitCount++;
    }
    return state;
  }
  decreaseControlInitCount(store: Store<IWorkbenchPartState>, params) {
    let state = store.state;
    if (state.status <= PageStatusEnum.initing) {
      state.controlInitCount--;
    }
    return state;
  }
  increaseControlLoadCount(store: Store<IWorkbenchPartState>, params) {
    let state = store.state;
    if (state.status === PageStatusEnum.dataLoading) {
      state.controlLoadCount++;
    }
    return state;
  }
  decreaseControlLoadCount(store: Store<IWorkbenchPartState>, params) {
    let state = store.state;
    if (state.status === PageStatusEnum.dataLoading) {
      state.controlLoadCount--;
    }
    return state;
  }
  statusUpdated(store: Store<IWorkbenchPartState>, params) {
    let state: IWorkbenchPartState;
    ConsoleHelper.log2reduce(
      store['_id'],
      'statusUpdated',
      pageStatusEnum2string(params.newStatus),
    );
    switch (params.newStatus) {
      case PageStatusEnum.initing:
      case PageStatusEnum.inited:
        // case PageStatusEnum.dataLoaded:
        // case PageStatusEnum.done:
        state = { ...store.state };
        break;
      default:
        state = store.state;
        break;
    }
    state.status = params.newStatus;
    return state;
  }
}

export default new WorkbenchPartReduce();
