import Store from '@mjcloud/redux';
import Reduce from './reduce';
import TabsExtendStore from './extendStore';
import { ContainerInstanceBase } from '@mjcloud/instance';
import { getInstance } from '../../core/util';
import { ITabsState } from './typings';

export default class Tabs extends ContainerInstanceBase<ITabsState> {
  __createStore() {
    return new Store<ITabsState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new TabsExtendStore(this),
    });
  }

  createControl(id) {
    // 创建复杂控件
    return this.page.createControl(id, { parentType: 'tabs' });
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  getAllTabPaneInstance() {
    const { items } = this.store.state;
    return items.map(item => {
      const { controlId, parentId, pageKey } = item;
      const instance = getInstance({ controlId, parentId, pageKey });
      return instance;
    });
  }

  /**
   * 设置激活Tab
   * @param {number} activeIndex 激活tab索引
   */
  setActiveIndex(activeIndex: number) {
    this.store.dispatch('updateActiveIndex', { activeIndex });
  }

  /**
   * 根据标题设置激活Tab
   * @param {string} title 激活tab标题
   */
  setActiveIndexByTitle(title: string) {
    let activeIndex = 0;
    this.store.state.texts.map((text, index) => {
      if (text === title) {
        activeIndex = index;
      }
    });
    this.store.dispatch('updateActiveIndex', { activeIndex });
  }

  /**
   * 根据复杂控件ID设置激活Tab
   * @param {string} title 激活tab标题
   */
  setActiveIndexById(id: string) {
    let activeIndex = 0;
    this.store.state.items.map(({ controlId }, index) => {
      if (controlId === id) {
        activeIndex = index;
      }
    });
    this.store.dispatch('updateActiveIndex', { activeIndex });
  }

  updateTabPaneHeight(tabPaneHeight: number | string) {
    this.store.dispatch('updateTabPaneHeight', { tabPaneHeight });
  }
}
