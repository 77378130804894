import Store from '@mjcloud/redux';
import Reduce from './reduce';
import { DataModeEnum } from '@mjcloud/types';
import ListEditExtendStore from './extendStore';
import { InstanceBase, ContainerDataInstanceBase } from '@mjcloud/instance';
import { IListEditState } from './typings';

export default class ListEdit extends ContainerDataInstanceBase<IListEditState> {
  public extraData;
  private columns: any = {};

  __createStore() {
    return new Store<IListEditState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new ListEditExtendStore(this),
    });
  }

  getDataMode() {
    return DataModeEnum.all;
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  __setExtraData(obj) {
    this.extraData = obj;
  }

  __registerControl(columnId, rowId, controlInstance) {
    if (this.columns[columnId] == null) {
      this.columns[columnId] = {};
    }
    this.columns[columnId][rowId] = controlInstance;
  }

  createControl(rowId, config) {
    const { id, title, control, modifyMode } = config,
      controlId = id + rowId;
    if (controlId) {
      if (control) {
        if (!control.nodeName) {
          console.error(`容器控件Form-${this.id} 中的控件 ${controlId} 中不存在nodeName节点`);
          return null;
        }
        control.title = title;
        if (modifyMode != null) control.modifyMode = modifyMode;
        // 创建子控件
        return super.__createControl(controlId, control);
      } else {
        // 创建复杂控件
        const controlInstance = this.page.createControl(controlId, {
          title,
          parentType: 'listedit',
        });
        return controlInstance;
      }
    } else {
      console.error(`容器控件Form-${this.id} 中的控件 controlId 不存在`);
      return null;
    }
  }

  /**
   *
   * @param {number} rid
   */
  getRowForRowId(rid: number) {
    for (const row of this.store.state.dataSource.toArray()) {
      if (row._rid === rid) return row;
    }
    return undefined;
  }

  get dataModel() {
    return this.store.state.dataSource;
  }

  __deleteRow2cells(rid: number) {
    // TODO: 待实现
  }

  initDatas(dataSource) {
    const { rowIdCount } = this.store.state;
    this.store.dispatch('loaded', { dataSource, rowIdCount });
  }

  /**
   * 动态的往当前表格添加一行
   * @param rowData 行初始值
   *
   * lg. addRow({ [key]: value })
   */
  addRow(rowData = {}) {
    this.store.dispatch('batchAddRow', { rows: [rowData] });
  }

  /**
   * 动态的往当前表格添加多行
   * @param rows
   */
  batchAddRow(rows = []) {
    this.store.dispatch('batchAddRow', { rows });
  }

  /**
   * 清空所有数据
   */
  deleteAllRow() {
    this.store.dispatch('deleteAllRow', {});
  }

  /**
   * 更新数据源
   * @param rows 数据源
   */
  updateDatas(rows) {
    this.store.dispatch('loaded', { dataSource: rows });
  }

  async valid() {
    return true;
  }

  async getData(isValid) {
    if (isValid) {
      await this.valid();
    }
    return this.dataModel.toJSON();
  }

  /**
   * 获取某一行某一列的控件实例
   * @param rowIndex 行索引
   * @param cellId 列ID
   */
  findCellControl<T extends InstanceBase>(rowIndex: number, cellId: string) {
    const rowId = this.dataModel.toArray()[rowIndex]._rid;
    return this.findControl<T>(`${cellId}${rowId}`);
  }

  /**
   * 获取某一行某一列的控件实例
   * @param rowId 行ID
   * @param cellId 列ID
   */
  findCellControl2rid<T extends InstanceBase>(rowId: number, cellId: string) {
    return this.findControl<T>(`${cellId}${rowId}`);
  }
}
