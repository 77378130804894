import React from 'react';
import { Picker } from 'antd-mobile';
import ControlBase from '../../core/base';
import ListItem from '../../component/ListItem';
import { PickerData } from 'antd-mobile/lib/picker/PropsType';
import { ISelectState } from '@mjcloud/mcontrols/dist/controls/select/typings';

export default class SelectControl extends ControlBase<ISelectState> {
  private handleChange = (value?: Array<string | number>) => {
    this.instance.store.dispatch('updateValue', {
      value: value && value[0],
      actionSourceSign: this.instance.store,
    });
  };

  private handleClick = () => {
    const eventManager = this.instance.eventManager;
    if (eventManager.getHandlerCount('onSelectClick') > 0) {
      eventManager.trigger('onSelectClick', {});
    }
  };

  renderContent() {
    const { text, value, theme, iconfont, readonly, placeholder, isFetching } = this.state,
      { dataSource, isCustomizeMode } = this.state,
      props = { theme, iconfont, readonly, placeholder },
      data: PickerData[] = dataSource.map(row => ({ value: row._value, label: row._text }));

    if (isFetching) return <ListItem {...props} extra="加载中……" />;
    if (readonly) return <div className="dy-font-elipsis">{text}</div>;
    if (isCustomizeMode) return <ListItem {...props} extra={text} onClick={this.handleClick} />;
    return (
      <Picker value={[value]} data={data} extra={text} cols={1} onChange={this.handleChange}>
        <ListItem {...props} />
      </Picker>
    );
  }
}
