import Form from '.';
import Store from '@mjcloud/redux';
import { sleep } from '@mjcloud/utils';
import SectionReduce from './form-section/reduce';
import { DataStateEnum, PageModeEnum, InvoiceExecStatusEnum } from '@mjcloud/types';
import FormExtendStoreBase from '../common/form/extendStore';
import FormSectionExtendStore from './form-section/extendStore';
import { ActionBeforeEventArg, ActionAfterEventArg } from '@mjcloud/redux';
import { ValueChangeArg, RowDataStateChangeArg } from '@mjcloud/data-model';
import { FormInitialStateParams, IFormState, FormActionType, IFormLoadedParams } from './typings';
import { InstanceBase, ContainerInstanceBase } from '@mjcloud/instance';
import {
  IFormSectionState,
  FormSectionInitialStateParams,
  FormSectionActionType,
} from './form-section/typings';
import Service from '@mjcloud/service';

export default class FormExtendStore extends FormExtendStoreBase<Form> {
  private isFirstLoad = false;
  private isExistDraft = false;

  handleInitialStateBefore(e: ActionBeforeEventArg<FormInitialStateParams>) {
    const { initConfig } = e.params;
    const sectionConfigList = (initConfig.sections && initConfig.sections.items) || [],
      sectionsLength = sectionConfigList.length,
      sections: Array<Store<IFormSectionState>> = [];
    sectionConfigList
      .map(item => {
        if (item.index == null) item.index = 10;
        return item;
      })
      .sort((a, b) => (a.index as number) - (b.index as number))
      .forEach((sectionConfig, i) => {
        const { id } = sectionConfig;
        if (id) {
          const store = new Store<IFormSectionState, FormSectionActionType>({
            id,
            reduce: SectionReduce,
            extendStore: new FormSectionExtendStore(id, this.instance),
          });
          sections.push(store);
          sectionConfig.showDivider = i === sectionsLength - 1 ? false : sectionConfig.showDivider;
          store.dispatch<Partial<FormSectionInitialStateParams>>('initialState', {
            initConfig: sectionConfig,
            pageMode: this.instance.page.pageMode,
          });
        } else {
          console.error(
            `容器控件Form-${this.instance.id} 下的section节点中的 id 不存在`,
            sectionConfig,
          );
        }
      });
    e.params.sections = sections;

    if (initConfig.workflow) {
      this.instance.page.eventManager.add('inited', this.initWorkflow.bind(this));
      this.instance.page.eventManager.__insert('dataLoaded', 1, this.doneWorkflow.bind(this));
    }

    if (initConfig.revise) {
      this.instance.page.eventManager.add('inited', this.initRevise.bind(this));
      this.instance.page.eventManager.__insert('dataLoaded', 0, this.doneRevise.bind(this));
    }

    const key = this.instance.id,
      pageModel = this.instance.page.dataModel;
    if (pageModel[key]) {
      e.params.dataModel = pageModel[key];
    }
    this.isExistDraft = !!pageModel[key];
  }

  handleInitialStateAfter(
    e: ActionAfterEventArg<IFormState, FormActionType, FormInitialStateParams>,
  ) {
    super.handleInitialStateAfter(e);
    const { isReset, dataSource } = e.newState.state;
    if (isReset) {
      const values = dataSource.toJSON();
      this.instance.__updateValues(values, this.instance, true);
    } else {
      dataSource.bind('valueChange', this.handleDataModelValueChange.bind(this));
      dataSource.bind('dataStateChange', this.handleDataModelDataStateChange.bind(this));
    }
  }

  handleStartLoadBefore(e: ActionBeforeEventArg<any>) {
    const { revise } = this.instance.store.state.config;
    if (revise) e.params.revise = true;
  }

  async handleStartLoadAfter(e: ActionAfterEventArg<any, any, any>) {
    const key = this.instance.id,
      pageModel = this.instance.page.dataModel;
    if (this.isExistDraft) {
      if (!this.isFirstLoad) {
        this.instance.__loadStart();
        this.isFirstLoad = true;
        await sleep(66);
        e.newState.dispatch('loaded', {
          dataModel: pageModel[key],
        });
        this.instance.__loadComplete();
      } else {
        await super.handleStartLoadAfter(e);
      }
    } else {
      if (!pageModel[key]) pageModel[key] = this.instance.dataModel;
      await super.handleStartLoadAfter(e);
    }
  }

  handleLoadedBefore(e: ActionBeforeEventArg<IFormLoadedParams>) {
    e.params.dataState = DataStateEnum.added;
    if (
      this.instance.page.pageMode === PageModeEnum.modify ||
      this.instance.page.pageMode === PageModeEnum.look
    ) {
      e.params.dataState = DataStateEnum.unchanged;
    }
  }

  handleLoadedAfter(e: ActionAfterEventArg<IFormState>) {
    super.handleLoadedAfter(e);
    const values = e.newState.state.dataSource.toJSON();
    this.instance.__updateValues(values, this.instance, true);
  }

  private handleDataModelValueChange(e: ValueChangeArg) {
    if (!e.data.notUpdateState) {
      // 如果数据发生变更时，dataModel._dataState 不是 unchanged 则通知页面
      // 这里添加该功能，主要是防止在新增页面数据改变但_dataState不发生改变而导致新增页面Prompt功能失效
      this.instance.page.controlDataUpdated(this.instance.id);
    }

    if (e.eventSourceSign === this.instance) return;
    if (e.eventSourceSign === this.instance.dataModel) return;
    this.instance.__updateValues(e.data.values, this.instance.dataModel);
  }

  private handleDataModelDataStateChange(e: RowDataStateChangeArg) {
    if (e.data.dataState === DataStateEnum.unchanged) return;
    this.instance.page.controlDataUpdated(this.instance.id);
  }

  private getReviseBtns() {
    const { revise } = this.instance.store.state.config;

    let launchBtn: InstanceBase | undefined, submitBtn: InstanceBase | undefined;
    if (revise) {
      const { launchBtnId, submitBtnId } = revise;

      if (launchBtnId) {
        const [containerId, btnId] = launchBtnId.split('.');
        const container = this.instance.page.findControl(containerId);
        if (btnId && container instanceof ContainerInstanceBase)
          launchBtn = container.findControl(btnId);
      }
      if (submitBtnId) {
        const [containerId, btnId] = submitBtnId.split('.');
        const container = this.instance.page.findControl(containerId);
        if (btnId && container instanceof ContainerInstanceBase)
          submitBtn = container.findControl(btnId);
      }
    }
    return { launchBtn, submitBtn };
  }

  private initRevise() {
    if (this.instance.page.pageMode === PageModeEnum.modify) {
      const { revise } = this.instance.store.state.config;
      if (revise) {
        const { launchBtn, submitBtn } = this.getReviseBtns();
        if (launchBtn) launchBtn.display = false;
        if (submitBtn) submitBtn.display = false;
      }
    }
  }

  private doneRevise() {
    if (this.instance.page.pageMode === PageModeEnum.modify) {
      const { revise } = this.instance.store.state.config;
      if (revise) {
        const { _reviseId, _currentUserCanRevise, _isNeedApprove } = this.instance.dataModel;
        const { launchBtn, submitBtn } = this.getReviseBtns();

        if (_reviseId) {
          if (_currentUserCanRevise) {
            if (launchBtn) launchBtn.display = _isNeedApprove;
            if (submitBtn) submitBtn.display = !_isNeedApprove;
          }
        } else {
          // 没有修订记录
          if (launchBtn) launchBtn.display = true;
        }
      }
    }
  }

  private getWorkflowBtns() {
    const { workflow } = this.instance.store.state.config;

    let launchBtn: InstanceBase | undefined, agreeBtn: InstanceBase | undefined, refuseBtn: InstanceBase | undefined;
    if (workflow) {
      const { launchBtnId, agreeBtnId, refuseBtnId } = workflow;

      if (launchBtnId) {
        const [containerId, btnId] = launchBtnId.split('.');
        const container = this.instance.page.findControl(containerId);
        if (btnId && container instanceof ContainerInstanceBase)
          launchBtn = container.findControl(btnId);
      }
      if (agreeBtnId) {
        const [containerId, btnId] = agreeBtnId.split('.');
        const container = this.instance.page.findControl(containerId);
        if (btnId && container instanceof ContainerInstanceBase)
          agreeBtn = container.findControl(btnId);
      }
      if (refuseBtnId) {
        const [containerId, btnId] = refuseBtnId.split('.');
        const container = this.instance.page.findControl(containerId);
        if (btnId && container instanceof ContainerInstanceBase)
          refuseBtn = container.findControl(btnId);
      }
    }
    return { launchBtn, agreeBtn, refuseBtn };
  }

  private initWorkflow() {
    if (this.instance.page.pageMode === PageModeEnum.look) {
      const { launchBtn, agreeBtn, refuseBtn } = this.getWorkflowBtns();
      if (agreeBtn) agreeBtn.display = false;
      if (refuseBtn) refuseBtn.display = false;
    }
  }

  private async doneWorkflow() {
    if (this.instance.page.pageMode === PageModeEnum.look) {
      const { workflow } = this.instance.store.state.config;
      console.log('doneWorkflow.workflow.break', !workflow);
      const { businessType } = workflow;

      const _isWorkflow = this.instance.page.getParam("_isWorkflow");
      console.log('doneWorkflow._isWorkflow.break', !_isWorkflow);
      if (!_isWorkflow) return;

      const result: any = await Service.getWorkflowMode({ businessType });
      console.log('doneWorkflow.result.break', result === 1);
      if (result === 1) return;

      const { approveState } = this.instance.dataModel;
      console.log('doneWorkflow.approveState', approveState);
      if (approveState) {
        const { launchBtn, agreeBtn, refuseBtn } = this.getWorkflowBtns();
        if (agreeBtn) agreeBtn.display = approveState > 4 && approveState < 9;
        if (refuseBtn) refuseBtn.display = approveState > 4 && approveState < 9;
      }
    }
  }
}
