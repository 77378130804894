import Page from '@mjcloud/page';
import {
  IAppInfo,
  IAppSettingInfo,
  IDictionary,
  IPlatformConfig,
  IUserInfoConfig,
  Omit,
} from '@mjcloud/types';

interface ITopConfig extends Omit<IPlatformConfig, 'debugger'> {
  /** 当前应用的系统Id */
  appId: string;
  /** 登录地址 */
  loginUrl: string;
  /** 应用名称 */
  appName: string;
}

class TopConfig {
  /** 当前应用的系统Id */
  readonly appId: string;
  readonly loginVersion: string;
  readonly loginHost: string;
  readonly loginUrl: string;
  readonly appName: string;

  appInfoList: IDictionary<IAppInfo> = {};
  appSettingList: IDictionary<IAppSettingInfo> = {};
  constructor(params: ITopConfig) {
    const { version, apiHost, appId, loginUrl, appName } = params;
    this.loginHost = apiHost;
    this.loginVersion = version;
    this.appId = appId;
    this.loginUrl = loginUrl;
    this.appName = appName;
  }

  get apiHost() {
    return this.appSettingList[this.appId].apiHost;
  }

  setApiHost(appUrl: string) {
    this.appSettingList[this.appId].apiHost = appUrl;
  }

  get version() {
    return this.appSettingList[this.appId].version;
  }
}

export default class RbCore {
  public _isInit: boolean = false;
  private _config?: TopConfig;
  private _userInfo?: IUserInfoConfig | false;
  private _pageCountIndex: number;
  private _top: string = 'page-0';
  private _pages: IDictionary<Page>;
  private _permissions: IDictionary<string[]> = {};
  private _moduleCodes: IDictionary<IDictionary<string>> = {};
  constructor(public isMobile: boolean = false) {
    this._pageCountIndex = 0;
    this._pages = {};
  }

  public get config() {
    return this._config as TopConfig;
  }

  public get userInfo() {
    return this._userInfo;
  }

  public __setUserInfo(userInfo: IUserInfoConfig | false) {
    this._userInfo = userInfo;
  }

  public __initialConfig(config: ITopConfig) {
    this._isInit = true;
    this._config = new TopConfig(config);
  }

  public nextPageKey() {
    return `page-${(this._pageCountIndex++).toString()}`;
  }

  /**
   * 清空已存储的页面实例
   */
  public clearAllPage() {
    this._pages = {};
  }

  /**
   * 注册最顶层浏览器页面
   */
  public registerTopPage(pageKey: string, page: Page) {
    this._top = pageKey;
    this.registerPage(pageKey, page);
  }

  /** 添加一个页面实例 */
  public registerPage(pageKey: string, page: Page) {
    this._pages[pageKey] = page;
  }

  /**
   * 当前页面的最顶层浏览器页面
   */
  public get top() {
    return this._pages[this._top];
  }

  public getPage(pageKey: string) {
    return this._pages[pageKey];
  }

  /**
   * 判断应用权限列表是否已存在
   * @param appId 应用名称
   */
  public isExistPermission(appId: string) {
    if (this._permissions[appId]) return true;
    return false;
  }

  public setPermission(appId: string, permissionList: string[]) {
    this._permissions[appId] = permissionList;
  }

  public pushModuleCode(appId: string, path: string, moduleCode: string) {
    this._moduleCodes[appId] = { ...this._moduleCodes[appId], [path]: moduleCode };
  }

  public findModuleCode(appId: string, path: string) {
    if (this._moduleCodes[appId]) {
      return this._moduleCodes[appId][path];
    }
    return undefined;
  }

  /**
   * 判断用户是否具备执行该模块的权限
   * @param appId 应用名称
   * @param codes 模块代码
   */
  public permission(appId: string, codes: string[]) {
    let permissionCount = 0,
      codeCount = codes.length;
    if (this._permissions[appId]) {
      for (const permission of this._permissions[appId]) {
        for (const moduleCode of codes) {
          if (permission === moduleCode) permissionCount++;
        }
      }
    }
    return permissionCount === codeCount;
  }

  /** 回收一个页面实例 */
  public destroyPage(pageKey: string) {
    if (this._pages[pageKey]) {
      this._pages[pageKey].destroy();
    }
    delete this._pages[pageKey];
  }
}
