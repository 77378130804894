import Store from '@mjcloud/redux';
import PageModeHelper from '@mjcloud/page-mode-helper';
import SelectReduceBase from '../../common/select/reduce';
import { IRadioButtonsState, IRadioButtonsInitialStateParams } from './typings';

export class RadioButtonsReduce extends SelectReduceBase {
  initialState(store: Store<IRadioButtonsState>, params: IRadioButtonsInitialStateParams) {
    const { initConfig, pageMode } = params,
      { textFieldName, displayMode, disabledMode, theme, title, modifyMode } = initConfig,
      { tabs, placeholder = `请选择${title || ''}` } = initConfig;
    let state: IRadioButtonsState = {
      tabs,
      title,
      theme,
      value: '',
      texts: [],
      placeholder,
      tabIndex: -1,
      rowIdCount: 1,
      activeIndex: 0,
      dataSource: [],
      isValid: false,
      originalData: [],
      selectedRows: [],
      widthType: 'full',
      config: initConfig,
      multiSelect: false,
      originalDataSource: [],
      tokenSeparators: [',', '、'],
      textTokenSeparator: '、',
      vaueleTokenSeparator: ',',
      // TODO: defaultOpen配置值待实现
      defaultOpen: false,
      configIsFetching: false,
      textFieldName: textFieldName,
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    state.tokenSeparators = state.tokenSeparators
      .concat([state.textTokenSeparator, state.vaueleTokenSeparator])
      .filter((tokenSeparator, index, self) => self.indexOf(tokenSeparator) === index);
    return state;
  }

  loaded(store: Store<IRadioButtonsState>, params) {
    const state = super.loaded(store, params) as IRadioButtonsState;
    const texts = state.dataSource.map(row => row._text);
    let auto2width = 64,
      auto2itemwidth = 0;
    for (const text of texts) {
      auto2itemwidth = 64;
      auto2itemwidth += 28 * text.length;
      if (auto2itemwidth > 544) auto2itemwidth = 544;
      auto2width += auto2itemwidth;
    }
    state.widthType = auto2width > 750 ? 'auto' : 'full';
    state.texts = texts;
    return state;
  }

  updateActiveIndex(store: Store<IRadioButtonsState>, params: { activeIndex: number }) {
    // if (store.state.activeIndex === params.activeIndex) return store.state;
    const activeRow = store.state.dataSource[params.activeIndex];
    if (activeRow) {
      const { _text, _value } = activeRow,
        state = this.updateValue(store, { value: _value, text: _text }) as IRadioButtonsState;
      state.activeIndex = params.activeIndex;
      return state;
    }

    return store.state;
  }
}

export default new RadioButtonsReduce();
