import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { InstanceBase } from '@mjcloud/instance';
import { ActionAfterEventArg } from '@mjcloud/redux';
import { ExtendStoreBase } from '@mjcloud/extend-store';
import {
  ISimpleSearchState,
  ISimpleSearchConfig,
  SimpleSearchEventType,
  SimpleSearchActionType,
  ISimpleSearchFindParams,
  ISimpleSearchFindData,
} from './typings';

class SimpleSearchExtendStore extends ExtendStoreBase<SimpleSearch> {
  handleFindAfter(
    e: ActionAfterEventArg<ISimpleSearchState, SimpleSearchActionType, ISimpleSearchFindParams>,
  ) {
    const { _filter: filter } = e.newState.state;
    if (this.instance.eventManager.getHandlerCount('find') > 0) {
      this.instance.eventManager.trigger<ISimpleSearchFindData>('find', { filter });
    }
  }
}

class SimpleSearch extends InstanceBase<
  ISimpleSearchState,
  SimpleSearchEventType,
  SimpleSearchActionType
> {
  __createStore() {
    return new Store<ISimpleSearchState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new SimpleSearchExtendStore(this),
    });
  }

  initialState(initConfig: ISimpleSearchConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  get filter() {
    return this.store.state._filter;
  }
}

export default SimpleSearch;
