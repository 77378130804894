import WorkbenchPart from './part';
import { IControlSetConfig, XmlNode, XmlList } from '@mjcloud/types';
import { IComplexTableResult } from '@mjcloud/data-source-helper';
import { IDataState, IDataControlLoadedParams } from '@mjcloud/instance/dist/dataInstanceBase';
import { IInitialStateParams, IConfigStartLoadParams } from '@mjcloud/redux';

export enum WorkbenchAreaEnum {
  left = 1,
  right = 2,
}

export interface IWorkbenchPart {
  area: WorkbenchAreaEnum;
  code?: string;
  width: number;
  name: string;
  id: string;
  sort: number;
  title: string;
  group: string;
  workbenchId: string;
}

export interface IWorkbenchGroup {
  span: number;
  parts: IWorkbenchPart[];
}

export interface IWorkbenchConfigItem extends XmlNode {
  id: string;
  index?: number;
  /** 跨列数。默认为：1 */
  colspan?: number;
}

export interface IWorkbenchConfigSection extends XmlNode {
  id?: string;
  index?: number;
  /**
   * 段落标题。默认不显示标题
   */
  title?: string;
  /**
   * 当前段落每行显示的子项数。默认为：2
   */
  cols?: number;

  items: IWorkbenchConfigItem[];
}

export interface IWorkbenchConfig extends IControlSetConfig {
  isShowOverview: boolean;
  settingControllerId: string;
  cutoverControllerId: string;
  workbenchId?: string;
  workbenchName?: string;
  sections?: XmlList<IWorkbenchConfigSection>;
}

export interface ICutoverMenu {
  id: string;
  name: string;
}

export interface IWorkbenchItem extends IWorkbenchPart {
  id: string;
  index?: number;
  colspan: number;
}

export interface IWorkbenchSection extends XmlNode {
  id?: string;
  index?: number;
  /**
   * 段落标题。默认不显示标题
   */
  title?: string;
  /**
   * 当前段落每行显示的子项数。默认为：2
   */
  cols?: number;

  items: IWorkbenchItem[];
}

export interface IWorkbenchState extends IDataState<IWorkbenchConfig> {
  isShowOverview: boolean;
  isStaticMode: boolean;
  workbenchId?: string;
  workbenchName?: string;
  sections: IWorkbenchSection[];
  cutoverMenus: ICutoverMenu[];
  dataSource: IWorkbenchPart[];
  leftParts: IWorkbenchGroup[][];
  rightParts: IWorkbenchPart[];
  settingControllerId: string;
  cutoverControllerId: string;
}

export interface IWorkbenchInitialStateParams extends IInitialStateParams<IWorkbenchConfig> {}

export interface IWorkbenchConfigStartLoadParams extends IConfigStartLoadParams<IWorkbenchConfig> {}

export interface IWorkbenchLoadedParams extends IDataControlLoadedParams {
  dataSource: IComplexTableResult;
  parts: IWorkbenchPart[];
}

export interface IWorkbenchInitialStateParams extends IInitialStateParams<IWorkbenchConfig> {
  createWorkbenchPart(config: IWorkbenchPart): WorkbenchPart;
}
