import React from 'react';
import ControlBase from '../../base';
import globalData from '@mjcloud/global-data';
import { IWaterfallState } from '../typings';

import styles from './index.less';

export default class WaterfallControl extends ControlBase<IWaterfallState> {
  renderContent() {
    const { items } = this.state;
    const { ControlFactory } = globalData;
    return (
      <div>
        {items.map(({ isShowBottomBlank, ...props }) => {
          return (
            <React.Fragment key={props.id}>
              <ControlFactory {...props} />
              {isShowBottomBlank && <div className={styles.interval} />}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}
