import Page from '@mjcloud/page';
import { v4 as uuid } from 'uuid';
import Service from '@mjcloud/service';
import PageHelper from '@mjcloud/page-helper';
import { sleep, Filter } from '@mjcloud/utils';
import { DataModeEnum, IDictionary, IArrayDictionary, IDataInstanceConfig } from '@mjcloud/types';

export type AggType = 'None' | 'Sum' | 'Avg' | 'Max' | 'Min';
export type OrderbyType = 1 | 2;

export interface IData {
  id?: string;
  delay?: boolean;
  appId?: string;
  list?: IDictionary;
}

/**
 * 子控件数据类型
 */
export interface ItemControlData extends IData {
  /**
   * 值字段名称，默认为：id
   */
  valueField?: string;
  /**
   * 文本字段名称，默认为：text
   */
  textField?: string;
}

/**
 * 子控件树形数据类型
 */
export interface ItemControlTreeData extends ItemControlData {
  /**
   * 父级id字段名称，默认为：parentId
   */
  parentIdField?: string;
}

export interface ISigninAgg {
  /** 统计字段 */
  name: string;
  /** 统计类型。None | Sum | Avg | Max | Min */
  aggType?: AggType;
}

export interface IOrderby {
  /** 排序字段 */
  name: string;
  /** 排序方式 */
  sortType?: OrderbyType;
}

export interface IDataSourceParameters extends IDictionary {
  /** 页码 */
  pageIndex?: number;
  /** 页面大小 */
  pageSize?: number;

  /** 是否启用修订 */
  revise?: boolean;

  orderBy?: IOrderby[];

  /** 需要合计的列集合 */
  aggs?: ISigninAgg[];

  /** 定位 */
  location?: string;

  /** 过滤条件 */
  filter?: Filter;

  /** 覆盖原有配置 */
  config?: any;
}

export type ISingleRowResult = { id: string; [key: string]: any };

export type ISingleTableResult = ISingleRowResult[];

/**
 * 复杂表数据结构
 */
export interface IComplexTableResult {
  rows: ISingleTableResult;
  pageIndex: number;
  pageSize: number;
  count: number;
  aggs: IDictionary;
}

export type IDataResult = IDictionary | IComplexTableResult | undefined;

export interface ILoadDataParams {
  page: Page;
  data?: IData;
  isFirst: boolean;
  dataMode: DataModeEnum;
  params?: IDataSourceParameters;
  workbenchPart?: any;
}

export interface IGetDataInstanceConfigParams {
  page: Page;
  data: IData;
  param: any;
  workbenchPart?: any;
}

export interface IDataSourceItem extends IDictionary {
  _text: string;
  _value: string;
  _disabled: boolean;
  _children?: IDataSourceItem[];
}

export interface ITreeDataSourceItem extends IDataSourceItem {
  _parentId: string;
  _children?: ITreeDataSourceItem[];
}

export default class DataSource {
  static async getDataInstanceConfig(params: IGetDataInstanceConfigParams) {
    const { page, data, param, workbenchPart } = params;
    let { id, appId } = data;
    if (id) {
      if (appId) {
        id = appId + '/' + id;
      }
      const params = await Service.getDataSourceConfig({
        pageAddress: page.address,
        workbenchPartAddress: workbenchPart && workbenchPart.address,
        datasourceId: id,
      });
      if (params) {
        // if (params.datasource_not_exists) return undefined;
        const config: IDataInstanceConfig = { id, params: {} };
        config.params = await PageHelper.getDynamicParamValues(
          workbenchPart ? workbenchPart : page,
          params,
          param,
        );
        return config;
      }
    }
    return undefined;
  }

  static formatDataSourceParams(params: IDataSourceParameters) {
    const __params: IDataSourceParameters = { ...params };
    // let isExistFilter = false,
    //   filter = new Filter();
    // for (const key in params) {
    //   if (key.indexOf('filter') === 0) {
    //     isExistFilter = true;
    //     if (params[key] != null) filter.addCondition(params[key]);
    //   } else {
    //     __params[key] = params[key];
    //   }
    // }
    // if (isExistFilter) __params.filter = filter;
    if (!__params.orderBy) __params.orderBy = [];
    return __params;
  }

  static async loadData(param: ILoadDataParams): Promise<IDataResult | undefined> {
    const { page, dataMode, data, isFirst, workbenchPart, params = {} } = param,
      { data: e, ..._params } = params;
    await sleep(66);
    if (data && !data.id && data.list) {
      switch (dataMode) {
        case DataModeEnum.all:
        case DataModeEnum.pagination:
          return { ...data.list, aggs: {} };
        case DataModeEnum.one:
          return data.list.rows && data.list.rows[0];
        default:
          return undefined;
      }
    }
    if (!data || !data.id || (data.delay && isFirst)) {
      switch (dataMode) {
        case DataModeEnum.all:
          return { rows: [], aggs: {}, count: 0 };
        case DataModeEnum.one:
          return {};
        case DataModeEnum.pagination:
          return { rows: [], aggs: {}, pageIndex: 1, count: 0 };
        default:
          return undefined;
      }
    }
    const config = await DataSource.getDataInstanceConfig({
      page,
      data,
      param: e,
      workbenchPart,
    });
    if (config) {
      let res: any;
      if (config.params.datasource_not_exists) {
        res = { count: 0, aggs: {}, rows: [], row: {} };
      } else {
        // TODO: 后端不支持多个筛选条件，这里通过拼接的方式临时实现
        const __params = this.formatDataSourceParams(_params),
          { id } = config;
        if (dataMode === DataModeEnum.pagination) {
          if (!__params.pageIndex) __params.pageIndex = 1;
          if (!__params.pageSize) __params.pageSize = 10;
        }
        const _data = {
          id,
          dataMode,
          params: {
            ...config.params,
            ...__params,
          },
        };
        res = await Service.getDataSource<any>({
          ..._data,
          pageAddress: page.address,
          workbenchPartAddress: workbenchPart && workbenchPart.address,
        });
      }

      if (res) {
        // TODO: 推测后端和业务代码共同导致的异常
        // 当请求中具备 location 当数据库查询结果不存在时后端会返回 {pageIndex: 0} 的结果导致列表异常
        if (res.pageIndex === 0) res.pageIndex = 1;

        switch (dataMode) {
          case DataModeEnum.all:
            return res;
          case DataModeEnum.one:
            return res.row || {};
          case DataModeEnum.pagination:
            return res;
        }
      }
    }
    return undefined;
  }

  static formatDataSource<T extends IDataSourceItem = IDataSourceItem>(
    data: ItemControlData,
    dataSource: IDictionary[],
    disabledFn: (row: IDictionary) => boolean,
    callbackfn?: (value: IDictionary) => IDictionary,
  ) {
    let extraData: IDictionary = {};
    const { valueField = 'id', textField = 'text' } = data;
    const _dataSource: IDataSourceItem[] = [];
    for (const item of dataSource) {
      if (item) {
        const { [valueField]: _value = '', [textField]: _text = '' } = item;
        let _item: IDataSourceItem = {
          ...item,
          _text,
          _value,
          // TODO: 先还原原本写法防止业务报错
          // _text: `${_text}`,
          // _value: `${_value}`,
          _disabled: false,
          ...extraData,
        };
        extraData = callbackfn ? callbackfn(_item) : {};

        if (!extraData._isExist) {
          _item = { ..._item, ...extraData };
          _item._disabled = disabledFn(_item);
          _dataSource.push(_item);
        }
      } else {
        console.error('数据源子项中存在null值');
      }
    }
    return _dataSource as T[];
  }

  static formatTreeDataSource<T extends ITreeDataSourceItem = ITreeDataSourceItem>(
    data: ItemControlTreeData,
    dataSource: IDictionary[],
    disabledFn: (row: IDictionary) => boolean,
    callbackfn?: (value: IDictionary) => IDictionary,
  ) {
    let extraData: IDictionary = {};
    const { valueField = 'id', textField = 'text', parentIdField = 'parentId' } = data;
    const _dataSource: ITreeDataSourceItem[] = [];
    for (const item of dataSource) {
      if (item) {
        const {
          [textField]: _text = '',
          [valueField]: _value = '',
          [parentIdField]: _parentId = '',
        } = item;
        let _item: ITreeDataSourceItem = {
          ...item,
          _text,
          _value,
          _parentId,
          _isTop: true,
          _disabled: false,
        };
        extraData = callbackfn ? callbackfn(_item) : {};

        if (!extraData._isExist) {
          _item = { ..._item, ...extraData };
          _item._disabled = disabledFn(_item);
          _dataSource.push(_item);
        }
      } else {
        console.error('数据源子项中存在null值');
      }
    }
    return _dataSource as T[];
  }

  private static pushData(
    parentItem: ITreeDataSourceItem,
    data: ITreeDataSourceItem[],
    uuid: string,
  ) {
    let element: ITreeDataSourceItem;
    for (let i = 0; i < data.length; i++) {
      element = data[i];
      if (element._key == null || element._key.indexOf(uuid) < 0) {
        element._key = `${uuid}_${i}`;
      }
      if (!element._pathValue) {
        element._pathValue = element._value;
      }
      if (!element._pathKey) {
        element._pathKey = element._key;
      }
      if (!element._isTop) {
        continue;
      }
      if (parentItem._value != element._parentId) {
        continue;
      }
      if (element._value == element._parentId) {
        continue;
      }
      element._isTop = false;
      element._parent = parentItem;
      element._key = `${parentItem._key}-${i}`;
      element._pathKey = `${parentItem._pathKey},${element._key}`;
      element._pathValue = `${parentItem._pathValue},${element._value}`;
      if (!parentItem._children) {
        parentItem._children = [element];
      } else {
        parentItem._children.push(element);
      }
    }
  }

  static formatTreeData<T extends ITreeDataSourceItem = ITreeDataSourceItem>(
    data: T[],
    expandLevel: number = 0,
    keyFieldName = '_key',
    isUUID: boolean = true,
  ) {
    let _expandLevel = expandLevel >= 0 ? expandLevel : 0;
    const _uuid = isUUID ? uuid().replace(/-/g, '') : 'key';
    const _data: T[] | undefined = [];
    const expandLevel2Keys: string[][] = [[]];
    const __expandLevel2Keys: string[][] = [];
    for (const item of data) {
      this.pushData(item, data, _uuid);
    }
    for (const item of data) {
      if (item._isTop) _data.push(item);
    }

    function generateKeys(dataSource: ITreeDataSourceItem[], expandLevelKey: number = 1) {
      const _expandLevelKey = expandLevelKey + 1;
      if (!__expandLevel2Keys[expandLevelKey]) __expandLevel2Keys[expandLevelKey] = [];
      for (const row of dataSource) {
        __expandLevel2Keys[expandLevelKey].push(row[keyFieldName]);
        if (row._children && row._children.length > 0) {
          generateKeys(row._children, _expandLevelKey);
        }
      }
    }

    generateKeys(_data as any);

    __expandLevel2Keys.forEach((keys, index) => {
      for (let i = 0; i < index; i++) {
        keys = keys.concat(__expandLevel2Keys[i]);
      }
      expandLevel2Keys[index] = keys.filter(k => !!k);
    });

    const maxExpandLevel = expandLevel2Keys.length - 1;
    const _expandLevel2Keys: IArrayDictionary<string[]> = {};
    Array.from({ length: maxExpandLevel }).map((_, k) => {
      _expandLevel2Keys[k] = expandLevel2Keys[k];
    });
    _expandLevel = _expandLevel >= maxExpandLevel ? maxExpandLevel - 1 : _expandLevel;
    return {
      dataSource: _data,
      expandLevel: _expandLevel,
      expandLevel2Keys: _expandLevel2Keys,
      treeDefaultExpandedKeys: _expandLevel2Keys[_expandLevel],
    };
  }
}
