import { DataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';

class CarouselReduce extends DataReduceBase {
  initialState(store, params) {
    let { initConfig, pageMode } = params,
      { displayMode } = initConfig;
    return {
      tabIndex: -1,
      dataSource: [],
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
  }
}

export default new CarouselReduce();
