interface IDictionary<T = any> {
  [key: string]: T;
}

declare global {
  function define(name: string, fn: Function, callback?: Function): void;
  class requirejs {
    constructor(name: string[], callback: Function, errback?: Function, optional?: any);
    static config(config: any): void;
  }
}

/**
 * requireJs的平台封装类
 */
export default class RequireHelper {
  /**
   * `require`一个远程脚本
   * @param url 远程脚本地址
   */
  public static loadScript<T = any>(url: string) {
    return new Promise<T>((resolve, reject) => {
      new requirejs(
        [url],
        (res: any) => {
          if (res) {
            if (res.default) {
              resolve(res.default);
            } else {
              resolve(res);
            }
          } else {
            const error = new Error(url + ' is undefined');
            error.name = 'UndefinedException';
            reject(error);
          }
        },
        (ex: any) => {
          const error: any = new Error(url + ' is scripterror(404 or other)');
          error.name = 'scripterror';
          error.data = ex;
          console.error(ex);
          reject(error);
        },
      );
    });
  }

  /**
   * 根据传入的路径列表define批量远程脚本
   */
  public static async defineScripts(paths: IDictionary<string>) {
    for (const key in paths) {
      if (paths.hasOwnProperty(key)) {
        const path = paths[key];
        const script = await this.loadScript(path);
        define(key, () => script);
      }
    }
  }
}
