/**
 * 下划线转驼峰命名
 */
export const camelCase = (name: string) =>
  name.charAt(0).toUpperCase() + name.slice(1).replace(/-(\w)/g, (_, n) => n.toUpperCase());

/**
 * 获取对象差异
 * @param {source} object 原始对象
 * @param {target} object 目标对象
 */
export const checkChanged = (source: object, target: object) =>
  JSON.stringify(source) === JSON.stringify(target);

/**
 * 判断两数组是否完全相同
 * @param {source} [] 原数组
 * @param {target} [] 目标数组
 */
export const sameArray = (source: any[], target: any[]): boolean =>
  new Set(source.concat(target)).size === source.length;

export const isType = (value: any, type: string) => {
  const toString = {}.toString;
  return toString.call(value) === '[object ' + type + ']';
};

export const chnSubstr = (value: string, length: number): String => {
  if (!value) return value;
  var str_length = 0;
  var str_len = 0;
  let str_cut = new String();
  str_len = value.length;
  for (var i = 0; i < str_len; i++) {
    let a = value.charAt(i);
    str_length++;
    if (escape(a).length > 4) {
      //中文字符的长度经编码之后大于4
      str_length++;
    }
    if (str_length >= length) {
      str_cut = str_cut.concat("...");
      return str_cut;
    }

    str_cut = str_cut.concat(a);
  }
  //如果给定字符串小于指定长度，则返回源字符串；
  return value;
}
