import React from 'react';
import Icon from '@mjcloud/icon';
import SelectInstance from '..';
import { WhiteSpace } from '@mjcloud/ui';
import { findDOMNode } from 'react-dom';
import globalData from '@mjcloud/global-data';
import { DelayCallback } from '@mjcloud/utils';
import { SelectProps } from 'antd/lib/select';
import Table, { TableInstance } from './Table';
import { TablePaginationConfig } from 'antd/lib/table';
import ControlBase, { IControlBaseProps } from '../../base';
import { TableRowSelection } from 'antd/lib/table/interface';
import { Select, Pagination, Spin, Divider, Button } from 'antd';
import { ISelectBaseDataSource } from '../../common/select/typings';
import { ISelectState, ISelectButtonClickAfterParams } from '../typings';
import { IValueControlUpdateValueParams } from '@mjcloud/instance/dist/valueInstanceBase';

import styles from './index.less';
export interface LabeledValue {
  key?: string;
  value: string | number;
  label: string | undefined;
}
type SelectValue = LabeledValue | LabeledValue[];

function splitBySeparators(str: string, separators: string[]) {
  if (!str) return [];
  const reg = new RegExp(`[${separators.join()}]`);
  return `${str}`.split(reg).filter(token => token);
}

function filterOption(inputValue: string, option: any) {
  const children = option.children;

  if (typeof children === 'string' || typeof children === 'number') {
    return String(children).indexOf(inputValue) >= 0;
  }
  return false;
}

class SelectControl extends ControlBase<ISelectState, SelectInstance> {
  // private _select: Select | null = null;
  private _delayCallback: DelayCallback;
  private tableInstance: TableInstance | null = null;
  constructor(props: IControlBaseProps) {
    super(props);
    this._delayCallback = new DelayCallback(this.handDelayCallback);
  }

  updateDropdownStyle = () => {
    const fn = (stop: boolean) => {
      if (this.tableInstance) {
        const dropdownWidth = this.tableInstance.getClientWidth(this.state.totalWidth);
        console.log('updateDropdownStyle', dropdownWidth);
        if (dropdownWidth) {
          stop = true;
          this.instance.store.dispatch<React.CSSProperties>('updateDropdownStyle', {
            width: dropdownWidth,
          });
        }
      }
      return stop;
    };

    let stop: boolean = false,
      cb = () => {
        setTimeout(() => {
          stop = fn(stop);
          if (!stop) cb();
        }, 66);
      };
    cb();
  };

  dropdownRef = (instance: TableInstance | null) => {
    this.tableInstance = instance;
  };

  selectRef = (instance: any) => {
    if (this.state.autoFocus) {
      const el = findDOMNode(instance);
      if (el instanceof Element) {
        const input = el.getElementsByTagName('input');

        setTimeout(() => {
          if (input[0]) input[0].focus();
        }, 66);
      }
    }
  };

  paginationRender = () => {
    const { pagination } = this.state;
    if (pagination) {
      return (
        <>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <div className={styles.pagination} onMouseDown={this.handleMouseDown}>
            <Pagination
              {...pagination}
              onShowSizeChange={this.handlePaginationShowSizeChange}
              onChange={this.handlePaginationChange}
              size="small"
            />
          </div>
        </>
      );
    }
    return null;
  };

  addButtonRender = () => {
    const { columns, addRowButton } = this.state;

    if (addRowButton && addRowButton.isAuthority && addRowButton.display) {
      const { title = '添加', icon = 'push' } = addRowButton;
      if (columns) {
        return (
          <div onMouseDown={this.handleMouseDown}>
            <WhiteSpace />
            <Button block size="small" type="dashed" onClick={this.handleAddRowButtonClick}>
              <Icon type={icon} />
              {title}
            </Button>
          </div>
        );
      }
      return (
        <>
          <Divider style={{ margin: '4px 0' }} />
          <div
            style={{ padding: '4px 8px', cursor: 'pointer' }}
            onMouseDown={this.handleMouseDown}
            onClick={this.handleAddRowButtonClick}
          >
            <Icon type={icon} /> {title}
          </div>
        </>
      );
    }
    return null;
  };

  dropdownRender = (menu: React.ReactElement) => {
    const {
      value,
      columns,
      pagination,
      dataSource,
      isFetching,
      multiSelect,
      errorMessage,
      dropdownStyle,
      addRowButton,
      tokenSeparators,
    } = this.state;
    if (errorMessage) return menu;
    if (columns) {
      const valueArr: string[] = multiSelect ? splitBySeparators(value, tokenSeparators) : [value];
      const selectedRowKeys: number[] = dataSource
        .map(item => {
          for (const _value of valueArr) {
            if (_value === item._value) {
              return item._rid;
            }
          }
          return undefined;
        })
        .filter(item => !!item) as number[];
      const rowSelection: TableRowSelection<ISelectBaseDataSource> = {
        fixed: true,
        selectedRowKeys,
        type: multiSelect ? 'checkbox' : 'radio',
        onChange: this.handleTableSelectionChange,
      };
      return (
        <Table
          ref={this.dropdownRef}
          style={dropdownStyle}
          columns={columns}
          loading={isFetching}
          pagination={pagination}
          dataSource={dataSource}
          rowSelection={rowSelection}
          onMouseDown={this.handleMouseDown}
          onChange={this.handleTableChange}
        >
          {this.addButtonRender()}
        </Table>
      );
    }
    if (addRowButton && addRowButton.isAuthority && addRowButton.display) {
      if (pagination) {
        return (
          <Spin spinning={isFetching}>
            {menu}
            {this.paginationRender()}
            {this.addButtonRender()}
          </Spin>
        );
      }
      return (
        <div>
          {menu}
          {this.addButtonRender()}
        </div>
      );
    } else if (pagination) {
      return (
        <Spin spinning={isFetching}>
          {menu}
          {this.paginationRender()}
        </Spin>
      );
    }
    if (isFetching || errorMessage) return menu;
    return menu;
  };

  private handDelayCallback = (searchValue: string) => {
    this.instance.store.dispatch('handleSearch', { searchValue });
  };

  private handleChange = (selectValue: SelectValue | undefined, option?: any) => {
    const { textTokenSeparator, vaueleTokenSeparator } = this.state;
    let textArr: string[] = [],
      valueArr: string[] = [],
      rowIds: string[] = [];
    if (selectValue instanceof Array) {
      selectValue.forEach(({ label = '', key = '' }) => {
        textArr.push(label);
        valueArr.push(key);
      });
    } else if (typeof selectValue === 'object') {
      textArr = [selectValue.label || ''];
      valueArr = [`${selectValue.value}`];
    }
    if (option instanceof Array) {
      option.forEach(({ key = '' }) => {
        rowIds.push(key);
      });
    } else if (typeof option === 'object') {
      rowIds = [option.key];
    }
    const params: IValueControlUpdateValueParams = {
      value: selectValue == null ? null : valueArr.join(vaueleTokenSeparator),
      text: selectValue == null ? null : textArr.join(textTokenSeparator),
      actionSourceSign: this.instance.store,
      rowIds,
    };
    this.instance.store.dispatch<IValueControlUpdateValueParams>('updateValue', params);
  };

  private handleSearch = (value: string) => {
    const { pagination } = this.state;
    if (pagination) {
      this._delayCallback.execute(value);
    }
  };

  private handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.tableInstance) this.tableInstance.onKeyDown(event);
  };

  private handleDropdownVisibleChange = (open: boolean) => {
    const { alwaysLoad, value } = this.state;
    if (open && alwaysLoad) {
      this.instance.store.dispatch('startLoad', { open, location: value });
    } else {
      this.instance.store.dispatch('updateOpen', { open });
    }
    if (open) {
      this.updateDropdownStyle();
    }
  };

  private handleFocus = () => {
    if (this.state.autoFocus) {
      this.handleDropdownVisibleChange(true);
    }
  };

  handleMouseDown = e => e.preventDefault();

  handleTableSelectionChange = (
    selectedRowKeys: Array<string | number>,
    selectedRows: ISelectBaseDataSource[],
  ) => {
    const { textTokenSeparator, vaueleTokenSeparator } = this.state;
    let textArr: string[] = [],
      valueArr: string[] = [];
    for (const row of selectedRows) {
      textArr.push(row._text);
      valueArr.push(row._value);
    }
    const params: IValueControlUpdateValueParams = {
      value: valueArr.join(vaueleTokenSeparator),
      text: textArr.join(textTokenSeparator),
      actionSourceSign: this.instance.store,
      rowIds: selectedRowKeys,
    };

    this.instance.store.dispatch<IValueControlUpdateValueParams>('updateValue', params);
  };

  handleTableChange = (pagination: TablePaginationConfig) => {
    if (this.state.pagination) {
      this.instance.store.dispatch('updatePagination', { pagination });
    }
  };

  private handleSuffixButtonClick = event => {
    if (event) event.preventDefault();
    if (this.state.buttonLoading) return;
    this.instance.store.dispatch<ISelectButtonClickAfterParams>('buttonClickAfter', {
      config: this.state.suffixButton,
    });
  };

  private handleAddRowButtonClick = event => {
    if (event) event.preventDefault();
    if (this.state.buttonLoading) return;
    this.instance.store.dispatch<ISelectButtonClickAfterParams>('buttonClickAfter', {
      isAdd: true,
      config: this.state.addRowButton,
    });
  };

  private handlePaginationShowSizeChange = (current: number, pageSize: number) => {
    const { pagination } = this.state;
    if (pagination) {
      pagination.current = current;
      pagination.pageSize = pageSize;
      // TODO: 当前页码为0的时候会导致空数据异常
      if (pagination.current === 0) pagination.current = 1;
      this.instance.store.dispatch('updatePagination', { pagination });
    }
  };

  private handlePaginationChange = (page: number, pageSize?: number) => {
    const { pagination } = this.state;
    if (pagination) {
      pagination.current = page;
      pagination.pageSize = pageSize;
      this.instance.store.dispatch('updatePagination', { pagination });
    }
  };

  renderOption() {
    const { columns, dataSource, isFetching, errorMessage } = this.state;
    if (isFetching || errorMessage || columns) return null;

    return dataSource.map(item => {
      return (
        <Select.Option key={item._rid} value={item._value} disabled={item._disabled}>
          {item._text}
        </Select.Option>
      );
    });
  }

  renderContent() {
    const { id } = this.props;
    const { formatMessage } = globalData;
    if (this.state.readonly) return <>{this.state.text || ''}</>;
    const { textTokenSeparator, tokenSeparators } = this.state,
      { needDropdown, suffixButton } = this.state,
      { disabled, isFetching, placeholder, multiSelect } = this.state,
      { pagination, columns } = this.state,
      { open, autoFocus } = this.state,
      { value, text } = this.state;
    if (this.state.configErrorMessage) return <Select disabled value={this.state.errorMessage} />;
    const selectProps: SelectProps<SelectValue> = { style: { width: '100%' } };
    let selectValue: SelectValue = { key: value, value, label: text };
    let separators: string[] | undefined = undefined;
    if (multiSelect) {
      const valueArr: string[] = splitBySeparators(value, tokenSeparators);
      let textArr: string[] = [];
      if (text) textArr = splitBySeparators(text, tokenSeparators);
      selectValue = valueArr.map((item, i) => ({ key: item, value: item, label: textArr[i] }));
      separators = tokenSeparators.concat([textTokenSeparator]);
    }

    if (needDropdown && columns) {
      selectProps.open = open;
    }
    let children: React.ReactNode;

    if (!needDropdown) {
      children = (
        <Select<SelectValue>
          {...selectProps}
          mode="multiple"
          value={selectValue}
          open={false}
          labelInValue
          showArrow={false}
          showSearch={false}
          onChange={this.handleChange}
        />
      );
    } else {
      children = (
        <Select<SelectValue>
          {...selectProps}
          id={id}
          disabled={disabled}
          ref={this.selectRef}
          autoFocus={autoFocus}
          defaultOpen={autoFocus}
          placeholder={placeholder}
          onFocus={this.handleFocus}
          tokenSeparators={separators}
          onChange={this.handleChange}
          onSearch={this.handleSearch}
          defaultActiveFirstOption={false}
          dropdownMatchSelectWidth={!columns}
          dropdownRender={this.dropdownRender}
          onInputKeyDown={this.handleInputKeyDown}
          mode={multiSelect ? 'multiple' : undefined}
          filterOption={pagination ? undefined : filterOption}
          onDropdownVisibleChange={this.handleDropdownVisibleChange}
          value={value == null || value === '' ? undefined : selectValue}
          notFoundContent={isFetching ? formatMessage({ id: 'global.data.loading' }) : undefined}
          labelInValue
          allowClear
          showSearch
          showArrow
        >
          {this.renderOption()}
        </Select>
      );
    }

    if (suffixButton) {
      const { icon = 'plus' } = suffixButton,
        { buttonLoading } = this.state;
      if (selectValue instanceof Array) {
        const selectValues = [...selectValue],
          handleClose = (index: number) => {
            selectValues.splice(index, 1);
            console.log(selectValues);
            this.handleChange(selectValues);
          };
        return (
          <div className={styles.group}>
            <div className={styles.select}>
              {selectValues.map((row, index) => (
                <div key={row.key} className={styles.tag}>
                  <div className={styles.content}> {row.label}</div>
                  <span className={styles.close} onClick={() => handleClose(index)}>
                    <Icon type="close" />
                  </span>
                </div>
              ))}
            </div>
            <div className={styles.icon} onClick={this.handleSuffixButtonClick}>
              <Icon type={buttonLoading ? 'loading' : icon} spin={buttonLoading} />
            </div>
          </div>
        );
      }
      return (
        <div className={styles.group}>
          {children}
          <div className={styles.icon} onClick={this.handleSuffixButtonClick}>
            <Icon type={buttonLoading ? 'loading' : icon} spin={buttonLoading} />
          </div>
        </div>
      );
    } else {
      return children;
    }
  }
}

export default SelectControl;
