import React from 'react';
import { TextareaItem } from 'antd-mobile';
import ControlBase from '../../core/base';
import { ITextAreaState } from '@mjcloud/mcontrols/dist/controls/text-area/typings';

export default class TextAreaControl extends ControlBase<ITextAreaState> {
  private handChange = (value?: string) => {
    this.instance.store.dispatch('updateValue', {
      value,
      text: value,
      actionSourceSign: this.instance.store,
    });
  };

  renderContent() {
    const { value, text, readonly, placeholder, maxLength } = this.state,
      _value = value == null ? undefined : value.toString();
    if (readonly) return <div className="dy-font-elipsis">{text}</div>;
    return (
      <TextareaItem
        value={_value}
        onChange={this.handChange}
        placeholder={placeholder}
        count={maxLength}
        rows={2}
        clear
      />
    );
  }
}
