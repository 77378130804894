import react from 'react';
import * as tslib from 'tslib';
import ReactDOM from 'react-dom';
import ReactIs from 'react-is';
import styled from 'styled-components';

const _tslib = tslib;
const _react = react;
const _ReactDOM = ReactDOM;
const _ReactIs = ReactIs;
const _antd = require('antd');
const _styled = styled;

define('tslib', function() {
  return _tslib;
});
define('react-is', function() {
  return _ReactIs;
});
define('styled-components', function() {
  return _styled;
});
define('React', function() {
  return _react;
});
define('ReactDOM', function() {
  return _ReactDOM;
});
define('antd', function() {
  return _antd;
});
