

/** 基于视觉稿横屏尺寸/100得出的基准font-size */
export const BASEFONTSIZE = 750;
export const SPACING = 8;

export const TABLECOLUMNWIDTH = 250;

export const COLOR = {
  /** 主要色 */
  PRIMARY: '#4e78b8',
  /** 黑色 */
  BLACK: '#191f25',
  /** 白色 */
  WHITE: 'white',
  /** 次要色  */
  MINOR: '#D9D8D9',
};

export const EMPTYTEXT = '暂无数据';
export const EMPTYEDITTEXT = '请添加数据';
