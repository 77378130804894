// import F2 from '@antv/my-f2/dist/my-f2.min';
import Store from '@mjcloud/redux';
import normal from './theme/normal';
import globalData from '@mjcloud/global-data';
import { IDictionary } from '@mjcloud/types';
import { NumberHelper } from '@mjcloud/utils';
import {
  IChartState,
  IChartBarlineConfig,
  IChartBarConfig,
  IChartLineConfig,
  IChartPieConfig,
  IChartBasePieConfig,
  IChartTagPieConfig,
  IChartCircleProgressConfig,
  IChartDashboardConfig,
} from './typings';

type Chart = any;
let _colors: string[] = [
  '#1890FF',
  '#2FC25B',
  '#FACC14',
  '#223273',
  '#8543E0',
  '#13C2C2',
  '#3436C7',
  '#F04864',
];

export const style = {
  normal() {
    const { AntV } = globalData;
    AntV.Global.setTheme(normal);
  },
};

export function setColors(colors: string[]) {
  const { AntV } = globalData;
  _colors = colors;
  AntV.Global.setTheme({ colors });
}

function values(obj: any) {
  var arr: any = [];
  for (let i in obj) {
    arr.push(obj[i]); //属性
  }
  return arr;
}

export function drawChart(chart: Chart, store: Store<IChartState>) {
  const { chartType, dataSource: data, series } = store.state;
  // console.log(chartType, data);
  switch (chartType) {
    case 'barline':
      drawChart2barline(chart, store, data, series.barline as any);
      break;
    case 'bar':
      drawChart2bar(chart, store, data, series.bar as any);
      break;
    case 'line':
      drawChart2line(chart, store, data, series.line as any);
      break;
    case 'pie':
      drawChart2pie(chart, data, series.pie as any);
      break;
    case 'basePie':
      drawChart2basepie(chart, data, series.basePie as any);
      break;
    case 'circleProgress':
      drawChart2circleprogress(chart, data, series.circleProgress as any);
      break;
    default:
      break;
  }
}

function drawChart2barline(
  chart: Chart,
  store: Store<IChartState>,
  data: any[],
  config: IChartBarlineConfig,
) {
  const { rotate = 0, xAxisTitleInterval = 1, lineName } = config,
    ticks = data
      .map(({ x }, i) => {
        if (i % xAxisTitleInterval === 0) {
          return x;
        }
        return undefined;
      })
      .filter(i => !!i);
  let lineIndex = 0,
    lineColors: string[] = [],
    legendItems: any[] = [],
    legendDictionary: IDictionary<boolean> = {};
  for (const { name } of data) {
    if (!legendDictionary[name]) legendDictionary[name] = true;
  }
  // __legendItems.push(lineName);
  Object.getOwnPropertyNames(legendDictionary).map((name, i) => {
    legendItems.push({
      name,
      marker: 'circle',
      fill: _colors[i],
      checked: true,
    });
  });
  lineIndex = legendItems.length;
  if (_colors.length > lineIndex) {
    lineColors = _colors.slice(lineIndex);
  } else {
    // TODO: 需要考虑柱状图分组大于颜色组的处理逻辑
    lineColors = _colors;
  }
  if (lineName) {
    legendItems.push({
      name: lineName,
      marker: 'circle',
      fill: lineColors[0],
      offsetX: 24,
      checked: true,
    });
  }

  chart.source(data);
  chart.legend({
    custom: true,
    // itemWidth: null,
    items: legendItems,
    // items: [
    //   {
    //     name: '单位: 万元',
    //     fill: '#000',
    //     marker: { symbol: 'square', fillOpacity: 0 },
    //     checked: false,
    //   },
    //   ...legendItems,
    // ],
  });
  chart.scale('x', {
    ticks,
  });

  chart.axis('x', {
    label: {
      rotate: Math.PI / (180 / rotate),
      textAlign: rotate > 0 ? 'start' : 'center',
      textBaseline: 'middle',
    },
    ticks: data
      .map(({ x }, i) => {
        if (i % xAxisTitleInterval === 0) {
          return x;
        }
        return undefined;
      })
      .filter(i => !!i),
  });
  // chart.axis('y', {});
  chart.axis('z', { grid: null });
  chart.tooltip({
    custom: true,
    showCrosshairs: false,
    onChange: function onChange(ev: any) {
      store.dispatch('updateTooltip', {
        tooltipItems: ev.items.map(item => {
          if (item.name == 'z') item.name = lineName;
          return item;
        }),
        tooltipStyle: {
          opacity: 1,
          // left: currentData.x - tooltipEl.outerWidth() / 2,
          // top: currentData.y - tooltipEl.outerHeight() - 15,
        },
        tooltipStyleText: 'opacity: 1;',
      });
    },
    onHide: function onHide() {
      store.dispatch('updateTooltip', {
        tooltipStyle: { opacity: 0 },
        tooltipStyleText: 'opacity: 0;',
      });
    },
  });

  chart
    .interval()
    .position('x*y')
    .color('name')
    .adjust({
      type: 'dodge',
      marginRatio: 0.1, // 设置分组间柱子的间距
    });
  if (lineName) {
    chart
      .line()
      .position('x*z')
      .color(lineColors);
    chart
      .point()
      .position('x*z')
      .color(lineColors);
  }

  chart.render();
}

function drawChart2bar(
  chart: Chart,
  store: Store<IChartState>,
  data: any[],
  config: IChartBarConfig,
) {
  const { legend = { show: true }, rotate = 0, xAxisTitleInterval = 1 } = config,
    ticks = data
      .map(({ x }, i) => {
        if (i % xAxisTitleInterval === 0) {
          return x;
        }
        return undefined;
      })
      .filter(i => !!i);

  chart.source(data);
  chart.scale('x', {
    ticks,
  });
  chart.axis('x', {
    label: {
      rotate: Math.PI / (180 / rotate),
      textAlign: rotate > 0 ? 'start' : 'center',
      textBaseline: 'middle',
    },
  });

  if (!legend.show) {
    chart.legend(false);
  }

  chart.tooltip({
    custom: true,
    showCrosshairs: false,
    onChange: function onChange(ev: any) {
      store.dispatch('updateTooltip', {
        tooltipItems: ev.items,
        tooltipStyle: {
          opacity: 1,
          // left: currentData.x - tooltipEl.outerWidth() / 2,
          // top: currentData.y - tooltipEl.outerHeight() - 15,
        },
        tooltipStyleText: 'opacity: 1;',
      });
    },
    onHide: function onHide() {
      store.dispatch('updateTooltip', {
        tooltipStyle: { opacity: 0 },
        tooltipStyleText: 'opacity: 0;',
      });
    },
  });

  chart
    .interval()
    .position('x*y')
    .color('name')
    .adjust({
      type: 'dodge',
      marginRatio: 0.05, // 设置分组间柱子的间距
    });
  chart.render();
}

function drawChart2line(
  chart: Chart,
  store: Store<IChartState>,
  data: any[],
  config: IChartLineConfig,
) {
  const { rotate = 0, xAxisTitleInterval = 1 } = config,
    ticks = data
      .map(({ x }, i) => {
        if (i % xAxisTitleInterval === 0) {
          return x;
        }
        return undefined;
      })
      .filter(i => !!i);

  chart.source(data);
  chart.scale('x', {
    ticks,
  });

  chart.axis('x', {
    label: {
      rotate: Math.PI / (180 / rotate),
      textAlign: rotate > 0 ? 'start' : 'center',
      textBaseline: 'middle',
    },
  });

  chart.tooltip({
    custom: true,
    showCrosshairs: false,
    onChange: function onChange(ev: any) {
      store.dispatch('updateTooltip', {
        tooltipItems: ev.items,
        tooltipStyle: {
          opacity: 1,
          // left: currentData.x - tooltipEl.outerWidth() / 2,
          // top: currentData.y - tooltipEl.outerHeight() - 15,
        },
        tooltipStyleText: 'opacity: 1;',
      });
    },
    onHide: function onHide() {
      store.dispatch('updateTooltip', {
        tooltipStyle: { opacity: 0 },
        tooltipStyleText: 'opacity: 0;',
      });
    },
  });
  chart
    .line()
    .position('x*y')
    .color('name');
  chart.point().position('x*y');

  chart.render();
}

function drawChart2pie(chart: Chart, data: any[], config: IChartPieConfig) {
  chart.source(data.map(row => ({ y: row.y, name: row.x, const: row.name })));
  chart.coord('polar', {
    transposed: true,
    radius: 0.6,
    innerRadius: 0.5,
  });

  chart.axis(false);
  chart.legend(false);
  chart.tooltip(false);

  chart
    .interval()
    .position('const*y')
    .adjust('stack')
    .color('name');

  chart.pieLabel({
    sidePadding: 15,
    activeShape: true,
    label1(data, color) {
      return {
        text: data.name,
        fill: color,
      };
    },
    label2: data => {
      const { unit, unitPosition = 'left' } = {} as any;
      let text = '';
      if (unit && unitPosition === 'left') {
        text += unit;
      }
      text += data.y;
      if (unit && unitPosition === 'right') {
        text += unit;
      }
      return {
        text,
        fill: '#999',
        fontWeight: 'bold',
      };
    },
  });
  chart.render();
}

function drawChart2basepie(chart: Chart, data: any[], config: IChartBasePieConfig) {
  let dataSum = 0;
  data.map(row => {
    dataSum += row.y;
  });
  chart.source(data.map(row => ({ y: row.y, name: row.x, const: row.name })));
  chart.coord('polar', {
    transposed: true,
    radius: 1,
  });

  chart.axis(false);
  chart.legend({
    position: 'bottom',
    itemFormatter: function itemFormatter(val) {
      return (
        val +
        '  ' +
        String(Math.floor(data.find(row => row.x == val).y * 100) / 100).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ',',
        )
      );
    },
  });
  chart.tooltip(false);

  chart
    .interval()
    .position('const*y')
    .adjust('stack')
    .color('name');

  chart.pieLabel({
    sidePadding: 10,
    lineHeight: 20,
    inflectionOffset: 5,
    label1: function label1(item, color) {
      return {
        text: ((item.y / dataSum) * 100).toFixed(2) + '%',
        fill: color,
      };
    },
  });
  chart.render();
}

function drawChart2circleprogress(chart: Chart, data: any[], config: IChartCircleProgressConfig) {
  const { AntV } = globalData;
  const { Shape, G, Util, Global } = AntV;
  const Vector2 = G.Vector2;
  const { name } = config,
    progressVal = data[0] ? data[0].y : 0;

  // 极坐标下带圆角的柱子，只对极坐标生效
  Shape.registerShape('interval', 'polar-tick', {
    draw: function draw(cfg, container) {
      const points = this.parsePoints(cfg.points);
      const style = Util.mix(
        {
          stroke: cfg.color,
        },
        Global.shape.interval,
        cfg.style,
      );

      let newPoints = points.slice(0);
      if (this._coord.transposed) {
        newPoints = [points[0], points[3], points[2], points[1]];
      }

      const center = cfg.center;
      const x = center.x,
        y = center.y;

      const v = [1, 0];
      const v0 = [newPoints[0].x - x, newPoints[0].y - y];
      const v1 = [newPoints[1].x - x, newPoints[1].y - y];
      const v2 = [newPoints[2].x - x, newPoints[2].y - y];

      let startAngle = Vector2.angleTo(v, v1);
      let endAngle = Vector2.angleTo(v, v2);
      const r0 = Vector2.length(v0);
      const r = Vector2.length(v1);

      if (startAngle >= 1.5 * Math.PI) {
        startAngle = startAngle - 2 * Math.PI;
      }

      if (endAngle >= 1.5 * Math.PI) {
        endAngle = endAngle - 2 * Math.PI;
      }

      const lineWidth = r - r0;
      const newRadius = r - lineWidth / 2;

      return container.addShape('Arc', {
        className: 'interval',
        attrs: Util.mix(
          {
            x,
            y,
            startAngle,
            endAngle,
            r: newRadius,
            lineWidth,
            lineCap: 'round',
          },
          style,
        ),
      });
    },
  });

  chart.source(data, {
    y: {
      max: 100,
      min: 0,
    },
  });

  chart.axis(false);
  chart.tooltip(false);
  chart.coord('polar', {
    transposed: true,
    innerRadius: 0.9,
  });
  chart.guide().arc({
    start: [0, 0],
    end: [1, 99.98],
    top: false,
    style: {
      lineWidth: 15,
      stroke: '#ccc',
    },
  });
  let progress;
  if (name) {
    chart.guide().text({
      position: ['51%', '60%'],
      content: name,
      style: {
        fontSize: 12,
        fill: '#878787',
      },
    });
    progress = chart.guide().text({
      position: ['51%', '45%'],
      content: '0.00%',
      style: {
        fontSize: 28,
        fill: '#000',
        fontWeight: '500',
      },
    });
  } else {
    progress = chart.guide().text({
      position: ['51%', '50%'],
      content: '0.00%',
      style: {
        fontSize: 24,
        fill: '#1890ff',
      },
    });
  }

  chart
    .interval()
    .position('x*y')
    .size(15)
    .shape('polar-tick')
    .animate({
      appear: {
        duration: 1200,
        easing: 'cubicIn',
        animation: function animation(shape, animateCfg) {
          const startAngle = shape.attr('startAngle');
          const endAngle = shape.attr('endAngle');
          shape.attr('endAngle', startAngle);
          shape
            .animate()
            .to(
              Util.mix(
                {
                  attrs: {
                    endAngle,
                  },
                },
                animateCfg,
              ),
            )
            .onUpdate(function(frame) {
              progress.content = NumberHelper.format(frame * progressVal, '#,##0.00%');
              progress.repaint();
            });
        },
      },
    });

  // chart
  //   .interval()
  //   .position('x*expect')
  //   .size(20)
  //   .color('#ccc')
  //   .animate(false); // 背景条
  // chart
  //   .interval()
  //   .position('x*y')
  //   .shape('polar-tick')
  //   .size(20); // 实际进度

  chart.render();
}
