import Store from '@mjcloud/redux';
import Reduce from './reduce';
import { DataModeEnum } from '@mjcloud/types';
import SelectExtendStore from './extendStore';
import { ValueDataInstanceBase } from '@mjcloud/instance';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';

export default class Select extends ValueDataInstanceBase {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  __createStore() {
    return new Store({
      id: this.id,
      reduce: Reduce,
      extendStore: new SelectExtendStore(this),
    });
  }

  getDataMode() {
    return DataModeEnum.all;
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  setSelectedRows(rows: any[]) {
    if (rows && rows.length > 0) {
      this.store.dispatch('updateValue', {
        text: rows[0].text,
        value: rows[0].id,
        actionSourceSign: this.store,
      });
    }
  }
}
