import React from 'react';
import { Result } from 'antd';
import { Screen } from './util';
import ExtraPage from './extra';
import globalData from '@mjcloud/global-data';
import { ObjectHelper } from '@mjcloud/utils';
import { AppControlBase } from '../../app';
import RouterHelper from '@mjcloud/router-helper';
import IndexLayout from './layouts/IndexLayout';
import MobileLayout from './layouts/MobileLayout';
import AddressBookAPI from './components/AddressBookAPI';

const Error500: React.FC<{
  showInDev?: boolean;
  errorMessage: string;
}> = ({ showInDev, errorMessage }) =>
  showInDev ? (
    <Result icon={<img src="/images/development.svg" />} title="页面开发中，敬请期待……" />
  ) : (
    <Result status="500" subTitle={errorMessage} />
  );

class IndexPage extends AppControlBase {
  render() {
    const { isMobile } = globalData.rbCore;
    const { appInfo, Template, pageKey } = this.state,
      { showInDev, errorMessage } = this.state,
      moduleId = RouterHelper.getModuleId(this.props);
    let { hideNavigation } = ObjectHelper.search2params(window.location.search);
    hideNavigation = hideNavigation == '1';
    if (moduleId === globalData.DataCockpit) hideNavigation = true;
    if (!appInfo && errorMessage)
      return <Error500 showInDev={showInDev} errorMessage={errorMessage} />;
    if (appInfo) {
      const children = errorMessage ? (
        <Error500 showInDev={showInDev} errorMessage={errorMessage} />
      ) : Template && pageKey ? (
        <Template id={pageKey} pageKey={pageKey} parentId={pageKey} />
      ) : null;

      if (hideNavigation) {
        return children;
      }
      if (isMobile) {
        return <MobileLayout>{moduleId === '_extra' ? <ExtraPage /> : children}</MobileLayout>;
      }
      return <IndexLayout config={appInfo}>{children}</IndexLayout>;
    }
    return <Screen />;
  }
}

export default IndexPage;
