import { getInstance } from './util';

export default {
  props: {
    /**
     * 当前控件索引Id
     * @type {string} controlId
     */
    controlId: undefined,
    /**
     * 当前控件的父级控件Id
     * @type {string} parentId
     *
     * 若`parentId == pageKey`即父级控件为页面，反之则表示父级控件为容器控件
     */
    parentId: undefined,
    /**
     * @type {string} pageKey - 页面索引Id
     */
    pageKey: undefined,
  },
  didMount() {
    // didMount 为渲染后回调，此时页面已经渲染，通常在这里请求服务端数据比较合适。
    const { controlId, parentId, pageKey } = this.props;

    if (controlId && parentId && pageKey) {
      this.instance = getInstance({ pageKey, parentId, controlId });
      this.setData(this.instance.store.state);
      this.instance.store.bindViewListen((state, cb) => {
        this.setData(state);
        cb();
      });
    } else {
      console.error(`组件${this.data.$uiName}传入参数缺少 controlId、parentId、pageKey`);
    }
  },
} as any;
