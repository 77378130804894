import {
  PageModeEnum,
  ControlModifyModeEnum,
  ControlDisplayModeEnum,
  ControlDisabledModeEnum,
  ControlDigestDisplayModeEnum,
} from '@mjcloud/types';

function pageMode2number(mode: PageModeEnum): number {
  return mode;
  // switch (pageMode) {
  //   case PageModeEnum.add:
  //     return 1;
  //   case PageModeEnum.modify:
  //     return 2;
  //   case PageModeEnum.look:
  //     return 4;
  //   case PageModeEnum.none:
  //     return 0;
  //   default:
  //     let _pageMode = Number(pageMode);
  //     if (isNaN(_pageMode)) _pageMode = 0;
  //     return _pageMode;
  // }
}

function disabledMode2number(mode: ControlDisabledModeEnum): number {
  return mode;
  // switch (disabledMode) {
  //   case ControlDisabledModeEnum.none:
  //     return 0;
  //   case ControlDisabledModeEnum.add:
  //     return 1;
  //   case ControlDisabledModeEnum.modify:
  //     return 2;
  //   case ControlDisabledModeEnum.look:
  //     return 4;
  //   case ControlDisabledModeEnum['add&modify']:
  //     return 3;
  //   case ControlDisabledModeEnum['add&look']:
  //     return 5;
  //   case ControlDisabledModeEnum['modify&look']:
  //     return 6;
  //   case ControlDisabledModeEnum.all:
  //     return 7;
  //   default:
  //     return 0;
  // }
}

function displayMode2number(mode: ControlDisplayModeEnum): number {
  return mode;
  // switch (displayMode) {
  //   case ControlDisplayModeEnum.none:
  //     return 0;
  //   case ControlDisplayModeEnum.add:
  //     return 1;
  //   case ControlDisplayModeEnum.modify:
  //     return 2;
  //   case ControlDisplayModeEnum.look:
  //     return 4;
  //   case ControlDisplayModeEnum['add&modify']:
  //     return 3;
  //   case ControlDisplayModeEnum['add&look']:
  //     return 5;
  //   case ControlDisplayModeEnum['modify&look']:
  //     return 6;
  //   case ControlDisplayModeEnum.all:
  //     return 7;
  //   default:
  //     return 7;
  // }
}

function modifyMode2number(mode: ControlModifyModeEnum): number {
  return mode;
  // switch (mode) {
  //   case ControlModifyModeEnum.none:
  //     return 0;
  //   case ControlModifyModeEnum.add:
  //     return 1;
  //   case ControlModifyModeEnum.modify:
  //     return 2;
  //   case ControlModifyModeEnum.look:
  //     return 4;
  //   case ControlModifyModeEnum['add&modify']:
  //     return 3;
  //   case ControlModifyModeEnum['add&look']:
  //     return 5;
  //   case ControlModifyModeEnum['modify&look']:
  //     return 6;
  //   case ControlModifyModeEnum.all:
  //     return 7;
  //   default:
  //     return 7;
  // }
}

function digestDisplay2number(mode: ControlDigestDisplayModeEnum): number {
  return mode;
  // switch (mode) {
  //   case ControlDigestDisplayModeEnum.none:
  //     return 0;
  //   case ControlDigestDisplayModeEnum.add:
  //     return 1;
  //   case ControlDigestDisplayModeEnum.modify:
  //     return 2;
  //   case ControlDigestDisplayModeEnum.look:
  //     return 4;
  //   case ControlDigestDisplayModeEnum['add&modify']:
  //     return 3;
  //   case ControlDigestDisplayModeEnum['add&look']:
  //     return 5;
  //   case ControlDigestDisplayModeEnum['modify&look']:
  //     return 6;
  //   case ControlDigestDisplayModeEnum.all:
  //     return 7;
  //   default:
  //     return 4;
  // }
}

export default class PageModeHelper {
  public static disabledMode2boolean(
    pageMode: PageModeEnum,
    disabledMode: ControlDisabledModeEnum = ControlDisabledModeEnum.none,
  ) {
    const _disabledMode = disabledMode2number(disabledMode);
    const _pageMode = pageMode2number(pageMode);
    if (_disabledMode === 0) return false;
    if (_disabledMode === 7) return true;
    if (_pageMode === 0) return false;
    return (_disabledMode & _pageMode) === _pageMode;
  }

  public static displayMode2boolean(
    pageMode: PageModeEnum,
    displayMode: ControlDisplayModeEnum = ControlDisplayModeEnum.all,
  ) {
    const _displayMode = displayMode2number(displayMode);
    const _pageMode = pageMode2number(pageMode);
    if (_displayMode === 0) return false;
    if (_displayMode === 7) return true;
    if (_pageMode === 0) return false;
    return (_displayMode & _pageMode) === _pageMode;
  }

  public static digestDisplayMode2boolean(
    pageMode: PageModeEnum,
    digestDisplayMode: ControlDigestDisplayModeEnum = ControlDigestDisplayModeEnum.look,
  ) {
    const _displayMode = digestDisplay2number(digestDisplayMode);
    const _pageMode = pageMode2number(pageMode);
    if (_displayMode === 0) return false;
    if (_displayMode === 7) return true;
    if (_pageMode === 0) return false;
    return (_displayMode & _pageMode) === _pageMode;
  }

  public static modifyMode2boolean(
    pageMode: PageModeEnum,
    modifyMode: ControlModifyModeEnum = ControlModifyModeEnum['add&modify'],
  ) {
    const _modifyMode = modifyMode2number(modifyMode);
    const _pageMode = pageMode2number(pageMode);
    if (_modifyMode === 0) return false;
    if (_modifyMode === 7) return true;
    if (_pageMode === 0) return true;
    return (_modifyMode & _pageMode) === _pageMode;
  }
}
