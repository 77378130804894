import Store from '@mjcloud/redux';
import ViewModelHelper from '@mjcloud/data-model';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { FormReduceBase } from '../common/form/reduce';
import { IFormState, FormInitialStateParams, IFormLoadedParams } from './typings';

class FormReduce extends FormReduceBase {
  initialState(store: Store<IFormState>, params: FormInitialStateParams) {
    const { initConfig, pageMode, sections, isReset } = params,
      { displayMode } = initConfig,
      { dataModel = ViewModelHelper.createViewModel() } = params,
      state: IFormState = {
        isReset,
        sections,
        tabIndex: 0,
        isFetching: false,
        config: initConfig,
        configIsFetching: false,
        dataSource: isReset ? store.state.dataSource : dataModel,
        display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      };

    return state;
  }

  loaded(store: Store<IFormState>, params: IFormLoadedParams) {
    if (params.dataModel) {
      return { ...store.state, isFetching: false };
    }
    const { dataSource } = store.state,
      { dataSource: _dataSource, dataState } = params;
    dataSource.initDatas(_dataSource, dataState);
    return { ...store.state, isFetching: false };
  }
}

export default new FormReduce();
