import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { InstanceBase } from '@mjcloud/instance';
import ButtonExtendStore from './extendStore';
import {
  IButtonConfig,
  IButtonState,
  ButtonEventType,
  ButtonActionType,
  IButtonConfigStartLoadParams,
} from './typings';

class Button extends InstanceBase<IButtonState, ButtonEventType, ButtonActionType> {
  __createStore() {
    return new Store<IButtonState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new ButtonExtendStore(this),
    });
  }

  initialState(initConfig: IButtonConfig) {
    this.store.dispatch<IButtonConfigStartLoadParams>('configStartLoad', {
      initConfig,
      address: this.page.address,
    });
  }

  get display() {
    if (!this.store.state.isAuthority) return false;
    return this.store.state.display;
  }

  set display(display: boolean) {
    this.store.dispatch('updateDisplay', { display });
  }

  get disable() {
    return this.store.state.disabled;
  }

  set disable(disabled: boolean) {
    this.store.dispatch('updateDisabled', { disabled });
  }

  get active() {
    return false;
  }

  set active(active: boolean) {}

  click() {
    if (this.store.state.loading) return;
    if (!this.display) return;
    if (this.disable) return;
    this.store.dispatch('clickAfter', {});
  }
}

export default Button;
