import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { DataModeEnum } from '@mjcloud/types';
import { ActionAfterEventArg } from '@mjcloud/redux';
import { ValueDataInstanceBase } from '@mjcloud/instance';
import { Filter, OperationTypeEnum, FilterTypeEnum } from '@mjcloud/utils';
import { ValueDataExtendStoreBase } from '@mjcloud/extend-store';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import {
  IAutoCompleteConfig,
  IAutoCompleteState,
  AutoCompleteEventType,
  AutoCompleteActionType,
} from './typings';

class AutoCompleteExtendStore extends ValueDataExtendStoreBase<string, AutoComplete> {
  protected isNeedNotificationPage = false;
  protected isFirstLoad = false;

  async handleStartLoadAfter(e: any) {
    if (!this.isFirstLoad) {
      this.isFirstLoad = true;
      e.newState.dispatch('loaded', {
        ...e.params,
        dataSource: { rows: [], count: 0, pageIndex: 1, pageSize: 10 },
      });
      return;
    }
    await super.handleStartLoadAfter(e);
  }

  handleUpdateOptionsAfter(e: ActionAfterEventArg<IAutoCompleteState>) {
    let filter: Filter;
    const { searchText, search } = e.newState.state;
    if (searchText) {
      filter = new Filter(FilterTypeEnum.or);
      for (var item of search) {
        filter.addCondition({
          left: item.left,
          right: searchText,
          op: OperationTypeEnum.like,
        });
      }
    } else {
      filter = Filter.EqualFilter;
    }
    this.instance.refresh({ filter, pageSize: 20 });
  }
}

class AutoComplete extends ValueDataInstanceBase<
  string,
  IAutoCompleteState,
  AutoCompleteEventType,
  AutoCompleteActionType
> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  getDataMode(): DataModeEnum {
    return DataModeEnum.pagination;
  }

  __createStore() {
    return new Store<IAutoCompleteState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new AutoCompleteExtendStore(this),
    });
  }

  initialState(initConfig: IAutoCompleteConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}

export default AutoComplete;
