/**
 * 间距基准
 */
export const SPACING = 8;

/**
 * 列表型容器分页高度
 */
export const PAGINATIONSIZE = 56;

/**
 * 列表型容器父级El最小可自适应高度
 */
export const PARENTMINHEIGHT = 240;

/**
 * 列表型容器默认最小高度
 */
export const SCROLLMINHEIGHT = 468;

export const COLOR = {
  /** 主要色 */
  PRIMARY: '#4e78b8',
  /** 红色  */
  RED: '#f5222d',
  /** 黑色 */
  BLACK: '#191f25',
  /** 白色 */
  WHITE: 'white',
  /** 次要色  */
  MINOR: '#D9D8D9',
};

export enum DevicesSizeEnum {
  xs = 1,
  sm = 2,
  md = 3,
  lg = 4,
  xl = 5,
  xxl = 6,
}

export function isDevicesSize(width: number) {
  if (width < 576) {
    return DevicesSizeEnum.xs;
  }

  if (width >= 1600) {
    return DevicesSizeEnum.xxl;
  } else if (width >= 1200) {
    return DevicesSizeEnum.xl;
  } else if (width >= 992) {
    return DevicesSizeEnum.lg;
  } else if (width >= 768) {
    return DevicesSizeEnum.md;
  } else if (width >= 576) {
    return DevicesSizeEnum.sm;
  }
  return DevicesSizeEnum.xxl;
}
