import Store from "@mjcloud/redux";
import Reduce from "./reduce";
import { DataModeEnum } from "@mjcloud/types";
import AttendanceExtendStore from "./extendStore";
import { DataInstanceBase } from "@mjcloud/instance";

export default class Attendance extends DataInstanceBase {
  
  __createStore() {
    return new Store({
      id: this.id,
      reduce: Reduce,
      extendStore: new AttendanceExtendStore(this)
    });
  }

  getDataMode() {
    return DataModeEnum.one;
  }

  initialState(initConfig) {
    this.store.dispatch("configStartLoad", {
      initConfig
    });
  }
}
