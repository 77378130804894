import { ITabsConfig } from './typings';

const config: ITabsConfig = {
  items: [],
  isShowTabPane: true,
  activeKey: undefined,
  displayMode: undefined,
};

export default config;
