import React from 'react';
import { Modal, Input } from 'antd';

interface InputPlainResult {
  text: string;
  oper: 'ok' | 'cancel';
}

export default function(placeholder: string, text = '') {
  return new Promise<InputPlainResult>((resolve, fail) => {
    const modal = Modal.confirm({
      // title: placeholder,
      icon: <React.Fragment />,
      onOk() {
        resolve({ text, oper: 'ok' });
      },
      onCancel() {
        resolve({ text: '', oper: 'cancel' });
      },
    });
    const handChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      text = event.currentTarget.value;
      modal.update({
        content: <Input placeholder={placeholder} value={text} onChange={handChange} />,
      });
    };
    modal.update({
      content: <Input placeholder={placeholder} value={text} onChange={handChange} />,
    });
  });
}
