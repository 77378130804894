import Page from '@mjcloud/page';
import ComponentBase from './base';
import { IDictionary } from '@mjcloud/types';
import { ExceptionHelper } from '@mjcloud/exception';
import { EventListening, FormulaHelper } from '@mjcloud/utils';
import { IEventArg } from '@mjcloud/utils/dist/events/eventListening';
import {
  IViewModel,
  IViewModelValueChangeEventDataArgs,
  ViewModelTypeEnum,
  IViewModelCollection,
  IViewModelRowValueChangeEventDataArgs,
  IViewModelObject,
} from '@mjcloud/data-model';

export default class FormulaLinkage extends ComponentBase {
  private _config: IDictionary = {};

  constructor(id: string, page: Page, data: IDictionary, extra: IDictionary) {
    super(id, page, data, extra);

    let controlId, control, dataModel: IViewModel, config;
    for (controlId in data) {
      control = page.findControl(controlId);
      if (!control) {
        throw ExceptionHelper.notFoundObjectException(
          '控件',
          controlId,
          '公式初始化时没有找到配置的控件[' + controlId + ']。',
        );
      }
      dataModel = control.dataModel;
      if (!dataModel) {
        throw ExceptionHelper.notFoundObjectException(
          '属性',
          'dataModel',
          '公式初始化时配置的控件[' + controlId + ']没有找到[dataModel]属性。',
        );
      }
      this._config[controlId] = this.initControlConfig(data[controlId]);
      switch (dataModel._viewModelType) {
        case ViewModelTypeEnum.viewModel:
          dataModel.bind('valueChange', this.bindValueChange(controlId));
          break;
        case ViewModelTypeEnum.viewModelCollection:
          dataModel.bind('rowValueChange', this.bindRowValueChange(controlId));
          break;
      }
    }
  }

  private bindValueChange(controlId: string) {
    return e => {
      this.handValueChange(controlId, e);
    };
  }

  private bindRowValueChange(controlId: string) {
    return e => {
      this.handRowValueChange(controlId, e);
    };
  }

  private handValueChange(
    controlId,
    e: IEventArg<IViewModelObject, IViewModelValueChangeEventDataArgs>,
  ) {
    if (e.eventSourceSign == this || !e.data.values) {
      return;
    }

    let sourceKeys = {};
    for (let key in e.data.values) {
      sourceKeys[key] = true;
    }
    let updateValueHelper = new UpdateValueHelper(
      e.sender,
      this,
      e.sender,
      this._config[controlId],
      sourceKeys,
    );
    updateValueHelper.update();
  }

  private handRowValueChange(
    controlId,
    e: IEventArg<IViewModelCollection, IViewModelRowValueChangeEventDataArgs>,
  ) {
    if (e.eventSourceSign == this || !e.data.values) {
      return;
    }

    let sourceKeys = {};
    for (let key in e.data.values) {
      sourceKeys[key] = true;
    }
    let updateValueHelper = new UpdateValueHelper(
      e.sender,
      this,
      e.data.row,
      this._config[controlId],
      sourceKeys,
    );
    updateValueHelper.update();
  }

  private initControlConfig(data: IDictionary) {
    let config = {},
      formula,
      targetKey,
      sourceKey,
      sourceFormulas: Array<any>;

    for (targetKey in data) {
      formula = data[targetKey].formula;
      const additionObject = {
        page: this.page,
        logic: this.page.logic,
        template: this.page.template,
      };
      formula = {
        formula: FormulaHelper.create(
          formula,
          data[targetKey].precision,
          data[targetKey].unround,
          additionObject,
        ),
        targetKey,
      };
      for (sourceKey of formula.formula.getPartake()) {
        sourceFormulas = config[sourceKey];
        if (!sourceFormulas) {
          sourceFormulas = [];
          config[sourceKey] = sourceFormulas;
        }
        sourceFormulas.push(formula);
      }
    }
    return config;
  }
}

class UpdateValueHelper {
  constructor(
    private sender,
    private eventSourceSign,
    private dataModel: IViewModelObject,
    private configs: IDictionary,
    private sourceKeys: IDictionary,
  ) {}

  update() {
    for (let sourceKey in this.sourceKeys) {
      let formulas = this.configs[sourceKey];
      if (!formulas) {
        continue;
      }

      this.updateLinkage(formulas, []);
    }
  }

  private updateLinkage(formulas: Array<any>, updateKeys: Array<String>) {
    let targetKey, targetFormulas, values, e, childFormulas;
    for (let targetFormula of formulas) {
      targetKey = targetFormula.targetKey;
      if (this.sourceKeys[targetKey] || updateKeys.indexOf(targetKey) >= 0) {
        continue;
      }

      if (!targetFormulas) {
        targetFormulas = {};
      }
      targetFormulas[targetKey] = targetFormula.formula;
      
      if(!e){
        e = EventListening.getHandlerArg(this.sender, { row: this.dataModel });
      }
      values = {};
      values[targetKey] = targetFormulas[targetKey].calc(this.dataModel, {}, { e });
      this.dataModel.update(values, this.eventSourceSign);

      childFormulas = this.configs[targetKey];
      if (childFormulas) {
        updateKeys.push(targetKey);
        this.updateLinkage(childFormulas, updateKeys);
        updateKeys.pop();
      }
    }
  }
}
