import { IAccordionConfig } from './typings';

const config: IAccordionConfig = {
  filters: [],
  content: { items: [] },
  collapse: { items: [] },
  accordion: true,
  titleFieldName: 'title',
};

export default config;
