import React from 'react';
import GridInstance from '..';
import ControlBase from '../../base';
import { Row, Col } from 'antd';
import globalData from '@mjcloud/global-data';
import { IGridState } from '../typings';

import styles from './index.less';

class GridControl extends ControlBase<IGridState, GridInstance> {
  renderContent() {
    const { ControlFactory } = globalData;
    const { sections } = this.state;
    return (
      <div className={styles.grid}>
        {sections.map(section => {
          const { cols = 2, title, items } = section;
          return (
            <Row>
              {!!title && <div className={styles.title}>{title}</div>}
              {items.map(item => {
                const { colspan = 1, ...controlProps } = item;
                let span = (24 / cols) * colspan;
                span = span > 24 ? 24 : span;
                return (
                  <Col key={item.id} span={span}>
                    <ControlFactory {...controlProps} />
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
    );
  }
}
export default GridControl;
