import Workbench from '.';
import { DataExtendStoreBase } from '@mjcloud/extend-store';
import { ActionBeforeEventArg } from '@mjcloud/redux';
import { IWorkbenchLoadedParams, IWorkbenchInitialStateParams } from './typings';

export default class WorkbenchExtendStore extends DataExtendStoreBase<Workbench> {
  protected isNeedNotificationPage = true;

  handleInitialStateBefore(e: ActionBeforeEventArg<IWorkbenchInitialStateParams>) {
    e.params.createWorkbenchPart = this.instance.__createWorkbenchPart.bind(this.instance);
  }

  async handleLoadedBefore(e: ActionBeforeEventArg<IWorkbenchLoadedParams>) {
    const { rows } = e.params.dataSource;
    e.params.parts = rows.map((part: any) => {
      if (part.name === 'Placeholder') {
        return part;
      } else {
        const _part = this.instance.__createWorkbenchPart(part);
        return { ...part, id: _part['id'] };
      }
    });
  }
}
