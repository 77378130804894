import { useRef, useEffect } from 'react';
import { Chart } from '@antv/g2';

export interface PlotConfig {
  memoData?: string | number | any[];
  data?: any;
  onlyChangeData?: boolean;
}

export default function useChart(config: PlotConfig, updateConfig: (chart: Chart) => void) {
  const chart = useRef<Chart>();
  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (chart.current) {
      if (config.onlyChangeData) {
        chart.current.changeData(config.data || []);
      } else {
        updateConfig(chart.current);
        chart.current.render();
      }
    }
  }, [config.memoData ? config.memoData : JSON.stringify(config)]);

  useEffect(() => {
    if (!container.current) {
      return () => null;
    }
    const containerDOM = container.current;
    const chartInstance = new Chart({
      container: containerDOM,
      width: containerDOM.offsetWidth,
      height: containerDOM.offsetHeight,
    });
    chart.current = chartInstance;
    chartInstance.data(config.data || []);
    updateConfig(chartInstance);
    chartInstance.render();
    return () => chartInstance.destroy();
  }, []);

  return {
    chart,
    container,
  };
}
