import Store from '@mjcloud/redux';
import { NumberHelper } from '@mjcloud/utils';
import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { INumberConfig, INumberState, INumberInitialStateParams } from './typings';

class NumberReduce extends ValueReduceBase<number, INumberState> {
  initialState(store: Store<INumberState>, params: INumberInitialStateParams) {
    const { initConfig, pageMode } = params,
      { value } = store.state,
      { title, autoFocus, modifyMode } = initConfig,
      { format, max: _max, min: _min, step: _step, displayMode, disabledMode } = initConfig,
      { placeholder = `请输入${title || ''}` } = initConfig,
      max = typeof _max === 'string' ? parseInt(_max) : _max,
      min = typeof _min === 'string' ? parseInt(_min) : _min,
      step = typeof _step === 'string' ? parseInt(_step) : _step;
    let text: string | number = value;
    if (text != null) {
      text = NumberHelper.format(value, format);
    }
    const state: INumberState = {
      max,
      min,
      step,
      text,
      title,
      value,
      format,
      autoFocus,
      placeholder,
      tabIndex: 0,
      isFocus: false,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    return state;
  }

  updateFocus(store: Store<INumberState>, params) {
    return { ...store.state, isFocus: params.isFocus };
  }
}

export default new NumberReduce();
