import React from 'react';
import Store from '@mjcloud/redux';
import { List } from 'antd-mobile';
import FormItemControl from './FormItem';
import { Form, getInstance } from '@mjcloud/mcontrols';
import {
  IFormSectionState,
  ISectionItemProps,
} from '@mjcloud/mcontrols/dist/controls/form/section/typings';

export default class FormSectionControl extends React.Component<
  ISectionItemProps,
  IFormSectionState
> {
  private store: Store<IFormSectionState>;
  constructor(props: ISectionItemProps) {
    super(props);
    const { id, pageKey, parentId, controlId } = this.props;
    const parent = getInstance<Form>({ pageKey, parentId, controlId });
    this.store = parent.sections[id];
    this.state = this.store.state;
    this.store.bindViewListen(this.handStateChange);
  }

  componentWillUnmount() {
    this.store.unbindViewListen(this.handStateChange);
  }

  private handStateChange = (state: IFormSectionState, cb?: () => void) => {
    this.setState(state, cb);
  };

  render() {
    const { items, showTitle, title } = this.state;
    if (!this.state.display) return null;
    return (
      <List renderHeader={showTitle ? title : undefined}>
        {items.map(item => (
          <FormItemControl key={item.id} {...item} />
        ))}
      </List>
    );
  }
}
