import { ExceptionHelper } from '@mjcloud/exception';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { RequiredTypeEnum } from '@mjcloud/types';

class FormItemReduce {
  initialState(store, params) {
    const { initConfig, pageMode, children } = params;
    const { field, requiredType = RequiredTypeEnum.normal, modifyMode, tip, tipIcon } = initConfig,
      { message, max, min, validationType, whitespace, len, enums, pattern } = initConfig,
      id = initConfig.id || '',
      colspan = initConfig.colspan == null ? 1 : initConfig.colspan,
      index = initConfig.index == null ? 0 : initConfig.index,
      title = initConfig.title || '',
      showTitle = initConfig.title ? true : false;
    let _enum, _pattern;
    if (enums) {
      try {
        _enum = JSON.parse(enums.replace(/'/g, '"'));
      } catch (error) {
        const ex = ExceptionHelper.jsonParseException(
          `FormItemReduce ${enums} 不是json字符串`,
          error.stack,
        );
        ExceptionHelper.dispose(ex);
      }
    }
    if (pattern) {
      try {
        _pattern = new RegExp(JSON.parse(pattern.replace(/'/g, '"')));
      } catch (error) {
        const ex = ExceptionHelper.jsonParseException(
          `FormItemReduce ${pattern} 不是json字符串`,
          error.stack,
        );
        ExceptionHelper.dispose(ex);
      }
    }
    let state = {
      id,
      max,
      min,
      len,
      tip,
      index,
      title,
      tipIcon,
      colspan,
      message,
      children,
      showTitle,
      whitespace,
      enum: _enum,
      requiredType,
      tabIndex: -1,
      help: undefined,
      pattern: _pattern,
      field: field || id,
      config: initConfig,
      type: validationType,
      configIsFetching: false,
      validateStatus: undefined,
      nodeName: initConfig.nodeName,
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, initConfig.displayMode),
    };
    return state;
  }

  updateRequiredType(store, params) {
    if (params.requiredType === store.state.requiredType) {
      return store.state;
    }
    const state = { ...store.state, requiredType: params.requiredType };
    if (params.requiredType !== RequiredTypeEnum.required) {
      state.help = undefined;
      state.validateStatus = undefined;
    }
    return state;
  }

  updateReadonly(store, params) {
    if (params.readonly === store.state.readonly) {
      return store.state;
    }
    const state = { ...store.state, readonly: params.readonly };
    return state;
  }

  updateDisplay(store, params) {
    if (params.display === store.state.display) {
      return store.state;
    }
    const state = { ...store.state, display: params.display };
    return state;
  }

  updateTitle(store, params) {
    if (params.title === store.state.title) {
      return store.state;
    }
    const state = { ...store.state, title: params.title };
    return state;
  }

  validError(store, params) {
    const { errorHelp, actionSourceSign } = params;
    const state = {
      ...store.state,
      validateStatus: 'error',
      help: errorHelp,
      actionSourceSign,
    };
    return state;
  }

  validSuccess(store, params) {
    let state = store.state;
    if (state.validateStatus === undefined) return state;
    state = {
      ...state,
      validateStatus: undefined,
      help: undefined,
      actionSourceSign: null,
    };
    return state;
  }
}

export default new FormItemReduce();
