/**
 * 引入外部js辅助类
 */
export default class ImportLoader {
  private id: string;
  private protocol: string = '';

  /**
   * 初始化 ImportLoader
   * @param url 要引入的外部js文件
   * @param id 脚本ID，防止重复加载
   * @param protocol
   */
  constructor(private url: string, id: string = '', protocol?: string) {
    this.id = id;
    if (this.url.indexOf('//') === 0) {
      this.protocol = protocol || window.location.protocol;
      if (this.protocol.indexOf(':') === -1) {
        this.protocol += ':';
      }
    }
  }

  private buildScriptTag(src: string, id?: string) {
    if (document) {
      const script = document.createElement('script');
      if (id) script.id = id;
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.src = src;
      return script;
    }
    return undefined;
  }

  private getScriptPromise(src: string, id?: string) {
    const script = this.buildScriptTag(src, id);
    if (script) {
      const p = new Promise<any>((resolve, reject) => {
        script.onload = function() {
          // console.log('getScriptPromise', arguments);
          resolve(this);
        };
        script.onerror = function() {
          reject(arguments);
        };
      });
      document.body.appendChild(script);
      return p;
    }

  }

  private getMainPromise() {
    return this.getScriptPromise(`${this.protocol}${this.url}`, this.id);
  }

  /** 加载外部js文件 */
  async load() {
    if (typeof window === 'undefined') {
      return null;
    }
    if (document && document.getElementById(this.id)) return null;
    return this.getMainPromise();
  }
}
