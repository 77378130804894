import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from "@mjcloud/page-mode-helper";

class ToolbarReduce extends ReduceBase {
  initialState(store, params) {
    let { initConfig, items, pageMode } = params,
      { displayMode, cols = 1 } = initConfig,
      display = PageModeHelper.displayMode2boolean(pageMode, displayMode);
    return {
      cols,
      items,
      display,
      tabIndex: -1,
      config: initConfig,
      configIsFetching: false,
    };
  }

  updateChildrenDisplay(store, params) {
    const state = store.state,
      { items } = state,
      { index, display } = params;
    let isUpdate = false;
    if (items[index].display !== display) {
      items[index].display = display;
      isUpdate = true;
    }
    return isUpdate ? { ...state, items } : state;
  }
}

export default new ToolbarReduce();
