import React from 'react';
import TreeList from '..';
import { Tree } from 'antd';
import ControlBase from '../../base';
import { TreeProps } from 'antd/lib/tree';
import {
  ITreeListState,
  TreeListUpdateSelectedKeysParams,
  TreeListUpdateExpandedKeysParams,
} from '../typings';

import styles from './index.less';

class TreeListControl extends ControlBase<ITreeListState, TreeList> {
  handleClick = (e: React.MouseEvent<HTMLSpanElement>, node: any) => {
    const _rid = node.key,
      _disabled = node.disabled;
    if (_disabled) return;
    this.instance.store.dispatch<TreeListUpdateSelectedKeysParams>('updateSelectedKeys', {
      selectedKeys: [_rid.toString()],
      selectedRows: [node.data],
    });
  };

  handleReload = () => {
    this.instance.refresh();
  };

  handleExpand = (expandedKeys: React.Key[]) => {
    this.instance.store.dispatch<TreeListUpdateExpandedKeysParams>('updateExpandedKeys', {
      expandedKeys,
      autoExpandParent: false,
    });
  };

  loopData = (data: any[]) => {
    const { searchValue = '' } = this.state;
    return data.map(item => {
      const { _text, _disabled, _rid, _children } = item;
      const index = _text.indexOf(searchValue);
      const beforeStr = _text.substr(0, index);
      const afterStr = _text.substr(index + searchValue.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ color: '#f50' }}>{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{_text}</span>
        );
      if (_children) {
        return (
          <Tree.TreeNode
            title={title}
            key={_rid.toString()}
            selectable={false}
            disabled={_disabled}
            data={item}
          >
            {this.loopData(_children)}
          </Tree.TreeNode>
        );
      }
      return (
        <Tree.TreeNode
          title={title}
          key={_rid.toString()}
          selectable={false}
          disabled={_disabled}
          data={item}
        />
      );
    });
  };

  renderTree() {
    const { dataSource, expandedKeys, autoExpandParent, theme, selectedKeys } = this.state;
    const props: TreeProps = {
      expandedKeys,
      selectedKeys,
      showIcon: false,
      autoExpandParent,
      onExpand: this.handleExpand,
      // onSelect: this.handleClick,
      onClick: this.handleClick,
    };

    switch (theme) {
      case 'tree':
        return (
          <div id={this.props.id}>
            <Tree {...props}>{this.loopData(dataSource)}</Tree>
          </div>
        );
      case 'menu':
      default:
        return (
          <div id={this.props.id}>
            <Tree.DirectoryTree {...props} expandAction="doubleClick">
              {this.loopData(dataSource)}
            </Tree.DirectoryTree>
          </div>
        );
    }
  }

  renderContent() {
    const { title } = this.state;
    if (title) {
      return (
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{title}</div>
            {/* <Icon type="reload" onClick={this.handleReload} /> */}
          </div>
          <div className={styles.content}>{this.renderTree()}</div>
        </div>
      );
    }
    return this.renderTree();
  }
}
export default TreeListControl;
