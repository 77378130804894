import App from '../app';
import React from 'react';
import { ENV, APP_TYPE } from '@mjcloud/jsapi';
import PageHelper from '@mjcloud/page-helper';
import { PageModeEnum } from '@mjcloud/types';
import { ObjectHelper } from '@mjcloud/utils';
import RouterHelper from '@mjcloud/router-helper';
import { IAppState, IAppProps, IAppInitialStateParams, IAppRefreshParams } from '../typings';

export default abstract class AppControlBase extends React.Component<
  IAppProps,
  Partial<IAppState>
> {
  instance: App;
  constructor(props: IAppProps) {
    super(props);
    this.state = {};
    this.loginInit(props);
    this.instance = new App();
    RouterHelper._create(props);
    this.instance.store.bindViewListen(this.handStateChange);
    setTimeout(() => {
      this.instance.store.dispatch<IAppInitialStateParams>('initialState', {
        initConfig: this.props,
        pageMode: PageModeEnum.none,
      });
    }, 0);
  }

  shouldComponentUpdate(nextProps: Readonly<IAppProps>, nextState: Readonly<Partial<IAppState>>) {
    if (nextState !== this.state) return true;
    if (nextProps !== this.props) return true;
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<IAppProps>) {
    const moduleId = RouterHelper.getModuleId(this.props),
      nextModuleId = RouterHelper.getModuleId(nextProps),
      pageMode = RouterHelper.getPageMode(nextProps),
      address = {
        ...this.props.match.params,
        moduleId,
        pageId: RouterHelper.getPageId(this.props),
      },
      nextAddress = {
        ...nextProps.match.params,
        moduleId: nextModuleId,
        pageId: RouterHelper.getPageId(nextProps),
      },
      addressIsEqual = PageHelper.addressIsEqual(address, nextAddress);
    if (!addressIsEqual) {
      // 页面刷新
      const { query = {}, state = {} } = nextProps.location,
        params = { ...query, ...state, pageMode };
      this.instance.store.dispatch<IAppRefreshParams>('refresh', {
        ...nextAddress,
        moduleId: nextModuleId,
        action: nextProps.history.action,
        params,
      });
    }
    RouterHelper._onRouterChange(this.props, nextProps, addressIsEqual);
  }

  /**
   * 判断当前所在容器是否可登录
   */
  private loginInit(props: IAppProps) {
    const { history } = props,
      { state } = ObjectHelper.search2params(window.location.search);
    if (ENV.appType === APP_TYPE.WEB && state && state.search('wechat_redirect') === 0) {
      const appId = state.split('-')[1];
      history.replace(`/${appId}/scanlogin`);
    }
  }

  private handStateChange = (state: IAppState, cb?: () => void) => {
    this.setState(state, cb);
  };
}
