import GridEdit from '..';
import Store from '@mjcloud/redux';
import { IDictionary, RequiredTypeEnum, Size } from '@mjcloud/types';
import { AsyncValidator, DateHelper } from '@mjcloud/utils';
import TableCellBase from '../../common/table/cell';
import { IViewModelRow } from '@mjcloud/data-model';
import { ActionAfterEventArg } from '@mjcloud/redux';
import { IEditableCellBaseState, GridEditCellBaseActionType } from './typings';
import { ValidationError, ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { ITableCellBaseInitConfig } from '../../common/table/cell/typings';
import { IGridEditConfigItem } from '../typings';

export default abstract class EditableCellBase<
  S extends IEditableCellBaseState = IEditableCellBaseState,
  ActionType extends string = GridEditCellBaseActionType
> extends TableCellBase<GridEdit, S, ActionType> {
  protected validator: AsyncValidator | null = null;

  constructor(public id: string, public parent: GridEdit, protected config: IGridEditConfigItem) {
    super(id, parent, config);
  }

  protected __getInitConfig(params: ITableCellBaseInitConfig): IDictionary {
    const { id, title, modifyMode, validationType, requiredType } = this.config,
      required = requiredType === RequiredTypeEnum.required,
      { message, whitespace = required, len, min, max, enums, pattern } = this.config,
      rule = {
        len,
        min,
        max,
        enums,
        pattern,
        message,
        whitespace,
        requiredType,
        validationType,
        RequiredTypeEnum,
      };
    return { ...params, ...rule, modifyMode, id, title };
  }

  updateSize(rowId: number, size: Size) {
    const column = this.parent.__getCellColumnInfo(this.config.id);
    if (column && column.fixed) {
      const store: Store<S, GridEditCellBaseActionType> = this.stores[rowId] as any;
      if (store) store.dispatch('updateSize', { size });
    }
  }

  abstract getCellActiveStatus(store: Store<S, GridEditCellBaseActionType>): boolean;

  setCellValue(row: IViewModelRow, noTriggerEvent = false) {
    const rowId = row._rid,
      store: Store<S, GridEditCellBaseActionType> = this.stores[rowId] as any;
    let value: any = null,
      text: any = null,
      controlInstance = this.getControlInstance(rowId) as any,
      textFieldName: string | undefined = undefined;

    // TODO: 需要查明为什么store还没创建就要执行的Bug
    if (store) {
      const active = this.getCellActiveStatus(store);
      value = row[this.id];
      if (value != null) text = value;
      if (controlInstance) {
        textFieldName = controlInstance.store.state['textFieldName'];
        if (textFieldName) {
          if (value == null) {
            text = null;
            if (this.parent.dataModel[textFieldName]) this.parent.dataModel[textFieldName] = null;
          } else {
            text = row[textFieldName];
          }
        }
        if (controlInstance['format'] instanceof Function) {
          text = controlInstance['format'](value);
        }
        if (active) {
          controlInstance.store.dispatch('updateValue', {
            value,
            text,
            noTriggerEvent,
          });
        }
      }
      store.dispatch('updateValue', {
        value,
        text,
      });
    }
  }

  handleUpdateColorAfter(e: ActionAfterEventArg<S, GridEditCellBaseActionType, any>) {
    const { rowId, colorCommandFn } = e.params;
    if (colorCommandFn) {
      this.parent.dataModel[rowId].bind('valueChange', async () => {
        e.newState.dispatch('updateColor', {
          color: await colorCommandFn(rowId, this.parent.dataModel[rowId]),
        });
      });
    }
  }

  updateReadonly(readonly?: boolean, isAddReadonly?: boolean) {
    const store = this.store as Store<S, GridEditCellBaseActionType>;
    if (readonly == null) readonly = store.state.readonly;
    store.dispatch('updateReadonly', { readonly, isAddReadonly });
  }

  updateRequiredType(requiredType: RequiredTypeEnum) {
    const cellTitle = this.parent.__findCellTitle(this.id);
    if (cellTitle) {
      cellTitle.updateRequiredType(requiredType);
    }
    if (this.validator) {
      this.validator.updateRule({
        required: requiredType === RequiredTypeEnum.required,
      });
    }
    for (const key in this.stores) {
      const store = this.stores[key] as Store<S, GridEditCellBaseActionType>;
      store.dispatch('updateRequiredType', { requiredType });
    }
  }

  getValueType(rowId: number): ValidationRuleType {
    const controlInstance = this.getControlInstance(rowId);
    return controlInstance && controlInstance['valueType'];
  }

  formatValue(rowId: number, value: any) {
    if (value != null) {
      const valueType = this.getValueType(rowId);
      switch (valueType) {
        case 'string':
          value = value + '';
          break;
        case 'number':
          value = Number(value);
          break;
        case 'boolean':
          value = Boolean(value);
          break;
        case 'date':
          value = DateHelper.parse(value);
          break;
        default:
          break;
      }
    }
    return value;
  }

  valid(): Promise<IDictionary<ValidationError | true>>;
  valid(rowId: number, value: string): Promise<IDictionary<ValidationError | true>>;
  async valid(rowId?: number, value?: string) {
    if (this.validator) {
      if (rowId) {
        const store: Store<S, GridEditCellBaseActionType> = this.stores[rowId] as any;
        if (store) {
          const validResult = await this.validator.valid(this.formatValue(rowId, value));
          if (validResult !== true) {
            store.dispatch('validError', { errorHelp: validResult.message });
          } else {
            store.dispatch('validSuccess', {});
          }
          return { [rowId]: validResult };
        }
      } else {
        const validResults: IDictionary<true | ValidationError | undefined> = {};
        const keys = this.parent.dataModel.toArray().map(r => r._rid);

        for (const key of keys) {
          const store: Store<S, GridEditCellBaseActionType> = this.stores[key] as any;

          if (store && store.state.display) {
            const rowId: number = key as any;
            const validResult = await this.validator.valid(
              this.formatValue(rowId, store.state.value),
            );
            if (validResult !== true) {
              store.dispatch('validError', {
                errorHelp: validResult.message,
                actionSourceSign: store,
              });
            } else {
              store.dispatch('validSuccess', {});
            }
            validResults[key] = validResult;
          } else {
            validResults[key] = true;
          }
        }
        return validResults;
      }
    }
  }

  triggervValid(rowId: string, message: string) {
    if (rowId != null) {
      const store: Store<S, GridEditCellBaseActionType> = this.stores[rowId] as any;
      store.dispatch('validError', { errorHelp: message, actionSourceSign: store });
    }
  }
}
