import Textbox from '..';
import React from 'react';
import Icon from '@mjcloud/icon';
import ControlBase from '../../base';
import { Input, Tooltip } from 'antd';
import { ITextboxState, ITextboxButtonConfig } from '../typings';

import styles from './index.less';

const iconProps = {
  style: { color: 'rgba(0,0,0,.25)' },
};

class TextboxControl extends ControlBase<ITextboxState, Textbox> {
  private ref = (instance: Input | null) => {
    // if (instance) {
    //   this.instance.__registeredFocus(instance.focus.bind(instance));
    //   this.instance.__registeredBlur(instance.blur.bind(instance));
    // }
  };
  private handChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { prefixValue, suffixValue } = this.state;
    this.instance.store.dispatch('updateValue', {
      prefixValue,
      suffixValue,
      value: event.currentTarget.value,
      text: event.currentTarget.value,
      actionSourceSign: this.instance.store,
    });
  };

  private handleClearClick = () => {
    const { prefixValue, suffixValue } = this.state;
    this.instance.store.dispatch('updateValue', {
      prefixValue,
      suffixValue,
      value: '',
      text: undefined,
      actionSourceSign: this.instance.store,
    });
  };

  private handleRightIconClick = () => {
    // TODO: 右icon单击事件
  };

  private handleAddonAfterClick = event => {
    if (event) event.preventDefault();
    if (this.state.buttonLoading) return;
    this.instance.store.dispatch('buttonClickAfter', {});
  };

  renderAddonAfter(button: ITextboxButtonConfig) {
    const { icon = 'zoomin', tip } = button,
      { buttonLoading } = this.state;
    const _button = (
      <Icon
        type={buttonLoading ? 'loading' : icon}
        spin={buttonLoading}
        onClick={this.handleAddonAfterClick}
      />
    );
    if (tip) {
      return <Tooltip title={tip}>{_button}</Tooltip>;
    }
    return _button;
  }

  renderContent() {
    const { id } = this.props;
    const { value: _val, text, button, disabled } = this.state,
      { prefixValue, prefixFieldName, suffixValue, suffixFieldName } = this.state,
      { ruleKey, isAutoCode, isUUID } = this.state;
    const value = button ? text : _val;
    const readonlyText = `${prefixValue || ''}${text || ''}${suffixValue || ''}`;
    if (this.state.readonly) return <>{readonlyText}</>;
    if (ruleKey && isAutoCode) {
      if (isUUID || !value) return <>自动生成</>;
      return <>{readonlyText}</>;
    }
    const { placeholder, minLength, maxLength, leftIcon, rightIcon, autoFocus } = this.state;
    const closeSuffix =
      !disabled && value ? (
        <Icon type="icon-close" className={styles.close} onClick={this.handleClearClick} />
      ) : (
        <span />
      );
    const suffix = rightIcon ? (
      <>
        {closeSuffix}
        <Icon type={rightIcon} {...iconProps} onClick={this.handleRightIconClick} />
      </>
    ) : (
      closeSuffix
    );
    const prefix: React.ReactNode = leftIcon ? <Icon type={leftIcon} {...iconProps} /> : undefined;
    const addonAfter = button ? this.renderAddonAfter(button) : undefined;
    return (
      <Input
        id={id}
        ref={this.ref}
        value={value}
        prefix={prefix}
        suffix={suffix}
        disabled={disabled}
        addonBefore={prefixValue}
        addonAfter={addonAfter}
        maxLength={maxLength}
        minLength={minLength}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onChange={this.handChange}
      />
    );
  }
}

export default TextboxControl;
