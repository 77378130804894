import Page from '@mjcloud/page';
import { IPageState } from '@mjcloud/page/dist/typings';
import { IDataSourceParameters } from '@mjcloud/data-source-helper';
import { IEventArg } from '@mjcloud/utils/dist/events/eventListening';
/**
 * 页面逻辑页
 */
export class LogicBase {
  constructor(public page: Page) {
    const em = page.eventManager;
    if (this.pageDidConfigLoaded) em.add('configLoaded', this.pageDidConfigLoaded.bind(this));
    if (this.pageDidInited) em.add('inited', this.pageDidInited.bind(this));
    if (this.pageDidDataLoaded) em.add('dataLoaded', this.pageDidDataLoaded.bind(this));
    if (this.pageDidDone) em.add('done', this.pageDidDone.bind(this));
    if (this.pageDidBack) em.add('back', this.pageDidBack.bind(this));
    if (this.pageDidClose) em.add('close', this.pageDidClose.bind(this));
  }


  /**
   * 逻辑页通用刷新方法，统一函数名字方便对外使用
   * 
   * 在页面逻辑页的刷新方法，为除模板页自带提供的控件外其他控件的刷新，通常的应用场景是表单下的复杂控件
   */
  refresh?(params: IDataSourceParameters = {}) {}

  /**
   * 页面配置获取完成事件
   */
  protected pageDidConfigLoaded?(e: IEventArg<Page, IPageState>) {}
  /**
   * 页面下的控件初始化完成事件
   */
  protected pageDidInited?(e: IEventArg<Page, IPageState>) {}
  /**
   * 页面下控件数据源加载完成事件
   */
  protected pageDidDataLoaded?(e: IEventArg<Page, IPageState>) {}
  /**
   * 页面初始化完成事件
   */
  protected pageDidDone?(e: IEventArg<Page, IPageState>) {}
  /**
   * 上层页面返回到本页面的回调事件
   */
  protected pageDidBack?(e: IEventArg<Page, IPageState>) {}
  /**
   * 弹层页面关闭回到本页面的回调事件
   */
  protected pageDidClose?(e: IEventArg<Page, IPageState>) {}
}

/**
 * 模板逻辑页
 */
export class TemplateLogicBase {
  static templateType: string;
  constructor(public page: Page) {
    const em = page.eventManager;
    if (this.pageDidConfigLoaded) em.add('configLoaded', this.pageDidConfigLoaded.bind(this));
    if (this.pageDidInited) em.add('inited', this.pageDidInited.bind(this));
    if (this.pageDidDataLoaded) em.add('dataLoaded', this.pageDidDataLoaded.bind(this));
    if (this.pageDidDone) em.add('done', this.pageDidDone.bind(this));
    if (this.pageDidBack) em.add('back', this.pageDidBack.bind(this));
    if (this.pageDidClose) em.add('close', this.pageDidClose.bind(this));
  }

  /**
   * 模板页通用刷新方法，统一函数名字方便对外使用
   */
  refresh?(params: IDataSourceParameters = {}) {}

  /**
   * 页面配置获取完成事件
   */
  protected pageDidConfigLoaded?(e: IEventArg<Page, IPageState>) {}
  /**
   * 页面下的控件初始化完成事件
   */
  protected pageDidInited?(e: IEventArg<Page, IPageState>) {}
  /**
   * 页面下控件数据源加载完成事件
   */
  protected pageDidDataLoaded?(e: IEventArg<Page, IPageState>) {}
  /**
   * 页面初始化完成事件
   */
  protected pageDidDone?(e: IEventArg<Page, IPageState>) {}
  /**
   * 上层页面返回到本页面的回调事件
   */
  protected pageDidBack?(e: IEventArg<Page, IPageState>) {}
  /**
   * 弹层页面关闭回到本页面的回调事件
   */
  protected pageDidClose?(e: IEventArg<Page, IPageState>) {}
}
