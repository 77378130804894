import Service from '@mjcloud/service';
import globalData from '@mjcloud/global-data';
import { XmlList, XmlNode } from '@mjcloud/types';

export interface WorkbenchGroupItem extends XmlNode {
  title: string;
  parts: XmlList<WorkbenchPartItemConfig>;
}

export interface WorkbenchGroupConfig extends XmlList<WorkbenchGroupItem>, XmlNode {
  index: number;
  width?: number;
}

export interface WorkbenchPartItemConfig extends XmlNode {
  name: string;
  index: number;
  width: number;
  height?: number;
}

export interface WorkbenchPartList extends XmlList<WorkbenchPartItemConfig | WorkbenchGroupConfig> {
  gutter: number;
}

export interface WorkbenchConfig {
  parts: WorkbenchPartList;
}

/**
 * 获取工作台页面配置
 * @param path
 */
export async function getWorkbenchConfig(path: string) {
  // lg: "/group/Main"
  const { appId, rbCore } = globalData,
    pathArr = path.split('/');
  if (pathArr.length === 3) {
    return Service.requestServiceByNoVersion<WorkbenchConfig>('/workbench/page', {
      workbenchAddress: { appId, moduleId: pathArr[1], pageId: pathArr[2] },
      isMobile: rbCore.isMobile,
    });
  }
  return undefined;
}
