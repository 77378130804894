import { IHyperlinkConfig } from './typings';

const config: IHyperlinkConfig = {
  title: '',
  placeholder: '',
  autoFocus: false,
  modifyMode: undefined,
  format2Date: undefined,
  format2Number: undefined,
  controllerId: undefined,
  displayMode: undefined,
  disabledMode: undefined,
};

export default config;
