import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { ValueInstanceBase } from '@mjcloud/instance';
import { DateHelper, NumberHelper } from '@mjcloud/utils';
import { ValueExtendStoreBase } from '@mjcloud/extend-store';
import {
  ILabelState,
  LabelEventType,
  LabelActionType,
  ILabelConfig,
  ILabelConfigStartLoadParams,
} from './typings';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';

class LabelExtendStore extends ValueExtendStoreBase<string, Label> {
  handleUpdateValueBefore(e) {
    super.handleUpdateValueBefore(e);
    e.params.text = this.instance.format(e.params.value as any);
  }
}

class Label extends ValueInstanceBase<string, ILabelState, LabelEventType, LabelActionType> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  __createStore() {
    return new Store<ILabelState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new LabelExtendStore(this),
    });
  }

  initialState(initConfig: ILabelConfig) {
    this.store.dispatch<ILabelConfigStartLoadParams>('configStartLoad', {
      initConfig,
    });
  }

  format(value?: any, config: any = {}): string {
    if (value == null) return '';
    let _value = value;
    const {
      format2Date = config.format2Date,
      format2Number = config.format2Number,
    } = this.store.state;
    if (format2Number) {
      _value = NumberHelper.format(value, format2Number);
    } else if (format2Date) {
      _value = DateHelper.format(value, format2Date);
    }
    return _value;
  }
}

export default Label;
