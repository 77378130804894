import Schema from 'async-validator';
import EventManager from '../events';
import {
  type as typeRule,
  enum as enumRule,
  range as rangeRule,
  pattern as patternRule,
  required as requiredRule,
  whitespace as whitespaceRule,
} from 'async-validator/lib/rule';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

Schema.warning = function() {
  AsyncValidator.warning(...arguments);
};

const cn = {
  array: {
    len: '%s 的长度必须完全为 %s ',
    max: '%s 的长度不能大于 %s ',
    min: '%s 的长度不能小于 %s ',
    range: '%s 的长度必须介于 %s 和 %s 之间',
  },
  date: {
    format: '%s 日期 %s 对格式 %s 无效',
    invalid: '%s 日期 %s 无效',
    parse: '%s 日期无法解析， %s 无效',
  },
  default: '字段 %s 上的验证错误',
  enum: '%s 必须是 %s 之一',
  number: {
    len: '%s 必须等于 %s ',
    max: '%s 不能大于 %s ',
    min: '%s 不能小于 %s ',
    range: '%s 必须介于 %s 和 %s 之间',
  },
  pattern: { mismatch: '%s 值 %s 与 pattern %s 不匹配' },
  required: '%s 必填',
  string: {
    len: '%s 必须是 %s 个字符',
    max: '%s 不能超过 %s 个字符',
    min: '%s 必须至少为 %s 个字符',
    range: '%s 字段长度必须介于 %s 和 %s 之间',
  },
  types: {
    array: '%s 不是有效的 %s 类型',
    boolean: '%s 不是有效的 %s 类型',
    date: '%s 不是有效的 %s 类型',
    email: '%s 不是有效的 %s 类型',
    float: '%s 不是有效的 %s 类型',
    hex: '%s 不是有效的 %s 类型',
    integer: '%s 不是有效的 %s 类型',
    method: '%s 不是有效的 %s 类型 (function)',
    number: '%s 不是有效的 %s 类型',
    object: '%s 不是有效的 %s 类型',
    regexp: '%s 不是有效的 %s 类型',
    string: '%s 不是有效的 %s 类型',
    url: '%s 不是有效的 %s 类型',
  },
  whitespace: '%s 不能为空',
};

export declare type ValidateCallback<V> = (errors: ValidationError[], values: V) => void;

export declare type ValidationRuleType =
  | 'string'
  | 'number'
  | 'boolean'
  | 'method'
  | 'regexp'
  | 'integer'
  | 'float'
  | 'array'
  | 'object'
  | 'enum'
  | 'date'
  | 'url'
  | 'hex'
  | 'email';

export interface ValidationRule {
  /** 验证错误消息 */
  message?: string;
  /** 内置验证类型，可用选项： https://github.com/yiminghe/async-validator#type */
  type?: ValidationRuleType;
  /** 表示是否需要字段 */
  required?: boolean;
  /** 处理仅包含空格作为错误的必填字段 */
  whitespace?: boolean;
  /** 验证字段的确切长度 */
  len?: number;
  /** 验证字段的最小长度 */
  min?: number;
  /** 验证字段的最大长度 */
  max?: number;
  /** 从可能值列表中验证值 */
  enum?: string | string[];
  /** 从正则表达式验证 */
  pattern?: RegExp;
  /** 在验证之前转换值 */
  transform?: (value: any) => any;
  /** custom validate function (Note: callback must be called) */
  validator?: (rule: any, value: any, callback: any, source?: any, options?: any) => any;
}
export interface ValidationRules {
  [rule: string]: ValidationRule;
}
export interface ValidationError {
  message: string;
  field: string;
}
export default class AsyncValidator {
  public static warning: (message?: any, ...optionalParams: any[]) => void = console.warn;

  private validator: Schema;
  private descriptor: ValidationRules;
  constructor(
    private key: string,
    rule: Omit<ValidationRule, 'validator'>,
    private eventManager?: EventManager,
  ) {
    this.descriptor = {
      [key]: {
        ...rule,
        validator: this.handleValidator.bind(this),
      },
    };
    this.validator = new Schema(this.descriptor);
    this.validator.messages(cn);
  }

  updateRule(rule: Omit<ValidationRule, 'validator'>) {
    this.descriptor[this.key] = { ...this.descriptor[this.key], ...rule };
    this.validator = new Schema(this.descriptor);
    this.validator.messages(cn);
  }

  valid(value: any) {
    return new Promise<ValidationError | true>((resolve, reason) => {
      const callback: ValidateCallback<any> = errors => {
        if (errors) {
          resolve(errors[0]);
          return;
        }
        resolve(true);
      };
      this.validator.validate({ [this.key]: value }, callback);
    });
  }

  private async handleValidator(
    rule: ValidationRule,
    value: any,
    callback: any,
    source?: any,
    options?: any,
  ) {
    let errors: ValidationError[] = [];
    const eventManager = this.eventManager;
    if (eventManager && eventManager.getHandlerCount('onValid') > 0) {
      const _errors: string[] = [];
      await eventManager.trigger('onValid', { value, rule, errors: _errors });
      errors = errors.concat(_errors.map(message => ({ message, field: this.key })));
    }
    const { required, whitespace, type, pattern, enum: _enum } = rule;
    if (required) requiredRule(rule, value, source, errors, options);
    if (value) {
      if (whitespace) whitespaceRule(rule, value, source, errors, options);
      if (type && value) typeRule(rule, value, source, errors, options);
      if (pattern) patternRule(rule, value, source, errors, options);
      if (_enum) enumRule(rule, value, source, errors, options);
      rangeRule(rule, value, source, errors, options);
    }
    callback(errors);
    return;
  }
}
