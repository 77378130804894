import React from 'react';
import { SPACING } from './constant';
import styled, { StyledComponent } from 'styled-components';
import { Card as AntCard } from 'antd';
import { CardProps } from 'antd/lib/card';

const Layout = styled(AntCard)`
  width: calc(100% - ${SPACING * 2}px);
  height: calc(100% - ${SPACING * 2}px);
  margin: ${SPACING}px;
  /* background: #f0f2f5; */
  /* background: transparent; */

  > .ant-card-body {
    width: 100%;
    height: 100%;
    padding: ${SPACING}px;
    /* background: #fff; */
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.div`
  width: 100%;
`;

const HeaderContent = styled.div`
  margin-bottom: ${SPACING * 2}px;
`;

const Content = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  width: calc(100% - ${SPACING * 2}px);
  margin: 0 ${SPACING}px;
`;

const MainLayout: StyledComponent<typeof AntCard, any, CardProps, any> & {
  Header: StyledComponent<'div', any, {}, any> & { Content: StyledComponent<'div', any, {}, any> };
  Content: StyledComponent<'div', any, {}, any>;
} = Layout as any;

MainLayout.Header = Header as any;
MainLayout.Content = Content;
MainLayout.Header.Content = HeaderContent;

export default MainLayout;
