import Store from '@mjcloud/redux';
import Reduce from './reduce';
import SimpleSearchExtendStore from './extendStore';
import { ContainerInstanceBase } from '@mjcloud/instance';
import { ISimpleSearchState } from './typings';

export default class SimpleSearch extends ContainerInstanceBase<ISimpleSearchState> {
  __createStore() {
    return new Store({
      id: this.id,
      reduce: Reduce,
      extendStore: new SimpleSearchExtendStore(this),
    });
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  get filter() {
    return this.store.state._filter;
  }

  blur() {
    this.store.dispatch('onBlur', {});
  }
}
