import React from 'react';
import GridView from '..';
import Icon from '@mjcloud/icon';
import get from 'lodash/get';
import classNames from 'classnames';
import { TagProps } from 'antd/lib/tag';
import { IDictionary } from '@mjcloud/types';
import PageHelper from '@mjcloud/page-helper';
import { ArrayHelper, EventListening } from '@mjcloud/utils';
import { Card, Tag, Spin, Row, Col, Divider } from 'antd';
import { IGridViewConfigList, IGridViewConfigCardItem } from '../typings';
import { ITableBaseRow, ITableBaseColumnProps } from '../../common/table/typings';
import {
  Key,
  TableLocale,
  SorterResult,
  TablePaginationConfig,
  TableCurrentDataSource,
} from 'antd/lib/table/interface';

import styles from './index.less';

interface IGridViewListProps<T> {
  dataSource?: T[];
  loading?: boolean;
  instance: GridView;
  locale?: TableLocale;
  config: IGridViewConfigList;
  columns: ITableBaseColumnProps<T>[];
  components?: any;
  pagination?: TablePaginationConfig | false;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, Key[] | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>,
  ) => void;
}

interface IColumnProps<T> extends ITableBaseColumnProps<T> {
  colspan: number;
  colorFn?: (rowId: number) => Promise<string | undefined>;
}

interface IGridViewListState<T> {
  titleColumns: IColumnProps<T>[];
  statusColumns: IColumnProps<T>[];
  summaryColumns: IColumnProps<T>[];
  operationColumn?: IColumnProps<T>;
  columns: IColumnProps<T>[];
  sorter?: SorterResult<T>;
}

interface ICardTagProps extends TagProps {
  rowId: number;
  colorFn?: (rowId: number) => Promise<string | undefined>;
}

function tileColumns<T>(columns: ITableBaseColumnProps<T>[]) {
  let _columns: ITableBaseColumnProps<T>[] = [];
  for (const column of columns) {
    _columns.push(column);
    if (column.children) {
      _columns = _columns.concat(tileColumns(column.children));
    }
  }
  return _columns;
}

function isInvalidRenderCellText(text) {
  return (
    text &&
    !React.isValidElement(text) &&
    Object.prototype.toString.call(text) === '[object Object]'
  );
}

class CardTag extends React.Component<ICardTagProps, TagProps> {
  constructor(props: ICardTagProps) {
    super(props);
    const { rowId, colorFn, ...restProps } = props;
    this.state = { ...restProps };
  }
  async componentDidMount() {
    const { rowId, colorFn } = this.props;
    if (colorFn) {
      const color = await colorFn(rowId);
      if (color) {
        this.setState({ color });
      }
    }
  }
  render() {
    return <Tag {...this.state}>{this.props.children}</Tag>;
  }
}

export default class GridViewList<T extends ITableBaseRow> extends React.Component<
  IGridViewListProps<T>,
  IGridViewListState<T>
> {
  constructor(props: IGridViewListProps<T>) {
    super(props);
    this.state = { ...this.formatColumns(props) };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IGridViewListProps<T>) {
    if (nextProps.columns !== this.props.columns || nextProps.config !== this.props.config) {
      this.setState({ ...this.formatColumns(nextProps) });
    }
  }

  private formatColumns(props: IGridViewListProps<T>) {
    const { config, instance } = props,
      { title, status, items = [] } = config,
      titleColumns: IColumnProps<T>[] = [],
      statusColumns: IColumnProps<T>[] = [],
      summaryColumns: IColumnProps<T>[] = [],
      _title: IDictionary<IGridViewConfigCardItem> = {},
      _items: IDictionary<IGridViewConfigCardItem> = {},
      _status: IDictionary<IGridViewConfigCardItem> = {},
      _summary: IDictionary<IGridViewConfigCardItem> = {};
    let operationColumn: IColumnProps<T> | undefined;
    title.unshift({ id: '_revise' });
    for (const column of title) {
      const { position = 'left' } = column;
      if (position === 'left') {
        _title[column.id] = column;
      } else if (position === 'right') {
        _summary[column.id] = column;
      } else {
        _title[column.id] = column;
      }
    }
    for (const column of status) {
      _status[column.id] = column;
    }
    for (const column of items) {
      _items[column.id] = column;
    }
    const columns = tileColumns(props.columns)
      .map(column => {
        const _column = column as IColumnProps<T>;
        _column.colspan = 1;
        if (_column.key) {
          if (column.key === '_operation') {
            operationColumn = _column;
            return undefined;
          } else if (_title[_column.key]) {
            titleColumns.push(_column);
            return undefined;
          } else if (_summary[_column.key]) {
            summaryColumns.push(_column);
            return undefined;
          } else if (_status[_column.key]) {
            const { backgroundColorCommand } = _status[_column.key];
            if (backgroundColorCommand) {
              _column.colorFn = async (rowId: number) => {
                const fn = PageHelper.createPageScriptFunction(
                    instance.page,
                    backgroundColorCommand,
                    true,
                  ),
                  row = instance.getRowForRowId(rowId);

                // TODO: 修复因QueueCallback去掉异步所引起的Bug
                return row ? fn(EventListening.getHandlerArg(instance, { rowId, row })) : undefined;
              };
            }
            statusColumns.push(_column);
            return undefined;
          } else if (_items[_column.key]) {
            const { colspan = 1 } = _items[_column.key];
            _column.colspan = colspan;
            return _column;
          }
          return _column;
        }
        return undefined;
      })
      .filter(column => !!column) as IColumnProps<T>[];
    return { titleColumns, statusColumns, summaryColumns, operationColumn, columns };
  }

  renderBodyCell(record: T, column: IColumnProps<T>, index: number) {
    const { dataIndex, render, onCellClick, onCell, className = '' } = column,
      { components = {} } = this.props,
      { body = {} } = components,
      { cell: BodyCell = 'div' } = body;
    let text;
    if (typeof dataIndex === 'number') {
      text = get(record, dataIndex);
    } else if (!dataIndex || dataIndex.length === 0) {
      text = record;
    } else {
      text = get(record, dataIndex);
    }
    let tdProps: any = {};
    let colSpan;
    let rowSpan;

    if (render) {
      text = render(text, record, index);
      if (isInvalidRenderCellText(text)) {
        tdProps = text.props || tdProps;
        colSpan = tdProps.colSpan;
        rowSpan = tdProps.rowSpan;
        text = text.children;
      }
    }

    if (onCell) {
      tdProps = { ...tdProps, ...onCell(record, index) };
    }

    // Fix https://github.com/ant-design/ant-design/issues/1202
    if (isInvalidRenderCellText(text)) {
      text = null;
    }

    if (rowSpan === 0 || colSpan === 0) {
      return null;
    }

    if (column.align) {
      tdProps.style = { ...tdProps.style, textAlign: column.align };
    }

    const handleClick = e => {
      if (onCellClick) {
        onCellClick(record, e);
      }
    };
    return (
      <BodyCell className={className} onClick={handleClick} isCard={true} {...tdProps}>
        {text}
      </BodyCell>
    );
  }

  render() {
    const {
        sorter,
        columns,
        titleColumns,
        statusColumns,
        summaryColumns,
        operationColumn,
      } = this.state,
      { loading, locale = {}, dataSource = [] } = this.props,
      titleColumnsLength = titleColumns.length,
      summaryColumnsLength = summaryColumns.length,
      { emptyText } = locale,
      cols = 1,
      labelNumber = 4,
      colLabelClassName = `colLabel${labelNumber}`,
      _columns = ArrayHelper.oneArray2twoArray(columns, cols, column => {
        return column.colspan;
      });
    let colLabelStyle: React.CSSProperties | undefined;
    return (
      <Spin spinning={loading}>
        <div className={styles.card}>
          {dataSource.length === 0 ? (
            <div className={styles.empty}>{emptyText}</div>
          ) : (
            dataSource.map((item, i) => {
              const rowClassName = classNames({
                [styles.row]: true,
                [styles.rowBorder]: i !== dataSource.length - 1,
              });
              return (
                <div key={item._rid} className={rowClassName}>
                  <Row className={styles.rowTitle}>
                    {titleColumns.map((column, index) => {
                      const { key } = column;
                      return (
                        <React.Fragment key={key}>
                          {this.renderBodyCell(item, column, i)}
                          {index !== titleColumnsLength - 1 && <Divider type="vertical" />}
                        </React.Fragment>
                      );
                    })}
                    <div className={styles.rowTitleMiddle} />
                    {summaryColumns.map((column, index) => {
                      const { key } = column;
                      return (
                        <React.Fragment key={key}>
                          {this.renderBodyCell(item, column, i)}
                          {index !== summaryColumnsLength - 1 && <Divider type="vertical" />}
                        </React.Fragment>
                      );
                    })}
                  </Row>

                  {_columns.map((__columns, j) => {
                    return (
                      <Row key={j.toString()} className={styles.itemRow}>
                        {__columns.map(column => {
                          const { key, title, colspan } = column;
                          let span = (24 / cols) * colspan;
                          span = span > 24 ? 24 : span;
                          return (
                            <Col span={span} key={key} className={styles.col}>
                              <div
                                style={colLabelStyle}
                                className={`${styles.colLabel} ${styles[colLabelClassName]}`}
                              >
                                {title}:
                              </div>
                              <div className={styles.colValue}>
                                {this.renderBodyCell(item, column, i)}
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    );
                  })}

                  <Row className={classNames(styles.status, 'gridview-card')}>
                    {statusColumns.map(column => {
                      const { key, colorFn } = column;
                      return (
                        <CardTag rowId={item._rid} colorFn={colorFn} key={key}>
                          {this.renderBodyCell(item, column, i)}
                        </CardTag>
                      );
                    })}
                    <div className={styles.statusMiddle} />
                    {operationColumn && this.renderBodyCell(item, operationColumn, i)}
                  </Row>
                </div>
              );
            })
          )}
        </div>
      </Spin>
    );
  }
}
