import { ValueDataExtendStoreBase } from '@mjcloud/extend-store';

export default class SelectExtendStore extends ValueDataExtendStoreBase {
  protected isNeedNotificationPage = true;
  private isFirstDefaultValue = true;

  handleInitialStateBefore(e) {
    const eventManager = this.instance.eventManager;
    e.params.isCustomizeMode = eventManager.getHandlerCount('onSelectClick') > 0;
  }

  async handleStartLoadAfter(e) {
    const { isCustomizeMode } = e.newState.state;
    if (isCustomizeMode) {
      e.newState.dispatch('loaded', {
        ...e.params,
        dataSource: { rows: [] },
      });
      return;
    }
    await super.handleStartLoadAfter(e);
  }

  handleLoadedBefore(e) {
    const eventManager = this.instance.eventManager;
    if (eventManager.getHandlerCount('onDataChange') > 0) {
      eventManager.trigger('onDataChange', e.params);
    }
  }

  handleLoadedAfter(e) {
    super.handleLoadedAfter(e);
    let { needDefaultValue, dataSource, value, text } = e.newState.state;
    if (!value && needDefaultValue && this.isFirstDefaultValue && dataSource.length > 0) {
      value = dataSource[0]._value;
      text = dataSource[0]._text;
      e.newState.dispatch('updateValue', { value, text, index: 0 });
    }
    this.isFirstDefaultValue = false;
  }
}
