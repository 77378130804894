import React from 'react';
import ContentLoader from 'react-content-loader';
import globalData from '@mjcloud/global-data';

import styles from './load.less';
import getConfig from '../config';

interface ILoadProps {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
}

const Load: React.FC<ILoadProps> = () => {
  // return <Result icon={loading} title={title} subTitle={subTitle} />;
  // return <div className="dy-loading"></div>;
  // return <div className={styles.load}>{getConfig(globalData.appId).loadIcon}</div>;
  return (
    <div className={styles.load}>
      <div id="dy-loading-icon"></div>
    </div>
  );
};

// 设置平台加载中组件属性
globalData.Load = Load;
