import ExtendStoreBase from '../base';
import { EventManager } from '@mjcloud/utils';
import { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux';
import ValueInstanceBase, {
  IValueState,
  ValueControlActionType,
  IValueControlUpdateValueParams,
  IValueChangeEventData,
} from '@mjcloud/instance/dist/valueInstanceBase';

export default abstract class ValueExtendStoreBase<
  TValue = string,
  TInstance extends ValueInstanceBase<TValue> = ValueInstanceBase<TValue>
> extends ExtendStoreBase<TInstance> {
  handleUpdateValueBefore(e: ActionBeforeEventArg<IValueControlUpdateValueParams>) {
    const lastValue = this.instance.store.state.value,
      value: any = e.params.value;
    e.params.isUpdate = value !== lastValue;
  }

  handleUpdateValueAfter(
    e: ActionAfterEventArg<IValueState, ValueControlActionType, IValueControlUpdateValueParams>,
  ) {
    const { value, text } = e.newState.state as any,
      { noTriggerEvent = false, isParentChange = false, isUpdate = true } = e.params;
    if (!noTriggerEvent && isUpdate) {
      const params = { ...e.params, isParentChange, value, text };
      this.triggerValueChange('onValueChange', params);
      this.triggerValueChange('valueChange', params);
    }
  }

  triggerValueChange(eventType: string, params: any) {
    const eventManager = this.instance.eventManager as EventManager,
      handlerCount = eventManager.getHandlerCount(eventType);
    if (handlerCount > 0) {
      setTimeout(() => {
        eventManager.trigger<IValueChangeEventData<any>>(eventType, params);
      }, 100);
    }
  }
}
