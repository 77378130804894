import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { IWorkbenchState, IWorkbenchInitialStateParams } from './typings';

class WorkbenchReduce extends ReduceBase<IWorkbenchState> {
  initialState(store: Store<IWorkbenchState>, params: IWorkbenchInitialStateParams) {
    const { initConfig, pageMode } = params,
      { path, parts, gutter, displayMode } = initConfig;
    const state: IWorkbenchState = {
      path,
      parts,
      gutter,
      tabIndex: -1,
      isFetching: false,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  startUpdatePath(store: Store<IWorkbenchState>, params: { path: string }) {
    store.state.path = params.path;
    store.state.isFetching = true;
    return { ...store.state };
  }

  updatePath(store: Store<IWorkbenchState>, params) {
    store.state.gutter = params.gutter;
    store.state.parts = params.parts;
    store.state.isFetching = false;
    return { ...store.state };
  }
}

export default new WorkbenchReduce();
