import Store from '@mjcloud/redux';
import Reduce from './reduce';
import DatetimeExtendStore from './extendStore';
import { ValueInstanceBase } from '@mjcloud/instance';
import { DateHelper } from '@mjcloud/utils';
import { IDatetimeState } from './typings';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';

export default class Datetime extends ValueInstanceBase<Date | null, IDatetimeState> {
  get valueType(): ValidationRuleType {
    return 'date';
  }

  __createStore() {
    return new Store<IDatetimeState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new DatetimeExtendStore(this),
    });
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  format(value) {
    if (value == null) return null;
    const { format = 'YYYY-MM-DD' } = this.store.state;
    return DateHelper.format(value, format);
  }
}
