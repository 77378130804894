import Filterbar from '.';
import { ContainerExtendStoreBase } from '@mjcloud/extend-store';
import { IFilterbarItem } from './typings';

export default class FilterbarExtendStore extends ContainerExtendStoreBase<Filterbar> {
  handleInitialStateBefore(e) {
    const { initConfig } = e.params,
      { controls } = initConfig,
      items: IFilterbarItem[] = [];
    if (controls) {
      controls.items.forEach((item, index) => {
        const controlInstance = this.instance.createControl(item, index);
        if (controlInstance) {
          items.push({
            controlId: controlInstance.id,
            parentId: this.instance.id,
            pageKey: this.instance.page.id,
            position: index === 0 ? 'left' : 'right',
          });
        }
      });
    }
    e.params.items = items;
  }
}
