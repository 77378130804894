import globalData from '@mjcloud/global-data';
import JsApiHelper from '@mjcloud/jsapi';
import { IRouteComponentProps } from '@mjcloud/router-helper';
import Service from '@mjcloud/service';
import { ObjectHelper } from '@mjcloud/utils';
import React from 'react';
import { withRouter } from 'react-router';
import getConfig from '../../../../config';
import { Screen } from '../../util';

interface IBasicLayoutProps extends IRouteComponentProps {}

interface IBasicLayoutState {
  inited: boolean;
}
class BasicLayout extends React.Component<IBasicLayoutProps, IBasicLayoutState> {
  public constructor(props: IBasicLayoutProps) {
    super(props);
    const { match } = props,
      { corpId } = ObjectHelper.search2params(window.location.search),
      { appId } = match.params;
    this.state = { inited: globalData.rbCore._isInit };
    globalData.corpId = corpId;
    globalData.appId = appId;
    this.init();
  }

  private async init() {
    if (!globalData.rbCore._isInit) {
      const { appId } = this.props.match.params;
      const platform = await Service.platformConfigInfo();
      if (platform) {
        globalData.debugger = platform.debugger;
        globalData.isHttps = platform['isHttps'];
        // ConsoleHelper.debugger = platform.debugger;
        globalData.rbCore.__initialConfig({ ...platform, appId });

        this.initAppName(appId);
        // if (globalData.debugger) {
        //   window['eruda'].init({ defaults: { theme: 'Light' } });
        // }
        const { appName } = getConfig(appId);
        let _appName = (globalData.rbCore?.config as any)?.appName || appName;
        await Service.appSettingInfo(appId, { title: _appName, desc: _appName });
      }
      this.setState({ inited: globalData.rbCore._isInit });
    }
  }

  private initAppName(appId: string) {
    const { appName } = getConfig(appId);

    globalData.appName = appName;
    JsApiHelper.setNavigationBar({ title: appName });
  }

  render() {
    const { children } = this.props;
    const { inited } = this.state;
    if (inited) {
      return children;
    }
    return <Screen />;
  }
}

export default withRouter(BasicLayout);
