import React from 'react';
import ControlBase from '../../core/base';
import { DatePicker } from 'antd-mobile';
import zh_CN from 'antd-mobile/lib/date-picker/locale/zh_CN';
import { IDatetimeState } from '@mjcloud/mcontrols/dist/controls/datetime/typings';
import ListItem from '../../component/ListItem';

export default class DatetimeControl extends ControlBase<IDatetimeState> {
  private handleChange = (value?: Date) => {
    this.instance.store.dispatch('updateValue', {
      value: value,
      actionSourceSign: this.instance.store,
    });
  };

  renderContent() {
    const { mode, text, readonly, value, theme, iconfont, placeholder } = this.state,
      props = { theme, iconfont, readonly, placeholder };
    if (readonly) return <div className="dy-font-elipsis">{text}</div>;
    return (
      <DatePicker
        mode={mode}
        locale={zh_CN}
        value={value ? value : undefined}
        onChange={this.handleChange}
      >
        <ListItem {...props} extra={text} />
      </DatePicker>
    );
  }
}
