import Reduce from './reduce';
import Store from '@mjcloud/redux';
import Service from '@mjcloud/service';
import TextboxExtendStore from './extendStore';
import { ExceptionHelper } from '@mjcloud/exception';
import { ValueInstanceBase } from '@mjcloud/instance';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import {
  ITextboxState,
  ITextboxConfig,
  TextBoxEventType,
  ITextboxConfigStartLoadParams,
  TextBoxActionType,
} from './typings';

/**
 * TODO: ValueInstanceBase 值控件优化方案：
 * 容器控件的dataModel传递给值控件，值控件和dataModel进行双向绑定
 */
class Textbox extends ValueInstanceBase<
  string,
  ITextboxState,
  TextBoxEventType,
  TextBoxActionType
> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  __createStore() {
    return new Store<ITextboxState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new TextboxExtendStore(this),
    });
  }

  async initialState(initConfig: ITextboxConfig) {
    this.store.dispatch<ITextboxConfigStartLoadParams>('configStartLoad', {
      initConfig,
      callbackfn: async config => {
        config.isAutoCode = false;
        if (config.ruleKey) {
          try {
            config.isAutoCode = await Service.getIsAutoCoded(config.ruleKey, this.page.address);
          } catch (error) {
            ExceptionHelper.dispose(error);
            config.isAutoCode = false;
          }
        }
        return config;
      },
    });
  }

  __registeredFocus(focus: () => boolean) {
    this.focus = focus;
  }
  __registeredBlur(blur: () => boolean) {
    this.blur = blur;
  }
}

export default Textbox;
