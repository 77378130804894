import React from 'react';
import GridView from '..';
import get from 'lodash/get';
import classNames from 'classnames';
import { TagProps } from 'antd/lib/tag';
import { IDictionary } from '@mjcloud/types';
import PageHelper from '@mjcloud/page-helper';
import { EventListening } from '@mjcloud/utils';
import { CalendarOutlined } from '@ant-design/icons';
import { Avatar, Tag, Button, Row, Col, List, Divider } from 'antd';
import { IGridViewConfigCardList, IGridViewConfigCardItem } from '../typings';
import { ITableBaseRow, ITableBaseColumnProps } from '../../common/table/typings';
import {
  Key,
  TableLocale,
  SorterResult,
  TablePaginationConfig,
  TableCurrentDataSource,
} from 'antd/lib/table/interface';

import styles from './cardList.less';

interface IGridViewCardListProps<T> {
  dataSource?: T[];
  loading?: boolean;
  instance: GridView;
  locale?: TableLocale;
  config: IGridViewConfigCardList;
  columns: ITableBaseColumnProps<T>[];
  components?: any;
  pagination?: TablePaginationConfig | false;
  onRowClick?: (row: any) => void;
  onLoadMore?: (pagination: TablePaginationConfig) => void;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, Key[] | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>,
  ) => void;
}

interface IColumnProps<T> extends ITableBaseColumnProps<T> {
  colspan: number;
  colorFn?: (rowId: number) => Promise<string | undefined>;
}

interface IGridViewCardListState<T> {
  titleColumns: IColumnProps<T>[];
  statusColumns: IColumnProps<T>[];
  dateColumns: IColumnProps<T>[];
  avatarColumns: IColumnProps<T>[];
  tagsColumns: IColumnProps<T>[];
  operationColumn?: IColumnProps<T>;
  columns: IColumnProps<T>[];
  sorter?: SorterResult<T>;
}

interface ICardTagProps extends TagProps {
  rowId: number;
  colorFn?: (rowId: number) => Promise<string | undefined>;
}

const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
function isUrl(path: string): boolean {
  return reg.test(path) || path.indexOf('//') === 0;
}

function tileColumns<T>(columns: ITableBaseColumnProps<T>[]) {
  let _columns: ITableBaseColumnProps<T>[] = [];
  for (const column of columns) {
    _columns.push(column);
    if (column.children) {
      _columns = _columns.concat(tileColumns(column.children));
    }
  }
  return _columns;
}

function isInvalidRenderCellText(text) {
  return (
    text &&
    !React.isValidElement(text) &&
    Object.prototype.toString.call(text) === '[object Object]'
  );
}

class CardTag extends React.Component<ICardTagProps, TagProps> {
  constructor(props: ICardTagProps) {
    super(props);
    const { rowId, colorFn, ...restProps } = props;
    this.state = { ...restProps };
  }
  async componentDidMount() {
    const { rowId, colorFn } = this.props;
    if (colorFn) {
      const color = await colorFn(rowId);
      if (color) {
        this.setState({ color });
      }
    }
  }
  render() {
    return <Tag {...this.state}>{this.props.children}</Tag>;
  }
}

export default class GridViewCardList<T extends ITableBaseRow> extends React.Component<
  IGridViewCardListProps<T>,
  IGridViewCardListState<T>
> {
  constructor(props: IGridViewCardListProps<T>) {
    super(props);
    this.state = { ...this.formatColumns(props) };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IGridViewCardListProps<T>) {
    if (nextProps.columns !== this.props.columns || nextProps.config !== this.props.config) {
      this.setState({ ...this.formatColumns(nextProps) });
    }
  }

  private formatColumns(props: IGridViewCardListProps<T>) {
    const { config, instance } = props,
      { title, status, date, avatar, tags, items = [] } = config,
      titleColumns: IColumnProps<T>[] = [],
      statusColumns: IColumnProps<T>[] = [],
      dateColumns: IColumnProps<T>[] = [],
      avatarColumns: IColumnProps<T>[] = [],
      tagsColumns: IColumnProps<T>[] = [],
      _title: IDictionary<IGridViewConfigCardItem> = {},
      _date: IDictionary<IGridViewConfigCardItem> = {},
      _avatar: IDictionary<IGridViewConfigCardItem> = {},
      _tags: IDictionary<IGridViewConfigCardItem> = {},
      _items: IDictionary<IGridViewConfigCardItem> = {},
      _status: IDictionary<IGridViewConfigCardItem> = {};
    let operationColumn: IColumnProps<T> | undefined;
    title.unshift({ id: '_revise' });
    for (const column of title) {
      _title[column.id] = column;
    }
    for (const column of status) {
      _status[column.id] = column;
    }
    for (const column of date) {
      _date[column.id] = column;
    }
    for (const column of avatar) {
      _avatar[column.id] = column;
    }
    for (const column of tags) {
      _tags[column.id] = column;
    }
    for (const column of items) {
      _items[column.id] = column;
    }
    const columns = tileColumns(props.columns)
      .map(column => {
        const _column = column as IColumnProps<T>;
        _column.colspan = 1;
        if (_column.key) {
          if (column.key === '_operation') {
            operationColumn = _column;
            return undefined;
          } else if (_title[_column.key]) {
            titleColumns.push(_column);
            return undefined;
          } else if (_date[_column.key]) {
            dateColumns.push(_column);
            return undefined;
          } else if (_avatar[_column.key]) {
            avatarColumns.push(_column);
            return undefined;
          } else if (_tags[_column.key]) {
            const { backgroundColorCommand } = _tags[_column.key];
            if (backgroundColorCommand) {
              _column.colorFn = async (rowId: number) => {
                const fn = PageHelper.createPageScriptFunction(
                    instance.page,
                    backgroundColorCommand,
                    true,
                  ),
                  row = instance.getRowForRowId(rowId);

                // TODO: 修复因QueueCallback去掉异步所引起的Bug
                return row ? fn(EventListening.getHandlerArg(instance, { rowId, row })) : undefined;
              };
            }
            tagsColumns.push(_column);
            return undefined;
          } else if (_status[_column.key]) {
            const { backgroundColorCommand } = _status[_column.key];
            if (backgroundColorCommand) {
              _column.colorFn = async (rowId: number) => {
                const fn = PageHelper.createPageScriptFunction(
                    instance.page,
                    backgroundColorCommand,
                    true,
                  ),
                  row = instance.getRowForRowId(rowId);

                // TODO: 修复因QueueCallback去掉异步所引起的Bug
                return row ? fn(EventListening.getHandlerArg(instance, { rowId, row })) : undefined;
              };
            }
            statusColumns.push(_column);
            return undefined;
          } else if (_items[_column.key]) {
            const { colspan = 1 } = _items[_column.key];
            _column.colspan = colspan;
            return _column;
          }
          return _column;
        }
        return undefined;
      })
      .filter(column => !!column) as IColumnProps<T>[];
    return {
      titleColumns,
      statusColumns,
      dateColumns,
      avatarColumns,
      tagsColumns,
      operationColumn,
      columns,
    };
  }

  renderBodyCell(record: T, column: IColumnProps<T>, index: number) {
    const { dataIndex, render, onCellClick, onCell, className = '' } = column,
      { components = {} } = this.props,
      { body = {} } = components,
      { cell: BodyCell = 'div' } = body;
    let text;
    if (typeof dataIndex === 'number') {
      text = get(record, dataIndex);
    } else if (!dataIndex || dataIndex.length === 0) {
      text = record;
    } else {
      text = get(record, dataIndex);
    }
    let tdProps: any = {};
    let colSpan;
    let rowSpan;

    if (render) {
      text = render(text, record, index);
      if (isInvalidRenderCellText(text)) {
        tdProps = text.props || tdProps;
        colSpan = tdProps.colSpan;
        rowSpan = tdProps.rowSpan;
        text = text.children;
      }
    }

    if (onCell) {
      tdProps = { ...tdProps, ...onCell(record, index) };
    }

    // Fix https://github.com/ant-design/ant-design/issues/1202
    if (isInvalidRenderCellText(text)) {
      text = null;
    }

    if (rowSpan === 0 || colSpan === 0) {
      return null;
    }

    if (column.align) {
      tdProps.style = { ...tdProps.style, textAlign: column.align };
    }

    const handleClick = e => {
      if (onCellClick) {
        onCellClick(record, e);
      }
    };
    return (
      <BodyCell className={className} onClick={handleClick} isCard={true} {...tdProps}>
        {text}
      </BodyCell>
    );
  }

  private renderStatus(item: T, i: number) {
    const { statusColumns } = this.state;
    return statusColumns.map(column => {
      const { key, colorFn } = column;
      return (
        <CardTag rowId={item._rid} colorFn={colorFn} key={key}>
          {this.renderBodyCell(item, column, i)}
        </CardTag>
      );
    });
  }

  private renderTags(item: T, i: number) {
    const { tagsColumns } = this.state;
    return tagsColumns.map(column => {
      const { key, colorFn } = column;
      return (
        <CardTag rowId={item._rid} colorFn={colorFn} key={key}>
          {this.renderBodyCell(item, column, i)}
        </CardTag>
      );
    });
  }

  private renderTitle(item: T, i: number) {
    const { titleColumns } = this.state;
    const titleColumnsLength = titleColumns.length;
    return titleColumns.map((column, index) => {
      const { key } = column;
      return (
        <React.Fragment key={key}>
          {this.renderBodyCell(item, column, i)}
          {index !== titleColumnsLength - 1 && <Divider type="vertical" />}
        </React.Fragment>
      );
    });
  }

  private renderDate(item: T, i: number) {
    const { dateColumns } = this.state;
    const [dateColumn] = dateColumns;
    return dateColumn ? (
      <div className={styles.rowDate}>
        <CalendarOutlined {...({} as any)} />
        {this.renderBodyCell(item, dateColumn, i)}
      </div>
    ) : null;
  }

  private renderAvatar(item: T, i: number) {
    const { avatarColumns } = this.state;
    const [avatarColumn] = avatarColumns;
    if (!avatarColumn) return null;
    const { dataIndex } = avatarColumn;
    if (!dataIndex || typeof dataIndex === 'object') return null;
    const avatarSrc: string = item[dataIndex];
    if (!avatarSrc) return null;
    return isUrl(avatarSrc) ? (
      <Avatar src={avatarSrc} />
    ) : (
      <Avatar className={styles.avatar}>{avatarSrc.substring(0, 2)}</Avatar>
    );
  }

  private renderItem = (item: T, i: number) => {
    const { onRowClick } = this.props;
    const { columns } = this.state;
    const rowClassName = classNames(styles.row, !!onRowClick && styles.rowClick);
    return (
      <div
        key={item._rid}
        className={rowClassName}
        onClick={() => (onRowClick ? onRowClick(item) : undefined)}
      >
        <Row className={styles.rowTop}>
          <Col span={12} className={styles.rowItem}>
            {this.renderStatus(item, i)}
            {this.renderDate(item, i)}
          </Col>
          <Col span={12} className={styles.rowRightItem}>
            {this.renderAvatar(item, i)}
          </Col>
        </Row>
        <Row className={styles.rowTitle}>{this.renderTitle(item, i)}</Row>
        <Row className={styles.rowBottom}>
          <Col span={12}>
            {columns.map(column => (
              <React.Fragment key={column.key}>
                {this.renderBodyCell(item, column, i)}
              </React.Fragment>
            ))}
          </Col>
          <Col span={12} className={styles.rowRightItem}>
            {this.renderTags(item, i)}
          </Col>
        </Row>
      </div>
    );
  };

  onLoadMore = () => {
    const { pagination, onLoadMore } = this.props;
    if (onLoadMore && pagination) {
      if (pagination.current != null) pagination.current += 1;
      onLoadMore(pagination);
    }
  };

  renderLoadMore() {
    const { loading, pagination, dataSource = [] } = this.props;
    if (!loading && pagination) {
      const { total = dataSource.length } = pagination;
      if (dataSource.length == total) return null;
      return (
        <div className={styles.more}>
          <Button type="link" onClick={this.onLoadMore}>
            加载更多
          </Button>
        </div>
      );
    }
    return null;
  }

  render() {
    const { loading, locale = {}, dataSource = [], config } = this.props,
      { topTitle, enableCount = true } = config,
      dataSourceLen = dataSource.length,
      { emptyText } = locale;
    return (
      <div className={styles.cardList}>
        {topTitle ? (
          <div className={styles.topTitle}>
            {topTitle} {enableCount && !loading && <span>{dataSourceLen}</span>}
          </div>
        ) : null}

        <List
          className={styles.list}
          dataSource={dataSource}
          loading={loading}
          bordered={false}
          locale={{ emptyText }}
          itemLayout="horizontal"
          loadMore={this.renderLoadMore()}
          renderItem={this.renderItem}
        />

        {/* <div className={styles.list}>
          <Spin spinning={loading}>
            {dataSource.length === 0 ? (
              <div className={styles.empty}>{emptyText}</div>
            ) : (
              this.renderList()
            )}
          </Spin>
        </div> */}
      </div>
    );
  }
}
