import Toolbar from '.';
import { ContainerExtendStoreBase } from '@mjcloud/extend-store';
import { IToolbarItem } from './typings';

class ChildrenExtendStore {
  constructor(private instance, private controlInstance, private index) {}

  handleInitialStateAfter(e) {
    const { instance, index } = this,
      { display } = e.newState.state;
    instance.store.dispatch('updateChildrenDisplay', { index, display });
  }

  handleUpdateDisplayAfter(e) {
    const { instance, index } = this,
      { display } = e.newState.state;
    instance.store.dispatch('updateChildrenDisplay', { index, display });
  }
}

export default class ToolbarExtendStore extends ContainerExtendStoreBase<Toolbar> {
  handleInitialStateBefore(e) {
    const { initConfig } = e.params,
      items: IToolbarItem[] = [];
    if (initConfig && initConfig.controls) {
      let index = 0;
      initConfig.controls.items.map(item => {
        const { colspan = 1 } = item;
        const controlInstance = this.instance.createControl(item);
        if (controlInstance) {
          controlInstance.store.bindExtendStore(
            new ChildrenExtendStore(this.instance, controlInstance, index),
          );
          items.push({
            colspan,
            display: true,
            controlId: controlInstance.id,
            parentId: this.instance.id,
            pageKey: this.instance.page.id,
          });
          index++;
        }
      });
    }
    e.params.items = items;
  }
}
