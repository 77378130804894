import TreeList from '.';
import PageHelper from '@mjcloud/page-helper';
import { EventListening } from '@mjcloud/utils';
import { DataExtendStoreBase } from '@mjcloud/extend-store';
import { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux';
import {
  ITreeListState,
  TreeListActionType,
  ITreeListLoadedParams,
  TreeListUpdateSelectedKeysParams,
} from './typings';

export default class TreeListExtendStore extends DataExtendStoreBase<TreeList> {
  protected isNeedNotificationPage = true;

  handleUpdateSelectedKeysAfter(
    e: ActionAfterEventArg<ITreeListState, TreeListActionType, TreeListUpdateSelectedKeysParams>,
  ) {
    if (e.params.actionSourceSign === this.instance) return;
    const eventManager = this.instance.eventManager;
    if (eventManager.getHandlerCount('selectedChange') > 0) {
      eventManager.trigger('selectedChange', e.params);
    }
  }

  handleLoadedBefore(e: ActionBeforeEventArg<ITreeListLoadedParams>) {
    const { disabledControl } = this.instance.store.state;
    if (disabledControl) {
      e.params.disabledFn = row => {
        const fn = PageHelper.createPageScriptFunction(this.instance.page, disabledControl, true),
          _e = EventListening.getHandlerArg(this.instance, { row });
        return fn(_e);
      };
    }
  }
}
