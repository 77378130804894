import React from 'react';
import ControlBase from '../../core/base';
import { IHyperlinkState } from '@mjcloud/mcontrols/dist/controls/hyperlink/typings';

class HyperlinkControl extends ControlBase<IHyperlinkState> {
  private handleClick = event => {
    if (event) event.preventDefault();
    this.instance.store.dispatch('clickAfter', {});
  };

  renderContent() {
    const { value, text } = this.state;
    if (value == null) return null;
    return <a onClick={this.handleClick}>{text}</a>;
  }
}
export default HyperlinkControl;
