import GridView from '..';
import Store, { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux';
import Reduce from './reduce';
import { InstanceBase, ValueInstanceBase } from '@mjcloud/instance';
import TableCellBase from '../../common/table/cell';
import { ControlModifyModeEnum, IDictionary } from '@mjcloud/types';
import { ITableCellBaseInitConfig } from '../../common/table/cell/typings';
import { GridViewCellActionType, IGridViewCellState } from './typings';
import { IGridViewConfigItem } from '../typings';

class GridViewCellItemExtendStore {
  constructor(
    private instance: GridViewCell,
    private controlInstance: InstanceBase,
    private rowId: number | undefined,
  ) {}

  handleInitialStateAfter(e: ActionAfterEventArg<any>) {
    if (this.rowId == null) {
      for (const rowId in this.instance.stores) {
        this.updateValue(Number(rowId));
      }
    } else {
      this.updateValue(this.rowId);
    }
  }

  updateValue(rowId: number) {
    let value: any, text: string;
    const { instance, controlInstance } = this,
      record = instance.parent.__getCellRecord(rowId) || { _rid: rowId },
      textFieldName: string | undefined = controlInstance.store.state['textFieldName'];
    value = text = record[instance['config'].field || instance.id];
    if (textFieldName) text = record[textFieldName];
    if (value !== text) instance.stores[rowId].dispatch('updateValue', { value, text });
  }

  handleClickAfterBefore(e: ActionBeforeEventArg<any>) {
    const { rowId, instance } = this;
    if (rowId != null) {
      e.params = { ...e.params, rowId, row: instance.parent.getRowForRowId(rowId) };
    }
  }
}

export default class GridViewCell extends TableCellBase<
  GridView,
  IGridViewCellState,
  GridViewCellActionType
> {
  private special: boolean = false;
  controlInstances: IDictionary<InstanceBase | null> = {};
  constructor(public id: string, parent: GridView, protected config: IGridViewConfigItem) {
    super(id, parent, config);
    if (config.control) {
      this.special = config.control.nodeName === 'hyperlink';
    }
  }
  protected __createStore(rowId: number) {
    const store = new Store<IGridViewCellState, GridViewCellActionType>({
      id: `cell-${this.id}`,
      reduce: Reduce,
      extendStore: {
        handleInitialStateAfter: this.handleInitialStateAfter.bind(this),
      },
    });

    return store;
  }

  protected __createCommStore() {
    const store = new Store<IGridViewCellState, GridViewCellActionType>({
      id: `cell-${this.id}`,
      reduce: Reduce,
    });
    return store;
  }

  protected __getInitConfig(params: ITableCellBaseInitConfig): IDictionary {
    return { ...params, modifyMode: ControlModifyModeEnum.none, special: this.special };
  }

  private handleInitialStateAfter(e: ActionAfterEventArg<IGridViewCellState>) {
    const { value, text, rowId } = e.newState.state,
      controlInstance = this.getControlInstance(rowId);
    if (controlInstance instanceof ValueInstanceBase) {
      controlInstance.store.dispatch('updateValue', {
        value,
        text,
      });
    }
  }

  getControlInstance(rowId: number) {
    let _rowId = this.special ? rowId : 0;
    let controlInstance = this.controlInstances[_rowId];
    if (!controlInstance) {
      if (this.special) {
        const config = { ...this.config, id: `${this.config.id}${rowId}` };
        controlInstance = this.parent.__createControlByItem(config);
        if (controlInstance) this.parent.__registerSpecialCell(controlInstance);
      } else {
        controlInstance = this.parent.__createControlByItem(this.config);
      }
      if (controlInstance) {
        this.controlInstances[_rowId] = controlInstance;
        controlInstance.store.bindExtendStore(
          new GridViewCellItemExtendStore(this, controlInstance, this.special ? rowId : undefined),
        );
      }
    }
    return controlInstance;
  }
}
