import NumberHelper from '../number-helper';

interface IDictionary<T = any> {
  [key: string]: T;
}

enum ExpressionTypeEnum {
  symbol = 1,
  value = 2,
  array = 3,
  operation = 4,
  property = 5,
  variable = 6,
  function = 7,
}

interface IExpression {
  type: ExpressionTypeEnum;
}

interface ISymbolExpression extends IExpression {
  symbol: string;
  priority: number;
}

interface IValueExpression extends IExpression {
  value: any;
}

interface IArrayExpression extends IExpression {
  data: Array<IExpression>;
}

interface IOperationExpression extends IExpression {
  operation: string;
  left: IExpression;
  right: IExpression;
}

interface IPropertyExpression extends IExpression {
  targetName: string;
  propertys: Array<IExpression>;
}

interface IFunctionExpression extends IExpression {
  name?: string;
  params: IArrayExpression;
}

interface IVariableExpression extends IExpression {
  name: string;
}

interface IExpressionTraverseInfo {
  strExpression: string;
  lastIndex: number;
}

var ExpressionExtend = {
  selectCase: function(matching, defaultResult, ...caseArray: Array<any>[]) {
    let i, item;
    if (defaultResult instanceof Array) {
      i = 1;
      defaultResult = 0;
    } else {
      i = 2;
    }
    for (; i < arguments.length; i++) {
      item = arguments[i];
      if (matching == item[0]) {
        return item[1];
      }
    }
    return defaultResult;
  },
  iif: function(expression, trueValue, falseValue) {
    if (expression instanceof Array) {
      trueValue = expression[1];
      falseValue = expression[2];
      expression = expression[0];
    }
    return expression ? trueValue : falseValue;
  },
  round: function(value, precision, unround) {
    if (value instanceof Array) {
      precision = value[1];
      unround = value[2];
      value = value[0];
    }
    if (precision === undefined || precision === null || precision < 0) {
      precision = 2;
    }
    return unround ? NumberHelper.unround(value, precision) : NumberHelper.round(value, precision);
  },
};

export abstract class BaseExpression {
  constructor(
    protected strExpression: string,
    protected dataName: string,
    protected additionObject: IDictionary = {},
  ) {}

  abstract exec(data: IDictionary, additionObject?: IDictionary): any;
}

class FunctionExpression extends BaseExpression {
  private _fn!: Function;
  private _calcParams!: Array<any>;

  exec(data: IDictionary, additionObject?: IDictionary) {
    if (additionObject) this.additionObject = { ...this.additionObject, ...additionObject };
    if (!this._fn) {
      let params: Array<string> = [this.dataName, 'extend'],
        calcParams: Array<any> = [null, ExpressionExtend];
      if (this.additionObject) {
        for (let key in this.additionObject) {
          params.push(key);
          calcParams.push(this.additionObject[key]);
        }
      }
      params.push('return (' + this.strExpression.trim() + ')');

      this._fn = Function.apply(window, params);
      this._calcParams = calcParams;
    }

    this._calcParams[0] = data;
    return this._fn.apply(window, this._calcParams);
  }
}

class DynamicExpression extends BaseExpression {
  private static s_symbolPriority: IDictionary<number> = { '+': 1, '-': 1, '*': 2, '/': 2 };
  private static s_maxPriority: number = 3;
  private static s_propertyRegex: RegExp = new RegExp('([a-zA-Z]+[a-zA-Z0-9_]*)[.|[]+', 'g');
  private static s_trimQuotationRegex: RegExp = new RegExp('^[s|\'|"]*|[s|\'|"]*$', 'g');
  private static s_nonVariableCharRegex: RegExp = new RegExp('[^a-zA-Z0-9_]{1}', 'g');

  private _expression!: IExpression;
  private _data!: IDictionary;

  exec(data: IDictionary, additionObject?: IDictionary) {
    if (additionObject) this.additionObject = { ...this.additionObject, ...additionObject };
    if (!this._expression) {
      this._expression = DynamicExpression.s_buildExpression(
        this.strExpression,
        this.additionObject,
      );
    }
    this._data = data;
    return this._calcExpression(this._expression);
  }

  private _calcExpression(expression: IExpression, targetObject?: any) {
    switch (expression.type) {
      case ExpressionTypeEnum.variable:
        return targetObject[(<IVariableExpression>expression).name];
      case ExpressionTypeEnum.operation:
        return this._calcOperationExpression(<IOperationExpression>expression);
      case ExpressionTypeEnum.function: {
        let name = (<IFunctionExpression>expression).name;
        if (name) {
          if (targetObject[name] && targetObject[name].apply) {
            return targetObject[name].apply(
              targetObject,
              this._calcArrayExpression((<IFunctionExpression>expression).params),
            );
          } else {
            throw new Error(`函数${name}不存在当前类中!`);
          }
        } else {
          return targetObject.apply(
            targetObject.caller,
            this._calcArrayExpression((<IFunctionExpression>expression).params),
          );
        }
      }
      case ExpressionTypeEnum.array:
        return this._calcArrayExpression(<IArrayExpression>expression);
      case ExpressionTypeEnum.value:
        return (<IValueExpression>expression).value;
      case ExpressionTypeEnum.property:
        return this._calcPropertyExpression(<IPropertyExpression>expression);
      default:
        throw new Error('发生未知异常！');
    }
  }

  //#region 计算相关函数

  private _calcPropertyExpression(expression: IPropertyExpression): any {
    let targetName = (<IPropertyExpression>expression).targetName,
      targetObject;
    if (targetName == this.dataName) {
      targetObject = this._data;
    } else if (targetName == 'extend') {
      targetObject = ExpressionExtend;
    } else {
      targetObject = (<any>this.additionObject)[targetName];
    }

    for (let propertyExpression of expression.propertys) {
      targetObject = this._calcExpression(propertyExpression, targetObject);
    }
    return targetObject;
  }

  private _calcOperationExpression(expression: IOperationExpression): number {
    let left = this._calcExpression(expression.left);
    if (expression.operation === '.') {
      return this._calcExpression(left, expression.right);
    }

    let right = this._calcExpression(expression.right);
    switch (expression.operation) {
      case '+':
        return left + right;
      case '-':
        return left - right;
      case '*':
        return left * right;
      case '/':
        return left / right;
      case '>':
        return left > right ? 1 : 0;
      case '<':
        return left < right ? 1 : 0;
      case '>=':
        return left >= right ? 1 : 0;
      case '<=':
        return left <= right ? 1 : 0;
      case '=':
        return right;
      case '==':
        return left == right ? 1 : 0;
      case '===':
        return left === right ? 1 : 0;
      default:
        throw new Error('不支持[' + expression.operation + ']符号运算！');
    }
  }

  private _calcArrayExpression(expression: IArrayExpression): Array<any> {
    let array: Array<any> = [];
    for (let param of expression.data) {
      array.push(this._calcExpression(param));
    }
    return array;
  }

  //#endregion

  //#region 构建相关函数

  private static s_buildExpression(strExpression: string, targetObject: IDictionary): IExpression {
    let expressions!: Array<IExpression>, preExpression!: IExpression, expression!: IExpression;
    let expressionTraverseInfo: IExpressionTraverseInfo = {
        strExpression: strExpression,
        lastIndex: 0,
      },
      expressionLength = strExpression.length;
    while (
      expressionTraverseInfo.lastIndex >= 0 &&
      expressionTraverseInfo.lastIndex < expressionLength
    ) {
      expression = DynamicExpression.s_buildNextExpression(expressionTraverseInfo, targetObject);
      expressionTraverseInfo.lastIndex = DynamicExpression.s_findNextNonEmptyCharIndex(
        strExpression,
        expressionTraverseInfo.lastIndex +
          (expression.type === ExpressionTypeEnum.value
            ? (<IValueExpression>expression).value.length
            : 1),
      );
      if (preExpression) {
        if (
          expression.type === ExpressionTypeEnum.value &&
          preExpression.type === ExpressionTypeEnum.symbol &&
          (<ISymbolExpression>preExpression).symbol === '-' &&
          (!expressions || expressions[expressions.length - 1].type === ExpressionTypeEnum.symbol)
        ) {
          (<IValueExpression>expression).value =
            0 - NumberHelper.parse((<IValueExpression>expression).value);
          preExpression = expression;
          continue;
        } else {
          if (expressions) {
            expressions.push(preExpression);
          } else {
            expressions = [preExpression];
          }
        }
      }

      preExpression = expression;
    }

    if (expressions) {
      if (preExpression) {
        expressions.push(preExpression);
      }
      for (let i = DynamicExpression.s_maxPriority; i >= 0; i--) {
        expressions = DynamicExpression.s_combinePriorityExpression(expressions, i);
        if (expressions.length === 1) {
          return expressions[0];
        }
      }
    } else if (preExpression) {
      return preExpression;
    }

    throw new Error('发生内部未知错误！');
  }

  /**
   * 将四则允许按照指定的优先级进行组合
   * @param expressions 要进行组合的表达式集合
   * @param priority 当前要组合的优先级
   */
  private static s_combinePriorityExpression(
    expressions: Array<IExpression>,
    priority: number,
  ): Array<IExpression> {
    let symbolExpression: ISymbolExpression,
      operationExpression: IOperationExpression,
      newExpressions: Array<IExpression> = [];
    newExpressions.push(expressions[0]);
    for (let i = 1; i < expressions.length; i += 2) {
      symbolExpression = <ISymbolExpression>expressions[i];
      if (symbolExpression.priority === priority) {
        newExpressions[newExpressions.length - 1] = <IOperationExpression>{
          type: ExpressionTypeEnum.operation,
          operation: symbolExpression.symbol,
          left: newExpressions[newExpressions.length - 1],
          right: expressions[i + 1],
        };
      } else {
        newExpressions.push(symbolExpression);
        newExpressions.push(expressions[i + 1]);
      }
    }
    return newExpressions;
  }

  /**
   * 从当前位置开始到一个完整的最小表达式截取出来进行表达式构建
   * @param formulaInfo 表达式信息
   */
  private static s_buildNextExpression(
    expressionTraverseInfo: IExpressionTraverseInfo,
    targetObject: IDictionary,
  ): IExpression {
    let { strExpression, lastIndex } = expressionTraverseInfo,
      startIndex: number,
      endIndex: number,
      expression: IExpression | null;

    let nowChar = strExpression.charAt(lastIndex);
    switch (nowChar) {
      case "'":
      case '"':
        endIndex = strExpression.indexOf(nowChar, lastIndex + 1);
        if (endIndex > 0) {
          expressionTraverseInfo.lastIndex = endIndex;
          return {
            type: ExpressionTypeEnum.value,
            value: strExpression.substring(lastIndex + 1, endIndex),
          } as IValueExpression;
        } else {
          throw new Error('当前公式表达式缺少结尾[' + nowChar + ']符号。');
        }
      case '(':
        endIndex = DynamicExpression.s_findReverseBracketIndex(strExpression, lastIndex + 1);
        if (endIndex < 0) {
          throw new Error('当前公式表达式缺少结尾[)]符号。');
        }

        expressionTraverseInfo.lastIndex = endIndex;
        return DynamicExpression.s_buildExpression(
          strExpression.substring(lastIndex + 1, endIndex).trim(),
          targetObject,
        );
      case '[':
        endIndex = DynamicExpression.s_findReverseBracket2Index(strExpression, lastIndex + 1);
        if (endIndex < 0) {
          throw new Error('当前公式表达式缺少结尾[]]符号。');
        }

        expressionTraverseInfo.lastIndex = endIndex;
        return DynamicExpression.s_buildArrayExpression(
          strExpression.substring(lastIndex + 1, endIndex).trim(),
          targetObject,
        );
      case '*':
      case '/':
      case '+':
      case '-':
        return {
          type: ExpressionTypeEnum.symbol,
          symbol: nowChar,
          priority: DynamicExpression.s_symbolPriority[nowChar],
        } as ISymbolExpression;
      case '>':
      case '<':
      case '=': {
        let symbol = nowChar;
        endIndex = lastIndex;
        while (strExpression.charAt(++endIndex) === '=') {
          symbol += '=';
        }
        expressionTraverseInfo.lastIndex = endIndex - 1;
        return {
          type: ExpressionTypeEnum.symbol,
          symbol: symbol,
          priority: 0,
        } as ISymbolExpression;
      }
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        endIndex = DynamicExpression.s_findNextNonNumberCharIndex(strExpression, lastIndex + 1);
        if (endIndex > 0) {
          return {
            type: ExpressionTypeEnum.value,
            value: NumberHelper.parse(strExpression.substring(lastIndex, endIndex)),
          } as IValueExpression;
        } else {
          return {
            type: ExpressionTypeEnum.value,
            value: NumberHelper.parse(strExpression.substr(lastIndex)),
          } as IValueExpression;
        }
      default:
        expression = DynamicExpression.s_tryBuildPropertyExpression(
          expressionTraverseInfo,
          targetObject,
        );
        if (expression) {
          return expression;
        }
        throw new Error(`${strExpression} 当前公式表达式格式不支持。`);
    }
  }

  private static s_buildArrayExpression(
    arrayContent: string,
    targetObject: IDictionary,
  ): IArrayExpression {
    let startIndex: number = 0,
      endIndex: number,
      expression: IExpression;
    let arrayExpression: IArrayExpression = {
      type: ExpressionTypeEnum.array,
      data: [],
    };
    endIndex = DynamicExpression.s_findNextArraySplitIndex(arrayContent, startIndex);
    while (endIndex >= 0) {
      expression = DynamicExpression.s_buildExpression(
        arrayContent.substring(startIndex, endIndex).trim(),
        targetObject,
      );
      arrayExpression.data.push(expression);

      startIndex = endIndex + 1;
      endIndex = DynamicExpression.s_findNextArraySplitIndex(arrayContent, startIndex);
    }

    if (startIndex < arrayContent.length) {
      expression = DynamicExpression.s_buildExpression(
        arrayContent.substring(startIndex).trim(),
        targetObject,
      );
      arrayExpression.data.push(expression);
    }

    return arrayExpression;
  }

  private static s_tryBuildPropertyExpression(
    expressionTraverseInfo: IExpressionTraverseInfo,
    targetObject: IDictionary,
  ): IPropertyExpression | null {
    let { strExpression, lastIndex } = expressionTraverseInfo,
      targetName: string | undefined,
      propertys: Array<IExpression> = [],
      childProperty: IExpression | null;

    DynamicExpression.s_propertyRegex.lastIndex = lastIndex;
    let matchItem = DynamicExpression.s_propertyRegex.exec(strExpression);
    if (matchItem) {
      targetName = matchItem[1];
    } else {
      if (targetObject) {
        for (const key in targetObject) {
          if (key === strExpression) {
            targetName = key;
          }
        }
        if (!targetName) return null;
      } else {
        return null;
      }
    }

    expressionTraverseInfo.lastIndex = DynamicExpression.s_propertyRegex.lastIndex - 1;
    while (true) {
      childProperty = DynamicExpression.s_tryBuildContinuityProperty(
        expressionTraverseInfo,
        targetObject,
      );
      if (!childProperty) {
        expressionTraverseInfo.lastIndex--;
        break;
      }

      propertys.push(childProperty);

      expressionTraverseInfo.lastIndex++;
      if (expressionTraverseInfo.lastIndex >= strExpression.length) {
        expressionTraverseInfo.lastIndex--;
        break;
      }
    }

    return {
      type: ExpressionTypeEnum.property,
      targetName,
      propertys,
    } as IPropertyExpression;
  }

  private static s_tryBuildContinuityProperty(
    expressionTraverseInfo: IExpressionTraverseInfo,
    targetObject: IDictionary,
  ): IExpression | null {
    let { strExpression, lastIndex } = expressionTraverseInfo,
      startIndex,
      endIndex,
      childPropertyName;

    switch (strExpression[lastIndex]) {
      case '.': {
        // 访问子属性
        DynamicExpression.s_nonVariableCharRegex.lastIndex = lastIndex + 1;
        let matchItem = DynamicExpression.s_nonVariableCharRegex.exec(strExpression);

        if (matchItem) {
          startIndex = DynamicExpression.s_nonVariableCharRegex.lastIndex - 1;
          childPropertyName = strExpression.substring(lastIndex + 1, startIndex);
          if (matchItem[0] === '(') {
            // 函数调用
            endIndex = DynamicExpression.s_findReverseBracketIndex(strExpression, startIndex + 1);
            if (endIndex < 0) {
              throw new Error('当前公式表达式应用了函数，但是缺少[)]符号。');
            }
            expressionTraverseInfo.lastIndex = endIndex;
            return {
              type: ExpressionTypeEnum.function,
              name: childPropertyName,
              params: DynamicExpression.s_buildArrayExpression(
                strExpression.substring(startIndex + 1, endIndex),
                targetObject,
              ),
            } as IFunctionExpression;
          } else {
            expressionTraverseInfo.lastIndex = startIndex - 1;
          }
        } else {
          childPropertyName = strExpression.substr(lastIndex + 1);
          expressionTraverseInfo.lastIndex = strExpression.length - 1;
        }

        return {
          type: ExpressionTypeEnum.variable,
          name: childPropertyName,
        } as IVariableExpression;
      }
      case '[': // 访问子属性
        endIndex = DynamicExpression.s_findReverseBracket2Index(strExpression, lastIndex + 1);
        if (endIndex < 0) {
          throw new Error('当前公式表达式缺少[]]符号。');
        }
        childPropertyName = strExpression
          .substring(lastIndex + 1, endIndex)
          .replace(DynamicExpression.s_trimQuotationRegex, '');

        startIndex = DynamicExpression.s_findNextNonEmptyCharIndex(strExpression, endIndex + 1);
        if (strExpression[startIndex] === '(') {
          // 函数调用
          endIndex = DynamicExpression.s_findReverseBracketIndex(strExpression, startIndex + 1);
          if (endIndex < 0) {
            throw new Error('当前公式表达式应用了函数，但是缺少[)]符号。');
          }
          expressionTraverseInfo.lastIndex = endIndex;
          return {
            type: ExpressionTypeEnum.function,
            name: childPropertyName,
            params: DynamicExpression.s_buildArrayExpression(
              strExpression.substring(startIndex + 1, endIndex),
              targetObject,
            ),
          } as IFunctionExpression;
        }

        expressionTraverseInfo.lastIndex = endIndex;
        return {
          type: ExpressionTypeEnum.variable,
          name: childPropertyName,
        } as IVariableExpression;
      case '(': // 函数调用
        endIndex = DynamicExpression.s_findReverseBracketIndex(strExpression, lastIndex + 1);
        if (endIndex < 0) {
          throw new Error('当前公式表达式应用了函数，但是缺少[)]符号。');
        }
        expressionTraverseInfo.lastIndex = endIndex;
        return {
          type: ExpressionTypeEnum.function,
          params: DynamicExpression.s_buildArrayExpression(
            strExpression.substring(lastIndex + 1, endIndex),
            targetObject,
          ),
        } as IFunctionExpression;
      default:
        return null;
    }
  }

  //#endregion

  //#region 辅助函数

  private static s_findNextNonCharIndex(str: string, start: number): number {
    let nowChar;
    for (; start < str.length; start++) {
      nowChar = str.charAt(start);
      if (
        nowChar === ' ' ||
        nowChar === '\t' ||
        nowChar === '+' ||
        nowChar === '-' ||
        nowChar === '*' ||
        nowChar === '/' ||
        nowChar === ')'
      ) {
        return start;
      }
    }

    return -1;
  }

  private static s_findNextNonNumberCharIndex(str: string, start: number): number {
    for (; start < str.length; start++) {
      switch (str.charAt(start)) {
        case '0':
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
          continue;
        default:
          return start;
      }
    }

    return -1;
  }

  private static s_findNextNonEmptyCharIndex(str: string, start: number): number {
    let nowChar;
    for (; start < str.length; start++) {
      nowChar = str.charAt(start);
      if (nowChar === ' ' || nowChar === '\t') {
        continue;
      }

      return start;
    }

    return -1;
  }

  /**
   * 查找反括号
   * @param str
   * @param start
   * @param startPoise
   * @param endPoise
   */
  private static s_findReverseBracketIndex(str: string, start: number): number {
    let quotesChar: string | null = null,
      bracketCount: number = 0,
      nowChar: string;
    for (; start < str.length; start++) {
      nowChar = str.charAt(start);

      if (quotesChar) {
        if (nowChar === quotesChar) {
          quotesChar = null;
        }

        continue;
      }

      switch (nowChar) {
        case '"':
        case "'":
          quotesChar = nowChar;
          continue;
        case '(':
          bracketCount++;
          continue;
        case ')':
          if (bracketCount) {
            bracketCount--;
          } else {
            return start;
          }
          break;
      }
    }

    return -1;
  }

  /**
   * 查找反括号
   * @param str
   * @param start
   * @param startPoise
   * @param endPoise
   */
  private static s_findReverseBracket2Index(str: string, start: number): number {
    let quotesChar: string | null = null,
      bracketCount: number = 0,
      nowChar: string;
    for (; start < str.length; start++) {
      nowChar = str.charAt(start);

      if (quotesChar) {
        if (nowChar === quotesChar) {
          quotesChar = null;
        }

        continue;
      }

      switch (nowChar) {
        case '"':
        case "'":
          quotesChar = nowChar;
          continue;
        case '[':
          bracketCount++;
          continue;
        case ']':
          if (bracketCount) {
            bracketCount--;
          } else {
            return start;
          }
          break;
      }
    }

    return -1;
  }

  private static s_findNextArraySplitIndex(str: string, start: number): number {
    let quotesChar: string | null = null,
      bracketCount1: number = 0,
      bracketCount2: number = 0,
      nowChar: string;
    for (; start < str.length; start++) {
      nowChar = str.charAt(start);

      if (quotesChar) {
        if (nowChar === quotesChar) {
          quotesChar = null;
        }

        continue;
      }

      switch (nowChar) {
        case '"':
        case "'":
          quotesChar = nowChar;
          continue;
        case '(':
          bracketCount1++;
          continue;
        case ')':
          bracketCount1--;
          break;
        case '[':
          bracketCount2++;
          continue;
        case ']':
          bracketCount2--;
          break;
      }

      if (bracketCount1 || bracketCount2) {
        continue;
      }

      if (',' === nowChar) {
        return start;
      }
    }
    return -1;
  }

  //#endregion
}

export default class ExpressionHelper {
  /**
   * 创建PC端的编译型公式计算
   */
  static create(
    expression: string,
    dataName: string,
    additionObject?: IDictionary,
  ): BaseExpression {
    if (window) {
      return new FunctionExpression(expression, dataName, additionObject);
    } else {
      return new DynamicExpression(expression, dataName, additionObject);
    }
    // TODO: 暂时不考虑性能问题，保证移动端和PC端的动态脚本解析一致
    // return new DynamicExpression(expression, dataName, additionObject);
  }
}
