import { EventListening, NumberHelper } from '@mjcloud/utils';
import { ExceptionHelper } from '@mjcloud/exception';
import { DataStateEnum, IDictionary } from '@mjcloud/types';
import { IEventArg } from '@mjcloud/utils/dist/events/eventListening';
import Service from '@mjcloud/service';

export type ValueChangeArg = IEventArg<IViewModelObject, IViewModelValueChangeEventDataArgs>;
export type DataStateChangeArg = IEventArg<
  IViewModelObject,
  IViewModelDataStateChangeEventDataArgs
>;
export type RowValueChangeArg = IEventArg<
  IViewModelCollection,
  IViewModelRowValueChangeEventDataArgs
>;
export type AggValueChangeArg = IEventArg<
  IViewModelCollection,
  IViewModelAggValueChangeEventDataArgs
>;
export type RowDataStateChangeArg = IEventArg<
  IViewModelCollection,
  IViewModelRowDataStateChangeEventDataArgs
>;
export type CollectionDataStateChangeArg = IEventArg<
  IViewModelCollection,
  IViewModelStateChangeEventDataArgs
>;
export enum ViewModelTypeEnum {
  viewModel = 1,
  viewModelCollection = 2,
}
export interface IViewModel {
  readonly _viewModelType: ViewModelTypeEnum;
  bind(eventType: string, handler: any): void;
  unbind(eventType: string, handler: any): void;
}

export interface IViewModelObject extends IViewModel {
  readonly _dataState: DataStateEnum;
  [key: string]: any;
  [key: number]: any;

  bind(eventType: 'valueChange', handler: (e: ValueChangeArg) => void): void;
  bind(eventType: 'dataStateChange', handler: (e: DataStateChangeArg) => void): void;
  bind(eventType: string, handler: any): void;

  unbind(eventType: 'valueChange', handler: (e: ValueChangeArg) => void): void;
  unbind(eventType: 'dataStateChange', handler: (e: DataStateChangeArg) => void): void;
  unbind(eventType: string, handler: any): void;

  /**
   * 获取当前行的rid
   */
  getRId(): void;

  toJSON(): any;
  /**
   * 初始化数据，但是不触发任何事件
   * @param datas
   * @param dataState DataStateEnum， 默认数据的修改状态更新为新增，若页面状态为修改请改为未修改
   */
  initDatas(datas: IDictionary, dataState?: DataStateEnum): void;
  update: (updateData: IDictionary, changeSourceSign?: any, notUpdateState?: boolean) => void;
  delete: (changeSourceSign?: any) => void;
  updateDataState: (dataState: DataStateEnum, changeSourceSign?: any) => void;
}

export interface IViewModelValueChangeEventDataArgs {
  values: IDictionary;
  oldValues: IDictionary;
  notUpdateState?: boolean;
}

export interface IViewModelDataStateChangeEventDataArgs {
  dataState: DataStateEnum;
  oldDataState: DataStateEnum;
}

export interface IViewModelRow extends IViewModelObject {
  readonly _rid: number;
}

export enum AggTypeEnum {
  sum = 1,
}

export interface AggProperty {
  propertyName: string;
  aggType: AggTypeEnum;
  decimal?: number;
}

interface AggPropertyValue {
  propertyName: string;
  aggType: AggTypeEnum;
  decimal?: number;
  aggValue?: any;
  aggNewValue?: any;
}

export interface IViewModelCollection extends IViewModel {
  readonly isChanged: boolean;
  readonly length: number;
  [key: number]: IViewModelRow;

  bind(eventType: 'rowValueChange', handler: (e: RowValueChangeArg) => void): void;
  bind(eventType: 'rowDataStateChange', handler: (e: RowDataStateChangeArg) => void): void;
  bind(eventType: 'dataStateChange', handler: (e: CollectionDataStateChangeArg) => void): void;
  bind(eventType: 'aggValueChange', handler: (e: AggValueChangeArg) => void): void;
  bind(eventType: string, handler: any): void;

  unbind(eventType: 'rowValueChange', handler: (e: RowValueChangeArg) => void): void;
  unbind(eventType: 'rowDataStateChange', handler: (e: RowDataStateChangeArg) => void): void;
  unbind(eventType: 'dataStateChange', handler: (e: CollectionDataStateChangeArg) => void): void;
  unbind(eventType: 'aggValueChange', handler: (e: AggValueChangeArg) => void): void;
  unbind(eventType: string, handler: any): void;

  toJSON(): any[];
  toArray: (excludeDelete?: boolean) => Array<IViewModelRow>;
  indexOf: (rid: number) => number;
  findRow: (rid: number, excludeDelete?: boolean) => IViewModelRow | undefined;
  addRow: (row: IDictionary, changeSourceSign?: any) => number;
  removeRow: (rid: number, changeSourceSign?: any) => boolean;

  /**
   * 新增一行数据
   * @returns 新增数据的id
   */
  addRowAsync: (row: IDictionary, changeSourceSign?: any) => string;
  /**
   * 插入一行数据
   * @param index 索引
   * @returns 新增数据的id
   */
  insertRowAsync: (index: number, row: IDictionary, changeSourceSign?: any) => string;
  /**
   * 插入一行数据
   * @returns 新增数据的id
   */
  insertBeforeRowAsync: (id: string, row: IDictionary, changeSourceSign?: any) => string;
  /**
   * 插入一行数据
   * @returns 新增数据的id
   */
  insertAfterRowAsync: (id: string, row: IDictionary, changeSourceSign?: any) => string;
  /**
   * 删除一行数据
   * @returns 新增数据的id
   */
  deleteRowAsync: (id: string, changeSourceSign?: any) => void;
  /**
   * 修改一行数据
   * @returns 新增数据的id
   */
  updateRowAsync: (id: string, row: IDictionary, changeSourceSign?: any) => void;
  /**
   * 获取一行数据
   * @returns 新增数据的id
   */
  getRowAsync: (id: string, changeSourceSign?: any) => IViewModelRow | undefined;
  /**
   * 获取数据
   * @param keyValues 查询条件
   * @returns 新增数据的id
   */
  findRowsAsync: (keyValues: IDictionary, changeSourceSign?: any) => IViewModelRow[];

  /**
   * 清空数据，但是不触发任何事件
   */
  clear: () => void;
  /**
   * 初始化数据，但是不触发任何事件
   * @param datas
   * @param dataState DataStateEnum， 默认数据的修改状态更新为未修改，若页面状态为新增请改为新增
   */
  initDatas(datas: Array<IDictionary>, dataState?: DataStateEnum): void;
  dataSubmitFinish: (changeSourceSign?: any) => void;
  /**
   * 添加聚合字段集合
   */
  addAggProperties: (aggProperties: Array<AggProperty>) => void;
  /**
   * 移除聚合字段
   */
  removeAggProperty: (propertyName: string) => void;
  /**
   * 根据字段获取聚合字段信息
   */
  getAggProperty: (propertyName: string) => AggPropertyValue | undefined;
  /**
   * 根据字段获取聚合字段值
   */
  getAggPropertyValue: (propertyName: string) => any;
}

export interface IViewModelRowValueChangeEventDataArgs {
  row: IViewModelRow;
  values: IDictionary;
  oldValues: IDictionary;
}

export interface IViewModelAggValueChangeEventDataArgs {
  propertyName: string;
  value: any;
  oldValue: any;
}

export interface IViewModelRowDataStateChangeEventDataArgs {
  row: IViewModelRow;
  dataState: DataStateEnum;
  oldDataState: DataStateEnum;
}

export interface IViewModelStateChangeEventDataArgs {
  isChange: boolean;
}

export interface ViewModelHandler extends ProxyHandler<IDictionary>, IDictionary {}

export default class ViewModelHelper {
  /**
   * 创建单数据视图模型对象
   * @param fns
   * @param rid
   */
  public static createViewModel(fns: ViewModelHandler = {}, rid?: number): IViewModelObject {
    const _valueChangeEvent = new EventListening('valueChange');
    const _dataStateChangeEvent = new EventListening('dataStateChange');

    var _target: IDictionary = { _dataState: DataStateEnum.unchanged };
    var _proxy: any;
    var _rid: number = rid || 0;

    function getDifference(oldValue: any, newValue: any): any {
      var diffValue = {},
        existDiff;
      for (var key in newValue) {
        if (newValue[key] !== oldValue[key]) {
          diffValue[key] = newValue[key];
          existDiff = true;
        }
      }
      return existDiff ? diffValue : null;
    }

    var _extendFunctions = {
      getRId: function() {
        return _rid;
      },
      bind: function(eventType: string, handler: any) {
        switch (eventType) {
          case 'valueChange':
            _valueChangeEvent.add(handler);
            break;
          case 'dataStateChange':
            _dataStateChangeEvent.add(handler);
            break;
          default:
            throw ExceptionHelper.notSupportException(
              '当前对象不支持当前要绑定的事件：' + eventType,
            );
        }
      },
      unbind: function(eventType: string, handler: any) {
        switch (eventType) {
          case 'valueChange':
            _valueChangeEvent.remove(handler);
            break;
          case 'dataStateChange':
            _dataStateChangeEvent.remove(handler);
            break;
          default:
            throw ExceptionHelper.notSupportException(
              '当前对象不支持当前要解除绑定的事件：' + eventType,
            );
        }
      },
      toJSON: function() {
        const { _children, _parent, ...outerTarget } = _target;
        return { ...outerTarget, _rid, _viewModelType: ViewModelTypeEnum.viewModel };
      },
      delete: function(changeSourceSign?: any) {
        if (_target._dataState !== DataStateEnum.deleted) {
          _extendFunctions.updateDataState(DataStateEnum.deleted, changeSourceSign);
        }
      },
      updateDataState: function(dataState: DataStateEnum, changeSourceSign?: any) {
        if (_target._dataState === dataState) return;
        let oldDataState = _target._dataState;
        _target._dataState = dataState;

        if (_dataStateChangeEvent.count > 0) {
          _dataStateChangeEvent.trigger(
            _proxy,
            { dataState: dataState, oldDataState: oldDataState },
            {},
            changeSourceSign,
          );
        }
      },
      update: function(values: IDictionary, changeSourceSign?: any, notUpdateState?: boolean) {
        if (values && values._viewModelType) {
          throw ExceptionHelper.notSupportException(
            'ViewModelCollection对象不支持直接设置_viewModelType值！',
          );
        }

        let diffValue = getDifference(_target, values);
        if (!diffValue) {
          return;
        }

        let key: string | number,
          value: any,
          oldValues: IDictionary = {};
        for (key in diffValue) {
          if (key.charAt(0) === '_') {
            throw ExceptionHelper.notSupportException('ViewModel对象中的私有数据不支持批量更新！');
          }
          value = diffValue[key];
          oldValues[key] = _target[key];

          _target[key] = value;
        }

        if (_valueChangeEvent.count > 0) {
          _valueChangeEvent.trigger(
            _proxy,
            { values: diffValue, oldValues, notUpdateState },
            {},
            changeSourceSign,
          );
        }

        if (_target._dataState === DataStateEnum.unchanged && !notUpdateState) {
          _extendFunctions.updateDataState(DataStateEnum.changed, changeSourceSign);
        }
      },
      clear: function() {
        _target = {};
      },
      initDatas: function(datas: IDictionary, dataState: DataStateEnum = DataStateEnum.added) {
        _extendFunctions.clear();
        _target = { ...datas, _dataState: dataState };
      },
    };

    const { get, set, has, ownKeys, deleteProperty, ...restFns } = fns;
    const obj: ProxyHandler<IDictionary> = {
      get: function(target, key: string, receiver) {
        let fn = _extendFunctions[key];
        if (fn) {
          return fn;
        }
        if (key === '_viewModelType') {
          return ViewModelTypeEnum.viewModel;
        }
        if (key === '_rid') {
          return _rid;
        }
        if (get) {
          return get(_target, key, receiver);
        } else {
          return _target[key];
        }
      },
      set: function(target, key: string, value, receiver): boolean {
        if (key.charAt(0) === '_') {
          throw ExceptionHelper.notSupportException('ViewModel对象中的私有数据不支持直接修改！');
        }

        var values: any = {};
        values[key] = value;
        _extendFunctions.update(values);

        if (set) {
          return set(_target, key, value, receiver);
        } else {
          return true;
        }
      },
      deleteProperty: function(target, key: string): boolean {
        if (key.charAt(0) === '_') {
          throw ExceptionHelper.notSupportException('ViewModel对象中的私有数据不支持删除！');
        }

        var values: any = {};
        values[key] = undefined;
        _extendFunctions.update(values);
        if (deleteProperty) {
          return deleteProperty(_target, key);
        } else {
          return true;
        }
      },
      has: function(target, key: string): boolean {
        if (has) {
          return has(_target, key);
        } else {
          return _target.hasOwnProperty(key);
        }
      },
      // enumerate: function(target) {
      //   if (enumerate) {
      //     return enumerate(_target);
      //   } else {
      //     return Object.keys(_target);
      //   }
      // },
      ownKeys: function(target): Array<PropertyKey> {
        if (ownKeys) {
          return ownKeys(_target);
        } else {
          return Object.keys(_target);
        }
      },
      isExtensible: function(target): boolean {
        return true;
      },
      defineProperty: function(target, p: PropertyKey, attributes: PropertyDescriptor): boolean {
        return false;
      },
      ...restFns,
    };

    _proxy = new Proxy(_target, obj);

    if (fns && fns.extendFunctions) {
      let extendFunctions = fns.extendFunctions(_proxy);
      for (let key in extendFunctions) {
        _extendFunctions[key] = extendFunctions[key];
      }
    }

    return _proxy;
  }

  /**
   * 创建集合数据模型对象
   * @param fns
   */
  public static createViewModelCollection(fns?: IDictionary): IViewModelCollection {
    const _rowValueChangeEvent = new EventListening('rowValueChange');
    const _rowDataStateChangeEvent = new EventListening('rowDataStateChange');
    const _dataStateChangeEvent = new EventListening('dataStateChange');
    const _aggValueChangeEvent = new EventListening('aggValueChange');

    var _ridCounter: number = 1;
    var _datas: Array<IViewModelRow> = [];
    var _deleteds: Array<IViewModelRow> = [];
    var _rids: Array<string> = [];
    var _proxy: any;
    var _isChanged: boolean = false;

    var _aggProperties: Array<AggPropertyValue> = [];

    function _findAggPropertyIndex(propertyName: string): number {
      for (let i = 0; i < _aggProperties.length; i++) {
        if (_aggProperties[i].propertyName === propertyName) {
          return i;
        }
      }
      return -1;
    }

    function _calcAgg(isMust: boolean = false, aggProperties?: Array<AggPropertyValue>) {
      let i,
        count,
        aggPropertyValue: AggPropertyValue,
        eventData: IViewModelAggValueChangeEventDataArgs;
      if (!aggProperties) {
        aggProperties = _aggProperties;
      }
      count = aggProperties.length;
      if (count === 0) {
        return;
      }

      for (i = 0; i < count; i++) {
        aggProperties[i].aggNewValue = 0;
      }
      for (let row of _datas) {
        for (i = 0; i < count; i++) {
          aggPropertyValue = aggProperties[i];
          aggPropertyValue.aggNewValue = NumberHelper.calculate(
            aggPropertyValue.aggNewValue,
            row[aggPropertyValue.propertyName],
            '+',
            aggPropertyValue.decimal,
          );
        }
      }
      for (i = 0; i < count; i++) {
        aggPropertyValue = aggProperties[i];
        // aggPropertyValue.aggNewValue = NumberHelper.round(
        //   aggPropertyValue.aggNewValue,
        //   aggPropertyValue.decimal,
        // );
        if (isMust || aggPropertyValue.aggValue !== aggPropertyValue.aggNewValue) {
          eventData = {
            propertyName: aggPropertyValue.propertyName,
            value: aggPropertyValue.aggNewValue,
            oldValue: aggPropertyValue.aggValue,
          };
          aggPropertyValue.aggValue = aggPropertyValue.aggNewValue;

          _aggValueChangeEvent.trigger(_proxy, eventData, undefined, isMust);
        }
      }
    }

    function handRowValueChange(e: IEventArg<IViewModelRow, IViewModelValueChangeEventDataArgs>) {
      if (_rowValueChangeEvent.count > 0) {
        _rowValueChangeEvent.trigger(
          _proxy,
          { row: e.sender, values: e.data.values, oldValues: e.data.oldValues },
          {},
          e.eventSourceSign,
        );
      }

      if (_aggProperties.length > 0) {
        let values = e.data.values,
          aggPropertyValue,
          aggProperties: Array<AggPropertyValue> | undefined;
        for (aggPropertyValue of _aggProperties) {
          if (values[aggPropertyValue.propertyName] != null) {
            if (!aggProperties) {
              aggProperties = [];
            }
            aggProperties.push(aggPropertyValue);
          }
        }
        if (aggProperties) {
          _calcAgg(false, aggProperties);
        }
      }
    }

    function handRowDataStateChange(
      e: IEventArg<IViewModelRow, IViewModelDataStateChangeEventDataArgs>,
    ) {
      let dataState = e.data.dataState;
      if (dataState === DataStateEnum.unchanged) {
        return;
      }

      if (dataState === DataStateEnum.deleted) {
        let row = e.sender;

        let index = _datas.indexOf(row);
        if (index < 0) {
          throw ExceptionHelper.notSupportException('当前删除的对象不在当前集合中或已删除！');
        }
        _datas.splice(index, 1);
        row.unbind('valueChange', handRowValueChange);
        row.unbind('dataStateChange', handRowDataStateChange);
        _rids.splice(_rids.indexOf(row._rid.toString()), 1);

        if (e.data.oldDataState !== DataStateEnum.added) {
          _deleteds.push(row);
        }
      }

      if (_rowDataStateChangeEvent.count > 0) {
        _rowDataStateChangeEvent.trigger(
          _proxy,
          { row: e.sender, dataState: dataState, oldDataState: e.data.oldDataState },
          {},
          e.eventSourceSign,
        );
      }

      if (!_isChanged) {
        _isChanged = true;

        if (_dataStateChangeEvent.count > 0) {
          _dataStateChangeEvent.trigger(_proxy, { isChange: true }, {}, e.eventSourceSign);
        }
      }

      if (_aggProperties.length > 0) {
        _calcAgg();
      }
    }

    var _extendFunctions = {
      bind: function(eventType: string, handler: any) {
        switch (eventType) {
          case 'rowValueChange':
            _rowValueChangeEvent.add(handler);
            break;
          case 'rowDataStateChange':
            _rowDataStateChangeEvent.add(handler);
            break;
          case 'dataStateChange':
            _dataStateChangeEvent.add(handler);
            break;
          case 'aggValueChange':
            _aggValueChangeEvent.add(handler);
            break;
          default:
            throw ExceptionHelper.notSupportException(
              '当前对象不支持当前要绑定的事件：' + eventType,
            );
        }
      },
      unbind: function(eventType: string, handler: any) {
        switch (eventType) {
          case 'rowValueChange':
            _rowValueChangeEvent.remove(handler);
            break;
          case 'rowDataStateChange':
            _rowDataStateChangeEvent.remove(handler);
            break;
          case 'dataStateChange':
            _dataStateChangeEvent.remove(handler);
            break;
          case 'aggValueChange':
            _aggValueChangeEvent.remove(handler);
            break;
          default:
            throw ExceptionHelper.notSupportException(
              '当前对象不支持当前要解除绑定的事件：' + eventType,
            );
        }
      },
      toJSON: function() {
        const datas = [..._datas, ..._deleteds];
        return datas.map(data => data.toJSON());
      },
      toArray: function(excludeDelete: boolean = true) {
        const datas = excludeDelete ? [..._datas] : [..._datas, ..._deleteds];
        return datas;
      },
      indexOf: function(rid: number): number {
        for (let i = _datas.length - 1; i >= 0; i--) {
          if (_datas[i]._rid === rid) {
            return i;
          }
        }
        return -1;
      },
      findRow: function(rid: number, excludeDelete?: boolean): IViewModelRow | undefined {
        for (let i = _datas.length - 1; i >= 0; i--) {
          if (_datas[i]._rid === rid) {
            return _datas[i];
          }
        }
        if (!excludeDelete) {
          for (let i = _deleteds.length - 1; i >= 0; i--) {
            if (_deleteds[i]._rid === rid) {
              return _deleteds[i];
            }
          }
        }
        return undefined;
      },
      each: function(fn: Function) {
        for (let i = _datas.length - 1; i >= 0; i--) {
          let result = fn.call(this, _datas[i]);
          if (result === false) {
            break;
          }
        }
      },
      addRow: function(row: IDictionary, changeSourceSign?: any): number {
        if (row && row._viewModelType) {
          throw ExceptionHelper.notSupportException(
            'ViewModelCollection对象不支持直接设置_viewModelType值！',
          );
        }

        let rid = _ridCounter++;
        let rowViewModel = ViewModelHelper.createViewModel(undefined, rid) as IViewModelRow;
        rowViewModel.initDatas(row, DataStateEnum.added);
        rowViewModel.bind('valueChange', handRowValueChange);
        rowViewModel.bind('dataStateChange', handRowDataStateChange);
        _datas.push(rowViewModel);
        _rids.push(rid.toString());

        if (_rowDataStateChangeEvent.count > 0) {
          _rowDataStateChangeEvent.trigger(
            _proxy,
            { row: rowViewModel, dataState: rowViewModel._dataState, oldDataState: undefined },
            {},
            changeSourceSign,
          );
        }

        if (!_isChanged) {
          _isChanged = true;

          if (_dataStateChangeEvent.count > 0) {
            _dataStateChangeEvent.trigger(_proxy, { isChange: true }, {}, changeSourceSign);
          }
        }

        if (_aggProperties.length > 0) {
          _calcAgg();
        }

        return rid;
      },
      removeRow: function(rid: number, changeSourceSign?: any): boolean {
        let row = _extendFunctions.findRow(rid, true);
        if (row) {
          row.delete(changeSourceSign);
          return true;
        } else {
          return false;
        }
      },

      /**
       * 新增一行数据
       * @returns 新增数据的id
       */
      addRowAsync: async function(row: IDictionary, changeSourceSign?: any) {
        const ids = await Service.requestService<string[]>('/createid/newids', { quantity: 1 });
        if (!ids) {
          throw ExceptionHelper.notSupportException('ViewModelCollection创建时没有id值！');
        }
        const id = ids[0];
        console.info(111, { ...row, id });
        _extendFunctions.addRow({ ...row, id }, changeSourceSign);
        return id;
      },
      /**
       * 插入一行数据
       * @param index 索引
       * @returns 新增数据的id
       */
      insertRowAsync: async function(index: number, row: IDictionary, changeSourceSign?: any) {
        const ids = await Service.requestService<string[]>('/createid/newids', { quantity: 1 });
        if (!ids) {
          throw ExceptionHelper.notSupportException('ViewModelCollection创建时没有id值！');
        }
        const id = ids[0];
        row.id = id;

        if (row && row._viewModelType) {
          throw ExceptionHelper.notSupportException(
            'ViewModelCollection对象不支持直接设置_viewModelType值！',
          );
        }

        let rid = _ridCounter++;
        let rowViewModel = ViewModelHelper.createViewModel(undefined, rid) as IViewModelRow;
        rowViewModel.initDatas(row, DataStateEnum.added);
        rowViewModel.bind('valueChange', handRowValueChange);
        rowViewModel.bind('dataStateChange', handRowDataStateChange);
        _datas.splice(index, 0, rowViewModel);
        _rids.push(rid.toString());

        if (_rowDataStateChangeEvent.count > 0) {
          _rowDataStateChangeEvent.trigger(
            _proxy,
            { row: rowViewModel, dataState: rowViewModel._dataState, oldDataState: undefined },
            {},
            changeSourceSign,
          );
        }

        if (!_isChanged) {
          _isChanged = true;

          if (_dataStateChangeEvent.count > 0) {
            _dataStateChangeEvent.trigger(_proxy, { isChange: true }, {}, changeSourceSign);
          }
        }

        if (_aggProperties.length > 0) {
          _calcAgg();
        }

        return id;
      },
      /**
       * 插入一行数据
       * @returns 新增数据的id
       */
      insertBeforeRowAsync: async function(id: string, row: IDictionary, changeSourceSign?: any) {
        const insertRow = await _extendFunctions.getRowAsync(id);
        if (!insertRow) {
          throw ExceptionHelper.notSupportException('指定行不存在！');
        }

        return await _extendFunctions.insertRowAsync(
          _datas.indexOf(insertRow),
          row,
          changeSourceSign,
        );
      },
      /**
       * 插入一行数据
       * @returns 新增数据的id
       */
      insertAfterRowAsync: async function(id: string, row: IDictionary, changeSourceSign?: any) {
        const insertRow = await _extendFunctions.getRowAsync(id);
        if (!insertRow) {
          throw ExceptionHelper.notSupportException('指定行不存在！');
        }

        return await _extendFunctions.insertRowAsync(
          _datas.indexOf(insertRow) + 1,
          row,
          changeSourceSign,
        );
      },
      /**
       * 删除一行数据
       * @returns 新增数据的id
       */
      deleteRowAsync: async function(id: string, changeSourceSign?: any) {
        let row = await _extendFunctions.getRowAsync(id, true);
        if (row) row.delete(changeSourceSign);
      },
      /**
       * 修改一行数据
       * @returns 新增数据的id
       */
      updateRowAsync: async function(id: string, row: IDictionary, changeSourceSign?: any) {
        let deleteRow = await _extendFunctions.getRowAsync(id, true);
        if (deleteRow) deleteRow.update(row);
      },
      /**
       * 获取一行数据
       * @returns 新增数据的id
       */
      getRowAsync: async function(id: string, changeSourceSign?: any) {
        for (let i = _datas.length - 1; i >= 0; i--) {
          if (id == _datas[i].id) {
            return _datas[i];
          }
        }
        return undefined;
      },
      /**
       * 获取数据
       * @param keyValues 查询条件
       * @returns 新增数据的id
       */
      findRowsAsync: async function(keyValues: IDictionary, changeSourceSign?: any) {
        const result: IViewModel[] = [];
        for (let i = _datas.length - 1; i >= 0; i--) {
          let flag = true;
          const keys = Object.keys(keyValues);
          for (let j = keys.length - 1; j >= 0; j--) {
            flag = flag && _datas[i][keys[j]] == keyValues[keys[j]];
            if (!flag) {
              break;
            }
          }
          if (flag) {
            result.push(_datas[i]);
          }
        }
        return result.reverse();
      },

      clear: function(notCalcAgg?: boolean) {
        for (let rowViewModel of _datas) {
          rowViewModel.unbind('valueChange', handRowValueChange);
          rowViewModel.unbind('dataStateChange', handRowDataStateChange);
        }
        _rids = [];
        _datas = [];
        _deleteds = [];
        _isChanged = false;

        if (!notCalcAgg && _aggProperties.length > 0) {
          _calcAgg();
        }
      },
      initDatas: function(
        datas: Array<IDictionary>,
        dataState: DataStateEnum = DataStateEnum.unchanged,
      ) {
        _extendFunctions.clear(true);
        datas = datas.filter(r => !!r);
        if (datas && datas.length > 0) {
          let rid: number, rowViewModel: IViewModelRow;
          for (let row of datas) {
            rid = _ridCounter++;
            rowViewModel = ViewModelHelper.createViewModel(undefined, rid) as IViewModelRow;
            rowViewModel.initDatas(row, row._dataState != null ? row._dataState : dataState);
            rowViewModel.bind('valueChange', handRowValueChange);
            rowViewModel.bind('dataStateChange', handRowDataStateChange);
            _rids.push(rid.toString());
            if (row._dataState === DataStateEnum.deleted) {
              _deleteds.push(rowViewModel);
            } else {
              _datas.push(rowViewModel);
            }
          }
        }

        if (_aggProperties.length > 0) {
          _calcAgg(true);
        }
      },
      dataSubmitFinish: function(changeSourceSign?: any) {
        if (!_isChanged) return;

        _isChanged = false;

        for (let row of _datas) {
          row.updateDataState(DataStateEnum.unchanged);
        }
        _deleteds = [];

        if (_dataStateChangeEvent.count > 0) {
          _dataStateChangeEvent.trigger(_proxy, { isChange: false }, {}, changeSourceSign);
        }
      },
      addAggProperties: function(__aggProperties: AggProperty[]) {
        if (__aggProperties && __aggProperties.length > 0) {
          let index,
            aggProeprtyValue: AggPropertyValue,
            aggProperties: Array<AggPropertyValue> = [];
          for (let aggProperty of __aggProperties) {
            aggProeprtyValue = {
              propertyName: aggProperty.propertyName,
              aggType: aggProperty.aggType,
            };
            aggProperties.push(aggProeprtyValue);

            index = _findAggPropertyIndex(aggProperty.propertyName);
            if (index >= 0) {
              _aggProperties[index] = aggProeprtyValue;
            } else {
              _aggProperties.push(aggProeprtyValue);
            }
          }
          _calcAgg(false, aggProperties);
        }
      },
      removeAggProperty: function(propertyName: string) {
        let index = _findAggPropertyIndex(propertyName);
        if (index >= 0) {
          _aggProperties.splice(index, 1);
        }
      },
      getAggProperty: function(propertyName: string) {
        let index = _findAggPropertyIndex(propertyName);
        if (index >= 0) {
          return _aggProperties[index];
        }
      },
      getAggPropertyValue: function(propertyName: string) {
        let index = _findAggPropertyIndex(propertyName);
        if (index >= 0) {
          return _aggProperties[index].aggValue;
        }
      },
    };

    const obj: ProxyHandler<IDictionary> = {
      get: function(target, key: string) {
        let fn = _extendFunctions[key];
        if (fn) {
          return fn;
        }
        switch (key) {
          case '_viewModelType':
            return ViewModelTypeEnum.viewModelCollection;
          case 'isChanged':
            return _isChanged;
          case 'length':
            return _datas.length;
        }

        return _extendFunctions.findRow(parseInt(key), true);
      },
      set: function(target, key: string, values): boolean {
        if (values && values._viewModelType) {
          throw ExceptionHelper.notSupportException(
            'ViewModelCollection对象不支持直接设置_viewModelType值！',
          );
        }

        let rowViewModel: IViewModelRow | undefined = _extendFunctions.findRow(parseInt(key), true);
        if (!rowViewModel) {
          throw ExceptionHelper.notSupportException('当前要更新的行数据不存在或已删除！');
        }

        if (!values) {
          // 删除
          rowViewModel.delete();
        } else {
          // 更新
          rowViewModel.update(values);
        }

        return true;
      },
      deleteProperty: function(target, key: string): boolean {
        let rowViewModel: IViewModelRow | undefined = _extendFunctions.findRow(parseInt(key), true);
        if (rowViewModel) {
          rowViewModel.delete();
        }

        return true;
      },
      has: function(target, key: string): boolean {
        return _rids.indexOf(key) >= 0;
      },
      // enumerate: function(target): PropertyKey[] {
      //   return _rids;
      // },
      ownKeys: function(target): Array<PropertyKey> {
        return _rids;
      },
      isExtensible: function(target): boolean {
        return true;
      },
      defineProperty: function(target, p: PropertyKey, attributes: PropertyDescriptor): boolean {
        return false;
      },
      ...fns,
    };

    _proxy = new Proxy({}, obj);

    if (fns && fns.extendFunctions) {
      let extendFunctions = fns.extendFunctions(_proxy);
      for (let key in extendFunctions) {
        _extendFunctions[key] = extendFunctions[key];
      }
    }

    return _proxy;
  }
}
