import { ExceptionHelper } from '@mjcloud/exception';
import globalData from '@mjcloud/global-data';
import Icon from '@mjcloud/icon';
import { IRouteComponentProps } from '@mjcloud/router-helper';
import Service from '@mjcloud/service';
import { IAppItem, IDictionary } from '@mjcloud/types';
import { ObjectHelper } from '@mjcloud/utils';
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import Link from 'umi/link';
import { MAINPATH } from './app/constant';

const gridStyle: React.CSSProperties = {
  width: '25%',
  textAlign: 'center',
};

const SelectPage: React.FC<IRouteComponentProps> = ({ history }) => {
  const query: IDictionary<string> = ObjectHelper.search2params(window.location.search);
  if (!query._t) return null;

  // 首重安全协议导致hash丢失，在这里重定向到Main
  const { pathname } = window.location,
    appId = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.lastIndexOf('.'));
  const trustworthyAppIds = ['trustworthyproject', 'trustworthyprojectcorp'];
  if (trustworthyAppIds.includes(appId)) {
    history.replace(`/${appId}/Main`);
    return null;
  }

  const { code, state } = query,
    [appList, updateList] = useState<IAppItem[] | undefined>(undefined);
  if (code && state.search('wechat_redirect') === 0) {
    const appId = state.split('-')[1];
    globalData.__WxAcquiredCode = true;
    history.replace(`/${appId}/wxlogin`);
    return null;
  } else {
    useEffect(() => {
      (async () => {
        if (appList == null) {
          try {
            const reslut = await Service.platformConfigInfo();
            if (reslut && reslut.appList) updateList(reslut.appList);
            else updateList([]);
          } catch (error) {
            updateList([]);
            ExceptionHelper.dispose(error);
          }
        }
      })();
    });
  }

  return (
    <Card title="请选择你要进入的应用">
      {appList &&
        appList.map(({ name, appId, icon }) => (
          <Link to={`${appId}${MAINPATH}`} key={appId}>
            <Card.Grid style={gridStyle} key={appId}>
              <Icon type={icon} style={{ fontSize: 32 }} />
              <br />
              {name}
            </Card.Grid>
          </Link>
        ))}
    </Card>
  );
};

export default SelectPage;
