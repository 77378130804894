import Chart from '.';
import { DataExtendStoreBase } from '@mjcloud/extend-store';
import { ChartNodeActionType, ChartNodeEventType, IChartState } from './typings';
import { ActionAfterEventArg } from '@mjcloud/redux';
import { IDictionary } from '@mjcloud/types';
import { ControllerHelper, ExceptionHelper } from '@mjcloud/core';

export default class ChartExtendStore extends DataExtendStoreBase<Chart> {
  protected isNeedNotificationPage = true;

  handleInitialStateBefore(e) {
    e.params.chartId = this.instance.id;
  }

  async handleClickAfterAfter(e: ActionAfterEventArg<IChartState, ChartNodeActionType>) {
    // const { tip, title, controllerId } = e.newState.state;
    // const exclusiveLockId = this.instance.page.applyExclusiveLock(tip || `正在执行${title}操作...`);
    const exclusiveLockId = this.instance.page.applyExclusiveLock(`正在执行操作...`);
    if (exclusiveLockId === 0) {
      e.newState.dispatch('clickDone', {});
      return;
    }
    const params: IDictionary = { ...e.params },
      before = async (data: IDictionary, extra: IDictionary = {}) => {
        if (this.instance.eventManager.getHandlerCount('onBeforeClick') > 0) {
          await this.instance.eventManager.trigger('onBeforeClick', data, extra);
        }
        return data;
      },
      after = async (data: IDictionary, extra: IDictionary = {}) => {
        if (this.instance.eventManager.getHandlerCount('onAfterClick') > 0) {
          await this.instance.eventManager.trigger('onAfterClick', data, extra);
        }
        return data;
      };
    try {
      // if (controllerId) {
      //   const _params = { data: params, before, after };
      //   await ControllerHelper.execute({
      //     sender: this.instance,
      //     controllerId,
      //     ..._params,
      //   });
      // } else {
      await before(params);
      await after(params);
      // }

      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      e.newState.dispatch('clickDone', {});
    } catch (error) {
      e.newState.dispatch('clickDone', {});
      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      ExceptionHelper.dispose(error);
    }
  }
}
