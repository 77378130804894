export function isFunction(what: any) {
  return typeof what === 'function';
}

function isUndefined(what: any) {
  return what === void 0;
}

function hasKey(object: any, key: any) {
  return Object.prototype.hasOwnProperty.call(object, key);
}

function each(obj: any, callback: any) {
  var i, j;

  if (isUndefined(obj.length)) {
    for (i in obj) {
      if (hasKey(obj, i)) {
        callback.call(null, i, obj[i]);
      }
    }
  } else {
    j = obj.length;
    if (j) {
      for (i = 0; i < j; i++) {
        callback.call(null, i, obj[i]);
      }
    }
  }
}

export function objectMerge(obj1: any, obj2: any) {
  if (!obj2) {
    return obj1;
  }
  each(obj2, function(key: any, value: any) {
    obj1[key] = value;
  });
  return obj1;
}

export var handleWindowError = function(_window: any, config: any) {
  _window._oldWindowError = _window.onerror;
  _window.onerror = function(msg: any, url: any, line: any, col: any, error: any) {
    if (error && error.stack) {
      config.sendError({
        title: msg,
        msg: error.stack,
        category: 'js',
        level: 'error',
      });
    } else if (typeof msg === 'string') {
      config.sendError({
        title: msg,
        msg: JSON.stringify({
          resourceUrl: url,
          rowNum: line,
          colNum: col,
        }),
        category: 'js',
        level: 'error',
      });
    }
    if (_window._oldWindowError && isFunction(_window._oldWindowError)) {
      if (_window.windowError) _window.windowError.apply(window, arguments);
    }
  };
};
export var handleRejectPromise = function(_window: any, config: any) {
  _window.addEventListener(
    'unhandledrejection',
    function(event: any) {
      if (event) {
        var reason = event.reason;
        config.sendError({
          title: 'unhandledrejection',
          msg: reason,
          category: 'js',
          level: 'error',
        });
      }
    },
    true,
  );
};

export var handleResourceError = function(_window: any, config: any) {
  _window.addEventListener(
    'error',
    function(event: any) {
      if (event) {
        var target = event.target || event.srcElement;
        var isElementTarget =
          target instanceof HTMLScriptElement ||
          target instanceof HTMLLinkElement ||
          target instanceof HTMLImageElement;
        if (!isElementTarget) return; // js error不再处理

        var url = target.src || target.href;
        var error = new Error(url);
        error.name = 'resourceError';
        config.sendError({
          title: target.nodeName,
          msg: error,
          category: 'resource',
          level: 'error',
        });
      }
    },
    true,
  );
};

var _handleFetchError = function(_window: any, config: any) {
  if (!_window.fetch) return;
  let _oldFetch = _window.fetch;
  _window.fetch = function() {
    return _oldFetch
      .apply(this, arguments)
      .then((res: any) => {
        if (!res.ok) {
          // True if status is HTTP 2xx
          config.sendError({
            title: arguments[0],
            msg: JSON.stringify(res),
            category: 'ajax',
            level: 'error',
          });
        }
        return res;
      })
      .catch((error: any) => {
        config.sendError({
          title: arguments[0],
          msg: JSON.stringify({
            message: error.message,
            stack: error.stack,
          }),
          category: 'ajax',
          level: 'error',
        });
        throw error;
      });
  };
};

export var handleAjaxError = function(_window: any, config: any) {
  var protocol = _window.location.protocol;
  if (protocol === 'file:') return;

  // 处理fetch
  _handleFetchError(_window, config);

  // 处理XMLHttpRequest
  if (!_window.XMLHttpRequest) {
    return;
  }
  var xmlhttp = _window.XMLHttpRequest;

  var _oldSend = xmlhttp.prototype.send;
  var _handleEvent = function(event: any) {
    if (event && event.currentTarget && event.currentTarget.status !== 200) {
      config.sendError({
        title: event.target.responseURL,
        msg: JSON.stringify({
          response: event.target.response,
          responseURL: event.target.responseURL,
          status: event.target.status,
          statusText: event.target.statusText,
        }),
        category: 'ajax',
        level: 'error',
      });
    }
  };
  xmlhttp.prototype.send = function() {
    if (this.addEventListener) {
      this.addEventListener('error', _handleEvent);
      this.addEventListener('load', _handleEvent);
      this.addEventListener('abort', _handleEvent);
    } else {
      var _oldStateChange = this.onreadystatechange;
      this.onreadystatechange = function(event: any) {
        if (this.readyState === 4) {
          _handleEvent(event);
        }
        if (_oldStateChange) _oldStateChange.apply(this, arguments);
      };
    }
    return _oldSend.apply(this, arguments);
  };
};

export var handleConsoleError = function(_window: any, config: any) {
  if (!_window.console || !_window.console.error) return;

  var _oldConsoleError = _window.console.error;
  _window.console.error = function() {
    var args = arguments.length === 1 ? [arguments[0]] : Array.apply(null, arguments as any);
    config.sendError({
      title: 'consoleError',
      msg: JSON.stringify(args.join(',')),
      category: 'js',
      level: 'error',
    });
    if (_oldConsoleError) _oldConsoleError.apply(_window, arguments);
  };
};
