import { ControllerBase, IControllerInitData, IRequest } from './base';
import { IDictionary, XmlParamList } from '@mjcloud/types';
import { InstanceBase } from '@mjcloud/instance';
import PageHelper from '@mjcloud/page-helper';
import globalData from '@mjcloud/global-data';
import Service, { IFileInfo } from '@mjcloud/service';

export interface ImportFileControllerInitData extends IControllerInitData {
  exportId?: string;
  exportFileName?: string;
  limitType?: string;
  service: XmlParamList;
}

/**
 * 执行脚本请求对象类型
 */
export interface IImportFileRequest extends IRequest {}

/**
 * 执行脚本控制器
 */
export default class ImportFileController extends ControllerBase<ImportFileControllerInitData> {
  constructor(sender: InstanceBase, initData: ImportFileControllerInitData) {
    super(sender, initData);
  }

  /**
   * 创建执行请求对象
   */
  public async createRequest(args: IDictionary): Promise<IImportFileRequest> {
    const values: IDictionary<string> = {};
    if (this.initData.service && this.initData.service.items)
      this.initData.service.items.forEach(item => {
        values[item.name] = item.value;
      });
    const params = await PageHelper.getDynamicParamValues(this.page, values, args);
    return { ...params, ...args };
  }

  /**
   * 执行控制器
   * @param request 执行时的请求对象
   */
  public async execute<T>(request: IImportFileRequest) {
    const { exportId, exportFileName: templateLabel, limitType: accept } = this.initData;
    const getTemplateFile = async (exportId: string) =>
      this.page.executeController(exportId, { _isDownloadFile: false }, this.sender);

    const { fileId } = await globalData.__importFile({
      accept,
      exportId,
      templateLabel,
      getTemplateFile,
    });
    request.fileId = fileId;

    const result = await Service.executeController<T>({
      ...this.page.getControllerExecParams(),
      params: request,
      controllerId: this.id,
      workbenchPartAddress: this.sender.workbenchPart && this.sender.workbenchPart.address,
    });
    return result;
  }
}
