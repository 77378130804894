import Workbench from './instance';
import { ExtendStoreBase } from '@mjcloud/extend-store';
import { ActionAfterEventArg } from '@mjcloud/redux';
import { IWorkbenchState } from './typings';

export default class WorkbenchExtendStore extends ExtendStoreBase<Workbench> {
  async handleStartUpdatePathAfter(e: ActionAfterEventArg<IWorkbenchState>) {
    const { path } = e.newState.state;
    const { gutter, parts } = await this.instance.__getWorkbenchPageConfig(path);
    e.newState.dispatch('updatePath', { gutter, parts });
  }
}
