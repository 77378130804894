import JsApiHelper from '@mjcloud/jsapi';

interface IException {
  /**
   * 异常名称
   */
  name: string;
  /**
   * 错误消息
   */
  message: string;
  /**
   * 错误的具体信息
   */
  data?: any;
  /**
   * 是否属于业务异常
   */
  isBusiness?: boolean;
  /**
   * 标记该异常已被捕获
   *
   * 针对钉钉小程序无法全局捕获异常的处理
   */
  isCaptured?: boolean;
  /**
   * 是否属于从后端请求产生的异常
   */
  isRequestService?: boolean;
  /**
   * 是否需要业务开发自行处理的异常
   */
  isHandleBusiness?: boolean;
  /**
   * 内部错误对象
   */
  innerException?: Exception;
  /**
   * 堆栈信息
   */
  stack?: string;
  [key: string]: any;
}

export interface IBusinessException extends IException {
  /**
   * 消息级别
   *
   * Msg\Info\Warn\Error\Success
   */
  level: string;
  /**
   * 消息标题
   */
  title: string;
}

/**
 * 异常定义
 */
export class Exception extends Error implements IException {
  isBusiness?: boolean;
  isCaptured?: boolean;
  isHandleBusiness?: boolean;
  isRequestService?: boolean;
  innerException?: Exception;
  errorCode?: string;
  data?: any;

  level?: string;
  title?: string;

  constructor(params: IException) {
    const {
      name,
      message,
      data,
      isBusiness,
      isCaptured,
      innerException,
      stack,
      ...restParams
    } = params;
    super(message);
    this.name = name;
    if (data) this.data = data;
    if (stack) this.stack = stack;
    if (isBusiness) this.isBusiness = isBusiness;
    if (isCaptured) this.isCaptured = isCaptured;
    if (innerException) this.innerException = innerException;

    for (const key in restParams) {
      this[key] = restParams[key];
    }
  }

  toJSON() {
    const { toJSON, sender, name, message, stack, ...exception } = this;
    const json = { ...exception, name, message, stack };
    return json;
  }

  [key: string]: any;
}

export class ExceptionHelper {
  static warn: (exception: IBusinessException) => any = exception =>
    JsApiHelper.showToast({ type: 'exception', content: exception.message });
  static error: (exception: Exception, message: string) => any = (ex, message) =>
    console.error(message, ex);

  /**
   * 创建一个Error对象
   * @returns 返回Error对象
   */
  private static create(params: IException): Exception {
    const err = new Exception(params);
    return err;
  }

  private static createBusinessException(name: string, message: string, data?: any): Exception {
    const err = new Exception({ name, message, data });
    err.isBusiness = true;
    return err;
  }

  public static isException(ex: any) {
    return ex instanceof Exception;
  }

  public static convertTo(ex: any) {
    let message = '未知错误';
    if (typeof ex != 'object') {
      const err = new Exception({ name: 'unknownError', message, data: ex });
      return err;
    }
    // if (ex.name === 'passException') message = '你抛出了passException异常';
    ex.message = ex.message || ex.errorMsg || ex.errorMessage || message;
    // if (ex.stackTrace) ex.data = ex.stackTrace;
    const err = new Exception(ex);
    return err;
  }

  /**
   * 创建一个业务异常对象
   * @param message 提示信息
   * @param data 错误的具体信息
   */
  public static businessException(message: string, data?: any): Exception {
    return this.createBusinessException('businessException', message, data);
  }

  /**
   * 创建一个没有权限的异常
   * @param message 指定的异常消息
   */
  public static notRightsException(message: string = '您当前没有操作此功能的权限！') {
    return this.createBusinessException('notRightsException', message);
  }

  /**
   * 创建一个notSupport(平台不支持)的异常对象
   *  @param message 指定的异常消息
   */
  public static notSupportException(message: string = '不支持当前操作！'): Exception {
    return this.create({ name: 'notSupportException', message });
  }

  /**
   * 创建一个参数类异常对象
   * @param argName 发生错误的参数名称
   * @param message 异常消息
   */
  public static argumentException(
    argName: string,
    message: string = `参数${argName}存在错误！`,
  ): Exception {
    return this.create({ name: 'argumentException', message, data: argName });
  }

  /**
   * 创建一个参数为null值的异常对象
   * @param argName 发生错误的参数名称
   * @param message 异常消息
   */
  public static argumentNullException(
    argName: string,
    message: string = `参数[${argName}]不能为null！`,
  ): Exception {
    return this.create({ name: 'argumentNullException', message });
  }

  /**
   * 创建一个没有找到指定对象的异常
   * @param objectType 对象类别
   * @param objectId 对象id
   * @param message 指定的异常信息
   */
  public static notFoundObjectException(
    objectType: string,
    objectId: string,
    message: string = `类别[${objectType}]的[${objectId}]没有找到！`,
  ): Exception {
    return this.create({
      message,
      name: 'notFoundObjectException',
      data: { objectType, objectId },
    });
  }

  /**
   * 创建一个未实现异常对象
   * @param message 提示信息
   */
  public static notImplementException(message: string = '当前操作正在实现中！') {
    return this.createBusinessException('notImplementException', message);
  }

  /**
   * 创建一个校验异常
   * @param message 提示信息
   * @param sender 触发的实例
   * @param itemId 要定位的列Id或子项Id
   * @param rowId 要定位的行Id，仅在GridEdit有效
   */
  public static checkException(message: string, sender: any, itemId: string, rowId?: string) {
    const ex = this.create({ name: 'CheckException', sender, message, itemId, rowId });
    ex.isBusiness = true;
    return ex;
  }

  /**
   * 创建一个用户取消操作异常对象
   * @param message 提示信息
   */
  public static userCanceledException(message: string = '用户取消操作') {
    return this.create({ name: 'userCanceledException', message });
  }

  /**
   * 创建一个pass异常对象
   */
  public static passException(): Exception {
    return this.create({ name: 'passException', message: '' });
  }

  /**
   * 创建一个网络异常对象
   * @param message 异常消息
   */
  public static networkException(message: string = '发生网络错误！'): Exception {
    return this.createBusinessException('networkException', message);
  }

  public static requestServiceException(message: string, data?: any): Exception {
    return this.create({ name: 'requestServiceException', message, data });
  }

  public static jsonParseException(message: string, stack?: string): Exception {
    return this.create({ name: 'jsonParseException', message, stack });
  }

  public static noLogicException(message: string, stack?: string): Exception {
    return this.create({ name: 'noLogicException', message, stack });
  }

  public static resourceException(message: string): Exception {
    return this.create({ name: 'resourceException', message });
  }

  /**
   * 处理当前异常对象
   */
  public static dispose(ex: any) {
    const exception: Exception = this.isException(ex) ? ex : this.convertTo(ex);

    if (exception.name === 'passException' || exception.name === 'userCanceledException') {
      return;
    }

    if (typeof exception.message === 'string' && exception.message.search('签名校验失败') === 0) {
      // 先不呈现报错
      exception.isNotPresent = true;
    }

    if (exception.name === 'CheckException') {
      const { message, sender, itemId, rowId } = exception;
      if (sender && sender.triggervValid) {
        sender.triggervValid(message, itemId, rowId);
      }
      return;
    }

    if (exception.isCaptured) {
      return;
    }

    if (exception.isBusiness) {
      this.warn(exception as any);
      return;
    }

    this.error(exception, `发生内部错误，请联系系统管理员（错误码： {{code}}）!`);
  }
}
