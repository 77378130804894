import GridEdit from '..';
import Store from '@mjcloud/redux';
import GridEditCellReduce from './reduce';
import { AsyncValidator } from '@mjcloud/utils';
import { IGridEditConfigItem } from '../typings';
import { IDictionary, RequiredTypeEnum } from '@mjcloud/types';
import EditableComplexCellBase from '../gridedit-cell-base';
import { ValueInstanceBase, InstanceBase } from '@mjcloud/instance';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux';
import { IDataControlStartLoadParams } from '@mjcloud/instance/dist/dataInstanceBase';
import { IEditableComplexCellState, EditableComplexCellActionType } from './typings';
import {
  IValueState,
  ValueControlActionType,
  IValueControlUpdateValueParams,
} from '@mjcloud/instance/dist/valueInstanceBase';

class EditableComplexCellItemExtendStore {
  constructor(
    private rowId: number,
    private instance: EditableComplexCell,
    private controlInstance: InstanceBase,
  ) {}

  handleUpdateValueBefore(e: ActionBeforeEventArg<IValueControlUpdateValueParams>) {
    const rowId = this.rowId;
    if (rowId) {
      e.params.row = this.instance.parent.dataModel[rowId];
    }
  }

  handleUpdateValueAfter(
    e: ActionAfterEventArg<
      IValueState,
      ValueControlActionType,
      IValueControlUpdateValueParams<any>
    >,
  ) {
    const rowId = this.rowId;
    if (rowId) {
      const { value, text } = e.newState.state as any;
      const field = this.instance.id;
      let updateData: IDictionary = { [field]: value };
      const textFieldName: string | undefined = e.newState.state['textFieldName'];
      // _isObject 是针对区间控件做的多值处理
      if (value && typeof value === 'object' && value._isObject) {
        const { _isObject, ...val } = value;
        updateData = { ...updateData, ...val };
      }
      if (textFieldName) {
        updateData[textFieldName] = text;
      }
      if (this.instance.stores[rowId]) {
        // TODO: 需要查明为什么store还没创建就要执行的Bug
        this.instance.stores[rowId].dispatch('updateValue', { rowId, value, text });
      }
      this.instance.parent.dataModel[rowId].update(updateData, this.instance.parent);
      this.instance.valid(rowId, value);
    } else {
      console.error('EditableComplexCellItemExtendStore:', 'rowId is null');
    }
  }

  handleStartLoadBefore(e: ActionBeforeEventArg<IDataControlStartLoadParams>) {
    const rowId = this.rowId;
    if (rowId) {
      const row = this.instance.parent.dataModel[rowId];
      e.params.data = { rowId, row };
    }
  }
}

export default class EditableComplexCell extends EditableComplexCellBase<
  IEditableComplexCellState,
  EditableComplexCellActionType
> {
  cellType: 'simple' | 'complex' = 'complex';
  controlInstances: IDictionary<InstanceBase> = {};

  constructor(id: string, parent: GridEdit, protected config: IGridEditConfigItem) {
    super(id, parent, config);
  }

  getControlInstance(rowId: number): InstanceBase | null {
    let controlInstance = this.controlInstances[rowId];
    if (!controlInstance) {
      this.config.id = `${this.id}${rowId}`;
      let disabled: boolean | undefined;
      if (!this.parent.store.state.modify) disabled = true;
      controlInstance = this.parent.__createControlByItem(this.config, disabled) as InstanceBase;
      if (controlInstance) {
        controlInstance.store.bindExtendStore(
          new EditableComplexCellItemExtendStore(rowId, this, controlInstance),
        );
      }
      this.controlInstances[rowId] = controlInstance;
    }

    return controlInstance;
  }

  getCellActiveStatus() {
    return true;
  }

  protected __createStore(rowId: number, controlInstance: InstanceBase | null) {
    return new Store<IEditableComplexCellState, EditableComplexCellActionType>({
      id: `cell-${this.id}-${rowId}`,
      reduce: GridEditCellReduce,
      extendStore: {
        handleUpdateColorAfter: this.handleUpdateColorAfter.bind(this),
        handleInitialStateAfter: (e: ActionAfterEventArg<IEditableComplexCellState>) => {
          const { title, value, text, requiredType, type, max, min, len } = e.newState.state,
            { message, whitespace, enum: _enum, pattern } = e.newState.state,
            required = requiredType === RequiredTypeEnum.required,
            _type: ValidationRuleType | undefined = type
              ? type
              : controlInstance instanceof ValueInstanceBase
              ? controlInstance.valueType
              : 'string';
          if (controlInstance instanceof ValueInstanceBase) {
            controlInstance.setValue(value, text);
          }
          if (this.validator) return;
          this.validator = new AsyncValidator(
            title,
            { type: _type, required, max, min, len, whitespace, enum: _enum, pattern, message },
            controlInstance ? controlInstance.eventManager : undefined,
          );
        },
      },
    });
  }

  protected __createCommStore() {
    const store = new Store<IEditableComplexCellState, EditableComplexCellActionType>({
      id: `cell-${this.id}`,
      reduce: GridEditCellReduce,
    });
    return store;
  }
}
