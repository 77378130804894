import { DataStateEnum } from '@mjcloud/types';
import ViewModelHelper from '@mjcloud/data-model';
import { ValueDataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';

export class RecordWorkReduce extends ValueDataReduceBase {
  initialState(store, params) {
    const { initConfig, pageMode } = params,
      {
        addClickController,
        valueField = 'value',
        nameField = 'name',
        unitField = 'unit',
        displayMode,
        modifyMode,
      } = initConfig,
      dataSource = ViewModelHelper.createViewModelCollection();
    const state: any = {
      count: 0,
      nameField,
      unitField,
      valueField,
      dataSource,
      tabIndex: -1,
      rowIdCount: 0,
      isFetching: false,
      config: initConfig,
      addClickController,
      configIsFetching: false,
      _dataSource: dataSource.toArray(true),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
    };
    return state;
  }

  loaded(store, params) {
    const state = { ...store.state },
      { valueField = 'value' } = state,
      { dataState = DataStateEnum.unchanged } = params;
    state.dataSource.initDatas(params.dataSource, dataState);
    state._dataSource = state.dataSource.toArray(true);
    state.count = 0;
    state.isFetching = false;
    state._dataSource.map(item => {
      if (typeof item[valueField] === 'number') {
        state.count += item[valueField];
      } else {
        console.error(`value不是一个有效的number类型：${item[valueField]}`);
      }
    });
    return state;
  }

  updateDataSource(store, params) {
    const { index, value } = params,
      state = { ...store.state },
      { valueField = 'value' } = state,
      rid = state.dataSource.toArray(true)[index]._rid;
    if (typeof value === 'number') {
      state.dataSource[rid][valueField] = value;
    } else if (typeof value === 'string') {
      state.dataSource[rid][valueField] = Number(value);
    } else {
      console.error(`value不是一个有效的number类型：${value}`);
    }
    state.count = 0;
    state.isFetching = false;
    state._dataSource = state.dataSource.toArray(true);
    state._dataSource.map(item => {
      if (typeof item[valueField] === 'number') {
        state.count += item[valueField];
      } else {
        console.error(`value不是一个有效的number类型：${value}`);
      }
    });
    return state;
  }

  removeDataSource(store, params) {
    const { index } = params,
      state = { ...store.state },
      { valueField = 'value' } = state,
      rid = state.dataSource.toArray(true)[index]._rid;
    state.dataSource.removeRow(rid);
    state.count = 0;
    state.isFetching = false;
    state._dataSource = state.dataSource.toArray(true);
    state._dataSource.map(item => {
      if (typeof item[valueField] === 'number') {
        state.count += item[valueField];
      } else {
        console.error(`value不是一个有效的number类型：${item[valueField]}`);
      }
    });
    return state;
  }

  batchAddDataSource(store, params) {
    const { workers } = params,
      state = { ...store.state },
      { valueField = 'value' } = state;
    for (const worker of workers) {
      state.dataSource.addRow(worker);
    }
    state.count = 0;
    state.isFetching = false;
    state._dataSource = state.dataSource.toArray(true);
    state._dataSource.map(item => {
      if (typeof item[valueField] === 'number') {
        state.count += item[valueField];
      } else {
        console.error(`value不是一个有效的number类型：${item[valueField]}`);
      }
    });
    return state;
  }

  deleteRow(store, params) {
    const { dataSource } = store.state;
    dataSource.removeRow(params.rid);
    return { ...store.state };
  }

  deleteAllRow(store, params) {
    const { dataSource } = store.state;
    const list = dataSource.toArray(true);
    for (const item of list) {
      dataSource.removeRow(item._rid);
    }
    return { ...store.state };
  }
}

export default new RecordWorkReduce();
