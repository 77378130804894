import React from 'react';
import { IDictionary } from '@mjcloud/types';
import { IState } from '@mjcloud/redux';
import { ExceptionHelper } from '@mjcloud/exception';
import { InstanceBase } from '@mjcloud/instance';
import { getInstance } from './util';

export interface IControlBaseProps {
  /**
   * 控件Id
   */
  id: string;
  /**
   * 当前控件的父级控件Id
   *
   * 若`parentId == pageKey`即父级控件为页面，反之则表示父级控件为容器控件
   */
  parentId: string;
  // TODO: 临时处理多重容器控件嵌套问题
  workbenchId?: string;
  /**
   * 当前控件所在页面的pageKey
   */
  pageKey: string;
}

interface ErrorBoundaryProps {
  extra: IDictionary;
}
interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: any) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    let message = ' | info: ';
    try {
      message += JSON.stringify({ extra: this.props.extra, info });
    } catch (error) {}
    error.message += message;
    ExceptionHelper.dispose(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return '控件发生内部错误';
    }
    return this.props.children;
  }
}

/**
 * 控件基类
 * @description 控件通过类组件的形态创建
 */
export default abstract class ControlBase<
  S extends IState,
  I extends InstanceBase = InstanceBase,
  P extends IControlBaseProps = IControlBaseProps
> extends React.Component<P, S> {
  instance: I;
  constructor(props: P) {
    super(props);
    const { id, parentId, pageKey, workbenchId } = props;
    this.instance = getInstance<I>(id, parentId, pageKey, workbenchId);
    this.state = this.instance.store.state as any;
    this.instance.store.bindViewListen(this.handStateChange);
  }

  public componentWillUnmount() {
    this.instance.store.unbindViewListen(this.handStateChange);
  }

  shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>) {
    if (nextState !== this.state) return true;
    return false;
  }

  private handStateChange = (state: S, cb?: () => void) => {
    this.setState(state, cb);
  };

  public renderContent(): React.ReactNode | null {
    throw new Error('Method not implemented.');
  }

  public render(): React.ReactNode {
    if (this.state.configIsFetching) return null;
    if (!this.state.display) return null;
    if (this.state.configErrorMessage) return this.state.configErrorMessage;
    return <ErrorBoundary extra={this.state}>{this.renderContent()}</ErrorBoundary>;
  }
}
