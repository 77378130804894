import { IComplexSearchConfig } from './typings';

const config: IComplexSearchConfig = {
  tip: undefined,
  expand: false,
  searchText: '搜索',
  standard: undefined,
  advanced: undefined,
};

export default config;
