import React from 'react';
import Icon from '@mjcloud/icon';
import classNames from 'classnames';
import ControlBase from '../../base';
import { AutoSizer } from 'react-virtualized';
import { Row, Col, Empty, Spin, Divider } from 'antd';
import { IStatisticsState, IStatisticsInfo } from '../typings';
import { isDevicesSize, DevicesSizeEnum } from '../../constant';

import styles from './index.less';

function getItemSpan(width: number): number {
  const size = isDevicesSize(width);
  switch (size) {
    case DevicesSizeEnum.xs:
    case DevicesSizeEnum.sm:
      return 12;

    default:
      return 8;
  }
}

export default class StatisticsControl extends ControlBase<IStatisticsState> {
  renderInfo(info: IStatisticsInfo) {
    const { cols, dataSource } = this.state,
      { labelNumber = 5, labelWidth } = info,
      colLabelClassName = `colLabel${labelNumber}`,
      { _title = [], _rTitle = [], _tags = [], _infoBriefList } = dataSource[0],
      titleLength = _title.length,
      rTitleLength = _rTitle.length,
      span = 24 / cols;
    let colLabelStyle: React.CSSProperties | undefined;
    if (labelWidth != null) {
      colLabelStyle = { width: labelWidth, flex: `0 0 ${labelWidth}px` };
    }
    return (
      <div className={styles.info}>
        <Row className={styles.rowTitle}>
          {_title &&
            _title.map(({ id, style, text }, index) => (
              <React.Fragment key={id}>
                <div key={id} style={style}>
                  {text}
                </div>
                {index !== titleLength - 1 && <Divider type="vertical" />}
              </React.Fragment>
            ))}

          <div className={styles.rowTitleMiddle} />
          {_rTitle &&
            _rTitle.map(({ id, style, text }, index) => (
              <React.Fragment key={id}>
                <div key={id} style={style}>
                  {text}
                </div>
                {index !== rTitleLength - 1 && <Divider type="vertical" />}
              </React.Fragment>
            ))}
        </Row>

        {_infoBriefList.map((items, key) => (
          <Row key={key} gutter={24} className={styles.itemRow} style={{ width: '100%' }}>
            {items.map(({ text, title, style }, index) => (
              <Col span={span} key={index} className={styles.col} style={{ padding: 0 }}>
                <div
                  style={colLabelStyle}
                  className={`${styles.colLabel} ${styles[colLabelClassName]}`}
                >
                  {title}:
                </div>
                <div className={styles.colValue} style={style}>
                  {text}
                </div>
              </Col>
            ))}
          </Row>
        ))}
      </div>
    );
  }

  renderOverview() {
    const { cols, dataSource } = this.state,
      { __infoBriefList } = dataSource[0];
    let colLabelStyle: React.CSSProperties | undefined;
    return (
      <AutoSizer disableHeight>
        {({ width }) => {
          const span = getItemSpan(width);
          return (
            <Row className={styles.overview} style={{ width }}>
              {__infoBriefList.map(({ text, icon, title, style, iconColor }, index) => (
                <Col span={span} key={index} className={styles.col} style={{ padding: 0 }}>
                  <div className={styles.colIcon} style={{ backgroundColor: iconColor }}>
                    <Icon type={icon} />
                  </div>
                  <div className={styles.colRight}>
                    <div style={colLabelStyle} className={styles.colLabel}>
                      {text}
                    </div>
                    <div className={styles.colValue} style={style}>
                      {title}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          );
        }}
      </AutoSizer>
    );
  }

  renderContent() {
    const { info, dataSource, isFetching } = this.state;
    if (dataSource.length === 0) {
      return (
        <div className={classNames(styles.empty)}>
          <Empty />
        </div>
      );
    }
    return (
      <div className={classNames(styles.container)}>
        <Spin spinning={isFetching} style={{ width: '100%' }}>
          {info ? this.renderInfo(info) : this.renderOverview()}
        </Spin>
      </div>
    );
  }
}
