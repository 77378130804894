import Store from '@mjcloud/redux';
import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { ITextboxState, ITextboxInitialStateParams } from './typings';
import { PageModeEnum, ControlDisabledModeEnum } from '@mjcloud/types';

export class TextboxReduce extends ValueReduceBase {
  initialState(store: Store<ITextboxState>, params: ITextboxInitialStateParams): ITextboxState {
    const { initConfig, pageMode } = params,
      { leftIcon, rightIcon, title, autoFocus, modifyMode, button } = initConfig,
      { textFieldName, prefixFieldName, suffixFieldName } = initConfig,
      { value = store.state.value, placeholder = `请输入${title || ''}` } = initConfig,
      { disabledMode = button && ControlDisabledModeEnum.all } = initConfig,
      { maxLength, displayMode, ruleKey, isAutoCode } = initConfig;
    const state: ITextboxState = {
      value,
      title,
      button,
      ruleKey,
      leftIcon,
      rightIcon,
      autoFocus,
      isAutoCode,
      placeholder,
      tabIndex: -1,
      textFieldName,
      prefixFieldName,
      suffixFieldName,
      config: initConfig,
      buttonLoading: false,
      configIsFetching: false,
      maxLength: maxLength || 400,
      //TODO: 目前限制自动编码只在新增页有效
      isUUID: pageMode === PageModeEnum.add,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    return state;
  }

  updateValue(store: Store<ITextboxState>, params) {
    const state = super.updateValue(store, params) as ITextboxState;
    state.text = params.text == null ? state.value : params.text;
    state.prefixValue = params.prefixValue;
    state.suffixValue = params.suffixValue;
    // if (params.isUpdate) state.isUUID = !!params.isUUID;
    return state;
  }

  buttonClickAfter(store: Store<ITextboxState>, params: {}) {
    if (store.state.buttonLoading) {
      return store.state;
    }
    return { ...store.state, loading: true };
  }

  buttonClickDone(store: Store<ITextboxState>, params: {}) {
    if (!store.state.buttonLoading) {
      return store.state;
    }
    return { ...store.state, loading: false };
  }

  updateButtonLoad(store: Store<ITextboxState>, params: { loading: boolean }) {
    if (store.state.buttonLoading === params.loading) {
      return store.state;
    }
    return { ...store.state, buttonLoading: params.loading };
  }
}

export default new TextboxReduce();
