import Store from '@mjcloud/redux';
import { ITreeViewCellState, ITreeViewCellInitialStateParams } from './typings';
import { IReduce } from '@mjcloud/redux';

class TreeViewCellReduce implements IReduce<ITreeViewCellState> {
  initialState(store: Store<ITreeViewCellState>, params: ITreeViewCellInitialStateParams) {
    const { initConfig, rowId } = params;
    const { value, text, special } = initConfig;
    const state: ITreeViewCellState = {
      text,
      value,
      rowId,
      special,
      tabIndex: -1,
      display: true,
      configIsFetching: false,
      config: initConfig,
    };
    return state;
  }

  updateColor(store: Store<ITreeViewCellState>, params) {
    const { color } = params;
    return { ...store.state, color };
  }

  updateValue(store: Store<ITreeViewCellState>, params) {
    const { text, value } = params;
    return { ...store.state, text, value };
  }
}

export default new TreeViewCellReduce();
