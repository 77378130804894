import React from 'react';
import Menu from './Menu';
import ControlBase from '../../core/base';
import { IModuleState, AppMenuConfig } from '@mjcloud/mcontrols/dist/controls/module/typings';

export default class ModuleControl extends ControlBase<IModuleState> {
  private onModuleTap = (module: AppMenuConfig) => {
    if (this.instance.eventManager.getHandlerCount('moduleClick') > 0) {
      this.instance.eventManager.trigger('moduleClick', { module });
    }
  };

  renderContent() {
    const { tile, dataSource } = this.state;
    return <Menu tile={tile} menus={dataSource} onModuleTap={this.onModuleTap} />;
  }
}
