import Page from '@mjcloud/page';
import ComponentBase from './base';
import { IDictionary } from '@mjcloud/types';
import { ComponentConfig } from '@mjcloud/types';
import { ExceptionHelper } from '@mjcloud/exception';

import ValueLinkage from './valueLinkage';
import MarkPinConvert from './markPinConvert';
import FormulaLinkage from './formulaLinkage';
import AmountUpperConvert from './amountUpperConvert';

const components = {
  valueLinkage: ValueLinkage,
  amountUpperConvert: AmountUpperConvert,
  formulaLinkage: FormulaLinkage,
  markPinConvert: MarkPinConvert,
};

export default class ComponentHelper {
  /**
   * 根据配置创建一个组件
   * @param page
   * @param params
   */
  public static create(page: Page, params: ComponentConfig): ComponentBase | null {
    const { id, nodeName, value, ...extra } = params;
    const componentType = components[nodeName];
    if (!componentType) {
      console.warn(`平台不支持 ${nodeName} 组件`);
      // ExceptionHelper.dispose(ExceptionHelper.notImplementException(`平台不支持 ${nodeName} 组件`));
      return null;
    }
    let data: IDictionary = {};
    if (typeof value === 'string' && value) {
      try {
        data = JSON.parse(value);
      } catch (error) {
        ExceptionHelper.dispose(
          ExceptionHelper.jsonParseException(
            `${nodeName} 组件数据： ${value} JSON.parse error`,
            error.stack,
          ),
        );
      }
    }
    return new componentType(id, page, data, extra);
  }

  /**
   * 批量创建组件
   * @param page
   * @param params
   */
  public static batchCreation(page: Page, params: ComponentConfig[]) {
    const _components: IDictionary<ComponentBase> = {};
    for (const item of params) {
      const component = this.create(page, item);
      if (component) {
        _components[item.id] = component;
      }
    }
    return _components;
  }
}
