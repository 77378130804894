import Grid from '.';
import { ActionBeforeEventArg } from '@mjcloud/redux';
import { ContainerExtendStoreBase } from '@mjcloud/extend-store';
import { IGridInitialStateParams } from './typings';

class GridExtendStore extends ContainerExtendStoreBase<Grid> {
  handleInitialStateBefore(e: ActionBeforeEventArg<IGridInitialStateParams>) {
    e.params.createControl = this.instance.createControl.bind(this.instance);
  }
}

export default GridExtendStore;
