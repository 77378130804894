import { ExtendStoreBase } from '@mjcloud/extend-store';
import { ExceptionHelper } from '@mjcloud/exception';
import PageHelper from '@mjcloud/page-helper';
import Service from '@mjcloud/service';
import FileManager from '.';

export default class FileManagerExtendStore extends ExtendStoreBase<FileManager> {
  constructor(instance) {
    super(instance);
    instance.page.eventManager.add('inited', this.pageInited.bind(this));
    instance.page.eventManager.add('done', this.pageDone.bind(this));
  }

  async getBusinessId(businessId) {
    let _businessId;
    if (businessId) {
      const _e = { sender: this.instance, data: {}, eventType: 'getFileList' },
        fn = PageHelper.createPageScriptFunction(this.instance.page, businessId, true);
      _businessId = await fn(_e);
    } else {
      _businessId = this.instance.page.getParam('id');
    }
    if (!_businessId) _businessId = null;
    return _businessId || '0';
  }

  async pageInited() {
    const { businessId, businessType } = this.instance.store.state,
      _businessId = await this.getBusinessId(businessId);
    const uploadProps = await Service.getUploadInfo(_businessId || '', businessType);
    this.instance.store.dispatch('startLoad', {
      uploadProps,
    });
  }

  async pageDone() {
    // const { businessId, businessType } = this.instance.store.state,
    //   _businessId = await this.getBusinessId(businessId);
    // const uploadProps = await Service.getUploadInfo(
    //   _businessId || "",
    //   businessType
    // );
    // this.instance.store.dispatch("startLoad", {
    //   uploadProps
    // });
  }

  async handleStartLoadAfter(e) {
    const { businessId, businessType } = e.newState.state,
      _businessId = await this.getBusinessId(businessId);
    if (_businessId) {
      try {
        const dataSource = await Service.getFileList(_businessId, businessType);
        if (dataSource) {
          e.newState.dispatch('loaded', { ...e.params, dataSource });
        } else {
          throw new Error('获取数据源失败');
        }
      } catch (error) {
        e.newState.dispatch('loadError', {
          ...e.params,
          errorMessage: '数据加载错误',
        });
        ExceptionHelper.dispose(error);
      }
    } else {
      e.newState.dispatch('loaded', { ...e.params, dataSource: [] });
    }
  }
}
