import { DataExtendStoreBase } from '@mjcloud/extend-store';

export default class CalendarExtendStore extends DataExtendStoreBase {
  protected isNeedNotificationPage = true;

  handlePreMonthTapAfter(e) {
    this.instance.refresh();
  }

  handleNextMonthTapAfter(e) {
    this.instance.refresh();
  }

  handleSelectDateAfter(e) {
    this.instance.refresh();
  }

  handleDayTapAfter(e) {
    const { dayindex, weekindex } = e.params;
    const { days } = e.newState.state;
    this.triggerSelectChange(days, dayindex, weekindex);
  }

  handleUpdateDayAfter(e) {
    const { dayindex, weekindex } = e.params;
    if (dayindex != null && weekindex != null) {
      this.instance.store.dispatch('dayTap', { dayindex, weekindex });
    }
  }

  triggerSelectChange(days, dayindex, weekindex) {
    const row = days[dayindex][weekindex];
    if (this.instance.eventManager.getHandlerCount('selectChange') > 0 && row) {
      this.instance.eventManager.trigger('selectChange', { row });
    }
  }
}
