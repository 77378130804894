import 'dingtalk-jsapi/entry/union';

import requestJs from './requestJs';
import { IDictionary } from '@mjcloud/types';
import globalData from '@mjcloud/global-data';
import { CookieHelper } from '@mjcloud/utils';
import { promisifyAll } from './promisify';
import { ExceptionHelper } from '@mjcloud/exception';
import Service, { IFileInfo } from '@mjcloud/service';
import ENV, { CONTAINER_ENUM, ENV_ENUM, APP_TYPE } from './env';
import { ui, biz, device, config, error, runtime, ready } from 'dingtalk-jsapi';
import {
  IDeviceNotificationActionSheetParams,
  IDeviceNotificationActionSheetResult,
} from 'dingtalk-jsapi/api/device/notification/actionSheet';
import { IRuntimePermissionRequestAuthCodeParams } from 'dingtalk-jsapi/api/runtime/permission/requestAuthCode';
import { IBizContactComplexPickerParams } from 'dingtalk-jsapi/api/biz/contact/complexPicker';
import {
  IBizCspacePreviewParams,
  IBizCspacePreviewResult,
} from 'dingtalk-jsapi/api/biz/cspace/preview';
import {
  IBizUtilPreviewImageParams,
  IBizUtilPreviewImageResult,
} from 'dingtalk-jsapi/api/biz/util/previewImage';
import { IBizUtilOpenSlidePanelResult } from 'dingtalk-jsapi/api/biz/util/openSlidePanel';
import { IBizUtilDownloadFileResult } from 'dingtalk-jsapi/api/biz/util/downloadFile';
declare var dd: any;

export interface IDownloadFileParams {
  /**
   * 要下载的文件的url
   */
  url: string;
  /**
   * 定义下载文件名字
   */

  name: string;
  /**
   * 文件下载进度回调
   * @param msg 文件下载进度
   */
  onProgress?(msg: any): void;
}

export interface IRequestAuthenticationParams {
  /**
   * 必填，微应用ID
   */
  agentId: string;
  /**
   * 必填，企业ID
   */
  corpId: string;
  /**
   * 必填，生成签名的时间戳
   */
  timeStamp: string;
  /**
   * 必填，生成签名的随机串
   */
  nonceStr: string;
  /**
   * 必填，签名
   */
  signature: string;
}

export interface IRequestNavigationBarParams {
  /**
   * 导航栏标题
   */
  title?: string;
  /**
   * 导航栏背景色，支持十六进制颜色值
   */
  backgroundColor?: string;
  /**
   * 是否重置导航栏为钉钉默认配色，默认 false
   */
  reset?: boolean;
}

export interface IOpenSlidePanelResult extends IBizUtilOpenSlidePanelResult {
  oper: 'ok' | 'cancel';
}

export interface IInputPlainParams {
  /**
   * 占位符
   */
  placeholder: string;
  /**
   * 默认填充文本
   */
  text?: string;
}

export interface IInputPlainResult {
  text: string;
  oper: 'ok' | 'cancel';
  error?: any;
}

export interface IUtilConfirmParams {
  /**
   * confirm框的标题
   */
  title: string;
  /**
   * confirm框的内容
   */
  content: string;
  /**
   * 确认按钮文字
   */
  confirmButtonText?: string;
  /**
   * 取消按钮文字
   */
  cancelButtonText?: string;
}

export interface IUtilConfirmResult {
  /**
   * 点击 confirm 返回 true，点击 cancel 返回false
   */
  confirm: boolean;
}

export interface IUtilAlertParams {
  message: string;
  description?: string;
  buttonLabels?: string[];
}

export interface IUtilAlertResult {
  /** 被点击按钮的索引值，Number类型，从0开始 */
  buttonIndex: number;
}

export interface IUtilShowToastParams {
  /**
   * 文字内容
   */
  content?: string;
  /**
   * toast 类型，展示相应图标，默认 none，支持 success / fail / exception / none。其中 exception 类型必须传文字信息
   */
  type?: 'none' | 'success' | 'fail' | 'exception';
  /**
   * 显示时长，单位为 ms，默认 2000。按系统规范，android只有两种(<=2s >2s)
   */
  duration?: number;
}

export interface IShowLoadingParams {
  /**
   * 提示的内容
   */
  title: string;
  /**
   * 是否显示透明蒙层，防止触摸穿透
   * @supportVersion  微信小程序 WEB
   *
   */
  mask?: boolean;
  /**
   * 自动关闭的延时，单位秒
   */
  duration?: number;
}

export interface IChooseImageParams {
  /**
   * 最大可选照片数，默认1张
   */
  count?: number;
  /**
   * 相册选取或者拍照，默认 ['camera','album']
   */
  sourceType?: ['camera', 'album'] | ['camera'] | ['album'];
}

export interface IChooseImageResult {
  filePaths: string[];
}

export interface IUploadFileParams {
  /**
   * 开发者服务器地址
   */
  url: string;
  /**
   * 要上传文件资源的本地定位符
   */
  filePath: string;
  /**
   * 文件名，即对应的 key, 开发者在服务器端通过这个 key 可以获取到文件二进制内容
   */
  fileName: string;
  /**
   * 文件类型，image / video
   */
  fileType: 'image' | 'video';
  /**
   * HTTP 请求 Header
   */
  header?: IDictionary;
  /**
   * HTTP 请求中其他额外的 form 数据
   */
  formData?: IDictionary;
}

export interface IUploadFileResult {
  /**
   * 服务器返回的数据
   */
  data: string;
  /**
   * HTTP 状态码
   */
  statusCode: string;
  /**
   * 服务器返回的 header
   */
  header: IDictionary;
}

export interface IRequestParams {
  /**
   * 目标服务器url
   */
  url: string;
  /**
   * 默认GET，目前支持GET，POST
   */
  method: 'GET' | 'POST';
  /**
   * 设置请求的 HTTP 头
   */
  headers: IDictionary;
  /**
   * 超时时间，单位ms，默认30000
   */
  timeout?: number;
  /**
   * 请求参数
   */
  data: IDictionary;
}

export type StoragePositionType = 'cookie' | 'localStorage' | 'sessionStorage';

const { notification, base: deviceBase } = device;
const { permission } = runtime;
const { util, contact, cspace } = biz;

// export { default as Monitor } from './arms';
export { default as ENV, CONTAINER_ENUM, ENV_ENUM, APP_TYPE } from './env';

var wxp: any, ddp: any;
/**
 * 对参数添加_nopl以表明当前jsapi用平台包调用
 */
function paramsAnopl<T extends IDictionary>(params: T) {
  (params as any)._nopl = true;
  return params;
}

export default class JsApiHelper {
  static ENV = ENV;
  static ENV_ENUM = ENV_ENUM;
  static APP_TYPE = APP_TYPE;
  static CONTAINER_ENUM = CONTAINER_ENUM;

  private static initPromisifyExtension() {
    // 扩展小程序api支持promise
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        if (!ddp && dd) ddp = promisifyAll(dd);
        break;
      case APP_TYPE.MINIAPP:
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        if (!wxp && wx) wxp = promisifyAll(wx);
        break;
      default:
        break;
    }
  }

  private static notWxSupportException(apiName: string) {
    return ExceptionHelper.notSupportException(`${apiName}: 微信端待完善该接口，请联系平台开发者!`);
  }

  private static notApiSupportException(apiName: string) {
    return ExceptionHelper.notSupportException(`当前容器不支持此接口: ${apiName}`);
  }

  private static notWebSupportException(apiName: string) {
    return ExceptionHelper.notSupportException(`当前容器中 globalData.${apiName} 不存在`);
  }

  /**
   * 请求授权码，免登改造用
   */
  static async requestAuthCode(params: IRuntimePermissionRequestAuthCodeParams) {
    this.initPromisifyExtension();
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
        return permission.requestAuthCode(params);
      default:
        throw this.notApiSupportException('requestAuthCode');
    }
  }

  static requestAuthentication(params: IRequestAuthenticationParams) {
    const { agentId, corpId, timeStamp, nonceStr, signature } = params;
    config({
      agentId,
      corpId,
      timeStamp,
      nonceStr,
      signature,
      jsApiList: [
        'runtime.permission.requestOperateAuthCode',
        'biz.util.ut',
        'biz.util.open',
        'biz.telephone.quickCallList',
        'biz.ding.post',
        'biz.contact.complexPicker',
        'biz.contact.departmentsPicker',
        'biz.contact.externalComplexPicker',
        'biz.customContact.choose',
        'biz.customContact.multipleChoose',
        'biz.cspace.preview',
      ],
    });

    this.error(async err => {
      err.name = err.name ? `钉钉异常:${err.name}` : '钉钉异常';
      err.data = { ...params, phoneInfo: await this.getPhoneInfo() };
      // await Service.errorLog(err);
      ExceptionHelper.dispose(err);
    });
  }

  static error(callback: (err: any) => void) {
    error(callback);
  }

  static async getPhoneInfo(): Promise<any> {
    if (ENV.isMobile) return deviceBase.getPhoneInfo({});
    return {};
  }

  /**
   * 设置导航栏文字及样式。
   */
  static async setNavigationBar(params: IRequestNavigationBarParams) {
    const { title, backgroundColor, reset } = params;
    this.initPromisifyExtension();
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        await ddp.setNavigationBar({ title, reset, backgroundColor });
        return {};
      case APP_TYPE.MINIAPP:
        await wxp.setNavigationBarTitle({ title });
        await wxp.setNavigationBarColor({ backgroundColor });
        return {};
      case APP_TYPE.WEBVIEW_IN_DDAPP:
        if (ENV.isMobile) {
          await biz.navigation.setTitle({ title });
          return {};
        }
        if (!globalData.__setNavigationBar) throw this.notWebSupportException('__setNavigationBar');
        return globalData.__setNavigationBar(params);
      case APP_TYPE.WEB:
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        if (!globalData.__setNavigationBar) throw this.notWebSupportException('__setNavigationBar');
        return globalData.__setNavigationBar(params);
      default:
        throw this.notApiSupportException('setNavigationBar');
    }
  }

  /**
   * 选人与部门
   * 支持选择部门后，把所选部门转换成对应部门下的人，permissionType可以添加权限校验
   */
  static async complexPicker(params: IBizContactComplexPickerParams) {
    this.initPromisifyExtension();
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
        return contact.complexPicker(params);
      case APP_TYPE.MINIAPP:
      case APP_TYPE.WEBVIEW_IN_WWAPP:
        throw this.notWxSupportException('complexPicker');
      case APP_TYPE.WEBVIEW_IN_WXAPP:
      default:
        throw this.notApiSupportException('complexPicker');
    }
  }

  static async request<T>(params: IRequestParams, source: IDictionary = {}) {
    this.initPromisifyExtension();
    let result: T | undefined,
      { url, data, method, timeout, headers } = params;
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        result = await new Promise<T | undefined>((resolve, fail) => {
          const requestTask = dd.httpRequest({
            url, // 目标服务器url
            method, // 默认GET，目前支持GET，POST
            headers, // 设置请求的 HTTP 头
            timeout, // 超时时间，单位ms，默认30000
            data, // 请求参数
            dataType: 'text', // 期望返回的数据格式，默认json，支持json，text，base64
            success(response: any) {
              if (response.status >= 200 && response.status < 300) {
                try {
                  let data = JSON.parse(response.data);
                  if (data.e) {
                    const ex = ExceptionHelper.convertTo(data.e);
                    ex.isRequestService = true;
                    fail(ex);
                  } else if (data.data) {
                    resolve(data.data);
                  } else {
                    resolve(data);
                  }
                } catch (error) {
                  // const ex = ExceptionHelper.jsonParseException(
                  //   `服务端返回数据解析错误:${response.data}`,
                  //   error.stack,
                  // );
                  // fail(ex);
                  resolve(response.data);
                }
              } else {
                // console.log(response);
              }
            },
            fail(err: any) {
              if (
                err.error == 19 &&
                err.status == 500 &&
                err.data &&
                typeof err.data === 'string'
              ) {
                let data = JSON.parse(err.data);
                if (data.e != null) {
                  const ex = ExceptionHelper.convertTo(data.e);
                  ex.isRequestService = true;
                  ExceptionHelper.dispose(ex);
                  fail(ex);
                } else {
                  fail(err);
                }
              } else {
                fail(err);
              }
            },
          });
          if (requestTask && requestTask.abort) source.cancel = requestTask.abort;
        });
        break;
      case APP_TYPE.MINIAPP:
        result = await new Promise<T | undefined>((resolve, fail) => {
          const requestTask = wx.request({
            url, // 开发者服务器接口地址
            method, // HTTP 请求方法
            timeout, // 超时时间，单位为毫秒
            header: headers, // 设置请求的 header，header 中不能设置 Referer。
            data, // 请求参数
            dataType: '其他', // 返回的数据格式
            responseType: 'text', // 响应的数据类型
            success(response: any) {
              // console.log('wx.request.success', response);
              if (response.statusCode >= 200 && response.statusCode < 300) {
                try {
                  let data = JSON.parse(response.data);
                  if (data.e) {
                    const ex = ExceptionHelper.convertTo(data.e);
                    ex.isRequestService = true;
                    fail(ex);
                  } else if (data.data) {
                    resolve(data.data);
                  } else {
                    resolve(data);
                  }
                } catch (error) {
                  resolve(response.data);
                }
              }
            },
            fail(err: any) {
              // console.log('wx.request.fail', err);
              fail(err);
            },
          });
          if (requestTask && requestTask.abort) source.cancel = requestTask.abort;
        });
        break;
      case APP_TYPE.WEB:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        result = await requestJs<T>(url, method, data, { headers, timeout }, source);
        break;
    }
    return result;
  }

  static async uploadFile(params: IUploadFileParams) {
    this.initPromisifyExtension();
    let result: IUploadFileResult;
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        result = await ddp.uploadFile(params);
        return result;
      default:
        throw this.notApiSupportException('uploadFile');
    }
  }

  /**
   * 选择图片
   */
  static async chooseImage(params: IChooseImageParams) {
    this.initPromisifyExtension();
    let result: IChooseImageResult;
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        result = await ddp.chooseImage(params);
        return result;
      default:
        throw this.notApiSupportException('chooseImage');
    }
  }

  /**
   * 图片浏览器
   * @description 调用此api，将显示一个图片浏览器
   */
  static async previewImage(params: IBizUtilPreviewImageParams) {
    this.initPromisifyExtension();
    let result: IBizUtilPreviewImageResult;
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
        return util.previewImage(params);
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        result = await wxp.previewImage(params);
        return result;
      case APP_TYPE.MINIAPP:
        throw this.notWxSupportException('previewImage');
      default:
        throw this.notApiSupportException('previewImage');
    }
  }

  /**
   * 预览钉盘文件
   */
  private static async previewFileInDingTalk(params: IBizCspacePreviewParams) {
    // let result: IBizCspacePreviewResult;
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        return cspace.preview(params);
      case APP_TYPE.WEBVIEW_IN_DDAPP:
        const { appId, corpId } = globalData,
          { search, origin } = window.location;
        await Service.ddConfig({ appId, corpId, url: encodeURIComponent(origin + search) });
        return new Promise<IBizCspacePreviewResult>((resolve, reject) => {
          ready(() =>
            cspace
              .preview(params)
              .then(resolve)
              .catch(reject),
          );
        });
      default:
        throw this.notApiSupportException('previewFileInDingTalk');
    }
  }

  private static async ddPreviewFile(file: IFileInfo) {
    const { id, size: fileSize, type, name: fileName } = file;
    const previewInfo = await Service.previewFileInDingtalk(id);
    if (previewInfo) {
      const { spaceId, fileId } = previewInfo,
        fileType = type && type.substring(1);
      if (fileType) {
        return JsApiHelper.previewFileInDingTalk({
          corpId: globalData.corpId,
          spaceId,
          fileId,
          fileName,
          fileSize,
          fileType,
        });
      } else {
        throw ExceptionHelper.argumentNullException('fileType');
      }
    } else {
      throw ExceptionHelper.argumentNullException('previewInfo');
    }
  }

  /**
   * 预览文件
   */
  static async previewFile(file: IFileInfo): Promise<IBizCspacePreviewResult> {
    this.initPromisifyExtension();
    const { url, name, size } = file;
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
        return this.ddPreviewFile(file);
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        await wxp.previewFile({ url, name, size });
        return {};
      // case APP_TYPE.WEB:
      //   const __previewFile = () => {
      //     if (!globalData.__previewFile) throw this.notWebSupportException('__previewFile');
      //     return new Promise<IBizCspacePreviewResult>((resolve, fail) => {
      //       globalData.__previewFile({
      //         file,
      //         onOk() {
      //           resolve({ confirm: true });
      //         },
      //         onCancel() {
      //           resolve({ confirm: false });
      //         },
      //       });
      //     });
      //   };
      //   return __previewFile();
      case APP_TYPE.MINIAPP:
        throw this.notWxSupportException('previewFile');
      default:
        throw this.notApiSupportException('previewFile');
    }
  }

  /**
   * 下载文件
   */
  static async downloadFile(params: IDownloadFileParams) {
    this.initPromisifyExtension();
    let result: IBizUtilDownloadFileResult;
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
        try {
          result = await biz.util.downloadFile(params);
        } catch (error) {
          const { errorCode } = error;
          if (errorCode == '-1' || errorCode == '22') {
            result = { oper: 'cancel' } as IOpenSlidePanelResult;
            return result;
          }
          error.message = '当前容器调用 downloadFile 接口发生未知错误';
          throw error;
        }
        return result;
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
      case APP_TYPE.WEB:
        window.open(params.url);
        result = {};
        return result;
      default:
        throw this.notApiSupportException('downloadFile');
    }
  }

  /**
   * ActionSheet控件 单选列表
   */
  static async showActionSheet(
    params: IDeviceNotificationActionSheetParams,
  ): Promise<IDeviceNotificationActionSheetResult> {
    this.initPromisifyExtension();
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
        return notification.actionSheet(params);
      default:
        throw this.notApiSupportException('showActionSheet');
    }
  }

  private static async __openSlidePanel(title: string, url: string) {
    if (ENV.isDingTalk && ENV.platform === ENV_ENUM.pc) {
      try {
        const result = await biz.util.openSlidePanel({ title, url });
        return { oper: 'ok', ...result } as IOpenSlidePanelResult;
      } catch (error) {
        const { errorCode } = error;
        if (errorCode == '-1' || errorCode == '22') {
          return { oper: 'cancel' } as IOpenSlidePanelResult;
        }
        error.message = '当前容器调用 openSlidePanel 接口发生未知错误';
        throw error;
      }
    } else {
      throw this.notApiSupportException('openSlidePanel');
    }
  }

  /**
   * 在新窗口上打开链接
   * @param url 要打开链接的地址
   */
  static async openLink(url: string) {
    this.initPromisifyExtension();
    if (ENV.isDingTalk) {
      return util.openLink({ url });
    } else {
      window.open(url);
    }
  }

  /**
   * 打开侧边框
   */
  static async openSlidePanel(title: string, url: string): Promise<IOpenSlidePanelResult> {
    this.initPromisifyExtension();
    if (ENV.isDingTalk && ENV.platform === ENV_ENUM.pc) {
      return this.__openSlidePanel(title, url);
    } else {
      throw this.notApiSupportException('openSlidePanel');
    }
  }

  static async quitSlidePanel(message: string) {
    this.initPromisifyExtension();
    if (ENV.isDingTalk && ENV.platform === ENV_ENUM.pc) {
      const result = await biz.navigation.quit({ message });
      return result;
    } else {
      throw this.notApiSupportException('quitSlidePanel');
    }
  }

  /**
   * 输入框（单行）
   */
  static async inputPlain(params: IInputPlainParams): Promise<IInputPlainResult> {
    this.initPromisifyExtension();
    const { placeholder, text } = params,
      __inputPlain = () => {
        if (!globalData.__inputPlain) throw this.notWebSupportException('__inputPlain');
        return globalData.__inputPlain(placeholder, text);
      };
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
        if (!ENV.isMobile) return __inputPlain();
        try {
          const { text } = await ui.input.plain(params);
          return { text, oper: 'ok' };
        } catch (error) {
          return { text: '', oper: 'cancel', error };
        }
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
      case APP_TYPE.WEB:
        return __inputPlain();
      default:
        throw this.notApiSupportException('inputPlain');
    }
  }

  /**
   * 弹窗confirm
   */
  static async confirm(params: IUtilConfirmParams): Promise<IUtilConfirmResult> {
    this.initPromisifyExtension();
    const { title, content, confirmButtonText = '确定', cancelButtonText = '取消' } = params;
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
        // if (!ENV.isMobile) return __confirm();
        try {
          const result = await notification.confirm({
            title,
            message: content,
            buttonLabels: [confirmButtonText, cancelButtonText],
          });
          return { confirm: result.buttonIndex === 0 };
        } catch (error) {
          return { confirm: false };
        }
      case APP_TYPE.WEB:
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        const __confirm = () => {
          if (!globalData.__confirm) throw this.notWebSupportException('__confirm');
          return new Promise<IUtilConfirmResult>((resolve, fail) => {
            globalData.__confirm({
              title,
              content,
              okText: confirmButtonText,
              cancelText: cancelButtonText,
              onOk() {
                resolve({ confirm: true });
              },
              onCancel() {
                resolve({ confirm: false });
              },
            });
          });
        };
        return __confirm();
      default:
        throw this.notApiSupportException('confirm');
    }
  }

  /**
   * Toast
   */
  static async showToast(params: IUtilShowToastParams): Promise<{}> {
    const { content, type = 'none', duration = 3000 } = params;
    this.initPromisifyExtension();
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        await ddp.showToast({ type, content, duration });
        return {};
      case APP_TYPE.MINIAPP:
        await wxp.showToast({ title: content, duration, mask: true });
        return {};
      case APP_TYPE.WEB:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        if (!globalData.__message) throw this.notWebSupportException('__message');
        return globalData.__message(type, content, duration);
      default:
        throw this.notApiSupportException('showToast');
    }
  }

  static async showLoading(params: IShowLoadingParams) {
    const { title, mask = false } = params;
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        return ddp.showLoading({ content: title });
      case APP_TYPE.MINIAPP:
        return wxp.showLoading({ title, mask });
      case APP_TYPE.WEB:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        if (!globalData.__showLoading) throw this.notWebSupportException('__showLoading');
        globalData.__showLoading({ content: title, duration: 0, mask });
        return {};
      default:
        throw this.notApiSupportException('showLoading');
    }
  }

  static hideLoading() {
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        ddp.hideLoading();
        break;
      case APP_TYPE.MINIAPP:
        wxp.hideLoading();
        break;
      case APP_TYPE.WEB:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        if (!globalData.__hideLoading) throw this.notWebSupportException('__hideLoading');
        globalData.__hideLoading();
        return {};
      default:
        throw this.notApiSupportException('hideLoading');
    }
  }

  /**
   * Alert 警告提示
   */
  static async alert(params: IUtilAlertParams): Promise<IUtilAlertResult> {
    const { message, description, buttonLabels = [] } = params;
    this.initPromisifyExtension();
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        const buttonText = buttonLabels.length > 0 ? buttonLabels[0] : undefined;
        await ddp.alert({ title: message, content: description, buttonText });
        return { buttonIndex: 0 };
      case APP_TYPE.MINIAPP:
        throw this.notWxSupportException('alert');
      case APP_TYPE.WEB:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        if (!globalData.__alert) throw this.notWebSupportException('__alert');
        return globalData.__alert(message, description, buttonLabels);
      default:
        throw this.notApiSupportException('alert');
    }
  }

  static async getStorage(
    key: string,
    position: StoragePositionType = 'localStorage',
  ): Promise<IDictionary | string | Array<any> | null> {
    this.initPromisifyExtension();
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        const result = await ddp.getStorage({ key });
        return result.data;
      case APP_TYPE.MINIAPP:
        throw this.notWxSupportException('getStorage');
      case APP_TYPE.WEB:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        let value: string | null = null;
        switch (position) {
          case 'localStorage':
            if (localStorage) value = localStorage.getItem(key);
            else throw this.notApiSupportException('getStorage');
            break;
          case 'sessionStorage':
            if (sessionStorage) value = sessionStorage.getItem(key);
            else throw this.notApiSupportException('getStorage');
            break;
          case 'cookie':
          default:
            if (document && document.cookie != null) value = CookieHelper.getItem(key);
            else throw this.notApiSupportException('getStorage');
            break;
        }
        if (value && value != 'undefined' && value != 'null') {
          try {
            return JSON.parse(value);
          } catch (error) {
            const ex = ExceptionHelper.jsonParseException(
              `getStorage JSON.parse异常 ${value}`,
              error.stack,
            );
            ExceptionHelper.dispose(ex);
            return null;
          }
        }
        return null;
      default:
        throw this.notApiSupportException('getStorage');
    }
  }

  static removeStorage(key: string, position: StoragePositionType = 'localStorage') {
    this.initPromisifyExtension();
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        ddp.removeStorage({ key });
        break;
      case APP_TYPE.MINIAPP:
        throw this.notWxSupportException('removeStorage');
      case APP_TYPE.WEB:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        switch (position) {
          case 'localStorage':
            if (localStorage) localStorage.removeItem(key);
            else throw this.notApiSupportException('removeStorage');
            break;
          case 'sessionStorage':
            if (sessionStorage) sessionStorage.removeItem(key);
            else throw this.notApiSupportException('removeStorage');
            break;
          case 'cookie':
          default:
            if (document && document.cookie != null) CookieHelper.removeItem(key);
            else throw this.notApiSupportException('removeStorage');
            break;
        }
        break;
      default:
        throw this.notApiSupportException('removeStorage');
    }
  }

  static async setStorage(
    key: string,
    value: IDictionary | string | Array<any>,
    position: StoragePositionType = 'localStorage',
  ) {
    this.initPromisifyExtension();
    switch (ENV.appType) {
      case APP_TYPE.EAPP:
        const result = await ddp.setStorage({ key, data: value });
        return result.data;
      case APP_TYPE.MINIAPP:
        throw this.notWxSupportException('setStorage');
      case APP_TYPE.WEB:
      case APP_TYPE.WEBVIEW_IN_DDAPP:
      case APP_TYPE.WEBVIEW_IN_WWAPP:
      case APP_TYPE.WEBVIEW_IN_WXAPP:
        try {
          value = JSON.stringify(value);
        } catch (error) {
          const emg = `getStorage JSON.stringify 异常: ${value}`,
            ex = ExceptionHelper.jsonParseException(emg, error.stack);
          ExceptionHelper.dispose(ex);
          return;
        }
        switch (position) {
          case 'localStorage':
            if (localStorage) localStorage.setItem(key, value);
            else throw this.notApiSupportException('setStorage');
            break;
          case 'sessionStorage':
            if (sessionStorage) sessionStorage.setItem(key, value);
            else throw this.notApiSupportException('setStorage');
            break;
          case 'cookie':
          default:
            if (document && document.cookie != null) CookieHelper.setItem(key, value);
            else throw this.notApiSupportException('setStorage');
            break;
        }
        break;
      default:
        throw this.notApiSupportException('setStorage');
    }
  }
}
