import React from 'react';
import styled from 'styled-components';
import { Size, CollectionProps, CollectionCellSizeAndPositionGetter } from 'react-virtualized';

const Container = styled.div`
  box-sizing: border-box;
  direction: ltr;
  position: relative;
  will-change: transform;
  overflow: auto;
`;

const InnerScrollContainer = styled.div`
  overflow: hidden;
  position: absolute;
`;

function calculateSizeAndPositionData(
  cellCount: number,
  cellSizeAndPositionGetter: CollectionCellSizeAndPositionGetter,
): Size {
  let height = 0;
  let width = 0;

  for (let index = 0; index < cellCount; index++) {
    const cellMetadatum = cellSizeAndPositionGetter({ index });

    if (
      cellMetadatum.height == null ||
      isNaN(cellMetadatum.height) ||
      cellMetadatum.width == null ||
      isNaN(cellMetadatum.width) ||
      cellMetadatum.x == null ||
      isNaN(cellMetadatum.x) ||
      cellMetadatum.y == null ||
      isNaN(cellMetadatum.y)
    ) {
      throw Error(
        `Invalid metadata returned for cell ${index}:
        x:${cellMetadatum.x}, y:${cellMetadatum.y}, width:${cellMetadatum.width}, height:${cellMetadatum.height}`,
      );
    }

    height = Math.max(height, cellMetadatum.y + cellMetadatum.height);
    width = Math.max(width, cellMetadatum.x + cellMetadatum.width);
  }

  return {
    height,
    width,
  };
}

const Collection: React.FC<CollectionProps> = props => {
  const {
      className,
      width,
      height,
      cellCount,
      cellRenderer,
      cellSizeAndPositionGetter,
      scrollLeft,
      scrollTop,
    } = props,
    style: React.CSSProperties = { ...props.style, width, height },
    innerStyle: React.CSSProperties = {
      left: scrollLeft && -scrollLeft,
      top: scrollTop && -scrollTop,
      ...calculateSizeAndPositionData(cellCount, cellSizeAndPositionGetter),
    };
  return (
    <Container className={className} style={style}>
      <InnerScrollContainer style={innerStyle}>
        {Array.from({ length: cellCount }, (_, i) => i).map(v => {
          const { width, height, x: left, y: top } = cellSizeAndPositionGetter({ index: v });
          return cellRenderer({
            key: v,
            index: v,
            isScrolling: true,
            style: { width, height, left, top, position: 'absolute' },
          });
        })}
      </InnerScrollContainer>
    </Container>
  );
};
export default Collection;
