import React from 'react';
import { Tooltip } from 'antd';
import globalData from '@mjcloud/global-data';
import { IEditableCellProps, IEditableCellState } from '../typings';
import EditableCellBaseControl from '../../gridedit-cell-base/pc';
import { TooltipPlacement } from 'antd/lib/tooltip';

export default class EditableCellControl extends EditableCellBaseControl<
  IEditableCellProps,
  IEditableCellState
> {
  handleClick = (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
    const { rowId, store, cellId, parentModify, onClick } = this.props;
    if (onClick) onClick(event);
    if (!parentModify) return;
    if (this.state.readonly) return;
    if (this.state.active) return;
    store.parent.dispatch('activeCell', { cellId, rowId });
  };

  renderContent() {
    const { ControlFactory } = globalData;
    const { active, help, text } = this.state;
    const { id, parentId, pageKey } = this.props;
    let placement: TooltipPlacement = 'topLeft';
    if (this.props.style && this.props.style.textAlign === 'right') {
      placement = 'topRight';
    }
    return active ? (
      <ControlFactory id={id} parentId={parentId} pageKey={pageKey} />
    ) : (
      <Tooltip title={help ? help : text} placement={placement}>
        <div className={this.valueWrapClassName}>{text}</div>
      </Tooltip>
    );
  }
}
