import { IGridEditConfig } from './typings';
import controlDefaults from '../button/controlDefaults';
import { IButtonConfig } from '../button/typings';

const config: IGridEditConfig = {
  data: {},
  orderby: [],
  fastnessCount: 0,
  mayCustom: false,
  showOrdinal: true,
  buttons: undefined,
  showSummary: false,
  collapseMenu: false,
  items: { items: [] },
  displayMode: undefined,
  disabledMode: undefined,
  addRowDisplayMode: undefined,
  addRowButton: undefined,
};

export const addRowButtonControlDefaults: IButtonConfig = {
  ...controlDefaults,
  title: '添加',
  icon: 'plus',
};

export default config;
