import JsApiHelper from '@mjcloud/jsapi';
import RbCore from '@mjcloud/core-helper';
import PageHelper from '@mjcloud/page-helper';
import RouterHelper from '@mjcloud/router-helper';
// import { ConsoleHelper } from '@mjcloud/utils';
import { IDictionary, IAppInfo, IUserInfoConfig } from '@mjcloud/types';

interface LocationDescriptorObject {
  pathname?: string;
  state?: any;
}

interface IBreadcrumbItem {
  name: string;
  location: LocationDescriptorObject;
}

class Location {
  hash: string = '';
  host: string = '';
  hostname: string = '';
  href: string = '';
  origin: string = '';
  pathname: string = '';
}

class Storage {
  async get(key: string): Promise<IDictionary | string | Array<any> | null> {
    const userInfo = globalData.rbCore.userInfo;
    if (userInfo) {
      const { id = 'default' } = userInfo as any;
      return JsApiHelper.getStorage(`${globalData.appId}_${id}_${key}`, 'localStorage');
    }
    return null;
  }

  async set(key: string, value: any) {
    const userInfo = globalData.rbCore.userInfo;
    if (userInfo) {
      const { id = 'default' } = userInfo as any;
      await JsApiHelper.setStorage(`${globalData.appId}_${id}_${key}`, value, 'localStorage');
    }
    return true;
  }

  get identities() {
    const userInfo = globalData.rbCore.userInfo;
    if (userInfo) {
      return userInfo.identities;
    }
  }

  async getIdentityCode() {
    const appId = globalData.appId;
    return globalData.storage.get(`${appId}_identityCode`);
  }

  async setIdentityCode(code: string) {
    const appId = globalData.appId;
    return globalData.storage.set(`${appId}_identityCode`, code);
  }
}

class TestApi {
  constructor(public globalData: IGlobalData) {}

  registeredErrorCallback(cb: Function) {
    globalData.__TestApiErrorCallback = cb;
  }

  getMenuList() {
    return PageHelper.getMenuList(this.globalData.appId);
  }

  clickMenu(name: string) {
    let _cb: () => any | undefined;
    const pathname = PageHelper.formatMemuPath({ appId: this.globalData.appId, moduleId: name });
    RouterHelper.push({
      pathname,
      afterCallback: () => {
        if (_cb) {
          this.currentPage.eventManager.add('done', _cb);
        }
      },
    });
    return {
      ready: (cb: Function) => {
        _cb = () => setTimeout(cb, 400);
      },
    };
  }

  assertion(type: string) {
    const className = `ant-message-custom-content ant-message-${type}`;
    return document && document.getElementsByClassName(className).length > 0;
  }

  get currentPage() {
    return this.globalData.rbCore.top;
  }
}

/**
 * 平台全局变量存储区,不放在windows下以兼容小程序的应用
 */
class IGlobalData {
  /**
   * 当前用户所在组织的corpId
   */
  corpId: string = '';
  /**
   * 当前应用的appId
   */
  appId: string = '';
  isHttps: boolean = false;
  /**
   * 全局的持久化存储, 根据当前用户长期保存
   */
  readonly storage: Storage = new Storage();
  /**
   * 当前应用的应用信息，包含 模块列表
   */
  appInfo: IAppInfo = undefined as any;
  /**
   * 当前应用的用户信息
   */
  userInfo: IUserInfoConfig = undefined as any;
  /**
   * 核心实例，全局管理页面实例、应用权限信息、用户信息
   */
  rbCore: RbCore = undefined as any;

  __breadcrumb: IBreadcrumbItem[] = [];

  /**
   * __dingyun_global_data__.__initTestAPI();
   */
  __initTestAPI() {
    if (window) {
      const dy_global_data = new TestApi(this);
      window['api'] = {
        dy_global_data,
        ready: (cb: Function) => {
          cb.bind(dy_global_data)();
        },
      };
    }
  }

  private _debugger: boolean = false;

  /**
   * 当前是否开启调试模式
   */
  get debugger() {
    return this._debugger;
  }

  /**
   * 当前是否开启调试模式
   */
  set debugger(_debugger: boolean) {
    this._debugger = _debugger;
    // ConsoleHelper.debugger = _debugger;
    // if (_debugger === false) ConsoleHelper.isEnable = false;
  }

  /**
   * 控件类型列表
   */
  controlTypes: IDictionary = {};
  /**
   * 页面逻辑页路由表，针对小程序无法远程获取逻辑页的解决方案
   */
  logicClassList: IDictionary = {};
  /**
   * 模板逻辑页路由表，针对小程序无法远程获取逻辑页的解决方案
   */
  templateClassList: IDictionary = {};
  /**
   * 包含有关当前 URL 的信息
   */
  location: Location = new Location();
  /**
   * 模态对话框。
   */
  confirm: any;
  /**
   * 全局展示操作反馈信息。
   */
  message: any;
  /**
   * 数据大屏moduleId
   */
  DataCockpit: string = 'DataCockpit';
  /**
   * 登录前地址
   */
  preLoginAddress?: string;

  // /**
  //  * 控件工厂组件，仅可在web端使用
  //  */
  // ControlFactory: React.ComponentType<IControlBaseProps> = () => null;

  [key: string]: any;
}

/**
 * 平台全局变量存储区,不放在windows下 以兼容小程序的应用
 */
const globalData = new IGlobalData();

export default globalData;
