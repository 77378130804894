import Tabs from '../controls/tabs/controlDefaults';
import Chart from '../controls/chart/controlDefaults';
import Number from '../controls/number/controlDefaults';
import Filterbar from '../controls/filterbar/controlDefaults';
import Accordion from '../controls/accordion/controlDefaults';
import Statistics from '../controls/statistics/controlDefaults';
import SimpleSearch from '../controls/simple-search/controlDefaults';

const controlTypes = { Tabs, Accordion, Filterbar, Chart, Number, Statistics, SimpleSearch };

export default controlTypes;
