import Reduce from './reduce';
import Store from '@mjcloud/redux';
import GridExtendStore from './extendStore';
import { ContainerInstanceBase } from '@mjcloud/instance';
import { IGridState, IGridConfig, IGridConfigItem, GridEventType, GridActionType } from './typings';

class Grid extends ContainerInstanceBase<IGridState, GridEventType, GridActionType> {
  __createStore() {
    return new Store<IGridState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new GridExtendStore(this),
    });
  }

  initialState(initConfig: IGridConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  createControl(config: IGridConfigItem) {
    const { id: controlId, control } = config;
    if (controlId) {
      if (control) {
        if (!control.nodeName) {
          console.error(`容器控件Grid-${this.id} 中的控件 ${controlId} 中不存在nodeName节点`);
          return null;
        }
        return super.__createControl(controlId, control);
      } else {
        return this.page.createControl(controlId);
      }
    } else {
      console.error(`容器控件Grid-${this.id} 中的控件 controlId 不存在`);
      return null;
    }
  }
}

export default Grid;
