import Store from '@mjcloud/redux';
import Reduce from './reduce';
import { drawChart } from './util';
import ChartExtendStore from './extendStore';
import { DataInstanceBase } from '@mjcloud/instance';
import { DataModeEnum } from '@mjcloud/types';
import { IChartState } from './typings';

export default class Chart extends DataInstanceBase<IChartState> {
  chart: any;
  __createStore() {
    return new Store<IChartState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new ChartExtendStore(this),
    });
  }

  getDataMode() {
    if (this.store.state.pageSize) return DataModeEnum.pagination;
    return DataModeEnum.all;
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  __drawChart() {
    if (this.chart) {
      this.chart.clear();
      drawChart(this.chart, this.store);
    }
  }

  __createChart(chart: any) {
    this.chart = chart;
    drawChart(this.chart, this.store);
  }
}
