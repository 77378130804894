import ReduceBase from '../base';
import Store from '@mjcloud/redux';
import {
  IValueState,
  IValueControlUpdateValueParams,
  IValueControlUpdateDisabledParams,
} from '@mjcloud/instance/dist/valueInstanceBase';

export default abstract class ValueReduceBase<
  TValue = string,
  S extends IValueState<TValue> = IValueState<TValue>
> extends ReduceBase<S> {
  updateReadonly(store: Store<S>, params: { readonly: boolean }) {
    const state: S = { ...store.state, readonly: params.readonly };
    return state;
  }

  updateValue(store: Store<S>, params: IValueControlUpdateValueParams<TValue>) {
    const { value, text, noUpdate } = params as any;
    const state: S = store.state;
    state.text = text;
    state.value = value;
    return noUpdate ? state : { ...state };
  }

  updateDisabled(store: Store<S>, params: IValueControlUpdateDisabledParams) {
    const state: S = { ...store.state, disabled: params.disabled };
    return state;
  }
}
