import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import ControlBase from '../../base';
import { IDatetimeState } from '../typings';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { TimePicker, DatePicker } from '../../../components';

class DatetimeControl extends ControlBase<IDatetimeState> {
  private handChange = (value: Dayjs | null) => {
    const params = {
      text: null,
      value: value ? value.toDate() : null,
      actionSourceSign: this.instance.store,
    };
    this.instance.store.dispatch('updateValue', params);
  };

  private disabledDate = (current: Dayjs | undefined): boolean => {
    if (current) {
      const { max, min } = this.state;
      if (min && current.isBefore(min)) return true;
      if (max && current.isAfter(max)) return true;
    }

    return false;
  };

  renderContent() {
    const { readonly, text } = this.state;
    if (readonly) {
      return <>{text ? text : ''}</>;
    }
    const { mode, autoFocus, value: _value, disabled, placeholder, format, showTime } = this.state;
    const value: any = _value ? dayjs(_value) : undefined;
    const datetimeProps = {
      value,
      format,
      showTime,
      disabled,
      autoFocus,
      placeholder,
      id: this.props.id,
      defaultValue: value,
      defaultOpen: autoFocus,
      onChange: this.handChange,
    };
    if (mode === 'time') {
      return <TimePicker {...datetimeProps} locale={locale} />;
    }
    return (
      <DatePicker
        {...datetimeProps}
        locale={locale}
        disabledDate={this.disabledDate}
        style={{ width: '100%' }}
      />
    );
  }
}
export default DatetimeControl;
