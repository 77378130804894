import Store from '@mjcloud/redux';
import globalData from '@mjcloud/global-data';
import { px2rem } from '../../core/util';
import { CSSProperties } from '../../type';
import { DataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { IDictionary, DataModeEnum } from '@mjcloud/types';
import { DateHelper, NumberHelper, ArrayHelper } from '@mjcloud/utils';
import { BASEFONTSIZE, TABLECOLUMNWIDTH } from '../../core/constant';
import {
  IGridViewState,
  IGridViewColumn,
  IGridViewTableConfig,
  IGridViewInitialStateParams,
} from './typings';
type handleColorCommandFn = (colorCommand: string, _item: IDictionary) => string;
const colors = ['#1890ff', '#f5222d', '#faad14', '#52c41a', '#ddd'];
// const formula = FormulaHelper.create('');
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
function isUrl(path: string): boolean {
  return reg.test(path) || path.indexOf('//') === 0;
}

function formatGridViewItem(value: any, type: string, config: any) {
  if (value == null) return '-';
  switch (type) {
    case 'datetime':
      const { format: dateFormat } = config;
      return DateHelper.format(value, dateFormat);
    case 'number':
      const { format: numberFormat = '#,##0.00' } = config;
      if (typeof value === 'number' || typeof value === 'string') {
        value = NumberHelper.format(value, numberFormat);
        return value;
      }
      return value;
    case 'hyperlink':
    case 'label':
      const { format2Number, format2Date } = config;
      if (format2Number && typeof value === 'number') {
        value = NumberHelper.format(value, format2Number);
        return value;
      } else if (format2Date) {
        return DateHelper.format(value, format2Date);
      }
      return value;
    default:
      break;
  }
  return value;
}

function format2style1(
  _item: IDictionary,
  columns: IGridViewColumn[],
  handleColorCommand: handleColorCommandFn,
) {
  _item._briefList = [];
  const _leftList: any[] = [],
    _rightList: any[] = [];

  for (const { slot, title, field, control, colorCommand } of columns) {
    const color = handleColorCommand(colorCommand, _item),
      style = color && `color: ${color};`,
      style2react: CSSProperties = { color },
      type = control && control.nodeName,
      text = formatGridViewItem(_item[field], type, control);
    switch (slot) {
      case 'title':
        _item._title = { style, style2react, text };
        break;
      case 'status':
        _item._extra = { style, style2react, text };
        break;
      case 'left':
        _leftList.push({ style, style2react, text: `${title}: ${text}` });
        break;
      case 'right':
        _rightList.push({ style, style2react, text: `${title}: ${text}` });
        break;
      default:
        break;
    }
  }
  let length = _leftList.length;
  if (length < _rightList.length) {
    length = _rightList.length;
  }
  for (let index = 0; index < length; index++) {
    _item._briefList.push({
      left: _leftList[index] || '',
      right: _rightList[index] || '',
    });
  }
  return _item;
}

function format2style2(
  _item: IDictionary,
  columns: IGridViewColumn[],
  handleColorCommand: handleColorCommandFn,
) {
  _item._briefList = [];
  const className = 'dy-font-elipsis dy-grid-view-style2-brief';
  for (const { slot, title, field, control, colorCommand } of columns) {
    const color = handleColorCommand(colorCommand, _item),
      style = color && `color: ${color};`,
      style2react: CSSProperties = { color },
      type = control && control.nodeName,
      text = formatGridViewItem(_item[field], type, control);
    switch (slot) {
      case 'icon':
        _item._icon = { style, style2react, text };
        break;
      case 'title':
        _item._title = { style, style2react, text };
        break;
      case 'subtitle':
        _item._subtitle = { style, style2react, text };
        _item._briefList.push({ style, style2react, text, className });
        break;
      case 'brief':
        _item._briefList.push({ style, style2react, text: `${title}: ${text}`, className });
        break;
      default:
        break;
    }
  }
  return _item;
}

function format2normal(
  _item: IDictionary,
  normal: IDictionary,
  columns: IGridViewColumn[],
  handleColorCommand: handleColorCommandFn,
) {
  const {
    cols = 1,
    avatar = [],
    title = [],
    subtitle = [],
    extra = [],
    status = [],
    buttons = [],
    tags = [],
    footer = [],
  } = normal || {};
  let isConfig = false,
    _briefList: any[] = [],
    _leftList: any[] = [],
    _middleList: any[] = [],
    _rightList: any[] = [];
  _item._cols = cols;
  _item._avatar = {};
  _item._tags = [];
  _item._footer = [];
  _item._buttons = [];
  for (const { id, title: _title, field, control, colorCommand } of columns) {
    isConfig = false;
    const color = handleColorCommand(colorCommand, _item),
      type = control && control.nodeName,
      text = formatGridViewItem(_item[field], type, control),
      _row: any = { text, title: _title };
    if (type === 'hyperlink') {
      _row.control = control;
      _row.style = color ? `color: ${color};` : `color: #4d96f2;`;
      _row.style2react = color ? { color } : { color: '#4d96f2' };
    } else {
      _row.style = color && `color: ${color};`;
      _row.style2react = { color };
    }
    for (const _avatar of avatar) {
      if (id === _avatar.id) {
        isConfig = true;
        if (_avatar.text) {
          _item._avatar = {
            text: _item[_avatar.text],
          };
          if (isUrl(_avatar.text)) {
            _item._avatar.url = text;
          } else {
            _item._avatar.icon = text;
          }
        }
      }
    }
    for (const _title of title) {
      if (id === _title.id) {
        isConfig = true;
        _item._title = _row;
      }
    }
    for (const _extra of extra) {
      if (id === _extra.id) {
        isConfig = true;
        _item._extra = _row;
      }
    }
    for (const _subtitle of subtitle) {
      if (id === _subtitle.id) {
        isConfig = true;
        _item._subtitle = _row;
      }
    }
    for (const _status of status) {
      if (id === _status.id) {
        isConfig = true;
        _item._status = _row;
      }
    }
    for (const _buttons of buttons) {
      if (id === _buttons.id) {
        isConfig = true;
        _item._buttons.push({ ..._row, control, controlType: type });
      }
    }
    for (const _tags of tags) {
      if (id === _tags.id) {
        isConfig = true;
        _item._tags.push({
          ..._row,
          type: handleColorCommand(_tags.backgroundColorCommand, _item),
        });
      }
    }
    for (const _footer of footer) {
      if (id === _footer.id) {
        isConfig = true;
        _item._footer.push(_row);
      }
    }
    if (!isConfig) {
      _briefList.push({ ..._row, text: `${_title}: ${text}` });
    }
  }
  _item._tags = _item._tags.filter(tag => !!tag.text);
  // TODO: briefList需要支持跨列功能
  switch (cols) {
    case 2:
      _item._briefList = [];
      _leftList = [];
      _rightList = [];
      for (let i = 0; i < _briefList.length; i++) {
        const _brief = _briefList[i];
        if (i % 2 === 0) {
          _leftList.push(_brief);
        } else {
          _rightList.push(_brief);
        }
      }
      let length = _leftList.length;
      if (length < _rightList.length) {
        length = _rightList.length;
      }
      for (let index = 0; index < length; index++) {
        _item._briefList.push({
          left: _leftList[index] || { text: '' },
          right: _rightList[index] || { text: '' },
        });
      }
      break;
    case 3:
      break;
    case 1:
    default:
      _item._briefList = _briefList;
      break;
  }
  return _item;
}

function format2statistics(
  _item: IDictionary,
  statistics: IDictionary,
  columns: IGridViewColumn[],
  handleColorCommand: handleColorCommandFn,
) {
  const { cols = 3, title = [], subtitle = [], progress = [], tags = [], footer = [] } =
    statistics || {};
  let isConfig = false;
  _item._cols = cols;
  _item._tags = [];
  _item._footer = [];
  _item._briefList = [];
  for (const { id, title: _title, field, control, colorCommand } of columns) {
    isConfig = false;
    const color = handleColorCommand(colorCommand, _item),
      style = color && `color: ${color};`,
      style2react: CSSProperties = { color },
      type = control && control.nodeName,
      text = formatGridViewItem(_item[field], type, control),
      _row: any = { text, title: _title };
    if (type === 'hyperlink') {
      _row.control = control;
      _row.style = color ? `color: ${color};` : `color: #4d96f2;`;
      _row.style2react = color ? { color } : { color: '#4d96f2' };
    } else {
      _row.style = color && `color: ${color};`;
      _row.style2react = { color };
    }
    for (const _title of title) {
      if (id === _title.id) {
        isConfig = true;
        _item._title = _row;
      }
    }
    for (const _subtitle of subtitle) {
      if (id === _subtitle.id) {
        isConfig = true;
        _item._subtitle = _row;
      }
    }
    for (const _tags of tags) {
      if (id === _tags.id) {
        isConfig = true;
        _item._tags.push({
          ..._row,
          type: handleColorCommand(_tags.backgroundColorCommand, _item),
        });
      }
    }
    for (const _footer of footer) {
      if (id === _footer.id) {
        isConfig = true;
        _item._footer.push(_row);
      }
    }
    for (const _progress of progress) {
      if (id === _progress.id) {
        isConfig = true;
        _item._progress = { ..._row };
        if (control) {
          const { valueFieldName = field, zeroFieldName, fullFieldName } = control,
            percent = _item[valueFieldName];
          _item._progress.percent = percent;
          _item._progress.zero = zeroFieldName ? _item[zeroFieldName] : percent + '%';
          _item._progress.full = fullFieldName ? _item[fullFieldName] : percent + '%';
        }
      }
    }
    if (!isConfig) {
      _item._briefList.push(_row);
    }
  }
  _item._tags = _item._tags.filter(tag => !!tag.text);
  if (cols > 1) {
    _item._briefList = ArrayHelper.oneArray2twoArray(_item._briefList, cols);
  }
  return _item;
}

function format2table(
  _item: IDictionary,
  table: IGridViewTableConfig,
  columns: IGridViewColumn[],
  handleColorCommand: handleColorCommandFn,
) {
  _item._row = {};
  for (const { field, control } of columns) {
    _item._row[field] = formatGridViewItem(_item[field], control && control.nodeName, control);
  }
  return _item;
}

class GridViewReduce extends DataReduceBase<IGridViewState> {
  private isFirstLoad = true;

  initialState(store: Store<IGridViewState>, params: IGridViewInitialStateParams) {
    const maxWidth = BASEFONTSIZE;
    let isLongList = true,
      autoWidthColumnCount = 0,
      columnWidth = 0,
      columnWidthTotal = 0,
      dataMode: DataModeEnum = DataModeEnum.pagination,
      { initConfig, pageMode, onOperation } = params,
      { displayMode, styleName, parentType, rowSelection } = initConfig,
      { orderby = [], emptyText, rowClickController, pageSize } = initConfig,
      { normal, table, statistics } = initConfig,
      columns: IGridViewColumn[] = [],
      isExistButton = false,
      leftButtons: any[] = [],
      rightButtons: any[] = [];
    if (initConfig) {
      if (initConfig.items) {
        for (const item of initConfig.items.items) {
          if (item.nodeName === 'item') {
            const { id, title = '', index = 0, slot, width, control } = item,
              { field = id, colorCommand } = item,
              controlConfig: any = item.control ? item.control : {},
              controlType = controlConfig.nodeName;
            let isRight = controlType === 'number';
            if (controlType === 'hyperlink' || controlType === 'label') {
              if (controlConfig.format2Number) isRight = true;
            }
            if (!width) autoWidthColumnCount++;
            columnWidthTotal += width || 200;
            columns.push({
              id,
              slot,
              index,
              width,
              title,
              field,
              control,
              colorCommand,
              align: isRight ? 'right' : 'left',
            });
          }
        }
        if (maxWidth <= columnWidthTotal) {
          columnWidth = TABLECOLUMNWIDTH;
          columns[0].fixed = 'left';
        } else {
          const laveWidthTotal = columnWidthTotal - autoWidthColumnCount * TABLECOLUMNWIDTH;
          columnWidth = (maxWidth - laveWidthTotal) / autoWidthColumnCount;

          columnWidthTotal = laveWidthTotal + autoWidthColumnCount * columnWidth;
        }
        columns = columns.map(row => {
          row.width = row.width || columnWidth;
          row.width2rem = px2rem(row.width);
          return row;
        });
      }
      if (initConfig.buttons) {
        isExistButton = initConfig.buttons.items.length > 0;
        for (const button of initConfig.buttons.items) {
          if (button.position === 'left') {
            leftButtons.push(button);
          } else {
            rightButtons.push(button);
          }
        }
      }
      if (normal) {
        styleName = 'normal';
      } else if (table) {
        styleName = 'table';
      } else if (statistics) {
        styleName = 'statistics';
      }
    }

    switch (parentType) {
      case 'form':
      case 'accordion':
      case 'listedit':
        isLongList = false;
        dataMode = DataModeEnum.all;
        break;
      case 'workbench':
        if (pageSize == null) pageSize = 5;
        isLongList = false;
        break;

      case 'tabs':
      case 'waterfall':
      default:
        break;
    }
    if (pageSize == null) pageSize = 10;
    leftButtons = leftButtons.map((button, i) =>
      i < 5 ? { ...button, backgroundColor: button.backgroundColor || colors[i] } : button,
    );
    rightButtons = rightButtons.map((button, i) =>
      i < 5 ? { ...button, backgroundColor: button.backgroundColor || colors[i] } : button,
    );

    const state: IGridViewState = {
      table,
      normal,
      columns,
      orderby,
      total: 0,
      pageSize,
      dataMode,
      styleName,
      emptyText,
      current: 0,
      isLongList,
      statistics,
      onOperation,
      leftButtons,
      rowSelection,
      rightButtons,
      tabIndex: -1,
      isExistButton,
      type: 'first',
      dataSource: [],
      isFetching: true,
      columnWidthTotal,
      rowClickController,
      config: initConfig,
      configIsFetching: false,
      columnWidthTotal2rem: px2rem(columnWidthTotal),
      leftColumns: columns.filter(column => column.fixed === 'left'),
      rightColumns: columns.filter(column => column.fixed === 'right'),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    if (globalData.__ListViewDataSource) {
      state._dataSource = new globalData.__ListViewDataSource({
        rowHasChanged: (r1, r2) => r1 !== r2,
      });
    }
    return state;
  }

  startLoad(store: Store<IGridViewState>, params) {
    const state: IGridViewState = super.startLoad(store, params) as any,
      { type = this.isFirstLoad ? 'first' : 'down', orderBy = [], aggs = [] } = params;
    if (aggs.length === 0) params.aggs = state.aggs;
    if (orderBy.length === 0) params.orderBy = state.orderby;
    if (params.pageSize == null) params.pageSize = state.pageSize;
    state.orderby = params.orderBy || [];
    state.type = type;
    this.isFirstLoad = false;
    return state;
  }

  loaded(store: Store<IGridViewState>, params) {
    let rowIdCount = 0,
      { type, table, styleName, normal, statistics, columns = [] } = store.state;
    const { click, handleColorCommand } = params,
      { rows, count, pageIndex, pageSize } = params.dataSource;

    // TODO 返回的值本应该是 int,不需要 parseInt
    let total = parseInt(count),
      current = parseInt(pageIndex),
      _pageSize = parseInt(pageSize);
    rowIdCount = _pageSize * (current - 1);

    const _dataSource = rows.map(item => {
      const _item = { ...item, _rid: ++rowIdCount };
      if (click) _item._click = () => click(_item);
      if (_item.selected != null) _item._selected = _item.selected;
      if (normal) {
        return format2normal(_item, normal, columns, handleColorCommand);
      } else if (table) {
        return format2table(_item, table, columns, handleColorCommand);
      } else if (statistics) {
        return format2statistics(_item, statistics, columns, handleColorCommand);
      }
      switch (styleName) {
        case 'style1':
          return format2style1(_item, columns, handleColorCommand);
        case 'style2':
          return format2style2(_item, columns, handleColorCommand);
      }

      return _item;
    });
    const dataSource = type === 'up' ? store.state.dataSource.concat(_dataSource) : _dataSource;
    let state: IGridViewState = {
      ...store.state,
      total,
      current,
      dataSource,
      isFetching: false,
      pageSize: _pageSize,
      type: dataSource.length >= count ? 'done' : type,
    };
    if (state._dataSource) state._dataSource = state._dataSource.cloneWithRows(dataSource);
    return state;
  }

  moreClickAfter(store: Store<IGridViewState>, params) {
    return store.state;
  }

  rowClickAfter(store: Store<IGridViewState>, params) {
    const { dataSource, styleName } = store.state,
      { row } = params;
    if (styleName === 'table') {
      const state: IGridViewState = { ...store.state },
        i = dataSource.indexOf(row);
      if (state.dataSource[i]) {
        state.dataSource[i]._highlight = true;
      }
      return state;
    }
    return store.state;
  }

  cancelRowHighlight(store: Store<IGridViewState>, params) {
    const { dataSource, styleName } = store.state,
      { row } = params;
    if (styleName === 'table') {
      const state: IGridViewState = { ...store.state },
        i = dataSource.indexOf(row);
      if (state.dataSource[i]) {
        state.dataSource[i]._highlight = false;
      }
      return state;
    }
    return store.state;
  }

  updateDataSource(store: Store<IGridViewState>, params: { row: any }) {
    const { row } = params,
      state: IGridViewState = { ...store.state },
      { dataSource } = state,
      selected = row._selected;
    state.dataSource = dataSource.map(item => {
      if (row.id === item.id) return { ...item, _selected: selected };
      else if (selected && item._selected) return { ...item, _selected: false };
      return item;
    });
    if (state._dataSource) state._dataSource = state._dataSource.cloneWithRows(state.dataSource);
    return state;
  }

  updateScrollHeight(store: Store<IGridViewState>, params) {
    return { ...store.state, height: params.height } as IGridViewState;
  }
}

export default new GridViewReduce();
