import Service from '@mjcloud/service';
import JsApiHelper from '@mjcloud/jsapi';
import PageHelper from '@mjcloud/page-helper';
import globalData from '@mjcloud/global-data';
import { ExceptionHelper } from '@mjcloud/exception';
import { IDictionary, XmlParamList, PageModeEnum } from '@mjcloud/types';
import { ControllerBase, IControllerInitData, IRequest } from './base';
import { IPopupModalResult } from '@mjcloud/page/dist/typings';

export interface LaunchProcessServiceControllerInitData extends IControllerInitData {
  service: XmlParamList;
}

/**
 * 执行服务请求对象类型
 */
export interface ILaunchProcessServiceRequest extends IRequest {
  businessId: string;
  businessType: string;
  originatorId: string;
  originatorDeptId: string;
  extendData: string;
  version: string;
  argData: string;
  annexs?:
  | { businessId: string; businessType: string }[]
  | { businessId: string; businessType: string };
  annexs2automatic?: boolean;
}

interface IGetUniqueDeptIdResult {
  isUnique: boolean;
  originatorId: string;
  originatorDeptId: string;
}

interface ISelectSingleDepartmentResult extends IPopupModalResult {
  data: IDictionary;
}

interface IAnnexInfo {
  spaceId: string;
  fileName: string;
  fileSize: string;
  fileType: string;
  fileId: string;
}

/**
 * 执行服务控制器
 */
export default class LaunchProcessServiceController extends ControllerBase<
  LaunchProcessServiceControllerInitData
  > {
  /**
   * 创建执行请求对象
   */
  public async createRequest(args: IDictionary): Promise<ILaunchProcessServiceRequest> {
    const values: IDictionary<string> = {};
    if (this.initData.service)
      this.initData.service.items.forEach(item => {
        values[item.name] = item.value;
      });
      console.info('values', values)
    const params = await PageHelper.getDynamicParamValues<ILaunchProcessServiceRequest>(
      this.page,
      values,
      args,
    );
    return { ...params };
  }

  /**
   * 执行控制器
   * @param request 执行时的请求对象
   */
  public async execute<T>(request: ILaunchProcessServiceRequest) {
    try {
      // //1.校验
      // this.emptyValueValiator('业务id', request.businessId);
      // this.emptyValueValiator('业务类型', request.businessType);

      const result: any = await Service.getWorkflowMode({ businessType: request.businessType });
      if (result === 1) {
        //1.默认发起人为登录用户
        let originatorId = request.originatorId;
        if (originatorId == null || originatorId.length == 0) {
          if (globalData.rbCore.userInfo) {
            request.originatorId = globalData.rbCore.userInfo['id'];
          }
        }

        //2.一人多岗处理
        let originatorDeptId = request.originatorDeptId;
        if (originatorDeptId == null || originatorDeptId.length == 0) {
          let multiResult = await this.getUniqueDeptIdService(request.originatorId);
          if (multiResult) {
            if (multiResult.isUnique) {
              //用户仅隶属一个部门
              request.originatorId = multiResult.originatorId;
              request.originatorDeptId = multiResult.originatorDeptId;
            } else {
              //用户有多个兼职部门
              request.originatorId = multiResult.originatorId;
              var selectData = await this.selectSingleDepartment(request.originatorId);
              if (selectData.oper == 'ok') {
                request.originatorDeptId = selectData.data.id;
              } else {
                //取消、关闭，终止流程
                const ex = ExceptionHelper.userCanceledException();
                throw ex;
              }
            }
          }
        }
        //3.参数准备
        let argData: any;
        //3.1.授权码
        let authCode: any = null;
        const res = await JsApiHelper.requestAuthCode({ corpId: globalData.corpId });
        if (res) {
          authCode = res.code;
        }
        argData = { authCode, files: await this.getFiles(request) };
        if (argData != null) {
          request.argData = JSON.stringify(argData);
        }
      }

      //4.发起
      return Service.executeController<T>({
        ...this.page.getControllerExecParams(),
        params: request,
        controllerId: this.id,
        workbenchPartAddress: this.sender.workbenchPart && this.sender.workbenchPart.address,
      });
    } catch (error) {
      const ex = ExceptionHelper.convertTo(error);
      ex.isHandleBusiness = true;
      throw ex;
    }
  }

  private async getFiles(request: ILaunchProcessServiceRequest) {
    const { annexs = [], annexs2automatic = true } = request,
      message = '当前工作流需要的文件列表中存在重复的businessType',
      result: IDictionary<IAnnexInfo[]> = {};
    if (annexs2automatic) {
      // 若当前文件列表由平台自动获取，则遍历当前页面的附件控件，获取相应的信息并转为后端所需信息
      // TODO: 注意，若后期平台的附件控件支持小控件的配置，则需要优化该遍历逻辑
      for (const key in this.page['controls']) {
        const control = this.page['controls'][key];
        if (control instanceof Array) {
          for (const instance of control) {
            if (instance instanceof globalData.controlTypes.FileManager) {
              const { businessType, annex } = await this.getAnnexs(instance);
              if (annex.length > 0) {
                if (globalData.debugger && result[businessType])
                  JsApiHelper.showToast({ content: message, type: 'exception' });

                result[businessType] = annex;
              }
            }
          }
        } else {
          if (control instanceof globalData.controlTypes.FileManager) {
            const { businessType, annex } = await this.getAnnexs(control);
            if (annex.length > 0) {
              if (globalData.debugger && result[businessType])
                JsApiHelper.showToast({ content: message, type: 'exception' });

              result[businessType] = annex;
            }
          }
        }
      }
    } else if (annexs instanceof Array) {
      // 根据annexs请求文件列表并转为后端所需信息
      if (annexs.length > 0) {
        // TODO: annexs2automatic = false 逻辑实现
      }
    } else if (annexs) {
      // TODO: annexs2automatic = false 逻辑实现
    }
    return result;
  }

  private async getAnnexs(instance: any) {
    const annex: IAnnexInfo[] = [],
      files: any[] = instance.getFileList();
    console.log('getAnnexs', files);
    for (const { id: fileId, size: fileSize, name: fileName, type } of files) {
      const result = await Service.workflowFileInDingtalk(fileId);
      if (result) {
        const fileType = type && type.substring(1);
        annex.push({
          spaceId: result.spaceId,
          fileName,
          fileSize,
          fileType,
          fileId: result.fileId,
        });
      }
    }
    return { businessType: instance.getBusinessType(), annex } as {
      businessType: string;
      annex: IAnnexInfo[];
    };
  }

  //获取用户所属部门（唯一对应才获取）
  private async getUniqueDeptIdService(userId: string) {
    if (userId == null || userId.length == 0) {
      userId = '0';
    }
    const params = { userId };
    return Service.requestService<IGetUniqueDeptIdResult>('/workflow/getuniquedeptid', params);
  }

  private selectSingleDepartment(userId: string, title = '发起工作流'): any {
    //todo: open window and select data
    const params: IDictionary = { userId };
    return this.page.popupModal<ISelectSingleDepartmentResult>({
      params,
      pageInfo: {
        title,
        width: '60%',
        pageId: 'Main',
        moduleId: 'Workflow',
        pageMode: PageModeEnum.none,
        appId: this.page.address.appId,
        // appId: 'general', // 曾经的模拟数据，目前后端自动处理
      },
    });
  }
}
