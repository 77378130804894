import Store, { IState } from '@mjcloud/redux';
import ContainerInstanceBase from '../containerInstanceBase';
import { DataModeEnum, PageModeEnum, IDictionary, ControlConfig } from '@mjcloud/types';
import InstanceBase, { ControlActionType, ControlEventType } from '../base';
import { IDataResult, IDataSourceParameters, IData } from '@mjcloud/data-source-helper';

export interface IOrderbySet {
  allowSort?: boolean;
  name: string;
  sortType?: 1 | 2;
}
export interface IDataInstanceBase extends InstanceBase {
  isJudgePageMode: boolean;
  getDataMode(): DataModeEnum;
  refresh(params?: IDataSourceParameters): void;
  __loadStart(): void;
  __loadComplete(): void;
}
export type DataControlEventType = ControlEventType | 'ready';

export type DataControlActionType =
  | ControlActionType
  | 'startLoad'
  | 'loaded'
  | 'loadError'
  | 'updateDataConfig';

export interface IDataControlStartLoadParams extends IDataSourceParameters {
  /**
   * 数据源配置
   */
  config?: IData;
}

export interface IDataControlLoadedParams {
  dataSource: IDataResult;
}

export interface IDataControlLoadErrorParams {
  errorMessage: string;
}

export interface IDataState<TLayout = IDictionary> extends IState<TLayout> {
  orderby?: IOrderbySet[];
  dataSource: IDataResult;
  isFetching?: boolean;
  errorMessage?: string;
  startLoadParams?: IDataControlStartLoadParams;
}

export default abstract class DataInstanceBase<
  S extends IDataState = IDataState,
  TEventType extends string = string,
  TDispatchType extends string = string
> extends InstanceBase<S, TEventType, TDispatchType> implements IDataInstanceBase {
  isJudgePageMode = false;
  constructor(id: string, parent: ContainerInstanceBase, workbenchPart?: any) {
    super(id, parent, workbenchPart);
    if (parent['getDataMode']) {
      parent.eventManager.add('ready', this.handParentDataLoad.bind(this));
    } else {
      this.page.eventManager.add('inited', this.handParentDataLoad.bind(this));
    }
    if (workbenchPart) {
      workbenchPart.eventManager.add('inited', this.handParentDataLoad.bind(this));
    }
  }

  abstract getDataMode(): DataModeEnum;

  /**
   * 刷新数据
   * @param params
   */
  refresh(params: IDataControlStartLoadParams = {}) {
    if (this.store.state.config) {
      // 判断控件是否初始化完毕
      // 这里通过拓展运算符改变参数地址防止业务人员开发刷新参数跨控件
      (this.store as Store<S, DataControlActionType>).dispatch('startLoad', { ...params });
    }
  }

  /**
   * 更新数据源
   * @param rows 数据源
   */
  updateDatas(data: IDictionary[] | IDictionary) {
    let dataSource = {},
      rows = data instanceof Array ? data : [data];
    switch (this.getDataMode()) {
      case DataModeEnum.all:
      case DataModeEnum.pagination:
        dataSource = { rows };
        break;
      case DataModeEnum.one:
        dataSource = rows[0];
        break;
    }
    (this.store as Store<S, DataControlActionType>).dispatch('loaded', { dataSource });
  }

  updateDataConfig(config: IData) {
    (this.store as Store<S, DataControlActionType>).dispatch('updateDataConfig', { config });
  }

  private handParentDataLoad() {
    if (this.isPage) return;
    if (this.isJudgePageMode && this.page.pageMode === PageModeEnum.add) {
      (this.store as Store<S, DataControlActionType>).dispatch('loaded', {});
    } else {
      this.refresh();
    }
  }

  __loadStart() {
    this.parent.__controlLoadStart(this.id);
    if (this.workbenchPart) {
      this.workbenchPart.__controlLoadStart(this.id);
    }
  }

  __loadComplete() {
    this.parent.__controlLoadComplete(this.id);
    if (this.workbenchPart) {
      this.workbenchPart.__controlLoadComplete(this.id);
    }
  }
}
