import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { TableCell } from './pc';
import EditableCell from './treeedit-cell';
import TreeEditExtendStore from './extendStore';
import TableInstanceBase from '../common/table';
import { ExceptionHelper } from '@mjcloud/exception';
import EditableComplexCell from './treeedit-complex-cell';
import { ITreeDataSourceItem } from '@mjcloud/data-source-helper';
import { IViewModelCollection, IViewModelRow } from '@mjcloud/data-model';
import { IDictionary, DataModeEnum, PageControlMode, RequiredTypeEnum, Size } from '@mjcloud/types';
import {
  ITreeEditState,
  ITreeEditConfig,
  TreeEditActionType,
  TreeEditEventType,
  ITreeEditConfigStartLoadParams,
  ITreeEditUpdateSelectedRowsParams,
} from './typings';

class TreeEdit extends TableInstanceBase<
  IViewModelCollection,
  IViewModelRow,
  ITreeEditState,
  EditableCell | EditableComplexCell,
  TreeEditEventType,
  TreeEditActionType
> {
  private _pureTableCells: IDictionary<IDictionary<TableCell>> = {};

  get pageControlMode(): PageControlMode {
    return PageControlMode.treeEdit;
  }

  __createStore() {
    return new Store<ITreeEditState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new TreeEditExtendStore(this),
    });
  }

  getDataMode(): DataModeEnum {
    return DataModeEnum.all;
  }

  initialState(initConfig: ITreeEditConfig) {
    this.store.dispatch<ITreeEditConfigStartLoadParams>('configStartLoad', {
      initConfig,
      address: this.page.address,
    });
  }

  unActiveCell() {
    const { activeCellId } = this.store.state;
    if (activeCellId) {
      const prevActiveCell = this.findCell(activeCellId);
      if (prevActiveCell instanceof EditableCell) {
        prevActiveCell.unActive();
      }
    }
  }

  __updateCellSize(rowId: number, size: Size) {
    for (const key in this.cells) {
      const cell = this.cells[key];
      if (cell) cell.updateSize(rowId, size);
    }
    for (const key in this._pureTableCells) {
      const pureTableCells = this._pureTableCells[key],
        pureTableCell = pureTableCells[rowId];
      if (pureTableCell) pureTableCell.updateSize(size);
    }
    if (this.operation) this.operation.updateSize(rowId, size);
  }

  __getCellColumnInfo(id: string) {
    const { columns } = this.store.state;
    if (columns && columns.length > 0) {
      for (const column of columns) {
        if (column.key === id) {
          return column;
        }
      }
    }
    return undefined;
  }

  __getCellRecord(rowId: number) {
    return this.dataModel[rowId];
  }

  __registerTableCell2Pure(cellId: string, rowId: number, item: TableCell) {
    if (this._pureTableCells[cellId] == null) this._pureTableCells[cellId] = {};
    this._pureTableCells[cellId][rowId] = item;
  }

  /**
   * 手动触发校验报错
   */
  triggervValid(message: string, itemId: string, rowId: string) {
    for (const key in this.cells) {
      if (itemId == key) {
        const item = this.cells[key];
        item.triggervValid(rowId, message);
      }
    }
    throw ExceptionHelper.businessException(message);
  }

  async valid() {
    for (const key in this.cells) {
      const item = this.cells[key];
      const validResults = await item.valid();
      for (const key in validResults) {
        const validResult = validResults[key];
        if (validResult && validResult !== true) {
          throw ExceptionHelper.businessException(validResult.message);
        }
      }
    }
    return true;
  }

  __deleteRow2cells(rid: number) {
    for (const key in this.cells) {
      const item = this.cells[key];
      if (item) item.deleteStore(rid);
    }
  }
  __createRow2cells(rid: number) {
    for (const key in this.cells) {
      const item = this.cells[key];
      if (item) item.createStore(rid);
    }
  }

  get dataModel() {
    return this.store.state.dataSource;
  }

  getRowForRowId(rid: number) {
    return this.store.state.dataSource[rid];
  }

  /**
   * 设置列是否只读
   * @param key 子项Id
   * @param readonly 是否只读
   * @param isAddReadonly 通过点击添加按钮添加的新行是否关联该设置，默认为false，即新行可编辑
   */
  setItem2Readonly(key: string, readonly: boolean, isAddReadonly = false) {
    const cell = this.findCell(key);
    if (cell) {
      cell.updateReadonly(readonly, isAddReadonly);
    }
  }

  /**
   * 设置列是否必填
   * @param key 子项Id
   * @param readonly 是否只读
   */
  setItem2RequiredType(key: string, requiredType: RequiredTypeEnum) {
    const cell = this.findCell(key);
    cell.updateRequiredType(requiredType);
  }

  /**
   * 设置列标题的提示
   * @param cellId 子项Id
   * @param tip 提示文本，为undefined时隐藏
   * @param tipIcon 提示图标
   */
  async setItem2Tip(cellId: string, tip: string | undefined, tipIcon?: string) {
    const cellTitle = this.__findCellTitle(cellId);
    if (cellTitle) {
      await cellTitle.setTip(tip, tipIcon);
    }
  }

  /**
   * 动态的往当前表格添加一行
   * @param rowData 行初始值
   *
   * lg. addRow({ [key]: value })
   */
  addRow(rowData: IDictionary = {}) {
    this.store.dispatch('batchAddRow', { rows: [rowData] });
  }

  /**
   * 动态的往当前表格添加多行
   * @param rows
   */
  batchAddRow(rows: IDictionary[] = []) {
    this.store.dispatch('batchAddRow', { rows });
  }

  rowIsExistSubset(row: any) {
    const { _children = [] } = row;
    if (_children instanceof Array && _children.length > 0) {
      return true;
    }
    return false;
  }

  private getRowChildren(dataSource: ITreeDataSourceItem[], id: string) {
    const rows: ITreeDataSourceItem[] = [];
    for (const row of dataSource) {
      if (row._children && row._children.length > 0) {
        rows.push(...this.getRowChildren(row._children, id));
      }
      if (id === row._parentId) {
        rows.push(row);
      }
    }
    return rows;
  }

  /**
   * 删除一行
   * @param rid 行Id
   */
  deleteRow(rid: number) {
    const { _dataSource, config } = this.store.state,
      { data } = config,
      { valueField = 'id' } = data;
    const children = this.getRowChildren(_dataSource as any, this.dataModel[rid][valueField]);

    if (children.length > 0) {
      throw ExceptionHelper.businessException('当前行存在子级不能删除！');
    }
    this.store.dispatch('deleteRow', { rid });
  }

  /**
   * 清空所有数据
   * @param isKeepDataState 是否保留已清空的数据
   */
  deleteAllRow(isKeepDataState: boolean = true) {
    this.store.dispatch('deleteAllRow', { isKeepDataState });
  }

  /**
   * 手动设置列表的选中项
   * @param selectedRows 要选中的行数据
   */
  setSelectedRows(selectedRows: IViewModelRow[], actionSourceSign?: any) {
    this.store.dispatch<ITreeEditUpdateSelectedRowsParams>('updateSelectedRows', {
      halfSelectedKeys: [],
      selectedRowKeys: selectedRows.map(r => r._rid),
      actionSourceSign,
    });
  }

  private ids2Rows(dataSource: IViewModelRow[], ids: string[], idFieldName = 'id') {
    const rows: IViewModelRow[] = [];
    for (const row of dataSource) {
      if (row._children && row._children.length > 0) {
        rows.push(...this.ids2Rows(row._children, ids, idFieldName));
      }
      for (const id of ids) {
        if (id === row[idFieldName]) {
          rows.push(row);
        }
      }
    }
    return rows;
  }

  /**
   * 手动设置列表的选中项
   * @param ids 要选中的行数据Id集合
   * @param idFieldName 默认值: 'id'
   */
  setSelectedRows2Id(ids: string[], idFieldName = 'id', actionSourceSign?: any) {
    const selectedRows: IViewModelRow[] = this.ids2Rows(
      this.store.state._dataSource,
      ids,
      idFieldName,
    ) as IViewModelRow[];
    this.setSelectedRows(selectedRows, actionSourceSign);
  }

  getAllData(): IDictionary[] {
    const { dataSource } = this.store.state;
    return dataSource.toArray();
  }

  /**
   * 获取当前选中的行数据
   */
  getSelectedRows(containHalf = true): IDictionary[] {
    const { selectedRows = [], halfSelectedRows = [] } = this.store.state;
    if (containHalf) {
      return [...selectedRows, ...halfSelectedRows];
    }
    return selectedRows;
  }

  /**
   * 获取当前半选中的行数据
   */
  getHalfSelectedRows(): IDictionary[] {
    return this.store.state.halfSelectedRows || [];
  }

  /**
   * 获取当前选中的行Id
   */
  getSelectedIds(idFieldName = 'id', containHalf = false): string[] {
    const { selectedRows = [], halfSelectedRows = [] } = this.store.state;
    let _selectedRows: IViewModelRow[] = [];
    if (containHalf) {
      _selectedRows = [...selectedRows, ...halfSelectedRows];
    } else {
      _selectedRows = [...selectedRows];
    }
    return _selectedRows.map(row => row[idFieldName]);
  }

  /**
   * treeEdit 进入可编辑模式
   */
  enterEditMode() {
    this.store.dispatch('updateModifyMode', { modify: true });
  }

  /**
   * treeEdit 退出可编辑模式
   */
  quitEditMode() {
    this.store.dispatch('updateModifyMode', { modify: false });
  }

  /**
   * 清空并更新数据源
   * @param rows 数据源
   */
  updateDatas(rows: IDictionary[]) {
    this.store.dispatch('loaded', { dataSource: { rows } });
  }

  async getData(isValid: boolean = true): Promise<any> {
    if (isValid) {
      await this.valid();
    }
    return this.dataModel.toJSON().map(({ _children, _parent, ...row }) => row);
  }

  /**
   * 控件数据提交完成
   */
  dataSubmitFinish(): void {
    this.dataModel.dataSubmitFinish();
    this.page.controlDataSubmitFinish(this.id);
  }

  activeCell(rowIndex: number, cellId: string) {
    const cell = this.findCell(cellId);
    if (cell instanceof EditableCell) {
      this.store.dispatch('activeCell', { cellId, rowId: this.dataModel.toArray()[rowIndex]._rid });
    }
    return cell;
  }

  findCellControl(rowIndex: number, cellId: string) {
    const cell = this.activeCell(rowIndex, cellId);
    if (cell instanceof EditableCell) {
      return cell.controlInstance;
    } else {
      return cell.getControlInstance(this.dataModel.toArray()[rowIndex]._rid);
    }
  }
}

export default TreeEdit;
