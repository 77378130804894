import {
  objectMerge,
  isFunction,
  handleWindowError,
  handleRejectPromise,
  handleResourceError,
  handleAjaxError,
  handleConsoleError,
} from './utils';

type LevelType = 'info' | 'warning' | 'error';
type CategoryType = 'js' | 'resource' | 'ajax';

interface IErrorInfo {
  title: string;
  msg: any;
  level: LevelType;
  category: CategoryType;
  tags?: any;
}

interface IBetterOptions {
  jsError?: boolean;
  resourceError?: boolean;
  ajaxError?: boolean;
  /**
   * console.error默认不处理
   */
  consoleError?: boolean;
  /**
   * 跨域js错误，默认不处理，因为没有任何信息
   */
  scriptError?: boolean;
  /**
   * 过滤器，命中的不上报
   */
  filters?: any[];
  sendError(error: IErrorInfo): void;
}

var _window: any = {};
if (typeof window !== 'undefined') _window = window;
else if (typeof global !== 'undefined') _window = global;
else if (typeof self !== 'undefined') _window = self;

export default class BetterJs {
  constructor() {}

  public static init(options: IBetterOptions) {
    var defaultConfig = {
      jsError: true,
      resourceError: true,
      ajaxError: true,
      consoleError: false, // console.error默认不处理
      scriptError: false, // 跨域js错误，默认不处理，因为没有任何信息
      autoReport: true,
      filters: [], // 过滤器，命中的不上报
      levels: ['info', 'warning', 'error'],
      category: ['js', 'resource', 'ajax'],
    };
    var config = objectMerge(defaultConfig, options);

    if (!config.scriptError) {
      config.filters.push(function() {
        return /^Script error\.?$/.test(arguments[0]);
      });
    }

    // 处理过滤器
    var _oldSendError = config.sendError;
    config.sendError = function(title: any, msg: any, level: any, category: any, tags: any) {
      try {
        var isFilter = config.filters.some((func: any) => {
          return isFunction(func) && func.apply(this, arguments);
        });
        if (isFilter) {
          return;
        }
        _oldSendError.apply(this, arguments);
        if (config.autoReport) {
          return;
        }
        // TODO ajax上报
      } catch (e) {
        _oldSendError({
          title: 'betterJs',
          msg: e,
          category: 'js',
        });
      }
    };

    var addEventListener = _window.addEventListener || _window.attachEvent;
    if (config.jsError) {
      handleWindowError(_window, config);
    }
    if (config.jsError) {
      // https://developer.mozilla.org/zh-CN/docs/Web/Events/unhandledrejection
      handleRejectPromise(_window, config);
    }
    if (config.resourceError && addEventListener) {
      handleResourceError(_window, config);
    }
    if (config.ajaxError) {
      handleAjaxError(_window, config);
    }
    if (config.consoleError) {
      handleConsoleError(_window, config);
    }
  }
}
