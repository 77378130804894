import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { IFileInfo } from '@mjcloud/service';

interface IPreviewModalProps {
  file: IFileInfo;
  destroy(): void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 95vw;
  height: ${() => window.innerHeight - 120}px;
  /* 防止双击缩放 */
  touch-action: manipulation;

  iframe {
    flex: 1;
  }
`;

async function ready(instance: Element | null, file: IFileInfo) {}

const PreviewModal: React.FC<IPreviewModalProps> = ({ destroy, file }) => {
  return (
    <div>
      <Modal
        visible
        title={file.name}
        footer={null}
        width="95vw"
        onCancel={destroy}
        style={{ top: 28 }}
        bodyStyle={{ padding: 0 }}
      >
        <Container ref={instance => ready(instance, file)}></Container>
      </Modal>
    </div>
  );
};

export default PreviewModal;
