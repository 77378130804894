import Store from '@mjcloud/redux';
import Reduce from './reduce';
import NumberExtendStore from './extendStore';
import { ValueInstanceBase } from '@mjcloud/instance';
import { NumberHelper } from '@mjcloud/utils';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { INumberState } from './typings';

export default class Number extends ValueInstanceBase<number, INumberState> {
  get valueType(): ValidationRuleType {
    return 'number';
  }

  __createStore() {
    return new Store<INumberState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new NumberExtendStore(this),
    });
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  format(value) {
    if (value == null) return '';
    let _value = '';
    const { format } = this.store.state;
    _value = NumberHelper.format(value, format);
    return _value;
  }
}
