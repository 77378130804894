/**
 * 在ts中mixins的简单实现
 * @description: 使用请参考 https://www.tslang.cn/docs/handbook/mixins.html
 * @param derivedCtor
 * @return:
 */
function applyMixins(derivedCtor: any, ...baseCtors: any[]) {
  baseCtors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      derivedCtor.prototype[name] = baseCtor.prototype[name];
    });
  });
}

export default applyMixins;
