import ExtendStoreBase from '../base';
import { ActionAfterEventArg, IState, IInitialStateParams } from '@mjcloud/redux';
import ContainerInstanceBase, {
  ContainerControlActionType,
} from '@mjcloud/instance/dist/containerInstanceBase';

export default abstract class ContainerExtendStoreBase<
  TInstance extends ContainerInstanceBase = ContainerInstanceBase
> extends ExtendStoreBase<TInstance> {
  public handleInitialStateAfter(
    e: ActionAfterEventArg<IState, ContainerControlActionType, IInitialStateParams>,
  ) {
    if (this.instance.isPage) return;
    this.instance.__instanceInited = true;
    if (this.instance.isControlInited()) {
      this.instance.controlInitComplete();
    }
  }
}
