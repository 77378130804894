import { ITreeViewConfig } from './typings';

const config: ITreeViewConfig = {
  data: {},
  expandLevel: 9,
  items: { items: [] },
  mayCustom: false,
  showPager: false,
  showOrdinal: true,
  showSummary: false,
  collapseMenu: false,
  rowSelection: false,
  orderby: [],
  fastnessCount: 0,
  rowSelectionType: 'checkbox',
  buttons: undefined,
  modifyMode: undefined,
  displayMode: undefined,
  disabledMode: undefined,
};

export default config;
