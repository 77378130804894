import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { NumberHelper } from '@mjcloud/utils';

export class NumberReduce extends ValueReduceBase {
  initialState(store, params) {
    const { initConfig, pageMode } = params,
      { value } = store.state,
      { title, autoFocus, modifyMode } = initConfig,
      { format, max: _max, min: _min, displayMode, disabledMode } = initConfig,
      { placeholder = `请输入${title || ''}` } = initConfig,
      max = typeof _max === 'string' ? parseInt(_max) : _max,
      min = typeof _min === 'string' ? parseInt(_min) : _min;
    let text = value;
    if (text != null) {
      text = NumberHelper.format(value, format);
    }
    const state = {
      max,
      min,
      text,
      title,
      value,
      format,
      autoFocus,
      placeholder,
      tabIndex: -1,
      _value: value,
      isFocus: false,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    return state;
  }

  updateValue(store, params) {
    let state = super.updateValue(store, params) as any;
    const { value } = state;
    let _value: any = value;
    if (value) {
      if (typeof value === 'string') {
        if (value.substr(-1) === '.') {
          if (value.substr(-2) === '..') {
            _value = value.substr(0, value.length - 1);
          } else {
            _value = value;
          }
        } else {
          const _val = parseFloat(value);
          if (!isNaN(_val)) _value = _val;
        }
      }
    } else {
      _value = null;
    }
    state.value = _value;
    state._value = state.active ? state.value : state.text;
    return state;
  }

  updateInputValue(store, params) {
    let state = store.state;
    state._value = params.active ? state.value : state.text;
    return { ...state, active: params.active };
  }
}

export default new NumberReduce();
