import PageHelper from '@mjcloud/page-helper';
import { ContainerDataExtendStoreBase } from '@mjcloud/extend-store';

export default class StatisticsExtendStore extends ContainerDataExtendStoreBase {
  protected isNeedNotificationPage = true;

  handleLoadedBefore(e) {
    e.params.handleColorCommand = (colorCommand, row) => {
      if (colorCommand) {
        const e = {
            sender: this.instance,
            eventType: 'colorCommand',
            data: { row },
          },
          fn = PageHelper.createPageScriptFunction(this.instance.page, colorCommand, true);
        return fn(e);
      } else {
        return undefined;
      }
    };
  }
}
