import { IGridViewCellState, IGridViewCellInitialStateParams } from './typings';
import Store, { IReduce } from '@mjcloud/redux';

class GridViewCellReduce implements IReduce<IGridViewCellState> {
  initialState(store: Store<IGridViewCellState>, params: IGridViewCellInitialStateParams) {
    const { initConfig, rowId } = params;
    const { value, text, special = false } = initConfig;
    const state: IGridViewCellState = {
      text,
      value,
      rowId,
      special,
      tabIndex: -1,
      display: true,
      configIsFetching: false,
      config: initConfig,
    };
    return state;
  }

  updateColor(store: Store<IGridViewCellState>, params) {
    const { color } = params;
    return { ...store.state, color };
  }

  updateValue(store: Store<IGridViewCellState>, params) {
    const { text, value } = params;
    return { ...store.state, text, value };
  }
}

export default new GridViewCellReduce();
