import Reduce from './reduce';
import { INumberRangeConfig, INumberRangeState } from './typings';
import Store from '@mjcloud/redux';
import { ValueInstanceBase } from '@mjcloud/instance';
import { ValueExtendStoreBase } from '@mjcloud/extend-store';
import { ActionBeforeEventArg } from '@mjcloud/redux';
import { IValueControlUpdateValueParams } from '@mjcloud/instance/dist/valueInstanceBase';
import { ControlConfig, IDictionary } from '@mjcloud/types';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { NumberHelper } from '@mjcloud/utils';

class NumberRangeExtendStore extends ValueExtendStoreBase<IDictionary<number>, NumberRange> {
  handleUpdateValueBefore(e: ActionBeforeEventArg<IValueControlUpdateValueParams>) {
    super.handleUpdateValueBefore(e);
    const value = e.params.value as any;
    if (typeof value === 'object' && value._isObject) {
      let { startFieldName, endFieldName } = this.instance.store.state;
      if (startFieldName === endFieldName) {
        startFieldName = 'start';
        endFieldName = 'end';
      }
      const start = value[startFieldName],
        end = value[endFieldName];
      e.params.text = `${this.instance.format(start)} ~ ${this.instance.format(end)}`;
    }
  }
}

class NumberRange extends ValueInstanceBase<IDictionary<number>, INumberRangeState> {
  get valueType(): ValidationRuleType {
    return 'object';
  }

  __createStore() {
    return new Store<INumberRangeState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new NumberRangeExtendStore(this),
    });
  }

  initialState(initConfig: INumberRangeConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  format(value?: number, config: any = { format: '#,##0.00' }): string {
    if (value == null) return '';
    let _value = '';
    const { format = config.format } = this.store.state;
    _value = NumberHelper.format(value, format);
    return _value;
  }
}

export default NumberRange;
