import Store, { IState } from '@mjcloud/redux';
import { IDictionary, Omit } from '@mjcloud/types';
import { IEventArg } from '@mjcloud/utils/dist/events/eventListening';
import InstanceBase, { ControlActionType, ControlEventType } from '../base';
import { ValidationRule, ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';

export type ValueControlEventType = ControlEventType | 'onValueChange' | 'onValid';

export interface IValueChangeEventData<TValue = string> {
  value: TValue;
  text?: string;
  /**
   * 这次值更改是否来源于父级控件
   */
  isParentChange: boolean;
}

export interface IValueChangeEventArg<S = any, TValue = string>
  extends IEventArg<S, IValueChangeEventData<TValue>> {}

export interface IValidEventData<TValue = string> {
  value: TValue;
  rule: Omit<ValidationRule, 'validator'>;
  errors: string[];
}

export interface IValidEventArg<S = any, TValue = string>
  extends IEventArg<S, IValidEventData<TValue>> {}

export type ValueControlActionType = ControlActionType | 'updateValue' | 'updateDisabled';

export interface IValueControlUpdateValueParams<TValue = string> extends IDictionary {
  value: TValue | null;
  text: string | null;
  actionSourceSign?: any;
}

export interface IValueControlUpdateDisabledParams {
  disabled: boolean;
}

export interface IValueState<TValue = string, TConfig = IDictionary> extends IState<TConfig> {
  /** 标题，通过父级控件传递过来，在此用在未设置placeholder下的默认提示文案和校验错误文本提示中 */
  title: string;
  /**
   * 获取控件的值
   */
  value: TValue;
  /**
   * 获取控件的显示文本
   */
  text?: string;
  /**
   * 获取或设置控件是否禁用
   */
  disabled: boolean;
  readonly: boolean;
}

export default abstract class ValueInstanceBase<
  TValue = string,
  S extends IValueState<TValue> = IValueState<TValue>,
  TEventType extends string = string,
  TDispatchType extends string = string
> extends InstanceBase<S, TEventType, TDispatchType> {
  public abstract get valueType(): ValidationRuleType;

  /**
   * 获取控件的值
   */
  public get value(): TValue {
    return this.store.state.value;
  }

  /**
   * 获取控件的显示文本
   */
  public get text(): string {
    return this.store.state.text || '';
  }

  /**
   * 获取或设置控件是否禁用
   */
  public get disabled() {
    return this.store.state.disabled;
  }

  public set disabled(disabled: boolean) {
    const store = this.store as Store<S, ValueControlActionType>;
    store.dispatch('updateDisabled', { disabled });
  }

  public setValue(value: TValue | null, text: string | null = null) {
    const store = this.store as Store<S, ValueControlActionType>;
    store.dispatch<IValueControlUpdateValueParams<TValue>>('updateValue', { value, text });
  }

  public focus: () => boolean = function() {
    return false;
  };
  public blur: () => boolean = function() {
    return false;
  };
}
