import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';

export class LabelReduce extends ValueReduceBase {
  initialState(store, params) {
    const { initConfig, pageMode } = params;
    const config = initConfig;
    const { title, autoFocus, value = '', modifyMode } = initConfig;
    const { maxLength, disabledMode, displayMode, leftIcon, rightIcon } = config;
    let placeholder = config.placeholder || `请输入${title || ''}`,
      _value = store.state.value ? store.state.value : value;
    const state = {
      title,
      leftIcon,
      rightIcon,
      autoFocus,
      placeholder,
      tabIndex: -1,
      text: _value,
      value: _value,
      config: initConfig,
      configIsFetching: false,
      maxLength: maxLength || 400,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    return state;
  }
}

export default new LabelReduce();
