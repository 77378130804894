import Reduce from './reduce';
import PageHelper from '@mjcloud/page-helper';
import { EventListening } from '@mjcloud/utils';
import SelectInstanceBase from '../common/select';
import Store, { ActionBeforeEventArg } from '@mjcloud/redux';
import SelectExtendStoreBase from '../common/select/extendStore';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { ControlConfig, DataModeEnum, IDictionary } from '@mjcloud/types';
import {
  ISelectTreeConfig,
  ISelectTreeState,
  SelectTreeEventType,
  SelectTreeActionType,
} from './typings';

class SelectTreeExtendStore extends SelectExtendStoreBase<string, SelectTree> {
  handleInitialStateBefore(e: ActionBeforeEventArg<any>) {
    e.params.iconFn = async (row: IDictionary) => {
      const { iconControl } = this.instance.store.state;
      if (iconControl && row) {
        // TODO: 修复因QueueCallback去掉异步所引起的Bug
        const fn = PageHelper.createPageScriptFunction(this.instance.page, iconControl, true);
        return fn(EventListening.getHandlerArg(this.instance, { row }));
      }
      return undefined;
    };
  }
}

class SelectTree extends SelectInstanceBase<
  string,
  ISelectTreeState,
  SelectTreeEventType,
  SelectTreeActionType
> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  getDataMode(): DataModeEnum {
    return DataModeEnum.all;
  }

  __createStore() {
    return new Store<ISelectTreeState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new SelectTreeExtendStore(this),
    });
  }

  initialState(initConfig: ISelectTreeConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  rowIsExistSubset(row: any) {
    const { _children = [] } = row;
    if (_children instanceof Array && _children.length > 0) {
      return true;
    }
    return false;
  }

  choose2Index(index: number) {
    const { originalDataSource } = this.store.state,
      chooseRow = originalDataSource[index];
    if (chooseRow) {
      const { _value: value, _text: text } = chooseRow;
      this.store.dispatch('updateValue', { value, text, actionSourceSign: this.store });
    }
  }

  multChoose2Index(indexs: number[]) {
    let values: string[] = [],
      texts: string[] = [];
    const { textTokenSeparator, vaueleTokenSeparator, originalDataSource } = this.store.state;
    indexs.forEach(index => {
      const chooseRow = originalDataSource[index];
      if (chooseRow) {
        values.push(chooseRow._value);
        texts.push(chooseRow._text);
      }
    });
    this.store.dispatch('updateValue', {
      value: values.join(vaueleTokenSeparator),
      text: texts.join(textTokenSeparator),
      actionSourceSign: this.store,
    });
  }
}

export default SelectTree;
