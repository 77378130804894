import { IDictionary } from '@mjcloud/types';

export interface IMapType2Icon {
  icon: string;
  color: string;
}

const map: IDictionary<string> = {
  '.png': 'image/png',
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
};
const mapIcon: IDictionary<IMapType2Icon> = {
  '.doc': { icon: 'icon-doc', color: '#2A5699' },
  '.docx': { icon: 'icon-doc', color: '#2A5699' },
  '.ppt': { icon: 'icon-ppt', color: '#D24625' },
  '.pptx': { icon: 'icon-ppt', color: '#D24625' },
  '.xls': { icon: 'icon-xls', color: '#207245' },
  '.xlsx': { icon: 'icon-xls', color: '#207245' },
  '.pdf': { icon: 'icon-pdf', color: '#DC2E1B' },
  '.png': { icon: 'icon-png', color: '#077467' },
  '.jpg': { icon: 'icon-jpg', color: '#077467' },
  '.jpeg': { icon: 'icon-jpg', color: '#077467' },
};

export function mapFileType(type: string) {
  return map[type] || type;
}

export function mapFileType2Icon(type: string): IMapType2Icon {
  return mapIcon[type] || { icon: 'icon-othe', color: '#A33639' };
}
