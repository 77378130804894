import { IIframeConfig } from './typings';

const config: IIframeConfig = {
  delay: false,
  emptyDisplayMode: 0,
  pageInfo: null as any,
  displayMode: undefined,
};

export default config;
