import { DataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import ViewModelHelper from '@mjcloud/data-model';
import { IFormState } from './typings';

class FormReduce extends DataReduceBase {
  initialState(store, params) {
    const { initConfig, pageMode, sections } = params,
      { displayMode } = initConfig,
      dataSource = ViewModelHelper.createViewModel(),
      state: IFormState = {
        sections,
        dataSource,
        tabIndex: -1,
        isFetching: false,
        config: initConfig,
        configIsFetching: false,
        display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      };
    return state;
  }

  loaded(store, params) {
    const { dataSource } = store.state,
      { dataSource: _dataSource, dataState } = params;
    dataSource.initDatas(_dataSource, dataState);
    return { ...store.state, isFetching: false };
  }
}

export default new FormReduce();
