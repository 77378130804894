import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import { PageModeEnum } from '@mjcloud/types';
import RouterHelper from '@mjcloud/router-helper';
import {
  IAppState,
  IAppBackParams,
  IAppRefreshParams,
  IAppInitialStateParams,
  IAppUpdateAppInfoParams,
  IAppInstallTemplateParams,
} from './typings';

class AppReduce extends ReduceBase<IAppState> {
  public initialState(store: Store<IAppState>, param: IAppInitialStateParams) {
    const { initConfig } = param,
      { appId } = initConfig.match.params,
      { query = {}, state: _params = {} } = initConfig.location,
      pageId = RouterHelper.getPageId(initConfig),
      moduleId = RouterHelper.getModuleId(initConfig),
      pageMode = RouterHelper.getPageMode(initConfig),
      params = { ...query, ..._params };
    const state: IAppState = {
      history: [],
      tabIndex: 0,
      display: true,
      showInDev: true,
      reLaunchIndex: 1,
      config: initConfig,
      appInfo: undefined,
      pageKey: undefined,
      Template: undefined,
      configIsFetching: false,
      info: {
        address: {
          appId,
          moduleId,
          pageId,
          pageMode,
          authorityNeedAddress: !!params._authorityNeedAddress,
        },
        params,
      },
    };
    return state;
  }

  public refresh(store: Store<IAppState>, param: IAppRefreshParams) {
    const { params, pageId = 'Main', moduleId, pageMode = PageModeEnum.none, ...address } = param;
    const state: IAppState = {
      ...store.state,
      pageKey: undefined,
      Template: undefined,
      errorMessage: undefined,
      info: {
        address: {
          ...address,
          pageId,
          moduleId,
          pageMode,
          authorityNeedAddress: !!params._authorityNeedAddress,
        },
        params,
      },
    };
    return state;
  }

  public back(store: Store<IAppState>, params: IAppBackParams) {
    let state: IAppState = { ...store.state };
    const { destroyTemplate, n } = params,
      historyLength = state.history.length;
    if (historyLength >= 2) {
      const deleteCount = historyLength - n - 1;
      for (let i = 0; i < deleteCount; i++) {
        const { pageKey } = state.history[state.history.length - 1];
        // destroyTemplate(pageKey);
        state.history.pop();
      }
      const { pageKey, Template } = state.history[state.history.length - 1];
      state.Template = Template;
      state.pageKey = pageKey;
      return state;
    } else {
      return store.state;
    }
  }

  public updateAppInfo(store: Store<IAppState>, params: IAppUpdateAppInfoParams) {
    const { appInfo, param } = params;
    let iframePageKey: string | undefined = undefined,
      parentPageKey: string | undefined = undefined;
    if (param.params && param.params.__iframePageKey) {
      iframePageKey = param.params.__iframePageKey;
    }
    if (param.params && param.params.__parentPageKey) {
      parentPageKey = param.params.__parentPageKey;
    } else {
      const historyList = store.state.history,
        historyLength = historyList.length;
      if (historyLength > 0 && historyList[historyLength - 1]) {
        parentPageKey = historyList[historyLength - 1].pageKey;
      }
    }
    const state: IAppState = { ...store.state, appInfo, iframePageKey, parentPageKey };
    return state;
  }

  public pushExtraPage(store: Store<IAppState>, params) {
    const { pageKey, getPage, registerPage } = params,
      state: IAppState = store.state,
      extraPageKey = `_extra[${pageKey}]`;
    registerPage(extraPageKey, getPage(pageKey));
    state.history.push({ pageKey: extraPageKey, Template: null as any });
    return state;
  }

  public installTemplate(store: Store<IAppState>, params: IAppInstallTemplateParams) {
    const { Template, action, pageKey, getTemplate, destroyTemplate } = params;
    const state: IAppState = { ...store.state, Template, pageKey };
    if (state.history.length >= 2) {
      switch (action) {
        case 'PUSH':
          const page = getTemplate(pageKey);
          page.__setPreviousPage(state.history[state.history.length - 1].pageKey);
          state.history.push({ pageKey, Template });
          break;

        case 'REPLACE':
          // destroyTemplate(state.history[state.history.length - 1].pageKey);
          state.history[state.history.length - 1] = { pageKey, Template };
          break;
      }
    } else {
      state.history.push({ pageKey, Template });
    }
    return state;
  }

  public fetchTemplateError(store: Store<IAppState>, params: any) {
    const { errorMessage, showInDev = false } = params;
    const state: IAppState = { ...store.state, errorMessage, showInDev };
    return state;
  }

  public prevTab(store: Store<IAppState>, params: any) {
    const { tabIndex } = store.state;
    if (tabIndex == null) {
      return store.state;
    }
    store.state.tabIndex--;
    return store.state;
  }

  public nextTab(store: Store<IAppState>, params: any) {
    const { tabIndex } = store.state;
    if (tabIndex == null) {
      return store.state;
    }
    store.state.tabIndex++;
    return store.state;
  }
}

export default new AppReduce();
