import React, { createRef } from 'react';
import classNames from 'classnames';
import globalData from '@mjcloud/global-data';
import { Row, Col } from 'antd';
import { findDOMNode } from 'react-dom';
import ControlBase from '../../core/base';
import { Tabs, SearchBar } from 'antd-mobile';
import { ISimpleSearchState } from '@mjcloud/mcontrols/dist/controls/simple-search/typings';

import styles from './index.less';

export default class SimpleSearchControl extends ControlBase<ISimpleSearchState> {
  private searchBarRef = createRef<SearchBar>();
  private handleChange = (value: string) => {
    this.instance.store.dispatch('updateValue', { value });
  };
  private handleSubmit = (value: string) => {
    this.instance.store.dispatch('find', { value });
  };

  private handleClear = (value: string) => {
    this.instance.store.dispatch('find', { value });
  };

  private handleFocus = () => {
    this.instance.store.dispatch('onFocus', {});
  };

  private handleBlur = () => {
    this.instance.store.dispatch('onBlur', {});
    // TODO fix SearchBar 的 value 无法清空的异常
    if (this.searchBarRef.current) {
      const el = findDOMNode(this.searchBarRef.current);
      if (el instanceof Element) {
        const input = el.getElementsByTagName('input')[0];
        if (input) input.value = '';
      }
    }
  };

  renderResult() {
    const { ControlFactory } = globalData;
    const { isSearch, tabs, _tabs } = this.state;
    if (!isSearch) {
      return _tabs.map((items, i) => (
        <Row gutter={24} key={i} className={styles.dySearchEmpty}>
          {items.map(({ id, title }) => (
            <Col span={8}>
              <a key={id}>{title}</a>
            </Col>
          ))}
        </Row>
      ));
    }
    return (
      <Tabs tabs={tabs}>
        {tabs.map(item => {
          if (item.id === 'all') {
            return <div key={item.id}>all</div>;
          } else {
            return (
              <div key={item.id} style={{ height: 'calc(100vh - 88px)' }}>
                <ControlFactory {...item} />
              </div>
            );
          }
        })}
      </Tabs>
    );
    // return null;
  }

  renderContent() {
    const { placeholder, searchValue, one, active, theme } = this.state;

    return (
      <div
        className={classNames(
          styles.dySearch,
          one && styles.one,
          active && styles.dySearchActive,
          !active && theme === 'blue' && styles.dySearchBlue,
        )}
      >
        <SearchBar
          ref={this.searchBarRef}
          value={searchValue}
          placeholder={placeholder}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onClear={this.handleClear}
          onFocus={this.handleFocus}
          onCancel={this.handleBlur}
        />
        {active ? this.renderResult() : null}
      </div>
    );
  }
}
