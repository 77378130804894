import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';

class TabsReduce extends ReduceBase {
  initialState(store, params) {
    let { initConfig, texts, items, activeIndex = 0, isShowBottomBlank = true, pageMode } = params,
      { displayMode, page } = initConfig,
      auto2width = 64,
      auto2itemwidth = 0;
    for (const text of texts) {
      auto2itemwidth = 64;
      auto2itemwidth += 28 * text.length;
      if (auto2itemwidth > 544) auto2itemwidth = 544;
      auto2width += auto2itemwidth;
      console.log('TabsReduce-auto2itemwidth', auto2itemwidth);
    }
    console.log('TabsReduce-auto2width', auto2width);

    return {
      page,
      texts,
      items,
      activeIndex,
      tabIndex: -1,
      isShowBottomBlank,
      config: initConfig,
      configIsFetching: false,
      widthType: auto2width > 750 - 80 ? 'auto' : 'full',
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
  }

  updateActiveIndex(store, params) {
    return { ...store.state, activeIndex: params.activeIndex };
  }

  updateTabPaneHeight(store, params) {
    return { ...store.state, tabPaneHeight: params.tabPaneHeight };
  }
}

export default new TabsReduce();
