// import Iframe from '../Iframe';
import globalData from '@mjcloud/global-data';
import PageHelper from '@mjcloud/page-helper';
import RouterHelper from '@mjcloud/router-helper';
import { IInitialStateParams } from '@mjcloud/redux';
import { IDictionary, XmlNode, PageModeEnum, OpenModeEnum } from '@mjcloud/types';

export interface IPageInfo {
  pageId: string;
  moduleId: string;
  appId: string;
  width?: string | number;
  height?: string | number;
  pageMode: PageModeEnum;
  openModal?: OpenModeEnum;
  title: string;
}

export interface IModalInitialStateParams extends IInitialStateParams<ModalConfig> {
  // iframe: Iframe;
}

export interface ModalConfig extends XmlNode {
  id: string;
  pageInfo: IPageInfo;
  params: IDictionary;
}

export interface IPopupModalParams {
  pageInfo?: IPageInfo;
  params?: IDictionary;
  url?: string;
  afterCallback?: (modalId: string) => void;
  closeAfter?: (modalId: string, param: IDictionary) => void;
}

interface ModalItem extends IPopupModalParams {
  modalId: string;
}

let modalCount = 0;

export default class ModalHelper {
  private static modalList: ModalItem[] = [];
  private static modalCloseParamList: IDictionary<IDictionary> = {};

  public static popupModal(param: IPopupModalParams) {
    if (globalData.rbCore.isMobile) {
      this.popupModalByMobile(param);
    } else {
      this.popupModalByPC(param);
    }
  }

  public static closeModal(modalId: string, param: IDictionary = {}) {
    if (globalData.rbCore.isMobile) {
      this.modalCloseParamList[modalId] = param;
      RouterHelper.goBack();
    } else {
      this.closeModalByPC(modalId, param);
    }
  }

  public static popupModalByPC(param: IPopupModalParams) {
    const id = `modal_${++modalCount}`,
      { afterCallback } = param;
    this.modalList.unshift({ ...param, modalId: id });
    if (afterCallback) afterCallback(id);
  }

  public static popupModalByMobile(param: IPopupModalParams) {
    const { modalId } = RouterHelper.query,
      pageId = RouterHelper.getPageId(),
      moduleId = RouterHelper.getModuleId(),
      { afterCallback, closeAfter } = param,
      id = `modal_${++modalCount}`;
    let pathname = '';
    if (id === modalId) {
      pathname = PageHelper.formatMemuPath({ appId: globalData.appId, pageId, moduleId });
      RouterHelper.replace(pathname);
      return;
    } else {
      pathname = PageHelper.formatMemuPath({
        appId: globalData.appId,
        params: { modalId: id },
        moduleId,
        pageId,
      });
      this.modalList.unshift({ ...param, modalId: id });
      RouterHelper.push({
        pathname,
        afterCallback: () => {
          if (afterCallback) afterCallback(id);
        },
        backAfter: () => {
          if (closeAfter) closeAfter(id, { ...this.modalCloseParamList[id] });
        },
      });
    }
  }

  public static closeModalByPC(modalId: string, param?: IDictionary) {
    let start = 0;
    for (let i = 0; i < this.modalList.length; i++) {
      let _param: IDictionary = { ...param };
      const { modalId: id, closeAfter } = this.modalList[i];
      start = i;
      if (param == null && this.modalCloseParamList[modalId]) {
        _param = this.modalCloseParamList[modalId];
      }
      if (closeAfter) closeAfter(id, _param);
      if (id === modalId) break;
    }
    this.modalList.splice(start, 1);
  }
}
