import Store from '@mjcloud/redux';
import Reduce from './reduce';
import globalData from '@mjcloud/global-data';
import ModalHelper from '@mjcloud/modal-helper';
import { InstanceBase } from '@mjcloud/instance';
import IframeExtendStore from './extendStore';
import { PageModeEnum, IDictionary } from '@mjcloud/types';
import { IPageRefreshParams } from '@mjcloud/page/dist/typings';
import {
  IIframeState,
  IIframeConfig,
  IframeEventType,
  IframeActionType,
  IframePushParams,
  IframeBackParams,
  IframeReplaceParams,
  IframeStartLoadParams,
  IframeConfigStartLoadParams,
} from './typings';

// /**
//  * 无数据状态
//  */
// enum NoDataStatusEnum {
//   /** 加载中 */
//   loading,
//   /** 空状态 */
//   emptyStatus,
// }

export default class Iframe extends InstanceBase<IIframeState, IframeEventType, IframeActionType> {
  public __createStore() {
    return new Store<IIframeState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new IframeExtendStore(this),
    });
  }

  initialState(initConfig: IIframeConfig) {
    this.store.dispatch<IframeConfigStartLoadParams>('configStartLoad', {
      initConfig,
    });
  }

  /**
   * 当前iframe下的page实例
   */
  get currentPage() {
    const { pageKey } = this.store.state;
    if (pageKey) {
      const page = globalData.rbCore.getPage(pageKey);
      return page;
    }
    return undefined;
  }

  /**
   * 刷新页面
   */
  public refresh() {
    this.store.dispatch<IframeStartLoadParams>('startLoad', {});
  }

  /**
   * 在不影响 history 长度的情况下（如一个重定向），用新的页面替换当前页面。
   */
  public replace(params: IPageRefreshParams) {
    this.store.dispatch<IframeReplaceParams>('replace', {
      ...params,
      defaultappId: globalData.rbCore.config.appId,
      defaultPageMode: PageModeEnum.none,
    });
  }

  /**
   * 跳转至一个新的页面
   */
  public push(params: IPageRefreshParams) {
    this.store.dispatch<IframePushParams>('push', {
      ...params,
      defaultappId: globalData.rbCore.config.appId,
      defaultPageMode: PageModeEnum.none,
    });
  }

  /**
   * 在 history 中后退
   */
  public back(param: IDictionary = {}) {
    this.store.dispatch<IframeBackParams>('back', {
      param,
      destroyTemplate: globalData.rbCore.destroyPage.bind(globalData.rbCore),
    });
  }

  /**
   * 关闭页面
   * @param param
   */
  close(param: IDictionary = {}): void {
    if (this.id.startsWith('tierIframe_')) {
      ModalHelper.closeModal(this.id.substring(11), param);
    } else {
      this.page.close(param);
    }
  }

  reLaunch() {
    // TODO: 需要实现reLaunch功能，方便业务开发使用
  }

  // /**
  //  * 设置控件的无数据时的状态，页面根据状态类型显示对应界面
  //  * @param status 无数据状态枚举
  //  */
  // setStatus(status: NoDataStatusEnum) {}



  
}
