interface IOriginalArray<T> {
  [key: number]: T;
}
interface IDictionary {
  colspan?: number;
  [key: string]: any;
}
type ColspanFn<T> = (sourceItem: T) => number;
export default class ArrayHelper {
  /**
   * 一维数组转化为二维数组
   * @param sourceArray 一维数组
   * @param num 每个子数组里的元素个数
   */
  public static oneArray2twoArray<T extends IDictionary>(
    sourceArray: T[],
    num: number,
    colspanFn: ColspanFn<T> = ({ colspan = 1 }) => colspan,
  ): T[][] {
    let colspan: number;
    const source: T[] = [...sourceArray];
    const _colspanFn: ColspanFn<T> = item => {
      let colspan = colspanFn(item) || 1;
      if (isNaN(colspan)) colspan = 1;
      return colspan;
    };

    // ========================== 补全缺失的blank ==========================
    let k: number;
    let sourceBlank = 0;
    let blankArray: T[] = [];
    const len = sourceArray.length;
    for (let i = 0; i < len; ) {
      sourceBlank = 0;
      for (k = 0; k < num; k++) {
        if (!sourceArray[i + k]) break;
        colspan = _colspanFn(sourceArray[i + k]);
        if (colspan > num) colspan = num;
        if (sourceBlank + colspan > num) break;
        sourceBlank += colspan;
      }
      if (sourceBlank < num) {
        blankArray = Array.from(
          { length: num - sourceBlank },
          () => ({ __placeholder: true } as any),
        );
        source.splice(i + k, 0, ...blankArray);
      }
      i += k;
    }

    // ========================== 格式化源数组 ==========================
    let sourceArrayCount = 0;
    let originalArray: IOriginalArray<T> = {};
    for (let i = 0, j = 0; i < source.length; i++) {
      colspan = _colspanFn(source[i]);
      originalArray[j] = source[i];
      sourceArrayCount += colspan;
      j += colspan;
    }

    let twoArray = new Array(Math.ceil(sourceArrayCount / num)) as T[][];
    for (let i = 0; i < twoArray.length; i++) {
      twoArray[i] = new Array() as T[];
      for (let j = 0; j < num; j++) {
        twoArray[i][j] = undefined as any;
      }
    }
    for (let i = 0; i < sourceArrayCount; i++) {
      const original = originalArray[i];
      if (original) {
        twoArray[parseInt((i / num).toString())][i % num] = original;
      }
    }

    // ========================== 删除占位item ==========================
    twoArray = twoArray.map(item => {
      return item.filter(value => {
        if (value == null) return false;
        if (value['__placeholder']) return false;
        return true;
      }) as T[];
    });
    return twoArray;
  }
}
