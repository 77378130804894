import Store from '@mjcloud/redux';
import Reduce from './reduce';
import WorkbenchPart from './part';
import { ExceptionHelper } from '@mjcloud/exception';
import WorkbenchExtendStore from './extendStore';
import { ContainerInstanceBase } from '@mjcloud/instance';
import { EventManager, ConsoleHelper } from '@mjcloud/utils';
import { PageStatusEnum, IDictionary } from '@mjcloud/types';
import { getWorkbenchConfig, WorkbenchPartItemConfig, WorkbenchGroupConfig } from './util';
import {
  IWorkbenchPart,
  IWorkbenchGroup,
  IWorkbenchState,
  IWorkbenchConfig,
  IWorkbenchGroupItem,
  IWorkbenchPartConfig,
  IWorkbenchPageConfig,
  IWorkbenchConfigStartLoadParams,
} from './typings';

export default class Workbench extends ContainerInstanceBase<IWorkbenchState> {
  private _readyCallback?: () => void;
  private partInitCount = 0;
  /**
   * 当前部件列表是否需要初始化，不需要则直接执行 _readyCallback
   */
  private isPartInit = false;
  private _paths: IDictionary<IWorkbenchPageConfig> = {};
  private _parts: IDictionary<WorkbenchPart> = {};
  private _busManager = new EventManager<string>(this);

  __createStore() {
    return new Store<IWorkbenchState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new WorkbenchExtendStore(this),
    });
  }

  initialState(initConfig: IWorkbenchConfig) {
    this.store.dispatch<IWorkbenchConfigStartLoadParams>('configStartLoad', {
      initConfig,
      callbackfn: async config => {
        const { gutter, parts } = await this.__getWorkbenchPageConfig(config.path);
        config.parts = parts;
        config.gutter = gutter;
        return config;
      },
    });
  }

  __createWorkbenchPart(config: IWorkbenchPartConfig) {
    const { name: partId } = config;
    if (!partId)
      throw ExceptionHelper.argumentNullException('name', '工作台部件配置缺少必填的name');
    if (this._parts[partId]) return this._parts[partId];

    this._parts[partId] = new WorkbenchPart(partId, this, config);
    this.isPartInit = true;
    this.partInitCount++;
    return this._parts[partId];
  }

  async __getWorkbenchPageConfig(path: string) {
    let _config: IWorkbenchPageConfig = { gutter: 8, parts: [] };
    if (!path) return _config;
    if (this._paths[path]) {
      if (this._readyCallback) {
        this._readyCallback();
        this._readyCallback = undefined;
      }
      return this._paths[path];
    }

    const config = await getWorkbenchConfig(path);

    if (config) {
      let errMsg = `工作台页面配置存在错误，页面路径：${path}, 错误信息：部件`,
        isErr = false,
        partNames: IDictionary<number> = {};
      this.isPartInit = false;
      this.partInitCount = 0;
      const { gutter = 8, items } = config.parts,
        size = 750 / gutter,
        parts = items
          .map(item => {
            if (item.nodeName === 'part') {
              const part = { ...item } as WorkbenchPartItemConfig,
                { width = gutter } = part,
                instance = this.__createWorkbenchPart(part);
              return { ...part, width, size, id: instance['id'] } as IWorkbenchPart;
            } else if (item.nodeName === 'group') {
              let auto2width = 64,
                auto2itemwidth = 0;
              const group = { ...item } as WorkbenchGroupConfig,
                { width = gutter } = group,
                parts = group.items.map(group => {
                  const _group: IWorkbenchGroupItem = {
                    title: group.title,
                    parts: group.parts.items.map(p => {
                      const { width: _width = width } = p,
                        instance = this.__createWorkbenchPart(p);
                      return { ...p, width: _width, size, id: instance['id'] };
                    }),
                  };
                  return _group;
                }),
                texts = parts.map(part => part.title);
              for (const text of texts) {
                auto2itemwidth = 64;
                auto2itemwidth += 28 * text.length;
                if (auto2itemwidth > 544) auto2itemwidth = 544;
                auto2width += auto2itemwidth;
              }
              return {
                ...item,
                width,
                texts,
                items: parts,
                widthType: auto2width > 750 ? 'auto' : 'full',
              } as IWorkbenchGroup;
            }
            return undefined;
          })
          .filter(item => !!item);
      _config = { gutter, parts } as IWorkbenchPageConfig;
      if (!this.isPartInit && this._readyCallback) {
        this._readyCallback();
        this._readyCallback = undefined;
      }
      for (const key in partNames) {
        if (partNames[key] > 1) {
          isErr = true;
          errMsg += key + `、`;
        }
      }
      if (isErr) {
        errMsg = errMsg.substring(0, errMsg.length - 1);
        throw ExceptionHelper.argumentException('workbench', errMsg);
      }
    }

    this._paths[path] = _config;
    return _config;
  }

  __partLoadComplete(partId: string) {
    if (this.isPartInit) {
      this.partInitCount--;
      if (this.partInitCount === 0 && this._readyCallback) {
        this._readyCallback();
        this._readyCallback = undefined;
      }
    }
  }

  /**
   * 当前控件的数据总线管理器
   */
  get busManager() {
    return this._busManager;
  }

  findPart(id: string) {
    return this._parts[id];
  }

  existPath(path: string) {
    return !!this._paths[path];
  }

  updatePath(path: string, cb: () => void) {
    this._readyCallback = cb;
    this.store.dispatch('startUpdatePath', { path });
  }
}
