import { env as ddenv } from 'dingtalk-jsapi';
import { ENV_ENUM as ddENV_ENUM, APP_TYPE as ddAPP_TYPE } from 'dingtalk-jsapi/lib/sdk/sdkLib';

export enum CONTAINER_ENUM {
  WEB = 'WEB',
  DINGTALK = 'DINGTALK',
  WEIXIN = 'WEIXIN',
}

export enum ENV_ENUM {
  pc = 'pc',
  android = 'android',
  ios = 'ios',
}

export enum APP_TYPE {
  /** web 程序 */
  WEB = 'WEB',
  /** 钉钉E应用 */
  EAPP = 'EAPP',
  /** 微信小程序 */
  MINIAPP = 'MINIAPP',
  /** 钉钉H5微应用 */
  WEBVIEW_IN_DDAPP = 'WEBVIEW_IN_DDAPP',
  /** 微信H5微应用 */
  WEBVIEW_IN_WXAPP = 'WEBVIEW_IN_WXAPP',
  /** 企业微信H5微应用 */
  WEBVIEW_IN_WWAPP = 'WEBVIEW_IN_WWAPP',
}

export default class ENV {
  static get userAgent() {
    const userAgent = navigator && navigator.userAgent;
    return userAgent == null ? '' : userAgent;
  }
  static get versions() {
    const u = this.userAgent;
    return {
      //移动终端浏览器版本信息
      trident: u.indexOf('Trident') > -1, //IE内核
      presto: u.indexOf('Presto') > -1, //opera内核
      webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
      iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf('iPad') > -1, //是否iPad
      webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
    };
  }

  /** current client is mobile */
  static get isMobile() {
    if (this.isDingTalk) {
      return ENV.platform === ENV_ENUM.android || ENV.platform === ENV_ENUM.ios;
    }
    return this.versions.mobile;
  }

  /** current container is dingtalk */
  static get isDingTalk() {
    return this.container === CONTAINER_ENUM.DINGTALK;
  }

  /** current container is wechat */
  static get isWeChat() {
    return this.container === CONTAINER_ENUM.WEIXIN;
  }

  /** current container (web or dingtalk or weixin) */
  static get container(): CONTAINER_ENUM {
    const platform = ddenv.platform,
      ua = this.userAgent.toLowerCase(); //获取判断用的对象;
    if (platform !== ddENV_ENUM.notInDingTalk) {
      return CONTAINER_ENUM.DINGTALK;
    }
    if (ua.match(/MicroMessenger/i)) {
      //在微信中打开
      return CONTAINER_ENUM.WEIXIN;
    }
    if (wx && !wx.isJsSDK) {
      // 微信小程序
      return CONTAINER_ENUM.WEIXIN;
    }
    return CONTAINER_ENUM.WEB;
  }

  /** current platform (iOS or Android or PC) */
  static get platform(): ENV_ENUM {
    if (this.isDingTalk) {
      // 当前应用属于钉钉应用
      switch (ddenv.platform) {
        case ddENV_ENUM.android:
          return ENV_ENUM.android;
        case ddENV_ENUM.ios:
          return ENV_ENUM.ios;
        case ddENV_ENUM.pc:
        default:
          return ENV_ENUM.pc;
      }
    } else {
      // 非钉钉容器判断当前应用属于什么平台
      if (this.versions.mobile) {
        if (this.versions.ios) {
          //是否在IOS浏览器打开
          return ENV_ENUM.ios;
        }
        if (this.versions.android) {
          //是否在安卓浏览器打开
          return ENV_ENUM.android;
        }
      }
      return ENV_ENUM.pc;
    }
  }

  /** current client version */
  static get version(): string | undefined {
    return ddenv.version;
  }

  /** @deprecated recommend use navigator.language to get current language */
  static get language(): string | undefined {
    return ddenv.language;
  }

  /** current appType(web or eapp or miniapp) */
  static get appType(): APP_TYPE {
    if (this.isDingTalk) {
      // 当前应用属于钉钉应用
      switch (ddenv.appType) {
        case ddAPP_TYPE.MINI_APP:
          return APP_TYPE.EAPP;
        default:
          return APP_TYPE.WEBVIEW_IN_DDAPP;
      }
    } else {
      // 非钉钉容器判断当前应用属于什么应用类型
      if (wx && !wx.isJsSDK) return APP_TYPE.MINIAPP;
      const ua = this.userAgent.toLowerCase();
      if (ua.match(/wxwork/i)) {
        //在企业微信中打开
        return APP_TYPE.WEBVIEW_IN_WWAPP;
      } else if (ua.match(/MicroMessenger/i)) {
        //在微信中打开
        return APP_TYPE.WEBVIEW_IN_WXAPP;
      }
      return APP_TYPE.WEB;
    }
  }

  [key: string]: any;
}
