import React from 'react';
import { InputNumber, Tooltip } from 'antd';
import ControlBase from '../../base';
import { INumberState } from '../typings';
import { InputNumberProps } from 'antd/lib/input-number';

class NumberControl extends ControlBase<INumberState> {
  private handChange = (value: string | number | null | undefined) =>
    this.instance.store.dispatch('updateValue', {
      value: value,
      text: value,
      actionSourceSign: this.instance.store,
    });

  private handFocus = (e: any) => {
    const { autoFocus } = this.state;
    if (autoFocus && e.target && e.target.select) e.target.select();
    // this.instance.store.dispatch('updateFocus', { isFocus: true });
  };

  // private handBlur = () => {
  //   this.instance.store.dispatch('updateFocus', { isFocus: false });
  // };

  renderContent() {
    const { value, text, placeholder, autoFocus, disabled } = this.state,
      { max, min, step, isFocus, format } = this.state;
    if (this.state.readonly) return <>{text || ''}</>;
    const inputNumberProps: InputNumberProps = {
      max,
      min,
      step,
      value,
      disabled,
      autoFocus,
      placeholder,
      id: this.props.id,
      // parser: value => `${value}`.replace(/\$\s?|(,*)/g, ''),
      // formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      // parser: value => NumberHelper.parse(value),
      // formatter: value => (value == null ? '' : NumberHelper.format(value, format)),
    };
    console.info('Number.state', this.state)
    return (
      <Tooltip trigger="focus" title={text || placeholder} placement="topLeft">
        <InputNumber
          {...inputNumberProps}
          onChange={this.handChange}
          onFocus={this.handFocus}
          // onBlur={this.handBlur}
          style={{ width: '100%' }}
        />
      </Tooltip>
    );
  }
}
export default NumberControl;
