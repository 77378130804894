import { IFileManagerConfig } from './typings';

const config: IFileManagerConfig = {
  title: '',
  mode: undefined,
  placeholder: undefined,
  limitType: undefined,
  maxSize: undefined,
  styleName: 'normal',
  businessType: '',
  businessId: '',
  maxFileCount: -1,
  displayMode: undefined,
  disabledMode: undefined,
};

export default config;
