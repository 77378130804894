import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import { OpenModeEnum } from '@mjcloud/types';
import { IModalInitialStateParams, IPageInfo } from '@mjcloud/modal-helper';
import { IModalState } from './typings';

function getStyles(pageInfo: IPageInfo, ratio = 0.8) {
  const { width, height } = pageInfo,
    innerHeight = window.innerHeight - 120;
  let top: string | number | undefined,
    _height = height ? height : window.innerHeight - 312,
    _width = width ? width : window.innerWidth * ratio;
  if (typeof _height === 'number' && _height > innerHeight) {
    _height = innerHeight;
    top = 24;
  }
  if (typeof _width === 'number' && _width > window.innerWidth) {
    _width = window.innerWidth;
  }
  return { top, width: _width, height: _height };
}

class ModalReduce extends ReduceBase<IModalState> {
  initialState(store: Store<IModalState>, params: IModalInitialStateParams) {
    const { initConfig } = params,
      { id, pageInfo } = initConfig,
      { title, openModal } = pageInfo,
      isDrawer = openModal === OpenModeEnum.drawer,
      { top, width, height } = getStyles(pageInfo, isDrawer ? 0.6 : 0.8);
    const state: IModalState = {
      id,
      top,
      title,
      width,
      height,
      pageInfo,
      tabIndex: -1,
      display: true,
      visible: true,
      config: initConfig,
      afterClose: undefined,
      configIsFetching: false,
      isDrawer: openModal === OpenModeEnum.drawer,
    };
    return state;
  }

  updateStyles(store: Store<IModalState>, params: {}) {
    const { top, width, height } = getStyles(store.state.pageInfo);
    if (store.state.width === width && store.state.height === height) {
      return store.state;
    }
    return { ...store.state, top, width, height };
  }

  openModal(store: Store<IModalState>, params: {}) {
    const state: IModalState = { ...store.state, visible: true };
    return state;
  }

  closeModal(store: Store<IModalState>, params: { cb: (() => void) | undefined }) {
    const state: IModalState = { ...store.state, visible: false, afterClose: params.cb };
    return state;
  }
}

export default new ModalReduce();
