import Store, {
  IState,
  IReduce,
  IInitialStateParams,
  IConfigLoadedParams,
  IConfigStartLoadParams,
  IConfigLoadErrorParams,
} from '@mjcloud/redux';

export default abstract class ReduceBase<S extends IState = IState> implements IReduce<S> {
  public configStartLoad(store: Store<S>, params: IConfigStartLoadParams): S {
    let state = store.state;
    state.configIsFetching = true;
    state.configErrorMessage = undefined;
    return state;
  }
  public configLoaded(store: Store<S>, params: IConfigLoadedParams): S {
    let state = store.state;
    state.configIsFetching = false;
    state.config = params.initConfig;
    return state;
  }
  public configLoadError(store: Store<S>, params: IConfigLoadErrorParams): S {
    return { ...store.state, configIsFetching: false, configErrorMessage: params.errorMessage };
  }
  public abstract initialState(store: Store<S>, params: IInitialStateParams): S;

  public updateDisplay(store: Store<S>, params: { display: boolean }): S {
    return { ...store.state, display: params.display };
  }
}
