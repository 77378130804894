import globalData from '@mjcloud/global-data';
import { BASEFONTSIZE } from './constant';
import { InstanceBase, ContainerInstanceBase } from '@mjcloud/instance';

interface GetInstanceParams {
  controlId: string;
  parentId: string;
  pageKey: string;
}

export function getInstance<I extends InstanceBase>(params: GetInstanceParams): I {
  const { pageKey, parentId, controlId } = params,
    page = globalData.rbCore.getPage(pageKey);
  let instance: any;
  if (pageKey === controlId) {
    // 当前控件为页面控件
    instance = page;
  } else {
    if (parentId === pageKey) {
      // 当前控件的父级控件为页面控件
      instance = page.findControl(controlId);
      if (!instance) instance = page.createControl(controlId);
    } else {
      // 当前控件的父级控件为非页面级别的容器控件
      const parent = page.findControl(parentId);
      if (parent instanceof ContainerInstanceBase) {
        instance = parent.findControl(controlId);
      }
    }
  }
  return instance;
}

export function px2rem(px?: number | string) {
  if (typeof px === 'string') return px;
  return px ? `${px / BASEFONTSIZE}rem` : undefined;
}
