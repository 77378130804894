import Store from '@mjcloud/redux';
import { px2rem } from '../../core/util';
import { DataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { ArrayHelper, NumberHelper, DateHelper } from '@mjcloud/utils';
import {
  IStatisticsInfo,
  IStatisticsItem,
  IStatisticsQuota,
  IStatisticsState,
  IStatisticsColumn,
  IStatisticsBriefItem,
  IStatisticsInfoBriefItem,
  IStatisticsQuotaBriefItem,
  IStatisticsInitialStateParams,
  IStatisticsLoadedParams,
  handleColorCommandFn,
} from './typings';

function formatStatisticsItem(value: string, type: string, config: any) {
  let _fontSize: string | undefined;
  const item: IStatisticsBriefItem = { style: {}, styleText: '', text: value };
  switch (type) {
    case 'datetime':
      const { format: dateFormat } = config;
      item.text = DateHelper.format(value, dateFormat);
      break;
    case 'number':
      const { format: numberFormat = '#,##0.00' } = config;
      if (typeof value === 'number' || typeof value === 'string') {
        value = NumberHelper.format(value, numberFormat);
        item.text = value;
      }
      item.text = value;
      break;
    case 'label':
      const { fontColor, fontSize, fontWeight, format2Number, format2Date } = config;
      _fontSize = px2rem(fontSize);
      if (format2Number) {
        item.text = NumberHelper.format(value, format2Number);
      } else if (format2Date) {
        item.text = DateHelper.format(value, format2Date);
      }
      item.style = { color: fontColor, fontSize: _fontSize, fontWeight };
      if (fontColor) item.styleText += `color: ${fontColor};`;
      if (fontSize) item.styleText += `font-size:${fontSize}rpx;`;
      if (fontWeight) item.styleText += `font-weight:${fontWeight};`;
      break;

    default:
      break;
  }
  if (item.text == null || item.text === '') item.text = '-';
  return item;
}

function format2quota(
  _item: IStatisticsItem,
  quota: IStatisticsQuota,
  columns: IStatisticsColumn[],
  handleColorCommand: handleColorCommandFn,
) {
  const { items } = quota;
  _item._quotaBriefList = [];
  for (const { id, interval, status } of items) {
    let _row: IStatisticsQuotaBriefItem = {};

    for (const { id: columnId, title, control, field } of columns) {
      const type = control && control.nodeName;
      // color = colorCommand && handleColorCommand(colorCommand, _item),
      if (id === columnId) {
        _row.val = {
          ...formatStatisticsItem(_item[field], type, control),
          value: NumberHelper.parse(_item[field]),
          title,
        };
      } else if (interval === columnId) {
        _row.interval = {
          ...formatStatisticsItem(_item[field], type, control),
          value: NumberHelper.parse(_item[field]),
          title,
        };
      } else if (status === columnId) {
        _row.status = NumberHelper.parse(_item[field]);
      }
    }
    if (!_row.status && _row.interval) {
      _row.status = 2;
      // if (_row.interval.value === 0) {
      //   _row.status = 2;
      // } else if (_row.interval.value > 0) {
      //   _row.status = 1;
      // } else {
      //   _row.status = 0;
      // }
    }
    if (_row.interval && _row.interval.text) {
      if (_row.status === 0) _row.interval.text = '-' + _row.interval.text;
      if (_row.status === 1) _row.interval.text = '+' + _row.interval.text;
    }
    _item._quotaBriefList.push(_row);
  }
  return _item;
}

function format2info(
  _item: IStatisticsItem,
  info: IStatisticsInfo,
  columns: IStatisticsColumn[],
  handleColorCommand: handleColorCommandFn,
) {
  const { items } = info;
  _item._infoBriefList = [];
  for (const { id, unitText, unit } of items) {
    let _row: IStatisticsInfoBriefItem = {};

    for (const { id: columnId, title, control, field } of columns) {
      const type = control && control.nodeName;
      // color = colorCommand && handleColorCommand(colorCommand, _item),
      if (id === columnId) {
        _row.val = {
          ...formatStatisticsItem(_item[field], type, control),
          value: NumberHelper.parse(_item[field]),
          unit: unitText,
          title,
        };
      }
    }
    _item._infoBriefList.push(_row);
  }
  return _item;
}

export class StatisticsReduce extends DataReduceBase<IStatisticsState> {
  initialState(store: Store<IStatisticsState>, params: IStatisticsInitialStateParams) {
    const { initConfig, pageMode } = params,
      { size, displayMode, info, quota, cols, showLine, showRows, rowClickController } = initConfig;
    let columns: IStatisticsColumn[] = [];
    if (initConfig.items.items) {
      for (const item of initConfig.items.items) {
        const { id, title, control, colorCommand } = item,
          { field = id } = item;
        if (field)
          columns.push({
            id,
            title,
            field,
            control,
            colorCommand,
          });
      }
    }
    const state: IStatisticsState = {
      cols,
      info,
      size,
      quota,
      columns,
      showLine,
      showRows,
      tabIndex: -1,
      collapse: true,
      dataSource: [],
      _dataSource: [],
      rowClickController,
      config: initConfig,
      showCollapse: false,
      size2rem: px2rem(size),
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  loaded(store: Store<IStatisticsState>, params: IStatisticsLoadedParams) {
    let rowIdCount = 0,
      { handleColorCommand } = params,
      { columns = [], quota, info, cols, showRows, collapse, showCollapse } = store.state,
      { rows } = params.dataSource,
      _dataSource: IStatisticsItem[][] = [];
    const dataSource = rows.map(item => {
      const _item: IStatisticsItem = {
        ...item,
        _briefList: [],
        _infoBriefList: [],
        _quotaBriefList: [],
        _quotaBriefListBak: [],
        _rid: ++rowIdCount,
      };
      if (quota) {
        return format2quota(_item, quota, columns, handleColorCommand);
      } else if (info) {
        return format2info(_item, info, columns, handleColorCommand);
      } else {
        for (const { field, control } of columns) {
          const type = control && control.nodeName;
          _item._briefList.push(formatStatisticsItem(_item[field], type, control));
        }
      }
      return _item;
    });

    if (quota) {
      if (dataSource.length > 0) {
        const quotaBriefList = dataSource[0]._quotaBriefList;
        dataSource[0]._quotaBriefListBak = [...quotaBriefList];
        if (showRows != null) {
          const showLength = cols * showRows;
          if (quotaBriefList.length > showLength) {
            showCollapse = true;
            dataSource[0]._quotaBriefList = quotaBriefList.filter((_, index) => index < showLength);
          } else {
            showCollapse = false;
          }
        } else {
          showCollapse = false;
        }
      }
    } else if (info) {
      showCollapse = false;
    } else {
      if (showRows != null && collapse) {
        const showLength = cols * showRows;
        if (dataSource.length > showLength) {
          showCollapse = true;
          _dataSource = ArrayHelper.oneArray2twoArray(
            dataSource.filter((_, index) => index < showLength),
            cols,
          );
        } else {
          showCollapse = false;
          _dataSource = ArrayHelper.oneArray2twoArray(dataSource, cols);
        }
      } else {
        showCollapse = false;
        _dataSource = ArrayHelper.oneArray2twoArray(dataSource, cols);
      }
    }
    return {
      ...store.state,
      isFetching: false,
      collapse: true,
      showCollapse,
      dataSource,
      _dataSource,
    };
  }

  rowClickAfter(store: Store<IStatisticsState>, params) {
    return store.state;
  }

  updateCollapse(store: Store<IStatisticsState>, params: { collapse: boolean }) {
    // if (params.collapse === store.state.collapse) return store.state;
    const state = { ...store.state, collapse: params.collapse },
      { cols, quota, collapse, dataSource, showRows } = state;
    if (quota) {
      if (dataSource.length > 0) {
        const quotaBriefList = dataSource[0]._quotaBriefListBak;
        if (showRows != null) {
          const showLength = cols * showRows;
          if (quotaBriefList.length > showLength) {
            state.showCollapse = true;
            dataSource[0]._quotaBriefList = collapse
              ? quotaBriefList.filter((_, index) => index < showLength)
              : quotaBriefList;
          } else {
            state.showCollapse = false;
            dataSource[0]._quotaBriefList = quotaBriefList;
          }
        } else {
          state.showCollapse = false;
          dataSource[0]._quotaBriefList = quotaBriefList;
        }
      }
    } else {
      if (showRows != null) {
        const showLength = cols * showRows;
        if (dataSource.length > showLength) {
          state.showCollapse = true;
          state._dataSource = !collapse
            ? ArrayHelper.oneArray2twoArray(dataSource, cols)
            : ArrayHelper.oneArray2twoArray(
                dataSource.filter((_, index) => index < showLength),
                cols,
              );
        } else {
          state.showCollapse = false;
          state._dataSource = ArrayHelper.oneArray2twoArray(dataSource, cols);
        }
      } else {
        state.showCollapse = false;
        state._dataSource = ArrayHelper.oneArray2twoArray(dataSource, cols);
      }
    }
    return state;
  }
}

export default new StatisticsReduce();
