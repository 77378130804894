import WorkbenchPart from '.';
import Service from '@mjcloud/service';
import globalData from '@mjcloud/global-data';
import { ENV, APP_TYPE } from '@mjcloud/jsapi';
import { RequireHelper } from '@mjcloud/utils';
import { IWorkbenchPartConfig } from './typings';
import { IEventArg } from '@mjcloud/utils/dist/events/eventListening';

/**
 * 获取工作台部件页面配置
 * @param path
 */
export async function getWorkbenchPartConfig(name: string) {
  const { appId, rbCore } = globalData;
  if (name) {
    return Service.requestServiceByNoVersion<IWorkbenchPartConfig>('/workbench/part', {
      workbenchPartAddress: { appId, partId: name, pageId: 'Main' },
      isMobile: rbCore.isMobile,
    });
  }
  return undefined;
}

/**
 * 获取工作台部件逻辑页
 * @param url 逻辑页地址
 */
export async function fetchPartLogicPage(url: string) {
  if (ENV.appType === APP_TYPE.EAPP || ENV.appType === APP_TYPE.MINIAPP) {
    return globalData.workbenchClassList[url] as typeof WorkbenchPartLogicBase;
  } else {
    const { apiHost } = globalData.rbCore.config;
    let _url = `${apiHost}/resource/static${url}`;
    try {
      const Logic = await RequireHelper.loadScript<typeof WorkbenchPartLogicBase>(_url);
      return Logic;
    } catch (error) {
      throw error;
    }
  }
}

export class WorkbenchPartLogicBase {
  constructor(protected part: WorkbenchPart) {
    const em = part.eventManager;
    if (this.partDidInited) em.add('inited', this.partDidInited.bind(this));
    if (this.partDidDataLoaded) em.add('dataLoaded', this.partDidDataLoaded.bind(this));
    if (this.partDidDone) em.add('done', this.partDidDone.bind(this));
  }

  get page() {
    return this.part.page;
  }

  /**
   * 部件下的控件初始化完成事件
   */
  protected partDidInited?(e: IEventArg<WorkbenchPart, {}>) {}
  /**
   * 部件下控件数据源加载完成事件
   */
  protected partDidDataLoaded?(e: IEventArg<WorkbenchPart, {}>) {}
  /**
   * 部件初始化完成事件
   */
  protected partDidDone?(e: IEventArg<WorkbenchPart, {}>) {}
}
