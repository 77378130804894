import Store from '@mjcloud/redux';
import { DataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { Filter, FilterTypeEnum } from '@mjcloud/utils';
import {
  IAccordionItem,
  IAccordionState,
  IAccordionDataSourceItem,
  IAccordionInitialStateParams,
  IAccordionLoadedParams,
} from './typings';

class AccordionReduce extends DataReduceBase<IAccordionState> {
  initialState(store: Store<IAccordionState>, params: IAccordionInitialStateParams) {
    let { initConfig, collapse, content, pageMode } = params,
      { displayMode, accordion, filters, titleFieldName } = initConfig;
    const state: IAccordionState = {
      filters,
      content,
      collapse,
      accordion,
      tabIndex: -1,
      dataSource: [],
      titleFieldName,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  loaded(store: Store<IAccordionState>, params: IAccordionLoadedParams) {
    const state: IAccordionState = { ...store.state, isFetching: false },
      { createControl } = params,
      { rows } = params.dataSource,
      { content, collapse, titleFieldName, filters } = state;
    let _filter: Filter, _content: IAccordionItem[], _collapse: IAccordionItem[];
    store.state.expandIndex = undefined;
    state.dataSource = rows.map<IAccordionDataSourceItem>(row => {
      const { [titleFieldName]: _title } = row;
      _filter = new Filter({
        type: FilterTypeEnum.or,
        conditions: filters.map(filter => ({
          ...filter,
          right: filter.right && row[filter.right],
        })),
      });
      _content = content
        .map(({ id }) => {
          const instance = createControl(id, _filter);
          if (instance) {
            return {
              controlId: instance.id,
              parentId: instance.parent.id,
              pageKey: instance.page.id,
            } as IAccordionItem;
          }
          return undefined;
        })
        .filter(item => !!item) as IAccordionItem[];
      _collapse = collapse
        .map(({ id }) => {
          const instance = createControl(id, _filter);
          if (instance) {
            return {
              controlId: instance.id,
              parentId: instance.parent.id,
              pageKey: instance.page.id,
            } as IAccordionItem;
          }
          return undefined;
        })
        .filter(item => !!item) as IAccordionItem[];
      return { ...row, _title, _content, _collapse, _expanded: false };
    });
    // if (state.dataSource.length > 0) {
    //   state.dataSource[0]._expanded = true;
    // }
    return state;
  }

  updateExpanded(store: Store<IAccordionState>, params: { expandIndex: number }) {
    // if (store.state.expandIndex === params.expandIndex) return store.state;
    const state: IAccordionState = { ...store.state },
      { dataSource } = state;
    state.dataSource = dataSource.map((row, i) => {
      if (i === params.expandIndex) {
        row._expanded = !row._expanded;
      } else {
        row._expanded = false;
      }
      return row;
    });
    return state;
  }
}

export default new AccordionReduce();
