import Page from '.';
import Draft from './draft';
import Service from '@mjcloud/service';
import PageHelper from '@mjcloud/page-helper';
import ComponentHelper from '@mjcloud/component';
import { ExceptionHelper } from '@mjcloud/exception';
import { ActionAfterEventArg, IConfigLoadErrorParams } from '@mjcloud/redux';
import {
  PageStatusEnum,
  IDictionary,
} from '@mjcloud/types';
import { IPageState, PageStoreActionType } from './typings';
import {
  IPageStatusUpdatedParams,
  IPageConfigStartLoadParams,
  IPageConfigLoadedParams,
  IPageInitialStateParams,
  IPageDecreaseControlInitCountParams,
  IPageDecreaseControlLoadCountParams,
} from './reduce';
// import Workflow from './workflow';

export default class PageExtendStore {
  public constructor(protected instance: Page) {}

  public async handleConfigStartLoadAfter(
    e: ActionAfterEventArg<IPageState, PageStoreActionType, IPageConfigStartLoadParams>,
  ) {
    e.newState.dispatch<IPageStatusUpdatedParams>('statusUpdated', {
      newStatus: PageStatusEnum.configLoading,
    });
    try {
      const pageConfig = await Service.fetchPageConfig(e.params.initConfig.address);
      if (pageConfig) {
        const TemplateLogic = await Service.fetchTemplateLogicPage(e.params.initConfig.address),
          template = TemplateLogic && new TemplateLogic(this.instance as any),
          { configParams: _configParams } = pageConfig,
          values: IDictionary = {};
        if (_configParams && _configParams.items) {
          for (const { name, value } of _configParams.items) {
            values[name] = value;
          }
        }
        const configParams = await PageHelper.getDynamicParamValues(
          this.instance as any,
          values,
          {},
        );
        if (template) this.instance.__setTemplate(template);
        const params: IPageConfigLoadedParams = {
          templateType: TemplateLogic ? TemplateLogic.templateType : '',
          initConfig: e.params.initConfig,
          pageKey: this.instance.id,
          config: pageConfig,
          configParams,
        };

        if (pageConfig.clientEntrance) {
          const { path, isPrompt, items } = pageConfig.clientEntrance;
          params.isPrompt = isPrompt;
          if (path) {
            try {
              const Logic = await Service.fetchLogicPage(path);
              const _logic = new Logic(this.instance as any);
              this.instance.__setLogic(_logic);
            } catch (error) {
              error.islogic = true;
              throw error;
            }
          }
          if (items) {
            this.instance['_componentsConfig'] = items;
          }
        }
        if (pageConfig.draft) {
          const draft = new Draft(this.instance, pageConfig.draft);
          this.instance['_draft'] = draft;
        }

        e.newState.dispatch<IPageConfigLoadedParams>('configLoaded', params);
      }
    } catch (error) {
      e.newState.dispatch<IConfigLoadErrorParams>('configLoadError', {
        errorMessage: !error.islogic ? '获取页面配置失败！' : '获取页面逻辑页失败！',
      });
      e.newState.dispatch<IPageStatusUpdatedParams>('statusUpdated', {
        newStatus: PageStatusEnum.configLoaded,
      });

      if (!error.islogic) {
        if (!error.message) {
          error.message = `获取页面 ${this.instance.id} 配置失败`;
        }
        ExceptionHelper.dispose(error);
      }
    }
  }

  public async handleConfigLoadedAfter(
    e: ActionAfterEventArg<IPageState, PageStoreActionType, IPageConfigLoadedParams>,
  ) {
    if (this.instance['_draft']) await this.instance['_draft'].loadDraft();
    if (this.instance['_workflow']) await this.instance['_workflow'].initWorkflow();
    e.newState.dispatch<Partial<IPageInitialStateParams>>('initialState', {
      config: e.params.config,
      initConfig: e.params.initConfig,
      pageMode: e.params.initConfig.address.pageMode,
    });
    e.newState.dispatch<IPageStatusUpdatedParams>('statusUpdated', {
      newStatus: PageStatusEnum.configLoaded,
    });
  }

  public handleDecreaseControlInitCountAfter(
    e: ActionAfterEventArg<IPageState, PageStoreActionType, IPageDecreaseControlInitCountParams>,
  ) {
    const state = e.newState.state;
    if (state.tempState.controlInitCount === 0 && state.status === PageStatusEnum.initing) {
      e.newState.dispatch<IPageStatusUpdatedParams>('statusUpdated', {
        newStatus: PageStatusEnum.inited,
      });
    }
  }

  public handleDecreaseControlLoadCountAfter(
    e: ActionAfterEventArg<IPageState, PageStoreActionType, IPageDecreaseControlLoadCountParams>,
  ) {
    const state = e.newState.state;
    if (state.tempState.controlLoadCount === 0 && state.status === PageStatusEnum.dataLoading) {
      e.newState.dispatch<IPageStatusUpdatedParams>('statusUpdated', {
        newStatus: PageStatusEnum.dataLoaded,
      });
    }
  }

  public async handleStatusUpdatedAfter(
    e: ActionAfterEventArg<IPageState, PageStoreActionType, IPageStatusUpdatedParams>,
  ) {
    const { newStatus } = e.params;
    const state = e.newState.state;
    const em = this.instance.eventManager;
    try {
      switch (newStatus) {
        case PageStatusEnum.configLoaded:
          if (em.getHandlerCount('configLoaded') > 0) em.trigger('configLoaded', state);
          e.newState.dispatch<IPageStatusUpdatedParams>('statusUpdated', {
            newStatus: PageStatusEnum.initing,
          });
          break;
        case PageStatusEnum.inited:
          if (em.getHandlerCount('inited') > 0) em.trigger('inited', state);
          if (this.instance['dataControlCount'] === 0) {
            e.newState.dispatch<IPageStatusUpdatedParams>('statusUpdated', {
              newStatus: PageStatusEnum.dataLoaded,
            });
          } else {
            e.newState.dispatch<IPageStatusUpdatedParams>('statusUpdated', {
              newStatus: PageStatusEnum.dataLoading,
            });
          }
          break;
        case PageStatusEnum.dataLoaded:
          if (em.getHandlerCount('dataLoaded') > 0) await em.trigger('dataLoaded', state);
          this.instance['_components'] = ComponentHelper.batchCreation(
            this.instance as any,
            this.instance['_componentsConfig'],
          );
          this.instance.dataSubmitFinish();
          e.newState.dispatch('statusUpdated', {
            newStatus: PageStatusEnum.done,
          });
          break;
        case PageStatusEnum.done:
          if (em.getHandlerCount('done') > 0) await em.trigger('done', state);
          break;
      }
    } catch (error) {
      ExceptionHelper.dispose(error);
    }
  }
}
