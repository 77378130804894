import React from 'react';
import Icon from '@mjcloud/icon';
import ControlBase from '../../base';
import globalData from '@mjcloud/global-data';
import { IButtonsState } from '../typings';
import { Button, Menu, Dropdown } from 'antd';

class ButtonsControl extends ControlBase<IButtonsState> {
  renderContent() {
    const { ControlFactory } = globalData;
    const { id, title, icon, disabled, ChildrenControls } = this.state;
    const overlay = (
      <Menu>
        {ChildrenControls.map((controlProps, i) => (
          <Menu.Item key={i}>
            <ControlFactory {...controlProps} />
          </Menu.Item>
        ))}
      </Menu>
    );
    return (
      <Dropdown overlay={overlay} placement="bottomCenter" disabled={disabled}>
        <Button key={id} id={this.props.id}>
          {icon && <Icon type={icon} />}
          {title}
        </Button>
      </Dropdown>
    );
  }
}
export default ButtonsControl;
