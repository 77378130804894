import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { ValueInstanceBase } from '@mjcloud/instance';
import { ValueExtendStoreBase } from '@mjcloud/extend-store';
import { ISwitchConfig, ISwitchState } from './typings';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';

class SwitchExtendStore extends ValueExtendStoreBase<number | string, Switch> {}

class Switch extends ValueInstanceBase<number | string, ISwitchState> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  __createStore() {
    return new Store<ISwitchState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new SwitchExtendStore(this),
    });
  }

  initialState(initConfig: ISwitchConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}

export default Switch;
