import globalData from '@mjcloud/global-data';

export interface ITabBarItem {
  pagePath?: string;
  icon: string;
  activeIcon: string;
  name: string;
  isAdd?: boolean;
}
export interface ITabBarInfo {
  textColor?: string;
  selectedColor?: string;
  backgroundColor?: string;
  isAdd?: boolean;
  items: ITabBarItem[];
}
interface IConfigItem {
  appName: string;
  backgroundColor: string;
  // keFuQRCode: string;
  tabBar?: ITabBarInfo;
}
interface IConfigInfo {
  [key: string]: IConfigItem | undefined;
}

const tabBar: (appId: string) => ITabBarInfo = appId => ({
  isAdd: true,
  textColor: '#797C80',
  selectedColor: '#4D96F2',
  backgroundColor: '#ffffff',
  items: [
    {
      pagePath: `/${appId}/MainMobile`,
      icon: 'icon-home',
      activeIcon: 'icon-home-fill',
      name: '首页',
    },
    {
      pagePath: `/${appId}/JobsMobile`,
      icon: 'icon-appstore',
      activeIcon: 'icon-appstore-fill',
      name: '工作',
    },
    {
      isAdd: true,
      icon: 'icon-plus-circle-fill',
      activeIcon: 'icon-plus-circle-fill',
      name: '添加',
    },
    {
      pagePath: `/${appId}/AnalysisMobile`,
      icon: 'icon-fund',
      activeIcon: 'icon-fund-fill',
      name: '分析',
    },
    {
      pagePath: `/${appId}/UserMobile`,
      icon: 'icon-user',
      activeIcon: 'icon-user-fill',
      name: '我的',
    },
  ],
});

const config: IConfigInfo = {
  manage: {
    appName: '慧建宝',
    backgroundColor: '',
  },
  zhzj: {
    appName: '慧建通',
    backgroundColor: '',
    tabBar: tabBar('zhzj'),
  },
  zhzjzy: {
    appName: '慧建宝',
    backgroundColor: '',
    tabBar: tabBar('zhzjzy'),
  },
  decision: {
    appName: '慧决策',
    backgroundColor: '',
  },
  decisionalliance: {
    appName: '联营决策',
    backgroundColor: '',
  },
  wCertificate: {
    appName: '慧证通',
    backgroundColor: '',
    tabBar: tabBar('wCertificate'),
  },
  expenses: {
    appName: '费控易',
    backgroundColor: '',
    tabBar: tabBar('expenses'),
  },
  financial: {
    appName: '财务接口',
    backgroundColor: '',
  },
  taskmanage: {
    appName: '部门事务管理',
    backgroundColor: '',
  },
  trustworthyproject: {
    appName: '守重协会',
    backgroundColor: '',
  },
  trustworthyprojectcorp: {
    appName: (globalData.rbCore?.config as any)?.appName || '守重协会',
    backgroundColor: '',
  },
};

function getConfig(appId: string): IConfigItem {
  const _config = config[appId];
  if (_config) return _config;
  return {
    appName: '明建云',
    backgroundColor: '',
  };
}
export default getConfig;
