import { ISwitchConfig } from './typings';

const config: ISwitchConfig = {
  title: '',
  autoFocus: false,
  trueValue: 1,
  falseValue: 0,
  trueText: undefined,
  falseText: undefined,
  modifyMode: undefined,
  displayMode: undefined,
  disabledMode: undefined,
};

export default config;
