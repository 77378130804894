import Reduce from './reduce';
import Store from '@mjcloud/redux';
import SelectExtendStore from './extendStore';
import { DataModeEnum } from '@mjcloud/types';
import SelectInstanceBase from '../common/select';
import {
  ISelectConfig,
  ISelectState,
  ISelectConfigStartLoadParams,
  SelectEventType,
  SelectActionType,
} from './typings';

class Select extends SelectInstanceBase<string, ISelectState, SelectEventType, SelectActionType> {
  __createStore() {
    return new Store<ISelectState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new SelectExtendStore(this),
    });
  }

  getDataMode(): DataModeEnum {
    const { pagination } = this.store.state;
    if (pagination) {
      return DataModeEnum.pagination;
    }
    return DataModeEnum.all;
  }

  initialState(initConfig: ISelectConfig) {
    this.store.dispatch<ISelectConfigStartLoadParams>('configStartLoad', {
      initConfig,
      address: this.page.address,
    });
  }

  setAddRowBtn2Display(display: boolean) {
    this.store.dispatch('setAddRowBtn2Display', { display });
  }

  choose2Index(index: number) {
    const { originalDataSource } = this.store.state,
      chooseRow = originalDataSource[index];
    if (chooseRow) {
      const { _value: value, _text: text } = chooseRow;
      this.store.dispatch('updateValue', { value, text, actionSourceSign: this.store });
    }
  }

  multChoose2Index(indexs: number[]) {
    let values: string[] = [],
      texts: string[] = [];
    const { textTokenSeparator, vaueleTokenSeparator, originalDataSource } = this.store.state;
    indexs.forEach(index => {
      const chooseRow = originalDataSource[index];
      if (chooseRow) {
        values.push(chooseRow._value);
        texts.push(chooseRow._text);
      }
    });
    this.store.dispatch('updateValue', {
      value: values.join(vaueleTokenSeparator),
      text: texts.join(textTokenSeparator),
      actionSourceSign: this.store,
    });
  }
}

export default Select;
