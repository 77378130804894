import Reduce from './reduce';
import Store from '@mjcloud/redux';
import Service from '@mjcloud/service';
import JsApiHelper from '@mjcloud/jsapi';
import globalData from '@mjcloud/global-data';
import { InstanceBase } from '@mjcloud/instance';
import { ExceptionHelper } from '@mjcloud/exception';
import FileManagerExtendStore from './extendStore';
import { mapFileType, mapFileType2Icon } from './tool';
import { IFileManagerState, IUploadFile } from './typings';

export default class FileManager extends InstanceBase<IFileManagerState> {
  __createStore() {
    return new Store<IFileManagerState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new FileManagerExtendStore(this),
    });
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  /**
   * 获取当前文件列表Id集合
   */
  getData(isValid: boolean = true) {
    const { fileList, loading } = this.store.state;
    if (isValid && loading) {
      throw ExceptionHelper.businessException('还有文件正在上传中，请稍后保存！');
    }
    let _fileList = fileList
      .map(file => {
        if (file.status === 'done') {
          return { fileId: file.uid, groupId: 0 };
        }
        return undefined;
      })
      .filter(file => !!file) as {
      fileId: string;
      groupId: number;
    }[];
    return _fileList;
  }

  /**
   * 获取当前文件列表集合
   */
  getFileList() {
    const { fileList } = this.store.state;
    return fileList;
  }

  /**
   * 选择多个文件
   * @param {number} count 最大可选照片数，默认1张
   */
  async chooseFile(count) {
    try {
      const { maxFileCount, listType, fileList } = this.store.state;
      let _count = 0;
      if (count == null) {
        if (listType === 'avatar') {
          _count = 1;
        } else if (maxFileCount <= 0) {
          _count = 9;
        } else {
          _count = maxFileCount;
          _count -= fileList.length;
        }
      } else {
        _count = count;
      }
      const { filePaths = [] } = await JsApiHelper.chooseImage({
        count: _count,
      });
      return filePaths;
    } catch (ex) {
      if (ex && ex.error === 11) {
        return null;
      } else {
        throw ex;
      }
    }
  }

  /**
   * 上传多个文件
   * @param {Array} filePaths 要上传的本地资源占位符集合
   * @param {boolean} isshowLoading 是否静默上传，默认否
   */
  async uploadFile(filePaths: string[], isshowLoading = true) {
    try {
      if (filePaths && filePaths.length > 0) {
        if (isshowLoading) JsApiHelper.showLoading({ title: '上传中...' });
        const fileList: Array<IUploadFile> = [],
          { uploadProps } = this.store.state,
          { action: url, headers: header } = uploadProps;
        if (typeof url === 'string') {
          for (const filePath of filePaths) {
            const res = await JsApiHelper.uploadFile({
              url,
              header,
              filePath,
              fileType: 'image',
              fileName: 'file',
            });
            if (res.data && typeof res.data == 'string') {
              try {
                const data = JSON.parse(res.data);
                const { url, id, type, size, name } = data.data;
                if (url) {
                  let _url = Service.getUrl(url);
                  fileList.push({
                    id,
                    name,
                    size,
                    uid: id,
                    url: _url,
                    status: 'done',
                    thumbUrl: _url,
                    type: mapFileType(type),
                    ...mapFileType2Icon(type),
                  });
                }
              } catch (error) {
                console.error(error);
              }
            }
          }
        }
        JsApiHelper.hideLoading();
        this.store.dispatch('updateFileList', {
          fileList,
        });
        return fileList;
      }
      return [];
    } catch (error) {
      console.error(error);
      JsApiHelper.hideLoading();
      ExceptionHelper.dispose(error);
      return [];
    }
  }

  /**
   * 上传多个文件,自定义上传url版本
   * @param {string} url lg: "/file/upload"
   * @param {Array} filePaths filePaths 要上传的本地资源占位符集合
   */
  async uploadFile2customizeUrl(url: string, filePaths: string[]) {
    if (filePaths && filePaths.length > 0) {
      let result = {},
        { apiHost, version } = globalData.rbCore.config,
        _url = `${Service['getApiUrl'](apiHost, version)}${url}`,
        header = await Service['getHeaders']();
      for (const filePath of filePaths) {
        const res = await JsApiHelper.uploadFile({
          url: _url,
          header,
          filePath,
          fileType: 'image',
          fileName: 'file',
        });
        if (res.data && typeof res.data == 'string') {
          try {
            result = JSON.parse(res.data);
          } catch (error) {
            console.error(error);
          }
        }
      }
      return result;
    }
    return { _msg: 'is null' };
  }

  getBusinessType() {
    const { businessType } = this.store.state;
    return businessType;
  }
}
