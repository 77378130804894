import { IChartConfig } from './typings';

const config: IChartConfig = {
  nameFieldName: 'name',
  xAxisFieldName: 'x',
  yAxisFieldName: 'y',
  series: {},
  size: 750,
};

export default config;
