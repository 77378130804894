import Icon from '@mjcloud/icon';
import styled from 'styled-components';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { ITabBarInfo, ITabBarItem } from '../../../../config';
import { IRouteComponentProps } from '@mjcloud/router-helper';

interface ITabBarProps extends ITabBarInfo, IRouteComponentProps {
  selected: ITabBarItem;
  onAddClick?(): void;
}

const TabAddIcon = styled.div`
  width: 40px;
  height: 40px;
  font-size: 40px;
`;

const TabIcon = styled.div`
  width: 22px;
  height: 22px;
  font-size: 22px;
`;

const TabBar: React.FC<ITabBarProps> = ({
  history,
  selected,
  items,
  textColor,
  selectedColor,
  backgroundColor,
  onAddClick = function() {},
}) => {
  const [selectedKey, setSelectedKey] = useState(selected.name);
  function tabClick(item: ITabBarItem) {
    if (item.isAdd) {
      onAddClick();
      return;
    }

    setSelectedKey(item.name);
    if (item.pagePath) history.replace(item.pagePath);
  }
  return (
    <div className="am-tab-bar-bar" style={{ backgroundColor }}>
      {items.map(item => {
        const { name, icon, activeIcon, isAdd } = item,
          isSelected = selectedKey === name;
        if (isAdd)
          return (
            <div className="am-tab-bar-tab" onClick={() => tabClick(item)}>
              <TabAddIcon className="am-tab-bar-tab-icon" style={{ color: selectedColor }}>
                <Icon type={icon} />
              </TabAddIcon>
            </div>
          );
        return (
          <div
            className="am-tab-bar-tab"
            style={{ color: isSelected ? selectedColor : textColor }}
            onClick={() => tabClick(item)}
          >
            <TabIcon className="am-tab-bar-tab-icon">
              <Icon type={isSelected ? activeIcon : icon} />
            </TabIcon>
            <p className="am-tab-bar-tab-title">{name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default withRouter(TabBar);
