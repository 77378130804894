import GridView from '.';
import { IDictionary } from '@mjcloud/types';
import PageHelper from '@mjcloud/page-helper';
import { IGridViewState } from './typings';
import ControllerHelper from '@mjcloud/controller';
import { ActionAfterEventArg } from '@mjcloud/redux';
import { ContainerDataExtendStoreBase } from '@mjcloud/extend-store';

interface IGridViewHandleClickParams {
  afterClick: string;
  beforeClick: string;
  params: IDictionary;
  controllerId: string | undefined;
}

export default class GridViewExtendStore extends ContainerDataExtendStoreBase<GridView> {
  protected isNeedNotificationPage = true;

  private async _handleClick(param: IGridViewHandleClickParams) {
    const { params, controllerId, afterClick, beforeClick } = param;
    let before = async (data: IDictionary) => {
        if (this.instance.eventManager.getHandlerCount(afterClick) > 0) {
          await this.instance.eventManager.trigger(afterClick, data);
        }
        return data;
      },
      after = async (data: IDictionary) => {
        if (this.instance.eventManager.getHandlerCount(beforeClick) > 0) {
          await this.instance.eventManager.trigger(beforeClick, data);
        }
        return data;
      };
    if (controllerId) {
      await ControllerHelper.execute({
        sender: this.instance,
        controllerId,
        data: params,
        before,
        after,
      });
    } else {
      await before(params);
      await after(params);
    }
  }

  handleLoadedBefore(e) {
    e.params.handleColorCommand = (colorCommand, row) => {
      if (colorCommand) {
        const e = {
            sender: this.instance,
            eventType: 'colorCommand',
            data: { row },
          },
          fn = PageHelper.createPageScriptFunction(this.instance.page, colorCommand, true);
        return fn(e);
      } else {
        return undefined;
      }
    };
  }

  async handleRowClickAfterAfter(e: ActionAfterEventArg<IGridViewState, string, any>) {
    const { rowClickController } = e.newState.state,
      { row } = e.params;
    await this._handleClick({
      params: { row },
      afterClick: 'rowAfterClick',
      beforeClick: 'rowBeforeClick',
      controllerId: rowClickController,
    });
    setTimeout(() => e.newState.dispatch('cancelRowHighlight', { row }), 200);
  }

  async handleMoreClickAfterAfter(e: ActionAfterEventArg<IGridViewState, string, any>) {
    const { table } = e.newState.state;
    if (table) {
      await this._handleClick({
        params: {},
        afterClick: 'moreAfterClick',
        beforeClick: 'moreBeforeClick',
        controllerId: table.moreClickController,
      });
    }
  }
}
