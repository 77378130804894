import Store from '@mjcloud/redux';
import Reduce from './reduce';
import { DataModeEnum } from '@mjcloud/types';
import CalendarExtendStore from './extendStore';
import { DataInstanceBase } from '@mjcloud/instance';
import { ExceptionHelper } from '@mjcloud/exception';
import { IDataSourceParameters } from '@mjcloud/data-source-helper';
import { DateHelper, Filter, FilterTypeEnum, OperationTypeEnum } from '@mjcloud/utils';

export default class Calendar extends DataInstanceBase<any> {
  __createStore() {
    return new Store({
      id: this.id,
      reduce: Reduce,
      extendStore: new CalendarExtendStore(this),
    });
  }

  getDataMode() {
    return DataModeEnum.all;
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  get value() {
    return this.store.state.value;
  }

  set value(date) {
    if (date) {
      this.store.dispatch('updateDay', { date });
    } else {
      throw ExceptionHelper.argumentException('value', 'value值不能为null、undefined');
    }
  }

  refresh(params: IDataSourceParameters = {}) {
    const { date } = this.store.state,
      filter = new Filter(FilterTypeEnum.and),
      // TODO 时间控件针对获取下个月处理机制的临时方案
      nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 2),
      startDate: any = DateHelper.parse(DateHelper.format(date, 'YYYY-MM')),
      endDate: any = DateHelper.parse(DateHelper.format(nextMonth, 'YYYY-MM'));
    filter.addCondition({
      left: 'startDate',
      right: startDate,
      op: OperationTypeEnum.lessEq,
    });
    filter.addCondition({
      left: 'endDate',
      right: endDate,
      op: OperationTypeEnum.greater,
    });

    if (!params.filter) {
      params.filter = filter;
    } else {
      const newFilter = new Filter(FilterTypeEnum.or);
      newFilter.addCondition(params.filter);
      newFilter.addCondition(filter);
      params.filter = newFilter;
    }

    this.store.dispatch('startLoad', params);
  }

  getCurrentSelectedMonth() {
    const { date } = this.store.state;
    return DateHelper.parse(DateHelper.format(date, 'YYYY-MM'));
  }
}
