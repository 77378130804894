import Page from '@mjcloud/page';
import { ExceptionHelper, Exception } from '@mjcloud/exception';
import { IDictionary, PageModeEnum } from '@mjcloud/types';
import { EventListening, ExpressionHelper, ObjectHelper } from '@mjcloud/utils';

const c_pageParamPrefix = '{page:';
const c_scriptParamPrefix = '{script:';

export interface IPageAddress {
  appId: string;
  moduleId: string;
  pageId?: string;
  // pageMode?: PageModeEnum;
}

interface IMemuAddress {
  appId: string;
  pageId?: string;
  moduleId: string;
  pageMode?: PageModeEnum;
  params?: IDictionary;
}

type ScriptFunction = (e: any) => any;

let emptyFn: ScriptFunction = function() {};

export default class PageHelper {
  private static _modulePaths: IDictionary<IDictionary<string>> = {};

  private static findMenuPath(appId: string, moduleId: string) {
    let path: string = '';
    if (this._modulePaths[appId]) {
      path = this._modulePaths[appId][moduleId];
    }
    if (!path) {
      path = `/${appId}/${moduleId}/`;
    }
    return path;
  }

  private static formatPath(info: IPageAddress, defaultPageId: string = 'Main') {
    const { appId, moduleId, pageId } = info;
    return `${appId}/${moduleId}/${pageId || defaultPageId}`;
  }

  static formatTemplatePath(info: IPageAddress) {
    return `/template/${this.formatPath(info)}`;
  }

  static formatPagePath(info: IPageAddress) {
    return `/page/${this.formatPath(info, '')}`;
  }

  static formatLogicPath(info: IPageAddress) {
    return `/logic/${this.formatPath(info)}`;
  }

  static formatMemuPath(info: IMemuAddress) {
    const { appId, moduleId, pageId, pageMode, params } = info;
    let path = this.findMenuPath(appId, moduleId),
      search: IDictionary | undefined;
    if (pageId && pageId != 'Main') {
      search = { pageId };
    }
    if (pageMode) {
      search = { ...search, pageMode };
    }
    if (params) {
      search = { ...params, ...search };
    }
    if (search) {
      path += ObjectHelper.params2search(search);
    }
    return path;
  }

  static pushMenuPath(appId: string, moduleId: string, path: string) {
    if (this._modulePaths[appId]) {
      this._modulePaths[appId][moduleId] = path;
    } else {
      this._modulePaths[appId] = { [moduleId]: path };
    }
  }

  static getMenuList(appId: string) {
    if (this._modulePaths[appId]) {
      return Object.getOwnPropertyNames(this._modulePaths[appId]);
    }
    return [];
  }

  static addressIsEqual(a: IPageAddress, b: IPageAddress) {
    const { pageId: a_pageId = 'Main' } = a,
      { pageId: b_pageId = 'Main' } = b;
    return a.appId === b.appId && a.moduleId === b.moduleId && a_pageId === b_pageId;
  }

  /**
   * 创建当前页面脚本函数
   */
  static createPageScriptFunction(
    page: Page,
    st: string,
    packReturnValue: boolean = false,
  ): ScriptFunction {
    if (!st) {
      return emptyFn;
    } else {
      st = st.trim();
      if (st.charAt(0) === '{') {
        return e => {
          return this.getDynamicParamValue(page, st, e, true);
        };
      } else {
        return e => {
          try {
            const expression = ExpressionHelper.create(st, 'data', {
              e,
              page: page,
              logic: page.logic,
              template: page.template,
            });
            const returnValue = expression.exec({});
            if (packReturnValue) {
              return returnValue;
            }
          } catch (error) {
            if (!error.isBusiness) {
              error.message = `动态函数 ${st} 执行失败: ${error.message}, 位于：${this.formatPath(
                page.address,
              )}`;
            }
            throw error;
          }
        };
      }
    }
  }

  /**
   * 创建当前页面的事件回调函数
   */
  static createEventCallback(page: Page, st: string): ScriptFunction {
    return this.createPageScriptFunction(page, st);
  }

  /**
   * 判断当前值是否未动态脚本
   * @param value
   */
  static valueIsDynamic(value: string | undefined) {
    if (typeof value === 'string') {
      if (value.search(c_pageParamPrefix) === 0 || value.search(c_scriptParamPrefix) === 0) {
        return true;
      }
    }
    return false;
  }

  /**
   * 获取动态参数值的实际值
   * @param value 动态参数值
   * @param e 附件参数
   * @param isScriptFunction 是否通过createPageScriptFunction方法调用
   */
  private static getDynamicParamValue(
    page: Page,
    value: any,
    data: any = {},
    isScriptFunction = false,
  ) {
    if (typeof value === 'number' || typeof value === 'boolean' || typeof value === 'object') {
      return value;
    } else if (typeof value === 'string') {
      var length: number;
      switch (value.charAt(1)) {
        case 'p': // page
          length = c_pageParamPrefix.length;
          if (value.length > length && value.substr(0, length) === c_pageParamPrefix) {
            const key = value.substring(length, value.length - 1).trim();
            value = page.getParam(key);
            if (value == null) {
              return null;
            }
          }
          break;
        case 's': // script
          length = c_scriptParamPrefix.length;
          if (value.length > length && value.substr(0, length) === c_scriptParamPrefix) {
            var fn = this.createPageScriptFunction(
              page,
              value.substring(length, value.length - 1),
              true,
            );
            if (isScriptFunction) {
              return fn(data);
            } else {
              const e = EventListening.getHandlerArg(page, data);
              return fn(e);
            }
          }
          break;
      }
    } else {
      throw ExceptionHelper.argumentException(
        'value',
        'getDynamicParamValue的value只能为string类型',
      );
    }
    return value;
  }
  /**
   * 获取动态参数值的实际值
   * @param value 动态参数值
   * @param e 附件参数
   */
  static async getDynamicParamValues<T extends IDictionary = IDictionary>(
    page: Page,
    values: IDictionary<string>,
    data: IDictionary,
    nullValue: null | string = null,
  ): Promise<T> {
    const newValues: IDictionary = {};
    if (values) {
      for (const paramName in values) {
        try {
          newValues[paramName] = await this.getDynamicParamValue(page, values[paramName], data);
          if (newValues[paramName] == null) newValues[paramName] = nullValue;
        } catch (error) {
          newValues[paramName] = nullValue;
          const message = `动态参数值 ${values[paramName]} 获取失败`;
          if (!error.message) {
            error.message = message;
          }
          // TODO: 为什么要过滤掉 passException
          // if (error.name !== 'passException') {
          //   ExceptionHelper.dispose(error);
          //   error.isCaptured = true;
          //   throw error;
          // }

          ExceptionHelper.dispose(error);
          (error as Exception).isCaptured = true;
          throw error;
        }
      }
    }
    return newValues as T;
  }
}
