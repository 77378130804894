import Store from '@mjcloud/redux';
import { IDictionary } from '@mjcloud/types';
import SelectReduceBase from './reduce';
import DataSource, { ITreeDataSourceItem } from '@mjcloud/data-source-helper';
import { ISelectBaseState, ISelectBaseLoadedParams } from './typings';

interface IFormatDataParams {
  source: ITreeDataSourceItem[];
  disabledFn: (row: IDictionary) => boolean;
}

function formatData(params: IFormatDataParams) {
  const { source, disabledFn } = params;
  for (const row of source) {
    row._disabled = disabledFn(row);
    if (row._children && row._children.length > 0) {
      formatData({ source: row._children, disabledFn });
    }
  }
}

export default abstract class SelectTreeReduceBase<TValue = string> extends SelectReduceBase<
  TValue
> {
  loaded(store: Store<ISelectBaseState<TValue>>, params: ISelectBaseLoadedParams) {
    let { value, expandLevel, rowIdCount, vaueleTokenSeparator } = store.state as any;
    const h: any = {},
      { disabledFn } = params,
      { aggs, rows = [] } = params.dataSource,
      originalDataSource = DataSource.formatTreeDataSource(
        store.state.config.data,
        rows,
        row => false,
        row => {
          let _isExist = false;
          if (h[row._value]) _isExist = true;
          h[row._value] = true;
          return { _isExist, _rid: ++rowIdCount };
        },
      ),
      { dataSource, treeDefaultExpandedKeys: expandedKeys } = DataSource.formatTreeData(
        originalDataSource,
        expandLevel,
        '_rid',
      );
    if (disabledFn) formatData({ source: dataSource, disabledFn });
    const { texts, selectedRows } = this.setSelectedRows(
      dataSource as any,
      vaueleTokenSeparator,
      value as any,
    );

    let state: ISelectBaseState<TValue> = {
      ...store.state,
      rowIdCount,
      dataSource,
      selectedRows,
      isFetching: false,
      originalDataSource,
      originalData: rows,
      expandedKeys: expandedKeys.map(k => k.toString()),
    } as any;
    return state;
  }
}
