export default {
  version: '3.3.5',
  scales: {},
  widthRatio: { column: 0.5, rose: 0.999999, multiplePie: 0.75 },
  lineDash: [4, 4],
  fontFamily:
    '"Helvetica Neue", "San Francisco", Helvetica, Tahoma, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", sans-serif',
  defaultColor: '#1890FF',
  pixelRatio: 1,
  padding: 'auto',
  appendPadding: 15,
  colors: ['#1890FF', '#2FC25B', '#FACC14', '#223273', '#8543E0', '#13C2C2', '#3436C7', '#F04864'],
  shapes: { line: ['line', 'dash'], point: ['circle', 'hollowCircle'] },
  sizes: [4, 10],
  axis: {
    common: {
      label: { fill: '#808080', fontSize: 10 },
      line: { stroke: '#E8E8E8', lineWidth: 1 },
      grid: {
        type: 'line',
        stroke: '#E8E8E8',
        lineWidth: 1,
        lineDash: [2],
      },
      tickLine: null,
      labelOffset: 7.5,
    },
    bottom: {
      label: { fill: '#808080', fontSize: 10 },
      line: { stroke: '#E8E8E8', lineWidth: 1 },
      grid: null,
      tickLine: null,
      labelOffset: 7.5,
    },
    left: {
      label: { fill: '#808080', fontSize: 10 },
      line: null,
      grid: {
        type: 'line',
        stroke: '#E8E8E8',
        lineWidth: 1,
        lineDash: [2],
      },
      tickLine: null,
      labelOffset: 7.5,
    },
    right: {
      label: { fill: '#808080', fontSize: 10 },
      line: null,
      grid: {
        type: 'line',
        stroke: '#E8E8E8',
        lineWidth: 1,
        lineDash: [2],
      },
      tickLine: null,
      labelOffset: 7.5,
    },
    circle: {
      label: { fill: '#808080', fontSize: 10 },
      line: null,
      grid: {
        type: 'line',
        stroke: '#E8E8E8',
        lineWidth: 1,
        lineDash: [2],
      },
      tickLine: null,
      labelOffset: 7.5,
    },
    radius: {
      label: { fill: '#808080', fontSize: 10 },
      line: { stroke: '#E8E8E8', lineWidth: 1 },
      grid: {
        type: 'line',
        stroke: '#E8E8E8',
        lineWidth: 1,
        lineDash: [2],
      },
      tickLine: null,
      labelOffset: 4,
    },
  },
  shape: {
    line: { lineWidth: 2, lineJoin: 'round', lineCap: 'round' },
    point: { lineWidth: 0, size: 3 },
    area: { fillOpacity: 0.1 },
  },
  _defaultAxis: {
    label: { fill: '#808080', fontSize: 10 },
    line: { stroke: '#E8E8E8', lineWidth: 1 },
    grid: {
      type: 'line',
      stroke: '#E8E8E8',
      lineWidth: 1,
      lineDash: [2],
    },
    tickLine: null,
    labelOffset: 7.5,
  },
  tooltip: {
    triggerOn: ['touchstart', 'touchmove'],
    alwaysShow: false,
    showTitle: false,
    showCrosshairs: false,
    crosshairsStyle: { stroke: 'rgba(0, 0, 0, 0.25)', lineWidth: 1 },
    showTooltipMarker: true,
    background: {
      radius: 1,
      fill: 'rgba(0, 0, 0, 0.65)',
      padding: [3, 5],
    },
    titleStyle: {
      fontSize: 12,
      fill: '#fff',
      textAlign: 'start',
      textBaseline: 'top',
    },
    nameStyle: {
      fontSize: 12,
      fill: 'rgba(255, 255, 255, 0.65)',
      textAlign: 'start',
      textBaseline: 'middle',
    },
    valueStyle: {
      fontSize: 12,
      fill: '#fff',
      textAlign: 'start',
      textBaseline: 'middle',
    },
    showItemMarker: true,
    itemMarkerStyle: {
      radius: 3,
      symbol: 'circle',
      lineWidth: 1,
      stroke: '#fff',
    },
    layout: 'horizontal',
    snap: false,
  },
  guide: {
    line: { style: { stroke: '#a3a3a3', lineWidth: 1 }, top: true },
    text: {
      style: {
        fill: '#787878',
        textAlign: 'center',
        textBaseline: 'middle',
      },
      offsetX: 0,
      offsetY: 0,
      top: true,
    },
    rect: { style: { fill: '#fafafa' }, top: false },
    arc: { style: { stroke: '#a3a3a3' }, top: true },
    html: {
      offsetX: 0,
      offsetY: 0,
      alignX: 'center',
      alignY: 'middle',
    },
    tag: {
      top: true,
      offsetX: 0,
      offsetY: 0,
      side: 4,
      background: { padding: 5, radius: 2, fill: '#1890FF' },
      textStyle: {
        fontSize: 12,
        fill: '#fff',
        textAlign: 'center',
        textBaseline: 'middle',
      },
    },
    point: {
      top: true,
      offsetX: 0,
      offsetY: 0,
      style: { fill: '#fff', r: 3, lineWidth: 2, stroke: '#1890ff' },
    },
  },
  legend: {
    common: {
      itemMarginBottom: 12,
      itemGap: 10,
      showTitle: false,
      titleStyle: {
        fontSize: 12,
        fill: '#808080',
        textAlign: 'start',
        textBaseline: 'top',
      },
      nameStyle: {
        fill: '#808080',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
      },
      valueStyle: {
        fill: '#000000',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
      },
      unCheckStyle: { fill: '#bfbfbf' },
      itemWidth: 'auto',
      wordSpace: 6,
      selectedMode: 'multiple',
    },
    right: {
      position: 'right',
      layout: 'vertical',
      itemMarginBottom: 12,
      itemGap: 10,
      showTitle: false,
      titleStyle: {
        fontSize: 12,
        fill: '#808080',
        textAlign: 'start',
        textBaseline: 'top',
      },
      nameStyle: {
        fill: '#808080',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
      },
      valueStyle: {
        fill: '#000000',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
      },
      unCheckStyle: { fill: '#bfbfbf' },
      itemWidth: 'auto',
      wordSpace: 6,
      selectedMode: 'multiple',
    },
    left: {
      position: 'left',
      layout: 'vertical',
      itemMarginBottom: 12,
      itemGap: 10,
      showTitle: false,
      titleStyle: {
        fontSize: 12,
        fill: '#808080',
        textAlign: 'start',
        textBaseline: 'top',
      },
      nameStyle: {
        fill: '#808080',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
      },
      valueStyle: {
        fill: '#000000',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
      },
      unCheckStyle: { fill: '#bfbfbf' },
      itemWidth: 'auto',
      wordSpace: 6,
      selectedMode: 'multiple',
    },
    top: {
      position: 'top',
      layout: 'horizontal',
      itemMarginBottom: 12,
      itemGap: 10,
      showTitle: false,
      titleStyle: {
        fontSize: 12,
        fill: '#808080',
        textAlign: 'start',
        textBaseline: 'top',
      },
      nameStyle: {
        fill: '#808080',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
      },
      valueStyle: {
        fill: '#000000',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
      },
      unCheckStyle: { fill: '#bfbfbf' },
      itemWidth: 'auto',
      wordSpace: 6,
      selectedMode: 'multiple',
    },
    bottom: {
      position: 'bottom',
      layout: 'horizontal',
      itemMarginBottom: 12,
      itemGap: 10,
      showTitle: false,
      titleStyle: {
        fontSize: 12,
        fill: '#808080',
        textAlign: 'start',
        textBaseline: 'top',
      },
      nameStyle: {
        fill: '#808080',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
      },
      valueStyle: {
        fill: '#000000',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
      },
      unCheckStyle: { fill: '#bfbfbf' },
      itemWidth: 'auto',
      wordSpace: 6,
      selectedMode: 'multiple',
    },
  },
};
