import React from 'react';
import ReactDOM from 'react-dom';
import Service from '@mjcloud/service';
import JsApiHelper from '@mjcloud/jsapi';
import DownloadModal from '../components/DownloadModal';

export default async function(fileId: string, title = '下载文件', okText = '下载') {
  const file = await Service.getFileDetail(fileId);
  if (file) {
    const div = document.createElement('div');
    document.body.appendChild(div);
    function destroy() {
      const unmountResult = ReactDOM.unmountComponentAtNode(div);
      if (unmountResult && div.parentNode) {
        div.parentNode.removeChild(div);
      }
    }
    ReactDOM.render(
      <DownloadModal file={file} title={title} okText={okText} destroy={destroy} />,
      div,
    );
  } else {
    JsApiHelper.showToast({ content: '文件不存在', type: 'exception' });
  }
}
