import { ISimpleSearchConfig } from './typings';

const config: ISimpleSearchConfig = {
  items: [],
  tip: undefined,
  styleName: 'normal',
  displayMode: undefined,
};

export default config;
