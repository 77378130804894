import { ReduceBase } from '@mjcloud/reduce';
import { ConsoleHelper } from '@mjcloud/utils';
import { PageInitConfig, IPageState } from './typings';
import { IDictionary, PageStatusEnum, PageConfig } from '@mjcloud/types';
import Store, { IConfigStartLoadParams, IConfigLoadedParams, IInitialStateParams } from '@mjcloud/redux';

export interface IPageConfigStartLoadParams extends IConfigStartLoadParams<PageInitConfig> {}

export interface IPageConfigLoadedParams extends IConfigLoadedParams<PageInitConfig> {
  isPrompt?: boolean;
  config: PageConfig;
  pageKey: string;
  templateType: string;
  configParams: IDictionary;
}

export interface IPageInitialStateParams extends IInitialStateParams<PageInitConfig> {
  config: PageConfig;
}
export interface IPageRegisterControlParams {
  controlId: string;
  controlStore?: Store;
}

export interface IPageIncreaseControlInitCountParams {
  controlId: string;
}

export interface IPageDecreaseControlInitCountParams {
  controlId: string;
}

export interface IPageIncreaseControlLoadCountParams {
  controlId: string;
}

export interface IPageDecreaseControlLoadCountParams {
  controlId: string;
}

export interface IPageStatusUpdatedParams {
  newStatus: PageStatusEnum;
}

function pageStatusEnum2string(status: PageStatusEnum): string | undefined {
  switch (status) {
    case PageStatusEnum.configLoading:
      return 'configLoading';
    case PageStatusEnum.configLoaded:
      return 'configLoaded';
    case PageStatusEnum.initing:
      return 'initing';
    case PageStatusEnum.inited:
      return 'inited';
    case PageStatusEnum.dataLoading:
      return 'dataLoading';
    case PageStatusEnum.dataLoaded:
      return 'dataLoaded';
    case PageStatusEnum.done:
      return 'done';
    default:
      break;
  }
}

export class PageReduce extends ReduceBase<IPageState> {
  configLoaded(store: Store<IPageState>, params: IPageConfigLoadedParams) {
    let state = store.state;
    state.controls = {};
    state.isPrompt = !!params.isPrompt;
    state.configIsFetching = false;
    state.info = params.initConfig;
    state.config = params.config;
    state.pageKey = params.pageKey;
    state.configParams = params.configParams;
    state.templateType = params.templateType;
    state.tempState = { controlInitCount: 0, controlLoadCount: 0 };
    return state;
  }

  initialState(store: Store<IPageState>, param: IPageInitialStateParams) {
    const { config, initConfig } = param,
      { address, params } = initConfig;
    const state: IPageState = {
      config,
      tabIndex: 0,
      display: true,
      modalList: [],
      controlCount: 0,
      isDataUpdated: false,
      configIsFetching: false,
      info: { address, params },
      status: store.state.status,
      pageKey: store.state.pageKey,
      isPrompt: store.state.isPrompt,
      templateType: store.state.templateType,
      configParams: store.state.configParams,
      tempState: store.state.tempState,
      controls: store.state.controls,
    };
    return state;
  }

  registerControl(store: Store<IPageState>, params: IPageRegisterControlParams) {
    let state = store.state;
    state.controlCount++;
    if (params.controlStore) {
      state.controls[params.controlId] = params.controlStore;
    }
    return state;
  }

  increaseControlInitCount(store: Store<IPageState>, params: IPageIncreaseControlInitCountParams) {
    let state = store.state;
    if (state.status <= PageStatusEnum.initing) {
      state.tempState.controlInitCount++;
      ConsoleHelper.log2reduce(
        store['_id'],
        'increaseControlInitCount',
        pageStatusEnum2string(state.status),
        params.controlId,
        state.tempState.controlInitCount,
      );
    }
    return state;
  }

  decreaseControlInitCount(store: Store<IPageState>, params: IPageDecreaseControlInitCountParams) {
    let state = store.state;
    if (state.status <= PageStatusEnum.initing) {
      state.tempState.controlInitCount--;
      ConsoleHelper.log2reduce(
        store['_id'],
        'decreaseControlInitCount',
        pageStatusEnum2string(state.status),
        params.controlId,
        state.tempState.controlInitCount,
      );
    }
    return state;
  }

  increaseControlLoadCount(store: Store<IPageState>, params: IPageIncreaseControlLoadCountParams) {
    let state = store.state;
    if (state.status === PageStatusEnum.dataLoading) {
      state.tempState.controlLoadCount++;
      ConsoleHelper.log2reduce(
        store['_id'],
        'increaseControlLoadCount',
        pageStatusEnum2string(state.status),
        params.controlId,
        state.tempState.controlLoadCount,
      );
    }
    return state;
  }

  decreaseControlLoadCount(store: Store<IPageState>, params: IPageDecreaseControlLoadCountParams) {
    let state = store.state;
    if (state.status === PageStatusEnum.dataLoading) {
      state.tempState.controlLoadCount--;
      ConsoleHelper.log2reduce(
        store['_id'],
        'decreaseControlLoadCount',
        pageStatusEnum2string(state.status),
        params.controlId,
        state.tempState.controlLoadCount,
      );
    }
    return state;
  }

  statusUpdated(store: Store<IPageState>, params: IPageStatusUpdatedParams) {
    let state: IPageState;

    ConsoleHelper.log2reduce(
      store['_id'],
      'statusUpdated',
      pageStatusEnum2string(params.newStatus),
    );
    switch (params.newStatus) {
      case PageStatusEnum.initing:
      case PageStatusEnum.inited:
        // case PageStatusEnum.dataLoaded:
        // case PageStatusEnum.done:
        state = { ...store.state };
        break;
      default:
        state = store.state;
        break;
    }
    state.status = params.newStatus;
    return state;
  }

  addModal(store: Store<IPageState>, params: { id: string }) {
    const state: IPageState = { ...store.state };
    state.modalList.push(params.id);
    return state;
  }

  deleteModal(store: Store<IPageState>, params: { id: string }) {
    const modalList: string[] = [];
    for (const modalId of store.state.modalList) {
      if (params.id === modalId) modalList.push(modalId);
    }
    const state: IPageState = { ...store.state, modalList };
    return state;
  }

  updateIsDataUpdated(store: Store<IPageState>, params: { isDataUpdated: boolean }) {
    if (store.state.isDataUpdated === params.isDataUpdated) return store.state;
    return { ...store.state, isDataUpdated: params.isDataUpdated };
  }

  updateState(store: Store<IPageState>, params: any) {
    return { ...store.state, ...params };
  }
}

export default new PageReduce();
