import Button from '.';
import Service from '@mjcloud/service';
import { IDictionary } from '@mjcloud/types';
import ControllerHelper from '@mjcloud/controller';
import { ExceptionHelper } from '@mjcloud/exception';
import { ActionAfterEventArg } from '@mjcloud/redux';
import { ExtendStoreBase } from '@mjcloud/extend-store';
import { IButtonState, ButtonActionType } from './typings';

export default class ButtonExtendStore extends ExtendStoreBase<Button> {
  async handleInitialStateAfter(e: ActionAfterEventArg<IButtonState, ButtonActionType, any>) {
    super.handleInitialStateAfter(e);
  }

  async handleClickAfterAfter(e: ActionAfterEventArg<IButtonState, ButtonActionType>) {
    const { tip, title, controllerId } = e.newState.state;
    const exclusiveLockId = this.instance.page.applyExclusiveLock(tip || `正在执行${title}操作...`);
    if (exclusiveLockId === 0) {
      e.newState.dispatch('clickDone', {});
      return;
    }
    const params: IDictionary = { ...e.params },
      before = async (data: IDictionary, extra: IDictionary = {}) => {
        extra._before = () => e.newState.dispatch('updateLoad', { loading: true });
        extra._after = () => e.newState.dispatch('updateLoad', { loading: false });
        if (this.instance.eventManager.getHandlerCount('onBeforeClick') > 0) {
          await this.instance.eventManager.trigger('onBeforeClick', data, extra);
        }
        return data;
      },
      after = async (data: IDictionary, extra: IDictionary = {}) => {
        if (this.instance.eventManager.getHandlerCount('onAfterClick') > 0) {
          await this.instance.eventManager.trigger('onAfterClick', data, extra);
        }
        return data;
      };
    try {
      if (controllerId) {
        const _params = { data: params, before, after };
        await ControllerHelper.execute({
          sender: this.instance,
          controllerId,
          ..._params,
        });
        // if (this._controller) {
        //   await ControllerHelper.executeByCreated({
        //     controller: this._controller,
        //     ..._params,
        //   });
        // } else {
        //   await ControllerHelper.execute({
        //     sender: this.instance,
        //     controllerId,
        //     ..._params,
        //   });
        // }
      } else {
        await before(params);
        await after(params);
      }

      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      e.newState.dispatch('clickDone', {});
    } catch (error) {
      e.newState.dispatch('clickDone', {});
      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      ExceptionHelper.dispose(error);
    }
  }
}
