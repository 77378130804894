import EventListening, { EventFn } from './eventListening';

interface IDictionary<T = any> {
  [key: string]: T;
}

export default class EventManager<T extends string = string> {
  /**
   * 自定义异常捕获方法，默认直接抛出异常
   */
  static catch: (error: any) => void = error => {
    throw error;
  };
  private _events: IDictionary<EventListening> = {};

  constructor(private sender: any) {}

  getHandlerCount(eventType: T) {
    var eventListening: EventListening = this._events[eventType];
    if (eventListening) {
      return eventListening.count;
    }
    return 0;
  }

  add<D = any>(eventType: T, handler: EventFn<D>) {
    var eventListening: EventListening = this._events[eventType];
    if (!eventListening) {
      eventListening = new EventListening(eventType);
      this._events[eventType] = eventListening;
    }
    eventListening.add(handler);
  }

  __insert<D = any>(eventType: T, index: number, handler: EventFn<D>) {
    var eventListening: EventListening = this._events[eventType];
    if (!eventListening) {
      eventListening = new EventListening(eventType);
      this._events[eventType] = eventListening;
    }
    eventListening.insert(index, handler);
  }

  remove(eventType: T, handler: EventFn) {
    var eventListening: EventListening = this._events[eventType];
    if (eventListening) {
      eventListening.remove(handler);
    }
  }

  /**
   * 执行一个事件
   * @param eventType 事件类型
   * @param data 事件需要的数据
   * @param extra 事件需要的额外属性和方法
   * @param eventSourceSign
   * @param lastAutoTrigger
   */
  async trigger<D = any>(
    eventType: T,
    data: D,
    extra?: IDictionary,
    eventSourceSign?: any,
    lastAutoTrigger?: boolean,
  ) {
    var eventListening: EventListening = this._events[eventType];
    if (eventListening) {
      try {
        await eventListening.trigger(this.sender, data, extra, eventSourceSign, lastAutoTrigger);
      } catch (error) {
        EventManager.catch(error);
      }
    }
  }
}
