import DyChart from '..';
import React from 'react';
import Bar4line2 from './Bar4line2';
import ControlBase from '../../base';
import { Spin, Empty, Result } from 'antd';
import Pie, { PieConfig } from './charts/pie';
import Radar, { RadarConfig } from './charts/radar';
import Column, { ColumnConfig } from './charts/column';
import TransverseBar, { GroupedBarConfig } from './charts/transverseBar';
import Mind, { MindConfig } from './charts/mind';

import GroupedColumnLine, { GroupedColumnLineConfig } from './charts/groupedColumnLine';
import {
  IChartState,
  IChartBarlineConfig,
  IChartBarConfig,
  IChartRadarConfig,
  IChartBasePieConfig,
  IChartTransverseBarConfig,
  IChartMindConfig,
} from '../typings';

import styles from './index.less';

export default class ChartControl extends ControlBase<IChartState, DyChart> {
  private handleClick = (event, data) => {
    if (event) event.preventDefault();
    this.instance.store.dispatch('clickAfter', {...data});
  };

  renderBarline(dataSource: any[], barline: IChartBarlineConfig) {
    const { lineName, rotate } = barline;
    let key: any;
    const transformData: any = [],
      transformObj: any = {};
    for (const row of dataSource) {
      const { x, z } = row;
      key = `${x},${z}`;
      if (!transformObj[key]) {
        transformObj[row] = true;
        transformData.push({ x, [lineName]: z });
      }
    }
    const config: GroupedColumnLineConfig = {
      data: [dataSource, transformData],
      xField: 'x',
      yField: ['y', lineName],
      columnGroupField: 'name',
      xAxis: { label: { rotate: 0, autoRotate: true } },
      yAxis:{
        max:100,
        // tickCount:10,
        rightConfig:{
          visible:false
        }
      }
    };
    return <GroupedColumnLine {...config} />;
  }


  renderBar(data: any[], bar: IChartBarConfig) {
    const config: ColumnConfig = {
      forceFit: true,
      data,
      padding: 'auto',
      xField: 'x',
      yField: 'y',
      seriesField: 'year',
      // xAxis: { title: { visible: false } },
      meta: {
        y: { alias: data[0] && data[0].name },
      },
    };
    return <Column {...config} />;
  }


  renderRadar(data: any[], radar: IChartRadarConfig) {
    const config: RadarConfig = {
      data,
      angleField: 'x',
      radiusField: 'y',
      seriesField: 'z',
      radiusAxis: { grid: { line: { type: 'line' } } },
      line: { visible: true },
      point: {
        // visible: true,
        // shape: 'circle',
        size:2,
      },
      legend: {
        visible: true,
        position: 'top-center',
      },
    };
    return <Radar {...config} />;
  }

  renderTransverseBar(data: any[], transverseBar: IChartTransverseBarConfig) {
    const config: GroupedBarConfig = {
      // data,
      // isGroup: true,
      // xField: 'x',//value
      // yField: 'y',//lable
      /** 自定义颜色 */
      // color: ['#1383ab', '#c52125'],
      // groupField: 'z',
      // marginRatio: 0,
      // legend: {//图例
      //   position: 'top-right',
      // },
      // label: {
      //   // 可手动配置 label 数据标签位置
      //   position: 'middle', // 'left', 'middle', 'right'
      //   // 可配置附加的布局方法
      //   layout: [
      //     // 柱形图数据标签位置自动调整
      //     { type: 'interval-adjust-position' },
      //     // 数据标签防遮挡
      //     { type: 'interval-hide-overlap' },
      //     // 数据标签文颜色自动调整
      //     { type: 'adjust-color' },
      //   ],
      // },
      data,
      xField: 'x',
      yField: 'y',
      groupField: 'z',
      color: ['#66cc99', '#6699ff'],
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    };
    return <TransverseBar {...config} />;
  }

  renderBasePie(dataSource: any[], basePie: IChartBasePieConfig) {
    const config: PieConfig = {
      forceFit: true,//应该是宽度自适应
      radius: 0.8,//饼图的半径，原点为画布中心。配置值域为 (0,1]，1 代表饼图撑满绘图区域。
      data: dataSource,//数据
      angleField: 'y',//扇形切片大小（弧度）所对应的数据字段名。
      innerRadius:0.8,
      colorField: 'x',//扇形颜色映射对应的数据字段名。
      label: {
        visible: false,//数据标签
      //   type: 'inner',
      //   offset: '-50%',
      //   style: {
      //   textAlign: 'center',
      //   fontSize: 14,
      // },
      },
      legend: {//图例
        position: 'right-center',

      },
      statistic: {

      },

      interactions: [{ type: 'element-selected' }, { type: 'element-active' }, { type: 'pie-statistic-active' }],
    };
    return <Pie {...config} />;
  }

  renderPie(dataSource: any[], basePie: IChartBasePieConfig) {
    const config: PieConfig = {
      forceFit: true,//宽高自适应
      // width:515,
      // height:230,
      radius: 0.8,//饼图的半径，原点为画布中心。配置值域为 (0,1]，1 代表饼图撑满绘图区域。
      data: dataSource,//数据
      angleField: 'y',//扇形切片大小（弧度）所对应的数据字段名。
      colorField: 'x',//扇形颜色映射对应的数据字段名。
      label: {
        visible: false,//数据标签
      },
      legend: {//图例
        offsetX:-5,
        position: 'left-top',
        text:{
          style:{
            fontSize:14,
          }
        }
      },
      tooltip:{
        showTitle:false
      },
      interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }]
    };
    return <Pie {...config} />;
  }

  renderBar4line2(dataSource: any[], bar4line2: IChartBarlineConfig) {
    const { lineName } = bar4line2;
    return <Bar4line2 data={dataSource} lineName={lineName} />;
  }

  renderMind(dataSource: any[], mind: IChartMindConfig, onNodeClick?: (e: any, data: any) => void) {
    return <Mind data={dataSource} {...mind} onNodeClick={onNodeClick} />
  }


  renderChart() {
    const { dataSource, series } = this.state;

    if (series.barline) return this.renderBarline(dataSource, series.barline);
    else if (series.pie) return this.renderPie(dataSource, series.pie);
    else if (series.bar) return this.renderBar(dataSource, series.bar);
    else if (series.radar) return this.renderRadar(dataSource, series.radar);
    else if (series.basePie) return this.renderBasePie(dataSource, series.basePie);
    else if (series.bar4line2) return this.renderBar4line2(dataSource, series.bar4line2);
    else if (series.transverseBar) return this.renderTransverseBar(dataSource, series.transverseBar);
    else if (series.mind) return this.renderMind(dataSource, series.mind, this.handleClick);
    return <div>series is NULL</div>;
  }

  renderContent() {
    const { width, height, isFetching, errorMessage, dataSource } = this.state;
    if (errorMessage) return <Result subTitle={errorMessage} />;

    if (dataSource.length > 0) {
      return (
        <div className={styles.dyChart} style={{ width, height }}>
          {this.renderChart()}
        </div>
      );
    } else {
      return (
        <div className={styles.dyChartEmpty} style={{ minHeight: height }}>
          <Empty />
        </div>
      );
    }
  }
}
