import { IDictionary, DataModeEnum } from '@mjcloud/types';
import { applyMixins, ConsoleHelper } from '@mjcloud/utils';
import { IDataSourceParameters } from '@mjcloud/data-source-helper';
import ContainerInstanceBase, {
  IContainerState,
  ContainerControlEventType,
  ContainerControlActionType,
} from '../containerInstanceBase';
import DataInstanceBase, {
  IDataState,
  IDataInstanceBase,
  DataControlEventType,
  DataControlActionType,
} from '../dataInstanceBase';

export type ContainerDataControlEventType = ContainerControlEventType | DataControlEventType;
export type ContainerDataControlActionType = ContainerControlActionType | DataControlActionType;

export interface IContainerDataState<TLayout = IDictionary>
  extends IContainerState<TLayout>,
    IDataState<TLayout> {}

abstract class ContainerDataInstanceBase<
  S extends IContainerDataState = IContainerDataState,
  TEventType extends string = string,
  TDispatchType extends string = string
> extends ContainerInstanceBase<S, TEventType, TDispatchType> implements IDataInstanceBase {
  isJudgePageMode = false;
  constructor(id: string, parent: ContainerInstanceBase, workbenchPart?: any) {
    super(id, parent, workbenchPart);
    if (parent['getDataMode']) {
      parent.eventManager.add('ready', this.handParentDataLoad.bind(this));
    } else {
      this.page.eventManager.add('inited', this.handParentDataLoad.bind(this));
    }
    if (workbenchPart) {
      workbenchPart.eventManager.add('inited', this.handParentDataLoad.bind(this));
    }
  }

  public abstract getDataMode(): DataModeEnum;
  protected init() {
    throw new Error('Method not implemented.');
  }
  public refresh(params?: IDataSourceParameters): void {
    throw new Error('Method not implemented.');
  }
  protected handParentDataLoad(): void {
    throw new Error('Method not implemented.');
  }
  public __loadStart() {
    throw new Error('Method not implemented.');
  }
  public __loadComplete() {
    throw new Error('Method not implemented.');
  }
}
applyMixins(ContainerDataInstanceBase, DataInstanceBase);
export default ContainerDataInstanceBase;
