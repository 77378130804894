import Page from '.';
import Service from '@mjcloud/service';
import JsApiHelper from '@mjcloud/jsapi';
import { DelayCallback, DateHelper } from '@mjcloud/utils';
import PageHelper from '@mjcloud/page-helper';
import globalData from '@mjcloud/global-data';
import { ExceptionHelper } from '@mjcloud/exception';
import { PageModeEnum, DataStateEnum, DraftConfig, IDictionary, PageStatusEnum } from '@mjcloud/types';
import ViewModelHelper, {
  ValueChangeArg,
  IViewModelObject,
  IViewModelCollection,
  ViewModelTypeEnum,
} from '@mjcloud/data-model';

export default class Draft {
  private _isLoadDraft = false;
  // private _exclusiveLockId?: number;
  private _lastDraft: string | undefined;
  private _saveDelayCallback: DelayCallback;

  constructor(private page: Page, private data: DraftConfig) {
    this._saveDelayCallback = new DelayCallback(this._saveDraft);
  }

  private async getParams(needDraftName = true) {
    const { name, businessId: bId, businessType } = this.data;
    if (!bId || !businessType || !name) {
      throw ExceptionHelper.argumentNullException(
        'businessId',
        '草稿组件配置缺少name、businessId和businessType参数',
      );
    }
    const { draftName, businessId } = await PageHelper.getDynamicParamValues(
      this.page as any,
      { draftName: needDraftName ? name : '', businessId: bId },
      {},
    );
    return {
      ...this.getControllerExecParams(),
      businessId: businessId || '0',
      draftName,
    };
  }

  /**
   * 获取当前页面控制器执行参数
   */
  getControllerExecParams() {
    const { businessType } = this.data;

    return {
      pageParams: this.page._getPageParams(),
      pageAddress: this.page.address,
      businessType,
    };
  }

  private _saveDraft = async () => {
    const storage = this.page.dataModel.toJSON();

    const _draft = JSON.stringify(storage);
    if (this._lastDraft !== _draft) {
      this._lastDraft = _draft;
      const params = await this.getParams(),
        result = await Service.saveDraft({
          ...params,
          data: _draft,
        });
    }
    // if (this._exclusiveLockId) this.page.releaseExclusiveLock(this._exclusiveLockId);

    if (globalData.__setDraftTip) {
      globalData.__setDraftTip(`保存于 ${DateHelper.format2user(new Date(), false)}`);
    }
  };

  /**
   * 保存草稿
   */
  saveDraft() {
    if (this.page.store.state.status < PageStatusEnum.done) return;
    this._saveDelayCallback.execute({});
    // const exclusiveLockId = this.page.applyExclusiveLock('正在执行草稿保存操作...');
    // if (exclusiveLockId === 0) return;
    this._saveDelayCallback.bindCancelCallback(() => {
      // this.page.releaseExclusiveLock(exclusiveLockId);
    });
    // this._exclusiveLockId = exclusiveLockId;
  }

  /**
   * 读取草稿
   */
  async loadDraft() {
    switch (this.page.pageMode) {
      case PageModeEnum.add:
      case PageModeEnum.modify:
        return this._loadDraft();

      default:
        break;
    }
  }

  private async _loadDraft() {
    this._isLoadDraft = false;
    const params = await this.getParams(false),
      result: any = await Service.loadDraft(params);

    if (result && result.data) {
      const { confirm } = await JsApiHelper.confirm({ title: '提示', content: '是否加载草稿？' });
      this._isLoadDraft = confirm;
      if (confirm) {
        this._lastDraft = result.data;
        const _data = JSON.parse(result.data);
        if (_data && typeof _data === 'object') {
          console.log('loadDraft', result.data);
          _data._info = params;
          this.initDatas(_data);
          if (globalData.__setDraftTip) {
            const { modifyTime = result.createTime } = result;
            globalData.__setDraftTip(`最后更改于 ${DateHelper.format2user(modifyTime, true)}`);
          }

          // 最后更改于 今天 18:12
          // 最后更改于 06-13 19:22
          // 保存于 18:13:25
          // JsApiHelper.showToast({ content: '自动加载草稿完成', type: 'success' });
        }
      }
    }

    if (!this._isLoadDraft) {
      this.initDatas({ _info: params });
    }
    return result;
  }

  private initDatas(datas: IDictionary) {
    const dataState =
      this.page.pageMode === PageModeEnum.modify ? DataStateEnum.unchanged : DataStateEnum.added;
    for (const key in datas) {
      if (datas[key]) {
        switch (datas[key]._viewModelType) {
          case ViewModelTypeEnum.viewModel:
            const vm = ViewModelHelper.createViewModel();
            vm.initDatas(datas[key], dataState);
            vm.bind('valueChange', this.valueChange.bind(this));
            datas[key] = vm;
            break;

          case ViewModelTypeEnum.viewModelCollection:
          default:
            if (Array.isArray(datas[key])) {
              const vmc = ViewModelHelper.createViewModelCollection();
              vmc.initDatas(datas[key]);
              vmc.bind('rowValueChange', this.rowValueChange.bind(this));
              vmc.bind('rowDataStateChange', this.rowDataStateChange.bind(this));
              datas[key] = vmc;
            }

            break;
        }
      }
    }
    this.page.dataModel.initDatas(datas);
    this.page.dataModel.bind('valueChange', this.pageDataValueChange.bind(this));
  }

  private pageDataValueChange(e: ValueChangeArg) {
    for (const key in e.data.oldValues) {
      const vm = e.data.oldValues[key];
      if (vm) {
        switch (vm._viewModelType) {
          case ViewModelTypeEnum.viewModel:
            (<IViewModelObject>vm).unbind('valueChange', this.valueChange.bind(this));
            break;

          case ViewModelTypeEnum.viewModelCollection:
            (<IViewModelCollection>vm).unbind('rowValueChange', this.rowValueChange.bind(this));
            (<IViewModelCollection>vm).unbind(
              'rowDataStateChange',
              this.rowDataStateChange.bind(this),
            );
            break;
        }
      }
    }

    for (const key in e.data.values) {
      const vm = e.data.values[key];
      if (vm) {
        switch (vm._viewModelType) {
          case ViewModelTypeEnum.viewModel:
            (<IViewModelObject>vm).bind('valueChange', this.valueChange.bind(this));
            break;

          case ViewModelTypeEnum.viewModelCollection:
            (<IViewModelCollection>vm).bind('rowValueChange', this.rowValueChange.bind(this));
            (<IViewModelCollection>vm).bind(
              'rowDataStateChange',
              this.rowDataStateChange.bind(this),
            );
            break;
        }
      }
    }
  }

  private valueChange(e: ValueChangeArg) {
    if (e.data.notUpdateState) return;
    this.saveDraft();
  }

  private rowValueChange() {
    this.saveDraft();
  }

  private rowDataStateChange() {
    this.saveDraft();
  }
}
