import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import { ISystemPageState } from './typings';

class SystemPageReduce extends ReduceBase<ISystemPageState> {
  initialState(store: Store<ISystemPageState>) {
    const state: ISystemPageState = {
      id: "",
      title: "",
      url: "",
      width: 0,
      height: 0,
      tabIndex: -1,
      visible: true,
      afterClose: undefined,
      isDrawer: false,

      display: false,
      config: {},
      configIsFetching: false,
    };
    return state;
  }

  updateStyles(store: Store<ISystemPageState>, params: {}) {
    return store.state;
  }

  openSystemPage(store: Store<ISystemPageState>, params: {}) {
    const state: ISystemPageState = { ...store.state, visible: true };
    return state;
  }

  closeSystemPage(store: Store<ISystemPageState>, params: { cb: (() => void) | undefined }) {
    const state: ISystemPageState = { ...store.state, visible: false, afterClose: params.cb };
    return state;
  }
}

export default new SystemPageReduce();
