import { ContainerExtendStoreBase } from '@mjcloud/extend-store';

export default class SelectorsExtendStore extends ContainerExtendStoreBase<any> {
  protected isNeedNotificationPage = true;

  handleInitialStateBefore(e) {
    const { initConfig } = e.params,
      items: any = [];
    if (initConfig && initConfig.items) {
      initConfig.items.items.map(item => {
        const { id, op, control } = item;
        if (control) {
          const controlInstance = this.instance.createControl(id, op, control);
          if (controlInstance) {
            items.push({
              controlId: controlInstance.id,
              parentId: this.instance.id,
              pageKey: this.instance.page.id,
            });
          }
        }
      });
    }
    e.params.items = items;
  }
}
