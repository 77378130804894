import Store from '@mjcloud/redux';
import Reduce from './reduce';
import { DataModeEnum } from '@mjcloud/types';
import FormExtendStore from './extendStore';
import { ContainerDataInstanceBase } from '@mjcloud/instance';
import { ExceptionHelper } from '@mjcloud/exception';
import { IFormState } from './typings';

export default class Form extends ContainerDataInstanceBase<IFormState> {
  public formItems: any = {};
  public sections: any = [];

  __createStore() {
    return new Store<IFormState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new FormExtendStore(this),
    });
  }

  getDataMode() {
    return DataModeEnum.one;
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  createControl(config) {
    const { id: controlId, title, control, modifyMode } = config;
    if (controlId) {
      if (control) {
        if (!control.nodeName) {
          console.error(`容器控件Form-${this.id} 中的控件 ${controlId} 中不存在nodeName节点`);
          return null;
        }
        control['title'] = title;
        if (modifyMode != null) control['modifyMode'] = modifyMode;
        return super.__createControl(controlId, control);
      } else {
        return this.page.createControl(controlId, { title, parentType: 'form' });
      }
    } else {
      console.error(`容器控件Form-${this.id} 中的控件 controlId 不存在`);
      return null;
    }
  }

  __updateValues(values, actionSourceSign, isParentChange = false) {
    for (const key in this.formItems) {
      const items = this.formItems[key];
      if (items) {
        for (const item of items) {
          const { field } = item.store.state;
          if (field && values[field] !== undefined)
            item.updateValue(values[field], actionSourceSign, isParentChange);
        }
      }
    }
  }

  get dataModel() {
    return this.store.state.dataSource;
  }

  __registerFormItem(key, item) {
    const items = this.formItems[key] || [];
    items.push(item);
    this.formItems[key] = items;
  }

  __registerFormSection(key: number, section) {
    this.sections[key] = section;
  }

  /**
   * 手动触发校验报错
   */
  triggervValid(message, itemId, rowId) {
    for (const key in this.formItems) {
      if (itemId == key) {
        const items = this.formItems[key];
        if (items) {
          for (const item of items) {
            item.triggervValid(message);
          }
        } else console.error(`${key} 不存在`);
      }
    }
    throw ExceptionHelper.businessException(message);
  }

  /**
   * 设置表单子项是否必填
   * @param key 子项Id
   * @param readonly 是否只读
   */
  setFormItem2RequiredType(key, requiredType) {
    const items = this.formItems[key];
    if (items) {
      for (const item of items) {
        item.store.dispatch('updateRequiredType', { requiredType });
      }
    } else console.error(`${key} 不存在`);
  }

  /**
   * 设置表单子项是否只读
   * @param key 子项Id
   * @param readonly 是否只读
   */
  setFormItem2Readonly(key, readonly) {
    const items = this.formItems[key];
    if (items) {
      for (const item of items) {
        item.store.dispatch('updateReadonly', { readonly });
      }
    } else console.error(`${key} 不存在`);
  }

  /**
   * 设置表单子项是否显示
   * @param key 子项Id
   * @param readonly 是否显示
   */
  setFormItem2Display(key, display) {
    const items = this.formItems[key];
    if (items) {
      for (const item of items) {
        item.store.dispatch('updateDisplay', { display });
      }
    } else console.error(`${key} 不存在`);
  }

  /**
   * 设置表单子项的标题
   * @param key 子项Id
   * @param readonly 是否显示
   */
  setFormItem2Title(key, title) {
    const items = this.formItems[key];
    if (items) {
      for (const item of items) {
        item.store.dispatch('updateTitle', { title });
      }
    } else console.error(`${key} 不存在`);
  }

  /**
   * 设置表单某Section下的子项是否只读
   * @param key Section Id
   * @param readonly 是否只读
   */
  setFormSection2Readonly(key, readonly) {
    const { sections } = this.store.state;
    for (const section of sections) {
      if (section.state.id === key) {
        section.dispatch('updateReadonly', { readonly });
      }
    }
  }

  /**
   * 设置表单Section是否显示
   * @param key Section Id
   * @param readonly 是否显示
   */
  setFormSection2Display(key, display) {
    const { sections } = this.store.state;
    for (const section of sections) {
      if (section.state.id === key) {
        section.dispatch('updateDisplay', { display });
      }
    }
  }

  /**
   * 设置表单Section的标题
   * @param key Section Id
   * @param readonly 是否显示
   */
  setFormSection2Title(key, title) {
    const { sections } = this.store.state;
    for (const section of sections) {
      if (section.state.id === key) {
        section.dispatch('updateTitle', { title });
      }
    }
  }

  async valid() {
    for (const key in this.formItems) {
      const items = this.formItems[key];
      if (items) {
        for (const item of items) {
          const validResult = await item.valid(true);
          if (validResult && validResult !== true) {
            throw ExceptionHelper.businessException(validResult.message);
          }
        }
      } else console.error(`${key} 不存在`);
    }
    return true;
  }

  /**
   * 获取当前控件数据集
   * @param isValid 是否获取前做校验
   */
  async getData(isValid) {
    if (isValid) {
      await this.valid();
    }
    return this.dataModel.toJSON();
  }
}
