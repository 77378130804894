import Store from '@mjcloud/redux';
import PageModeHelper from '@mjcloud/page-mode-helper';
import SelectReduceBase from '../common/select/reduce';
import { IRadioButtonsState, IRadioButtonsInitialStateParams } from './typings';

export class RadioButtonsReduce extends SelectReduceBase {
  initialState(store: Store<IRadioButtonsState>, params: IRadioButtonsInitialStateParams) {
    const { initConfig, pageMode } = params,
      { textFieldName, displayMode, disabledMode, theme, title, modifyMode } = initConfig,
      { isAutoSelection, placeholder = `请选择${title || ''}` } = initConfig;
    let state: IRadioButtonsState = {
      title,
      theme,
      value: '',
      placeholder,
      tabIndex: -1,
      rowIdCount: 1,
      dataSource: [],
      isValid: false,
      activeIndex: -1,
      originalData: [],
      selectedRows: [],
      config: initConfig,
      multiSelect: false,
      originalDataSource: [],
      tokenSeparators: [',', '、'],
      textTokenSeparator: '、',
      vaueleTokenSeparator: ',',
      // TODO: defaultOpen配置值待实现
      defaultOpen: false,
      configIsFetching: false,
      textFieldName: textFieldName,
      isAutoSelection: !!isAutoSelection,
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    state.tokenSeparators = state.tokenSeparators
      .concat([state.textTokenSeparator, state.vaueleTokenSeparator])
      .filter((tokenSeparator, index, self) => self.indexOf(tokenSeparator) === index);
    return state;
  }

  loaded(store: Store<IRadioButtonsState>, params) {
    const state = super.loaded(store, params) as IRadioButtonsState,
      { isAutoSelection, multiSelect, dataSource } = state;
    if (isAutoSelection && !multiSelect && dataSource[0]) {
      state.activeIndex = 0;
      state.text = dataSource[0]._text;
      state.value = dataSource[0]._value;
    }
    return state;
  }

  updateActiveIndex(store: Store<IRadioButtonsState>, params: { activeIndex: number }) {
    // if (store.state.activeIndex === params.activeIndex) return store.state;
    const { _text, _value } = store.state.dataSource[params.activeIndex],
      state = this.updateValue(store, { value: _value, text: _text }) as IRadioButtonsState;
    state.activeIndex = params.activeIndex;
    return state;
  }
}

export default new RadioButtonsReduce();
