import Store from '@mjcloud/redux';
import { ValueDataReduceBase } from '@mjcloud/reduce';
import DataSource from '@mjcloud/data-source-helper';
import { IValueControlUpdateValueParams } from '@mjcloud/instance/dist/valueInstanceBase';
import { ISelectBaseState, ISelectBaseDataSource, ISelectBaseLoadedParams } from './typings';

export default abstract class SelectReduceBase<TValue = string> extends ValueDataReduceBase<
  TValue,
  ISelectBaseState<TValue>
> {
  clearDataSource(store: Store<ISelectBaseState<TValue>>, params: {}) {
    let state = { ...store.state };
    return state;
  }

  startLoad(store: Store<ISelectBaseState<TValue>>, params) {
    const state = super.startLoad(store, params);
    if (params.open != null) {
      state.open = params.open;
    }
    return state;
  }

  updateOpen(store: Store<ISelectBaseState<TValue>>, params) {
    const state = store.state;
    if (params.open != null) {
      state.open = params.open;
      return { ...state };
    }
    return state;
  }

  loaded(store: Store<ISelectBaseState<TValue>>, params: ISelectBaseLoadedParams) {
    let state = store.state,
      { rowIdCount } = state;
    const h: any = {},
      { rows } = params.dataSource,
      { config, value, vaueleTokenSeparator } = state,
      { data = {} } = config;
    const dataSource = DataSource.formatDataSource<ISelectBaseDataSource>(
        data,
        rows,
        params.disabledFn,
        row => {
          let _isExist = false;
          if (h[row._value]) _isExist = true;
          h[row._value] = true;
          return { _isExist, _rid: ++rowIdCount };
        },
      ),
      { selectedRows } = this.setSelectedRows(dataSource, vaueleTokenSeparator, value as any);
    state = {
      ...state,
      rowIdCount,
      dataSource,
      selectedRows,
      originalData: rows,
      isFetching: false,
      originalDataSource: dataSource,
    };
    return state;
  }

  updateValue(
    store: Store<ISelectBaseState<TValue>>,
    params: IValueControlUpdateValueParams<TValue>,
  ) {
    const state = super.updateValue(store, params),
      { vaueleTokenSeparator, originalDataSource = [] } = state,
      value: string = state.value as any,
      { selectedRows, rowIds } = this.setSelectedRows(
        originalDataSource,
        vaueleTokenSeparator,
        value,
        params.rowIds,
      );
    state.selectedRows = selectedRows;
    params.rowIds = rowIds;
    params.selectedRows = selectedRows;
    return state;
  }

  private setSelectedRows(
    dataSource: ISelectBaseDataSource[],
    vaueleTokenSeparator: string,
    value: string = '',
    rowIds?: number[],
  ) {
    const selectedRows: ISelectBaseDataSource[] = [],
      _rowIds: number[] = [];
    if (value) {
      const valueArr = `${value}`.split(vaueleTokenSeparator);
      for (const row of dataSource) {
        if (rowIds && rowIds.length > 0) {
          for (const rowId of rowIds) {
            if (row._rid == rowId) {
              selectedRows.push(row);
            }
          }
        } else {
          if (typeof value === 'string') {
            for (const rowVal of valueArr) {
              if (row._value == rowVal) {
                selectedRows.push(row);
                _rowIds.push(row._rid);
              }
            }
          }
        }
      }
    }
    return { rowIds: _rowIds, selectedRows };
  }
}
