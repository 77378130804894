import React from 'react';
import Store from '@mjcloud/redux';
import { Tooltip } from 'antd';
import { DateHelper } from '@mjcloud/utils';
import styled from 'styled-components';
import { AUTOCELLWIDTH } from './constant';
import ControllerHelper from '@mjcloud/controller';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { TooltipPlacement } from 'antd/lib/tooltip';
import SelectReduceBase from '../common/select/reduce';
import { TablePaginationConfig } from 'antd/lib/table';
import { Filter, OperationTypeEnum, FilterTypeEnum } from '@mjcloud/utils';
import { ISelectBaseDataSource, ISelectBaseLoadedParams } from '../common/select/typings';
import {
  ISelectState,
  ISelectInitialStateParams,
  ISelectConfigGridParent,
  ISelectConfigGridItem,
  ISelectConfigGridItemSet,
  ISelectConfigGrid,
  ISelectColumnProps,
} from './typings';

const Cell = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

function dateFormat2momentFormat(format?: string) {
  let _format: string,
    mode = 'date',
    showTime = false;
  switch (format) {
    case 'HH:mm':
    case 'HH:mm:ss':
      _format = format;
      mode = 'time';
      break;
    case 'yyyy-MM-dd HH:mm':
      _format = 'YYYY-MM-DD HH:mm';
      showTime = true;
      break;
    case 'yyyy-MM-dd HH:mm:ss':
      _format = 'YYYY-MM-DD HH:mm:ss';
      showTime = true;
      break;
    case 'yyyy':
      _format = 'YYYY';
      break;
    case 'yyyy-MM':
      _format = 'YYYY-MM';
      break;
    case 'yyyy-MM-dd':
    default:
      _format = 'YYYY-MM-DD';
      break;
  }
  return { format: _format, mode, showTime };
}

const formatter2number = value => {
  if (value === '-') return '-';
  if (value === undefined || isNaN(value as any)) return '';
  let val = `${value}`,
    _value = '',
    valArr: string[] = [];
  if (!val.includes('.')) {
    _value = val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    valArr = val.split('.');
    _value = `${valArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${valArr[1]}`;
  }
  return _value;
};

function getColumnParent(parent: ISelectConfigGridParent, isParent: boolean) {
  const { id, title, items } = parent;
  let totalWidth: number = 0;
  const children: ISelectColumnProps[] = [];
  if (items) {
    for (const _key in items) {
      const { column, width } = getColumn(items[_key], isParent);
      totalWidth += width == null ? AUTOCELLWIDTH : width;
      if (column) {
        children.push(column);
      }
    }
  }
  const column: ISelectColumnProps = {
    title,
    key: id,
    dataIndex: id,
    children,
  };
  return { column, width: totalWidth };
}

function getColumnItem(item: ISelectConfigGridItem) {
  const { id, field, width, title } = item,
    controlType = item.control && item.control.nodeName,
    isRight = controlType === 'number';
  let placement: TooltipPlacement = 'topLeft';
  if (isRight) {
    placement = 'topRight';
  }
  const render = (value: any, record: ISelectBaseDataSource, index: number) => {
      if (value == null) return null;
      let _value = value;

      if (item.control) {
        const { precision = 2, format } = item.control as any;
        if (controlType === 'number') {
          if (typeof value === 'number') {
            _value = value.toFixed(precision);
          }
          _value = formatter2number(value);
        } else if (controlType === 'datetime') {
          const { format: _format } = dateFormat2momentFormat(format);
          _value = DateHelper.format(value, _format);
        }
      }

      return (
        <Tooltip title={_value} placement={placement}>
          <Cell style={{ width: width ? width - 17 : '100%' }}>{_value}</Cell>
        </Tooltip>
      );
    },
    column: ISelectColumnProps = {
      title,
      width,
      render,
      key: id,
      dataIndex: field || id,
      ellipsis: { showTitle: false },
      align: isRight ? 'right' : 'left',
    };
  if (width == null) {
    column.colSpan = 2;
    column.render = (value, record, index) => ({
      props: { colSpan: 2 },
      children: render(value, record, index),
    });
  }
  return column;
}

function getColumn(row: ISelectConfigGridItemSet, isParent: boolean) {
  let item: ISelectConfigGridItem | undefined, parent: ISelectConfigGridParent | undefined;
  if (row.nodeName === 'item') {
    item = row;
  } else if (row.nodeName === 'parent') {
    parent = row;
  } else {
    console.error('gridEdit 下的 items只能具备 item和parent 节点');
  }
  let column: ISelectColumnProps | undefined;
  let width: number | undefined;
  if (item) {
    width = item.width;
    column = getColumnItem(item);
  } else if (parent) {
    isParent = true;
    const _parent = getColumnParent(parent, isParent);
    column = _parent.column;
    width = _parent.width;
  }
  return { column, width };
}

function getGridSet(grid?: ISelectConfigGrid) {
  let isParent = false,
    totalWidth: number = 20,
    columns: ISelectColumnProps[] = [];
  if (grid) {
    let { fastnessCount = 0 } = grid;
    grid.items.items.forEach(item => {
      const { column, width } = getColumn(item, isParent);
      if (column) {
        if (fastnessCount > 0) {
          fastnessCount--;
          column.fixed = 'left';
        }
        columns.push(column);
        if (width != null) {
          totalWidth += width;
        } else {
          totalWidth += AUTOCELLWIDTH;
          const render = () => ({ props: { colSpan: 0 } });
          columns.push({
            width: AUTOCELLWIDTH,
            colSpan: 0,
            render,
          });
        }
      }
    });
    return { columns, isParent, totalWidth };
  } else {
    return { columns: undefined, isParent, totalWidth };
  }
}

export class SelectReduce extends SelectReduceBase {
  initialState(store: Store<ISelectState>, params: ISelectInitialStateParams) {
    const { initConfig, pageMode, address } = params,
      { data: { valueField: valueFieldName = 'id' } = {} } = initConfig,
      { needDropdown, dataIsDefaultSelected, addRowButton, suffixButton } = initConfig,
      { tokenSeparators, textTokenSeparator, vaueleTokenSeparator } = initConfig,
      { grid, search, title, autoFocus, modifyMode, alwaysLoad, pagination } = initConfig,
      { multiSelect, displayMode, disabledMode, textFieldName, disabledControl } = initConfig,
      { isAutoSelection, placeholder = `请选择${title || ''}` } = initConfig;
    const { columns, isParent, totalWidth } = getGridSet(grid);
    let _pagination: TablePaginationConfig | undefined;
    if (pagination) {
      const { pageSize = 10, showTotal = false } = pagination;
      _pagination = { current: 0, pageSize };
      if (showTotal) {
        _pagination.showTotal = (total, range) => `共 ${total} 条`;
      }
    } else {
      if (columns) {
        _pagination = { current: 0, pageSize: 10 };
      }
    }
    if (addRowButton) {
      addRowButton.display = true;
      addRowButton.isAuthority = true;
      if (addRowButton.controllerId && addRowButton.code) {
        const addBtnAddress = { ...address };
        if (addRowButton.moduleId) addBtnAddress.moduleId = addRowButton.moduleId;
        addBtnAddress.authorityNeedAddress = true;
        addRowButton.isAuthority = ControllerHelper.hasRights(addBtnAddress, addRowButton.code);
      }
    }

    let state: ISelectState = {
      title,
      search,
      columns,
      autoFocus,
      totalWidth,
      alwaysLoad,
      placeholder,
      open: false,
      suffixButton,
      tabIndex: 0,
      addRowButton,
      textFieldName,
      rowIdCount: 1,
      dataSource: [],
      valueFieldName,
      isValid: false,
      disabledControl,
      tokenSeparators,
      originalData: [],
      selectedRows: [],
      config: initConfig,
      textTokenSeparator,
      vaueleTokenSeparator,
      pagination: _pagination,
      value: store.state.value,
      // TODO: defaultOpen配置值待实现
      defaultOpen: false,
      buttonLoading: false,
      originalDataSource: [],
      configIsFetching: false,
      needDropdown: !!needDropdown,
      isAutoSelection: !!isAutoSelection,
      multiSelect: !needDropdown ? true : !!multiSelect,
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
      dataIsDefaultSelected: dataIsDefaultSelected == null ? !needDropdown : dataIsDefaultSelected,
    };
    state.tokenSeparators = state.tokenSeparators
      .concat([state.textTokenSeparator, state.vaueleTokenSeparator])
      .filter((tokenSeparator, index, self) => self.indexOf(tokenSeparator) === index);
    return state;
  }

  loaded(store: Store<ISelectState>, params: ISelectBaseLoadedParams) {
    const state = super.loaded(store, params) as ISelectState,
      { dataSource, dataIsDefaultSelected } = state,
      { vaueleTokenSeparator, textTokenSeparator } = state,
      { count, pageIndex, pageSize } = params.dataSource;

    if (state.pagination) {
      state.pagination = { ...state.pagination, total: count, current: pageIndex, pageSize };
    }
    if (dataIsDefaultSelected) {
      state.value = dataSource.map(r => r._value).join(vaueleTokenSeparator);
      state.text = dataSource.map(r => r._text).join(textTokenSeparator);
      state.selectedRows = dataSource;
    }
    return state;
  }

  updateValue(store: Store<ISelectState>, params) {
    const state = super.updateValue(store, params) as ISelectState,
      { columns, multiSelect } = state;
    if (!multiSelect && columns) {
      state.open = false;
    }
    return state;
  }

  updatePagination(store: Store<ISelectState>, params) {
    const state = store.state;
    if (params.pagination != null) {
      state.pagination = params.pagination;
      return { ...state };
    }
    return state;
  }

  updateDropdownStyle(store: Store<ISelectState>, params: React.CSSProperties) {
    return { ...store.state, dropdownStyle: params };
  }

  buttonClickAfter(store: Store<ISelectState>, params: {}) {
    if (store.state.buttonLoading) {
      return store.state;
    }
    return { ...store.state, loading: true };
  }

  buttonClickDone(store: Store<ISelectState>, params: {}) {
    if (!store.state.buttonLoading) {
      return store.state;
    }
    return { ...store.state, loading: false };
  }

  updateButtonLoad(store: Store<ISelectState>, params: { loading: boolean }) {
    if (store.state.buttonLoading === params.loading) {
      return store.state;
    }
    return { ...store.state, buttonLoading: params.loading };
  }

  setAddRowBtn2Display(store: Store<ISelectState>, params: { display: boolean }) {
    if (store.state.addRowButton) {
      const { display } = store.state.addRowButton;
      if (display === params.display) return store.state;
      store.state.addRowButton.display = params.display;
      return { ...store.state };
    }
    return store.state;
  }

  handleSearch(store: Store<ISelectState>, params: { searchValue: string }) {
    const { searchValue } = params,
      { search } = store.state;
    if (search) {
      let filter: Filter;
      if (searchValue) {
        filter = new Filter(FilterTypeEnum.or);
        for (var item of search) {
          filter.addCondition({
            left: item.left,
            right: searchValue,
            op: OperationTypeEnum.like,
          });
        }
      } else {
        filter = Filter.EqualFilter;
      }
      store.dispatch('startLoad', { filter });
    }
    store.state.searchValue = searchValue;
    return store.state;
  }
}

export default new SelectReduce();
