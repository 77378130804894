import Page from '@mjcloud/page';
import { IDictionary } from '@mjcloud/types';

export default abstract class ComponentBase {
  private _id: string;
  private _page: Page;
  private _data: IDictionary;
  constructor(id: string, page: Page, data: IDictionary, extra: IDictionary) {
    this._id = id;
    this._page = page;
    this._data = data;
  }

  public get id() {
    return this._id;
  }
  public get page() {
    return this._page;
  }
  public get initData() {
    return this._data;
  }
}
