interface IDictionary<T = any> {
  [key: string]: T;
}

export default class ObjectHelper {
  public static params2search(params: IDictionary) {
    let search = '';
    if (params) {
      search = '?';
      const searchArr: string[] = [];
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          let param = params[key];
          // if (param instanceof Array) {
          //   param = param.join(',');
          // }
          if (param && typeof param === 'object' && typeof param.toJSON === 'function') {
            param = param.toJSON();
          }
          if (
            typeof param === 'string' ||
            typeof param === 'number' ||
            typeof param === 'boolean'
          ) {
            if (encodeURIComponent) param = encodeURIComponent(String(param));
            searchArr.push(`${key}=${param}`);
          }
        }
      }
      search += searchArr.join('&');
    }

    return search === '?' ? '' : search;
  }

  public static search2params(search: string) {
    const params: IDictionary = {};
    if (search) {
      if (search[0] === '?') search = search.substring(1);
      const searchArr = search.split('&');
      for (const item of searchArr) {
        const index = item.indexOf('=');
        if (index > 0) {
          const key = item.slice(0, index);
          let value = item.slice(index + 1);
          if (decodeURIComponent) value = decodeURIComponent(value);
          params[key] = value;
        }
      }
    }
    return params;
  }
}
