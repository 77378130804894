import SelectInstanceBase from '.';
import { ValueDataExtendStoreBase } from '@mjcloud/extend-store';
import { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux';
import { IDataSourceParameters } from '@mjcloud/data-source-helper';
import { ISelectBaseLoadedParams } from './typings';
import PageHelper from '@mjcloud/page-helper';
import { EventListening } from '@mjcloud/utils';

export default abstract class SelectExtendStoreBase<
  TValue = string,
  TInstance extends SelectInstanceBase<TValue> = SelectInstanceBase<TValue>
> extends ValueDataExtendStoreBase<TValue, TInstance> {
  protected isNeedNotificationPage = false;
  protected isFirstLoad = false;

  handleLoadedBefore(e: ActionBeforeEventArg<ISelectBaseLoadedParams>) {
    const { disabledControl } = this.instance.store.state;
    if (disabledControl) {
      e.params.disabledFn = row => {
        const fn = PageHelper.createPageScriptFunction(this.instance.page, disabledControl, true);
        const disabled = fn(EventListening.getHandlerArg(this.instance, { row }));
        return disabled;
      };
    }
  }

  handleLoadedAfter(e: ActionAfterEventArg<any, any, ISelectBaseLoadedParams>) {
    super.handleLoadedAfter(e);
    if (e.params.isAdd) {
      const { value, text, selectedRows } = e.newState.state;
      const params = { ...e.params, isParentChange: false, value, text, selectedRows };

      this.triggerValueChange('onValueChange', params);
      this.triggerValueChange('valueChange', params);
    }
  }

  async handleStartLoadAfter(e: any) {
    // TODO: 第一次不加载功能重复。
    const { open } = e.params,
      { alwaysLoad, pagination } = e.newState.state;
    if (alwaysLoad) {
      if (open) {
        this['isFirst'] = false;
        this.isFirstLoad = true;
        await super.handleStartLoadAfter(e);
        return;
      } else if (!this.isFirstLoad) {
        this.isFirstLoad = true;
        e.newState.dispatch('loaded', {
          ...e.params,
          dataSource: { rows: [], count: 0, pageIndex: 1, pageSize: 10 },
        });
        return;
      }
    }
    await super.handleStartLoadAfter(e);
  }

  handleUpdatePaginationAfter(e: ActionAfterEventArg<any, any, any>) {
    const { multiSelect, value, vaueleTokenSeparator, pagination } = e.newState.state;

    if (pagination) {
      // const valueArr: string[] = multiSelect ? value.split(vaueleTokenSeparator) : [value];
      const { current, pageSize } = pagination;
      e.newState.dispatch<IDataSourceParameters>('startLoad', {
        // location: valueArr.length > 0 ? valueArr[0] : undefined,
        pageIndex: current,
        pageSize,
      });
    }
  }
}
