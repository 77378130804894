import { ITextboxConfig } from './typings';

const config: ITextboxConfig = {
  title: '',
  maxLength: 400,
  autoFocus: false,
  leftIcon: undefined,
  rightIcon: undefined,
  ruleKey: undefined,
  isAutoCode: false,
  minLength: undefined,
  allowChars: undefined,
  placeholder: undefined,
  modifyMode: undefined,
  displayMode: undefined,
  disabledMode: undefined,
};

export default config;
