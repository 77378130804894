import Icon from '@mjcloud/icon';
import JsApiHelper from '@mjcloud/jsapi';
import Service from '@mjcloud/service';
import { Avatar, Button, Divider, Spin, Table, Upload } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import FileManager from '..';
import ControlBase from '../../base';
import { SCROLLMINHEIGHT, SPACING } from '../../constant';
import {
  IFileManagerState,
  IFileManageUpdateFileList2GroupParams,
  IFileManageUpdateFileListParams,
  IUploadFile,
} from '../typings';
import styles from './index.less';

const GroupAddBtn = styled.div`
  padding: ${SPACING * 2}px 0;
  width: 100%;

  .ant-upload {
    width: 100%;
  }
`;

class FileManagerControl extends ControlBase<IFileManagerState, FileManager> {
  private _key = 0;
  private handleBeforeUpload = (file: RcFile) => {
    if (this.state.config.limitType) {
      if (
        this.state.config.limitType
          .split(',')
          .filter(ext => file.name.toLowerCase().endsWith(ext.toLowerCase())).length == 0
      ) {
        JsApiHelper.showToast({
          content: `请上传${this.state.config.limitType}类型的文件!`,
          type: 'fail',
        });
        return false;
      }
    }
    return true;
  };

  private formatUrl = (_url: string): string => {
    let url = _url,
      URLREG = /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_=](\?)?)*)*$/i;
    if (!URLREG.test(url)) {
      url = Service.getUrl(url);
    }
    return url;
  };

  private handleChange = (info: UploadChangeParam) => {
    const fileList = [...info.fileList],
      { name, status } = info.file;
    if (status) {
      if (status === 'error') {
        JsApiHelper.showToast({ content: `${name}上传失败!`, type: 'fail' });
      }
      this.instance.store.dispatch<IFileManageUpdateFileListParams>('updateFileList', {
        fileList,
        loading: status === 'uploading',
      });
    }
  };

  private handleAddChange = (info: UploadChangeParam) => {
    this.instance.store.dispatch('addFile2Group', { file: info.file });
  };

  private handleChange2Group = (info: UploadChangeParam, record: IUploadFile, index: number) => {
    const { name, status } = info.file;
    if (status) {
      if (status === 'error') {
        JsApiHelper.showToast({ content: `${name}上传失败!`, type: 'fail' });
      }
      this.instance.store.dispatch<IFileManageUpdateFileList2GroupParams>('updateFileList2Group', {
        loading: status === 'uploading',
        file: info.file,
        record,
        index,
      });
    }
  };

  private removeFile2Group = (record: IUploadFile, index: number) => {
    this.instance.store.dispatch('removeFile2Group', { record, index });
  };

  private previewFile = (file: any) => {
    if (file) {
      // if (file.fileId) file.id = file.fileId;
      // this.instance.store.dispatch('previewFile', { file });
      if (file.previewUrl || file.info?.response?.data?.previewUrl) {
        let url = this.formatUrl(file.previewUrl || file.info.response.data.previewUrl);
        JsApiHelper.openLink(url);
      }
    }
  };

  private downloadFile = (file: any) => {
    let url = this.formatUrl(file.url);
    JsApiHelper.downloadFile({ url, name: file.name });
  };

  private customClick = (record: any, type) => {
    this.instance.store.dispatch('customClick', { record, type });
  };

  renderUpload() {
    // TODO: mode === 'append' 场景待实现
    let { readonly, fileList, listType, maxFileCount, placeholder } = this.state;
    const uploadProps: UploadProps = {
      ...this.state.uploadProps,
      fileList,
      multiple: true,
      onPreview: this.previewFile,
      onChange: this.handleChange,
      onDownload: this.downloadFile,
      beforeUpload: file => this.handleBeforeUpload(file),
    };
    const uploadButton = readonly ? null : (
      <div>
        <Icon type={this.state.loading ? 'icon-loading' : 'icon-plus'} spin={this.state.loading} />
        <div className={styles.text}>{this.state.loading ? '上传中……' : placeholder}</div>
      </div>
    );
    if (listType === 'dragger') {
      return (
        <Upload.Dragger {...uploadProps} accept={this.state.config.limitType}>
          {!readonly && <Button>{placeholder}</Button>}
        </Upload.Dragger>
      );
    } else if (listType === 'normal') {
      return (
        <Upload {...uploadProps} accept={this.state.config.limitType} listType="text">
          {!readonly && <Button>{placeholder}</Button>}
        </Upload>
      );
    } else if (listType === 'avatar') {
      const uploadContent =
        fileList.length > 0 && !this.state.loading ? (
          <Avatar src={fileList[0].url} shape="square" size={64} />
        ) : (
          uploadButton
        );
      if (readonly)
        return (
          <div className={styles.avatar} onClick={() => this.previewFile(fileList[0])}>
            {uploadContent}
          </div>
        );
      return (
        <Upload {...uploadProps} accept="image/*" listType="picture-card" showUploadList={false}>
          {uploadContent}
        </Upload>
      );
    } else if (listType === 'picture-card') {
      return (
        <Upload {...uploadProps} accept="image/*" listType="picture-card">
          {maxFileCount > 0
            ? fileList.length >= maxFileCount
              ? null
              : uploadButton
            : uploadButton}
        </Upload>
      );
    } else if (listType === 'picture') {
      return (
        <Upload {...uploadProps} accept="image/*" listType="picture">
          {maxFileCount > 0
            ? fileList.length >= maxFileCount
              ? null
              : uploadButton
            : uploadButton}
        </Upload>
      );
    }
    return (
      <Upload {...uploadProps} accept={this.state.config.limitType}>
        {!readonly && <Button>{placeholder}</Button>}
      </Upload>
    );
  }

  renderGroup() {
    const {
      isFetching,
      readonly,
      rowAddable,
      dataSource,
      groupCustomFirstBtn,
      groupCustomLastBtn,
      firstBtnShow,
      lastBtnShow,
    } = this.state;
    const columns: ColumnProps<IUploadFile>[] = [
      {
        title: '序号',
        width: 60,
        align: 'center',
        dataIndex: '_ordinalId',
        key: 'ordinalId',
        render: (text, record, index) => index + 1,
      },
      {
        title: '标题',
        width: 240,
        dataIndex: 'groupName',
        key: 'groupName',
        render: (text, record, index) => (
          <span className={classNames(record.isRequired && styles.titleRequired)}>{text}</span>
        ),
      },
      {
        title: '文档',
        dataIndex: 'fileName',
        key: 'fileName',
        render: (text, record, index) => {
          const previewFile = () => this.previewFile(record);
          if (text) {
            if (record.info) {
              const { status, percent } = record.info;
              return (
                <a
                  key="a"
                  onClick={previewFile}
                  className={classNames(
                    status === 'uploading' && styles.disabled,
                    status === 'error' && styles.error,
                  )}
                >
                  {status === 'uploading' && (
                    <Icon spin type="loading" style={{ marginRight: 8 }} />
                  )}
                  {text}
                </a>
              );
            }
            return <a onClick={previewFile}>{text}</a>;
          } else if (record.status === 'error') {
            return <span className={styles.error}>请上传文件</span>;
          }

          return null;
        },
      },
      {
        title: '操作',
        width: groupCustomFirstBtn || groupCustomLastBtn ? 220 : 140,
        dataIndex: '_operation',
        key: '_operation',
        render: (text, record, index) => {
          const { groupId, fileId } = record,
            downloadFile = () => fileId && this.downloadFile(record),
            removeFile = () => this.removeFile2Group(record, index),
            handleChange = (info: UploadChangeParam) =>
              this.handleChange2Group(info, record, index);
          if (record.isReadonly) return <div className={styles.operation} />;
          return (
            <div className={styles.operation}>
              {!!firstBtnShow && (
                <>
                  <a onClick={() => this.customClick(record, groupCustomFirstBtn)}>
                    {groupCustomFirstBtn}
                  </a>
                  <Divider type="vertical" />
                </>
              )}
              {!readonly && (
                <Upload
                  {...this.state.uploadProps}
                  accept={this.state.config.limitType}
                  listType="text"
                  showUploadList={false}
                  onChange={handleChange}
                  beforeUpload={file => this.handleBeforeUpload(file)}
                >
                  <a>{fileId ? '替换' : '上传'}</a>
                </Upload>
              )}
              {!readonly && <Divider type="vertical" />}
              <a className={classNames(!fileId && styles.disabled)} onClick={downloadFile}>
                下载
              </a>
              {!readonly && !groupId && <Divider type="vertical" />}
              {!readonly && !groupId && <a onClick={removeFile}>删除</a>}
              {!!lastBtnShow && (
                <>
                  <Divider type="vertical" />
                  <a onClick={() => this.customClick(record, groupCustomLastBtn)}>
                    {groupCustomLastBtn}
                  </a>
                </>
              )}
            </div>
          );
        },
      },
    ];
    return (
      <>
        <Table<IUploadFile>
          size="middle"
          columns={columns}
          pagination={false}
          loading={isFetching}
          dataSource={dataSource.map(r => {
            r.key = ++this._key + '';
            return r;
          })}
          scroll={{ x: 'max-content', y: SCROLLMINHEIGHT }}
        />
        {!readonly && rowAddable && (
          <GroupAddBtn>
            <Upload
              {...this.state.uploadProps}
              accept={this.state.config.limitType}
              listType="text"
              showUploadList={false}
              onChange={this.handleAddChange}
              beforeUpload={file => this.handleBeforeUpload(file)}
            >
              <Button block type="dashed">
                <Icon type="plus" />
                添加
              </Button>
            </Upload>
          </GroupAddBtn>
        )}
      </>
    );
  }

  renderContent() {
    const { isFetching, listType } = this.state;
    return (
      <Spin delay={600} spinning={isFetching}>
        {listType === 'group' ? this.renderGroup() : this.renderUpload()}
      </Spin>
    );
  }
}

export default FileManagerControl;
