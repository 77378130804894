import Store from '@mjcloud/redux';
import Reduce from './reduce';
import { AsyncValidator, DateHelper } from '@mjcloud/utils';
import { RequiredTypeEnum } from '@mjcloud/types';
import { ValueInstanceBase } from '@mjcloud/instance';
import FormItemControlExtendStore from './extendStore';
import { IFormItemState } from './typings';

export default class FormItem {
  private _store: Store<IFormItemState>;
  private _controlInstance;
  private validator;
  constructor(private id, private parent, private config) {
    this.id = id;
    this.parent = parent;
    this._store = new Store<IFormItemState>({
      id: `item-${id}`,
      reduce: Reduce,
      extendStore: {
        handleInitialStateAfter: this.handleInitialStateAfter.bind(this),
      },
    });
    this._controlInstance = this.parent.createControl(config);
    if (this._controlInstance) {
      this._controlInstance.store.bindExtendStore(new FormItemControlExtendStore(this));
    }
    this._store.dispatch('initialState', {
      initConfig: config,
      pageMode: parent.page.pageMode,
      children: this._controlInstance && this._controlInstance.store,
    });
  }

  get store() {
    return this._store;
  }

  get valueType() {
    return this._controlInstance && this._controlInstance['valueType'];
  }

  updateValue(value, actionSourceSign, isParentChange = false) {
    if (this._controlInstance instanceof ValueInstanceBase) {
      const store = this._controlInstance.store;
      if (store === actionSourceSign) return;
      const textFieldName = store.state['textFieldName'];
      let text = value,
        isUpdate = true;
      // if (actionSourceSign === this) isUpdate = true;
      // else if (value != null) isUpdate = true;
      // else if (value === null) isUpdate = true;
      if (isUpdate) {
        if (textFieldName && this.parent.dataModel[textFieldName]) {
          text = this.parent.dataModel[textFieldName];
        }
        const _params = {
          value,
          text,
          isParentChange,
          actionSourceSign,
          noTriggerEvent: true,
        };
        store.dispatch('updateValue', _params);
      }
    }
  }

  handleInitialStateAfter(e) {
    const { title, requiredType, type, max, min, len } = e.newState.state,
      required = requiredType === RequiredTypeEnum.required,
      { message, whitespace = required, enum: _enum, pattern } = e.newState.state,
      _type = type
        ? type
        : this._controlInstance instanceof ValueInstanceBase
        ? this._controlInstance.valueType
        : 'string';
    this.validator = new AsyncValidator(
      title,
      {
        type: _type,
        required,
        max,
        min,
        len,
        whitespace,
        enum: _enum,
        pattern,
        message,
      },
      this._controlInstance ? this._controlInstance.eventManager : undefined,
    );
  }

  handleUpdateReadonlyAfter(e) {
    const { readonly } = e.newState.state;
    if (this._controlInstance instanceof ValueInstanceBase) {
      this._controlInstance.store.dispatch('updateReadonly', { readonly });
    }
  }

  handleUpdateRequiredTypeAfter(e) {
    if (this.validator) {
      this.validator.updateRule({
        required: e.params.requiredType === RequiredTypeEnum.required,
      });
    }
  }

  formatValue(value) {
    if (value == null) return undefined;
    switch (this.valueType) {
      case 'string':
        value = value + '';
        break;
      case 'number':
        value = Number(value);
        break;
      case 'boolean':
        value = Boolean(value);
        break;
      case 'date':
        value = DateHelper.parse(value);
        break;
      default:
        break;
    }
    return value;
  }

  async valid(isParentChange = false) {
    if (this.validator && this._controlInstance instanceof ValueInstanceBase) {
      let value = this.formatValue(this._controlInstance.value);
      const validResult = await this.validator.valid(value);
      if (validResult !== true) {
        this.triggervValid(validResult.message, isParentChange ? this.store : undefined);
      } else {
        this.store.dispatch('validSuccess', {});
      }
      return validResult;
    }
  }

  triggervValid(message, actionSourceSign) {
    this.store.dispatch('validError', { errorHelp: message, actionSourceSign });
  }
}
