import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { IFilterbarState, IFilterbarInitialStateParams } from './typings';

class FilterbarReduce extends ReduceBase<IFilterbarState> {
  initialState(store: Store<IFilterbarState>, params: IFilterbarInitialStateParams) {
    let { initConfig, items, pageMode } = params,
      { displayMode, styleName } = initConfig;
    const state: IFilterbarState = {
      items,
      tabIndex: -1,
      theme: styleName,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }
}

export default new FilterbarReduce();
