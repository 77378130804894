import Store from '@mjcloud/redux';
import Reduce from './reduce';
import TextAreaExtendStore from './extendStore';
import { ValueInstanceBase } from '@mjcloud/instance';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { ITextAreaState } from './typings';

export default class TextArea extends ValueInstanceBase<string, ITextAreaState> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  __createStore() {
    return new Store<ITextAreaState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new TextAreaExtendStore(this),
    });
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}
