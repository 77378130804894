import React from 'react';
import Icon from '@mjcloud/icon';
import JsApiHelper from '@mjcloud/jsapi';
import { ObjectHelper } from '@mjcloud/utils';
import globalData from '@mjcloud/global-data';
import { List, Result, Typography } from 'antd';
import { ExceptionHelper } from '@mjcloud/exception';
import Service, { IFileInfo } from '@mjcloud/service';
import { IRouteComponentProps } from '@mjcloud/router-helper';
import WebOfficeSDK from './web-office-sdk-v1.1.8.es';

import styles from './filelist.less';
import { IDictionary } from '@mjcloud/types';

interface IWPSFilePageProps {
  url: string;
}

interface IWPSFilePageState {
  type?: string;
  fileId?: string;
}

export default class WPSFilePage extends React.Component<
  IWPSFilePageProps,
  IWPSFilePageState
  > {
  constructor(props: IWPSFilePageProps) {
    super(props);
    const url = props.url;
    const search = url.substring(url.indexOf("?"));
    const query: IDictionary<string> = ObjectHelper.search2params(search);
    this.state = { fileId: query.fileId, type: query.type };
    this.init();
  }

  async init() {
    let { type, fileId } = this.state;

    if (fileId) {
      const fileInfo = await Service.getFileDetail(fileId);
      switch (fileInfo?.type.toLowerCase()) {
        case ".doc":
        case ".dot":
        case ".wps":
        case ".wpt":
        case ".docx":
        case ".dotx":
        case ".docm":
        case ".dotm":
        case ".rtf":
          type = "w";
          break;
        case ".xls":
        case ".xlt":
        case ".et":
        case ".xlsx":
        case ".xltx":
        case ".csv":
        case ".xlsm":
        case ".xltm":
          type = "s";
          break;
        case ".ppt":
        case ".pptx":
        case ".pptm":
        case ".ppsx":
        case ".ppsm":
        case ".pps":
        case ".potx":
        case ".potm":
        case ".dpt":
        case ".dps":
          type = "p";
          break;
        case ".pdf":
          type = "f";
          break;
      }
    } else {
      // 新文件
      fileId = "new/0";
    }

    const wps = WebOfficeSDK.config({
      mount: document.querySelector(".wps"),
      url: `https://wwo.wps.cn/office/${type}/${fileId}?_w_tokentype=1&_w_appid=af041f95b1a2452fb5061cbe396b130b&_w_signature=`,
    });
    const YDY_ACCESSTOKEN = await JsApiHelper.getStorage(
      `YDY_ACCESSTOKEN_${globalData.appId}`,
      'cookie',
    );
    wps.setToken({
      token: YDY_ACCESSTOKEN,
    });
    await wps.ready();
  }

  render() {
    const { fileId } = this.state;

    if (fileId) {
      return (
        <div className="wps"></div>
      );
    }
    return <>文件不存在</>;
  }
}
