import React from 'react';
import { List } from 'antd-mobile';
import styled from 'styled-components';
import globalData from '@mjcloud/global-data';
import { px2rem, SPACING, COLOR } from '../../constant';
import { Form, FormItem, getInstance } from '@mjcloud/mcontrols';
import { IFormItemProps, IFormItemState } from '@mjcloud/mcontrols/dist/controls/form/item/typings';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Title = styled.span`
  width: ${px2rem(170)};
  color: ${COLOR.BLACK};
  font-size: ${px2rem(34)};
  margin-left: 0;
  margin-right: ${px2rem(SPACING * 2)};
  text-align: left;
  word-break: break-word;
  white-space: normal;
  overflow: hidden;
  padding: ${px2rem(SPACING)} 0;
`;

const Value = styled.div`
  flex: 1;
  width: 0px;
`;

export default class FormItemControl extends React.Component<IFormItemProps, IFormItemState> {
  private instance: FormItem | null = null;
  constructor(props: IFormItemProps) {
    super(props);
    const { id, nodeName, pageKey, controlId, controlParentId } = this.props;
    if (nodeName === 'blank') {
      this.state = { display: false } as any;
      return;
    }
    const parent = getInstance<Form>({ pageKey, parentId: controlParentId, controlId });
    const instance = parent.formItems[id][0];
    this.state = instance.store.state;
    instance.store.bindViewListen(this.handStateChange);
    this.instance = instance;
  }

  componentWillUnmount() {
    if (this.instance) this.instance.store.unbindViewListen(this.handStateChange);
  }

  private handStateChange = (state: IFormItemState, cb?: () => void) => {
    this.setState(state, cb);
  };

  render() {
    const { ControlFactory } = globalData;
    const { id, pageKey, parentId } = this.props,
      props = { controlId: id, pageKey, parentId },
      { title } = this.state;
    if (!this.state.display) return null;
    if (!title) return <ControlFactory {...props} />;
    return (
      <List.Item>
        <Container>
          <Title>{title}</Title>
          <Value>
            <ControlFactory {...props} />
          </Value>
        </Container>
      </List.Item>
    );
  }
}
