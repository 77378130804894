import Store from '@mjcloud/redux';
import Reduce from './reduce';
import { DataModeEnum } from '@mjcloud/types';
import { DataInstanceBase } from '@mjcloud/instance';
import ModuleExtendStore from './extendStore';

export default class Module extends DataInstanceBase {
  getDataMode(): DataModeEnum {
    return DataModeEnum.all;
  }

  __createStore() {
    return new Store({
      id: this.id,
      reduce: Reduce,
      extendStore: new ModuleExtendStore(this),
    });
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}
