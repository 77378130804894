import Store from '@mjcloud/redux';
import { DataReduceBase } from '@mjcloud/reduce';
import ViewModelHelper from '@mjcloud/data-model';
import PageModeHelper from '@mjcloud/page-mode-helper';
import ControllerHelper from '@mjcloud/controller';
import { IListEditState } from './typings';

function getButtonType(theme = '', active = false) {
  if (active) return 'primary';
  switch (theme) {
    case 'anchor':
      return 'anchor';
    case 'primary':
      return 'primary';
    case 'ghost':
      return 'ghost';
    case 'dashed':
      return 'dashed';
    case 'danger':
      return 'danger';
    case 'default':
    default:
      return 'default';
  }
}

function getButtonSize(size = '') {
  switch (size) {
    case 'large':
      return 'large';
    case 'small':
      return 'small';
    default:
      return 'default';
  }
}

function getAddRowButton(pageMode, address, isReadonly, config) {
  const {
    id,
    tip,
    icon,
    size,
    code,
    title,
    theme,
    confirm,
    controllerId,
    displayMode,
    displayControl,
    active = false,
    confirmTitle = '你确定要执行该操作吗？',
  } = config;
  let display = true;
  if (controllerId && code) display = ControllerHelper.hasRights(address, code);
  if (display) {
    if (isReadonly && displayMode == null) {
      display = false;
    } else {
      display = PageModeHelper.displayMode2boolean(pageMode, displayMode);
    }
  }
  const _theme = getButtonType(theme, active);
  return {
    id,
    tip,
    icon,
    display,
    controllerId,
    theme: _theme,
    displayControl,
    loading: false,
    disabled: false,
    _oldTheme: _theme,
    title: title || '',
    size: getButtonSize(size),
    confirm: confirm ? { title: confirmTitle } : false,
  };
}

class ListEditReduce extends DataReduceBase<IListEditState> {
  initialState(store: Store<IListEditState>, params) {
    let { initConfig, address, pageMode } = params,
      {
        title,
        showSummary = true,
        addRowButton = { title: '添加' },
        displayMode,
        modifyMode,
      } = initConfig,
      dataSource = ViewModelHelper.createViewModelCollection(),
      columns: any = [];
    if (initConfig && initConfig.items) {
      for (const item of initConfig.items.items) {
        if (item.nodeName === 'item') {
          const { id, title = '', index = 0, slot, control } = item,
            { field = id, colorCommand } = item;
          columns.push({
            id,
            slot,
            index,
            title,
            field,
            control,
            colorCommand,
          });
        }
      }
    }
    const readonly = !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      state: IListEditState = {
        title,
        columns,
        count: 0,
        readonly,
        dataSource,
        modifyMode,
        tabIndex: -1,
        rowIdCount: 0,
        config: initConfig,
        configIsFetching: false,
        showSummary: !!showSummary,
        removeButtonDisplay: !readonly,
        _dataSource: dataSource.toArray(true),
        display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
        addRowButton: getAddRowButton(pageMode, address, readonly, addRowButton),
      };
    if (state.addRowButton.display) state.removeButtonDisplay = true;
    return state;
  }

  startLoad(store: Store<IListEditState>, params) {
    const state = super.startLoad(store, params) as IListEditState,
      { aggs = [] } = params;
    if (aggs.length === 0) params.aggs = state.aggs;
    return state;
  }

  loaded(store: Store<IListEditState>, params) {
    const state = { ...store.state, rowIdCount: params.rowIdCount },
      { aggPropertieName } = store.state,
      { aggs, rows } = params.dataSource;
    state.dataSource.initDatas(rows);
    state._dataSource = state.dataSource.toArray(true);
    state.count = 0;
    state.isFetching = false;
    if (aggPropertieName) {
      Object.getOwnPropertyNames(aggs).map(key => {
        if (key === aggPropertieName) {
          state.aggPropertieValue = aggs[key];
        }
      });
    }
    return state;
  }

  updateAggPropertieName(store: Store<IListEditState>, params) {
    return { ...store.state, aggPropertieName: params.aggPropertieName, aggs: params.aggs };
  }

  updateAggPropertieValue(store, params) {
    return { ...store.state, aggPropertieValue: params.value };
  }

  batchAddRow(store, params) {
    let { rows } = params,
      state = { ...store.state },
      { rowIdCount, dataSource } = state;
    for (const rowData of rows) {
      rowIdCount = dataSource.addRow(rowData);
    }
    state.rowIdCount = rowIdCount;
    state._dataSource = dataSource.toArray(true);
    return state;
  }

  deleteRow(store, params) {
    const { index } = params,
      state = { ...store.state },
      { dataSource, _dataSource } = state;
    dataSource.removeRow(_dataSource[index]._rid);
    state._dataSource = dataSource.toArray(true);
    return state;
  }

  deleteAllRow(store, params) {
    const { dataSource } = store.state;
    const list = dataSource.toArray(true);
    for (const item of list) {
      dataSource.removeRow(item._rid);
    }
    return { ...store.state };
  }

  addButtonClickAfter(store, params) {
    const { addRowButton } = store.state;
    return { ...store.state, addRowButton: { ...addRowButton, loading: true } };
  }

  addButtonClickDone(store, params) {
    const { addRowButton } = store.state;
    return {
      ...store.state,
      addRowButton: { ...addRowButton, loading: false },
    };
  }
}

export default new ListEditReduce();
