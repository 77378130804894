import React from 'react';
import globalData from '@mjcloud/global-data';
import { Row, Col, Card, Skeleton } from 'antd';
import { getInstance } from '../../../util';
import { IControlBaseProps } from '@mjcloud/types';
import { Workbench, WorkbenchPart } from '@mjcloud/workbench';
import { IWorkbenchPartState } from '../../part/typings';

import styles from './index.less';

interface IWorkbenchPartProps extends IControlBaseProps {
  id: string;
  name: string;
  height?: number;
}

export default class WorkbenchPartControl extends React.Component<
  IWorkbenchPartProps,
  IWorkbenchPartState
> {
  private instance: WorkbenchPart;
  constructor(props: IWorkbenchPartProps) {
    super(props);
    const { id, controlId, parentId, pageKey } = props;
    const workbench = getInstance<Workbench>(controlId, parentId, pageKey);
    this.instance = workbench.findPart(id);
    this.state = this.instance.store.state as any;
    this.instance.store.bindViewListen(this.handStateChange);
  }

  public componentWillUnmount() {
    this.instance.store.unbindViewListen(this.handStateChange);
  }

  shouldComponentUpdate(
    nextProps: Readonly<IWorkbenchPartProps>,
    nextState: Readonly<IWorkbenchPartState>,
  ) {
    if (nextState !== this.state) return true;
    return false;
  }

  private handStateChange = (state: IWorkbenchPartState, cb?: () => void) => {
    this.setState(state, cb);
  };

  private renderPartContent() {
    const { ControlFactory } = globalData,
      { content, contentCols } = this.state;
    if (contentCols > 1) {
      const span = 24 / contentCols;
      return content.map((items, key) => (
        <Row key={key} gutter={24} style={{ width: '100%', height: '100%' }}>
          {items.map((item, index) =>
            item.colspan ? (
              <Col span={span * item.colspan} key={index} style={{ padding: 0, height: '100%' }}>
                <ControlFactory {...item} id={item.controlId} />
              </Col>
            ) : null,
          )}
        </Row>
      ));
    } else {
      return (
        content &&
        content[0] &&
        content[0].map(row => <ControlFactory key={row.controlId} {...row} id={row.controlId} />)
      );
    }
  }

  private renderTitle() {
    const { ControlFactory } = globalData,
      { title, showTitle, titleRight } = this.state;
    return showTitle ? (
      <div className={styles.dyPartTitleContainer}>
        <div className={styles.dyPartTitle}>{title}</div>
        <div className={styles.dyPartTitleMiddle}></div>
        {titleRight.length > 0 ? (
          <div className={styles.dyPartTitleRight}>
            {titleRight.map(titleRightProps => (
              <ControlFactory {...titleRightProps} id={titleRightProps.controlId} />
            ))}
          </div>
        ) : null}
      </div>
    ) : null;
  }

  private renderContent() {
    const { ControlFactory } = globalData,
      { newRow } = this.state;
    return (
      <Card
        className={styles.dyPartContainer}
        title={this.renderTitle()}
        bodyStyle={{ padding: '1px 0' }}
      >
        {newRow && newRow.length > 0 && (
          <div className={styles.dyPartRow}>
            {newRow.map(row => (
              <div key={row.controlId} className={styles.dyPartRowItem}>
                <ControlFactory {...row} id={row.controlId} />
              </div>
            ))}
          </div>
        )}

        {<div className={styles.dyPartContent}>{this.renderPartContent()}</div>}
      </Card>
    );
  }

  private renderLoad() {
    return <Skeleton className={styles.dyPartLoading} active />;
  }

  render() {
    const { configIsFetching } = this.state,
      { height } = this.props;
    return (
      <div style={{ minHeight: height }}>
        {configIsFetching ? this.renderLoad() : this.renderContent()}
      </div>
    );
  }
}
