import React from 'react';
import TreeView from '..';
// import { Table } from 'shineout';
import ControlBase from '../../base';
import { findDOMNode } from 'react-dom';
import globalData from '@mjcloud/global-data';
import TreeViewCellControl from '../treeview-cell/pc';
import ResizeObserver from 'resize-observer-polyfill';
import { ITableBaseRow } from '../../common/table/typings';
import Table from '../../../components/VirtualizedTable';
import { PARENTMINHEIGHT, SCROLLMINHEIGHT, SPACING } from '../../constant';
import OperationContentControl from '../../common/table/operationContent/pc';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import {
  ITreeViewState,
  ITreeViewStartLoadParams,
  ITreeViewUpdateSelectedRowsParams,
  ITreeViewUpdateExpandedRowKeysParams,
} from '../typings';
import {
  ITableLocale,
  ITableComponents,
  TableRowSelection,
  SelectionSelectFn,
} from '../../../components/VirtualizedTable/interface';

import styles from './index.less';

const TableCell: React.FC<any> = props => {
  if (props.cellId === '_operation') {
    return <OperationContentControl {...props} />;
  } else if (props.store) {
    return <TreeViewCellControl {...props} />;
  } else {
    const { rowId, cellId, record, store, children, ...restProps } = props;
    // return <td {...restProps}>{children}</td>;
    return <div {...restProps}>{children}</div>;
  }
};

const components: ITableComponents = {
  body: {
    cell: TableCell,
  },
};

class TreeViewControl extends ControlBase<ITreeViewState, TreeView> {
  private container: Element | null = null;
  private resizeObserver: ResizeObserver | null = null;

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.resizeObserver && this.container) {
      this.resizeObserver.unobserve(this.container);
      this.resizeObserver.disconnect();
      this.container = null;
    }
  }

  private onResize = () => {
    if (this.container) {
      const parentElementHeight = this.container.clientHeight,
        scrollHeight =
          parentElementHeight > PARENTMINHEIGHT ? parentElementHeight - SPACING : SCROLLMINHEIGHT;
      this.instance.store.dispatch('updateScrollHeight', { scrollHeight });
    }
  };

  private readyContainer = (instance: Table<ITableBaseRow> | null) => {
    const container = findDOMNode(instance);
    if (container instanceof Element && container.parentElement) {
      const parentElementHeight = container.parentElement.clientHeight;
      if (parentElementHeight > PARENTMINHEIGHT) {
        this.container = container.parentElement;
        this.resizeObserver = new ResizeObserver(this.onResize);
        this.resizeObserver.observe(container.parentElement);
      }
    }
  };

  private handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, React.Key[] | null>,
    sorter: SorterResult<ITableBaseRow> | SorterResult<ITableBaseRow>[],
  ) => {
    const { current, pageSize } = pagination;
    const params: ITreeViewStartLoadParams = {
      pageIndex: current,
      pageSize: pageSize,
    };
    params.orderBy = [];
    if (sorter instanceof Array) {
      // TODO: sorter in Array
    } else if (sorter.field && sorter.order && sorter.column) {
      const { dataIndex } = sorter.column;
      params.orderBy = [{ name: dataIndex as string, sortType: sorter.order === 'ascend' ? 1 : 2 }];
    }
    this.instance.store.dispatch<ITreeViewStartLoadParams>('startLoad', params);
  };

  private handleSelectChange: SelectionSelectFn<ITableBaseRow> = (
    record,
    selected,
    selectedRowKeys,
    halfSelectedKeys,
  ) => {
    this.instance.store.dispatch<ITreeViewUpdateSelectedRowsParams>('updateSelectedRows', {
      row: record,
      checked: selected,
      selectedRowKeys,
      halfSelectedKeys,
    });
  };

  private handleExpandedRowsChange = (
    expandedRowKeys: string[] | number[],
    expandLevel: number,
  ) => {
    this.instance.store.dispatch<ITreeViewUpdateExpandedRowKeysParams>('updateExpandedRowKeys', {
      expandLevel,
      expandedRowKeys,
    });
  };

  // private handleExpand = (expanded: boolean, record: ITableBaseRow) => {
  //   let expandedRowKeys = [...this.state.expandedRowKeys] as number[];
  //   if (expanded) {
  //     expandedRowKeys.push(record._rid);
  //   } else {
  //     expandedRowKeys = expandedRowKeys
  //       .map(key => {
  //         if (key === record._rid) return null;
  //         return key;
  //       })
  //       .filter(key => key != null) as number[];
  //   }
  //   this.instance.store.dispatch<ITreeViewUpdateExpandedRowKeysParams>('updateExpandedRowKeys', {
  //     expandedRowKeys,
  //   });
  // };

  rowClassName = (record: ITableBaseRow, index: number) => {
    const { rowSelection, selectedRows = [] } = this.state;
    if (rowSelection && rowSelection.type === 'radio') {
      if (selectedRows.length > 0 && selectedRows[0]._rid === record._rid) {
        return `ant-table-row-selected ${styles.rowSelected} ${styles.rowRadio}`;
      }
      return styles.rowRadio;
    }
    return '';
  };

  renderContent() {
    const {
      columns,
      pagination,
      dataSource,
      isFetching,
      expandLevel,
      showSummary,
      rowSelection,
      errorMessage,
      scrollHeight,
      expandedRowKeys,
      expandLevel2Keys,
      summaryDataSource,
      selectedRows = [],
      halfSelectedRows = [],
      configErrorMessage,
    } = this.state;
    const { formatMessage } = globalData;
    const locale: ITableLocale = {
      emptyText: errorMessage ? errorMessage : formatMessage({ id: 'table.locale.emptyText' }),
    };

    const _rowSelection: TableRowSelection<ITableBaseRow> | undefined = rowSelection
      ? {
          ...rowSelection,
          strictly: true,
          halfSelectedKeys: halfSelectedRows.map(row => row._rid),
          selectedRowKeys: selectedRows.map(row => row._rid),
          onSelect: this.handleSelectChange,
        }
      : undefined;
    if (configErrorMessage) return <>{configErrorMessage}</>;
    // return <Table bordered keygen="_rid" />
    const y = scrollHeight
      ? pagination === false
        ? scrollHeight
        : scrollHeight - 41
      : scrollHeight;

    return (
      <Table<ITableBaseRow>
        ref={this.readyContainer}
        bordered
        locale={locale}
        columns={columns}
        loading={isFetching}
        components={components}
        dataSource={dataSource}
        pagination={pagination}
        className={styles.table}
        showSummary={showSummary}
        rowSelection={_rowSelection}
        // rowClassName={this.rowClassName}
        expandLevel={expandLevel}
        expandedRowKeys={expandedRowKeys}
        expandLevel2Keys={expandLevel2Keys}
        summaryDataSource={summaryDataSource}
        scroll={{ x: 'max-content', y }}
        childrenColumnName="_children"
        onChange={this.handleTableChange}
        // onExpand={this.handleExpand}
        onExpandedRowsChange={this.handleExpandedRowsChange}
        // onRow={this.handleTableRow}
        rowKey="_rid"
        size="small"
      />
    );
  }
}
export default TreeViewControl;
