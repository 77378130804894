import requestJs from './requestJs';
import globalData from '@mjcloud/global-data';
import PageHelper from '@mjcloud/page-helper';
import { PageAddress } from '@mjcloud/page/dist/typings';
import JsApiHelper, { ENV, APP_TYPE } from '@mjcloud/jsapi';
import { TemplateLogicBase, LogicBase } from '@mjcloud/logic';
import { Exception, ExceptionHelper } from '@mjcloud/exception';
import { sleep, StringHelper, ImportLoader, RequireHelper, ObjectHelper } from '@mjcloud/utils';
import { WorkbenchPartAddress } from '@mjcloud/workbench/dist/part/typings';
import { wxDebug, wxJsApiList, wxAgentJsApiList, IWxAgentResult } from './wxInfo';
import {
  IAppInfo,
  IAppInfoConfig,
  PageConfig,
  IDictionary,
  PageModeEnum,
  IControllerConfig,
  IDataSourceParams,
  IUserInfoConfig,
  IAppSettingInfo,
  IPlatformConfig,
  IAppMenuConfig,
} from '@mjcloud/types';

export interface IGetDataSourceConfigParams {
  datasourceId: string;
  pageAddress: PageAddress;
  workbenchPartAddress?: WorkbenchPartAddress;
}
export interface IGetDataSourceParams extends IDataSourceParams {
  pageAddress: PageAddress;
  workbenchPartAddress?: WorkbenchPartAddress;
}

export interface IGetControllerConfigParams {
  controllerId: string;
  pageAddress: PageAddress;
  workbenchPartAddress?: WorkbenchPartAddress;
}
export interface IGetControllerParams {
  params: IDictionary;
  controllerId: string;
  pageAddress: PageAddress;
  pageParams: IDictionary;
  businessType?: string;
  workbenchPartAddress?: WorkbenchPartAddress;
}

export interface ISaveDraftParams {
  businessType: string;
  businessId: string;
  pageParams: IDictionary;
  draftName: string;
  data: string;
  pageAddress: PageAddress;
  workbenchPartAddress?: WorkbenchPartAddress;
}

export interface ILoadDraftParams {
  businessType: string;
  businessId: string;
  pageParams: IDictionary;
  pageAddress: PageAddress;
  workbenchPartAddress?: WorkbenchPartAddress;
}

export interface ISigninParams {
  /** 租户Id */
  tenantId: string;
  /** 用户名 */
  username: string;
  /** 密码 */
  password: string;
  /** 需登录的应用 */
  appId: string;
  /** 自定义后端请求地址 */
  appUrl?: string;
}

export interface IBigScreenSigninParams {
  /** 应用的corpId */
  corpId: string;
  /** 密码 */
  password: string;
  /** 需登录的应用 */
  appId: string;
}

export interface IDDSigninParams {
  /** 应用的corpId */
  corpId: string;
  /** 免登授权码，有效期5分钟，且只能使用一次 */
  authCode: string;
  /** 需登录的应用 */
  appId: string;
}

export interface IWXSigninParams {
  /** 应用的corpId */
  corpId: string;
  /** 免登授权码，有效期5分钟，且只能使用一次 */
  authCode: string;
  /** 需登录的应用 */
  appId: string;
}

export interface IDDConfigParams {
  /** 应用的corpId */
  corpId: string;
  /** 需登录的应用 */
  appId: string;
  url: string;
}

export interface IWXConfigParams {
  /** 应用的corpId */
  corpId: string;
  /** 需登录的应用 */
  appId: string;
  url: string;
}

interface ISigninResult {
  accessToken: string;
  appUrl: string;
  authCode: string;
}

interface IGetWxInfoResult {
  suiteId: string;
}

interface IGetReportUrlParams {
  reportId: string;
  directPrint?: boolean;
  directPreview?: boolean;
  params?: IDictionary;
  exportType?: 'EXCEL' | 'PDF' | 'WORD' | 'PRINT';
}
interface IGetReportUrlResult {
  filter: string;
  url: string;
}

export interface IFileInfo {
  businessId?: string;
  id: string;
  name: string;
  previewUrl: string;
  size: number;
  thumbUrl: string;
  type: string;
  url: string;
}

function addTimestamp(url: string) {
  return url.indexOf('?') >= 0
    ? `${url}&_t=${new Date().getTime()}`
    : `${url}?_t=${new Date().getTime()}`;
}

/**
 * 递归menus列表以添加全局ModuleCode
 */
function recursiveMenus(menus: IAppMenuConfig[], appId: string) {
  for (const menu of menus) {
    const { moduleId, code } = menu;
    globalData.rbCore.pushModuleCode(appId, moduleId, code);
    if (menu.children && menu.children.length > 0) {
      recursiveMenus(menu.children, appId);
    }
  }
}

function search2string(str: string, searchs: string[]) {
  for (const search of searchs) {
    if (str.search(search) >= 0) {
      return true;
    }
  }
  return false;
}

export default class Service {
  /**
   * 记录上一次dd.config的时间
   */
  private static _config_date: Date | null = null;
  /**
   * @description: 针对 requestJs 重写以适应当前平台对后端服务的请求
   * @param url 要请求的地址
   * @param params 要请求的参数
   * @return: 返回请求结果
   */
  static async requestService<T>(url: string, params: IDictionary = {}): Promise<T | undefined> {
    if (globalData.rbCore && globalData.rbCore.config) {
      const { apiHost, version } = globalData.rbCore.config;
      return this.__requestService(url, apiHost, version, params);
    }
    return undefined;
  }

  /**
   * @description: 针对 requestJs 重写以适应当前平台对后端服务的请求
   * @param url 要请求的地址
   * @param params 要请求的参数
   * @return: 返回请求结果
   */
  static async requestServiceByNoVersion<T>(
    url: string,
    params: IDictionary = {},
  ): Promise<T | undefined> {
    if (globalData.rbCore && globalData.rbCore.config) {
      const { apiHost } = globalData.rbCore.config;
      return requestJs<T>(apiHost + addTimestamp(url), 'POST', params, {
        headers: await this.getHeaders(),
      });
    }
    return undefined;
  }

  /**
   * @description: 针对 requestJs 重写以适应当前平台对后端服务的请求
   * @param url 要请求的地址
   * @param params 要请求的参数
   * @return: 返回请求结果
   */
  static async requestServiceByLogin<T>(
    url: string,
    params: IDictionary = {},
  ): Promise<T | undefined> {
    if (globalData.rbCore && globalData.rbCore.config) {
      const { loginHost, loginVersion } = globalData.rbCore.config,
        options = {};
      return requestJs<T>(
        this.getApiUrl(loginHost, loginVersion) + addTimestamp(url),
        'POST',
        params,
        options,
      );
    }
    return undefined;
  }

  private static async __requestService<T>(
    url: string,
    apiHost: string,
    version: string,
    params: IDictionary,
  ): Promise<T | undefined> {
    return requestJs<T>(this.getApiUrl(apiHost, version) + addTimestamp(url), 'POST', params, {
      headers: await this.getHeaders(),
    });
  }

  private static getApiUrl(host: string, version: string) {
    return `${host}/api/${version}`;
  }

  private static getAppId() {
    return globalData.appId || '';
  }

  private static async getHeaders() {
    const headers: any = {},
      YDY_ACCESSTOKEN = await JsApiHelper.getStorage(
        `YDY_ACCESSTOKEN_${this.getAppId()}`,
        'cookie',
      );
    if (YDY_ACCESSTOKEN) headers.YDY_ACCESSTOKEN = YDY_ACCESSTOKEN;
    return headers;
  }

  static getUrl(url: string) {
    const { apiHost } = globalData.rbCore.config;
    return `${apiHost}${url}`;
  }

  static getNewid() {
    return Service.requestService<string>('/createid/newid', {});
  }

  static getNewids(quantity = 1) {
    return Service.requestService<string[]>('/createid/newids', { quantity });
  }

  /**
   * 获取报表服务预览URL
   * @param reportId 报表Id，需要业务开发人员自行配置
   * @param type
   */
  static async getReportUrl(param: IGetReportUrlParams) {
    const { reportId, params = {}, exportType, directPrint, directPreview } = param,
      { _filter, ...outerParams } = params;
    let type = directPreview ? undefined : directPrint ? 'PRINT' : exportType;
    const search = ObjectHelper.params2search({ ...outerParams, reportId, type }),
      url = `/report/geturl${search}`;
    let result = await Service.requestService<IGetReportUrlResult>(url, {
      params: { filter: _filter },
    });
    if (result) {
      const resultArr = result.url.split('?');
      if (resultArr.length === 2) {
        const resultHost = resultArr[0],
          resultSearch = resultArr[1],
          resultParams = ObjectHelper.search2params(resultSearch);
        result.url = `${resultHost}${ObjectHelper.params2search({
          ...resultParams,
          ...params,
        })}`;
      }
    }
    return result;
  }

  /**
   * 获取上传文档到后端所需信息
   */
  static async getUploadInfo(businessId: string, businessType: string) {
    const { apiHost, version } = globalData.rbCore.config;
    return {
      action: `${this.getApiUrl(
        apiHost,
        version,
      )}/file/upload?businessId=${businessId}&businessType=${businessType}`,
      headers: await this.getHeaders(),
    };
  }

  /**
   * 获取文件列表
   * @param businessId 业务Id
   * @param businessType 业务类型
   */
  static async getFileList(businessId: string, businessType: string) {
    return Service.requestService<IFileInfo[]>('/file/list', {
      businessType,
      businessId,
    });
  }

  /**
   * 获取文件列表
   * @param businessId 业务Id
   * @param businessType 业务类型
   */
  static async getFileList2Group(businessId: string, businessType: string) {
    return Service.requestService<IFileInfo[]>('/file/listByGroup', {
      businessType,
      businessId,
    });
  }

  /**
   * 获取某一文件详情
   * @param fileId 文件Id
   */
  static async getFileDetail(fileId: string) {
    return Service.requestService<IFileInfo>('/file/one', { fileId });
  }

  /**
   * 获取钉钉预览文件需要的信息
   * @param fileId 文件Id
   */
  static async previewFileInDingtalk(fileId: string) {
    const { code } = await JsApiHelper.requestAuthCode({ corpId: globalData.corpId });
    return Service.requestService<{ spaceId: string; fileId: string }>('/file/ding_preview_info', {
      authCode: code,
      fileId,
    });
  }

  /**
   * 把文件转存到工作流钉钉空间并把
   * @param fileId 文件Id
   */
  static async workflowFileInDingtalk(fileId: string) {
    const { code } = await JsApiHelper.requestAuthCode({ corpId: globalData.corpId });
    return Service.requestService<{ spaceId: string; fileId: string }>('/file/ding_workflow_info', {
      authCode: code,
      fileId,
    });
  }

  /**
   * 获取该自动编号是否已启用
   * @param ruleKey
   * @param pageAddress
   */
  static async getIsAutoCoded(ruleKey: string, pageAddress: PageAddress) {
    const result = await Service.requestService<boolean>('/control/get_is_auto_coded', {
      ruleKey,
      pageAddress,
    });
    return !!result;
  }

  /**
   * 获取数据源配置
   */
  static getDataSourceConfig(params: IGetDataSourceConfigParams) {
    const { datasourceId, pageAddress, workbenchPartAddress } = params,
      requestParams: IDictionary = { datasourceId };
    let url = '/datasource/get';
    if (globalData.debugger) {
      url += '?type=datasource';
    }
    if (workbenchPartAddress) {
      requestParams.workbenchPartAddress = workbenchPartAddress;
    } else {
      requestParams.pageAddress = pageAddress;
    }
    return this.requestService<IDictionary>(url, requestParams);
  }

  /**
   * 获取指定数据源的数据
   */
  static getDataSource<T>(param: IGetDataSourceParams) {
    const { id, dataMode, params, pageAddress, workbenchPartAddress } = param,
      requestParams: IDictionary = { datasourceId: id, params };
    let url = '/datasource/' + dataMode;
    if (globalData.debugger) {
      url += '?type=datasource';
    }
    if (workbenchPartAddress) {
      requestParams.workbenchPartAddress = workbenchPartAddress;
    } else {
      requestParams.pageAddress = pageAddress;
    }
    return this.requestService<T>(url, requestParams);
  }

  /**
   * 获取控制器配置
   */
  static getControllerConfig(params: IGetControllerConfigParams) {
    const { controllerId, pageAddress, workbenchPartAddress } = params,
      requestParams: IDictionary = { controllerId };
    let url = '/controller/get';
    if (globalData.debugger) {
      url += `?type=controller`;
    }
    if (workbenchPartAddress) {
      requestParams.workbenchPartAddress = workbenchPartAddress;
    } else {
      requestParams.pageAddress = pageAddress;
    }
    return this.requestService<IControllerConfig>(url, requestParams);
  }

  /**
   * 执行服务控制器
   */
  static executeController<T>(params: IGetControllerParams) {
    const { pageAddress, pageParams, workbenchPartAddress, ...outerParams } = params,
      requestParams: IDictionary = { ...outerParams };
    let url = '/controller/execute';
    if (globalData.debugger) {
      url += `?type=controller`;
    }
    if (workbenchPartAddress) {
      requestParams.workbenchPartAddress = workbenchPartAddress;
    } else {
      requestParams.pageAddress = pageAddress;
      requestParams.pageAddress.pageParams = pageParams;
      if (pageParams._reviseId) {
        if (requestParams.params.reviseId && globalData.debugger) {
          JsApiHelper.showToast({
            content: `控制器中配置了参数reviseId，reviseId为平台修订功能专用参数`,
          });
        }
        requestParams.params.reviseId = pageParams._reviseId;
      }
    }
    return this.requestService<T>(url, requestParams);
  }

  /**
   * 获取控件配置
   * @param pageAddress 页面信息
   * @param id 控件Id
   * @param params 额外参数
   */
  static getControlConfig<T>(pageAddress: PageAddress, id: string, params: IDictionary) {
    return this.requestService<T>('/control/get_layout_by_service', {
      isMobile: globalData.rbCore.isMobile,
      controlId: id,
      pageAddress,
      params,
    });
  }

  /**
   * 获取当前应用信息
   * @param appId 应用Id
   * @param forcedRefresh 是否强制刷新
   */
  static async appInfo(appId: string, forcedRefresh = false) {
    let appInfo: IAppInfo | undefined = globalData.rbCore.config.appInfoList[appId],
      identityCode = await globalData.storage.get(`${appId}_identityCode`);
    if (identityCode == null) {
      const ex = new Error('您当前没有操作此应用的权限！');
      ex.name = 'notAppRightsException';
      throw ex;
    }
    if (appInfo == null || forcedRefresh === true) {
      const result = await this.requestService<IAppInfoConfig>('/info/app', {
        isMobile: globalData.rbCore.isMobile,
        appId,
        identityCode,
      });
      if (result) {
        appInfo = { appId, menus: result.modules };
        appInfo.menus = result.modules.map(menu => {
          if (!menu.icon) {
            menu.icon = `icon-${menu.moduleId}`;
          }
          return menu;
        });
        recursiveMenus(appInfo.menus, appId);
        globalData.appInfo = appInfo;
        globalData.rbCore.config.appInfoList[appId] = appInfo;
        const permission: string[] = [];
        const rights: string[] = [];
        for (const key in result.rights) {
          rights.push(result.rights[key]);
        }
        for (let i = 0; i < rights.length; i++) {
          const rightArr = rights[i].split(':'),
            moduleCode = rightArr[0];
          if (moduleCode && rightArr[1]) {
            const codes = rightArr[1].split(',');
            for (let j = 0; j < codes.length; j++) {
              const code = codes[j];
              if (code) permission.push(`${moduleCode}:${code}`);
            }
          }
        }
        globalData.rbCore.setPermission(appId, permission);

        return appInfo;
      }
      return undefined;
    }
    return appInfo;
  }

  static async appSettingInfo(
    appId: string,
    defaultInfoConfig: Partial<IAppSettingInfo> = {},
  ): Promise<IAppSettingInfo | undefined> {
    const _defaultInfoConfig: IAppSettingInfo = {
      title: '明建云',
      desc: '湖南明建云信息科技有限公司',
      logo: 'images/logo_32x32.png',
      ico: 'images/logo_32x32.png',
      copyright: {
        year: '2019',
        company: '湖南明建云信息科技有限公司',
        links: [
          {
            key: 'terms',
            title: '条款',
            href: 'https://yuandingyun.vip',
            blankTarget: true,
          },
          {
            key: 'logo',
            icon: 'platform-logo',
            href: 'https://yuandingyun.vip',
            blankTarget: true,
          },
          {
            key: 'help',
            title: '帮助',
            href: 'https://yuandingyun.vip',
            blankTarget: true,
          },
        ],
      },
      layout: 'sidemenu',
      version: 'v1.0',
      apiHost: 'http://localhost:8787',
      ...defaultInfoConfig,
    };
    const appSettingInfo: IAppSettingInfo = { ..._defaultInfoConfig };

    const accessToken = await this.isLogin();
    if (accessToken) {
      appSettingInfo.apiHost = accessToken.appUrl;
    }
    globalData.rbCore.config.appSettingList[appId] = appSettingInfo;
    return appSettingInfo;
  }

  /**
   * 获取当前用户的权限列表
   */
  static async permissionInfo(appId: string): Promise<void> {
    if (globalData.rbCore.isExistPermission(appId)) return;
    //TODO: permissionInfo 缺失通过登录获取身份列表
    await this.appInfo(appId);
  }

  /**
   * 判断是否登录
   */
  static async isLogin() {
    // if (
    //   ENV.appType === APP_TYPE.WEBVIEW_IN_DDAPP ||
    //   ENV.appType === APP_TYPE.WEBVIEW_IN_WWAPP
    //   // ENV.appType === APP_TYPE.WEBVIEW_IN_WXAP
    // ) {
    //   if (!this._config_date) return false;
    // }
    const YDY_ACCESSTOKEN = await JsApiHelper.getStorage(
      `YDY_ACCESSTOKEN_${this.getAppId()}`,
      'cookie',
    );
    if (YDY_ACCESSTOKEN && typeof YDY_ACCESSTOKEN === 'string') {
      const result = JSON.parse(decodeURIComponent(YDY_ACCESSTOKEN)) as ISigninResult;
      return result;
    }
    return false;
  }

  /**
   * 用户登录
   * @param params
   */
  static async signin(params: ISigninParams) {
    const { appUrl, tenantId, username: code, password: pwd, appId } = params,
      data = { appId, appUrl, tenantId, code, pwd };
    const result = await this.requestServiceByLogin<ISigninResult>('/oauth/login', data);
    if (result) await this.signinDone(result);
    return result;
  }

  /**
   * 大屏用户登录
   * @param params
   */
  static async bigScreenSignin(params: IBigScreenSigninParams) {
    const { corpId, password: pwd, appId } = params,
      data = { corpId, appId, pwd };
    const result = await this.requestServiceByLogin<ISigninResult>('/oauth/lslogin', data);
    if (result) await this.signinDone(result);
    return result;
  }

  static async autoSignin(accesstoken: string | ISigninResult) {
    if (typeof accesstoken === 'string') {
      accesstoken = JSON.parse(decodeURIComponent(accesstoken)) as ISigninResult;
    }
    await this.signinDone(accesstoken);
  }

  /**
   * 钉钉登录
   * @param params
   */
  static async ddSignin(params: IDDSigninParams) {
    const { corpId, authCode: code, appId } = params,
      data = { corpId, code, appId };
    const result = await this.requestServiceByLogin<ISigninResult>('/dingtalk/login', data);
    if (result) await this.signinDone(result);
    return result;
  }

  /**
   * 微信登录
   * @param params
   */
  static async wxSignin(params: IWXSigninParams) {
    const { corpId, authCode: code, appId } = params,
      data = { corpId, code, appId };
    const result = await this.requestServiceByLogin<ISigninResult>('/wechat/login', data);
    // const result: ISigninResult = {
    //   accessToken:
    //     'C1BWz9/xE0NuYuUmry2n6rGFemDr2bb1Htxax6e5qAUEyLBvxJvPulPc1mgOkh6rt22CyU/hyRm+b6ne+JGx9U0VlvhKV/LwkgilSsDSZ/SMuQcZFFB4XSGeF9nA6ze8EWzN+CT2cSFuf2I68oOXW9Hu4dIDNuC7fninbALj/sb6U/GXa84/qtzkn2p4Zd0oa5q0ocqBx3oBspY40IfYyJ10IYZXMhMXf09yu0CNN5/0XVtPXrnCsqbCpDje+4SFk+uLA3BxsEwO4e9lDWmI+/KKOyfaKl6u0kO88g3Yuxztz+92bY8U6ENmd5A4pQBe0w/YDtn1aVu88Favq0ubbA==',
    //   appUrl: '121.196.242.43',
    //   authCode: '427166038455619584',
    // };
    if (result) await this.signinDone(result);
    return result;
  }

  /**
   * 根据appId获取应用相关信息
   */
  static async getWxInfo(): Promise<IGetWxInfoResult> {
    const appId = globalData.appId;
    const result = await this.requestServiceByLogin<IGetWxInfoResult>('/wechat/info', { appId });
    if (result) return result;
    return {} as any;
  }

  private static async signinDone(result: ISigninResult) {
    if (!StringHelper.isUrl(result.appUrl)) {
      if (globalData.isHttps) {
        result.appUrl = `https://${result.appUrl}`;
      } else {
        result.appUrl = `http://${result.appUrl}`;
      }
    }
    globalData.rbCore.config.setApiHost(result.appUrl);
    await JsApiHelper.setStorage(
      `YDY_ACCESSTOKEN_${this.getAppId()}`,
      encodeURIComponent(JSON.stringify(result)),
      'cookie',
    );
  }

  /**
   * 钉钉JSAPI鉴权
   * @param params
   */
  static async ddConfig(params: IDDConfigParams) {
    if (this._config_date) return;
    this._config_date = new Date();
    const { corpId, url, appId } = params,
      data = { corpId, url, appId };
    const result = await this.requestServiceByLogin<any>('/dingtalk/config', data);
    JsApiHelper.requestAuthentication(result);
  }

  static async wxConfig(params: IWXConfigParams) {
    if (this._config_date) return;
    let result: any;
    this._config_date = new Date();
    const { corpId, url, appId } = params,
      data = { corpId, url, appId };
    try {
      result = await this.requestServiceByLogin<any>('/wechat/config', data);
      if (result) {
        const { nonceStr, signature, timeStamp } = result;
        wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: wxDebug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: globalData.corpId, // 必填，企业微信的corpID
          timestamp: timeStamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature, // 必填，签名，见附录1
          jsApiList: wxJsApiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
      }
    } catch (error) { }
    try {
      result = await this.requestServiceByLogin<any>('/wechat/agentconfig', data);
      if (result) {
        const { agentId, nonceStr, signature, timeStamp } = result;
        wx.ready(function () {
          wx.agentConfig({
            corpid: globalData.corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
            agentid: agentId, // 必填，企业微信的应用id
            timestamp: timeStamp, // 必填，生成签名的时间戳
            nonceStr, // 必填，生成签名的随机串
            signature, // 必填，签名，见附录1
            jsApiList: wxAgentJsApiList, //必填
            success: function (res: IWxAgentResult) {
              console.log('wx.agentConfig-success', res);
            },
            fail: function (res: any) {
              console.log('wx.agentConfig-fail', res);
              // if (res.errMsg.indexOf('function not exist') > -1) {
              //   alert('版本过低请升级');
              // }
            },
          });
        });
      }
    } catch (error) { }
    wx.error(function (res: any) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log('wx.error', res);
    });
  }

  /**
   * 用户登出
   */
  static async logout() {
    // const result = await this.requestServiceByLogin('/oauth/logout');
    //登出之后，将当前登录用户信息置空
    globalData.rbCore.__setUserInfo(undefined as any);
    globalData.rbCore.config.appInfoList[globalData.appId] = null as any
    JsApiHelper.removeStorage(`YDY_ACCESSTOKEN_${this.getAppId()}`, 'cookie');
    // return result;
  }

  static async userInfo(appId: string, isRetry = false) {
    if (isRetry === false && (globalData.rbCore.userInfo || globalData.rbCore.userInfo === false))
      return globalData.rbCore.userInfo;
    try {
      const userInfo = await this.requestService<IUserInfoConfig>('/info/user', {});
      if (userInfo) {
        globalData.rbCore.__setUserInfo(userInfo);
        globalData.userInfo = userInfo;
        if (userInfo.identities && userInfo.identities.length > 0) {
          const identityCode = await globalData.storage.get(`${appId}_identityCode`);
          await globalData.storage.set(`${appId}_identityCode`, userInfo.identities[0].code);
          for (const identitie of userInfo.identities) {
            if (identitie.code == identityCode) {
              // globalData.identities = userInfo.identities;
              await globalData.storage.set(`${appId}_identityCode`, identitie.code);
            }
          }
        } else {
          await globalData.storage.set(`${appId}_identityCode`, '');
        }
        this.alertAnnounce(userInfo);
      }
    } catch (error) {
      globalData.rbCore.__setUserInfo(false);
      throw error;
    }
  }

  /**
   * 显示公告
   */
  private static async alertAnnounce(userInfo: IUserInfoConfig) {
    if (userInfo.announce) {
      try {
        const { id, title, outline } = userInfo.announce;
        const result = await JsApiHelper.alert({
          message: title,
          description: outline,
          buttonLabels: ['不再提示'],
        });
        if (result) {
          if (result.buttonIndex === 0) {
            await Service.requestService('/notify/readNotify', { id });
          }
        }
      } catch (error) {
        ExceptionHelper.dispose(error);
      }
    }
  }

  /**
   * 保存草稿
   */
  static saveDraft(params: ISaveDraftParams) {
    return Service.requestService<void>('/draft/save', params);
  }

  /**
   * 读取草稿
   */
  static loadDraft(params: ILoadDraftParams) {
    return Service.requestService<string>('/draft/load', params);
  }

  /**
   * 删除草稿
   */
  static deleteDraft(params: ILoadDraftParams) {
    return Service.requestService<void>('/draft/delete', params);
  }

  /**
   * 创建一个或多个有序ID
   * @param quantity 要一次性创建的数量
   */
  static newids(quantity = 1) {
    return Service.requestService<string[]>('/createid/newids', { quantity });
  }

  /**
   * 获取页面配置
   * @param pageAddress 页面信息
   */
  static fetchPageConfig(pageAddress: PageAddress) {
    return this.requestServiceByNoVersion<PageConfig>(`/page/`, {
      pageAddress,
      isMobile: globalData.rbCore.isMobile,
    });
  }

  /**
   * 获取平台配置信息
   *
   * 只支持在PC端可用
   */
  static platformConfigInfo(): Promise<IPlatformConfig | undefined> {
    let url = `${window.location.origin}/config.json`;
    // url = addTimestamp(url);
    return requestJs<IPlatformConfig>(url);
  }
  /**
   * 获取业务项目公共脚本
   *
   * 只支持在PC端可用
   */
  static async fetchProjectCommonScripts(): Promise<void> {
    const { apiHost } = globalData.rbCore.config;
    try {
      await new ImportLoader(`${apiHost}/resource/static/scripts/vendor.js`, 'vendor').load();
    } finally {
      try {
        await new ImportLoader(`${apiHost}/resource/static/scripts/common.js`, 'common').load();
      } finally {
        return;
      }
    }
  }
  /**
   * 获取模板页或自定义页面
   * @param address 页面信息
   */
  static async fetchTemplatePage<T>(address: PageAddress) {
    const { apiHost } = globalData.rbCore.config;
    const path = PageHelper.formatTemplatePath(address);
    const pageMode = address.pageMode == null ? PageModeEnum.none : address.pageMode;
    let url = `${apiHost}/resource${path}/${pageMode}?isMobile=${globalData.rbCore.isMobile}`;
    if (globalData.debugger) {
      url += '&pageId=' + address.pageId;
    }
    const Template = await RequireHelper.loadScript<T>(url);
    return Template;
  }

  /**
   * 获取模板页或自定义页面的逻辑页
   * @param address 页面信息
   */
  static async fetchTemplateLogicPage(pageAddress: PageAddress) {
    if (ENV.appType === APP_TYPE.EAPP || ENV.appType === APP_TYPE.MINIAPP) {
      const templateType = await this.requestServiceByNoVersion<string>('/page/template_name', {
        pageAddress,
        isMobile: globalData.rbCore.isMobile,
      });
      if (templateType) {
        const TemplateLogic: typeof TemplateLogicBase = globalData.templateClassList[templateType];
        if (TemplateLogic) {
          TemplateLogic.templateType = templateType;
          return TemplateLogic;
        }
      }
    } else {
      const { apiHost } = globalData.rbCore.config;
      const path = PageHelper.formatTemplatePath(pageAddress);
      const pageMode = pageAddress.pageMode == null ? PageModeEnum.none : pageAddress.pageMode;
      let url = `${apiHost}/resource${path}/${pageMode}/logic?isMobile=${globalData.rbCore.isMobile}`;
      if (globalData.__debugger) {
        url += '&pageId=' + pageAddress.pageId;
      }
      try {
        const Template = await RequireHelper.loadScript<typeof TemplateLogicBase>(url);
        return Template;
      } catch (error) {
        throw error;
      }
    }
  }

  /**
   * 获取逻辑页
   * @param url 逻辑页地址
   */
  static async fetchLogicPage(url: string) {
    if (ENV.appType === APP_TYPE.EAPP || ENV.appType === APP_TYPE.MINIAPP) {
      return globalData.logicClassList[url] as typeof LogicBase;
    } else {
      const { apiHost } = globalData.rbCore.config;
      let _url = `${apiHost}/resource/logic${url}`;
      try {
        const Logic = await RequireHelper.loadScript<typeof LogicBase>(_url);
        return Logic;
      } catch (error) {
        throw error;
      }
    }
  }

  /**
   * 把异常提交到后端
   * @param exception
   */
  static async errorLog(exception: Exception): Promise<string | undefined> {
    if (globalData.rbCore._isInit) {
      let _data = { data: JSON.stringify({ exception }) },
        config = { headers: await this.getHeaders() },
        { apiHost, version } = globalData.rbCore.config,
        url = `${apiHost}/api/${version}/error/log`;

      try {
        const result = await JsApiHelper.request<string | undefined>({
          url,
          data: _data,
          method: 'POST',
          headers: config.headers,
        });
        return result;
      } catch (error) {
        if (error.id) return error.id;
      }
    }
    return undefined;
  }

  /**
   * 读取草稿
   */
  static async getWorkflowMode(params: Partial<ILoadDraftParams>): Promise<number | undefined> {
    return Service.requestService<number>('/workflow/getworkflowmode', params);
  }
}
