import Store from '@mjcloud/redux';
import Reduce from './reduce';
import Workbench from '..';
import { EventManager } from '@mjcloud/utils';
import globalData from '@mjcloud/global-data';
import { InstanceBase } from '@mjcloud/instance';
import { IWorkbenchPart } from '../typings';
import WorkbenchPartExtendStore from './extendStore';
import { WorkbenchPartLogicBase, fetchPartLogicPage } from './util';
import { IWorkbenchPartState, WorkbenchPartEventType, WorkbenchPartAddress } from './typings';

export default class WorkbenchPart {
  private workbenchPartName: string;
  private _store: Store<IWorkbenchPartState>;
  private _logic: WorkbenchPartLogicBase | null = null;
  private _eventManager = new EventManager<WorkbenchPartEventType>(this);
  constructor(private id: string, private workbench: Workbench, config: IWorkbenchPart) {
    this.workbenchPartName = config.name;
    this._store = new Store<IWorkbenchPartState>({
      id,
      reduce: Reduce,
      extendStore: new WorkbenchPartExtendStore(this),
    });
    this._store.dispatch('configStartLoad', {
      initConfig: config,
    });
  }

  get page() {
    return this.workbench.page;
  }

  get store() {
    return this._store;
  }

  get logic() {
    return this._logic as WorkbenchPartLogicBase;
  }

  /**
   * 当前控件的事件管理器
   */
  get eventManager() {
    return this._eventManager;
  }

  /**
   * 当前控件的数据总线管理器
   */
  get busManager() {
    return this.workbench.busManager;
  }

  get address(): WorkbenchPartAddress {
    const { appId } = globalData;
    return { appId, partId: this.workbenchPartName, pageId: 'Main' };
  }

  async __initLogic(name: string) {
    const LogicPage = await fetchPartLogicPage(`/workbench/parts/${name}/Main.logic.js`),
      _logic = new LogicPage(this);
    this._logic = _logic;
  }

  __createControl(config) {
    const { control } = config,
      { id = control.id } = config;

    if (id && control) {
      const controlId = `${this.id}-${id}`;
      control.parentType = 'workbench';
      return this.workbench.__createControl(controlId, control, this);
    } else {
      console.error(
        `容器控件Workbench-${this.workbench.id}下的部件${this.workbenchPartName}的${control.nodeName}控件没有设置id`,
      );
      return null;
    }
  }

  public __controlInitStart(controlId: string) {
    this.store.dispatch('increaseControlInitCount', { controlId });
  }

  public __controlInitComplete(controlId: string) {
    this.store.dispatch('decreaseControlInitCount', { controlId });
  }

  public __controlLoadStart(controlId: string) {
    this.store.dispatch('increaseControlLoadCount', { controlId });
  }

  public __controlLoadComplete(controlId: string) {
    this.store.dispatch('decreaseControlLoadCount', { controlId });
  }

  public __partLoadComplete() {
    this.workbench.__partLoadComplete(this.id);
  }

  /**
   * 获取当前页面指定参数的值
   * @param key 参数名称
   */
  public getParam(key: string): string | undefined {
    return undefined;
  }

  findControl<T extends InstanceBase>(controlId: string) {
    return this.workbench.findControl<T>(`${this.id}-${controlId}`);
  }
}
