import React from 'react';
import styled from 'styled-components';
import globalData from '@mjcloud/global-data';
import ControlBase from '../../core/base';
import { px2rem, SPACING } from '../../constant';
import { IWaterfallState } from '@mjcloud/mcontrols/dist/controls/waterfall/typings';

// TODO： 指定height为100%;在这里有极大风险导致页面异常，需要考虑滚动的情况
const Container = styled.div`
  height: 100%;
`;

const Interval = styled.div`
  height: ${px2rem(SPACING * 3)};
`;

export default class WaterfallControl extends ControlBase<IWaterfallState> {
  renderContent() {
    const { items } = this.state;
    const { ControlFactory } = globalData;
    return (
      <Container>
        {items.map(({ isShowBottomBlank, ...props }) => {
          return (
            <React.Fragment key={props.controlId}>
              <ControlFactory {...props} />
              {isShowBottomBlank && <Interval />}
            </React.Fragment>
          );
        })}
      </Container>
    );
  }
}
