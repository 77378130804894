import React from 'react';
import styled from 'styled-components';
import { px2rem, SPACING, COLOR } from '../../constant';
import ControlBase from '../../core/base';
import globalData from '@mjcloud/global-data';
import { IFilterbarState } from '@mjcloud/mcontrols/dist/controls/filterbar/typings';

interface IFilterbarProps {
  theme: string;
}

const Filterbar = styled.div<IFilterbarProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${px2rem(100)};

  color: ${({ theme }) => (theme === 'norm' ? COLOR.BLACK : COLOR.WHITE)};
  background-color: ${({ theme }) => (theme === 'norm' ? COLOR.WHITE : COLOR.PRIMARY)};
`;

const FilterLeft = styled.div`
  flex: 1;
  max-width: 100%;
  margin-right: ${px2rem(SPACING * 3)};
`;

const FilterRight = styled.div`
  width: ${px2rem(204)};
`;

export default class FilterbarControl extends ControlBase<IFilterbarState> {
  renderContent() {
    const { items, theme } = this.state;
    const { ControlFactory } = globalData;
    return (
      <Filterbar theme={theme}>
        {items.map(({ position, ...props }) => {
          if (position === 'left') {
            return (
              <FilterLeft key={props.controlId}>
                <ControlFactory {...props} />
              </FilterLeft>
            );
          } else {
            return (
              <FilterRight key={props.controlId}>
                <ControlFactory {...props} />
              </FilterRight>
            );
          }
        })}
      </Filterbar>
    );
  }
}
