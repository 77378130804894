import { IDictionary } from '@mjcloud/types';
import { ExceptionHelper } from '@mjcloud/exception';
import Axios, { AxiosRequestConfig } from 'axios';

Axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.data && error.response.data.e) {
      return {
        status: 200,
        statusText: '后端异常',
        data: error.response.data,
      };
    } else if (String(error).search('Network Error') >= 0) {
      return {
        status: 704,
        statusText: '找不到该链接',
        data: { e: ExceptionHelper.networkException() },
      };
    } else if (String(error).search('Request failed with status code 500') >= 0) {
      return {
        status: 705,
        statusText: 'Request failed with status code 500',
        data: {
          e: ExceptionHelper.requestServiceException('请求服务发生内部错误：705！', error),
        },
      };
    }
    return {
      status: 700,
      statusText: '未知错误',
      data: { e: ExceptionHelper.requestServiceException('请求服务发生内部错误：700！', error) },
    };
  },
);

interface IRbResponse<T> {
  data?: T;
  e?: any;
}

interface IRequestJsOptions {
  headers?: IDictionary<string>;
  timeout?: number;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param url       The URL we want to request
 * @param method
 * @param data
 * @param [options] The options we want to pass to "fetch"
 * @param source
 * @return           An object containing either "data" or "err"
 */
export default async function requestJs<T>(
  url: string,
  method: 'GET' | 'POST' = 'GET',
  data: IDictionary = {},
  options: IRequestJsOptions = {},
  source: IDictionary = {},
) {
  let { headers, timeout } = options;
  const _source = Axios.CancelToken.source();
  source.cancel = _source.cancel;
  let _options: AxiosRequestConfig = {
    timeout,
    headers,
    cancelToken: _source.token,
    transformRequest: [
      function(data) {
        let ret = '';
        for (let it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
        }
        return ret.substring(0, ret.length - 1);
      },
    ],
  };
  let response =
    method === 'GET'
      ? await Axios.get<IRbResponse<T>>(url, _options)
      : await Axios.post<IRbResponse<T>>(url, data, _options);
  if (response.status) {
    if (response.status >= 700) {
      switch (response.status) {
        case 704:
          throw ExceptionHelper.networkException();
        default:
          throw ExceptionHelper.requestServiceException(
            `请求服务发生内部错误：${response.status}`,
            response,
          );
      }
    }
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      if (data.e != null) {
        const ex = ExceptionHelper.convertTo(data.e);
        ex.isRequestService = true;
        throw ex;
      } else if (data.data != null) {
        return data.data as T;
      } else {
        let result: any = data;
        return result as T;
      }
    }
  }
  return undefined;
}
