import Store from '@mjcloud/redux';
import { ArrayHelper } from '@mjcloud/utils';
import { IDictionary } from '@mjcloud/types';
import { DataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import {
  ICutoverMenu,
  IWorkbenchPart,
  IWorkbenchState,
  IWorkbenchGroup,
  IWorkbenchSection,
  WorkbenchAreaEnum,
  IWorkbenchLoadedParams,
  IWorkbenchInitialStateParams,
} from './typings';

class WorkbenchReduce extends DataReduceBase<IWorkbenchState> {
  initialState(store: Store<IWorkbenchState>, params: IWorkbenchInitialStateParams) {
    const { initConfig, pageMode, createWorkbenchPart } = params,
      { cutoverControllerId, settingControllerId } = initConfig,
      { orderby, sections: _sections, isShowOverview, displayMode } = initConfig;

    const sections: IWorkbenchSection[] =
      _sections && _sections.items
        ? _sections.items.map(section => {
            const { items, ...outerSection } = section;
            return {
              ...outerSection,
              items: items.map(item => {
                const { id, colspan = 1 } = item;
                const config: IWorkbenchPart = {
                  area: WorkbenchAreaEnum.left,
                  width: colspan,
                  name: id,
                  id,
                  sort: 0,
                  title: '',
                  group: '',
                  workbenchId: '',
                };
                const part = createWorkbenchPart(config);
                return { ...item, ...config, colspan, id: part['id'] };
              }),
            };
          })
        : [];
    const state: IWorkbenchState = {
      orderby,
      sections,
      tabIndex: -1,
      leftParts: [],
      rightParts: [],
      isShowOverview,
      dataSource: [],
      cutoverMenus: [],
      isFetching: false,
      config: initConfig,
      cutoverControllerId,
      settingControllerId,
      configIsFetching: false,
      isStaticMode: _sections ? !!_sections.items : false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  loaded(store: Store<IWorkbenchState>, params: IWorkbenchLoadedParams) {
    if (store.state.isStaticMode) return store.state;
    const { parts } = params,
      leftParts: IWorkbenchGroup[] = [],
      rightParts: IWorkbenchPart[] = [],
      leftPartsDic: IDictionary<number> = {},
      state = super.loaded(store, params) as IWorkbenchState;
    for (const part of parts) {
      switch (part.area) {
        case WorkbenchAreaEnum.left:
          if (part.group) {
            if (leftPartsDic[part.group] == null) {
              const leftPartsIndex = leftParts.push({
                parts: [],
                span: part.width,
              });
              leftPartsDic[part.group] = leftPartsIndex - 1;
            }
            if (part.name === 'Placeholder') {
            } else if (leftParts[leftPartsDic[part.group]]) {
              leftParts[leftPartsDic[part.group]].parts.push(part);
            }
          }
          break;

        case WorkbenchAreaEnum.right:
          rightParts.push(part);
          break;
      }
    }

    state.leftParts = ArrayHelper.oneArray2twoArray(leftParts, 2, ({ span = 2 }) => span);
    state.rightParts = rightParts;

    return state;
  }

  updateCutoverMenus(
    store: Store<IWorkbenchState>,
    params: { workbenchId?: string; workbenchName?: string; menus?: ICutoverMenu[] },
  ) {
    const {
      workbenchId = store.state.workbenchId,
      workbenchName = store.state.workbenchName,
      menus = store.state.cutoverMenus,
    } = params;
    const state: IWorkbenchState = {
      ...store.state,
      workbenchId,
      workbenchName,
      cutoverMenus: menus,
    };
    return state;
  }
}

export default new WorkbenchReduce();
