import React from 'react';
import Icon from '@mjcloud/icon';
import SimpleSearch from '..';
import { Input, Button } from 'antd';
import ControlBase from '../../base';
import {
  ISimpleSearchState,
  ISimpleSearchUpdateValueParams,
  ISimpleSearchFindParams,
} from '../typings';

import styles from './index.less';

class SimpleSearchControl extends ControlBase<ISimpleSearchState, SimpleSearch> {
  private handPressEnter: React.KeyboardEventHandler<HTMLInputElement> = event => {
    this.instance.store.dispatch<ISimpleSearchFindParams>('find', {
      value: event.currentTarget.value,
    });
  };

  private handSearch = () => {
    this.instance.store.dispatch<ISimpleSearchFindParams>('find', {
      value: this.state.searchValue,
    });
  };

  private handChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.instance.store.dispatch<ISimpleSearchUpdateValueParams>('updateValue', {
      value: event.currentTarget.value,
    });
  };

  private handHeaderChange = (value?: string) => {
    this.instance.store.dispatch<ISimpleSearchUpdateValueParams>('updateValue', {
      value,
    });
  };

  private handHeaderPressEnter = (value?: string) => {
    this.instance.store.dispatch<ISimpleSearchFindParams>('find', {
      value,
    });
  };

  private handReset = () => {
    this.instance.store.dispatch<ISimpleSearchUpdateValueParams>('updateValue', {
      value: undefined,
    });
    this.instance.store.dispatch<ISimpleSearchFindParams>('find', {
      value: undefined,
    });
  };

  renderDefaultTheme() {
    const { placeholder, searchValue } = this.state;
    return (
      <div className={styles.container}>
        <Input
          value={searchValue}
          className={styles.search}
          placeholder={placeholder}
          onChange={this.handChange}
          onPressEnter={this.handPressEnter}
          prefix={<Icon type="icon-search" style={{ color: 'rgba(0,0,0,.25)' }} />}
        />
        <Button type="primary" onClick={this.handSearch}>
          搜索
        </Button>
        <Button onClick={this.handReset}>重置</Button>
      </div>
    );
  }

  // renderHeaderTheme() {
  //   const { placeholder } = this.state;
  //   return (
  //     <HeaderSearch
  //       placeholder={placeholder}
  //       className={styles.headerSearch}
  //       onChange={this.handHeaderChange}
  //       onPressEnter={this.handHeaderPressEnter}
  //     />
  //   );
  // }

  renderHeaderTheme() {
    const { placeholder, searchValue } = this.state;
    const closeSuffix = searchValue ? (
      <Icon type="icon-close" className={styles.close} onClick={this.handReset} />
    ) : (
      <span />
    );
    return (
      <Input.Search
        enterButton
        value={searchValue}
        suffix={closeSuffix}
        placeholder={placeholder}
        onSearch={this.handSearch}
        onChange={this.handChange}
      />
    );
  }

  renderContent() {
    const { theme } = this.state;
    switch (theme) {
      case 'header':
        return this.renderHeaderTheme();
      case "normal":
      default:
        return this.renderDefaultTheme();
    }
  }
}
export default SimpleSearchControl;
