import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { DateHelper } from '@mjcloud/utils';

class DatetimeReduce extends ValueReduceBase<any, any> {
  initialState(store, params) {
    const { initConfig, pageMode } = params;
    const { placeholder, format: _format, displayMode, disabledMode } = initConfig;
    const { title, needDefaultValue, theme = 'default', autoFocus, modifyMode } = initConfig;
    const { format, mode, showTime } = DateHelper.dateFormat2momentFormat(_format);
    let iconfont = 'icon-right';
    if (theme !== 'default') {
      iconfont = 'icon-caret-down';
    }

    let state = {
      title,
      theme,
      format,
      iconfont,
      showTime,
      text: '',
      autoFocus,
      tabIndex: -1,
      value: undefined,
      needDefaultValue,
      ddformat: _format,
      config: initConfig,
      configIsFetching: false,
      mode: showTime ? 'datetime' : mode,
      placeholder: placeholder || `请选择${title || ''}`,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    return state;
  }

  updateValue(store, params) {
    const state = super.updateValue(store, { ...params, noUpdate: true });
    // 通过判断_actionSourceSign存在与否来判断更新源
    if (state.value) {
      if (state.value['_actionSourceSign']) {
        return state;
      }
      state.text = DateHelper.format(state.value, state.format);
      state.value = DateHelper.parse(state.text);
      if (state.value) state.value['_actionSourceSign'] = true;
    } else {
      state.value = state.text = null as any;
    }

    return { ...state };
  }
}

export default new DatetimeReduce();
