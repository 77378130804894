import Store from '@mjcloud/redux';
import { IViewModelRow } from '@mjcloud/data-model';
import { SCROLLMINHEIGHT } from '../constant';
import { initialState } from '../button/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { PageAddress } from '@mjcloud/page/dist/typings';
import { OPERATIONWIDTH } from '../common/table/constant';
import { addRowButtonControlDefaults } from './controlDefaults';
import { IButtonState, IButtonConfig } from '../button/typings';
import ViewModelHelper, { IViewModelCollection } from '@mjcloud/data-model';
import TableReduceBase, { updateColumns } from '../common/table/reduce';
import DataSource, { ITreeDataSourceItem } from '@mjcloud/data-source-helper';
import { Key, TableRowSelection } from '../../components/VirtualizedTable/interface';
import { IDictionary, ControlDisplayModeEnum, PageModeEnum, DataStateEnum } from '@mjcloud/types';
import {
  ITreeEditState,
  IActiveCellParams,
  ITreeEditLoadedParams,
  ITreeEditInitialStateParams,
  ITreeEditUpdateCellSizeParams,
  ITreeEditUpdateSelectedRowsParams,
  ITreeEditUpdateExpandedRowKeysParams,
} from './typings';
import {
  convertDataToEntities,
  parseCheckedKeys,
  conductCheck,
} from '../../components/VirtualizedTable';

function formatAddRowButtonConfig(
  address: PageAddress,
  pageMode: PageModeEnum,
  initConfig?: IButtonConfig,
  addRowDisplayMode?: ControlDisplayModeEnum,
): IButtonState {
  const config: IButtonConfig = {} as any;
  if (!initConfig) initConfig = { displayMode: addRowDisplayMode } as any;
  if (initConfig) {
    for (const key in addRowButtonControlDefaults) {
      config[key] = initConfig[key] == null ? addRowButtonControlDefaults[key] : initConfig[key];
    }
  }
  return initialState(config, address, pageMode);
}

function disabledFn2Data(source: ITreeDataSourceItem[], disabledFn: (row: IDictionary) => boolean) {
  for (const row of source) {
    row._disabled = disabledFn(row);
    if (row._children && row._children.length > 0) {
      disabledFn2Data(row._children, disabledFn);
    }
  }
}

export class TreeEditReduce extends TableReduceBase<
  IViewModelCollection,
  IViewModelRow,
  ITreeEditState
> {
  private formatData(
    state: ITreeEditState,
    rows: IDictionary[],
    dataState: DataStateEnum,
    disabledFn: (row: IDictionary) => boolean = () => false,
  ) {
    let { config, defaultExpandLevel, dataSource, rowIdCount } = state;

    // initDatas 前先执行删除，清空与之关联的stores
    const rids = rows.map(r => r._rid).filter(r => !!r);
    if (rids.length > 0) {
      rids.forEach(rid => dataSource.removeRow(rid));
    }

    rows = rows.filter(r => !!r).map(({ _rid, ...row }) => row);
    dataSource.initDatas(rows, dataState);
    const originalDataSource = DataSource.formatTreeDataSource<ITreeDataSourceItem>(
      config.data,
      dataSource.toArray(true).map(r => r.toJSON()),
      row => false,
    );
    const treeData = DataSource.formatTreeData<any>(originalDataSource, defaultExpandLevel, '_rid');
    disabledFn2Data(treeData.dataSource, disabledFn);

    const { keyEntities } = convertDataToEntities(treeData.dataSource, '_children', {}, '_rid');

    return {
      rowIdCount,
      keyEntities,
      sourceDataSource: treeData.dataSource,
      expandLevel: treeData.expandLevel,
      expandLevel2Keys: treeData.expandLevel2Keys,
      expandedRowKeys: treeData.treeDefaultExpandedKeys,
      _dataSource: treeData.dataSource as IViewModelRow[],
    };
  }

  initialState(store: Store<ITreeEditState>, params: ITreeEditInitialStateParams) {
    const { initConfig, pageMode, address, onCell, onOperation, aggs, refs } = params,
      { dataModel = ViewModelHelper.createViewModelCollection() } = params,
      { mayCustom, modifyMode, enableRevise, displayMode, showSummary } = initConfig,
      { orderby, buttons, expandLevel, fastnessCount, autoGenerateId } = initConfig,
      { rowSelection, rowSelectionType, selectionStrictly, disabledControl } = initConfig,
      { addRowDisplayMode, addRowButton } = initConfig,
      items = initConfig.items.items,
      showOperation = buttons ? buttons.items.length > 0 : false,
      isModify = PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      // collapse = collapseMenu == null ? true : collapseMenu,
      collapse = false, // TODO: 针对操作列样式异常临时处理
      { columns, isParent, totalWidth } = this.getColumns(
        {
          refs,
          store,
          items,
          pageMode,
          collapse,
          isModify,
          showOrdinal: false,
          showOperation,
          fastnessCount,
          allowSort: false,
          // TODO: 针对操作列样式异常临时处理
          isCollapse: false,
          onCell,
          onOperation,
        },
        true,
      );
    let _rowSelection: TableRowSelection<IViewModelRow> | undefined = undefined;
    if (rowSelection) {
      _rowSelection = { type: rowSelectionType, strictly: !!selectionStrictly };
    }
    const state: ITreeEditState = {
      aggs,
      orderby,
      columns,
      collapse,
      totalWidth,
      expandLevel,
      tabIndex: -1,
      cellSize: {},
      rowIdCount: 0,
      showOperation,
      autoGenerateId,
      keyEntities: {},
      _dataSource: [],
      modify: isModify,
      isGridEdit: true,
      disabledControl,
      selectedRows: [],
      isFetching: false,
      config: initConfig,
      activeCellId: null,
      expandedRowKeys: [],
      sourceDataSource: [],
      halfSelectedRows: [],
      expandLevel2Keys: {},
      dataSource: dataModel,
      originalDataSource: [],
      entireColumns: columns,
      originalColumns: items,
      mayCustom: !!mayCustom,
      configIsFetching: false,
      showSummary: !!showSummary,
      rowSelection: _rowSelection,
      scrollHeight: SCROLLMINHEIGHT,
      operationWidth: OPERATIONWIDTH,
      defaultExpandLevel: expandLevel,
      addRowButton: formatAddRowButtonConfig(address, pageMode, addRowButton, addRowDisplayMode),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      // addRowDisplay: PageModeHelper.displayMode2boolean(pageMode, addRowDisplayMode),
    };

    state.columns = updateColumns(state.columns);
    return state;
  }

  loaded(store: Store<ITreeEditState>, params: ITreeEditLoadedParams) {
    const { dataModel, dataState = DataStateEnum.unchanged, disabledFn } = params;
    let state: ITreeEditState = { ...store.state, isFetching: false };
    if (dataModel) {
      state = {
        ...state,
        ...this.formatData(store.state, state.dataSource.toJSON(), dataState, disabledFn),
      };
    } else {
      const { rows = [] } = params.dataSource;
      state = {
        ...state,
        ...this.formatData(store.state, rows, dataState, disabledFn),
      };
    }

    return state;
  }

  addClickAfter(store: Store<ITreeEditState>, params) {
    const state = { ...store.state };
    state.addRowButton.loading = true;
    return state;
  }

  addClickDone(store: Store<ITreeEditState>, params: {}) {
    const { addRowButton } = store.state;
    addRowButton.loading = false;
    return { ...store.state, addRowButton };
  }

  updateAddBtnLoad(store: Store<ITreeEditState>, params: { loading: boolean }) {
    const state = { ...store.state };
    state.addRowButton.loading = params.loading;
    return state;
  }

  batchAddRow(store: Store<ITreeEditState>, params) {
    const { addRowButton } = store.state,
      rows = [...store.state.dataSource.toJSON(), ...params.rows];
    addRowButton.loading = false;
    return {
      ...store.state,
      addRowButton,
      ...this.formatData(store.state, rows, DataStateEnum.added, params.disabledFn),
    };
  }

  deleteRow(store: Store<ITreeEditState>, params: { rid: number }) {
    store.state.dataSource.removeRow(params.rid);
    return {
      ...store.state,
      ...this.formatData(store.state, store.state.dataSource.toJSON(), DataStateEnum.added),
    };
  }

  deleteAllRow(store: Store<ITreeEditState>, params: { isKeepDataState: boolean }) {
    const { dataSource } = store.state;
    if (params.isKeepDataState) {
      const list = dataSource.toArray(true);
      for (const item of list) {
        dataSource.removeRow(item._rid);
      }
    } else {
      dataSource.clear();
    }

    return {
      ...store.state,
      _dataSource: [],
    };
  }

  activeCell(store: Store<ITreeEditState>, params: IActiveCellParams) {
    const { cellId } = params;
    const state = store.state;
    state.activeCellId = cellId;
    return state;
  }

  updateCellSize(store: Store<ITreeEditState>, params: ITreeEditUpdateCellSizeParams) {
    const state = store.state,
      { rowId, size } = params;
    const _size = state.cellSize[rowId];
    params.isUpdate = true;
    if (_size) {
      const { width, height } = size,
        { width: oldwidth, height: oldheight } = _size;
      if (oldwidth === width && height === oldheight) {
        params.isUpdate = false;
      }
    }
    state.cellSize[rowId] = size;
    return state;
  }

  updateModifyMode(store: Store<ITreeEditState>, params) {
    let state: ITreeEditState = store.state,
      { showOperation } = state;
    if (showOperation) {
      state = this.updateColumns(store, {
        cells: [{ cellId: '_operation', display: params.modify }],
      });
    }
    state.modify = params.modify;
    return { ...state };
  }

  updateSelectedRows(store: Store<ITreeEditState>, params: ITreeEditUpdateSelectedRowsParams) {
    const state: ITreeEditState = store.state;

    const { keyEntities, rowSelection } = state;
    const { row, checked, selectedRowKeys = [] } = params;

    let checkedKeys: Key[] = [],
      halfCheckedKeys: Key[] = [];
    if (rowSelection && rowSelection.type === 'radio') {
      checkedKeys = [...selectedRowKeys];
    } else {
      if (row) {
        // Always fill first
        const conductKeys = conductCheck(selectedRowKeys, true, keyEntities);
        ({ checkedKeys, halfCheckedKeys } = conductKeys);

        // If remove, we do it again to correction
        if (!checked) {
          const keySet = new Set(checkedKeys);
          keySet.delete(row._rid);
          ({ checkedKeys, halfCheckedKeys } = conductCheck(
            Array.from(keySet),
            { checked: false, halfCheckedKeys },
            keyEntities,
          ));
        }
      } else {
        const checkedKeyEntity = parseCheckedKeys(selectedRowKeys);
        if (checkedKeyEntity) {
          const conductKeys = conductCheck(checkedKeyEntity.checkedKeys, true, keyEntities);
          ({ checkedKeys, halfCheckedKeys } = conductKeys);
        }
      }
    }

    state.selectedRows = checkedKeys.map(k => keyEntities[k].node) as any[];
    state.halfSelectedRows = halfCheckedKeys.map(k => keyEntities[k].node) as any[];
    return { ...state };
  }

  updateExpandedRowKeys(
    store: Store<ITreeEditState>,
    params: ITreeEditUpdateExpandedRowKeysParams,
  ) {
    const state: ITreeEditState = store.state;
    state.expandLevel = params.expandLevel;
    state.expandedRowKeys = params.expandedRowKeys;
    return { ...state };
  }
}

export default new TreeEditReduce();
