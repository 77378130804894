import Store, { IReduce } from '@mjcloud/redux';
import { ExceptionHelper } from '@mjcloud/exception';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { RequiredTypeEnum } from '@mjcloud/types';
import { IFormItemState, IFormItemInitialStateParams, IFormItemValidErrorParams } from './typings';

class FormItemReduce implements IReduce<IFormItemState> {
  initialState(store: Store<IFormItemState>, params: IFormItemInitialStateParams) {
    const { initConfig, pageMode, children, colorCommandFn } = params;
    const { field, requiredType = RequiredTypeEnum.normal, modifyMode, tip, tipIcon } = initConfig,
      { message, max, min, validationType, whitespace, len, enums, pattern } = initConfig,
      id = initConfig.id || '',
      colspan = initConfig.colspan == null ? 1 : initConfig.colspan,
      index = initConfig.index == null ? 0 : initConfig.index,
      title = initConfig.title || '',
      showTitle = initConfig.title ? true : false;
    let _enum: string | string[] | undefined, _pattern: RegExp | undefined;
    if (enums) {
      try {
        _enum = JSON.parse(enums.replace(/'/g, '"'));
      } catch (error) {
        const ex = ExceptionHelper.jsonParseException(
          `FormItemReduce ${enums} 不是json字符串`,
          error.stack,
        );
        ExceptionHelper.dispose(ex);
      }
    }
    if (pattern) {
      try {
        _pattern = new RegExp(JSON.parse(pattern.replace(/'/g, '"')));
      } catch (error) {
        const ex = ExceptionHelper.jsonParseException(
          `FormItemReduce ${pattern} 不是json字符串`,
          error.stack,
        );
        ExceptionHelper.dispose(ex);
      }
    }
    let state: IFormItemState = {
      id,
      max,
      min,
      len,
      tip,
      index,
      title,
      tipIcon,
      colspan,
      message,
      children,
      showTitle,
      whitespace,
      enum: _enum,
      requiredType,
      tabIndex: -1,
      colorCommandFn,
      help: undefined,
      pattern: _pattern,
      field: field || id,
      config: initConfig,
      type: validationType,
      configIsFetching: false,
      validateStatus: undefined,
      nodeName: initConfig.nodeName,
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, initConfig.displayMode),
    };
    return state;
  }

  updateColor(store: Store<IFormItemState>, params) {
    const { color } = params;
    if (store.state.color === color) return store.state;
    return { ...store.state, color };
  }

  updateRequiredType(store: Store<IFormItemState>, params: { requiredType: RequiredTypeEnum }) {
    if (params.requiredType === store.state.requiredType) {
      return store.state;
    }
    const state: IFormItemState = { ...store.state, requiredType: params.requiredType };
    if (params.requiredType !== RequiredTypeEnum.required) {
      state.help = undefined;
      state.validateStatus = undefined;
    }
    return state;
  }

  updateReadonly(store: Store<IFormItemState>, params: { readonly: boolean }) {
    if (params.readonly === store.state.readonly) {
      return store.state;
    }
    const state: IFormItemState = { ...store.state, readonly: params.readonly };
    return state;
  }

  updateDisplay(store: Store<IFormItemState>, params: { display: boolean }) {
    if (params.display === store.state.display) {
      return store.state;
    }
    const state: IFormItemState = { ...store.state, display: params.display };
    return state;
  }

  updateTitle(store: Store<IFormItemState>, params: { title: string }) {
    if (params.title === store.state.title) {
      return store.state;
    }
    const state: IFormItemState = { ...store.state, title: params.title };
    return state;
  }

  updateTip(store: Store<IFormItemState>, params) {
    const { tip, tipIcon } = params;
    if (tip === store.state.tip && tipIcon === store.state.tipIcon) {
      return store.state;
    }
    return { ...store.state, tip, tipIcon };
  }

  validError(store: Store<IFormItemState>, params: IFormItemValidErrorParams) {
    const { errorHelp, actionSourceSign } = params;
    const state: IFormItemState = {
      ...store.state,
      validateStatus: 'error',
      help: errorHelp,
      actionSourceSign,
    };
    return state;
  }

  validSuccess(store: Store<IFormItemState>, params: {}) {
    let state: IFormItemState = store.state;
    if (state.validateStatus === undefined) return state;
    state = { ...state, validateStatus: undefined, help: undefined, actionSourceSign: null };
    return state;
  }
}

export default new FormItemReduce();
