import React from 'react';
import { Radio } from 'antd';
import classNames from 'classnames';
import ControlBase from '../../base';
import { IRadioButtonsState } from '../typings';
import { RadioChangeEvent } from 'antd/lib/radio';
import { IValueControlUpdateValueParams } from '@mjcloud/instance/dist/valueInstanceBase';

import styles from './index.less';

class RadioButtonsControl extends ControlBase<IRadioButtonsState> {
  private handleChange = (event: RadioChangeEvent) => {
    const params: IValueControlUpdateValueParams = {
      value: event.target.value,
      text: '',
      actionSourceSign: this.instance.store,
    };
    for (const item of this.state.dataSource) {
      if (item._value === params.value) {
        params.text = item._text;
      }
    }
    this.instance.store.dispatch<IValueControlUpdateValueParams>('updateValue', params);
  };

  private handleChange2Index = (activeIndex: number) => {
    this.instance.store.dispatch('updateActiveIndex', { activeIndex });
  };

  renderContent() {
    if (this.state.readonly) return <>{this.state.text || ''}</>;
    if (this.state.errorMessage) return <>{this.state.errorMessage}</>;
    const { dataSource, value, theme, activeIndex } = this.state;
    var tabsIndex = activeIndex;
    if(tabsIndex == -1){
        tabsIndex = 0
    }
    if (theme === 'normal') {
      return (
        <Radio.Group id={this.props.id} onChange={this.handleChange} value={value}>
          {dataSource.map(({ _text, _value, _disabled }) => {
            return (
              <Radio.Button key={_value} value={_value} disabled={_disabled}>
                {_text}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      );
    }
   
    if(theme === 'group'){
      return (
        <Radio.Group id={this.props.id} onChange={this.handleChange} value={value} buttonStyle="solid">
          {dataSource.map(({ _text, _value, _disabled }) => {
            return (
              <Radio.Button key={_value} value={_value} disabled={_disabled}>
                {_text}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      );
    }
   

    if(theme === 'tabs'){
      
      return (
        <div className={styles.radioContainer}>
        <div
          className={classNames({
            [styles.radioButtons]: true,
            // [styles.radioDefaultTheme]: theme === 'default',
            [styles.radioTabs]: theme === 'tabs',
          })}
        >
          {dataSource.map((row, index) => {
            const itemClassName = classNames({
              [styles.radioButtonsItem]: true,
              [styles.radioActive]: index === tabsIndex,
            });
            return (
              <div
                key={row._value}
                className={itemClassName}
                onClick={() => this.handleChange2Index(index)}
              >
                {row._text}
              </div>
            );
          })}
        </div>
      </div>
      )
    }
    if(theme === 'group'){
      return (
        <Radio.Group id={this.props.id} onChange={this.handleChange} value={value} buttonStyle="solid">
          {dataSource.map(({ _text, _value, _disabled }) => {
            return (
              <Radio.Button key={_value} value={_value} disabled={_disabled}>
                {_text}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      );
    }

    if(theme === 'tabs'){
      
      return (
        <div className={styles.radioContainer}>
        <div
          className={classNames({
            [styles.radioButtons]: true,
            // [styles.radioDefaultTheme]: theme === 'default',
            [styles.radioTabs]: theme === 'tabs',
          })}
        >
          {dataSource.map((row, index) => {
            const itemClassName = classNames({
              [styles.radioButtonsItem]: true,
              [styles.radioActive]: index === tabsIndex,
            });
            return (
              <div
                key={row._value}
                className={itemClassName}
                onClick={() => this.handleChange2Index(index)}
              >
                {row._text}
              </div>
            );
          })}
        </div>
      </div>
      )
    }
    return (
      <div className={styles.radioContainer}>
        <div
          className={classNames({
            [styles.radioButtons]: true,
            [styles.radioDefaultTheme]: theme === 'default',
            [styles.radioText]: theme === 'text',
          })}
        >
          {dataSource.map((row, index) => {
            const itemClassName = classNames({
              [styles.radioButtonsItem]: true,
              [styles.radioActive]: index === activeIndex,
            });
            return (
              <div
                key={row._value}
                className={itemClassName}
                onClick={() => this.handleChange2Index(index)}
              >
                {row._text}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default RadioButtonsControl;
