import ExtendStoreBase from '../base';
import { IDictionary } from '@mjcloud/types';
import globalData from '@mjcloud/global-data';
import PageHelper from '@mjcloud/page-helper';
import { ObjectHelper } from '@mjcloud/utils';
import { ExceptionHelper } from '@mjcloud/exception';
import { ActionAfterEventArg } from '@mjcloud/redux';
import DataSource, { ILoadDataParams } from '@mjcloud/data-source-helper';
import {
  IDataInstanceBase,
  IDataState,
  DataControlActionType,
  IDataControlStartLoadParams,
} from '@mjcloud/instance/dist/dataInstanceBase';

export default abstract class DataExtendStoreBase<
  TInstance extends IDataInstanceBase = IDataInstanceBase
> extends ExtendStoreBase<TInstance> {
  private isFirst = true;
  private isOneData = false;
  private requestId = 0;
  private preRequestId: number | null = null;
  private cancels: IDictionary<boolean> = {};

  /**
   * 数据源加载完成是否需要通知页面，true为需要
   */
  protected abstract isNeedNotificationPage: boolean;

  public async handleStartLoadAfter(
    e: ActionAfterEventArg<IDataState, DataControlActionType, IDataControlStartLoadParams>,
  ) {
    let rowLength = 0;
    const begin = Date.now(),
      dataMode = this.instance.getDataMode(),
      { isFetching, config } = e.newState.state,
      data = config ? config.data : undefined,
      urlParams = {
        id: data && data.id,
        address: PageHelper.formatPagePath(this.instance.page.address),
      },
      url = 'customize/datasource/' + dataMode + ObjectHelper.params2search(urlParams);

    if (isFetching && this.preRequestId) {
      this.cancels[this.preRequestId] = true;
    }

    const requestId = ++this.requestId;
    // console.log('handleStartLoadAfter-start', e.params.id, this.preRequestId, requestId);
    this.preRequestId = requestId;
    this.instance.page.increaseLock(this.instance.id);
    this.instance.__loadStart();
    try {
      // TODO: e.newState.state.config 可能为 undefined，在GridView中，会存在select未创建而先请求数据源的Bug
      const loadDataParams: ILoadDataParams = {
        data,
        dataMode,
        isFirst: this.isFirst,
        page: this.instance.page,
        params: e.params,
      };
      if (this.instance.workbenchPart) loadDataParams.workbenchPart = this.instance.workbenchPart;
      this.isFirst = false;
      const result = await DataSource.loadData(loadDataParams);
      if (result && result.rows && result.rows.length === 1) {
        this.isOneData = true;
        rowLength = result.rows.length;
      }
      // console.log('handleStartLoadAfter-load', e.params.id, this.preRequestId, requestId);
      if (this.cancels && this.cancels[requestId]) {
        return;
      }
      // console.log('handleStartLoadAfter-real', e.params.id, this.preRequestId, requestId);
      if (this.preRequestId !== requestId && globalData.__bl) {
        // 添加监控，以验证数据请求成功但是数据混乱不对的异常
        globalData.__bl.api(
          url,
          false,
          Date.now() - begin,
          'ERROR',
          `当前返回数据无效: preRequestId=${this.preRequestId}, requestId=${requestId}`,
        );
      } else if (this.isOneData && globalData.__bl) {
        globalData.__bl.api(url, true, Date.now() - begin, '820', `len(${rowLength})`);
      }
      e.newState.dispatch('loaded', {
        ...e.params,
        dataSource: result,
      });
    } catch (reason) {
      this.isFirst = false;
      e.newState.dispatch('loadError', {
        ...e.params,
        errorMessage: '数据加载错误',
      });
      reason.message = `控件 ${this.instance.id} 数据加载错误: ${reason.message}`;
      ExceptionHelper.dispose(reason);
    } finally {
      this.instance.page.decreaseLock(this.instance.id);
      this.instance.__loadComplete();
    }
  }

  public handleLoadedAfter(e: ActionAfterEventArg<IDataState>) {
    this.instance.eventManager.trigger('ready', e.newState.state);
  }

  public handleLoadErrorAfter(e: ActionAfterEventArg<IDataState>) {}
}
