import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { InstanceBase } from '@mjcloud/instance';
import { ActionBeforeEventArg, ActionAfterEventArg } from '@mjcloud/redux';
import { ExtendStoreBase } from '@mjcloud/extend-store';
import { ISystemPageInitialStateParams, ISystemPageState, SystemPageActionType, SystemPageConfig } from './typings';

class SystemPageExtendStore extends ExtendStoreBase<SystemPage> {
  handleInitialStateBefore(e: ActionBeforeEventArg<ISystemPageInitialStateParams>) {

  }

  handleCloseSystemPageAfter(e: ActionAfterEventArg<ISystemPageState>) {
    const { isDrawer, afterClose } = e.newState.state;
    if (isDrawer && afterClose) afterClose();
  }
}

export default class SystemPage extends InstanceBase<ISystemPageState, string, SystemPageActionType> {
  __createStore() {
    return new Store<ISystemPageState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new SystemPageExtendStore(this),
    });
  }

  initialState(initConfig: SystemPageConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  close(cb: () => void) {
    this.store.dispatch('closeSystemPage', { cb });
  }
}
