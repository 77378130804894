import React from 'react';
import Icon from '@mjcloud/icon';
import { Upload, Button } from 'antd';
import ControlBase from '../../base';
import { DelayCallback } from '@mjcloud/utils';
import { ContentUtils } from 'braft-utils';
import BraftEditor, { EditorState, ControlType, ExtendControlType } from 'braft-editor';
import {
  IRichTextboxState,
  IRichTextboxUpdateValueParams,
  IRichTextboxUpdateEditorStateParams,
} from '../typings';

import 'braft-editor/dist/index.css';
import 'braft-editor/dist/output.css';
import styles from './index.less';

class RichTextboxControl extends ControlBase<IRichTextboxState> {
  private controls: ControlType[];
  private _delayCallback: DelayCallback;
  constructor(props: any) {
    super(props);
    this._delayCallback = new DelayCallback(this.handEditorChangeCallback.bind(this));
    const media: ExtendControlType = {
      key: 'custom-media',
      type: 'component',
      component: (
        <Upload accept="image/*" showUploadList={false} customRequest={this.uploadHandler}>
          <Button className="control-item button upload-button" data-title="插入图片">
            <Icon type="icon-image-fill" />
          </Button>
        </Upload>
      ),
    };
    this.controls = ['undo', 'redo', 'separator'];
    this.controls = this.controls.concat([
      'font-size',
      'line-height',
      'letter-spacing',
      'separator',
    ]);
    this.controls = this.controls.concat([
      'text-color',
      'bold',
      'italic',
      'underline',
      'strike-through',
      'separator',
    ]);
    this.controls = this.controls.concat([
      'superscript',
      'subscript',
      'remove-styles',
      'emoji',
      'separator',
      'text-indent',
      'text-align',
      'separator',
    ]);
    this.controls = this.controls.concat([
      'headings',
      'list-ul',
      'list-ol',
      'blockquote',
      'code',
      'separator',
    ]);
    this.controls = this.controls.concat(['media', 'link', 'hr', 'separator']);
    this.controls = this.controls.concat(['clear', 'separator']);
    this.controls = this.controls.concat(['fullscreen']);
  }

  private handEditorChangeCallback(editorState: EditorState) {
    const value = editorState.toHTML();
    this.instance.store.dispatch<IRichTextboxUpdateValueParams>('updateValue', {
      actionSourceSign: this.instance.store,
      value,
      text: value,
    });
  }

  private uploadHandler = param => {
    if (!param.file) {
      return false;
    }

    this.instance.store.dispatch<IRichTextboxUpdateEditorStateParams>('updateEditorState', {
      editorState: ContentUtils.insertMedias(this.state.editorState, [
        {
          type: 'IMAGE',
          id: 'jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ',
          name: 'jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
      ]),
    });
  };

  private handleEditorChange = (editorState: EditorState) => {
    // this.setState({ editorState }, () => this._delayCallback.execute(editorState));
    this.instance.store.dispatch<IRichTextboxUpdateEditorStateParams>('updateEditorState', {
      editorState,
    });
    this._delayCallback.execute(editorState);
  };

  renderContent() {
    const { id } = this.props;
    if (this.state.readonly)
      return (
        <div
          className="braft-output-content"
          dangerouslySetInnerHTML={{ __html: this.state.text || '' }}
        />
      );
    const { placeholder, editorState } = this.state;
    return (
      <div className={styles.embedEditor}>
        <BraftEditor
          id={id}
          value={editorState}
          placeholder={placeholder}
          controls={this.controls}
          onChange={this.handleEditorChange}
        />
      </div>
    );
  }
}

export default RichTextboxControl;
