import { ContainerDataExtendStoreBase } from '@mjcloud/extend-store';
import { ValueInstanceBase } from '@mjcloud/instance';
import { ISigninAgg } from '@mjcloud/data-source-helper';
import { ExceptionHelper } from '@mjcloud/exception';
import ControllerHelper from '@mjcloud/controller';
import { DataStateEnum } from '@mjcloud/types';
import { NumberHelper } from '@mjcloud/utils';
import ListEdit from '.';
import {
  RowValueChangeArg,
  RowDataStateChangeArg,
  CollectionDataStateChangeArg,
} from '@mjcloud/data-model';

class ListEditItemExtendStore {
  constructor(private instance, private controlInstance, private column, private rowId) {}

  handleInitialStateAfter(e) {
    const { dataSource } = this.instance.store.state,
      { textFieldName } = e.newState.state,
      item = dataSource[this.rowId].toJSON();
    // TODO: 修复list-edit中下拉框text值不对的问题，注意，该写法并没有完成
    let value, text;
    value = text = item[this.column.field];
    if (textFieldName) text = item[textFieldName];
    this.controlInstance.store.dispatch('updateValue', {
      value,
      text,
      actionSourceSign: this.instance,
    });
    if (this.controlInstance && this.controlInstance.refresh) {
      // TODO: 简单处理下拉框没有触发刷新的问题，注意，该操作为非正常写法
      this.controlInstance.refresh();
    }
  }

  handleStartLoadBefore(e) {
    if (this.rowId) {
      if (!e.params.data) e.params.data = {};
      e.params.data.row = this.instance.dataModel[this.rowId];
    }
  }

  handleUpdateValueBefore(e) {
    if (this.rowId) {
      e.params.row = this.instance.dataModel[this.rowId];
    }
  }

  handleUpdateValueAfter(e) {
    const { actionSourceSign } = e.params,
      { value, text } = e.newState.state;
    if (actionSourceSign === this.instance) return;
    const field = this.column.field;
    let updateData = { [field]: value };
    const textFieldName = e.newState.state.textFieldName;
    // _isObject 是针对区间控件做的多值处理
    if (value && typeof value === 'object' && value._isObject) {
      const { _isObject, ...val } = value;
      updateData = { ...updateData, ...val };
    }
    if (textFieldName) {
      updateData[textFieldName] = text;
    }
    this.instance.dataModel[this.rowId].update(updateData, this.instance.parent);
  }
}

export default class ListEditExtendStore extends ContainerDataExtendStoreBase<ListEdit> {
  protected isNeedNotificationPage = true;

  handleInitialStateAfter(e) {
    super.handleInitialStateAfter(e);
    const { columns } = e.newState.state;
    const properties: any[] = [],
      aggs: ISigninAgg[] = [];
    let aggPropertieName = '';
    e.newState.state.dataSource.bind('rowValueChange', this.handDataModelRowValueChange.bind(this));
    e.newState.state.dataSource.bind(
      'rowDataStateChange',
      this.handDataModelRowDataStateChangeEvent.bind(this),
    );
    e.newState.state.dataSource.bind(
      'dataStateChange',
      this.handDataModelDataStateChangeEvent.bind(this),
    );
    for (const column of columns) {
      if (column.control) {
        const { nodeName, summary } = column.control;
        if (nodeName === 'number' && summary) {
          const { decimal = 2, isMain = true } = summary;
          if (isMain) {
            aggPropertieName = column.id;
          }
          aggs.push({ name: column.id, aggType: 'Sum' });
          properties.push({
            propertyName: column.id,
            aggType: 1,
            decimal,
          });
        }
      }
    }
    if (properties.length > 0) {
      this.instance.dataModel.addAggProperties(properties);
      this.instance.dataModel.bind('aggValueChange', this.handleAggValueChange.bind(this));
    }
    if (aggPropertieName) {
      e.newState.dispatch('updateAggPropertieName', { aggs, aggPropertieName });
    }
  }

  handleAggValueChange(e) {
    if (this.instance.eventManager.getHandlerCount('aggValueChange') > 0) {
      this.instance.eventManager.trigger('aggValueChange', e.data);
    }
    // TODO format 不存在e.data中
    let { propertyName, format = '#,##0.00', value: _value } = e.data,
      { aggPropertieName } = this.instance.store.state;
    if (propertyName === aggPropertieName) {
      let value = NumberHelper.format(_value, format);
      this.instance.store.dispatch('updateAggPropertieValue', { value });
    }
  }

  handleLoadedBefore(e) {
    let { rows } = e.params.dataSource,
      { columns, modifyMode, rowIdCount } = this.instance.store.state;
    rows = rows.map(item => {
      item._rid = ++rowIdCount;
      return item;
    });
    for (const item of rows) {
      for (const column of columns) {
        if (modifyMode != null) column.modifyMode = modifyMode;
        const controlInstance = this.instance.createControl(item._rid, column);
        if (controlInstance) {
          controlInstance.store.bindExtendStore(
            new ListEditItemExtendStore(this.instance, controlInstance, column, item._rid),
          );
          this.instance.__registerControl(column.id, item._rid, controlInstance);
        }
      }
    }
    e.params.rowIdCount = rowIdCount;
  }

  handleBatchAddRowBefore(e) {
    let { rows } = e.params,
      { columns, modifyMode, rowIdCount } = this.instance.store.state;
    rows = rows.map(item => {
      item._rid = ++rowIdCount;
      return item;
    });
    for (const item of rows) {
      for (const column of columns) {
        if (modifyMode != null) column.modifyMode = modifyMode;
        const controlInstance = this.instance.createControl(item._rid, column);
        if (controlInstance) {
          controlInstance.store.bindExtendStore(
            new ListEditItemExtendStore(this.instance, controlInstance, column, item._rid),
          );
          this.instance.__registerControl(column.id, item._rid, controlInstance);
        }
      }
    }
    e.params.rowIdCount = rowIdCount;
  }

  async handleAddButtonClickAfterAfter(e) {
    const { addRowButton } = e.newState.state,
      { tip, title, controllerId } = addRowButton;
    const exclusiveLockId = this.instance.page.applyExclusiveLock(tip || `正在执行${title}操作...`);
    if (exclusiveLockId === 0) {
      e.newState.dispatch('addButtonClickDone', {});
      return;
    }
    let params = { ...e.params },
      before = async data => {
        if (this.instance.eventManager.getHandlerCount('onBeforeClick') > 0) {
          await this.instance.eventManager.trigger('onBeforeClick', data);
        }
        return data;
      },
      after = async data => {
        if (this.instance.eventManager.getHandlerCount('onAfterClick') > 0) {
          await this.instance.eventManager.trigger('onAfterClick', data);
        }
        return data;
      };
    try {
      if (controllerId) {
        this.instance.page['__backNoMonitor'] = true;
        await ControllerHelper.execute({
          sender: this.instance,
          controllerId,
          data: params,
          before,
          after,
        });
      } else {
        await before(params);
        this.instance.store.dispatch('batchAddRow', {
          rows: [{ _isBtnAdd: true }],
          isBtnClick: true,
        });
        await after(params);
      }

      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      e.newState.dispatch('addButtonClickDone', {});
    } catch (error) {
      e.newState.dispatch('addButtonClickDone', {});
      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      ExceptionHelper.dispose(error);
    }
  }

  private handDataModelRowValueChange(e: RowValueChangeArg) {
    if (e.eventSourceSign === this.instance) return;
    let text: string | undefined, textFieldName: string | undefined;
    const { row, values } = e.data;
    for (const key in values) {
      const value = values[key];
      if (value !== undefined) {
        text = value;
        const cell = this.instance.findCellControl2rid(row._rid, key);
        if (cell instanceof ValueInstanceBase) {
          textFieldName = cell.store.state['textFieldName'];
          if (textFieldName) text = values[textFieldName];
          cell.setValue(value, text);
        }
      }
    }
    const eventManager = this.instance.eventManager;
    if (eventManager.getHandlerCount('rowChange') > 0) {
      eventManager.trigger('rowChange', { ...e.data });
    }
  }

  private handDataModelRowDataStateChangeEvent(e: RowDataStateChangeArg) {
    if (e.eventSourceSign === this) return;
    switch (e.data.dataState) {
      case DataStateEnum.added:
        break;
      case DataStateEnum.deleted:
        this.instance.__deleteRow2cells(e.data.row._rid);
        break;
      case DataStateEnum.unchanged:
        return;
    }
    const eventManager = this.instance.eventManager;
    if (eventManager.getHandlerCount('rowChange') > 0) {
      eventManager.trigger('rowChange', { ...e.data });
    }
  }

  private handDataModelDataStateChangeEvent(e: CollectionDataStateChangeArg) {
    // if (e.data.isChange) {
    //   this.instance.page.controlDataUpdated(this.instance.id);
    // }
  }
}
