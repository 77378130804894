import ViewModelHelper from '@mjcloud/data-model'
import { ExceptionHelper } from '@mjcloud/exception'
import { ExtendStoreBase } from '@mjcloud/extend-store'
import { InstanceBase } from '@mjcloud/instance'
import JsApiHelper from '@mjcloud/jsapi'
import PageHelper from '@mjcloud/page-helper'
import Store, { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux'
import Service, { IFileInfo } from '@mjcloud/service'
import { EventListening } from '@mjcloud/utils'
import Reduce from './reduce'
import {
  FileManagerActionType,
  FileManagerEventType,
  IFileManagerConfig,
  IFileManagerConfigStartLoadParams,
  IFileManagerState,
} from './typings'

class FileManagerExtendStore extends ExtendStoreBase<FileManager> {
  private isFirstLoad = false
  private isExistDraft = false

  private dataModel = ViewModelHelper.createViewModelCollection()
  constructor(instance: FileManager) {
    super(instance)
    instance.page.eventManager.add('done', this.pageDone.bind(this))
  }

  private async pageDone() {
    this.instance.refresh()
  }

  private async _handleStartLoadAfter(
    e: ActionAfterEventArg<IFileManagerState, FileManagerActionType, {}>,
  ) {
    try {
      const { businessId, businessType, listType } = e.newState.state
      const _businessId = await this.instance.__getBusinessId(businessId)
      let dataSource: IFileInfo[] | undefined = []
      if (listType === 'group') {
        dataSource = await Service.getFileList2Group(_businessId || '0', businessType)
      } else {
        _businessId ? (dataSource = await Service.getFileList(_businessId, businessType)) : []
      }
      if (dataSource) {
        e.newState.dispatch('loaded', { ...e.params, dataSource })
      } else {
        throw new Error('获取数据源失败')
      }
    } catch (error) {
      e.newState.dispatch('loadError', { ...e.params, errorMessage: '数据加载错误' })
      ExceptionHelper.dispose(error)
    } finally {
    }
  }

  private _updateDataSource(e: ActionAfterEventArg<IFileManagerState>) {
    const { listType, fileList, dataSource } = e.newState.state
    this.dataModel.initDatas([])
    const datas: any[] = listType === 'group' ? dataSource : fileList
    datas.forEach(row => {
      delete row._viewModelType
      this.dataModel.addRow(row)
    })
  }

  handleInitialStateBefore(e: ActionBeforeEventArg<any>) {
    const key = this.instance.id,
      pageModel = this.instance.page.dataModel
    if (pageModel[key]) {
      this.dataModel = pageModel[key]
    }
    this.isExistDraft = !!pageModel[key]
  }

  async handleStartLoadAfter(e: ActionAfterEventArg<IFileManagerState, FileManagerActionType, {}>) {
    const key = this.instance.id,
      pageModel = this.instance.page.dataModel

    if (this.isExistDraft) {
      if (!this.isFirstLoad) {
        this.isFirstLoad = true
        e.newState.dispatch('loaded', { dataSource: this.dataModel.toJSON() })
      } else {
        await this._handleStartLoadAfter(e)
      }
    } else {
      if (!pageModel[key]) pageModel[key] = this.dataModel
      await this._handleStartLoadAfter(e)
    }
  }

  handleLoadedAfter(e: ActionAfterEventArg<IFileManagerState>) {
    const { listType, fileList, dataSource } = e.newState.state

    let datas: any[] = listType === 'group' ? dataSource : fileList
    datas = datas.map(({ _viewModelType, ...row }) => {
      return row
    })
    this.dataModel.initDatas(datas)
  }

  handleUpdateFileListAfter(e: ActionAfterEventArg<IFileManagerState>) {
    this._updateDataSource(e)

    if (this.instance.eventManager.getHandlerCount('onValueChange') > 0) {
      this.instance.eventManager.trigger('onValueChange', e.newState.state)
    }
  }
  handleUpdateFileList2GroupAfter(e: ActionAfterEventArg<IFileManagerState>) {
    this._updateDataSource(e)
  }
  handleAddFile2GroupAfter(e: ActionAfterEventArg<IFileManagerState>) {
    this._updateDataSource(e)
  }
  handleRemoveFile2GroupAfter(e: ActionAfterEventArg<IFileManagerState>) {
    this._updateDataSource(e)
  }

  async handlePreviewFileAfter(
    e: ActionAfterEventArg<IFileManagerState, FileManagerActionType, any>,
  ) {
    try {
      const { file } = e.params
      JsApiHelper.previewFile(file)
      this.instance.store.dispatch('previewFileDone', {})
    } catch (error) {
      console.error(error)
      ExceptionHelper.dispose(error)
      this.instance.store.dispatch('previewFileDone', {})
    }
  }

  async handleCustomClickAfter(
    e: ActionAfterEventArg<IFileManagerState, FileManagerActionType, any>,
  ) {
    if (this.instance.eventManager.getHandlerCount('onCustomClick') > 0) {
      this.instance.eventManager.trigger('onCustomClick', {
        data: e.params.record,
        type: e.params.type,
      })
    }
  }
}

class FileManager extends InstanceBase<
  IFileManagerState,
  FileManagerEventType,
  FileManagerActionType
> {
  __createStore() {
    return new Store<IFileManagerState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new FileManagerExtendStore(this),
    })
  }

  initialState(initConfig: IFileManagerConfig) {
    this.store.dispatch<IFileManagerConfigStartLoadParams>('configStartLoad', {
      initConfig,
    })
  }

  async __getBusinessId(businessId: string) {
    let _businessId: string | null | undefined = null
    if (businessId) {
      const fn = PageHelper.createPageScriptFunction(this.page, businessId, true)
      _businessId = await fn(EventListening.getHandlerArg(this, {}))
    } else {
      _businessId = this.page.getParam('id')
    }
    if (!_businessId) _businessId = null
    return _businessId
  }

  async refresh() {
    const { businessId, businessType } = this.store.state,
      _businessId = await this.__getBusinessId(businessId)
    this.store.dispatch('startLoad', {
      uploadProps: await Service.getUploadInfo(_businessId || '', businessType),
    })
  }

  /**
   * 获取当前文件列表Id集合
   */
  getData(isValid: boolean = true) {
    const { fileList, listType, dataSource, loading } = this.store.state
    if (isValid) {
      if (loading) throw ExceptionHelper.businessException('还有文件正在上传中，请稍后保存！')
      let errorMsg = ''
      const len = dataSource
        .map(({ fileId, groupName, isRequired }) => {
          if (!fileId && isRequired) {
            if (!errorMsg) errorMsg = `${groupName}的文档不能为空`
            return true
          }
          return false
        })
        .filter(file => file).length
      if (len > 0) {
        this.store.dispatch('updateFileList2Status', {})
        throw ExceptionHelper.businessException(errorMsg)
      }
    }
    if (listType === 'group') {
      return dataSource
        .map(({ fileId, groupId = 0 }) => {
          if (fileId) {
            return { fileId: fileId, groupId: groupId }
          }
          return undefined
        })
        .filter(file => !!file)
    }
    return fileList
      .map(file => {
        if (file.status === 'done') {
          return { fileId: file.uid, groupId: 0 }
          // return file.uid;
        }
        return undefined
      })
      .filter(file => !!file)
  }

  /**
   * 获取当前文件列表集合
   */
  getFileList() {
    const { fileList, dataSource, listType } = this.store.state
    if (listType === 'group') {
      return dataSource
        .map(file => {
          if (file.fileId) {
            return { ...file, ...file.info, id: file.fileId, name: file.fileName } as any
          }
          return undefined
        })
        .filter(file => !!file)
    }
    return fileList
  }

  getBusinessType() {
    const { businessType } = this.store.state
    return businessType
  }

  setCustomBtn2display({ type, display }) {
    this.store.dispatch('updateCustomBtn2display', { type, display })
  }
}

export default FileManager
