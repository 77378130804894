import Iframe from './iframe';
import Modal from './modal';
import SystemPage from './system-page';
import Workbench from './workbench';
import Module from './module';
import AutoComplete from './auto-complete';
import Button from './button';
import Buttons from './buttons';
import Cascader from './cascader';
import ComplexSearch from './complex-search';
import Datetime from './datetime';
import DateTimeRange from './datetime-range';
import FileManager from './filemanager';
import Form from './form';
import Chart from './chart';
import Grid from './grid';
import GridEdit from './gridedit';
import GridView from './gridview';
import Hyperlink from './hyperlink';
import Label from './label';
import Number from './number';
import Waterfall from "./waterfall";
import NumberRange from './number-range';
// import Password from './password';
import Radio from './radio';
import RadioButtons from './radio-buttons';
import RadioList from './radiolist';
import RichTextbox from './rich-textbox';
import Select from './select';
import SelectTree from './select-tree';
import Separate from './separate';
import SimpleSearch from './simple-search';
import Switch from './switch';
import Tabs from './tabs';
import Statistics from './statistics';
import TextArea from './textarea';
import Textbox from './textbox';
import Timeline from './timeline';
import Toolbar from './toolbar';
import TreeList from './treelist';
import TreeView from './treeview';
import TreeEdit from './treeedit';

const controlTypes = {
  Module,
  Workbench,
  Iframe,
  Modal,
  SystemPage,
  Chart,
  Waterfall,
  AutoComplete,
  Button,
  Buttons,
  Cascader,
  ComplexSearch,
  Datetime,
  DateTimeRange,
  FileManager,
  Form,
  Grid,
  GridEdit,
  GridView,
  Hyperlink,
  Label,
  Number,
  NumberRange,
  Statistics,
  // Password,
  Radio,
  RadioButtons,
  RadioList,
  RichTextbox,
  Select,
  SelectTree,
  Separate,
  SimpleSearch,
  Switch,
  Tabs,
  TextArea,
  Textbox,
  Timeline,
  Toolbar,
  TreeList,
  TreeView,
  TreeEdit,
  Search: SimpleSearch,
};

export default controlTypes;
