import TabBar from './TabBar';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import React, { useState } from 'react';
import globalData from '@mjcloud/global-data';
import { withRouter } from 'react-router';
import { NavBar, Drawer } from 'antd-mobile';
import getConfig, { ITabBarItem } from '../../../../config';
import DocumentTitle from 'react-document-title';
import { IRouteComponentProps } from '@mjcloud/router-helper';
import { ENV, CONTAINER_ENUM, IRequestNavigationBarParams } from '@mjcloud/jsapi';

const Container = styled.div<{ height: number }>`
  width: 100%;
  height: ${({ height }) => height}px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
  *::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
  background: #f7f7f7;
  /*解决ios上滑动不流畅*/
  -webkit-overflow-scrolling: touch;
`;

interface IMobileLayoutProps extends IRouteComponentProps {}
interface IMobileMainLayoutProps {
  title: string;
  height: number;
  tabBar?: React.ReactNode;
}

function isShowTabBar(items: ITabBarItem[], url: string) {
  for (const item of items) {
    if (item.pagePath === url) {
      return item;
    }
  }
  return undefined;
}

const MobileMainLayout: React.FC<IMobileMainLayoutProps> = ({
  title,
  height,
  tabBar,
  children,
}) => {
  return (
    <DocumentTitle title={title}>
      <Container height={height}>
        {ENV.container === CONTAINER_ENUM.WEB && <NavBar mode="light">{title}</NavBar>}
        <Content>{children}</Content>
        {tabBar}
      </Container>
    </DocumentTitle>
  );
};

const MobileLayout: React.FC<IMobileLayoutProps> = ({ children, ...props }) => {
  const { appName, tabBar } = getConfig(globalData.appId),
    { _title = appName } = globalData,
    [title, setTitle] = useState(_title),
    [open, setOpen] = useState(false),
    [height, setHeight] = useState(window.innerHeight);
  // JsApiHelper.setNavigationBar({ title: _title });
  globalData.__setNavigationBar = ({ title }: IRequestNavigationBarParams) => {
    if (title) setTitle(title);
  };
  if (tabBar) {
    const tab = isShowTabBar(tabBar.items, props.match.url);
    if (tab) {
      const _children = (
        <MobileMainLayout
          title={title}
          height={height}
          tabBar={<TabBar {...tabBar} selected={tab} onAddClick={() => setOpen(true)} />}
        >
          {children}
        </MobileMainLayout>
      );
      if (tabBar.isAdd) {
        return (
          <DocumentTitle title={title}>
            <Drawer
              position="bottom"
              enableDragHandle
              sidebar={<Sidebar height={height} onClose={() => setOpen(false)} />}
              style={{ height }}
              open={open}
              onOpenChange={() => setOpen(!open)}
            >
              {_children}
            </Drawer>
          </DocumentTitle>
        );
      } else {
        return _children;
      }
    }
  }
  return (
    <MobileMainLayout title={title} height={height}>
      {children}
    </MobileMainLayout>
  );
};

export default withRouter(MobileLayout);
