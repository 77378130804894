import { ControllerBase, IControllerInitData, IRequest } from './base';
import { ExceptionHelper } from '@mjcloud/exception';
import { InstanceBase } from '@mjcloud/instance';
import { IDictionary } from '@mjcloud/types';
// import ControllerHelper from './helper';

export interface ExecuteJoinControllerInitData extends IControllerInitData {
  set: string;
}

/**
 * 执行脚本请求对象类型
 */
export interface IExecuteJoinControllerRequest extends IRequest {}

/**
 * 执行脚本控制器
 */
export default class ExecuteJoinController extends ControllerBase<ExecuteJoinControllerInitData> {
  private _set: string[];
  constructor(sender: InstanceBase, initData: ExecuteJoinControllerInitData) {
    super(sender, initData);
    const { set = '' } = initData;
    this._set = set.split(',');
  }
  /**
   * 创建执行请求对象
   */
  public async createRequest(args: IDictionary): Promise<IExecuteJoinControllerRequest> {
    return { ...args };
  }

  /**
   * 执行控制器
   * @param request 执行时的请求对象
   */
  public async execute(request: IExecuteJoinControllerRequest) {
    let returnValues: any[] = [],
      result: any;
    for (const controllerId of this._set) {
      const data: IDictionary = { ...request },
        returnValue: any = { controllerId };
      data.result = result;
      data.dataBus = [...returnValues];
      try {
        await this.page.executeController(controllerId, data, this.sender);
        // await ControllerHelper.execute({ controllerId, sender: this.sender, data });
        result = data.result;
      } catch (error) {
        const ex = ExceptionHelper.convertTo(error);
        result = undefined;
        returnValue.e = ex;
      }
      returnValue.result = result;
      returnValue.request = data.request;
      returnValues.push(returnValue);
      if (returnValue.e) return returnValues as any;
    }
    return returnValues as any;
  }
}
