import Page from '@mjcloud/page';
import { applyMixins } from '@mjcloud/utils';
import { IPageState } from '@mjcloud/page/dist/typings';
import { DataModeEnum, IDictionary } from '@mjcloud/types';
import ContainerInstanceBase from '../containerInstanceBase';
import { IDataSourceParameters, IData } from '@mjcloud/data-source-helper';
import { IEventArg } from '@mjcloud/utils/dist/events/eventListening';
import ValueInstanceBase, {
  IValueState,
  ValueControlActionType,
  ValueControlEventType,
} from '../valueInstanceBase';
import DataInstanceBase, {
  IDataState,
  DataControlEventType,
  DataControlActionType,
  IDataInstanceBase,
} from '../dataInstanceBase';

export type ValueDataControlEventType = ValueControlEventType | DataControlEventType;
export type ValueDataControlActionType = DataControlActionType | ValueControlActionType;

export interface IValueDataState<TValue = string, TLayout = IDictionary>
  extends IValueState<TValue, TLayout>,
    IDataState<TLayout> {}

abstract class ValueDataInstanceBase<
  TValue = string,
  S extends IValueDataState<TValue> = IValueDataState<TValue>,
  TEventType extends string = string,
  TDispatchType extends string = string
> extends ValueInstanceBase<TValue, S, TEventType, TDispatchType> implements IDataInstanceBase {
  constructor(id: string, parent: ContainerInstanceBase, workbenchPart?: any) {
    super(id, parent, workbenchPart);
    if (parent['getDataMode']) {
      parent.eventManager.add('ready', this.handParentDataLoad.bind(this));
    } else {
      this.page.eventManager.add('inited', this.handParentDataLoad.bind(this));
    }
    if (workbenchPart) {
      workbenchPart.eventManager.add('inited', this.handParentDataLoad.bind(this));
    }
  }

  isJudgePageMode = false;
  updateDataConfig(config: IData): void {
    throw new Error('Method not implemented.');
  }

  public getDataMode(): DataModeEnum {
    throw new Error('Method not implemented.');
  }
  /**
   * 刷新数据
   * @param params
   */
  public refresh(params?: IDataSourceParameters): void {
    throw new Error('Method not implemented.');
  }
  /**
   * 更新数据源
   * @param rows 数据源
   */
  updateDatas(rows: IDictionary[]) {
    throw new Error('Method not implemented.');
  }
  protected pageInited(e: IEventArg<Page, IPageState>): void {
    throw new Error('Method not implemented.');
  }
  protected handParentDataLoad(): void {
    throw new Error('Method not implemented.');
  }
  public __loadStart() {
    throw new Error('Method not implemented.');
  }
  public __loadComplete() {
    throw new Error('Method not implemented.');
  }

  public focus: () => boolean = function() {
    throw new Error('Method not implemented.');
  };
  public blur: () => boolean = function() {
    throw new Error('Method not implemented.');
  };
}
applyMixins(ValueDataInstanceBase, DataInstanceBase);
export default ValueDataInstanceBase;
