import ReduceBase from '../base';
import Store from '@mjcloud/redux';
import {
  IDataState,
  IDataControlStartLoadParams,
  IDataControlLoadedParams,
  IDataControlLoadErrorParams,
} from '@mjcloud/instance/dist/dataInstanceBase';

export default abstract class DataReduceBase<S extends IDataState = IDataState> extends ReduceBase<
  S
> {
  startLoad(store: Store<IDataState>, params: IDataControlStartLoadParams): IDataState {
    if (store.state.isFetching) {
      params.isFetching = store.state.isFetching;
      return store.state;
    }
    const { config, orderBy = [] } = params,
      state: IDataState = { ...store.state, isFetching: true, errorMessage: undefined };
    if (state.startLoadParams) {
      for (const key in state.startLoadParams) {
        // 需要存留的刷新参数
        if (key.indexOf('filter') === 0 && params[key] == null) {
          params[key] = state.startLoadParams[key];
        }
      }
    }
    if (config && config.id) {
      state.config.data = config;
    }
    state.startLoadParams = params;
    if (orderBy.length === 0) params.orderBy = state.orderby;
    state.orderby = params.orderBy || [];
    return state;
  }

  loaded(store: Store<IDataState>, params: IDataControlLoadedParams): IDataState {
    const { dataSource } = params,
      state = { ...store.state, isFetching: false };
    if (dataSource instanceof Array) {
      state.dataSource = dataSource;
    } else if (dataSource) {
      state.dataSource = dataSource.rows;
    }
    return state;
  }

  loadError(store: Store<IDataState>, params: IDataControlLoadErrorParams): IDataState {
    const { errorMessage } = params;
    let state = { ...store.state, isFetching: false, errorMessage };
    return state;
  }
}
