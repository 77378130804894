function hasCallback(args: any) {
  if (!args || typeof args !== 'object') return false;

  const callback = ['success', 'fail', 'complete'];
  for (const m of callback) {
    if (typeof args[m] === 'function') return true;
  }
  return false;
}

function _promisify(func: any, ready?: any) {
  if (typeof func !== 'function') return func;
  return (args: any = {}) =>
    new Promise((resolve, reject) => {
      if (typeof ready === 'function') {
        ready(function() {
          func(
            Object.assign(args, {
              success: resolve,
              fail: reject,
            }),
          );
        });
      } else {
        func(
          Object.assign(args, {
            success: resolve,
            fail: reject,
          }),
        );
      }
    });
}

export function promisifyAll(wx: any = {}) {
  const wxp: any = {};
  Object.keys(wx).map(key => {
    const fn = wx[key];
    if (typeof fn === 'function') {
      wxp[key] = (args: any) => {
        // if (!args._nopl && console && console.warn)
        //   console.warn(`${key}: 请尽量使用由平台提供的 @mjcloud/jsapi 包进行调用jsapi`);
        if (hasCallback(args)) {
          fn(args);
        } else {
          return _promisify(fn, wx.ready)(args);
        }
      };
    } else {
      wxp[key] = fn;
    }
  });
  return wxp;
}
