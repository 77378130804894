import Store from '@mjcloud/redux';
import Reduce from './reduce';
import GridViewExtendStore from './extendStore';
import { ContainerDataInstanceBase, ContainerInstanceBase } from '@mjcloud/instance';
import { IGridViewState } from './typings';

export default class GridView extends ContainerDataInstanceBase<IGridViewState> {
  private _operation;
  constructor(id: string, parent: ContainerInstanceBase, workbenchPart?: any) {
    super(id, parent, workbenchPart);
    this.page.eventManager.add('pullDownRefresh', this.onPullDownRefresh.bind(this));
    this.page.eventManager.add('reachBottom', this.onReachBottom.bind(this));
  }

  __createStore() {
    return new Store<IGridViewState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new GridViewExtendStore(this),
    });
  }

  getDataMode() {
    const { dataMode } = this.store.state;
    return dataMode;
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  get operation() {
    return this._operation;
  }

  __registerTableOperation(operation) {
    this._operation = operation;
  }

  /**
   *
   * @param {number} rowId
   * @param {object} config
   */
  __createControlByOperation(rowId, config) {
    if (config.nodeName === 'button') {
      return this.__createControl(`${config.id}-${rowId}`, config);
    } else {
      console.error(`该容器控件-${this.id} 中的buttons节点不支持button以外的控件类型`);
      return null;
    }
  }

  onPullDownRefresh() {
    // 页面被下拉
    this.refresh({ type: 'down' });
  }

  onReachBottom() {
    // 页面被拉到底部
    this.refresh({ type: 'up', pageIndex: ++this.store.state.current });
  }

  /**
   *
   * @param {number} rid
   */
  getRowForRowId(rid) {
    for (const row of this.store.state.dataSource) {
      if (row._rid === rid) return row;
    }
    return undefined;
  }
}
