
interface CallbackQueue{
  current: ()=>void;
  next?: CallbackQueue;
}

export default class QueueCallback{
  private _lastCallbackQueue: CallbackQueue | undefined;

  addCallback(callback:()=>void){
    if(this._lastCallbackQueue){
      this._lastCallbackQueue.next = { current: callback };
      this._lastCallbackQueue = this._lastCallbackQueue.next;
    }
    else{
      this._lastCallbackQueue = { current: callback };
      this.processCallback(this._lastCallbackQueue);
    }
  }

  // TODO: 暂时去掉异步，防止死循环异常
  private processCallback(callbackQueue: CallbackQueue){
    while(callbackQueue){
      callbackQueue.current();
      callbackQueue = callbackQueue.next as CallbackQueue;
    }
    this._lastCallbackQueue = undefined;
  }
}
