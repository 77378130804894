import Reduce from './reduce';
import Store from '@mjcloud/redux';
import SelectInstanceBase from '../common/select';
import { ControlConfig, DataModeEnum } from '@mjcloud/types';
import SelectExtendStoreBase from '../common/select/extendStore';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { IRadioConfig, IRadioState, RadioEventType, RadioActionType } from './typings';

class RadioExtendStore extends SelectExtendStoreBase<string, Radio> {}

class Radio extends SelectInstanceBase<string, IRadioState, RadioEventType, RadioActionType> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  getDataMode(): DataModeEnum {
    return DataModeEnum.all;
  }

  __createStore() {
    return new Store<IRadioState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new RadioExtendStore(this),
    });
  }

  initialState(initConfig: IRadioConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}

export default Radio;
