const logColors = [
  'color: #ababab',
  'color: #1890ff',
  'color: #ababab',
  'color: #f5222d',
  'color: #ababab',
];
const logDarkColors = [
  'color: #ababab',
  'color: #1890ff',
  'color: #ababab',
  'color: #f5222d',
  'color: #ababab',
];

export default class ConsoleHelper {
  private static _isEnableDebugger = false;
  /**
   * 当前是否开启调试模式
   */
  static debugger: boolean = true;
  /**
   * 当前是否开启颜色模式
   */
  static isEnable: boolean = true;
  static theme: 'default' | 'dark' = 'default';

  static enableDebugger() {
    this._isEnableDebugger = true;
    this.debugger = true;
    return this;
  }

  public static log2reduce(arg1: any, arg2: any, arg3: any, ...args: any[]) {
    if (this.debugger) {
      if (this.isEnable) {
        if (this.theme === 'default') {
          console.log(`%creduce %c${arg1} %c${arg2} to %c${arg3} %cdone`, ...logColors, ...args);
        } else {
          console.log(
            `%creduce %c${arg1} %c${arg2} to %c${arg3} %cdone`,
            ...logDarkColors,
            ...args,
          );
        }
      } else {
        console.log(`reduce ${arg1} ${arg2} to ${arg3}`, ...args);
      }
    }
    if (this._isEnableDebugger) this.debugger = false;
  }
}
