import Form from '../controls/form';
import Tabs from '../controls/tabs';
import Chart from '../controls/chart';
import Label from '../controls/label';
import Module from '../controls/module';
import Button from '../controls/button';
import Select from '../controls/select';
import Number from '../controls/number';
import { Workbench } from '@mjcloud/workbench';
import Toolbar from '../controls/toolbar';
import Textbox from '../controls/textbox';
import Calendar from '../controls/calendar';
import Datetime from '../controls/datetime';
import Carousel from '../controls/carousel';
import TextArea from '../controls/text-area';
import ListEdit from '../controls/list-edit';
import GridView from '../controls/grid-view';
import Selectors from '../controls/selectors';
import Waterfall from '../controls/waterfall';
import Filterbar from '../controls/filterbar';
import Hyperlink from '../controls/hyperlink';
import Accordion from '../controls/accordion';
import Statistics from '../controls/statistics';
import Attendance from '../controls/attendance';
import RecordWork from '../controls/record-work';
import FileManager from '../controls/file-manager';
import SimpleSearch from '../controls/simple-search';
import RadioButtons from '../controls/radio-buttons';

const controlTypes = {
  Form,
  Tabs,
  Label,
  Chart,
  Number,
  Select,
  Module,
  Button,
  Toolbar,
  Textbox,
  Calendar,
  TextArea,
  Carousel,
  ListEdit,
  GridView,
  Datetime,
  Selectors,
  Accordion,
  Workbench,
  Hyperlink,
  Waterfall,
  Filterbar,
  RecordWork,
  Statistics,
  Attendance,
  FileManager,
  SimpleSearch,
  RadioButtons,
};

export default controlTypes;
