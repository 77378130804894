import React from 'react';
import ControlBase from '../../base';
import globalData from '@mjcloud/global-data';
import { TreeSelect, Select } from 'antd';
import TreeNodeIcon from './TreeNodeIcon';
import { ISelectTreeState } from '../typings';
import { ISelectBaseDataSource } from '../../common/select/typings';
import styles from './index.less' 
interface LabeledValue {
  value: string;
  label?: string;
}

type TreeSelectValue = LabeledValue | LabeledValue[];

function splitBySeparators(str: string, separators: string[]) {
  if (!str) return [];
  const reg = new RegExp(`[${separators.join()}]`);
  return `${str}`.split(reg).filter(token => token);
}

function filterOption(inputValue: string, option: any) {
  const children = option.props.text;
  if (typeof children === 'string' || typeof children === 'number') {
    return String(children).indexOf(inputValue) >= 0;
  }
  return false;
}

class SelectTreeControl extends ControlBase<ISelectTreeState> {
  // private selectRef = (instance: TreeSelect<any> | null) => {
  //   if (instance) {
  //     const node = findDOMNode(instance);
  //     if (node instanceof Element) {
  //       const onclick: Function = node['onclick'];
  //       const startLoad = this.handleFocus;

  //       node['onclick'] = function() {
  //         onclick.bind(this, arguments)();
  //         startLoad();
  //       };
  //     }
  //   }
  // };

  private handleFocus = () => {
    const { alwaysLoad, value } = this.state;
    if (alwaysLoad) {
      this.instance.store.dispatch('startLoad', { open, location: value });
    }
  };

  private handleTreeExpand = (expandedKeys: Array<React.Key>) => {
    this.instance.store.dispatch('updateTreeExpandedKeys', { expandedKeys });
  };

  private handleChange = (selectValue: TreeSelectValue | undefined) => {
    const { vaueleTokenSeparator } = this.state;
    let valueArr: string[] = [],
      // textArr: string[] = [],
      rowIds: string[] = [];
    if (selectValue instanceof Array) {
      selectValue.forEach(item => {
        // textArr.push(item.label || '');
        valueArr.push(item.value || '');
      });
    } else if (typeof selectValue === 'object') {
      // textArr = [selectValue.label || ''];
      valueArr = [selectValue.value];
    }
    this.instance.store.dispatch('updateValue', {
      value: selectValue == null ? null : valueArr.join(vaueleTokenSeparator),
      // text: selectValue == null ? null : textArr.join(textTokenSeparator),
      actionSourceSign: this.instance.store,
      rowIds,
    });
  };

  private handleSearch = (searchValue: any) => {
    this.instance.store.dispatch('updateSearchValue', { searchValue });
  };

  private loopData = (data: ISelectBaseDataSource[]) => {
    return data.map(row => {
      const { searchValue, iconFn } = this.state,
        { _children, _disabled, _rid, _text, _value } = row,
        treeNodeProps = {
          text: _text,
          title: _text as any,
          value: _value,
          disabled: _disabled,
          icon: <TreeNodeIcon row={row} iconFn={iconFn} />,
        };

      if (searchValue) {
        const index = _text.indexOf(searchValue);
        const beforeStr = _text.substr(0, index);
        const afterStr = _text.substr(index + searchValue.length);
        treeNodeProps.title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{ color: '#f50' }}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{_text}</span>
          );
      }

      if (_children) {
        return (
          <TreeSelect.TreeNode key={_rid.toString()} {...treeNodeProps}>
            {this.loopData(_children)}
          </TreeSelect.TreeNode>
        );
      }
      return <TreeSelect.TreeNode key={_rid.toString()} {...treeNodeProps} />;
    });
  };

  renderSelect() {
    const { id } = this.props;
    const { formatMessage } = globalData;
    const { expandedKeys, tokenSeparators, multiSelect, disabled, autoFocus } = this.state;
    const { dataSource, isFetching, placeholder, value, text, showIcon } = this.state;
    let selectValue: TreeSelectValue = { value, label: text };

    if (multiSelect) {
      const valueArr: string[] = splitBySeparators(value, tokenSeparators);
      let textArr: string[] = [];
      if (text) textArr = splitBySeparators(text, tokenSeparators);
      selectValue = valueArr.map((item, i) => ({ value: item, label: textArr[i] }));
    }
    return (
      <TreeSelect<any>
        id={id}
        // ref={this.selectRef}
        dropdownClassName={styles.selectTreeDrop}
        dropdownStyle={{ maxHeight: 320 }}
        searchValue={this.state.searchValue}
        treeIcon={showIcon}
        disabled={disabled}
        autoFocus={autoFocus}
        multiple={multiSelect}
        defaultOpen={autoFocus}
        placeholder={placeholder}
        onFocus={this.handleFocus}
        style={{ width: '100%' }}
        filterTreeNode={filterOption}
        onChange={this.handleChange}
        onSearch={this.handleSearch}
        onTreeExpand={this.handleTreeExpand}
        defaultActiveFirstOption={false}
        value={value ? selectValue : undefined}
        treeExpandedKeys={expandedKeys}
        notFoundContent={isFetching ? formatMessage({ id: 'global.data.loading' }) : undefined}
        dropdownMatchSelectWidth={false}
        labelInValue
        allowClear
        showSearch
        showArrow
      >
        {this.loopData(dataSource)}
      </TreeSelect>
    );
  }

  renderContent() {
    if (this.state.readonly) return <>{this.state.text || ''}</>;
    if (this.state.errorMessage) return <Select disabled value={this.state.errorMessage} />;
    return this.renderSelect();
  }
}
export default SelectTreeControl;
