import { IButtonConfig } from './typings';

const config: IButtonConfig = {
  title: '',
  active: false,
  confirm: false,
  tip: undefined,
  icon: undefined,
  size: undefined,
  theme: 'default',
  displayMode: undefined,
  controllerId: undefined,
  displayControl: undefined,
  confirmTitle: '你确定要执行该操作吗？',
};

export default config;
