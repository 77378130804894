import Store from '@mjcloud/redux';
import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { DateHelper, NumberHelper } from '@mjcloud/utils';
import { IHyperlinkState, IHyperlinkConfig } from './typings';

export class HyperlinkReduce extends ValueReduceBase {
  initialState(store: Store<IHyperlinkState>, params) {
    const { initConfig, pageMode } = params,
      { title, defaultValue, modifyMode, controllerId, disabledMode, displayMode } = initConfig,
      { value: _defaultValue = defaultValue } = store.state,
      { format2Date, format2Number, value = _defaultValue } = initConfig;
    let _value = value;
    if (_value != null) {
      if (format2Number) {
        _value = NumberHelper.format(value, format2Number);
      } else if (format2Date) {
        _value = DateHelper.format(value, format2Date);
      }
    }

    let state: IHyperlinkState = {
      title,
      value,
      format2Date,
      format2Number,
      text: _value,
      tabIndex: -1,
      controllerId,
      loading: false,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    return state;
  }

  clickAfter(store: Store<IHyperlinkState>, params: {}) {
    return { ...store.state, loading: true };
  }

  clickDone(store: Store<IHyperlinkState>, params: {}) {
    return { ...store.state, loading: false };
  }
}

export default new HyperlinkReduce();
