import React from 'react';
import globalData from '@mjcloud/global-data';
import { Row, Col, Skeleton } from 'antd';
import { IControlBaseProps } from '@mjcloud/types';
import { getInstance, px2rem } from '@mjcloud/mcontrols';
import { Workbench, WorkbenchPart } from '@mjcloud/workbench';
import { IWorkbenchPartState } from '@mjcloud/workbench/dist/part/typings';

import styles from './index.less';

interface IWorkbenchPartProps extends IControlBaseProps {
  id: string;
  size: number;
  name: string;
  width: number;
  height?: number;
}

export default class WorkbenchPartControl extends React.Component<
  IWorkbenchPartProps,
  IWorkbenchPartState
> {
  private instance: WorkbenchPart;
  constructor(props: IWorkbenchPartProps) {
    super(props);
    const { id, controlId, parentId, pageKey } = props;
    const workbench = getInstance<Workbench>({ pageKey, parentId, controlId });
    this.instance = workbench.findPart(id);
    this.state = this.instance.store.state as any;
    this.instance.store.bindViewListen(this.handStateChange);
  }

  public componentWillUnmount() {
    this.instance.store.unbindViewListen(this.handStateChange);
  }

  shouldComponentUpdate(
    nextProps: Readonly<IWorkbenchPartProps>,
    nextState: Readonly<IWorkbenchPartState>,
  ) {
    if (nextState !== this.state) return true;
    return false;
  }

  private handStateChange = (state: IWorkbenchPartState, cb?: () => void) => {
    this.setState(state, cb);
  };

  private renderPartContent() {
    const { ControlFactory } = globalData,
      { content, contentCols } = this.state;
    if (contentCols > 1) {
      const span = 24 / contentCols;
      return content.map((items, key) => (
        <Row key={key} gutter={24} style={{ width: '100%', height: '100%' }}>
          {items.map((item, index) => (
            <Col span={span} key={index} style={{ padding: 0, height: '100%' }}>
              <ControlFactory {...item} />
            </Col>
          ))}
        </Row>
      ));
    } else {
      return content[0] && content[0].map(row => <ControlFactory key={row.controlId} {...row} />);
    }
  }

  private renderContent() {
    const { ControlFactory } = globalData,
      { title, showTitle, titleRight, newRow } = this.state;
    return (
      <div className={styles.dyPartContainer}>
        {showTitle ? (
          <div className={styles.dyPartTitleContainer}>
            <div className={styles.dyPartTitle}>{title}</div>
            <div className={styles.dyPartTitleMiddle}></div>
            {titleRight ? (
              <div className={styles.dyPartTitleRight}>
                <ControlFactory {...titleRight} />
              </div>
            ) : null}
          </div>
        ) : null}

        {newRow && newRow.length > 0 && (
          <div className={styles.dyPartRow}>
            {newRow.map(row => (
              <div key={row.controlId} className={styles.dyPartRowItem}>
                <ControlFactory {...row} />
              </div>
            ))}
          </div>
        )}

        {<div className={styles.dyPartContent}>{this.renderPartContent()}</div>}
      </div>
    );
  }

  private renderLoad() {
    return <Skeleton className={styles.dyPartLoading} active />;
  }

  render() {
    const { configIsFetching } = this.state,
      { width, height, size } = this.props;
    return (
      <div
        style={{
          width: width && size ? px2rem(width * size) : undefined,
          height: height && size ? px2rem(height * size) : undefined,
        }}
      >
        {configIsFetching ? this.renderLoad() : this.renderContent()}
      </div>
    );
  }
}
