import EventManager from '../events';
import { EventFn } from '../events/eventListening';

export type KeyboardFn = EventFn<KeyboardEvent>;

/**
 * 快捷键管理器
 */
export default class KeyboardManager {
  private static inited = false;
  private static _eventManager = new EventManager(KeyboardManager);
  private static keyboardInit() {
    if (this.inited) return;
    const that = this;
    this.inited = true;
    document.onkeydown = function(event) {
      that._eventManager.trigger('onkeydown', event);
    };
  }

  constructor(handler?: KeyboardFn) {
    KeyboardManager.keyboardInit();
    if (handler) this.add(handler);
  }

  add(handler: KeyboardFn) {
    KeyboardManager._eventManager.add('onkeydown', handler);
  }

  remove(handler: KeyboardFn) {
    KeyboardManager._eventManager.remove('onkeydown', handler);
  }
}
