import Store from '@mjcloud/redux';
import Reduce from './reduce';
import { DataModeEnum } from '@mjcloud/types';
import RecordWorkExtendStore from './extendStore';
import { ValueDataInstanceBase } from '@mjcloud/instance';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';

export default class RecordWork extends ValueDataInstanceBase<string, any> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  __createStore() {
    return new Store<any>({
      id: this.id,
      reduce: Reduce,
      extendStore: new RecordWorkExtendStore(this),
    });
  }

  getDataMode() {
    return DataModeEnum.all;
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  get dataModel() {
    return this.store.state.dataSource;
  }

  async getData(isValid) {
    return this.dataModel.toJSON();
  }

  addWorker(worker: any) {
    this.batchAddWorker([worker]);
  }

  batchAddWorker(workers: any = []) {
    this.store.dispatch('batchAddDataSource', {
      workers,
      actionSourceSign: this.store,
    });
  }

  /**
   * 删除一行
   * @param rid 行Id
   */
  deleteRow(rid) {
    this.store.dispatch('deleteRow', { rid });
  }

  /**
   * 清空所有数据
   */
  deleteAllRow() {
    this.store.dispatch('deleteAllRow', {});
  }
}
