import Reduce from './reduce';
import SelectInstanceBase from '../../common/select';
import { ControlConfig, DataModeEnum } from '@mjcloud/types';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import SelectExtendStoreBase from '../../common/select/extendStore';
import Store, { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux';
import {
  IRadioButtonsConfig,
  IRadioButtonsState,
  RadioButtonsEventType,
  RadioButtonsActionType,
} from './typings';

class RadioButtonsExtendStore extends SelectExtendStoreBase<string, RadioButtons> {
  handleUpdateActiveIndexBefore(e: ActionBeforeEventArg<any>) {
    const activeRow = this.instance.store.state.dataSource[e.params.activeIndex];
    if (activeRow) {
      const { _text, _value } = activeRow;
      e.params = { ...e.params, value: _value, text: _text };
    }

    this.handleUpdateValueBefore(e);
  }

  handleUpdateActiveIndexAfter(e) {
    this.handleUpdateValueAfter(e);
  }

  handleLoadedAfter(e: ActionAfterEventArg<IRadioButtonsState>) {
    super.handleLoadedAfter(e);
    this.instance.store.dispatch('updateActiveIndex', {
      activeIndex: e.newState.state.activeIndex,
    });
  }
}

class RadioButtons extends SelectInstanceBase<
  string,
  IRadioButtonsState,
  RadioButtonsEventType,
  RadioButtonsActionType
> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  getDataMode(): DataModeEnum {
    return DataModeEnum.all;
  }

  __createStore() {
    return new Store<IRadioButtonsState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new RadioButtonsExtendStore(this),
    });
  }

  initialState(initConfig: IRadioButtonsConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}

export default RadioButtons;
