import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import { ErrorBoundary } from '../base';
import useG6, { G6Config } from '../hooks/useG6';
import { TreeGraph as G6TreeGraph } from '@antv/g6';

export interface MindConfig extends Omit<G6Config, 'tooltip'> {
  chartRef?: React.MutableRefObject<G6TreeGraph | undefined>;
  style?: React.CSSProperties;
  className?: string;
  onNodeClick?: (e: any, data: any) => void;
}

const MindChart = forwardRef((props: MindConfig, ref) => {
  const { chartRef, style = {}, className, ...rest } = props;

  const { chart, container } = useG6<G6TreeGraph, MindConfig>(G6TreeGraph, rest);

  useEffect(() => {
    if (chartRef) {
      chartRef.current = chart.current;
    }
  }, [chart.current]);
  useImperativeHandle(ref, () => ({
    getChart: () => chart.current,
  }));
  return (
    <ErrorBoundary>
      <div className={className} style={style} ref={container} />
    </ErrorBoundary>
  );
});

export default MindChart;
