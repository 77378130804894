/**
 * 过滤条件类型
 */
export enum FilterTypeEnum {
  /**
   * 未定义
   */
  none = 0,
  /**
   * 逻辑与
   */
  and,
  /**
   * 逻辑或
   */
  or,
  /**
   * 相对
   */
  equal,
  /**
   * 不相对
   */
  notEqual,
}

/**
 * 条件操作符
 */
export enum OperationTypeEnum {
  /**
   * 未定义类型
   */
  none = 0,
  /**
   * `=`
   */
  eq,
  /**
   * `<>`
   */
  notEq,
  /**
   * like
   */
  like,
  /**
   * not like
   */
  notLike,
  /**
   * left Like
   */
  lLike,
  /**
   * not left like
   */
  notLLike,
  /**
   * right like
   */
  rLike,
  /**
   * not right like
   */
  notRLike,
  /**
   * `>`
   */
  greater,
  /**
   * `<`
   */
  less,
  /**
   * `>=`
   */
  greaterEq,
  /**
   * `<=`
   */
  lessEq,
  /**
   * in(*)
   */
  in,
  /**
   * not in(*)
   */
  notIn,
}

/**
 * 数据类型枚举
 */
export enum DataTypeEnum {
  /**
   * 未定义类型
   */
  None = 0,
  /**
   * Null值
   */
  DBNull,
  /**
   * 字符串参数
   */
  String,
  /**
   * 日期参数
   */
  Datetime,
  /**
   * Guid参数
   */
  Guid,
  /**
   * 小数
   */
  Decimal,
  /**
   * 有序id参数
   */
  SequentialId,
  /**
   * 布尔型
   */
  Boolean,
  /**
   * 整型参数
   */
  Int,
  /**
   * 长整型参数
   */
  Long,
  /**
   * 字节数组参数
   */
  Binary,
  /**
   * 字段
   */
  Field,
}

/**
 * 条件表达式类型枚举
 */
export enum ConditionTypeEnum {
  /**
   * 本年
   */
  thisYear = 'thisYear',
  /**
   * 本月
   */
  thisMonth = 'thisMonth',
  /**
   * 本周
   */
  thisWeek = 'thisWeek',
  /**
   * 日期
   */
  date = 'date',
  /**
   * 今天
   */
  today = 'today',
  /**
   * 范围
   */
  scope = 'scope',
  /**
   * 多值
   */
  multValue = 'multValue',
}

export interface IExpression {}

/**
 * 条件表达式对象
 */
export interface ICondition extends IExpression {
  left: string;
  right?: string | number;
  dataType?: DataTypeEnum;
  op?: OperationTypeEnum;
  type?: ConditionTypeEnum;
  title?: string;
  [key: string]: any;
}

export interface IFilter extends IExpression {
  type: FilterTypeEnum;
  expressions?: Array<IExpression>;
}

/**
 * 过滤条件对象
 */
export default class Filter implements IFilter {
  private static s_equalFilter: Filter = new Filter(FilterTypeEnum.equal);
  private static s_notEqualFilter: Filter = new Filter(FilterTypeEnum.notEqual);

  private _type: FilterTypeEnum;
  private _expressions: Array<ICondition | Filter> = [];

  constructor();
  constructor(filter: { type: FilterTypeEnum; conditions: Array<ICondition> });
  constructor(type?: FilterTypeEnum);
  constructor(data?: any) {
    if (data) {
      if (typeof data === 'number') {
        this._type = data;
      } else {
        this._type = data.type;
        if (data.conditions) {
          for (var condition of data.conditions) {
            this.addCondition(condition);
          }
        }
      }
    }

    if (!this['_type']) {
      this._type = FilterTypeEnum.and;
    }
  }

  /**
   * 获取相对条件
   */
  static get EqualFilter(): Filter {
    return Filter.s_equalFilter;
  }

  /**
   * 获取不相对条件
   */
  static get NotEqualFilter(): Filter {
    return Filter.s_notEqualFilter;
  }

  /**
   * 获取条件类型
   */
  get type(): FilterTypeEnum {
    return this._type;
  }

  /**
   * 获取条件集合
   */
  get expressions(): Array<ICondition | Filter> {
    return this._expressions;
  }

  /**
   * 添加一个新的条件
   */
  addCondition(condition: ICondition | Filter) {
    this._expressions.push(condition);
  }

  toJSON() {
    var filter: IFilter = { type: this._type };
    switch (this._type) {
      case FilterTypeEnum.equal:
      case FilterTypeEnum.notEqual:
        return filter;
    }

    filter.expressions = this._expressions;
    return filter;
  }
}
