import { ControllerBase, IControllerInitData, IRequest } from './base';
import { InstanceBase } from '@mjcloud/instance';
import { EventListening } from '@mjcloud/utils';
import PageHelper from '@mjcloud/page-helper';
import { IDictionary } from '@mjcloud/types';

export interface ExecuteScriptControllerInitData extends IControllerInitData {
  script: string;
}

/**
 * 执行脚本请求对象类型
 */
export interface IExecuteScriptRequest extends IRequest {}

/**
 * 执行脚本控制器
 */
export default class ExecuteScriptController extends ControllerBase<
  ExecuteScriptControllerInitData
> {
  private _fn: Function;
  constructor(sender: InstanceBase, initData: ExecuteScriptControllerInitData) {
    super(sender, initData);
    this._fn = PageHelper.createPageScriptFunction(this.page, initData.script, true);
  }

  /**
   * 创建执行请求对象
   */
  public async createRequest(args: IDictionary): Promise<IExecuteScriptRequest> {
    return { ...args };
  }

  /**
   * 执行控制器
   * @param request 执行时的请求对象
   */
  public async execute(request: IExecuteScriptRequest) {
    return this._fn(EventListening.getHandlerArg(this.sender, request));
  }
}
