import Page from '@mjcloud/page';
import { InstanceBase } from '@mjcloud/instance';
import { IDictionary, IControllerConfig } from '@mjcloud/types';
import PageHelper from '@mjcloud/page-helper';
import { EventListening } from '@mjcloud/utils';

export interface IControllerInitData extends IControllerConfig {
  hasRights: boolean;
}

/**
 * 控制器请求对象类型
 */
export interface IRequest {
  [key: string]: any;
}
/**
 * 控制器基类
 */
export abstract class ControllerBase<TInitData extends IControllerInitData = IControllerInitData> {
  private _page: Page;
  protected _sender: InstanceBase;
  _before?: (params: IDictionary, extra?: IDictionary) => Promise<IDictionary>;
  _after?: (params: IDictionary, extra?: IDictionary) => Promise<IDictionary>;

  constructor(protected sender: InstanceBase, protected initData: TInitData) {
    this._sender = sender;
    this._page = sender.page;
    // this._page = sender.workbenchPart ? sender.workbenchPart : sender.page;
    if (initData.onBeforeClick) {
      const fn = PageHelper.createPageScriptFunction(this.page, initData.onBeforeClick);
      this._before = params => fn(EventListening.getHandlerArg(sender, params));
    }
    if (initData.onAfterClick) {
      const fn = PageHelper.createPageScriptFunction(this.page, initData.onAfterClick);
      this._after = params => fn(EventListening.getHandlerArg(sender, params));
    }
  }

  /**
   * 获取控制器id
   */
  public get id(): string {
    return this.initData.id;
  }

  /**
   * 是否存在权限
   */
  public get hasRights(): boolean {
    return this.initData.hasRights;
  }

  /**
   * 获取控制前所在页面对象
   */
  public get page(): Page {
    return this._page;
  }

  /**
   * 创建执行请求对象
   */
  public abstract createRequest(args: IDictionary): Promise<IRequest>;

  /**
   * 执行控制器
   * @param request 执行时的请求对象
   */
  public abstract execute<T>(request: IRequest): Promise<T | undefined>;
}
