import React from 'react';
import { InputNumber } from 'antd';
import ControlBase from '../../base';
import { INumberRangeState } from '../typings';
import { InputNumberProps } from 'antd/lib/input-number';

import styles from './index.less';

class NumberRangeControl extends ControlBase<INumberRangeState> {
  private handStartChange = (value: string | number | null | undefined) => {
    let { startFieldName, endFieldName, value: oldval } = this.state;
    if (startFieldName === endFieldName) {
      startFieldName = 'start';
      endFieldName = 'end';
    }
    let _value: any = {
      _isObject: true,
      [startFieldName]: value,
      [endFieldName]: oldval && oldval[endFieldName],
    };
    this.instance.store.dispatch('updateValue', {
      value: _value,
      actionSourceSign: this.instance.store,
    });
  };

  private handEndChange = (value: string | number | null | undefined) => {
    let { startFieldName, endFieldName, value: oldval } = this.state;
    if (startFieldName === endFieldName) {
      startFieldName = 'start';
      endFieldName = 'end';
    }
    let _value: any = {
      _isObject: true,
      [startFieldName]: oldval && oldval[startFieldName],
      [endFieldName]: value,
    };
    this.instance.store.dispatch('updateValue', {
      value: _value,
      actionSourceSign: this.instance.store,
    });
  };

  private handFocus = (e: any) => {
    const { autoFocus } = this.state;
    if (autoFocus && e.target && e.target.select) e.target.select();
  };

  renderContent() {
    const { value = {}, text, startPlaceholder, endPlaceholder, disabled } = this.state,
      { max, min, autoFocus } = this.state;
    if (this.state.readonly) return <>{text || ''}</>;
    let { startFieldName, endFieldName } = this.state;
    if (startFieldName === endFieldName) {
      startFieldName = 'start';
      endFieldName = 'end';
    }
    const numberProps: InputNumberProps = { max, min, disabled };
    return (
      <div id={this.props.id} className={styles.numberRange}>
        <InputNumber
          {...numberProps}
          autoFocus={autoFocus}
          value={value[startFieldName]}
          placeholder={startPlaceholder}
          onChange={this.handStartChange}
          onFocus={this.handFocus}
        />
        <div className={styles.separator}>~</div>
        <InputNumber
          {...numberProps}
          value={value[endFieldName]}
          placeholder={endPlaceholder}
          onChange={this.handEndChange}
          onFocus={this.handFocus}
        />
      </div>
    );
  }
}
export default NumberRangeControl;
