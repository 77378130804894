import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shallowEqual from 'shallowequal';

interface PanelContentProps {
  [key: string]: any;
}

class PanelContent extends Component<PanelContentProps> {
  static propTypes = {
    prefixCls: PropTypes.string,
    isActive: PropTypes.bool,
    children: PropTypes.any,
    destroyInactivePanel: PropTypes.bool,
    forceRender: PropTypes.bool,
    role: PropTypes.string,
  };

  private _isActived = false;

  shouldComponentUpdate(nextProps) {
    return this.props.forceRender || !shallowEqual(this.props, nextProps);
  }

  render() {
    this._isActived = this.props.forceRender || this._isActived || this.props.isActive;
    if (!this._isActived) {
      return null;
    }
    const { prefixCls, isActive, children, destroyInactivePanel, forceRender, role } = this.props;
    const contentCls = classnames({
      [`${prefixCls}-content`]: true,
      [`${prefixCls}-content-active`]: isActive,
      [`${prefixCls}-content-inactive`]: !isActive,
    });
    const child =
      !forceRender && !isActive && destroyInactivePanel ? null : (
        <div className={`${prefixCls}-content-box`}>{children}</div>
      );
    return (
      <div className={contentCls} role={role}>
        {child}
      </div>
    );
  }
}

export default PanelContent;
