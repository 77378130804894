import React from 'react';
import ReactDOM from 'react-dom';
import Service, { IFileInfo } from '@mjcloud/service';
import { ExceptionHelper } from '@mjcloud/exception';
import ImportFileModal from '../components/ImportFileModal';

interface ImportFileResult {
  fileId: string;
}

interface ImportFileProps {
  accept?: string;
  exportId?: string;
  templateLabel?: string;
  businessType?: string;
  getTemplateFile: (exportId: string) => Promise<IFileInfo | undefined>;
}

export default function ({ accept, exportId, templateLabel, getTemplateFile }: ImportFileProps) {
  return new Promise<ImportFileResult>(async (resolve, fail) => {
    let fileId: string | undefined;
    const uploadProps = await Service.getUploadInfo('', 'importfile');
    const div = document.createElement('div');
    document.body.appendChild(div);

    function destroy() {
      const unmountResult = ReactDOM.unmountComponentAtNode(div);
      if (unmountResult && div.parentNode) {
        div.parentNode.removeChild(div);
      }
    }
    async function onOk() {
      destroy();
      if (fileId) resolve({ fileId });
      else fail(ExceptionHelper.passException());
    }
    function onCancel() {
      destroy();
      fail(ExceptionHelper.passException());
    }
    function onChange(file: any) {
      fileId = file.id;
    }
    ReactDOM.render(
      <ImportFileModal
        {...uploadProps}
        accept={accept}
        exportId={exportId}
        templateLabel={templateLabel}
        getTemplateFile={getTemplateFile}
        onChange={onChange}
        onOk={onOk}
        onCancel={onCancel}
      />,
      div,
    );
  });
}
