import Form from '..';
import Reduce from './reduce';
import Store from '@mjcloud/redux';
import dayjs from 'dayjs';
import PageHelper from '@mjcloud/page-helper';
import { RequiredTypeEnum } from '@mjcloud/types';
import { IFormConfigItem } from '../typings';
import { PageModeEnum, IDictionary } from '@mjcloud/types';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux';
import { AsyncValidator, EventListening, DateHelper } from '@mjcloud/utils';
import { IFormItemState, IFormItemInitialStateParams, FormItemActionType } from './typings';
import {
  InstanceBase,
  DataInstanceBase,
  ValueDataInstanceBase,
  ValueInstanceBase,
} from '@mjcloud/instance';
import {
  IValueState,
  ValueControlActionType,
  IValueControlUpdateValueParams,
} from '@mjcloud/instance/dist/valueInstanceBase';

class FormItemControlExtendStore {
  constructor(private instance: FormItem, private controlInstance: InstanceBase) {}

  handleInitialStateAfter(e: any) {
    // TODO: 判断是否自动编码，如果是则取消必填功能。 功能已实现，但是代码位置不合理，需要🥺模型的调整
    const { ruleKey, isAutoCode } = e.newState.state;
    if (ruleKey && isAutoCode && this.instance.parent.page.pageMode === PageModeEnum.add) {
      if (this.instance['validator']) this.instance['validator'].updateRule({ required: false });
    }
    if (this.instance.parent.isReset) {
      if (this.controlInstance instanceof DataInstanceBase) {
        this.controlInstance.refresh();
      } else if (this.controlInstance instanceof ValueDataInstanceBase) {
        this.controlInstance.refresh();
      }
    }
  }

  handleUpdateValueBefore(e: ActionBeforeEventArg<IValueControlUpdateValueParams>) {
    e.params.row = this.instance.parent.dataModel;
  }

  handleUpdateValueAfter(
    e: ActionAfterEventArg<
      IValueState,
      ValueControlActionType,
      IValueControlUpdateValueParams<any>
    >,
  ) {
    let { actionSourceSign, notUpdateState } = e.params;
    const { value, text } = e.newState.state as any;

    const textFieldName: string | undefined = e.newState.state['textFieldName'];
    let updateData: IDictionary = { [this.instance.store.state.field]: value };
    // _isObject 是针对区间控件做的多值处理
    if (value && typeof value === 'object' && value._isObject) {
      const { _isObject, ...val } = value;
      updateData = { ...updateData, ...val };
    }
    if (textFieldName && text) {
      updateData[textFieldName] = text;
    }
    if (this.instance.parent === actionSourceSign) {
      notUpdateState = true;
    }
    if (actionSourceSign) {
      if (actionSourceSign == this.instance.store.state.children) {
        this.instance.parent.__updateValues(updateData, actionSourceSign);
      }
    }
    this.instance.parent.dataModel.update(updateData, this.instance.parent, notUpdateState);
    if (this.instance.parent != actionSourceSign) this.instance.valid();
  }
}

export default class FormItem {
  private _store: Store<IFormItemState, FormItemActionType>;
  private _controlInstance: InstanceBase | null;
  private validator: AsyncValidator | null = null;
  constructor(private id: string, public parent: Form, config: IFormConfigItem) {
    const { colorCommand } = config;
    this._store = new Store<IFormItemState>({
      id: `item-${id}`,
      reduce: Reduce,
      extendStore: {
        // handleUpdateColorAfter: this.handleUpdateColorAfter.bind(this),
        handleInitialStateAfter: this.handleInitialStateAfter.bind(this),
        handleUpdateReadonlyAfter: this.handleUpdateReadonlyAfter.bind(this),
        handleUpdateRequiredTypeAfter: this.handleUpdateRequiredTypeAfter.bind(this),
      },
    });
    this._controlInstance = this.parent.createControl(config);
    if (this._controlInstance) {
      this._controlInstance.store.bindExtendStore(
        new FormItemControlExtendStore(this, this._controlInstance),
      );
    }
    this._store.dispatch<IFormItemInitialStateParams>('initialState', {
      initConfig: config,
      pageMode: parent.page.pageMode,
      colorCommandFn: !colorCommand
        ? undefined
        : async row => {
            const fn = PageHelper.createPageScriptFunction(parent.page, colorCommand, true);
            return fn(EventListening.getHandlerArg(parent, { row }));
          },
      children:
        this._controlInstance &&
        (this._controlInstance.store as Store<IValueState, ValueControlActionType>),
    });
  }

  get store() {
    return this._store;
  }

  private get valueType(): ValidationRuleType {
    return this._controlInstance && this._controlInstance['valueType'];
  }

  get controlStore(): any {
    const store = this._controlInstance && this._controlInstance.store;
    return store;
  }

  updateValue(value: any, actionSourceSign?: any, isParentChange: boolean = false) {
    this.updateColor();
    if (this._controlInstance instanceof ValueInstanceBase) {
      const store = this._controlInstance.store;
      if (store === actionSourceSign) return;
      const textFieldName: string | undefined = store.state['textFieldName'];

      let text = value,
        isUpdate = true;
      // if (actionSourceSign === this) isUpdate = true;
      // else if (value != null) isUpdate = true;
      // else if (value === null) isUpdate = true;
      if (isUpdate) {
        if (textFieldName && this.parent.dataModel[textFieldName] != null) {
          text = this.parent.dataModel[textFieldName];
        }
        // TODO: datetime-range 控件的特别处理
        const startFieldName: string | undefined = store.state['startFieldName'],
          endFieldName: string | undefined = store.state['endFieldName'];
        if (startFieldName && endFieldName) {
          const { [startFieldName]: startDate, [endFieldName]: endDate } = this.parent.dataModel;
          value = {
            // TODO: datetime-range 控件的特别处理
            [startFieldName]: startDate && dayjs(startDate),
            [endFieldName]: endDate && dayjs(endDate),
            _isObject: true,
          };
        }
        // END
        const _params: any = {
          value,
          text,
          isParentChange,
          actionSourceSign,
          noTriggerEvent: true,
        };
        // TODO: textbox 控件的特别处理
        const prefixFieldName: string | undefined = store.state['prefixFieldName'],
          suffixFieldName: string | undefined = store.state['suffixFieldName'];
        if (prefixFieldName || suffixFieldName) {
          if (prefixFieldName) _params.prefixValue = this.parent.dataModel[prefixFieldName];
          if (suffixFieldName) _params.suffixValue = this.parent.dataModel[suffixFieldName];
          // _params.text = store.state.text;
          // _params.value = store.state.value;
        }
        store.dispatch('updateValue', _params);
      }
    }
  }

  async updateColor() {
    const { colorCommandFn } = this._store.state;
    if (colorCommandFn) {
      this._store.dispatch('updateColor', {
        color: await colorCommandFn(this.parent.dataModel),
      });
    }
  }

  private async handleInitialStateAfter(
    e: ActionAfterEventArg<IFormItemState, FormItemActionType>,
  ) {
    const { colorCommandFn, title, requiredType, type, max, min, len } = e.newState.state,
      required = requiredType === RequiredTypeEnum.required,
      { message, whitespace = required, enum: _enum, pattern } = e.newState.state,
      _type: ValidationRuleType | undefined = type
        ? type
        : this._controlInstance instanceof ValueInstanceBase
        ? this._controlInstance.valueType
        : 'string';
    this.validator = new AsyncValidator(
      title,
      { type: _type, required, max, min, len, whitespace, enum: _enum, pattern, message },
      this._controlInstance ? this._controlInstance.eventManager : undefined,
    );
  }

  private handleUpdateReadonlyAfter(e: ActionAfterEventArg<IFormItemState>) {
    const { readonly } = e.newState.state;
    if (this._controlInstance instanceof ValueInstanceBase) {
      this._controlInstance.store.dispatch('updateReadonly', { readonly });
    }
  }

  private handleUpdateRequiredTypeAfter(
    e: ActionAfterEventArg<IFormItemState, FormItemActionType, { requiredType: RequiredTypeEnum }>,
  ) {
    if (this.validator) {
      this.validator.updateRule({
        required: e.params.requiredType === RequiredTypeEnum.required,
      });
    }
  }

  private formatValue(value: any) {
    if (value == null) return undefined;
    switch (this.valueType) {
      case 'string':
        value = value + '';
        break;
      case 'number':
        value = Number(value);
        break;
      case 'boolean':
        value = Boolean(value);
        break;
      case 'date':
        value = DateHelper.parse(value);
        break;
      default:
        break;
    }
    return value;
  }

  async valid(isParentChange: boolean = false) {
    if (this.validator && this._controlInstance instanceof ValueInstanceBase) {
      if (!this._store.state.display) return true;
      let value = this.formatValue(this._controlInstance.value);
      const validResult = await this.validator.valid(value);
      if (validResult !== true) {
        this.triggervValid(validResult.message, isParentChange ? this.store : undefined);
      } else {
        this.store.dispatch('validSuccess', {});
      }
      return validResult;
    }
  }

  triggervValid(message: string, actionSourceSign?: any) {
    this.store.dispatch('validError', { errorHelp: message, actionSourceSign });
  }
}
