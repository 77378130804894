import { INumberRangeConfig } from './typings';

const config: INumberRangeConfig = {
  title: '',
  autoFocus: false,
  startFieldName: 'startNumber',
  endFieldName: 'endNumber',
  startPlaceholder: undefined,
  endPlaceholder: undefined,
  format: '#,##0.00',
  max: 999999999999999,
  min: -999999999999999,
  businessType: undefined,
  modifyMode: undefined,
  textFieldName: 'text',
  displayMode: undefined,
  disabledMode: undefined,
};

export default config;
