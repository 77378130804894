import dayjs, { ConfigType, OpUnitType, QUnitType } from 'dayjs';
import zhCn from './locale/zh-cn';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import 'dayjs/plugin/utc';
import 'dayjs/plugin/isBetween';
import 'dayjs/plugin/isSameOrBefore';
import 'dayjs/plugin/isSameOrAfter';
import 'dayjs/plugin/quarterOfYear';

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(quarterOfYear);
dayjs.locale(zhCn);

function fixZero(val: number) {
  let value = '' + val;
  if (val < 10) value = '0' + val;
  return value;
}

export type DatetimeModeType = 'date' | 'time';

export default class DateHelper {
  static dayjs = dayjs;
  static countDays(year: string | number, month: string | number): number;
  static countDays(year: any, month: any) {
    let date;

    year = parseInt(year, 10);
    month = parseInt(month, 10);
    month--; // 月份从0开始算,上面就是从1开始算

    if (month === 11) {
      month = 0;
      year++;
    } else {
      month++;
    }

    date = new Date(year, month);
    date.setTime(date.getTime() - 100);
    return date.getDate();
  }

  static getMonths(_date: Date, _minDate?: Date, _maxDate?: Date) {
    let year = _date.getFullYear();
    let monthIndex = 1;
    let i = 1;
    let total = 12;
    let months: Array<{ text: string; value: number }> = [];
    if (_minDate && _maxDate) {
      if (_minDate.getFullYear() === year) {
        i = _minDate.getMonth() + 1;
      } else if (_maxDate.getFullYear() === year) {
        total = _maxDate.getMonth() + 1;
      }
    }
    let index = 0;
    for (i; i <= total; i++) {
      if (_date.getMonth() + 1 === i) {
        monthIndex = index;
      }
      months.push({
        value: i,
        text: fixZero(i),
      });
      index++;
    }
    return { months: months, monthIndex: monthIndex };
  }

  static getMonthDays(_date: Date, _minDate?: Date, _maxDate?: Date) {
    let year = _date.getFullYear();
    let month = _date.getMonth() + 1;
    let dayIndex = 1;
    let i = 1;
    let total = this.countDays(year, month);
    let days: Array<{ text: string; value: number; month: number; year: number }> = [];
    if (_minDate && _maxDate) {
      if (_minDate.getFullYear() === year && _minDate.getMonth() + 1 === month) {
        i = _minDate.getDate();
      } else if (_maxDate.getFullYear() === year && _maxDate.getMonth() + 1 === month) {
        total = _maxDate.getDate();
      }
    }
    let index = 0;
    for (i; i <= total; i++) {
      if (_date.getDate() === i) {
        dayIndex = index;
      }
      days.push({
        year,
        month,
        text: fixZero(i),
        value: i,
      });
      index++;
    }
    return { days: days, dayIndex: dayIndex };
  }

  static dateFormat2momentFormat(format: string = 'YYYY-MM-DD') {
    let _format: string,
      mode: DatetimeModeType = 'date',
      showTime = false;
    switch (format) {
      case 'HH:mm':
      case 'HH:mm:ss':
        _format = format;
        mode = 'time';
        break;
      case 'yyyy-MM-dd HH:mm':
        _format = 'YYYY-MM-DD HH:mm';
        showTime = true;
        break;
      case 'yyyy-MM-dd HH:mm:ss':
        _format = 'YYYY-MM-DD HH:mm:ss';
        showTime = true;
        break;
      case 'yyyy':
        _format = 'YYYY';
        break;
      case 'yyyy-MM':
        _format = 'YYYY-MM';
        break;
      case 'yyyy-MM-dd':
        _format = 'YYYY-MM-DD';
        break;
      default:
        _format = format;
        break;
    }
    return { format: _format, mode, showTime };
  }

  /**
   * 获取当前时间
   */
  static now() {
    return dayjs.utc().toDate();
  }

  /**
   * 格式化日期对象
   * @param date 日期对象
   * @param format 格式
   * @param isLocal 时区是否取本地,90默认取本地时区进行格式化
   * @returns 返回格式化后的字符串
   */
  static format(
    date: Date | number | string | undefined,
    format: string = 'YYYY-MM-DD',
    isLocal = true,
  ) {
    let _data = dayjs.utc(date || undefined);
    if (isLocal) _data = _data.local();
    if (!_data.isValid()) {
      console.error(`the ${date} is not utc format.`);
    }
    const { format: _format } = this.dateFormat2momentFormat(format);
    return _data.format(_format);
  }

  static format2utc(date: Date | number | string | undefined) {
    let _data = dayjs.utc(date || undefined);
    if (!_data.isValid()) {
      console.error(`the ${date} is not utc format.`);
    }
    return _data.format();
  }

  static format2user(date: Date | number | string | undefined, showNow = true, isLocal = true) {
    let _data = dayjs.utc(date || undefined),
      _format = 'MM-DD HH:mm:ss',
      now = dayjs();
    if (isLocal) _data = _data.local();
    if (!_data.isValid()) {
      console.error(`the ${date} is not utc format.`);
    }
    if (now.format('YYYY-MM-DD') === _data.format('YYYY-MM-DD')) {
      _format = showNow ? '今天 HH:mm:ss' : 'HH:mm:ss';
    } else if (now.set('d', now.day() - 1).format('YYYY-MM-DD') === _data.format('YYYY-MM-DD')) {
      _format = '昨天 HH:mm:ss';
    }
    return _data.format(_format);
  }

  /**
   * 将一个日期字符串转换成一个日期对象
   * @param value 受支持的日期字符串，格式为：yyyy-MM-dd HH:mm:ss
   * @returns 一个日期对象
   */
  static parse(value: Date | number | string | undefined, isLocal = false): Date | null {
    if (value) {
      if (isLocal) return dayjs(value || undefined).toDate();
      return dayjs.utc(value || undefined).toDate();
    }
    return null;
  }

  /**
   * 判断 Day.js 对象是否在其他两个的日期时间之间
   *
   * 文档: https://day.js.org/docs/zh-CN/query/is-between
   */
  static isBetween(
    value: dayjs.Dayjs | Date | number | string | undefined,
    a: ConfigType,
    b: ConfigType,
    c?: OpUnitType | null,
    d?: string,
  ) {
    return dayjs(value).isBetween(a, b, c, d);
  }

  /**
   * 判断 Day.js 对象是否和另一个提供的日期时间相同或在其之前
   *
   * 文档: https://day.js.org/docs/zh-CN/query/is-same-or-before
   */
  static isSameOrBefore(
    value: dayjs.Dayjs | Date | number | string | undefined,
    date: ConfigType,
    unit?: OpUnitType,
  ) {
    return dayjs(value).isSameOrBefore(date, unit);
  }

  /**
   * 判断 Day.js 对象是否和另一个提供的日期时间相同或在其之后
   *
   * 文档: https://day.js.org/docs/zh-CN/query/is-same-or-before
   */
  static isSameOrAfter(
    value: dayjs.Dayjs | Date | number | string | undefined,
    date: ConfigType,
    unit?: OpUnitType,
  ) {
    return dayjs(value).isSameOrAfter(date, unit);
  }
}
