import GridEdit from '..';
import React from 'react';
import Icon from '@mjcloud/icon';
import { Size } from '@mjcloud/types';
import { WhiteSpace } from '@mjcloud/ui';
import classNames from 'classnames';
import ControlBase from '../../base';
import { findDOMNode } from 'react-dom';
import globalData from '@mjcloud/global-data';
import { IGridEditState } from '../typings';
import { IViewModelRow } from '@mjcloud/data-model';
import { Button, Popconfirm, Tooltip } from 'antd';
import ResizeObserver from 'resize-observer-polyfill';
import Table from '../../../components/VirtualizedTable';
import { PARENTMINHEIGHT, SCROLLMINHEIGHT, SPACING } from '../../constant';
import { ITableComponents, ITableLocale } from '../../../components/VirtualizedTable/interface';
import OperationContentControl from '../../common/table/operationContent/pc';
import EditableComplexCellControl from '../gridedit-complex-cell/pc';
import EditableCellControl from '../gridedit-cell/pc';

import styles from './index.less';

export class TableCell extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  updateSize(size: Size) {
    this.setState({ size });
  }

  render() {
    const { record, children, style = {}, isVirtualizedCell, ...restProps } = this.props,
      { size } = this.state;
    let height = style.height;
    if (size && size.height) {
      height = size.height;
    }
    // return (
    //   <td style={{ ...style, height }} {...restProps}>
    //     {children}
    //   </td>
    // );
    return (
      <div style={{ ...style, height }} {...restProps}>
        {children}
      </div>
    );
  }
}

class GridEditControl extends ControlBase<IGridEditState, GridEdit> {
  private container: Element | null = null;
  private resizeObserver: ResizeObserver | null = null;

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.resizeObserver && this.container) {
      this.resizeObserver.unobserve(this.container);
      this.resizeObserver.disconnect();
      this.container = null;
    }
  }

  private onResize = () => {
    if (this.container) {
      const parentElementHeight = this.container.clientHeight,
        scrollHeight =
          parentElementHeight > PARENTMINHEIGHT ? parentElementHeight - SPACING : SCROLLMINHEIGHT;
      this.instance.store.dispatch('updateScrollHeight', { scrollHeight });
    }
  };

  private readyContainer = (instance: Table<IViewModelRow> | null) => {
    const container = findDOMNode(instance);
    if (container instanceof Element && container.parentElement) {
      const parentElementHeight = container.parentElement.clientHeight;
      if (parentElementHeight > PARENTMINHEIGHT) {
        this.container = container.parentElement;
        this.resizeObserver = new ResizeObserver(this.onResize);
        this.resizeObserver.observe(container.parentElement);
      }
    }
  };

  private TableCell: React.FC<any> = props => {
    const { rowId, cellId, record, cellType, store, children, ...restProps } = props;

    if (cellId === '_operation') {
      return <OperationContentControl {...props} />;
    } else if (cellType) {
      if (cellType === 'simple') {
        return <EditableCellControl {...props} />;
      } else {
        return <EditableComplexCellControl {...props} />;
      }
    } else {
      const cellRef = (container: TableCell | null) => {
        if (container && record._rid && cellId) {
          this.instance.__registerTableCell2Pure(cellId, record._rid, container);
        }
      };
      return (
        <TableCell ref={cellRef} record={record} {...restProps}>
          {children}
        </TableCell>
      );
    }
  };

  private handleAddClick = event => {
    if (event) event.preventDefault();
    if (this.state.addRowButton.loading) return;
    this.instance.store.dispatch('addClickAfter', {});
  };

  private renderButton = (onClick?: (e: any) => void) => {
    const { id, size, tip, icon, theme, title, disabled, loading } = this.state.addRowButton;
    const iconType = loading ? 'loading' : icon;
    const iconNode = iconType ? <Icon type={iconType} /> : null;
    const classes = classNames({
      ['ant-btn-loading']: theme === 'anchor' ? false : !!loading,
      [styles.loading]: !!loading,
      [styles[theme]]: true,
    });
    const _button = (
      <Button
        block
        key={id}
        size={size}
        type="dashed"
        onClick={onClick}
        disabled={disabled}
        className={classes}
      >
        {iconNode}
        {title}
      </Button>
    );
    if (tip) {
      return <Tooltip title={tip}>{_button}</Tooltip>;
    }
    return _button;
  };

  renderAddButton() {
    const { confirm } = this.state.addRowButton;
    if (!confirm) {
      return this.renderButton(this.handleAddClick);
    }
    return (
      <Popconfirm
        title={confirm.title}
        onConfirm={this.handleAddClick}
        okText="确定"
        cancelText="取消"
      >
        {this.renderButton()}
      </Popconfirm>
    );
  }

  renderContent() {
    const {
        modify,
        columns,
        totalWidth,
        dataSource,
        isFetching,
        showSummary,
        scrollHeight,
        errorMessage,
        addRowButton,
        _dataSource,
        summaryDataSource = [],
        configErrorMessage,
      } = this.state,
      isShowAddRowButton = modify && addRowButton.display,
      { formatMessage } = globalData,
      locale: ITableLocale = {
        emptyText: errorMessage ? errorMessage : formatMessage({ id: 'table.locale.emptyText' }),
      };
    if (configErrorMessage) return <>{configErrorMessage}</>;

    const components: ITableComponents = {
      body: {
        cell: this.TableCell,
      },
    };
    return (
      <>
        <Table<IViewModelRow>
          ref={this.readyContainer}
          bordered
          rowHeight={45}
          locale={locale}
          columns={columns}
          loading={isFetching}
          components={components}
          showSummary={summaryDataSource.length > 100 ? false : showSummary}
          className={styles.editable}
          scroll={{ x: 'max-content', y: isShowAddRowButton ? scrollHeight - 48 : scrollHeight }}
          summaryDataSource={summaryDataSource}
          dataSource={_dataSource}
          pagination={false}
          rowKey="_rid"
          size="small"
        />
        {isShowAddRowButton ? (
          <>
            <WhiteSpace />
            {this.renderAddButton()}
            <WhiteSpace />
          </>
        ) : null}
      </>
    );
  }
}

export default GridEditControl;
