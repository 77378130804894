import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { Filter, FilterTypeEnum, OperationTypeEnum } from '@mjcloud/utils';
import {
  ISimpleSearchState,
  ISimpleSearchInitialStateParams,
  ISimpleSearchUpdateValueParams,
  ISimpleSearchFindParams,
  ISimpleSearchItem,
} from './typings';

export class SimpleSearchReduce extends ReduceBase<ISimpleSearchState> {
  initialState(store: Store<ISimpleSearchState>, params: ISimpleSearchInitialStateParams) {
    const { initConfig, pageMode } = params,
      { styleName, displayMode } = initConfig,
      items: ISimpleSearchItem[] = [],
      tips: Array<string> = [];
    if (initConfig.items) {
      for (const item of initConfig.items) {
        items.push(item);
        tips.push(item.title);
      }
    }
    const { tip = `请输入${tips.length > 0 ? tips.join('/') : '关键字'}` } = initConfig,
      state: ISimpleSearchState = {
        items,
        tabIndex: -1,
        theme: styleName,
        placeholder: tip,
        config: initConfig,
        _nowValue: undefined,
        searchValue: undefined,
        configIsFetching: false,
        _filter: Filter.EqualFilter,
        display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      };
    return state;
  }

  updateValue(store: Store<ISimpleSearchState>, params: ISimpleSearchUpdateValueParams) {
    return { ...store.state, searchValue: params.value };
  }

  find(store: Store<ISimpleSearchState>, params: ISimpleSearchFindParams) {
    let state = store.state;
    if (state._nowValue === params.value) return state;
    state._nowValue = params.value;
    let filter: Filter;
    if (!!params.value) {
      filter = new Filter(FilterTypeEnum.or);
      for (var item of state.items) {
        filter.addCondition({
          left: item.left,
          right: params.value,
          op: OperationTypeEnum.like,
          title: item.title,
        });
      }
    } else {
      filter = Filter.EqualFilter;
    }
    state._filter = filter;
    return state;
  }
}

export default new SimpleSearchReduce();
