import { StringHelper, QueueCallback } from '@mjcloud/utils';
import { ExceptionHelper } from '@mjcloud/exception';
import { PageModeEnum, IDictionary } from '@mjcloud/types';

export type ListenCallback = (state: any, cb?: () => void) => void;

export interface IInitialStateParams<IConfig = IDictionary> {
  initConfig: IConfig;
  pageMode: PageModeEnum;
}

export interface IConfigStartLoadParams<IConfig = IDictionary> {
  initConfig: IConfig;
  callbackfn?: (initConfig: IConfig) => Promise<IDictionary>;
}

export interface IConfigLoadedParams<IConfig = IDictionary> {
  initConfig: IConfig;
}

export interface IConfigLoadErrorParams {
  errorMessage: string;
}

/**
 * 逻辑计算基类
 *
 * @description: 一个控件的实际操控者，约束：内部函数必须为纯函数
 */
export interface IReduce<S extends IState = IState> {
  initialState(store: Store<S>, params: IInitialStateParams): S;
  [key: string]: any;
}

export type ReduceDefaultActionType = 'initialState';
export type ReduceConfigActionType = 'configStartLoad' | 'configLoaded' | 'configLoadError';

export interface ActionBeforeEventArg<TParams = {}> {
  type: string;
  params: TParams;
}

export interface ActionAfterEventArg<
  S extends IState = IState,
  TDispatchType extends string = string,
  TParams = {}
> {
  type: string;
  params: TParams;
  newState: Store<S, TDispatchType>;
}

export interface ActionCatchEventArg<TParams = {}> {
  type: string;
  params: TParams;
  error: any;
}

export interface IState<TConfig = IDictionary> {
  tabIndex: number;
  display: boolean;
  configIsFetching: boolean;
  configErrorMessage?: string;
  config: TConfig;
}

export interface INewStoreParams {
  reduce: IReduce;
  id?: string;
  extendStore?: IDictionary;
}

function shallowequal(objA: any, objB: any) {
  return objA === objB;
}

function isInvalid(parentStore: Store, childStore: Store): boolean {
  if (parentStore === childStore) {
    return true;
  }
  if (parentStore === childStore.parent) {
    return true;
  }
  return false;
}

function sortStore(stores: Array<Store>): Array<Store> {
  let newStores: Array<{ store: Store; invalid?: boolean }> = [],
    i: number,
    j: number,
    parentStore: Store,
    results: Array<Store> = [];
  for (parentStore of stores) {
    newStores.push({ store: parentStore });
  }

  for (i = 0; i < newStores.length; i++) {
    parentStore = newStores[i].store;
    for (j = 0; j < newStores.length; j++) {
      if (i === j || !newStores[j].invalid) {
        continue;
      }
      if (isInvalid(parentStore, newStores[j].store)) {
        newStores[j].invalid = true;
      }
    }
  }

  for (var item of newStores) {
    if (!item.invalid) {
      results.push(item.store);
    }
  }
  return results;
}

export default class Store<S extends IState = IState, TDispatchType extends string = string> {
  private _id: string = '';
  private _state: S = { display: true, configIsFetching: true } as S;
  private _parent?: Store;
  private _reduce: IReduce;
  private _extendStores: IDictionary[] = [];
  private _viewListenCallback?: ListenCallback;
  private _queueCallback: QueueCallback = new QueueCallback();
  constructor(private params: INewStoreParams) {
    const { reduce, id, extendStore } = params;
    this._reduce = reduce;
    if (id) this._id = id;
    if (extendStore) this._extendStores = [extendStore];
  }

  public clone(): Store<S> {
    const store = new Store<S>(this.params);
    store['_state'] = { ...this.state };
    return store;
  }

  /**
   * 获取父级存储器
   */
  public get parent() {
    return this._parent as Store;
  }

  /**
   * 获取内部状态数据，禁止直接修改
   */
  public get state(): S {
    return this._state;
  }

  /**
   * 设置当前存储器的父级存储器
   * @param parent
   */
  public setParent(parent: Store) {
    if (this._parent) {
      throw ExceptionHelper.notSupportException('一个存储器只能指定一个父级存储器。');
    }
    this._parent = parent;
  }

  public bindExtendStore(extendStore: IDictionary) {
    this._extendStores.push(extendStore);
  }

  public unbindExtendStore(extendStore: IDictionary) {
    const index = this._extendStores.indexOf(extendStore);
    if (index > -1) {
      this._extendStores.splice(index, 1);
    }
  }

  /**
   * 设置View监听回调
   * @param callback
   */
  public bindViewListen(callback: ListenCallback) {
    this._viewListenCallback = callback;
  }

  public unbindViewListen(callback: ListenCallback) {
    if (this._viewListenCallback === callback) {
      this._viewListenCallback = undefined;
    }
  }

  private filterCallback(type: string, action: 'before' | 'after' | 'catch', e: any) {
    const _type = StringHelper.captureName(type),
      fnName = `handle${_type}${StringHelper.captureName(action)}`;
    let isExecute: boolean = false;
    for (const extendStore of this._extendStores) {
      let fn = extendStore[fnName] as Function;
      if (fn) {
        fn = fn.bind(extendStore);
        isExecute = true;
        fn(e);
      }
    }
    return isExecute;
  }

  private createCallback(type: string, params?: IDictionary) {
    return () => {
      try {
        let e: any = { type, params: params || {} };
        this.filterCallback(type, 'before', e);
        let fn: Function = this._reduce[type as string];
        if (fn && typeof fn === 'function') {
          fn = fn.bind(this._reduce);
          let result;
          try {
            result = fn(this, e.params);
          } catch (ex) {
            e.error = ex;
            if (!this.filterCallback(type, 'catch', e)) {
              ExceptionHelper.dispose(e.error);
            }
          }
          if (result) {
            this.updateStore(result, () => {
              e.newState = this;
              this.filterCallback(type, 'after', e);
            });
          }
        } else {
          const ex = ExceptionHelper.notImplementException(
            `控件${this._id} reduce中的 ${type} 方法未实现`,
          );
          ex.isBusiness = false;
          throw ex;
        }
      } catch (ex) {
        ExceptionHelper.dispose(ex);
      }
    };
  }

  /**
   * 动作分发
   * @param type 动作类型
   * @param params 动作参数
   */
  public dispatch<TParams extends IDictionary = {}>(type: TDispatchType, params: TParams) {
    this._queueCallback.addCallback(this.createCallback(type, params));
    // this.createCallback(type, params)()
  }

  private updateStore(state: any, cb?: () => void) {
    if (shallowequal(this._state, state) || !this._viewListenCallback) {
      this._state = state;
      if (cb) cb();
      return;
    }
    this._state = state;
    this._viewListenCallback(state, cb);
    // Store.addWaitProcessStore(this);
  }

  /*--------------- 异步处理监听回调 ----------------*/
  private static s_stores: Array<Store> | undefined;

  private static addWaitProcessStore(store: Store) {
    if (!store._viewListenCallback) return;
    if (!Store.s_stores) {
      Store.s_stores = [];
      setTimeout(Store.handProcessStore, 100);
    }
    Store.s_stores.push(store);
  }

  private static handProcessStore() {
    var stores: Array<Store> = Store.s_stores as Array<Store>;
    Store.s_stores = undefined;

    stores = sortStore(stores);
    for (var store of stores) {
      if (store._viewListenCallback) {
        try {
          store._viewListenCallback(store.state);
        } catch (ex) {
          ExceptionHelper.dispose(ex);
        }
      }
    }
  }

  public toJSON() {
    return this._state;
  }
}
