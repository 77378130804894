import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { DataModeEnum } from '@mjcloud/types';
import SelectInstanceBase from '../common/select';
import SelectExtendStoreBase from '../common/select/extendStore';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import {
  IRadioListConfig,
  IRadioListState,
  IRadioListConfigStartLoadParams,
  RadioListEventType,
  RadioListActionType,
} from './typings';

class RadioListExtendStore extends SelectExtendStoreBase<string, RadioList> {}

export class RadioList extends SelectInstanceBase<
  string,
  IRadioListState,
  RadioListEventType,
  RadioListActionType
> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  __createStore() {
    return new Store<IRadioListState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new RadioListExtendStore(this),
    });
  }

  getDataMode(): DataModeEnum {
    return DataModeEnum.all;
  }

  initialState(initConfig: IRadioListConfig) {
    this.store.dispatch<IRadioListConfigStartLoadParams>('configStartLoad', {
      initConfig,
    });
  }
}

export default RadioList;
