import Service from '@mjcloud/service';
import PageHelper from '@mjcloud/page-helper';
import globalData from '@mjcloud/global-data';
import { StringHelper } from '@mjcloud/utils';
import { IControlEvents, IDictionary, ControlConfig } from '@mjcloud/types';
import { InstanceBase } from '@mjcloud/instance';
import { ExceptionHelper } from '@mjcloud/exception';
import { ControlActionType } from '@mjcloud/instance/dist/base';
import {
  IState,
  ActionAfterEventArg,
  IConfigLoadedParams,
  IInitialStateParams,
  IConfigStartLoadParams,
  IConfigLoadErrorParams,
} from '@mjcloud/redux';

/**
 * 格式化原始配置，把相关设置全打平，达成所有控件都是小控件配置的格式
 * @param initConfig 原始配置
 */
function formatInitConfig<T>(initConfig: any): T {
  const { nodeName, layoutService, layout, ...restLayout } = initConfig;
  let config: any = { ...restLayout };
  if (initConfig.layout) {
    const { set, ...restSet } = initConfig.layout;
    config = { ...config, ...restSet };
    if (set) {
      config = { ...config, ...set };
    }
  }
  return config;
}

abstract class ExtendStoreBase<TInstance extends InstanceBase = InstanceBase> {
  public constructor(protected instance: TInstance) {
    this.init();
  }

  /**
   * 初始化方法，通过该方法可不需重写 constructor
   */
  protected init() {}

  private __handler2Fn(handler: string) {
    handler.trim();
    const page = this.instance.workbenchPart ? this.instance.workbenchPart : this.instance.page;
    return PageHelper.createPageScriptFunction(page, handler, true);
  }

  public async handleConfigStartLoadAfter(
    e: ActionAfterEventArg<IState, ControlActionType, IConfigStartLoadParams<IDictionary>>,
  ) {
    let { initConfig, callbackfn } = e.params,
      { nodeName, layoutService } = initConfig as ControlConfig,
      controlName = StringHelper.captureName(nodeName);
    try {
      // 1. 通过layoutService获取layout配置

      if (layoutService) {
        const values: IDictionary<string> = {};
        if (layoutService.items) {
          layoutService.items.forEach(item => {
            values[item.name] = item.value;
          });
        }
        const params = await PageHelper.getDynamicParamValues(this.instance.page, values, {});
        const config = await Service.getControlConfig<IDictionary>(
          this.instance.page.address,
          this.instance.id,
          params,
        );
        if (config) initConfig.layout = config;
      }

      // 2. 通过formatInitConfig打平initConfig
      initConfig = formatInitConfig(initConfig);

      // 3. 遍历initConfig, 对其值具备动态脚本化的key做标记(该遍历并到步骤4)
      // 需求：时间控件的min支持关联表单某个字段
      // 实现逻辑：在这里做标记并对其设置为null，把标记填入 _dynamicKeys 内，在不同控件内实现更新时机;
      // 例如上述需求则需要from控件在datamodel值更改事件和数据源加载完成事件中更新相应值。
      // initConfig._dynamicKeys = {};

      // 4. 遍历initConfig，把默认值覆盖上去
      if (globalData.__controlDefaults && globalData.__controlDefaults[controlName]) {
        const defaults = globalData.__controlDefaults[controlName];
        for (const key in defaults) {
          if (defaults[key]) {
            if (initConfig[key] == null) initConfig[key] = defaults[key];
            // if (PageHelper.valueIsDynamic(initConfig[key])) {
            //   initConfig._dynamicKeys[key] = initConfig[key];
            //   if (PageHelper.valueIsDynamic(defaults[key])) {
            //     initConfig[key] = null;
            //   } else {
            //     initConfig[key] = defaults[key];
            //   }
            // }
          }
        }
      }

      // 3. 先执行一遍动态值
      // const _dynamicValues = await PageHelper.getDynamicParamValues(
      //   this.instance.page,
      //   initConfig._dynamicKeys,
      //   {},
      // );
      // initConfig = { ...initConfig, ..._dynamicValues };

      let events: IControlEvents = {};
      if (initConfig.events) {
        events = initConfig.events;
      }
      for (const key in events) {
        const event = events[key];
        this.instance.eventManager.add(key, this.__handler2Fn(event));
      }
      if (callbackfn) {
        initConfig = await callbackfn(initConfig);
      }
      initConfig.nodeName = nodeName;
      e.params.initConfig = initConfig;
      e.newState.dispatch('configLoaded', e.params);
    } catch (error) {
      e.newState.dispatch<IConfigLoadErrorParams>('configLoadError', {
        errorMessage: '获取控件配置失败！',
      });
      if (!ExceptionHelper.isException(error)) {
        error.message = `获取控件 ${this.instance.id} 配置失败`;
      }
      ExceptionHelper.dispose(error);
    }
  }

  public handleConfigLoadedAfter(
    e: ActionAfterEventArg<IState, ControlActionType, IConfigLoadedParams>,
  ) {
    e.newState.dispatch<IInitialStateParams>('initialState', {
      ...e.params,
      pageMode: this.instance.page.pageMode,
    });
  }

  public handleConfigLoadErrorAfter(
    e: ActionAfterEventArg<IState, ControlActionType, IInitialStateParams>,
  ) {
    this.instance.controlInitComplete();
  }

  public handleInitialStateAfter(
    e: ActionAfterEventArg<IState, ControlActionType, IInitialStateParams>,
  ) {
    this.instance.controlInitComplete();
  }
}

export default ExtendStoreBase;
