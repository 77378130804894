import Reduce from './reduce';
import Store from '@mjcloud/redux';
import GridViewCell from './gridview-cell';
import TableInstanceBase from '../common/table';
import GridViewExtendStore from './extendStore';
import DataSource from '@mjcloud/data-source-helper';
import { ITableBaseRow } from '../common/table/typings';
import { IDictionary, PageControlMode, DataModeEnum } from '@mjcloud/types';
import { IGridViewConfig, IGridViewState, GridViewEventType, GridViewActionType } from './typings';

class GridView extends TableInstanceBase<
  ITableBaseRow[],
  ITableBaseRow,
  IGridViewState,
  GridViewCell,
  GridViewEventType,
  GridViewActionType
> {
  getDataMode(): DataModeEnum {
    return DataModeEnum.pagination;
  }

  get pageControlMode(): PageControlMode {
    return PageControlMode.gridView;
  }

  __createStore() {
    return new Store<IGridViewState, GridViewActionType>({
      reduce: Reduce,
      id: this.id,
      extendStore: new GridViewExtendStore(this),
    });
  }

  __getCellRecord(rowId: number) {
    for (const record of this.store.state.dataSource) {
      if (record._rid == rowId) return record;
    }
  }

  initialState(initConfig: IGridViewConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  protected buildExportFileExtraParams() {
    const { filterCondition } = this.store.state;
    if (filterCondition) {
      const { aggs, filter, orderBy } = DataSource.formatDataSourceParams(filterCondition);
      return { aggs, filter, orderBy };
    }
    return undefined;
  }

  getRowForRowId(rid: number): IDictionary | undefined {
    for (const row of this.store.state.dataSource) {
      if (row._rid === rid) return row;
    }
    return undefined;
  }

  findCellControl(rowIndex: number, cellId: string) {
    const { _rid } = this.store.state.dataSource[rowIndex],
      cell = this.findCell(cellId);
    if (cell) {
      return cell.getControlInstance(_rid);
    }
    return null;
  }

  findCellButton(rowIndex: number, cellId: string) {
    const { _rid } = this.store.state.dataSource[rowIndex],
      button = this.buttons[`${cellId}${_rid}`];
    return button;
  }

  /**
   * 手动设置列表的选中项
   * @param selectedRows 要选中的行数据
   */
  setSelectedRows(selectedRows: ITableBaseRow[], actionSourceSign?: any) {
    this.store.dispatch('updateSelectedRows', {
      selectedRows,
      actionSourceSign,
    });
  }

  setSelectedRows2Id(ids: string[], idFieldName = 'id', actionSourceSign?: any) {
    const selectedRows: ITableBaseRow[] = this.store.state.dataSource
      .map(row => {
        for (const id of ids) {
          if (id === row[idFieldName]) return row;
        }
        return undefined;
      })
      .filter(row => !!row) as ITableBaseRow[];

    this.store.dispatch('updateSelectedRows', {
      selectedRows,
      actionSourceSign,
    });
  }

  getAllData(): IDictionary[] {
    const { dataSource = [] } = this.store.state;
    return dataSource;
  }

  /**
   * 获取当前选中的行数据
   */
  getSelectedRows(): IDictionary[] {
    return this.store.state.selectedRows || [];
  }

  /**
   * 获取当前选中的行Id
   */
  getSelectedIds(idFieldName = 'id'): string[] {
    if (this.store.state.selectedRows) {
      return this.store.state.selectedRows.map(row => row[idFieldName]);
    }
    return [];
  }

  /**
   * 进入拖拽排序模式
   */
  enterSortMode(): void {}

  /**
   * 导出到Excel
   */
  exportExcel(): void {}
}

export default GridView;
