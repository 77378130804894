const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export default class StringHelper {
  /**
   * @description: 首字母大写
   * @param str
   * @return: string
   */
  public static captureName(str: string) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public static isUrl(path: string): boolean {
    return reg.test(path) || path.indexOf('//') === 0;
  }
}
