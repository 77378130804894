import Form from '..';
import React from 'react';
import ControlBase from '../../base';
import { IFormState } from '../typings';
import { Form as AntdForm, Spin } from 'antd';
import FormSectionControl from '../form-section/pc';

class FormControl extends ControlBase<IFormState, Form> {
  renderContent() {
    const { isFetching, configErrorMessage, sections } = this.state;
    if (configErrorMessage) return <>{configErrorMessage}</>;
    return (
      <Spin delay={600} spinning={isFetching}>
        <AntdForm id={this.props.id}>
          {sections.map(section => (
            <FormSectionControl
              store={section}
              key={section.state.id}
              id={section.state.id}
              parentId={this.instance.id}
              pageKey={this.instance.page.id}
            />
          ))}
        </AntdForm>
      </Spin>
    );
  }
}

export default FormControl;
