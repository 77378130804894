import { ControllerBase, IControllerInitData, IRequest } from './base';
import { IDictionary, OpenModeEnum, PageModeEnum, XmlParam } from '@mjcloud/types';
import PageHelper from '@mjcloud/page-helper';
import Service from '@mjcloud/service';
import { Modal } from 'antd';
import JsApiHelper from '@mjcloud/jsapi';
import ModalHelper from '@mjcloud/modal-helper';
import globalData from '@mjcloud/global-data';

interface IReportInfo {
  reportId: string;
  exportType?: 'EXCEL' | 'PDF' | 'WORD';
  directPrint?: boolean;
  directPreview?: boolean;
  params?: XmlParam[];
}

export interface ReportControllerInitData extends IControllerInitData {
  reportInfo: IReportInfo;
}

/**
 * 执行脚本请求对象类型
 */
export interface IReportRequest extends IRequest {
  id: string;
  params: IDictionary;
  reportInfo: IReportInfo;
}

/**
 * 执行脚本控制器
 */
export default class ReportController extends ControllerBase<ReportControllerInitData> {
  /**
   * 创建执行请求对象
   */
  public async createRequest(args: IDictionary): Promise<IReportRequest> {
    const { params, reportId: _reportId, ...reportInfo } = this.initData.reportInfo;
    const { reportId } = await PageHelper.getDynamicParamValues(
      this.page,
      { reportId: _reportId },
      args,
    );
    var request: IReportRequest = {
      id: this.id,
      params: {},
      reportInfo: { ...reportInfo, reportId },
    };
    const values: IDictionary<string> = {};
    if (params) {
      params.forEach(item => {
        values[item.name] = item.value;
      });
    }
    request.params = await PageHelper.getDynamicParamValues(this.page, values, args);
    return request;
  }

  /**
   * 执行控制器
   * @param request 执行时的请求对象
   */
  public async execute(request: IReportRequest): Promise<any> {
    const {
      params,
      reportInfo: { reportId, exportType, directPreview, directPrint },
    } = request;

    const reportInfo = await Service.getReportUrl({ reportId, params, directPreview, directPrint, exportType });
    if (directPreview) {
      console.info('reportInfo', reportInfo);
      if (reportInfo) {
        this.page.popupModal({ 
          pageInfo: {
            pageId: "Main",
            moduleId: "Iframe",
            appId: globalData.appId,
            width: 9999,
            height: 9999,
            pageMode: PageModeEnum.look,
            openModal: OpenModeEnum.tier,
            title: "打印报表"
          }
          , params: { ...reportInfo, isTier: 'true' } 
        });
      }
    }

    return reportInfo;
  }
}
