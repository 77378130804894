import React from 'react';
import { Radio } from 'antd';
import ControlBase from '../../base';
import { IRadioState } from '../typings';
import { RadioChangeEvent } from 'antd/lib/radio';
import { IValueControlUpdateValueParams } from '@mjcloud/instance/dist/valueInstanceBase';

class RadioControl extends ControlBase<IRadioState> {
  private handleChange = (event: RadioChangeEvent) => {
    const params: IValueControlUpdateValueParams = {
      value: event.target.value,
      text: '',
      actionSourceSign: this.instance.store,
    };

    for (const item of this.state.dataSource) {
      if (item._value == params.value) {
        params.text = item._text;
      }
    }
    this.instance.store.dispatch<IValueControlUpdateValueParams>('updateValue', params);
  };

  renderContent() {
    if (this.state.readonly) return <>{this.state.text || ''}</>;
    if (this.state.errorMessage) return <>{this.state.errorMessage}</>;
    const { dataSource, value } = this.state;
    console.log('renderContent', this.props.id, value);

    return (
      <Radio.Group id={this.props.id} onChange={this.handleChange} value={String(value)}>
        {dataSource.map(({ _text, _value, _disabled }) => {
          return (
            <Radio key={_value} value={String(_value)} disabled={_disabled}>
              {_text}
            </Radio>
          );
        })}
      </Radio.Group>
    );
  }
}
export default RadioControl;
