import Store from '@mjcloud/redux';
import Service from '@mjcloud/service';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { mapFileType, mapFileType2Icon } from './tool';
import {
  IFileManagerState,
  IFileManagerInitialStateParams,
  IFileManageUpdateFileListParams,
  IUploadFile,
} from './typings';

export class FileManagerReduce extends ReduceBase<IFileManagerState> {
  initialState(store: Store<IFileManagerState>, params: IFileManagerInitialStateParams) {
    const { initConfig, pageMode } = params;
    const config = initConfig;
    const { title, theme = 'default' } = initConfig;
    const {
      displayMode,
      modifyMode,
      maxFileCount = -1,
      businessType = '',
      businessId,
      styleName: listType = 'avatar',
    } = config;
    let placeholder = config.placeholder || `点击上传${title || ''}`;
    let iconfont = 'icon-right';
    if (theme !== 'default') {
      iconfont = 'icon-caret-down';
    }
    const state: IFileManagerState = {
      title,
      theme,
      listType,
      iconfont,
      businessId,
      placeholder,
      tabIndex: -1,
      fileList: [],
      businessType,
      maxFileCount,
      loading: false,
      uploadProps: {},
      config: initConfig,
      configIsFetching: false,
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  startLoad(store: Store<IFileManagerState>, params) {
    const state = {
      ...store.state,
      isFetching: true,
    };
    if (params.uploadProps) state.uploadProps = params.uploadProps;
    return state;
  }

  loaded(store: Store<IFileManagerState>, params) {
    const fileList = params.dataSource.map(({ id, name, size, type, url }) => {
      const file = {
        id,
        name,
        size,
        uid: id,
        status: 'done',
        type: mapFileType(type),
        url: Service.getUrl(url),
        ...mapFileType2Icon(type),
      };
      return file;
    });
    return { ...store.state, fileList, isFetching: false };
  }

  loadError(store: Store<IFileManagerState>, params) {
    return {
      ...store.state,
      errorMessage: params.errorMessage,
      isFetching: false,
    };
  }

  updateFileList(store: Store<IFileManagerState>, params: IFileManageUpdateFileListParams) {
    const { maxFileCount, listType } = store.state;
    let fileList = [...params.fileList];
    fileList = fileList
      .filter(file => file.status != 'error')
      .map(file => {
        if (file.status != 'uploading' && file.response && file.response.data) {
          const { url, id } = file.response.data;
          file = { ...file, ...file.response.data };
          file.url = Service.getUrl(url);
          file.thumbUrl = file.url;
          file['previewUrl'] = file.url;
          file.uid = id;
        }
        return file;
      });
    if (listType === 'avatar') {
      fileList = [fileList[fileList.length - 1]];
    } else if (maxFileCount > 0) {
      fileList = fileList.slice(-maxFileCount);
    }
    return { ...store.state, fileList, loading: params.loading } as IFileManagerState;
  }

  removeFile(store: Store<IFileManagerState>, params: { index?: number; file?: IUploadFile }) {
    if (params.index != null) {
      const { fileList = [] } = store.state;
      let _fileList = [...fileList];
      _fileList.splice(params.index, 1);
      return { ...store.state, fileList: _fileList };
    }
    if (params.file != null) {
      const { uid } = params.file;
      const fileList = store.state.fileList.filter(f => f.uid !== uid);
      return { ...store.state, fileList };
    }
    return store.state;
  }
}

export default new FileManagerReduce();
