import Reduce from './reduce';
import Store from '@mjcloud/redux';
import PageHelper from '@mjcloud/page-helper';
import { ValueInstanceBase } from '@mjcloud/instance';
import { ValueExtendStoreBase } from '@mjcloud/extend-store';
import { IDatetimeState, IDatetimeConfig } from './typings';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { EventListening, DateHelper } from '@mjcloud/utils';

class DatetimeExtendStore extends ValueExtendStoreBase<Date | null, Datetime> {
  handleInitialStateAfter(e: any) {
    super.handleInitialStateAfter(e);
    this.instance._updateMaxAMinValue(e.newState.state);
  }
}

class Datetime extends ValueInstanceBase<Date | null, IDatetimeState> {
  get valueType(): ValidationRuleType {
    return 'date';
  }

  __createStore() {
    return new Store<IDatetimeState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new DatetimeExtendStore(this),
    });
  }

  initialState(initConfig: IDatetimeConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  async _updateMaxAMinValue(state: IDatetimeState, data: any = {}) {
    const { minControl, maxControl } = state,
      params: any = {};
    if (minControl) {
      const fn = PageHelper.createPageScriptFunction(this.page, minControl, true);
      params.min = await fn(EventListening.getHandlerArg(this, data));
    }
    if (maxControl) {
      const fn = PageHelper.createPageScriptFunction(this.page, maxControl, true);
      params.max = await fn(EventListening.getHandlerArg(this, data));
    }
    this.store.dispatch('updateMaxAMinValue', params);
  }

  format(value?: string | number | Date, config: any = {}) {
    if (value == null) return undefined;
    const { format = config.format } = this.store.state;
    if (typeof value === 'string' || typeof value === 'number' || value instanceof Date) {
      return DateHelper.format(value, format);
    }
    return value;
  }
}

export default Datetime;
