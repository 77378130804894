/**
 * 多次请求单次回调管理器
 */
export default class MultiRequestSingleCallback {
  private _index = 0;
  private _callback: (e: any, isValid: boolean, data: any) => void;
  private _catchCallback: (e: any, reason: any) => void;

  constructor(
    callback: (e: any, isValid: boolean, data: any) => void,
    catchCallback: (e: any, reason: any) => void,
  ) {
    this._callback = callback;
    this._catchCallback = catchCallback;
  }

  /**
   * 执行一个异步的请求，执行完成后调用回调函数
   * @param fn 要执行的请求
   */
  public request(e: any, fn: Function) {
    let index = ++this._index;
    try {
      let result = fn();
      if (result instanceof Promise) {
        // 异步函数
        result
          .then(data => {
            this.callback(e, index, data);
          })
          .catch(reason => {
            this._catchCallback(e, reason);
          });
      } else {
        // 同步函数
        this.callback(e, index, result);
      }
    } catch (error) {
      this._catchCallback(e, error);
    }
  }

  /**
   * 执行一个异步的请求，在执行函数中调用传入的完成函数来引发回调函数
   * @param fn 要执行的请求
   */
  public callbackRequest(e: any, fn: (finish: (data: any) => void) => void) {
    let index = ++this._index;
    let result: any = fn(data => {
      this.callback(e, index, data);
    });
    if (result instanceof Promise) {
      result.then();
    }
  }

  private callback(e: any, index: number, data: any) {
    this._callback(e, this._index === index, data);
  }
}
