import Store from "@mjcloud/redux";
import Reduce from "./reduce";
import { InstanceBase } from "@mjcloud/instance";
import ModuleExtendStore from "./extendStore";

export default class Module extends InstanceBase {
  __createStore() {
    return new Store({
      id: this.id,
      reduce: Reduce,
      extendStore: new ModuleExtendStore(this)
    });
  }

  initialState(initConfig) {
    this.store.dispatch("configStartLoad", {
      initConfig
    });
  }

  refresh(params = {}) {
    this.store.dispatch("startLoad", params);
  }
}
