import Store from '@mjcloud/redux';
import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { ITextAreaConfig, ITextAreaState, ITextAreaInitialStateParams } from './typings';

export class TextAreaReduce extends ValueReduceBase {
  initialState(store: Store<ITextAreaState>, params: ITextAreaInitialStateParams): ITextAreaState {
    const { initConfig, pageMode } = params,
      { title, autoFocus, modifyMode } = initConfig,
      { value = store.state.value, placeholder = `请输入${title || ''}` } = initConfig,
      { maxLength, minLength, disabledMode, displayMode } = initConfig;
    const state: ITextAreaState = {
      title,
      value,
      autoFocus,
      minLength,
      maxLength,
      placeholder,
      tabIndex: 0,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    return state;
  }
}

export default new TextAreaReduce();
