import React from 'react';
import { Skeleton } from 'antd';
import WorkbenchPart from './part';
import WorkbenchGroup from './group';
import ControlBase from '../../core/base';
import { IWorkbenchState, IWorkbenchPart, IWorkbenchGroup } from '@mjcloud/workbench/dist/typings';

import styles from './index.less';

export default class WorkbenchControl extends ControlBase<IWorkbenchState> {
  renderContent() {
    const { gutter, parts, isFetching } = this.state,
      length = parts.length > 2 ? parts.length : 4;
    console.log(parts, gutter);
    return (
      <div className={styles.dyWorkbench}>
        {isFetching ? (
          <div className={styles.dyWorkbenchSkeleton}>
            {Array.from({ length }, (_, i) => (
              <Skeleton key={i} active avatar />
            ))}
          </div>
        ) : (
          parts.map((row, i) => {
            if (row.nodeName === 'part') {
              const { id, name, size, width, height } = row as IWorkbenchPart;
              return (
                <WorkbenchPart
                  {...this.props}
                  key={i}
                  id={id}
                  size={size}
                  name={name}
                  width={width}
                  height={height}
                />
              );
            } else if (row.nodeName === 'group') {
              return <WorkbenchGroup {...this.props} key={i} group={row as IWorkbenchGroup} />;
            }
            return null;
          })
        )}
      </div>
    );
  }
}
