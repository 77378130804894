import React from 'react';
import styled from 'styled-components';
import ControlBase from '../../core/base';
import { Skeleton as AntSkeleton } from 'antd';
import FormSectionControl from './FormSection';
import { px2rem, SPACING } from '../../constant';
import { IFormState } from '@mjcloud/mcontrols/dist/controls/form/typings';

const Skeleton = styled(AntSkeleton)`
  padding: ${px2rem(SPACING * 4)};
`;

export default class FormControl extends ControlBase<IFormState> {
  renderContent() {
    const { isFetching, sections } = this.state;
    if (isFetching) return <Skeleton active paragraph={{ rows: 8 }} />;
    return sections.map(section => <FormSectionControl key={section.id} {...section} />);
  }
}
