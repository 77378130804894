import Draft from './draft';
import Store from '@mjcloud/redux';
import Reduce from './reduce';
import globalData from '@mjcloud/global-data';
import PageHelper from '@mjcloud/page-helper';
import { ConsoleHelper } from '@mjcloud/utils';
import PageExtendStore from './extendStore';
import ViewModelHelper from '@mjcloud/data-model';
import ControllerHelper from '@mjcloud/controller';
import { TemplateLogicBase, LogicBase } from '@mjcloud/logic';
import { InstanceBase, ContainerInstanceBase } from '@mjcloud/instance';
import ModalHelper, { IPopupModalParams, ModalConfig } from '@mjcloud/modal-helper';
import DataSource, { IData, IDataSourceParameters } from '@mjcloud/data-source-helper';
import { PageModeEnum, DataModeEnum, IDictionary, ComponentConfig } from '@mjcloud/types';
import {
  IPageState,
  PageAddress,
  PageEventType,
  PageInitConfig,
  IExclusiveLock,
  IPageRefreshParams,
  PageStoreActionType,
  IPopupModalResult,
} from './typings';
// import Workflow from './workflow';

const controlIndex: IDictionary<number> = {};

export default class Page extends ContainerInstanceBase<
  IPageState,
  PageEventType,
  PageStoreActionType
> {
  public static info: (message: string) => any = console.info;

  private _components: IDictionary = {};
  private _componentsConfig: ComponentConfig[] = [];

  private _lockCount = 0;
  private _dataUpdatedControls: Array<string> = [];
  private _exclusiveLock: IExclusiveLock = {
    id: 0,
    counter: 0,
    message: '当前正在执行其他操作，请稍后重试！',
  };
  private _previousPageKey?: string;
  private _draft?: Draft;
  private _workflow?: any;
  private _storage = ViewModelHelper.createViewModel();
  private _logic?: LogicBase;
  private _template?: TemplateLogicBase;
  private iframeId: string;

  protected get previous() {
    if (this._previousPageKey) {
      return globalData.rbCore.getPage(this._previousPageKey);
    }
    return null;
  }

  /**
   *
   * @param id
   * @param parent
   * @param initConfig 页面信息
   */
  constructor(
    id: string,
    iframeId: string,
    parent: ContainerInstanceBase,
    initConfig: PageInitConfig,
  ) {
    super(id, parent);
    if (globalData.__setDraftTip) globalData.__setDraftTip(undefined);
    this.iframeId = iframeId;
    this.store.dispatch('configStartLoad', { initConfig });
  }

  public __createStore() {
    return new Store<IPageState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new PageExtendStore(this),
    });
  }

  private get iframe() {
    return this.parent.findControl<any>(this.iframeId);
  }

  public __setLogic(logic: LogicBase) {
    this._logic = logic;
  }

  public __setTemplate(template: TemplateLogicBase) {
    this._template = template;
  }

  /// start: 供外部使用的属性和方法
  /**
   * 逻辑页实例
   *
   * 当该页面没有逻辑页时返回 {}
   */
  public get logic(): LogicBase {
    return (this._logic || {}) as LogicBase;
  }

  public get template(): TemplateLogicBase {
    return (this._template || {}) as TemplateLogicBase;
  }

  public get page() {
    const page: any = this;
    return page;
  }

  public get dataModel() {
    return this._storage;
  }

  public findComponent<T>(componentId: string): T | undefined {
    return this._components[componentId] as T;
  }

  /**
   * 页面模式
   */
  public get pageMode(): PageModeEnum {
    let pageMode = PageModeEnum.none;
    if (
      this.store.state.info &&
      this.store.state.info.address &&
      this.store.state.info.address.pageMode
    ) {
      pageMode = this.store.state.info.address.pageMode;
    }
    return pageMode;
  }

  /**
   * 模板页配置参数
   */
  public get templateConfigParams(): IDictionary {
    return this.store.state.configParams || {};
  }

  /**
   * 获取当前页面地址
   */
  public get address(): PageAddress {
    return this.store.state.info.address;
  }

  /**
   * 获取当前页面地址的路径部分
   */
  public get pathname(): string {
    return PageHelper.formatPagePath(this.address);
  }

  _getPageParams() {
    const {
      _title,
      pageId,
      pageMode,
      __iframePageKey,
      __parentPageKey,
      ...pageParams
    } = this.getParams();
    return pageParams;
  }

  /**
   * 获取当前页面控制器执行参数
   */
  getControllerExecParams() {
    if (this._draft) {
      return this._draft.getControllerExecParams();
    }
    return {
      pageParams: this._getPageParams(),
      pageAddress: this.address,
    };
  }

  /**
   * 执行一个控制器
   * @param controllerId 控制器Id
   * @param data 额外的参数
   */
  public async executeController(controllerId: string, data: IDictionary = {}, sender: any = this) {
    await ControllerHelper.execute({ controllerId, sender, data });
    if (data.e) throw data.e;
    return data.result;
  }

  public async downloadFile(fileId: string, title = '下载文件', okText = '下载') {
    return globalData.__downloadFile(fileId, title, okText);
  }

  /**
   * 获取数据源数据
   * @param data 数据源配置
   * @param dataMode 数据源请求模式，默认为all
   * @param params 数据源可选项，配置筛选、定位、排序等功能
   */
  public async getDataSource(
    data: IData,
    dataMode: DataModeEnum = DataModeEnum.all,
    params?: IDataSourceParameters,
  ) {
    const result = await DataSource.loadData({
      page: this.page,
      isFirst: false,
      data,
      dataMode,
      params,
    });
    return result;
  }

  /**
   * 把多个输入值拼接成数值转换成数组返回
   */
  public transformValue(...args: any[]) {
    if (args.length == 1) {
      return args[0];
    } else if (args.length > 0) {
      return args;
    }
    return undefined;
  }

  /**
   * 保存草稿
   */
  saveDraft() {
    if (this._draft) this._draft.saveDraft();
  }

  __prompt() {
    if (this._draft) return true;
    if (!this.store.state.isDataUpdated) return true;
    return window.confirm(`填写的资料尚未保存，确定要离开吗？`);
  }

  __isDataUpdated() {
    const { isDataUpdated, isPrompt } = this.store.state;
    if (this.pageMode === PageModeEnum.look) return false;
    if (isPrompt) return isDataUpdated;
    return false;
  }

  /**
   * 更新页面的 state, 一般用于自定义逻辑页中自定义数据
   * @param state
   */
  public updateState<D extends IDictionary>(state: D) {
    this.store.dispatch('updateState', state);
  }

  /**
   * 获取当前页面指定参数的值
   * @param key 参数名称
   */
  public getParam<T = string>(key: string): T | undefined {
    if (this.store.state.info && this.store.state.info.params) {
      return this.store.state.info.params[key];
    }
    return undefined;
  }

  getParams(): IDictionary {
    if (this.store.state.info && this.store.state.info.params) {
      return this.store.state.info.params;
    }
    return {};
  }

  private getInnerParams(): IDictionary {
    const params = this.getParams();
    const innerParams: IDictionary = {};
    for (const key in params) {
      if (key.charAt(0) === '_') {
        innerParams[key] = params[key];
      }
    }
    return innerParams;
  }

  /**
   * 增加全局锁
   */
  public increaseLock(controlId: string): void {
    this._lockCount++;
    ConsoleHelper.log2reduce(`lock-increaseLock：${controlId}`, this._lockCount, this.id);
  }
  /**
   * 减少全局锁
   */
  public decreaseLock(controlId: string): void {
    this._lockCount--;
    if (this._lockCount < 0) {
      console.error('decreaseLock', `锁异常: ${controlId}; lockCount: ${this._lockCount}`);
    }
    ConsoleHelper.log2reduce(`lock-decreaseLock：${controlId}`, this._lockCount, this.id);
  }
  /**
   * 当前页面是否已锁定
   */
  public isLock(): boolean {
    if (this._lockCount || this._exclusiveLock.id) {
      return true;
    }

    // let parent = this.instance.parent;
    // if (parent) {
    //   return parent.isLock();
    // }

    return false;
  }
  /**
   * 申请独占锁
   * @param msg 锁提示信息
   */
  public applyExclusiveLock(msg: string): number {
    ConsoleHelper.log2reduce('lock-applyExclusiveLock', this._lockCount, this.id);

    if (this._lockCount) {
      Page.info('当前正在加载处理，请稍后重试！');

      return 0;
    }
    if (this._exclusiveLock.id) {
      Page.info(this._exclusiveLock.message);

      return 0;
    }

    // let parent = this.instance.parent;
    // if (parent) {
    //   return parent.applyExclusiveLock(msg);
    // }
    this._exclusiveLock.id = ++this._exclusiveLock.counter;
    this._exclusiveLock.message = msg || '';
    return this._exclusiveLock.id;
  }
  /**
   * 释放独占锁
   * @param exclusiveLockId 当前要释放的独占锁id
   */
  public releaseExclusiveLock(exclusiveLockId: number): void {
    // let parent = this.instance.parent;
    // if (parent) {
    //   parent.releaseExclusiveLock(exclusiveLockId);
    //   return;
    // }

    if (this._exclusiveLock.id === exclusiveLockId) {
      this._exclusiveLock.id = 0;
    }
  }

  /**
   * 当前页面下是否有未修改的数据
   */
  get isDataUpdated() {
    return this.store.state.isDataUpdated;
  }
  /**
   * 控件数据已发生变更
   * @param controlId 数据发生变化的控件id
   */
  controlDataUpdated(controlId: string) {
    if (this._dataUpdatedControls.indexOf(controlId) < 0) {
      this._dataUpdatedControls.push(controlId);
    }
    this.store.dispatch('updateIsDataUpdated', { isDataUpdated: true });
  }
  /**
   * 控件数据的变化已经提交完成
   * @param controlId 数据发生变化的控件id
   */
  controlDataSubmitFinish(controlId: string) {
    let index = this._dataUpdatedControls.indexOf(controlId);
    if (index >= 0) {
      this._dataUpdatedControls.splice(index, 1);
    }
  }
  /**
   * 页面数据提交完成
   */
  public dataSubmitFinish(): void {
    for (let controlId of this._dataUpdatedControls) {
      const instance: any = this.findControl(controlId);
      if (instance && instance.dataSubmitFinish) instance.dataSubmitFinish();
    }
    this._dataUpdatedControls = [];
    this.store.dispatch('updateIsDataUpdated', { isDataUpdated: false });
  }

  /**
   * 设置页面具备引导指南
   */
  public async setGuide(params: IDictionary = {}) {
    const key = `${PageHelper.formatPagePath(this.address)}_guide`,
      guide = await globalData.storage.get(key);
    if (!guide) {
      this.store.dispatch('updateState', { guide: params });
      await globalData.storage.set(key, true);
    }
  }

  /**
   * 关闭页面
   * @param param
   */
  public close(param: IDictionary = {}): void {
    if (!this.iframe) return;
    const { Iframe } = globalData.controlTypes;
    if (Iframe && this.iframe instanceof Iframe && this.__isDataUpdated()) {
      if (this.__prompt()) this.iframe.close({ oper: 'cancel', ...param });
    } else {
      this.iframe.close({ oper: 'cancel', ...param });
    }
  }

  /**
   * 在 history 中后退
   * @param param
   */
  public async back(param: IDictionary = {}) {
    if (!this.iframe) return;
    const { Iframe } = globalData.controlTypes;
    if (Iframe && this.iframe instanceof Iframe && this.__isDataUpdated()) {
      if (this.__prompt()) this.iframe.back(param);
    } else {
      this.iframe.back(param);
    }
  }

  /**
   * 关闭所有页面，回退到`history`起止位置，一般是首页
   *
   * 在移动端，如果通过`Module`控件跳转到新的模块，则回退到该模块页面，而不是首页
   */
  public reLaunch() {
    if (!this.iframe) return;
    const { Iframe } = globalData.controlTypes;
    if (Iframe && this.iframe instanceof Iframe && this.__isDataUpdated()) {
      if (this.__prompt()) this.iframe.reLaunch();
    } else {
      this.iframe.reLaunch();
    }
  }

  /**
   * 刷新当前页面
   */
  public refresh(): void;
  /**
   * 根据传入的`src`刷新页面
   * @param src 要更新的地址
   */
  public refresh(src: string): void;
  /**
   * 刷新页面
   * @param params 参数
   */
  public refresh(params: IPageRefreshParams): void;
  public refresh(params?: string | IPageRefreshParams) {
    if (!this.iframe) return;
    if (typeof params === 'string') {
      // TODO: 支持通过src跳转
      console.warn('refresh方法暂不支持 src 参数');
    } else {
      const moduleCode = this.getParam('_moduleCode');
      const { pageId, moduleId, appId, pageMode, ...preParams } = this.getParams();

      if (!params) params = { pageId: this.address.pageId };
      if (!params.params) params.params = { ...preParams };
      else params.params = { ...preParams, ...params.params };

      params.appId = params.appId || this.address.appId;
      params.pageMode = params.pageMode || this.address.pageMode;

      if (moduleCode && params.params._moduleCode === undefined) {
        params.params._moduleCode = moduleCode;
      }
      const { actionMode = 'PUSH' } = params;
      if (actionMode === 'PUSH') {
        this.iframe.push(params);
      } else {
        this.iframe.replace(params);
      }
    }
  }

  /**
   * 通过弹层的方式打开一个页面
   * @param param
   */
  public popupModal<T extends IPopupModalResult = IPopupModalResult>(param: IPopupModalParams) {
    return new Promise<T>(resolve => {
      const { afterCallback, closeAfter } = param;
      let modal: any;
      ModalHelper.popupModal({
        ...param,
        afterCallback: modalId => {
          const { pageInfo, params } = param;
          modal = this.__createControl<ModalConfig>(modalId, {
            id: modalId,
            params,
            pageInfo,
            nodeName: 'Modal',
          });
          this.store.dispatch('addModal', { id: modalId });
          if (afterCallback) afterCallback(modalId);
        },
        closeAfter: (modalId, _param) => {
          if (modal && modal.close)
            modal.close(() => {
              this.store.dispatch('deleteModal', { id: modalId });
              this.page.eventManager.trigger('close', _param);
              resolve(_param as T);
            });
          if (closeAfter) closeAfter(modalId, _param);
        },
      });
    });
  }

  /// end

  public __setPreviousPage(pageKey: string) {
    this._previousPageKey = pageKey;
  }

  public __controlInitStart(controlId: string) {
    this.store.dispatch('increaseControlInitCount', { controlId });
  }

  public __controlInitComplete(controlId: string) {
    this.store.dispatch('decreaseControlInitCount', { controlId });
  }

  public __controlLoadStart(controlId: string) {
    this.store.dispatch('increaseControlLoadCount', { controlId });
  }

  public __controlLoadComplete(controlId: string) {
    this.store.dispatch('decreaseControlLoadCount', { controlId });
  }

  /**
   * 根据 controlId 从页面配置获取控件配置信息并创建控件
   * @description: 主要用在 renderControl 上，容器控件中也会调用
   * @param controlId
   * @return: InstanceBase | null
   */
  public createControl(
    controlId: string,
    extra: IDictionary = {},
    isRepeat = false,
  ): InstanceBase | null {
    const state = this.store.state;
    if (state.config) {
      const { controls } = state.config;
      if (controls) {
        for (const control of controls.items) {
          if (controlId === control.id) {
            if (!control.nodeName) {
              console.warn(`页面 ${this.pathname} 中的控件 ${controlId} 中不存在nodeName节点`);
              return null;
            }
            if (isRepeat) {
              if (!controlIndex[controlId]) controlIndex[controlId] = 1;
              controlIndex[controlId]++;
              controlId += controlIndex[controlId];
            }
            const controlInstance = this.__createControl(controlId, { ...control, ...extra });
            if (controlInstance) {
              this.store.dispatch('registerControl', {
                controlId,
                controlStore: controlInstance.store,
              });
            }
            return controlInstance;
          }
        }

        console.warn(`页面 ${this.pathname} 中的 controls 节点不存在 ${controlId}`);
        return null;
      }
    }
    return null;
  }

  public destroy() {}
}
