import globalData from '@mjcloud/global-data';
import JsApiHelper from '@mjcloud/jsapi';
import { PageModeEnum } from '@mjcloud/types';
import { IRouteComponentProps } from './typings';

export { IRouteComponentProps } from './typings';

export interface IPushParams {
  pathname: string;
  search?: string;
  state?: any;
  /** 路由跳转前回调 */
  beforeCallback?: () => void;
  /** 路由跳转后回调 */
  afterCallback?: () => void;
  /** 路由回退后回调 */
  backAfter?: () => void;
}

export interface historyRecordItem extends IPushParams {}

export default class RouterHelper {
  private static historyRecord: historyRecordItem[] = [];
  private static props: IRouteComponentProps;
  /**
   * 在web端初始化全局路由器
   */
  static _create(props: IRouteComponentProps) {
    this.props = props;
    this.setNavigationBar(props);
  }

  static get match() {
    return this.props.match;
  }

  static get query() {
    let { query = {}, state: _params = {} } = this.props.location,
      params = { ...query, ..._params };
    return params;
  }

  static getModuleId(props: IRouteComponentProps = this.props) {
    // /appId/modulePId/modulePId2/.../moduleId
    const { moduleIds } = props.match.params;
    if (moduleIds) {
      const moduleIdArr = moduleIds.split('/'),
        length = moduleIdArr.length,
        moduleId = moduleIdArr[length - 1];
      return moduleId;
    } else {
      throw Error(`moduleId 不能为空:${props.location.pathname}`);
    }
  }

  static getPageMode(props: IRouteComponentProps = this.props): PageModeEnum {
    let { query = {}, state: _params = {} } = props.location,
      params = { ...query, ..._params },
      { pageMode = PageModeEnum.none } = params;
    if (typeof pageMode != 'number') {
      pageMode = Number(pageMode);
      if (isNaN(pageMode)) {
        pageMode = PageModeEnum.none;
      }
    }
    return pageMode;
  }

  static getPageId(props: IRouteComponentProps = this.props): string {
    let { query = {}, state: _params = {} } = props.location,
      params = { ...query, ..._params },
      { pageId = 'Main' } = params;
    return pageId;
  }

  /**
   * 页面弹层处理
   */
  private static pageLayer(props: IRouteComponentProps, nextProps: IRouteComponentProps) {
    const { history, location } = nextProps,
      { pathname: _pathname } = location,
      { pathname: _lastPathname } = props.location,
      moduleId = this.getModuleId(nextProps),
      lastModalId = this.getModuleId(props);
    if (moduleId !== lastModalId) {
      if (history.action === 'POP') {
        // 关闭弹层
        if (lastModalId) {
          for (const { pathname, backAfter } of this.historyRecord) {
            if (pathname === _lastPathname) {
              if (backAfter) setTimeout(backAfter, 0);
            }
          }
        }
      } else if (history.action === 'PUSH') {
        // 弹层
        if (moduleId) {
          for (const { pathname, afterCallback } of this.historyRecord) {
            if (pathname === _pathname) {
              if (afterCallback) setTimeout(afterCallback, 0);
            }
          }
        }
      }
    }
  }

  private static setNavigationBar(props: IRouteComponentProps) {
    const {
      _title: title = (globalData.rbCore?.config as any)?.appName || globalData.appName,
    } = props.location.query;
    JsApiHelper.setNavigationBar({ title });
  }

  /**
   * 路由标题和面包屑处理逻辑
   */
  private static handleBreadcrumb(props: IRouteComponentProps, nextProps: IRouteComponentProps) {
    // const { action } = nextProps.history;
    // if (action === 'POP') {
    //   // 返回
    // } else if (action === 'PUSH' || action === 'REPLACE') {
    //   // 新页面
    // }
    this.setNavigationBar(nextProps);
  }

  static _onRouterChange(
    props: IRouteComponentProps,
    nextProps: IRouteComponentProps,
    addressIsEqual: boolean,
  ) {
    this.props = nextProps;
    this.handleBreadcrumb(props, nextProps);
    if (addressIsEqual) {
      this.pageLayer(props, nextProps);
    }
  }

  static replace(pathname: string) {
    this.props.history.replace({ pathname });
  }

  static push(param: IPushParams) {
    const { pathname, search, state = {}, afterCallback, beforeCallback } = param,
      { history } = this.props;
    if (beforeCallback) beforeCallback();
    this.historyRecord.unshift({ ...param });
    state.afterCallback = afterCallback;
    history.push({ pathname, search, state });
  }

  static goBack() {
    this.props.history.goBack();
  }
}
