import Store from '@mjcloud/redux';
import TableInstanceBase from '..';
import { InstanceBase } from '@mjcloud/instance';
import { ITableBaseConfigItem } from '../typings';
import { IDictionary, IArrayDictionary } from '@mjcloud/types';
import { ITableCellBaseState, ITableCellBaseInitConfig } from './typings';

export default abstract class TableCellBase<
  TInstance extends TableInstanceBase<any, any, any> = TableInstanceBase<any, any, any>,
  S extends ITableCellBaseState = ITableCellBaseState,
  TDispatchType extends string = string
> {
  private _stores: IArrayDictionary<Store<S, TDispatchType>> = {};
  private _store: Store<S, TDispatchType>;

  constructor(public id: string, public parent: TInstance, protected config: ITableBaseConfigItem) {
    this._store = this.__createCommStore();
    (this._store as Store<S>).dispatch('initialState', {
      initConfig: this.__getInitConfig({ value: null }),
      pageMode: this.parent.page.pageMode,
    });
  }

  cellType: 'simple' | 'complex' = 'simple';
  protected abstract __createStore(
    rowId: number,
    controlInstance: InstanceBase | null,
  ): Store<S, TDispatchType>;

  protected abstract __createCommStore(): Store<S, TDispatchType>;

  protected __getInitConfig(params: ITableCellBaseInitConfig): IDictionary {
    return params;
  }

  get stores() {
    return this._stores;
  }

  get store() {
    return this._store;
  }

  abstract getControlInstance(rowId: number): InstanceBase | null;

  createStore(rowId: number): Store<S, TDispatchType> {
    if (this._stores[rowId]) return this._stores[rowId];
    let value: any,
      text: string,
      controlInstance = this.getControlInstance(rowId),
      record = this.parent.__getCellRecord(rowId) || { _rid: rowId },
      textFieldName: string | undefined = undefined;

    value = text = record[this.config.field || this.id];
    if (controlInstance) {
      textFieldName = controlInstance.store.state['textFieldName'];
      if (textFieldName) text = record[textFieldName];
      if (controlInstance['format'] instanceof Function) {
        // TODO: 修复 表格的控件值初始化 格式化配置失效 临时解决方案
        text = controlInstance['format'](value, this.config.control);
      }
    }
    this._stores[rowId] = this.__createStore(rowId, controlInstance);
    this._stores[rowId].setParent(this.parent.store);
    (this._stores[rowId] as Store<S>).dispatch('initialState', {
      rowId,
      initConfig: this.__getInitConfig({ value, text }),
      pageMode: this.parent.page.pageMode,
    });
    return this._stores[rowId];
  }

  deleteStore(rowId: number) {
    if (this._stores[rowId]) delete this._stores[rowId];
  }
}
