import { IRadioButtonsConfig } from './typings';

const config: IRadioButtonsConfig = {
  title: '',
  theme: 'normal',
  autoFocus: false,
  alwaysLoad: false,
  textFieldName: 'text',
  modifyMode: undefined,
  placeholder: undefined,
  displayMode: undefined,
  isAutoSelection: false,
  disabledMode: undefined,
  disabledControl: undefined,
};

export default config;
