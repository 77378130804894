import Icon from '@mjcloud/icon';
import styled from 'styled-components';
import React, { useState } from 'react';

interface ISidebarProps {
  height: number;
  onClose?(): void;
}

const Container = styled.div<{ height: number }>`
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  height: ${({ height }) => height}px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Content = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  background: #f7f7f7;
  /*解决ios上滑动不流畅*/
  -webkit-overflow-scrolling: touch;
`;

const Footer = styled.div`
  width: auto;
  color: #4d96f2;
  font-size: 28px;
  margin: 4px auto;
`;
const Sidebar: React.FC<ISidebarProps> = ({ height, onClose = function() {} }) => {
  return (
    <Container height={height} onClick={onClose}>
      <Content></Content>
      <Footer onClick={onClose}>
        <Icon type="close" />
      </Footer>
    </Container>
  );
};

export default Sidebar;
