import GridView from '.';
import GridViewCell from './gridview-cell';
import { IDictionary } from '@mjcloud/types';
import PageHelper from '@mjcloud/page-helper';
import { EventListening } from '@mjcloud/utils';
import ControllerHelper from '@mjcloud/controller';
import { ExceptionHelper } from '@mjcloud//exception';
import { ActionAfterEventArg } from '@mjcloud/redux';
import { ActionBeforeEventArg } from '@mjcloud/redux';
import { ITableBaseRow } from '../common/table/typings';
import TableExtendStoreBase from '../common/table/extendStore';
import {
  IGridViewState,
  GridViewEventType,
  GridViewActionType,
  IGridViewConfigItemSet,
  IGridViewUpdateSelectedRowsParams,
  IGridViewInitialStateParams,
  IGridViewLoadedParams,
} from './typings';

interface HandleClickParams {
  afterClick: GridViewEventType;
  beforeClick: GridViewEventType;
  params: IDictionary;
  controllerId: string | undefined;
}

export default class GridViewExtendStore extends TableExtendStoreBase<
  ITableBaseRow[],
  ITableBaseRow,
  IGridViewState,
  GridView
  > {
  constructor(instance: GridView) {
    super(instance);
    instance.page.eventManager.add('done', this.pageDidDone.bind(this));
  }

  private pageDidDone() {
    const { selectedRows = [], isAutoSelection, rowSelection } = this.instance.store.state;
    if (isAutoSelection && rowSelection && rowSelection.type === 'radio' && selectedRows[0]) {
      const eventManager = this.instance.eventManager;
      if (eventManager.getHandlerCount('selectedChange') > 0) {
        eventManager.trigger('selectedChange', {
          checked: true,
          row: selectedRows[0],
          selectedRows: selectedRows,
        });
      }
    }
  }


  private async _handleClick(param: HandleClickParams) {
    const { params, controllerId, afterClick, beforeClick } = param;
    const exclusiveLockId = this.instance.page.applyExclusiveLock('正在执行点击行操作...');
    if (exclusiveLockId === 0) {
      this.instance.store.dispatch('clickDone', {});
      return;
    }
    let before = async (data: IDictionary) => {
      if (this.instance.eventManager.getHandlerCount(afterClick) > 0) {
        await this.instance.eventManager.trigger(afterClick, data);
      }
      return data;
    },
      after = async (data: IDictionary) => {
        if (this.instance.eventManager.getHandlerCount(beforeClick) > 0) {
          await this.instance.eventManager.trigger(beforeClick, data);
        }
        return data;
      };

    try {
      if (controllerId) {
        await ControllerHelper.execute({
          sender: this.instance,
          controllerId,
          data: params,
          before,
          after,
        });
      } else {
        await before(params);
        await after(params);
      }

      this.instance.store.dispatch('clickDone', {});
      this.instance.page.releaseExclusiveLock(exclusiveLockId);
    } catch (e) {
      ExceptionHelper.dispose(e);
      this.instance.store.dispatch('clickDone', {});
      this.instance.page.releaseExclusiveLock(exclusiveLockId);
    }
  }

  protected __createCell(item: IGridViewConfigItemSet) {
    return new GridViewCell(item.id, this.instance, item);
  }

  handleInitialStateBefore(e: ActionBeforeEventArg<IGridViewInitialStateParams>) {
    super.handleInitialStateBefore(e);
    e.params.refs = (key, instance) => {
      if (instance) {
        this.instance.__registerTableCellTitle(key, instance);
      }
    };
    e.params.render = (cell, config, text, record) => {
      const { id } = config;
      if (record._downMergeProps && record._downMergeProps[id] != null) {
        const rowSpan = record._downMergeProps[id];
        if (!cell.props) cell.props = { rowSpan };
        else cell.props.rowSpan = rowSpan;
      }
      return cell;
    };
  }

  handleLoadedBefore(e: ActionBeforeEventArg<IGridViewLoadedParams>) {
    super.handleLoadedBefore(e);

    const { downMergeColumns } = this.instance.store.state;
    // 记录每列当前合并到第几行
    let downMergeColumnsRowSpanIndex = {};
    for (const key in downMergeColumns) {
      downMergeColumnsRowSpanIndex[key] = 0;
    }
    e.params.callbackfn = (record, index, array) => {
      for (const key in downMergeColumns) {
        const column = downMergeColumns[key],
          { downMerge, downMergeControl } = column;
        if (downMerge && downMergeControl) {
          let e,
            rowSpan = 0;

          // 当 当前行 大于 被合并的行 后，才重新合并，否则跳过 当前行
          if (index >= downMergeColumnsRowSpanIndex[key]) {
            rowSpan = 1;
            const fn = PageHelper.createPageScriptFunction(
              this.instance.page,
              downMergeControl,
              true,
            );
            // 从 当前行 开始，往下计算，求出需要合并的行数
            for (let i = index; i < array.length - 1; i++) {
              const row = array[i];
              e = EventListening.getHandlerArg(this.instance, { row, nRow: array[i + 1] });
              if (!!fn(e)) {
                rowSpan++;
              } else {
                break;
              }
            }
            // 重新计算需要跳过的行
            downMergeColumnsRowSpanIndex[key] = index + rowSpan;
          }
          if (!record._downMergeProps) record._downMergeProps = { [key]: rowSpan };
          else record._downMergeProps[key] = rowSpan;
        }
      }
      return record;
    };
  }

  handleLoadedAfter(e: ActionAfterEventArg<IGridViewState, GridViewActionType>) {
    super.handleLoadedAfter(e);
    const { selectedRows = [], isAutoSelection, rowSelection } = e.newState.state;
    if (isAutoSelection && rowSelection && rowSelection.type === 'radio' && selectedRows[0]) {
      const eventManager = this.instance.eventManager;
      if (eventManager.getHandlerCount('selectedChange') > 0) {
        eventManager.trigger('selectedChange', {
          checked: true,
          row: selectedRows[0],
          selectedRows: selectedRows,
        });
      }
    }
  }

  handleUpdateSelectedRowsAfter(
    e: ActionAfterEventArg<IGridViewState, GridViewActionType, IGridViewUpdateSelectedRowsParams>,
  ) {
    const { selectedRows = [], dataSource } = e.newState.state,
      { row, checked, actionSourceSign } = e.params;
    if (actionSourceSign === this.instance) return;
    if (row) {
      const eventManager = this.instance.eventManager;
      const _selectedRows: IDictionary<boolean> = {};
      selectedRows.forEach(row => {
        _selectedRows[row._rid] = true;
      });

      if (eventManager.getHandlerCount('selectedChange') > 0) {
        eventManager.trigger('selectedChange', {
          checked,
          row,
          selectedRows: dataSource
            .map(row => {
              if (_selectedRows[row._rid]) {
                return row;
              }
              return undefined;
            })
            .filter(r => !!r),
        });
      }
    } else {
      // 全选按钮变化进这里
      const eventManager = this.instance.eventManager;
      const _selectedRows: IDictionary<boolean> = {};
      selectedRows.forEach(row => {
        _selectedRows[row._rid] = true;
      });
      const selectRows = dataSource
        .map(row => {
          if (_selectedRows[row._rid]) {
            return row;
          }
          return undefined;
        })
        .filter(r => !!r);
      if (eventManager.getHandlerCount('selectedChange') > 0) {
        // for (const row of selectRows) {
        //   eventManager.trigger('selectedChange', { checked: true, row, selectedRows: selectRows });
        // }
        // 取消全选后，将每项的checked值改为false，否则取消全选之后再点击其中一项，会导致全部选项选中问题
        let len = selectRows?.length, source = len ? selectRows : dataSource
        for (const row of source) {
          eventManager.trigger('selectedChange', { checked: len ? true : false, row, selectedRows: selectRows });
        }
      }
    }
  }


  async handleRowClickAfterAfter(e: ActionAfterEventArg<IGridViewState, string, any>) {
    const { rowClickController } = e.newState.state,
      { row } = e.params;
    await this._handleClick({
      params: { row },
      afterClick: 'rowAfterClick',
      beforeClick: 'rowBeforeClick',
      controllerId: rowClickController,
    });
  }
}
