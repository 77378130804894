import Select from '.';
import { IDictionary } from '@mjcloud/types';
import ControllerHelper from '@mjcloud/controller';
import { ExceptionHelper } from '@mjcloud/exception';
import { ActionAfterEventArg } from '@mjcloud/redux';
import SelectExtendStoreBase from '../common/select/extendStore';
import { ISelectState, SelectActionType, ISelectButtonClickAfterParams } from './typings';

class SelectExtendStore extends SelectExtendStoreBase<string, Select> {
  handleLoadedAfter(e: ActionAfterEventArg<any, any, any>) {
    super.handleLoadedAfter(e);
    // TODO: 需要考虑dataIsDefaultSelected对父级控件的影响
  }

  handleUpdateValueAfter(e: ActionAfterEventArg<any, any, any>) {
    super.handleUpdateValueAfter(e);
    const { searchValue, alwaysLoad, value, text } = e.newState.state as ISelectState;
    if (searchValue) {
      // TODO fix HZT-229  目前采取了笨方法，这个问题应该属于父级控件的职责
      this.instance.store.dispatch('handleSearch', {});
      return;
    }
    if (alwaysLoad && !this.isFirstLoad && value == text) {
      this.instance.refresh();
    }
  }

  async handleButtonClickAfterAfter(
    e: ActionAfterEventArg<ISelectState, SelectActionType, ISelectButtonClickAfterParams>,
  ) {
    const { value, text } = e.newState.state,
      { config, isAdd } = e.params;
    if (config) {
      const { tip, title, controllerId } = config;
      const exclusiveLockId = this.instance.page.applyExclusiveLock(
        tip || `正在执行${title}操作...`,
      );
      if (exclusiveLockId === 0) {
        e.newState.dispatch('buttonClickDone', {});
        return;
      }
      const params: IDictionary = { ...e.params, value, text },
        before = async (data: IDictionary, extra: IDictionary = {}) => {
          if (isAdd && data.request && data.request.params)
            data.request.params._authorityNeedAddress = true;

          extra._before = () => e.newState.dispatch('updateButtonLoad', { loading: true });
          extra._after = () => e.newState.dispatch('updateButtonLoad', { loading: false });
          if (this.instance.eventManager.getHandlerCount('onSuffixButtonBeforeClick') > 0) {
            await this.instance.eventManager.trigger('onSuffixButtonBeforeClick', data, extra);
          }
          return data;
        },
        after = async (data: IDictionary, extra: IDictionary = {}) => {
          const superFn = async () => {
            if (isAdd) {
              if (data.result && data.result.id) {
                const params: any = {
                  value: data.result.id,
                  actionSourceSign: this.instance.store,
                };
                e.newState.dispatch('updateValue', params);
              }
              this.instance.refresh({ isAdd, location: data.result && data.result.id });
            }
          };
          if (this.instance.eventManager.getHandlerCount('onSuffixButtonAfterClick') > 0) {
            data.super = superFn;
            await this.instance.eventManager.trigger('onSuffixButtonAfterClick', data, extra);
          } else {
            // 默认的 onSuffixButtonAfterClick 处理事件
            await superFn();
          }
          return data;
        };

      try {
        if (controllerId) {
          const _params = { data: params, before, after };
          const res = await ControllerHelper.execute({
            sender: this.instance,
            controllerId,
            ..._params,
          });
          if (res.result && res.result.data) {
            const { selectedIds, selectedTexts } = res.result.data;
            e.newState.dispatch('updateValue', {
              value: selectedIds,
              text: selectedTexts,
              actionSourceSign: this.instance.store,
            });
          }
        } else {
          await before(params);
          await after(params);
        }

        this.instance.page.releaseExclusiveLock(exclusiveLockId);
        e.newState.dispatch('buttonClickDone', {});
      } catch (error) {
        e.newState.dispatch('buttonClickDone', {});
        this.instance.page.releaseExclusiveLock(exclusiveLockId);
        ExceptionHelper.dispose(error);
      }
    }
  }
}
export default SelectExtendStore;
