import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import globalData from '@mjcloud/global-data';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { AppMenuConfig, IModuleState, IModuleInitialStateParams } from './typings';

const colors = ['orange', 'purple', 'green', 'blue'],
  length = colors.length;

function formatDataSource(_menus: AppMenuConfig[] = [], tile = false) {
  let menus: AppMenuConfig[] = [],
    _i = 0;
  if (tile) {
    if (_menus.length > 0 && _menus[0] && _menus[0].children) {
      menus = _menus[0].children;
    }
    menus = menus.map(menu => {
      if (menu.children) {
        menu.children = menu.children
          .map((_menu, index) => {
            if (_menu.moduleId != globalData.mainMobileName) {
              if (index >= length) {
                _i = index - Math.floor(index / length) * length;
              } else {
                _i = index;
              }
              _menu.color = colors[_i];
              return _menu;
            }
            return undefined;
          })
          .filter(menu => !!menu) as AppMenuConfig[];
      }
      return menu;
    });
    return menus;
  }
  if (_menus.length > 0 && _menus[0] && _menus[0].children) {
    _menus = _menus[0].children;
    if (_menus.length > 0) {
      let __menus: AppMenuConfig[] = [];
      for (const menu of _menus) {
        if (menu && menu.children) {
          __menus = __menus.concat(menu.children);
        }
      }
      _menus = __menus;
    }
  }
  _menus = _menus
    .map(menu => {
      if (menu.moduleId != globalData.mainMobileName) {
        return menu;
      }
      return undefined;
    })
    .filter(menu => !!menu) as AppMenuConfig[];

  if (_menus.length > 4) {
    for (let i = 0; i < 3; i++) {
      menus.push({ ..._menus[i], color: colors[i] });
    }
    menus.push({
      code: '-1',
      icon: 'icon-more',
      id: '-1',
      moduleId: 'More',
      name: '更多',
      color: 'blue',
    });
  } else {
    for (let i = 0; i < _menus.length; i++) {
      menus.push({ ..._menus[i], color: colors[i] });
    }
  }
  return menus;
}

class ModuleReduce extends ReduceBase<IModuleState> {
  initialState(store: Store<IModuleState>, params: IModuleInitialStateParams) {
    let { initConfig, pageMode } = params,
      { tile = false, displayMode } = initConfig;
    let menus: AppMenuConfig[] = [];
    if (globalData.appInfo.menus) {
      menus = formatDataSource(globalData.appInfo.menus, tile);
    }
    const state: IModuleState = {
      tile,
      tabIndex: -1,
      isFetching: false,
      dataSource: menus,
      config: initConfig,
      configIsFetching: false,
      errorMessage: undefined,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  startLoad(store: Store<IModuleState>, params) {
    return { ...store.state, isFetching: true, errorMessage: undefined };
  }

  loaded(store: Store<IModuleState>, params) {
    let { dataSource } = params,
      state = store.state;
    state.isFetching = false;
    if (dataSource) {
      state.dataSource = formatDataSource(dataSource, state.tile);
      return { ...state };
    }
    return { ...state };
  }

  loadError(store, params) {
    const { errorMessage } = params;
    let state = { ...store.state, isFetching: false, errorMessage };
    return state;
  }
}

export default new ModuleReduce();
