import Store from '@mjcloud/redux';
import Reduce from './reduce';
import WorkbenchPart from './part';
import { EventManager } from '@mjcloud/utils';
import { ExceptionHelper } from '@mjcloud/exception';
import WorkbenchExtendStore from './extendStore';
import { DataModeEnum, IDictionary } from '@mjcloud/types';
import { ContainerDataInstanceBase } from '@mjcloud/instance';
import {
  ICutoverMenu,
  IWorkbenchPart,
  IWorkbenchState,
  IWorkbenchConfig,
  IWorkbenchConfigStartLoadParams,
} from './typings';

export default class Workbench extends ContainerDataInstanceBase<IWorkbenchState> {
  private _readyCallback?: () => void;
  private partInitCount = 0;
  /**
   * 当前部件列表是否需要初始化，不需要则直接执行 _readyCallback
   */
  private isPartInit = false;
  private _parts: IDictionary<WorkbenchPart> = {};
  private _busManager = new EventManager<string>(this);

  getDataMode() {
    return DataModeEnum.all;
  }

  __createStore() {
    return new Store<IWorkbenchState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new WorkbenchExtendStore(this),
    });
  }

  initialState(initConfig: IWorkbenchConfig) {
    this.store.dispatch<IWorkbenchConfigStartLoadParams>('configStartLoad', {
      initConfig,
    });
  }

  __createWorkbenchPart(config: IWorkbenchPart) {
    const { name: partId } = config;
    if (!partId)
      throw ExceptionHelper.argumentNullException('name', '工作台部件配置缺少必填的name');
    if (this._parts[partId]) return this._parts[partId];

    this._parts[partId] = new WorkbenchPart(partId, this, config);
    this.isPartInit = true;
    this.partInitCount++;
    return this._parts[partId];
  }

  __partLoadComplete(partId: string) {
    if (this.isPartInit) {
      this.partInitCount--;
      if (this.partInitCount === 0 && this._readyCallback) {
        this._readyCallback();
        this._readyCallback = undefined;
      }
    }
  }

  /**
   * 当前控件的数据总线管理器
   */
  get busManager() {
    return this._busManager;
  }

  findPart(id: string) {
    return this._parts[id];
  }

  updateWorkbenchId(workbenchId: string, workbenchName?: string) {
    this.store.dispatch('updateCutoverMenus', {
      workbenchId,
      workbenchName,
    });
  }

  updateCutoverMenus(menus: ICutoverMenu[], workbenchId?: string, workbenchName?: string) {
    this.store.dispatch('updateCutoverMenus', {
      menus,
      workbenchId,
      workbenchName,
    });
  }
}
