import Store from '@mjcloud/redux';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { IToolbarState, IToolbarInitialStateParams, IToolbarStateArea } from './typings';

export class ToolBarReduce extends ReduceBase<IToolbarState> {
  initialState(store: Store<IToolbarState>, params: IToolbarInitialStateParams) {
    const { initConfig, leftControls, rightControls, leftFCs, rightFCs, pageMode } = params,
      { styleName = 'normal', areas, displayMode } = initConfig;
    let left: IToolbarStateArea | undefined, right: IToolbarStateArea | undefined;
    if (areas) {
      if (areas.left) {
        const { padding } = areas.left;
        left = {
          padding: padding == null ? 8 : padding,
          controls: leftControls,
          ChildrenControls: leftFCs,
        };
      }
      if (areas.right) {
        const { padding } = areas.right;
        right = {
          padding: padding == null ? 8 : padding,
          controls: rightControls,
          ChildrenControls: rightFCs,
        };
      }
    }
    const state: IToolbarState = {
      tabIndex: 0,
      theme: styleName,
      config: initConfig,
      areas: { left, right },
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }
}

export default new ToolBarReduce();
