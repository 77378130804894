import { ActionBeforeEventArg, ActionAfterEventArg } from '@mjcloud/redux';
import { ContainerExtendStoreBase } from '@mjcloud/extend-store';
import { DataInstanceBase, ContainerDataInstanceBase } from '@mjcloud/instance';
import { ISimpleSearchInitialStateParams, ISimpleSearchState } from './typings';

export default class SimpleSearchExtendStore extends ContainerExtendStoreBase {
  handleInitialStateBefore(e: ActionBeforeEventArg<ISimpleSearchInitialStateParams>) {
    e.params.createControl = id => this.instance.page.createControl(id);
  }

  handleFindAfter(e: ActionAfterEventArg<ISimpleSearchState, string, { isEqual: boolean }>) {
    const { isEqual } = e.params,
      { tabs, _filter: filter } = e.newState.state;
    if (!isEqual) {
      if (this.instance.eventManager.getHandlerCount('find') > 0) {
        this.instance.eventManager.trigger('find', { filter });
      }
      for (const { id } of tabs) {
        const instance = this.instance.page.findControl(id);
        if (instance instanceof DataInstanceBase || instance instanceof ContainerDataInstanceBase) {
          instance.refresh({ filter });
        }
      }
    }
  }

  handleOnBlurAfter(e: any) {
    this.handleFindAfter(e);
  }
}
