import { applyMixins } from '@mjcloud/utils';
import DataExtendStoreBase from '../dataExtendStoreBase';
import ContainerExtendStoreBase from '../containerExtendStoreBase';
import ContainerDataInstanceBase from '@mjcloud/instance/dist/containerDataInstanceBase';

abstract class ContainerDataExtendStoreBase<
  TInstance extends ContainerDataInstanceBase = ContainerDataInstanceBase
> extends DataExtendStoreBase<TInstance> implements ContainerExtendStoreBase<TInstance> {}

applyMixins(ContainerDataExtendStoreBase, ContainerExtendStoreBase);
export default ContainerDataExtendStoreBase;
