import { ValueDataInstanceBase } from '@mjcloud/instance';
import { ISelectBaseState, ISelectBaseDataSource } from './typings';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { DataModeEnum, IDictionary } from '@mjcloud/types';

export default abstract class SelectInstanceBase<
  TValue = string,
  S extends ISelectBaseState<TValue> = ISelectBaseState<TValue>,
  TEventType extends string = string,
  TDispatchType extends string = string
> extends ValueDataInstanceBase<TValue, S, TEventType, TDispatchType> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  getDataMode(): DataModeEnum {
    return DataModeEnum.all;
  }

  clearDataSource() {}

  get dataSource() {
    const { dataSource } = this.store.state;
    return dataSource;
  }

  filterRow<I>(data: I[], keyValue: string, keyFieldName: string = 'value'): I | undefined {
    return undefined;
  }
  filterRows<I>(data: I[], keyValues: string[], keyFieldName: string = 'value'): I[] {
    return [];
  }
  /** 获取当前已选中的集合 */
  getSelectedRows<I extends ISelectBaseDataSource = ISelectBaseDataSource>(): I[] {
    return this.store.state.selectedRows as I[];
  }
  /**
   * 获取当前已选中行的Id集合
   */
  getSelectedIds(): string[] {
    return this.store.state.selectedRows.map(row => row.id);
  }

  getAllData(): IDictionary[] {
    const { dataSource = [] } = this.store.state;
    return dataSource;
  }

  get length() {
    return this.store.state.originalDataSource.length;
  }
}
