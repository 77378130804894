import React from 'react';
import ReactDOM from 'react-dom';
import { IFileInfo } from '@mjcloud/service';
import PreviewModal from '../components/PreviewModal';

export default function(props: {
  file: IFileInfo;
  onOk?: (...args: any[]) => any;
  onCancel?: (...args: any[]) => any;
}) {
  const { file, onCancel } = props,
    div = document.createElement('div');
  document.body.appendChild(div);
  function destroy() {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
    if (onCancel) onCancel();
  }
  ReactDOM.render(<PreviewModal file={file} destroy={destroy} />, div);
}
