import React from 'react';
import Icon from '@mjcloud/icon';
import { Screen } from './util';
import JsApiHelper from '@mjcloud/jsapi';
import { ObjectHelper } from '@mjcloud/utils';
import globalData from '@mjcloud/global-data';
import { List, Result, Typography } from 'antd';
import { ExceptionHelper } from '@mjcloud/exception';
import Service, { IFileInfo } from '@mjcloud/service';
import { IRouteComponentProps } from '@mjcloud/router-helper';

import styles from './filelist.less';

interface IFileListPageState {
  loading: boolean;
  isMac: boolean;
  backInfo?: any;
  errorMsg?: string;
  fileList?: IFileInfo[];
}

export default class FileListPage extends React.Component<
  IRouteComponentProps,
  IFileListPageState
> {
  constructor(props: IRouteComponentProps) {
    super(props);
    const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    this.state = { isMac, loading: false };
    this.init();
  }

  async init() {
    const { match } = this.props,
      appId = match.params.appId,
      { search, origin } = window.location,
      { corpId, businessId, businessType } = ObjectHelper.search2params(search);
    globalData.corpId = corpId;
    globalData.appId = appId;
    let state: any = { fileList: [] };
    try {
      const { code } = await JsApiHelper.requestAuthCode({ corpId });
      await Service.ddSignin({ appId, corpId, authCode: code });
      Service.ddConfig({ appId, corpId, url: encodeURIComponent(origin + search) });
      const fileList = await Service.getFileList(businessId, businessType);
      state.fileList = fileList ? fileList : [];
    } catch (error) {
      if (error.message) {
        state.errorMsg = error.message;
      }
      ExceptionHelper.dispose(error);
    }
    if (JsApiHelper.ENV_ENUM.pc === JsApiHelper.ENV.platform && !this.state.isMac) {
      try {
        const backInfo = await Service.requestService<any>('/workflow/finddingprocessinstance', {
          id: businessId,
        });
        state.backInfo = backInfo;
      } catch (error) {
        ExceptionHelper.dispose(error);
      }
    }
    this.setState(state);
  }

  backClick = () => {
    if (this.state.backInfo) {
      const { title, url } = this.state.backInfo;
      if (url && title) {
        JsApiHelper.openSlidePanel(title, url);
      }
    }
  };

  fileClick = async (file: IFileInfo) => {
    this.setState({ loading: true }, async () => {
      try {
        await JsApiHelper.previewFile(file);
        this.setState({ loading: false });
      } catch (error) {
        console.error(error);
        ExceptionHelper.dispose(error);
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { fileList, loading, isMac, errorMsg } = this.state;
    if (errorMsg) {
      return <Result status="500" subTitle={errorMsg} />;
    }
    if (fileList) {
      return (
        <List<IFileInfo>
          loading={loading}
          className={styles.filelist}
          header={
            <>
              {JsApiHelper.ENV_ENUM.pc === JsApiHelper.ENV.platform && !isMac && (
                <Icon type="arrow-left" className={styles.back} onClick={this.backClick} />
              )}
              <span>附件列表</span>
            </>
          }
          bordered
          dataSource={fileList}
          renderItem={file => {
            const handleClick = () => this.fileClick(file);
            return (
              <List.Item onClick={handleClick}>
                <Typography.Text mark></Typography.Text> {file.name}
              </List.Item>
            );
          }}
        />
      );
    }
    return <Screen />;
  }
}
