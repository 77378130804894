import { DataReduceBase } from '@mjcloud/reduce';
import DataSource from '@mjcloud/data-source-helper';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { DateHelper, ArrayHelper } from '@mjcloud/utils';

function getMonths2Days(date) {
  const { months, monthIndex } = DateHelper.getMonths(date),
    { days, dayIndex } = DateHelper.getMonthDays(date),
    monthPreDayWeek = new Date(date.getFullYear(), date.getMonth(), 1).getDay(),
    monthNextDayWeek = new Date(
      date.getFullYear(),
      date.getMonth(),
      days[days.length - 1].value,
    ).getDay(),
    { nextMonths2Days, preMonths2Days } = intPreAndNext(
      days,
      date,
      monthPreDayWeek,
      monthNextDayWeek,
    );
  return {
    days,
    months,
    monthIndex,
    dayIndex,
    nextMonths2Days,
    preMonths2Days,
  };
}

function intPreAndNext(days, date, monthPreDayWeek, monthNextDayWeek) {
  const len = 6 - monthNextDayWeek,
    { days: nextMonths2Days } = getPreOrNextMonths2Days(
      new Date(date.getFullYear(), date.getMonth() + 1, 1),
      monthNextDayWeek,
    ),
    { days: preMonths2Days } = getPreOrNextMonths2Days(
      new Date(date.getFullYear(), date.getMonth() - 1, 1),
      monthPreDayWeek,
    );
  for (let index = 0; index < len; index++) {
    days.push({ ...nextMonths2Days[index], dashed: true });
  }
  for (let index = 0; index < monthPreDayWeek; index++) {
    days.unshift({
      ...preMonths2Days[preMonths2Days.length - 1 - index],
      dashed: true,
    });
  }
  return { nextMonths2Days, preMonths2Days };
}

function getPreOrNextMonths2Days(date, week) {
  const { months, monthIndex } = DateHelper.getMonths(date),
    { days, dayIndex } = DateHelper.getMonthDays(date);
  return { days, months, monthIndex, dayIndex };
}

class CalendarReduce extends DataReduceBase {
  initialState(store, params) {
    const date = new Date(),
      { initConfig, pageMode } = params,
      { displayMode } = initConfig,
      { days, months, nextMonths2Days, preMonths2Days } = getMonths2Days(date);
    return {
      date,
      months,
      value: null,
      tabIndex: -1,
      rowIdCount: 1,
      dataSource: [],
      preMonths2Days,
      nextMonths2Days,
      oldTap: undefined,
      isFetching: false,
      config: initConfig,
      configIsFetching: false,
      days: ArrayHelper.oneArray2twoArray(days, 7),
      weeks: ['日', '一', '二', '三', '四', '五', '六'],
      dateFormat: DateHelper.format(date, 'YYYY年MM月'),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
  }

  loaded(store, params) {
    const state = { ...store.state },
      { rows } = params.dataSource;
    // state.dataSource = [{ text: `${Math.floor(Math.random() * 20)}`, id: 0 }];
    let { rowIdCount, config, days } = state,
      dataSource = DataSource.formatDataSource(
        config.data,
        rows,
        row => false,
        row => ({ _rid: ++rowIdCount }),
      ),
      _days = days.map(week => {
        return week.map(day => {
          if (!day.dashed) {
            for (const item of dataSource) {
              if (item._text == day.value) {
                day.active = item._value == '1';
              }
            }
          }
          return day;
        });
      });
    state.days = _days;
    state.rowIdCount = rowIdCount;
    state.dataSource = dataSource;
    return state;
  }

  selectDate(store, params) {
    const { date } = params;
    const { days, months, nextMonths2Days, preMonths2Days } = getMonths2Days(date);
    return {
      ...store.state,
      date,
      months,
      nextMonths2Days,
      preMonths2Days,
      dateFormat: DateHelper.format(date, 'YYYY年MM月'),
      days: ArrayHelper.oneArray2twoArray(days, 7),
    };
  }

  dayTap(store, params) {
    const { dayindex, weekindex } = params;
    const { value: dayIndex, month: monthIndex, year: yearIndex } = store.state.days[dayindex][
      weekindex
    ];
    const state = { ...store.state, dayIndex, monthIndex, yearIndex };
    if (state.oldTap) {
      const { dayindex: _i, weekindex: _j } = state.oldTap;
      if (_i === dayindex && _j === weekindex) {
        return store.state;
      }
      state.days[_i][_j].tap = false;
    }
    state.days[dayindex][weekindex].tap = true;
    state.oldTap = { dayindex, weekindex };
    state.selected = { dayindex, weekindex, dateFormat: state.dateFormat };
    state.value = DateHelper.format2utc(`${yearIndex}-${monthIndex}-${dayIndex}`);
    return state;
  }

  updateDay(store, params) {
    const days = store.state.days,
      date = DateHelper.parse(params.date),
      dayIndex = date && date.getDate(),
      monthIndex = date && date.getMonth() + 1,
      yearIndex = date && date.getFullYear();
    let dayindex, weekindex;
    for (let i = 0; i < days.length; i++) {
      const week = days[i];
      for (let j = 0; j < week.length; j++) {
        const { value: day, month, year } = week[j];
        if (day == dayIndex && month == monthIndex && yearIndex == year) {
          dayindex = i;
          weekindex = j;
        }
      }
    }
    params.dayindex = dayindex;
    params.weekindex = weekindex;
    return store.state;
  }

  preMonthTap(store, params) {
    const state = store.state;
    const date = new Date(state.date);
    date.setMonth(date.getMonth() - 1);
    const { days, months, nextMonths2Days, preMonths2Days } = getMonths2Days(date),
      dateFormat = DateHelper.format(date, 'YYYY年MM月'),
      _days: any = ArrayHelper.oneArray2twoArray(days, 7);
    if (state.selected && dateFormat === state.selected.dateFormat) {
      const { dayindex, weekindex } = state.selected;
      _days[dayindex][weekindex].tap = true;
    }
    return {
      ...state,
      date,
      months,
      nextMonths2Days,
      preMonths2Days,
      dateFormat,
      days: _days,
    };
  }

  nextMonthTap(store, params) {
    const state = store.state;
    const date = new Date(state.date);
    date.setMonth(date.getMonth() + 1);
    const { days, months, nextMonths2Days, preMonths2Days } = getMonths2Days(date),
      dateFormat = DateHelper.format(date, 'YYYY年MM月'),
      _days: any = ArrayHelper.oneArray2twoArray(days, 7);
    if (state.selected && dateFormat === state.selected.dateFormat) {
      const { dayindex, weekindex } = state.selected;
      _days[dayindex][weekindex].tap = true;
    }
    return {
      ...state,
      date,
      months,
      nextMonths2Days,
      preMonths2Days,
      dateFormat,
      days: _days,
    };
  }
}

export default new CalendarReduce();
