export interface IWxAgentResult {
  checkResult: { [key: string]: boolean };
  errMsg: string;
}

export const wxDebug = false;
export const wxJsApiList = [
  'onMenuShareAppMessage',
  'onMenuShareWechat',
  'onMenuShareTimeline',
  'startRecord',
  'stopRecord',
  'onVoiceRecordEnd',
  'playVoice',
  'pauseVoice',
  'stopVoice',
  'onVoicePlayEnd',
  'uploadVoice',
  'downloadVoice',
  'chooseImage',
  'previewImage',
  'uploadImage',
  'downloadImage',
  'getLocalImgData',
  'getNetworkType',
  'onNetworkStatusChange',
  'openLocation',
  'getLocation',
  'startAutoLBS',
  'stopAutoLBS',
  'onLocationChange',
  'onHistoryBack',
  'hideOptionMenu',
  'showOptionMenu',
  'hideMenuItems',
  'showMenuItems',
  'hideAllNonBaseMenuItem',
  'showAllNonBaseMenuItem',
  'closeWindow',
  'openDefaultBrowser',
  'scanQRCode',
  'selectEnterpriseContact',
  'openEnterpriseChat',
  'chooseInvoice',
  'selectExternalContact',
  'getCurExternalContact',
  'openUserProfile',
  'shareAppMessage',
  'shareWechatMessage',
  'startWifi',
  'stopWifi',
  'connectWifi',
  'getWifiList',
  'onGetWifiList',
  'onWifiConnected',
  'getConnectedWifi',
  'setClipboardData',
];
export const wxAgentJsApiList = [
  'shareToExternalContact',
  'sendChatMessage',
  'getCurExternalChat',
  'applyCodeForCreateChat',
  'selectExternalContact',
  'openUserProfile',
  'thirdPartyOpenPage',
  'getCurExternalContact',
];
