import Service from '@mjcloud/service';
import JsApiHelper from '@mjcloud/jsapi';
import PageHelper from '@mjcloud/page-helper';
import { ExceptionHelper } from '@mjcloud/exception';
import { IDictionary, XmlParamList } from '@mjcloud/types';
import { ControllerBase, IControllerInitData, IRequest } from './base';
import OpenWindowController, { IOpenWindowInfo, OpenWindowControllerInitData } from './openWindow';
import { InstanceBase } from '@mjcloud/instance';

export interface OpenProcessServiceControllerInitData extends IControllerInitData {
  pageInfo: IOpenWindowInfo;
  service: XmlParamList;
}

/**
 * 执行服务请求对象类型
 */
export interface IOpenProcessServiceRequest extends IRequest {
  businessId: string;
  businessType: string;
}

interface IOpenProcessServiceResponse {
  workflowMode: number;
  data: any;
}

interface IProcessInstanceResult {
  url: string;
  title: string;
}

/**
 * 执行服务控制器
 */
export default class OpenProcessServiceController extends ControllerBase<
  OpenProcessServiceControllerInitData
  > {
  /**
   * 创建执行请求对象
   */
  public async createRequest(args: IDictionary): Promise<IOpenProcessServiceRequest> {
    const values: IDictionary<string> = {};
    if (this.initData.service)
      this.initData.service.items.forEach(item => {
        values[item.name] = item.value;
      });
    const params = await PageHelper.getDynamicParamValues<IOpenProcessServiceRequest>(
      this.page,
      values,
      args,
    );

    return { ...params };
  }

  /**
   * 执行控制器
   * @param request 执行时的请求对象
   */
  public async execute<T>(request: IOpenProcessServiceRequest) {
    //1.校验 动态参数无法确定名称
    // this.emptyValueValiator('业务id', request.businessId);
    // this.emptyValueValiator('业务类型', request.businessType);
    //2.查找流程信息
    const result = await Service.executeController<IOpenProcessServiceResponse>({
      ...this.page.getControllerExecParams(),
      params: request,
      controllerId: this.id,
      workbenchPartAddress: this.sender.workbenchPart && this.sender.workbenchPart.address,
    });
    if (result) {
      console.info('OpenProcessServiceController.execute.result', result);
      // if (result.workflowMode === 1) {
      const info = result.data as IProcessInstanceResult;
      if (info?.url != null) {
        // TODO: 目前工作流中相关附件的存在会引起侧边栏关闭无相关事件触发
        JsApiHelper.openSlidePanel(info.title, info.url);
      } else {
        throw ExceptionHelper.businessException('当前单据的流程信息不存在！');
      }
      // } else {
      //   throw ExceptionHelper.businessException('当前单据的流程信息不存在！');
      // }
    }
    return result as any;
  }
}
