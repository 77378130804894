import TreeView from '..';
import Reduce from './reduce';
import { InstanceBase, ValueInstanceBase } from '@mjcloud/instance';
import TableCellBase from '../../common/table/cell';
import { IDictionary, ControlModifyModeEnum } from '@mjcloud/types';
import Store, { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux';
import { ITableCellBaseInitConfig } from '../../common/table/cell/typings';
import { TreeViewCellActionType, ITreeViewCellState } from './typings';
import { ITreeViewConfigItem } from '../typings';

class TreeViewCellItemExtendStore {
  constructor(
    private rowId: number,
    private instance: TreeViewCell,
    private controlInstance: InstanceBase,
  ) {}

  handleInitialStateAfter(e: ActionAfterEventArg<any>) {
    let value: any, text: string;
    const { rowId, instance, controlInstance } = this,
      record = instance.parent.__getCellRecord(rowId) || { _rid: rowId },
      textFieldName: string | undefined = controlInstance.store.state['textFieldName'];
    value = text = record[instance['config'].field || instance.id];
    if (textFieldName) text = record[textFieldName];
    if (controlInstance['format'] instanceof Function) {
      text = controlInstance['format'](value);
    }
    if (value !== text) instance.stores[rowId].dispatch('updateValue', { value, text });
  }

  handleClickAfterBefore(e: ActionBeforeEventArg<any>) {
    const { rowId, instance } = this;
    e.params = { ...e.params, rowId, row: instance.parent.getRowForRowId(rowId) };
  }
}

export default class TreeViewCell extends TableCellBase<
  TreeView,
  ITreeViewCellState,
  TreeViewCellActionType
> {
  private special: boolean = false;
  controlInstances: IDictionary<InstanceBase | null> = {};
  constructor(public id: string, parent: TreeView, protected config: ITreeViewConfigItem) {
    super(id, parent, config);
    if (config.control) {
      this.special = config.control.nodeName === 'hyperlink';
    }
  }
  protected __createStore(rowId: number) {
    const store = new Store<ITreeViewCellState, TreeViewCellActionType>({
      id: `cell-${this.id}`,
      reduce: Reduce,
      extendStore: {
        handleInitialStateAfter: this.handleInitialStateAfter.bind(this),
      },
    });
    return store;
  }

  protected __createCommStore() {
    const store = new Store<ITreeViewCellState, TreeViewCellActionType>({
      id: `cell-${this.id}`,
      reduce: Reduce,
    });
    return store;
  }

  protected __getInitConfig(params: ITableCellBaseInitConfig): IDictionary {
    return { ...params, modifyMode: ControlModifyModeEnum.none, special: this.special };
  }

  private handleInitialStateAfter(e: ActionAfterEventArg<ITreeViewCellState>) {
    const { value, text, rowId } = e.newState.state,
      controlInstance = this.getControlInstance(rowId);
    if (controlInstance instanceof ValueInstanceBase) {
      controlInstance.store.dispatch('updateValue', {
        value,
        text,
      });
    }
  }

  getControlInstance(rowId: number) {
    let _rowId = this.special ? rowId : 0;
    let controlInstance = this.controlInstances[_rowId];
    if (!controlInstance) {
      if (this.special) {
        const config = { ...this.config, id: `${this.config.id}${rowId}` };
        controlInstance = this.parent.__createControlByItem(config);
      } else {
        controlInstance = this.parent.__createControlByItem(this.config);
      }
      if (controlInstance) {
        controlInstance.store.bindExtendStore(
          new TreeViewCellItemExtendStore(rowId, this, controlInstance),
        );
      }
    }
    return controlInstance;
  }
}
