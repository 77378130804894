import React from 'react';
import Icon from '@mjcloud/icon';
import { Empty } from 'antd';
import styled from 'styled-components';
import { ListEdit } from '@mjcloud/mcontrols';
import globalData from '@mjcloud/global-data';
import ControlBase from '../../core/base';
import { Button as AntBtn, List } from 'antd-mobile';
import { px2rem, SPACING, EMPTYTEXT, EMPTYEDITTEXT } from '../../constant';
import { IListEditState } from '@mjcloud/mcontrols/dist/controls/list-edit/typings';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: ${px2rem(SPACING * 2)}px;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: auto;
  /*解决ios上滑动不流畅*/
  -webkit-overflow-scrolling: touch;
`;

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Button = styled(AntBtn)`
  width: calc(100% - ${px2rem(SPACING * 4)});
  margin: ${px2rem(SPACING * 2)};
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${px2rem(SPACING * 2)};
  margin-bottom: ${px2rem(SPACING * 2)};
`;

const Title = styled.div`
  flex: 1;
  font-size: ${px2rem(24)};
  color: rgba(25, 31, 37, 0.8);
  margin-left: ${px2rem(SPACING * 3)};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ItemTitle = styled.div<{ readonly: boolean }>`
  width: ${px2rem(170)};
  color: #191f25;
  font-size: ${({ readonly }) => px2rem(readonly ? 28 : 34)};
  margin-left: 0;
  margin-right: ${px2rem(SPACING * 2)};
  text-align: left;
  white-space: wrap;
  overflow: hidden;
  padding: ${px2rem(SPACING)} 0;
`;

const ItemValue = styled.div<{ readonly: boolean }>`
  flex: 1;
  width: 0px;
  font-size: ${({ readonly }) => px2rem(readonly ? 28 : 32)};
  color: ${({ readonly }) => (readonly ? 'rgba(25, 31, 37, 0.8)' : 'inherit')};
`;

const Remove = styled(function({ className, onClick }) {
  return <Icon type="icon-close" className={className} onClick={onClick} />;
})`
  font-size: ${px2rem(32)};
  color: rgba(25, 31, 37, 0.8);
  padding-right: ${px2rem(SPACING * 3)};
`;

export default class ListEditComponent extends ControlBase<IListEditState, ListEdit> {
  private handleAddClick = () => {
    this.instance.store.dispatch('addButtonClickAfter', {});
  };

  private handleRemoveClick = (row: any, index: number) => {
    this.instance.store.dispatch('deleteRow', { index });
  };

  renderAddButton() {
    const { addRowButton } = this.state,
      { display, size, theme, title, loading } = addRowButton;
    let type: 'primary' | 'warning' | 'ghost' | undefined = undefined;
    if (theme === 'primary') type = 'primary';
    else if (theme === 'ghost') type = 'ghost';
    if (display)
      return (
        <Button
          type={type}
          loading={loading}
          size={size === 'default' ? undefined : size}
          onClick={this.handleAddClick}
        >
          {title}
        </Button>
      );
    return null;
  }

  renderContent() {
    const { ControlFactory } = globalData,
      { pageKey, controlId } = this.props,
      itemProps = { pageKey, parentId: controlId },
      { _dataSource, readonly, columns, title } = this.state;
    return (
      <Container>
        <Content>
          {_dataSource.length > 0 ? (
            _dataSource.map((row, i) => {
              const handleRemoveClick = () => this.handleRemoveClick(row, i);
              return (
                <ListContainer key={row._rid}>
                  <TitleContainer>
                    <Title>{`${title}${i + 1}`}</Title>
                    {!readonly && <Remove onClick={handleRemoveClick} />}
                  </TitleContainer>
                  <List style={{ width: '100%' }}>
                    {columns.map(column => {
                      return (
                        <List.Item key={column.id}>
                          <ItemContainer>
                            <ItemTitle readonly={readonly}>{column.title}</ItemTitle>
                            <ItemValue readonly={readonly}>
                              <ControlFactory controlId={column.id + row._rid} {...itemProps} />
                            </ItemValue>
                          </ItemContainer>
                        </List.Item>
                      );
                    })}
                  </List>
                </ListContainer>
              );
            })
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={readonly ? EMPTYTEXT : EMPTYEDITTEXT}
            />
          )}
        </Content>
        {this.renderAddButton()}
      </Container>
    );
  }
}
