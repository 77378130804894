import Store from '@mjcloud/redux';
import Reduce from './reduce';
import WaterfallExtendStore from './extendStore';
import { ContainerInstanceBase } from '@mjcloud/instance';

export default class Waterfall extends ContainerInstanceBase {
  __createStore() {
    return new Store({
      id: this.id,
      reduce: Reduce,
      extendStore: new WaterfallExtendStore(this),
    });
  }

  createControl(id: string) {
    // 创建复杂控件
    return this.page.createControl(id, { parentType: 'waterfall' });
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}
