import Store from '@mjcloud/redux';
import Reduce from './reduce';
import ToolbarExtendStore from './extendStore';
import { ContainerInstanceBase } from '@mjcloud/instance';

export default class Toolbar extends ContainerInstanceBase {
  __createStore() {
    return new Store({
      id: this.id,
      reduce: Reduce,
      extendStore: new ToolbarExtendStore(this),
    });
  }

  createControl(config) {
    return this.__createControl(config.id, config);
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}
