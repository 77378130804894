import { ENV } from '@mjcloud/jsapi';
/**
 * 页面除去中间内容显示区的高度，用于算法：`window.innerHeight - PADDING`
 */
export const PADDING = 64;

/**
 * 左侧菜单展开时的宽度
 */
export const SIDERWIDTH = 200;

/**
 * 平台主页面路径
 */
export const MAINPATH = ENV.isMobile ? '/MainMobile' : '/Main';
