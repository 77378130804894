import { v4 as uuid } from 'uuid';
import Store from '@mjcloud/redux';
import PageModeHelper from '@mjcloud/page-mode-helper';
import DataSource from '@mjcloud/data-source-helper';
import { SCROLLMINHEIGHT } from '../constant';
import { ITableBaseRow } from '../common/table/typings';
import { OPERATIONWIDTH } from '../common/table/constant';
import { Key, TableRowSelection } from '../../components/VirtualizedTable/interface';
import TableReduceBase, { updateColumns } from '../common/table/reduce';
import {
  ITreeViewState,
  ITreeViewInitialStateParams,
  ITreeViewLoadedParams,
  TreeViewActionType,
  ITreeViewUpdateSelectedRowsParams,
  ITreeViewUpdateExpandedRowKeysParams,
} from './typings';
import {
  convertDataToEntities,
  parseCheckedKeys,
  conductCheck,
} from '../../components/VirtualizedTable';

interface IFormatDataParams {
  source: any[];
  disabledFn: (row: any) => boolean;
}

function formatData(params: IFormatDataParams) {
  const { source, disabledFn } = params;
  for (const row of source) {
    row._disabled = disabledFn(row);
    if (row._children && row._children.length > 0) {
      formatData({ source: row._children, disabledFn });
    }
  }
}

export class TreeViewReduce extends TableReduceBase<
  ITableBaseRow[],
  ITableBaseRow,
  ITreeViewState
> {
  initialState(
    store: Store<ITreeViewState, TreeViewActionType>,
    params: ITreeViewInitialStateParams,
  ) {
    const { initConfig, pageMode, onCell, onOperation, aggs, refs } = params,
      { mayCustom, expandLevel, modifyMode, displayMode, showSummary } = initConfig,
      { collapseMenu, rowSelection, orderby, fastnessCount, rowSelectionType } = initConfig,
      { selectionStrictly, reviseControllerId, enableRevise, disabledControl } = initConfig,
      items = initConfig.items.items,
      showOperation = initConfig.buttons ? initConfig.buttons.items.length > 0 : false,
      isModify = PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      // collapse = collapseMenu == null ? true : collapseMenu,
      collapse = false, // TODO: 针对操作列样式异常临时处理
      { columns, isParent, totalWidth } = this.getColumns(
        {
          refs,
          store,
          items,
          pageMode,
          collapse,
          isModify,
          showOrdinal: false,
          // TODO: 针对操作列样式异常临时处理
          isCollapse: false,
          showOperation,
          fastnessCount,
          onCell,
          onOperation,
        },
        true,
      );
    let _rowSelection: TableRowSelection<ITableBaseRow> | undefined = undefined;
    if (rowSelection) {
      _rowSelection = { type: rowSelectionType, strictly: !!selectionStrictly };
    }

    const state: ITreeViewState = {
      aggs,
      orderby,
      columns,
      collapse,
      totalWidth,
      expandLevel,
      tabIndex: -1,
      showOperation,
      rowIdCount: 0,
      dataSource: [],
      disabledControl,
      keyEntities: {},
      isGridEdit: true,
      modify: isModify,
      isFetching: false,
      config: initConfig,
      expandedRowKeys: [],
      expandLevel2Keys: {},
      originalDataSource: [],
      entireColumns: columns,
      originalColumns: items,
      mayCustom: !!mayCustom,
      selectedRows: undefined,
      configIsFetching: false,
      showSummary: !!showSummary,
      rowSelection: _rowSelection,
      halfSelectedRows: undefined,
      scrollHeight: SCROLLMINHEIGHT,
      operationWidth: OPERATIONWIDTH,
      defaultExpandLevel: expandLevel,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      pagination: false,
    };
    state.columns = updateColumns(state.columns);
    return state;
  }
  loaded(store: Store<ITreeViewState>, params: ITreeViewLoadedParams) {
    let { disabledFn } = params,
      { summaryDataSource, defaultExpandLevel, rowIdCount, config } = store.state,
      ordinalIdCount = 0;

    const { aggs, rows = [] } = params.dataSource;
    const originalDataSource = DataSource.formatTreeDataSource<ITableBaseRow>(
      config.data,
      rows,
      row => false,
      row => ({ _rid: ++rowIdCount, _ordinalId: ++ordinalIdCount }),
    );
    if (aggs) {
      summaryDataSource = [{ ...aggs, _rid: uuid().replace(/-/g, '') }] as any[];
    }
    let {
      dataSource,
      expandLevel2Keys,
      expandLevel: currentExpandLevel,
      treeDefaultExpandedKeys: expandedRowKeys,
    } = DataSource.formatTreeData<ITableBaseRow>(originalDataSource, defaultExpandLevel, '_rid');
    if (disabledFn) formatData({ source: dataSource, disabledFn });

    const { keyEntities } = convertDataToEntities(dataSource, '_children', {}, '_rid');
    const state: ITreeViewState = {
      ...store.state,
      rowIdCount,
      dataSource,
      keyEntities,
      expandedRowKeys,
      expandLevel2Keys,
      isFetching: false,
      summaryDataSource,
      originalDataSource,
      selectedRows: undefined,
      expandLevel: currentExpandLevel,
    };
    return state;
  }

  updateSelectedRows(store: Store<ITreeViewState>, params: ITreeViewUpdateSelectedRowsParams) {
    const state: ITreeViewState = store.state;

    const { keyEntities, rowSelection } = state;
    const { row, checked, selectedRowKeys = [] } = params;

    let checkedKeys: Key[] = [],
      halfCheckedKeys: Key[] = [];
    if (rowSelection && rowSelection.type === 'radio') {
      checkedKeys = [...selectedRowKeys];
    } else {
      if (row) {
        // Always fill first
        const conductKeys = conductCheck(selectedRowKeys, true, keyEntities);
        ({ checkedKeys, halfCheckedKeys } = conductKeys);

        // If remove, we do it again to correction
        if (!checked) {
          const keySet = new Set(checkedKeys);
          keySet.delete(row._rid);
          ({ checkedKeys, halfCheckedKeys } = conductCheck(
            Array.from(keySet),
            { checked: false, halfCheckedKeys },
            keyEntities,
          ));
        }
      } else {
        const checkedKeyEntity = parseCheckedKeys(selectedRowKeys);
        if (checkedKeyEntity) {
          const conductKeys = conductCheck(checkedKeyEntity.checkedKeys, true, keyEntities);
          ({ checkedKeys, halfCheckedKeys } = conductKeys);
        }
      }
    }

    state.selectedRows = checkedKeys.map(k => keyEntities[k].node) as any[];
    state.halfSelectedRows = halfCheckedKeys.map(k => keyEntities[k].node) as any[];
    return { ...state };
  }

  updateExpandedRowKeys(
    store: Store<ITreeViewState>,
    params: ITreeViewUpdateExpandedRowKeysParams,
  ) {
    const state: ITreeViewState = store.state;
    state.expandedRowKeys = params.expandedRowKeys;
    state.expandLevel = params.expandLevel;
    return { ...state };
  }
}

export default new TreeViewReduce();
