import styled from 'styled-components';
import { SPACING } from '../../constant';
import KeyCode from 'rc-util/lib/KeyCode';
import { Table as StoreTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import { GetComponentProps } from 'rc-table/lib/interface';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { useRef, forwardRef, useImperativeHandle } from 'react';

export type TableRowClickFn<T> = (
  record: T,
  index: number | undefined,
  event: React.MouseEvent<HTMLElement, MouseEvent>,
) => void;
export interface ITableProps
  extends Omit<
    TableProps<any>,
    | 'bordered'
    | 'size'
    | 'scroll'
    | 'tableLayout'
    | 'rowClassName'
    | 'rowSelection'
    | 'rowKey'
    | 'onRow'
  > {
  rowSelection: TableRowSelection<any>;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export interface TableInstance {
  el: HTMLDivElement | null;
  onKeyDown: React.KeyboardEventHandler;
  getClientWidth: (totalWidth: number) => number | string | undefined;
}

const Container = styled.div`
  padding: ${SPACING}px;
  overflow: auto;

  .ant-table-row {
    cursor: pointer;
  }
  tr.ant-table-row-selected td {
    background: #bae7ff !important;
  }
  tr.ant-table-row-hover td {
    background: #fafafa !important;
  }
  tr.ant-table-row-disabled td {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 2px;
  }
  .ant-table-pagination.ant-pagination {
    margin: 8px 0 0 0;
  }
`;

const Table: React.ForwardRefRenderFunction<TableInstance, ITableProps> = (props, ref) => {
  const divRef = useRef<HTMLDivElement>(null);
  const { dataSource = [] } = props;
  const { style, rowSelection, children, pagination, onMouseDown, ...antProps } = props;
  const { type, selectedRowKeys = [], onChange } = rowSelection;
  const multiSelect = type === 'checkbox';

  // ========================== Active ==========================
  const getEnabledActiveIndex = (index: number, offset: number = 1): number => {
    const len = dataSource.length;

    for (let i = 0; i < len; i += 1) {
      const current = (index + i * offset + len) % len;
      const record = dataSource[current];
      if (!record._disabled) return current;
    }

    return -1;
  };
  const [activeIndex, setActiveIndex] = React.useState(() => getEnabledActiveIndex(0));
  const setActive = (index: number) => {
    setActiveIndex(index);
  };

  // ========================= Keyboard =========================
  useImperativeHandle(ref, () => ({
    el: divRef.current,
    onKeyDown: event => {
      const { which } = event;
      switch (which) {
        // >>> Arrow keys
        case KeyCode.UP:
        case KeyCode.DOWN: {
          let offset = 0;
          if (which === KeyCode.UP) {
            offset = -1;
          } else if (which === KeyCode.DOWN) {
            offset = 1;
          }

          if (offset !== 0) {
            const nextActiveIndex = getEnabledActiveIndex(activeIndex + offset, offset);
            setActive(nextActiveIndex);
          }

          break;
        }

        // >>> Select
        case KeyCode.ENTER: {
          // value
          const record = dataSource[activeIndex];
          if (onChange && record) {
            if (!multiSelect) {
              onChange([record._rid], [record]);
            } else {
              // TODO: 多选下通过键盘选定功能
            }
          }

          // if (open) {
          //   event.preventDefault();
          // }

          break;
        }

        // >>> Close
        case KeyCode.ESC: {
          // onToggleOpen(false);
        }
      }
    },
    getClientWidth: totalWidth => {
      const el = divRef.current;
      if (el && el.parentElement && el.parentElement.parentElement) {
        let parentEl = el.parentElement.parentElement,
          offsetWidth: number | string | null = parentEl.style.left,
          // offsetTop: number | string | null = parentEl.style.top,
          minWidth: number | string | null = parentEl.style.minWidth,
          parentElement = parentEl.parentElement;
        offsetWidth = offsetWidth ? offsetWidth : parentEl.style.right;
        parentElement = parentElement && parentElement.parentElement;
        parentElement = parentElement && parentElement.parentElement;
        if (parentElement) {
          let parentWidth = parentElement.clientWidth,
            dropdownWidth: number | string = `calc(${parentWidth}px - ${offsetWidth})`;
          if (parentWidth && offsetWidth) {
            const offsetWidthPx = offsetWidth.indexOf('px');
            if (offsetWidthPx > 0 && offsetWidth.length - 2 === offsetWidthPx) {
              offsetWidth = offsetWidth.substring(0, offsetWidthPx);
              offsetWidth = Number(offsetWidth);
              if (!isNaN(offsetWidth)) {
                dropdownWidth = parentWidth - offsetWidth;
                if (dropdownWidth > totalWidth) {
                  dropdownWidth = totalWidth + 8;
                }
              }
            }
            if (minWidth) {
              const minWidthPx = minWidth.indexOf('px');
              if (minWidthPx > 0 && minWidth.length - 2 === minWidthPx) {
                minWidth = minWidth.substring(0, minWidthPx);
                minWidth = Number(minWidth);
                if (!isNaN(minWidth)) {
                  if (dropdownWidth < minWidth) {
                    dropdownWidth = minWidth;
                  }
                }
              }
            }
            return dropdownWidth;
          }
        }
      }
      return undefined;
    },
  }));

  // ========================== Select ==========================
  const rowClassName = (record: any, index: number) => {
    if (record._disabled) return 'ant-table-row-disabled';
    if ((selectedRowKeys as any[]).indexOf(record._rid) >= 0) {
      return 'ant-table-row-selected';
    }
    if (activeIndex === index) {
      return 'ant-table-row-hover';
    }
    return '';
  };
  const handleTableRowClick: TableRowClickFn<any> = (record, index, event) => {
    if (record._disabled) return;
    if (onChange) onChange([record._rid], [record]);
  };
  const handleTableRow: GetComponentProps<any> = (record, index) => ({
    onClick: event => handleTableRowClick(record, index, event),
  });

  return (
    <Container ref={divRef} style={style} onMouseDown={onMouseDown}>
      <StoreTable
        {...antProps}
        bordered
        size="small"
        rowKey="_rid"
        rowClassName={rowClassName}
        scroll={{ x: 'max-content', y: 270 }}
        pagination={pagination ? { ...pagination, size: 'small' } : undefined}
        rowSelection={multiSelect ? rowSelection : undefined}
        onRow={!multiSelect ? handleTableRow : undefined}
      />
      {children}
    </Container>
  );
};

export default forwardRef(Table);
