import Form from '.';
import Store from '@mjcloud/redux';
import SectionReduce from './section/reduce';
import { ContainerDataExtendStoreBase } from '@mjcloud/extend-store';
import { DataStateEnum, PageModeEnum } from '@mjcloud/types';
import FormSectionExtendStore from './section/extendStore';

export default class FormExtendStore extends ContainerDataExtendStoreBase<Form> {
  protected isNeedNotificationPage = true;

  handleInitialStateBefore(e) {
    const { initConfig } = e.params;
    const sectionConfigList = (initConfig.sections && initConfig.sections.items) || [],
      sectionsLength = sectionConfigList.length,
      sections: any[] = [];
    sectionConfigList
      .map(item => {
        if (item.index == null) item.index = 10;
        return item;
      })
      .sort((a, b) => a.index - b.index)
      .forEach((sectionConfig, i) => {
        const { id } = sectionConfig;
        if (id) {
          const store = new Store({
            id,
            reduce: SectionReduce,
            extendStore: new FormSectionExtendStore(id, this.instance),
          });
          sections.push({
            id: store['_id'],
            controlId: this.instance.id,
            parentId: this.instance.parent.id,
            pageKey: this.instance.page.id,
          });
          this.instance.__registerFormSection(store['_id'], store);
          sectionConfig.showDivider = i === sectionsLength - 1 ? false : sectionConfig.showDivider;
          store.dispatch('initialState', {
            initConfig: sectionConfig,
            pageMode: this.instance.page.pageMode,
          });
        } else {
          console.error(
            `容器控件Form-${this.instance.id} 下的section节点中的 id 不存在`,
            sectionConfig,
          );
        }
      });
    e.params.sections = sections;
  }

  handleInitialStateAfter(e) {
    super.handleInitialStateAfter(e);
    e.newState.state.dataSource.bind('valueChange', this.handleDataModelValueChange.bind(this));
    e.newState.state.dataSource.bind(
      'dataStateChange',
      this.handleDataModelDataStateChange.bind(this),
    );
  }

  handleLoadedBefore(e) {
    e.params.dataState = DataStateEnum.added;
    if (this.instance.page.pageMode === PageModeEnum.modify) {
      e.params.dataState = DataStateEnum.unchanged;
    }
  }

  handleLoadedAfter(e) {
    super.handleLoadedAfter(e);
    this.instance.__updateValues(e.newState.state.dataSource.toJSON(), this.instance, true);
  }

  handleDataModelValueChange(e) {
    if (e.eventSourceSign === this.instance) return;
    if (e.eventSourceSign === this.instance.dataModel) return;
    this.instance.__updateValues(e.data.values, this.instance.dataModel);
  }

  handleDataModelDataStateChange(e) {}
}
