import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import { GroupedBar as G2plotRangeBar, GroupedBarConfig as G2plotProps } from '@antv/g2plot';
import useChart from '../hooks/useChart';
import { ErrorBoundary } from '../base';

export interface GroupedBarConfig extends Omit<G2plotProps, 'tooltip'> {
    chartRef?: React.MutableRefObject<G2plotRangeBar | undefined>;
    style?: React.CSSProperties;
    className?: string;
    // isGroup?:boolean;
    // label?:any;
    // groupField?:any;
    // marginRatio?:number;
  }

  const TransverseBarChart = forwardRef((props: GroupedBarConfig, ref) => {
    const { chartRef, style = {}, className, ...rest } = props;
  
    const { chart, container } = useChart<G2plotRangeBar, GroupedBarConfig>(G2plotRangeBar, rest);
  
    useEffect(() => {
      if (chartRef) {
        chartRef.current = chart.current;
      }
    }, [chart.current]);
    useImperativeHandle(ref, () => ({
      getChart: () => chart.current,
    }));
  
    return (
      <ErrorBoundary>
        <div className={className} style={style} ref={container} />
      </ErrorBoundary>
    );
  });

  TransverseBarChart.defaultProps = G2plotRangeBar.getDefaultOptions();

  export default TransverseBarChart;