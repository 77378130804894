import Store from '@mjcloud/redux';
import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { DateHelper, NumberHelper } from '@mjcloud/utils';
import { ILabelConfig, ILabelState, ILabelInitialStateParams } from './typings';

export class LabelReduce extends ValueReduceBase {
  initialState(store: Store<ILabelState>, params: ILabelInitialStateParams) {
    const { initConfig, pageMode } = params,
      { format2Date, format2Number } = initConfig,
      { title, modifyMode, defaultValue = store.state.value } = initConfig,
      { displayMode, isHtml, fontSize, fontColor, fontWeight } = initConfig;
    let { value = defaultValue } = initConfig;
    if (value !== (0 as any) && !value) {
      value = defaultValue;
    }
    let _fontWeight: any = Number(fontWeight),
      _value = value;
    if (isNaN(_fontWeight)) {
      _fontWeight = fontWeight;
    }
    if (_value != null) {
      if (format2Number) {
        _value = NumberHelper.format(value, format2Number);
      } else if (format2Date) {
        _value = DateHelper.format(value, format2Date);
      }
    }
    let state: ILabelState = {
      title,
      value,
      fontSize,
      fontColor,
      format2Date,
      text: _value,
      tabIndex: -1,
      format2Number,
      isHtml: !!isHtml,
      config: initConfig,
      fontWeight: _fontWeight,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: false,
    };
    return state;
  }
}

export default new LabelReduce();
