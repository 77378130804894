import Service from '@mjcloud/service';
import { ControllerBase } from './base';
import globalData from '@mjcloud/global-data';
import { InstanceBase } from '@mjcloud/instance';
import OpenWindowController from './openWindow';
import { ExceptionHelper } from '@mjcloud/exception';
import { PageAddress } from '@mjcloud/page/dist/typings';
import { IDictionary, Omit, PageModeEnum } from '@mjcloud/types';

import ReportController from './report';
import ExportFileController from './exportFile';
import ImportFileController from './importFile';
import ExecuteScriptController from './executeScript';
import ExecuteServiceController from './executeService';
import ExecuteJoinController from './executeJoinController';
import OpenProcessServiceController from './openProcess';
import LaunchProcessServiceController from './launchProcess';

const controllers = {
  executeScript: ExecuteScriptController,
  executeService: ExecuteServiceController,
  executeJoinController: ExecuteJoinController,
  openWindow: OpenWindowController,
  launchProcess: LaunchProcessServiceController,
  openProcess: OpenProcessServiceController,
  report: ReportController,
  exportFile: ExportFileController,
  importFile: ImportFileController,
};

export interface IExecuteControllerParams {
  sender: InstanceBase;
  controllerId: string;
  data: IDictionary;
  before?: (params: IDictionary, extra?: IDictionary) => Promise<IDictionary>;
  after?: (params: IDictionary, extra?: IDictionary) => Promise<IDictionary>;
}

export interface IControllerData extends IDictionary {
  /**
   * 请求参数，可在请求控制器前修复已有请求参数
   */
  request: IDictionary;
  /**
   *  执行控制器后返回结果
   */
  result?: IDictionary;
}

export interface IExecuteByCreatedControllerParams
  extends Omit<IExecuteControllerParams, 'controllerId' | 'sender'> {
  controller: ControllerBase;
}

export default class ControllerHelper {
  /**
   * 根据实例和权限点获取当前用户是否拥有该权限
   * @param sender 实例
   * @param code 权限点
   */
  public static hasRights(address: PageAddress, code: string) {
    if (window && window['__permission']) return true;
    if (code === '-1' && globalData.debugger) return true;
    let moduleCode: string | undefined;
    if (!address.authorityNeedAddress) moduleCode = globalData.rbCore.top.getParam('_moduleCode');
    const { appId, moduleId, pageMode } = address;
    if (!moduleCode) {
      moduleCode = globalData.rbCore.findModuleCode(appId, moduleId);
    }
    if (moduleCode) {
      const codes: string[] = [],
        _codes: string[] = code.split(',');
      if (_codes.length > 1) {
        const [addCode, modifyCode] = _codes;
        switch (pageMode) {
          case PageModeEnum.add:
            codes.push(addCode);
            break;

          case PageModeEnum.modify:
            codes.push(modifyCode);
            break;
        }
      } else if (_codes[0]) {
        codes.push(_codes[0]);
      }
      return globalData.rbCore.permission(
        appId,
        codes.map(_code => `${moduleCode}:${_code}`),
      );
    }
    return false;
  }

  /**
   * 创建指定页面下的控制器对象
   * @param page 页面对象
   * @param id 控制器id
   */
  public static async create(sender: InstanceBase, id: string): Promise<ControllerBase | null> {
    const initData = await Service.getControllerConfig({
      controllerId: id,
      pageAddress: sender.page.address,
      workbenchPartAddress: sender.workbenchPart && sender.workbenchPart.address,
    });
    if (initData) {
      if (initData.code) {
        initData['hasRights'] = this.hasRights(sender.page.address, initData.code);
      } else {
        initData['hasRights'] = true;
      }
      const controllerType = controllers[initData.nodeName];
      if (!controllerType) {
        ExceptionHelper.dispose(
          ExceptionHelper.notImplementException(`平台不支持 ${initData.nodeName} 控制器`),
        );
        return null;
      }
      return new controllerType(sender, initData);
    }
    return null;
  }

  public static async executeByCreated(params: IExecuteByCreatedControllerParams) {
    const extra: IDictionary = {},
      { controller, before, after, data } = params;
    let count = 0,
      isSuspend = false;
    async function* executeController(): AsyncIterableIterator<number> {
      yield 1;
      data.request = await controller.createRequest(data);
      if (before) await before(data, extra);
      if (controller._before) await controller._before(data, extra);
      yield 2;
      data.result = await controller.execute(data.request);
      yield 3;
      if (controller._after) await controller._after(data, extra);
      if (after) await after(data, extra);
      yield 4;
    }
    const _executeController = await executeController();
    async function _executeControllerFn() {
      if (count === 0 && !isSuspend) count = (await _executeController.next()).value;
      if (count === 1 && !isSuspend) count = (await _executeController.next()).value;
      if (count === 2 && !isSuspend) count = (await _executeController.next()).value;
      if (count === 3 && !isSuspend) count = (await _executeController.next()).value;
    }
    extra.suspend = () => {
      isSuspend = true;
    };
    extra.continue = async () => {
      isSuspend = false;
      if (count) {
        if (extra._before) extra._before();
        await _executeControllerFn();
        if (extra._after) extra._after();
      }
    };
    await _executeControllerFn();
    return data;
  }

  public static async execute(params: IExecuteControllerParams) {
    const { sender, controllerId, data } = params;
    const controller = await this.create(sender, controllerId);
    if (controller) {
      return this.executeByCreated({ ...params, controller });
    }
    return data;
  }
}
