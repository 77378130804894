import { ValueDataExtendStoreBase } from '@mjcloud/extend-store';
import { DataStateEnum, PageModeEnum } from '@mjcloud/types';

export default class RecordWorkExtendStore extends ValueDataExtendStoreBase {
  protected isNeedNotificationPage = true;
  handleLoadedBefore(e) {
    const { pageMode } = this.instance.page.address;
    e.params.dataState =
      pageMode === PageModeEnum.add ? DataStateEnum.added : DataStateEnum.unchanged;
  }
}
