import React from 'react';
import ErrorModal from './error';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import BasicLayout from './layouts/BasicLayout';
import { hideScreen } from './util';

const Layout: React.FC = ({ children }) => {
  hideScreen();
  return (
    <ConfigProvider locale={zhCN}>
      <BasicLayout>
        {children}
        <ErrorModal />
      </BasicLayout>
    </ConfigProvider>
  );
};

export default Layout;
