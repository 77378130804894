import Store from '@mjcloud/redux';
import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { DateHelper } from '@mjcloud/utils';
import { IDatetimeState, ITextboxInitialStateParams } from './typings';

export class DatetimeReduce extends ValueReduceBase<Date | null, IDatetimeState> {
  initialState(store: Store<IDatetimeState>, params: ITextboxInitialStateParams) {
    const { initConfig, pageMode } = params,
      { title, autoFocus, modifyMode, maxControl, minControl } = initConfig,
      { placeholder = `请输入${title || ''}` } = initConfig,
      { format: _format, displayMode, disabledMode } = initConfig;
    const { format, mode, showTime } = DateHelper.dateFormat2momentFormat(_format);
    let state: IDatetimeState = {
      mode,
      title,
      format,
      showTime,
      text: '',
      autoFocus,
      maxControl,
      minControl,
      placeholder,
      value: null,
      tabIndex: 0,
      config: initConfig,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    return state;
  }

  updateValue(store: Store<IDatetimeState>, params) {
    const state = super.updateValue(store, { ...params, noUpdate: true });
    // 通过判断_actionSourceSign存在与否来判断更新源
    if (state.value) {
      if (state.value['_actionSourceSign']) {
        return state;
      }
      state.text = DateHelper.format(state.value, state.format);
      state.value = DateHelper.parse(state.value);
      if (state.value) state.value['_actionSourceSign'] = true;
    } else {
      state.value = state.text = null as any;
    }
    return { ...state };
  }

  updateMaxAMinValue(store: Store<IDatetimeState>, params) {
    let state = store.state,
      isUpdate = false;
    if (params.min) {
      state.min = params.min;
      isUpdate = true;
    }
    if (params.max) {
      state.max = params.max;
      isUpdate = true;
    }
    return isUpdate ? { ...state } : state;
  }
}

export default new DatetimeReduce();
