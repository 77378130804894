import { DataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';

class AttendanceReduce extends DataReduceBase {
  initialState(store, params) {
    let { initConfig, pageMode } = params,
      {
        attendanceDaysFieldName = 'attendanceDays',
        dueDaysFieldName = 'dueDays',
        lateDaysFieldName = 'lateDays',
        leaveEarlyDaysFieldName = 'leaveEarlyDays',
        lackDaysFieldName = 'lackDays',
        displayMode,
      } = initConfig;
    return {
      tabIndex: -1,
      dataSource: {},
      config: initConfig,
      attendanceDaysFieldName,
      dueDaysFieldName,
      lateDaysFieldName,
      leaveEarlyDaysFieldName,
      lackDaysFieldName,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
  }
}

export default new AttendanceReduce();
