import Icon from '@mjcloud/icon';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useUpdateEffect } from 'ahooks';
import ContentLoader from 'react-content-loader';
import { COLOR } from '../../constant';

export type UploadFileStatus = 'error' | 'success' | 'done' | 'uploading' | 'removed';

interface ImageProps {
  src?: string;
  alt?: string;
  status?: UploadFileStatus;
  className?: string;
  onClick?: (event: any) => void;
}

const ImageLoad: React.FC = () => (
  <ContentLoader backgroundColor={COLOR.MINOR} viewBox="0 0 1024 1024">
    <path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zM338 304c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64z m513.9 437.1c-1.4 1.2-3.3 1.9-5.2 1.9H177.2c-4.4 0-8-3.6-8-8 0-1.9 0.7-3.7 1.9-5.2l170.3-202c2.8-3.4 7.9-3.8 11.3-1 0.3 0.3 0.7 0.6 1 1l99.4 118 158.1-187.5c2.8-3.4 7.9-3.8 11.3-1 0.3 0.3 0.7 0.6 1 1l229.6 271.6c2.6 3.3 2.2 8.4-1.2 11.2z"></path>
  </ContentLoader>
);

const CustomizeImage: React.FC<ImageProps> = ({
  src,
  alt,
  className,
  status: _status,
  onClick,
}) => {
  const [status, setStatus] = useState<UploadFileStatus | undefined>(
    _status === 'done' ? 'uploading' : _status,
  );
  useUpdateEffect(() => {
    if (_status !== 'done') setStatus(_status);
    return () => {};
  }, [_status]);
  const onLoad = () => {
    setStatus('done');
  };
  const onError = () => {
    setStatus('error');
  };
  console.log('_status', status);

  return (
    <div className={className}>
      {status === 'uploading' && <ImageLoad />}
      {status === 'error' && <Icon type="image-error" />}
      {src && (
        <img
          style={{ display: status === 'done' ? undefined : 'none' }}
          src={src}
          alt={alt}
          onLoad={onLoad}
          onError={onError}
          onClick={onClick}
        />
      )}
    </div>
  );
};

const Image = styled(CustomizeImage)`
  color: ${COLOR.MINOR};

  > * {
    width: 100% !important;
    height: 100% !important;
    vertical-align: top !important;
  }

  > img {
    object-fit: scale-down;
  }
`;

export default Image;
