import { IDatetimeConfig } from './typings';

const config: IDatetimeConfig = {
  title: '',
  min: undefined,
  max: undefined,
  autoFocus: false,
  format: 'yyyy-MM-dd',
  businessType: undefined,
  placeholder: undefined,
  modifyMode: undefined,
  displayMode: undefined,
};

export default config;
