export default class DelayCallback {
  private _delayTime: number;
  private _callback: Function;
  private _cancelCallback?: Function;
  private _setTimeId: number = 0;
  private _data: any;

  constructor(callback: Function, delayTime: number = 1) {
    this._delayTime = delayTime * 1000;
    this._callback = callback;
  }

  public bindCancelCallback(callback: Function) {
    this._cancelCallback = callback;
  }

  public execute(data: any) {
    this.clean();

    this._data = data;
    this._setTimeId = window.setTimeout(this._handTimeoutCallback, this._delayTime);
  }

  public clean() {
    if (this._setTimeId) {
      window.clearTimeout(this._setTimeId);
      if (this._cancelCallback) this._cancelCallback();
    }
  }

  private _handTimeoutCallback = () => {
    this._setTimeId = 0;
    this._callback(this._data);
  };
}
