import ToolBar from '.';
import Store from '@mjcloud/redux';
import { IControlBaseProps } from '../base';
import { ActionBeforeEventArg } from '@mjcloud/redux';
import { ContainerExtendStoreBase } from '@mjcloud/extend-store';
import { IToolbarInitialStateParams, ToolbarThemeType } from './typings';

export default class ToolbarExtendStoreBase extends ContainerExtendStoreBase<ToolBar> {
  handleInitialStateBefore(e: ActionBeforeEventArg<IToolbarInitialStateParams>) {
    let theme: ToolbarThemeType = 'normal';
    const { initConfig } = e.params,
      { style, areas } = initConfig,
      leftControls: Store[] = [],
      rightControls: Store[] = [],
      leftFCs: IControlBaseProps[] = [],
      rightFCs: IControlBaseProps[] = [];
    if (style) theme = style;
    if (areas) {
      if (areas.left && areas.left.controls) {
        areas.left.controls.items.forEach(item => {
          const controlInstance = this.instance.createControl(item, theme);
          if (controlInstance) {
            leftControls.push(controlInstance.store);
            leftFCs.push({
              id: controlInstance.id,
              parentId: this.instance.id,
              pageKey: this.instance.page.id,
            });
          }
        });
      }
      if (areas.right && areas.right.controls) {
        areas.right.controls.items.forEach(item => {
          const controlInstance = this.instance.createControl(item, theme);
          if (controlInstance) {
            rightControls.push(controlInstance.store);
            rightFCs.push({
              id: controlInstance.id,
              parentId: this.instance.id,
              pageKey: this.instance.page.id,
            });
          }
        });
      }
    }
    e.params.leftFCs = leftFCs;
    e.params.rightFCs = rightFCs;
    e.params.leftControls = leftControls;
    e.params.rightControls = rightControls;
  }
}
