import React from 'react';
import Icon from '@mjcloud/icon';
import { Tooltip, Col } from 'antd';
import { findDOMNode } from 'react-dom';
import globalData from '@mjcloud/global-data';
import { RequiredTypeEnum } from '@mjcloud/types';
import FormItem from '../../../../components/FormItem';
import { IFormItemState, IFormItemProps } from '../typings';

class FormItemControl extends React.Component<IFormItemProps, IFormItemState> {
  private container: Element | null = null;
  constructor(props: IFormItemProps) {
    super(props);
    this.state = props.store.state;
    props.store.bindViewListen(this.handStateChange);
  }

  componentWillReceiveProps(nextProps: IFormItemProps) {
    if (this.props.store !== nextProps.store) {
      this.props.store.unbindViewListen(this.handStateChange);
      nextProps.store.bindViewListen(this.handStateChange);
      this.setState(nextProps.store.state);
    }
  }

  componentWillUnmount() {
    this.props.store.unbindViewListen(this.handStateChange);
  }

  // targetingToCurrent() {
  //   if (this.container) {
  //     this.container.scrollIntoView();
  //   }
  // }

  // private getContainer = (containerId: string) => {
  //   if (containerId && !this.containerId) {
  //     this.containerId = containerId;
  //     if (!this.container) {
  //       this.container = document.getElementById(containerId);
  //     }
  //   }
  // };

  // private readyContainer = (instance: FormItem | null) => {
  //   if (instance) {
  //     const el = findDOMNode(instance);
  //     if (el instanceof Element) {
  //       this.container = el;
  //     }
  //   }
  // };

  private handStateChange = (state: IFormItemState, cb?: () => void) => {
    this.setState(state, cb);
  };

  renderContent() {
    const { ControlFactory } = globalData,
      { labelNumber, labelWidth, parentId, pageKey } = this.props,
      { id, title, tip, showTitle, colspan, nodeName } = this.state,
      { tipIcon = 'icon-question-circle' } = this.state;
    const { requiredType, readonly, color, help, validateStatus, actionSourceSign } = this.state;
    let label: React.ReactNode = null;
    if (showTitle) {
      if (tip) {
        label = (
          <span>
            {title}&nbsp;
            <Tooltip title={<div dangerouslySetInnerHTML={{ __html: tip }} />}>
              <Icon type={tipIcon} style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          </span>
        );
      } else {
        label = title;
      }
    }
    // TODO: 校验错误定位
    // if (validateStatus && actionSourceSign === this.props.store) {
    //   this.targetingToCurrent();
    // }
    return (
      <FormItem
        id={id}
        help={help}
        label={label}
        cols={colspan}
        style={{ color }}
        labelWidth={labelWidth}
        labelNumber={labelNumber}
        // ref={this.readyContainer}
        validateStatus={validateStatus}
        required={readonly ? false : requiredType === RequiredTypeEnum.required}
      >
        <ControlFactory
          id={id}
          pageKey={pageKey}
          parentId={nodeName === 'complexItem' ? pageKey : parentId}
        />
      </FormItem>
    );
  }

  render() {
    const { span } = this.props;
    const { id, display } = this.state;
    if (!display) return null;
    if (span != null) {
      return (
        <Col span={span} key={id} id={`form_${id}`}>
          {this.renderContent()}
        </Col>
      );
    }
    return this.renderContent();
  }
}

export default FormItemControl;
