import Store from '@mjcloud/redux';
import Reduce from './reduce';
import LabelExtendStore from './extendStore';
import { ValueInstanceBase } from '@mjcloud/instance';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';

export default class Label extends ValueInstanceBase {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  __createStore() {
    return new Store({
      id: this.id,
      reduce: Reduce,
      extendStore: new LabelExtendStore(this),
    });
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}
