import Store from '@mjcloud/redux';
import Reduce from './reduce';
import { DataModeEnum } from '@mjcloud/types';
import AccordionExtendStore from './extendStore';
import { ContainerDataInstanceBase } from '@mjcloud/instance';
import { IAccordionState } from './typings';

export default class Accordion extends ContainerDataInstanceBase<IAccordionState> {
  getDataMode() {
    return DataModeEnum.all;
  }

  __createStore() {
    return new Store<IAccordionState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new AccordionExtendStore(this),
    });
  }

  createControl(id: string) {
    // 创建复杂控件
    return this.page.createControl(id, { parentType: 'accordion' }, true);
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', { initConfig });
  }
}
