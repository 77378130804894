import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { DateHelper, NumberHelper } from '@mjcloud/utils';
import ControllerHelper from '@mjcloud/controller';
import { ExceptionHelper } from '@mjcloud/exception';
import { ActionAfterEventArg } from '@mjcloud/redux';
import { ValueInstanceBase } from '@mjcloud/instance';
import { IDictionary, ControlConfig } from '@mjcloud/types';
import { ValueExtendStoreBase } from '@mjcloud/extend-store';
import { ValidationRuleType } from '@mjcloud/utils/dist/asyncValidator';
import { IHyperlinkConfig, IHyperlinkState, HyperlinkActionType } from './typings';

class HyperlinkExtendStore extends ValueExtendStoreBase<string, Hyperlink> {
  handleUpdateValueBefore(e) {
    super.handleUpdateValueBefore(e);
    e.params.text = this.instance.format(e.params.value as any);
  }

  async handleClickAfterAfter(e: ActionAfterEventArg<IHyperlinkState, HyperlinkActionType>) {
    const { title, controllerId } = e.newState.state;
    const exclusiveLockId = this.instance.page.applyExclusiveLock(`正在执行${title}操作...`);
    if (exclusiveLockId === 0) {
      e.newState.dispatch('clickDone', {});
      return;
    }
    let params: IDictionary = { ...e.params },
      before = async (data: IDictionary, extra: IDictionary = {}) => {
        if (this.instance.eventManager.getHandlerCount('onBeforeClick') > 0) {
          await this.instance.eventManager.trigger('onBeforeClick', data, extra);
        }
        return data;
      },
      after = async (data: IDictionary, extra: IDictionary = {}) => {
        if (this.instance.eventManager.getHandlerCount('onAfterClick') > 0) {
          await this.instance.eventManager.trigger('onAfterClick', data, extra);
        }
        return data;
      };
    try {
      if (controllerId) {
        await ControllerHelper.execute({
          sender: this.instance,
          controllerId,
          data: params,
          before,
          after,
        });
      } else {
        await before(params);
        await after(params);
      }

      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      e.newState.dispatch('clickDone', {});
    } catch (error) {
      e.newState.dispatch('clickDone', {});
      this.instance.page.releaseExclusiveLock(exclusiveLockId);
      ExceptionHelper.dispose(error);
    }
  }
}

class Hyperlink extends ValueInstanceBase<string, IHyperlinkState> {
  get valueType(): ValidationRuleType {
    return 'string';
  }

  __createStore() {
    return new Store<IHyperlinkState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new HyperlinkExtendStore(this),
    });
  }

  initialState(initConfig: IHyperlinkConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  click() {
    if (this.store.state.loading) return;
    if (!this.display) return;
    this.store.dispatch('clickAfter', {});
  }

  format(value?: any, config: any = {}): string {
    if (value == null) return '';
    let _value = value;
    const {
      format2Date = config.format2Date,
      format2Number = config.format2Number,
    } = this.store.state;
    if (format2Number) {
      _value = NumberHelper.format(value, format2Number);
    } else if (format2Date) {
      _value = DateHelper.format(value, format2Date);
    }
    return _value;
  }
}

export default Hyperlink;
