import { applyMixins } from '@mjcloud/utils';
import { IDictionary } from '@mjcloud/types';
import DataExtendStoreBase from '../dataExtendStoreBase';
import ValueExtendStoreBase from '../valueExtendStoreBase';
import { ActionAfterEventArg, ActionBeforeEventArg } from '@mjcloud/redux';
import ValueDataInstanceBase from '@mjcloud/instance/dist/valueDataInstanceBase';
import {
  IValueState,
  ValueControlActionType,
  IValueControlUpdateValueParams,
} from '@mjcloud/instance/dist/valueInstanceBase';

abstract class ValueDataExtendStoreBase<
  TValue = string,
  TInstance extends ValueDataInstanceBase<TValue> = ValueDataInstanceBase<TValue>
> extends DataExtendStoreBase<TInstance> implements ValueExtendStoreBase<TValue, TInstance> {
  /**
   * 数据源加载完成是否需要通知页面，true为需要
   */
  protected abstract isNeedNotificationPage: boolean;

  handleUpdateValueBefore(e: ActionBeforeEventArg<IValueControlUpdateValueParams<string>>): void {
    throw new Error('Method not implemented.');
  }
  handleUpdateValueAfter(
    e: ActionAfterEventArg<
      IValueState<string, IDictionary<any>>,
      ValueControlActionType,
      IValueControlUpdateValueParams<string>
    >,
  ): void {
    throw new Error('Method not implemented.');
  }
  triggerValueChange(eventType: string, params: any): void {
    throw new Error('Method not implemented.');
  }
}
applyMixins(ValueDataExtendStoreBase, ValueExtendStoreBase);
export default ValueDataExtendStoreBase;
