import Reduce from './reduce';
import Store from '@mjcloud/redux';
import { getInstance } from '../util';
import ControllerHelper from '@mjcloud/controller';
import { ActionAfterEventArg } from '@mjcloud/redux';
import { IframeConfig } from '../iframe/typings';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { ContainerInstanceBase } from '@mjcloud/instance';
import { ContainerExtendStoreBase } from '@mjcloud/extend-store';
import {
  ITabsItem,
  ITabsState,
  ITabsConfig,
  TabsEventType,
  TabsActionType,
  IAddTabPane2IframeItem,
  ITabsInitialStateParams,
  ITabsUpdateActiveKeyParams,
  ITabsSetTabPane2DisplayItem,
  ITabsBatchAddTabPane2IframeParams,
  ITabsSetTabPane2DisplayParams,
} from './typings';

class TabsExtendStore extends ContainerExtendStoreBase<Tabs> {
  handleInitialStateBefore(
    e: ActionAfterEventArg<ITabsState, TabsActionType, ITabsInitialStateParams>,
  ) {
    const pageMode = this.instance.page.pageMode,
      { initConfig } = e.params,
      address = this.instance.page.address;
    e.params.items = [];
    e.params.texts = [];
    if (initConfig) {
      if (initConfig.items && initConfig.items.length > 0) {
        let activeKey = initConfig.items[0].id || '',
          activeKeyIsDisplay = false;
        initConfig.items.map(item => {
          const { title = '', id, displayMode } = item;
          let display = true;
          if (item.code) {
            display = ControllerHelper.hasRights(address, item.code);
          }
          if (display) display = PageModeHelper.displayMode2boolean(pageMode, displayMode);
          if (display && activeKey == id) activeKeyIsDisplay = true;
          if (display && !activeKeyIsDisplay) {
            activeKey = id;
            activeKeyIsDisplay = true;
          }
          e.params.texts.push(title);
          const instance = this.instance.createControl(id);
          if (instance) {
            e.params.items.push({
              title,
              display,
              id: instance.id,
              parentId: instance.parent.id,
              pageKey: instance.page.id,
            });
          }
        });
        e.params.activeKey = activeKey;
      }
    }
  }

  handleUpdateActiveKeyAfter(e: ActionAfterEventArg<ITabsState, TabsActionType, {}>) {
    if (this.instance.eventManager.getHandlerCount('tabClick') > 0) {
      const { activeKey, items } = e.newState.state;
      let activeRow: ITabsItem | undefined;
      for (const row of items) {
        if (row.id === activeKey) {
          activeRow = row;
          break;
        }
      }
      this.instance.eventManager.trigger('tabClick', { activeKey, activeRow });
    }
  }
}

class Tabs extends ContainerInstanceBase<ITabsState, TabsEventType, TabsActionType> {
  __createStore() {
    return new Store<ITabsState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new TabsExtendStore(this),
    });
  }

  initialState(initConfig: ITabsConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  createControl(id: string) {
    // 创建复杂控件
    return this.page.createControl(id);
  }

  get activeKey() {
    return this.store.state.activeKey;
  }

  /**
   * 获取当前Tabs下所有TabPane的控件实例
   */
  getAllTabPaneInstance() {
    const { items } = this.store.state;
    return items.map(item => {
      const { id, parentId, pageKey } = item;
      const instance = getInstance(id, parentId, pageKey);
      return instance;
    });
  }

  /**
   * 设置tabPane的高度
   * @param height
   */
  setTabPaneHeight(height: string | number | undefined) {
    this.store.dispatch('updateTabPaneHeight', { height });
  }

  setTabPane2Display(tabPanes: ITabsSetTabPane2DisplayItem[]) {
    this.store.dispatch<ITabsSetTabPane2DisplayParams>('setTabPane2Display', {
      tabPanes,
    });
  }

  /**
   * 设置激活Tab
   * @param controlId 激活tab索引
   */
  setActiveKey(controlId: string) {
    this.store.dispatch<ITabsUpdateActiveKeyParams>('updateActiveKey', { activeKey: controlId });
  }

  /**
   * 往当前 tabs 添加一个 tabPane
   * @param tabPane
   */
  addTabPane2Iframe(tabPane: IAddTabPane2IframeItem) {
    this.batchAddTabPane2Iframe([tabPane]);
  }

  /**
   * 批量添加 tabPane
   * @param tabPanes
   */
  batchAddTabPane2Iframe(tabPanes: IAddTabPane2IframeItem[]) {
    const parentId = this.parent.id,
      pageKey = this.page.id;
    for (const { tabPaneId, config } of tabPanes) {
      this.page.__createControl<IframeConfig>(tabPaneId, {
        ...config,
        id: tabPaneId,
        nodeName: 'iframe',
      });
    }
    this.store.dispatch<ITabsBatchAddTabPane2IframeParams>('batchAddTabPane2Iframe', {
      tabPanes,
      parentId,
      pageKey,
    });
  }
}

export default Tabs;
