import Store from '@mjcloud/redux';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { IAutoCompleteState } from './typings';
import { ValueDataReduceBase } from '@mjcloud/reduce';
import { IDataControlLoadedParams } from '@mjcloud/instance/dist/dataInstanceBase';
import DataSource, { IComplexTableResult } from '@mjcloud/data-source-helper';
import { ISelectBaseDataSource } from '../common/select/typings';

export class AutoCompleteReduce extends ValueDataReduceBase<string, IAutoCompleteState> {
  initialState(store: Store<IAutoCompleteState>, params) {
    const { initConfig, pageMode } = params,
      { title, search, autoFocus, modifyMode } = initConfig,
      { displayMode, disabledMode, placeholder = `请输入${title || ''}` } = initConfig;
    let state: IAutoCompleteState = {
      title,
      search,
      value: '',
      autoFocus,
      placeholder,
      options: [],
      tabIndex: 0,
      rowIdCount: 1,
      dataSource: [],
      selectedRows: [],
      config: initConfig,
      configIsFetching: false,
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    return state;
  }

  loaded(store: Store<IAutoCompleteState>, params: IDataControlLoadedParams) {
    let state = super.loaded(store, params),
      { config, searchText, rowIdCount } = state;
    const { rows, count, pageIndex, pageSize } = params.dataSource as IComplexTableResult;
    const originalData = rows;
    const dataSource = DataSource.formatDataSource<ISelectBaseDataSource>(
      config.data,
      originalData,
      row => false,
      row => ({ _rid: ++rowIdCount }),
    );
    state.dataSource = dataSource;
    state.rowIdCount = rowIdCount;
    // if (searchText) {
    //   state.options = state.dataSource.map(row => ({ value: row._text, text: row._text }));
    // } else {
    //   state.options = [];
    //   state.dataSource = [];
    // }
    state.options = state.dataSource.map(row => ({ value: row._text, text: row._text }));
    return state;
  }

  updateValue(store: Store<IAutoCompleteState>, params) {
    const state = super.updateValue(store, params);
    state.text = state.value;
    // if (state.value) {
    //   for (const row of state.dataSource) {
    //     if (row._text === state.value) {
    //       params.selectedRows = [row];
    //       break;
    //     }
    //   }
    // } else {
    //   state.options = [];
    // }
    for (const row of state.dataSource) {
      if (row._text === state.value) {
        params.selectedRows = [row];
        break;
      }
    }
    return state;
  }

  updateOptions(store: Store<IAutoCompleteState>, params: { searchText: string }) {
    store.state.searchText = params.searchText;
    return store.state;
  }
}

export default new AutoCompleteReduce();
