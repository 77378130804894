import Store from '@mjcloud/redux';
import { IDictionary } from '@mjcloud/types';
import { DataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { DateHelper, NumberHelper } from '@mjcloud/utils';
import {
  ITimelineState,
  ITimelineColumn,
  ITimelineConfigType,
  handleColorCommandFn,
  ITimelineLoadedParams,
  ITimelineInitialStateParams,
} from './typings';

function formatTimelineItem(value: any, type: string, config: any) {
  if (value == null) return '-';
  switch (type) {
    case 'datetime':
      const { format: dateFormat } = config;
      return DateHelper.format(value, dateFormat);
    case 'number':
      const { format: numberFormat = '#,##0.00' } = config;
      if (typeof value === 'number' || typeof value === 'string') {
        value = NumberHelper.format(value, numberFormat);
        return value;
      }
      return value;
    case 'hyperlink':
    case 'label':
      const { format2Number, format2Date } = config;
      if (format2Number && typeof value === 'number') {
        value = NumberHelper.format(value, format2Number);
        return value;
      } else if (format2Date) {
        return DateHelper.format(value, format2Date);
      }
      return value;
    default:
      break;
  }
  return value;
}

function format2horizontal(
  row: IDictionary,
  horizontal: ITimelineConfigType,
  columns: ITimelineColumn[],
  handleColorCommand: handleColorCommandFn,
) {
  const { title = [], date = [], status = [] } = horizontal;
  for (const { id, field, control, colorCommand } of columns) {
    const color = handleColorCommand(colorCommand, row),
      type = control && control.nodeName,
      text = formatTimelineItem(row[field], type, control),
      _row: any = { text, color };
    if (row._title == null) {
      for (const _title of title) {
        if (id === _title.id) {
          row._title = _row;
        }
      }
    }

    if (row._date == null) {
      for (const _date of date) {
        if (id === _date.id) {
          row._date = _row;
        }
      }
    }

    if (row._status == null) {
      for (const _status of status) {
        if (id === _status.id) {
          row._status = _row;
          if (_status.backgroundColorCommand) {
            row._status.type = handleColorCommand(_status.backgroundColorCommand, row);
          }
        }
      }
    }
  }
}

class TimelineReduce extends DataReduceBase<ITimelineState> {
  initialState(store: Store<ITimelineState>, params: ITimelineInitialStateParams) {
    const { initConfig, pageMode } = params,
      { displayMode, rowClickController, horizontal } = initConfig,
      columns: ITimelineColumn[] = [];

    if (initConfig) {
      if (initConfig.items) {
        for (const item of initConfig.items.items) {
          if (item.nodeName === 'item') {
            const { id, index = 0, width, control } = item,
              { field = id, colorCommand } = item;
            columns.push({
              id,
              index,
              width,
              field,
              control,
              colorCommand,
            });
          }
        }
      }
    }

    const state: ITimelineState = {
      columns,
      horizontal,
      tabIndex: -1,
      dataSource: [],
      rowClickController,
      config: initConfig,
      clickLoading: false,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };

    return state;
  }

  loaded(store: Store<ITimelineState>, params: ITimelineLoadedParams) {
    const state: ITimelineState = { ...store.state, isFetching: false };
    const { handleColorCommand } = params;
    if (params.dataSource) {
      state.dataSource = params.dataSource.rows;
      state.dataSource = state.dataSource.map(row => {
        if (state.horizontal) {
          format2horizontal(row, state.horizontal, state.columns, handleColorCommand);
        }
        return row;
      });
      console.log('loaded', state.dataSource);
    }

    return state;
  }

  rowClickAfter(store: Store<ITimelineState>, params: {}) {
    store.state.clickLoading = true;
    return store.state;
  }

  clickDone(store: Store<ITimelineState>, params: {}) {
    if (!store.state.clickLoading) {
      return store.state;
    }
    store.state.clickLoading = false;
    return store.state;
    // return { ...store.state, loading: false };
  }
}

export default new TimelineReduce();
