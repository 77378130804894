import Store from '@mjcloud/redux';
import { ArrayHelper } from '@mjcloud/utils';
import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { Filter, FilterTypeEnum, OperationTypeEnum } from '@mjcloud/utils';
import {
  ISimpleSearchTab,
  ISimpleSearchItem,
  ISimpleSearchState,
  ISimpleSearchInitialStateParams,
} from './typings';

export class SimpleSearchReduce extends ReduceBase<ISimpleSearchState> {
  initialState(store: Store<ISimpleSearchState>, params: ISimpleSearchInitialStateParams) {
    const { initConfig, createControl, pageMode } = params;
    let tabs: ISimpleSearchTab[] = [],
      items: ISimpleSearchItem[] = [],
      { tip = '搜索', enable, displayMode, styleName } = initConfig;
    if (initConfig.items) {
      for (const item of initConfig.items) {
        items.push(item);
      }
    }
    if (initConfig.tabs) {
      // tabs.push({ title: '全部', id: 'all' });
      for (const item of initConfig.tabs) {
        const instance = createControl(item.id);
        if (instance) {
          item.controlId = instance.id;
          item.parentId = instance.parent.id;
          item.pageKey = instance.page.id;
        }
        tabs.push(item);
      }
    }
    const texts = tabs.map(tab => tab.title);
    let auto2width = 64,
      auto2itemwidth = 0;
    for (const text of texts) {
      auto2itemwidth = 64;
      auto2itemwidth += 28 * text.length;
      if (auto2itemwidth > 544) auto2itemwidth = 544;
      auto2width += auto2itemwidth;
    }
    const state: ISimpleSearchState = {
      tabs,
      texts,
      items,
      enable,
      one: true,
      tabIndex: -1,
      active: false,
      isSearch: false,
      theme: styleName,
      placeholder: tip,
      tabsActiveIndex: 0,
      config: initConfig,
      _nowValue: undefined,
      searchValue: undefined,
      configIsFetching: false,
      _filter: Filter.EqualFilter,
      widthType: auto2width > 750 ? 'auto' : 'full',
      _tabs: ArrayHelper.oneArray2twoArray(tabs, 3),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  updateOne(store: Store<ISimpleSearchState>, params) {
    if (params.one === store.state.one) return store.state;
    return { ...store.state, one: params.one };
  }

  onFocus(store: Store<ISimpleSearchState>, params: {}) {
    const state = this.updateOne(store, { one: false }),
      { enable, active } = state;
    if (enable) {
      if (active === true) return state;
      return { ...state, active: true };
    }
    return state;
  }

  onBlur(store: Store<ISimpleSearchState>, params: any) {
    params.value = undefined;
    const state = this.find(store, params),
      { enable, active } = state;
    if (enable) {
      if (active === false) return state;
      return { ...state, active: false, isSearch: false, searchValue: undefined };
    }
    return state;
  }

  updateValue(store: Store<ISimpleSearchState>, params) {
    if (store.state.searchValue === params.value) return store.state;
    return { ...store.state, searchValue: params.value };
  }

  updateActiveIndex(store, params) {
    return { ...store.state, tabsActiveIndex: params.activeIndex };
  }

  find(store: Store<ISimpleSearchState>, params) {
    let state = this.updateValue(store, params);
    if (!params.value) params.value = undefined;
    if (state._nowValue == params.value) {
      params.isEqual = true;
      return state;
    }
    state._nowValue = params.value;
    let filter: Filter | undefined;
    if (!!params.value) {
      state.isSearch = true;
      filter = new Filter(FilterTypeEnum.or);
      for (var item of state.items) {
        filter.addCondition({
          left: item.left,
          right: params.value,
          op: OperationTypeEnum.like,
          title: item.title,
        });
      }
    } else {
      state.isSearch = false;
      // TODO filter 在这里不应该通过 enable 进行判断是否相对
      filter = state.enable ? Filter.NotEqualFilter : Filter.EqualFilter;
    }
    state._filter = filter;
    return { ...state };
  }
}

export default new SimpleSearchReduce();
