declare global {
  /** 微信小程序下的全局api常量 */
  var wx: any;
  function addResizeListener(el: Element, resizeCallback: () => void): void;
  function removeResizeListener(el: Element, resizeCallback: () => void): void;
}

export declare type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export interface IDictionary<T = any> {
  [key: string]: T;
}
export interface IArrayDictionary<T = any> {
  [key: number]: T;
}

export interface IControlBaseProps {
  /**
   * 控件Id
   */
  controlId: string;
  /**
   * 当前控件的父级控件Id
   *
   * 若`parentId == pageKey`即父级控件为页面，反之则表示父级控件为容器控件
   */
  parentId: string;
  /**
   * 当前控件所在页面的pageKey
   */
  pageKey: string;
}

export interface Size {
  width: string | number | undefined;
  height: string | number | undefined;
}

export interface XmlNode {
  nodeName: string;
}

export interface XmlList<TItem extends XmlNode> {
  items: TItem[];
}

export interface XmlParam {
  dymaic: boolean;
  name: string;
  value: string;
}

export interface XmlParamList {
  items: XmlParam[];
}

/**
 * @description: 页面配置接口
 */
export interface PageConfig {
  draft?: DraftConfig;
  revise?: ReviseConfig;
  configParams?: XmlParamList;
  clientEntrance?: PageClientEntranceType;
  controls?: XmlList<ControlConfig>;
}

export interface ReviseConfig {
  rejectBtnId?: string;
  unlockBtnId?: string;
}
export interface DraftConfig {
  businessId: string;
  businessType: string;
  name: string;
}
/**
 * 客户端入口信息配置
 */
export interface PageClientEntranceType {
  /**
   * 主入口脚本地址
   */
  path?: string;
  isPrompt?: boolean;
  items?: ComponentConfig[];
}

export interface ComponentConfig extends XmlNode {
  id: string;
  value: string;
}

export interface LayoutServiceConfig {
  items?: XmlParam[];
}

export interface IControlSetConfig extends IDictionary {
  /** 控件有效显示模式，默认为 ControlDisplayModeEnum.all */
  displayMode?: ControlDisplayModeEnum;
}

export interface IControlConfig extends XmlNode, IDictionary {
  id: string;
  events?: IControlEvents;
}

export interface IControlEvents extends IDictionary<string> {}

export interface ControlConfig extends XmlNode {
  id: string;
  layout?: IDictionary;
  layoutService?: LayoutServiceConfig;
}

export interface IControllerConfig {
  id: string;
  /**
   * 权限点
   *
   * lg. 01,02 权限标识，新增模式通过M:01进行权限检验，M:02为修改模式
   *
   * lg. 01;02 标识或
   */
  code?: string;
  /**
   * 控制器获取参数后执行控制器前事件
   */
  onBeforeClick?: string;
  onAfterClick?: string;
  nodeName: string;
}

export interface IDataInstanceConfig {
  id: string;
  params: IDictionary;
}

export interface IDataSourceParams {
  id: string;
  dataMode: DataModeEnum;
  params: IDictionary;
}

interface IAnnounceInfo {
  id: string;
  title: string;
  outline: string;
}

export interface IUserInfoConfig {
  id: string;
  name: string;
  type: number;
  avatar: string;
  identities?: IIdentityConfig[];
  announce?: IAnnounceInfo;
}
export interface IIdentityConfig {
  code: string;
  name: string;
}

export interface IAppItem {
  name: string;
  icon: string;
  appId: string;
}

export interface IPlatformConfig {
  /** 当前环境是否开启调试 */
  debugger: boolean;
  version: string;
  /** 该应用服务端Api的`origin` */
  apiHost: string;
  appList: IAppItem[];
}
export interface IAppInfoConfig {
  modules: IAppMenuConfig[];
  /**
   * 用户权限列表
   * lg. [moduleCode]:00,01,02,03,
   * 控制器权限点写：
   *    00,01 或，表示权限列表中具备其一即可
   *    00;01;01 组，新增;查看;编辑，表示该控制器在不同的页面模式下所代表的权限点不一样
   */
  rights: IDictionary<string>;
}
/**
 * 菜单
 */
export interface IAppMenuConfig {
  id: string;
  /** 模块权限点 */
  code: string;
  /** 呈现到界面的菜单名称 */
  name: string;
  /** 模块Id */
  moduleId: string;
  children?: IAppMenuConfig[];

  // TODO: 模块列表：后端未实现功能
  /** 菜单的图标类型 */
  icon?: string;
  /** 页面Id, 默认为Main */
  pageId?: string;
  url?: string | null;
  /** 在何处打开链接文档,该值存在时`type`只能为`a` */
  target?: '_blank' | '_self' | '_parent' | '_top';
}

export interface IAppInfo {
  dashboard?: string[];
  /**
   * 菜单列表 | 也是全局路由
   * 子项可配置权限点、路由路径 | 名称
   */
  menus: IAppMenuConfig[];
  /**
   * 系统Id
   */
  appId: string;
}

export interface IAppCopyright {
  year: string;
  company: string;
  links?: {
    key?: string;
    icon?: string;
    title?: string;
    href: string;
    blankTarget?: boolean;
  }[];
}

export interface IAppSettingInfo {
  /** 应用标题 */
  title: string;
  /** 应用副标题标题 */
  desc: string;
  /** 系统logo, 默认是公司logo */
  logo: string;
  /** 应用ico,默认是公司ico */
  ico: string;
  copyright: IAppCopyright;
  layout: 'sidemenu' | 'topmenu';

  version: string;
  apiHost: string;
}

export interface IBuildFileParamsItem {
  pageControlMode: PageControlMode;
  data: IDictionary;
  config: IDictionary;
}

/**
 * 修订状态
 */
export enum InvoiceExecStatusEnum {
  /** 已锁定 */
  lock = 0,

  /** 已打回 */
  reject = 1,

  /** 已解锁 */
  unlock = 2,

  /** 调整非关键信息 */
  unkeyMsg = 6,

  /** 调整关键信息 */
  keyMsg = 7,

  /** 已作废 */
  invalid = 9,
}
/**
 * 页面模式枚举
 */
export enum PageModeEnum {
  /** 默认 */
  none = 0, // 0
  /** 新增 */
  add = 1, // 1
  /** 修改 */
  modify = 2, // 2
  /** 查看 */
  look = 4, // 4
}

/** 大控件类型 */
export enum PageControlMode {
  /** 表单控件 */
  form = 0,
  /** 搜索栏控件 */
  find = 1,
  /** 表格编辑控件 */
  gridEdit = 2,
  /** 表格视图控件 */
  gridView = 3,
  /** 树表格视图控件 */
  treeView = 4,
  /** 树表格编辑控件 */
  treeEdit = 5,
  /** 树列表控件 */
  treeList = 6,
  /** 列表控件 */
  gridList = 7,
  /** 工具栏控件 */
  toolbar = 8,
  /** 文档控件 */
  docs = 9,
}

/** 当前表单子项必填类别 */
export enum RequiredTypeEnum {
  /** 不必填 */
  normal = 'normal',
  /** 必填 */
  required = 'required',
  /** 建议填 */
  advise = 'advise',
}

/**
 * 页面状态枚举
 */
export enum PageStatusEnum {
  configLoading = 1,
  configLoaded,
  initing,
  inited,
  dataLoading,
  dataLoaded,
  done,
}

/**
 * 打开窗口模式枚举
 */
export enum OpenModeEnum {
  /** 在当前页面通过弹出对话框打开一个页面 */
  tier = 'modalTier',
  /** 在当前页面通过屏幕边缘滑出的浮层面板打开一个页面 */
  drawer = 'drawer',
  /** 在浏览器中新的标签页打开一个页面 */
  openWindow = 'openWindow',
  /** 替换当前页面 */
  self = 'self',
  /** 替换父级页面 */
  parent = 'parent',
  /** 替换顶级页面 */
  top = 'top',
}

/**
 * 数据源请求模式枚举
 */
export enum DataModeEnum {
  /** 全部 */
  all = 'all',
  /** 首条记录 */
  one = 'one',
  /** 分页 */
  pagination = 'page',
}

/**
 * 行数据源状态枚举
 */
export enum DataStateEnum {
  /**
   * 自动新增行
   */
  autoNew = 0,
  /**
   * 新增状态
   */
  added = 1,
  /**
   * 修改状态
   */
  changed = 2,
  /**
   * 删除状态
   */
  deleted = 4,
  /**
   * 未修改状态
   */
  unchanged = 8,
  /**
   * 已更改的所有类型数据
   */
  allchanged = 7,
  /**
   * 所有未删除数据
   */
  undeleted = 11,
  /**
   * 所有数据
   */
  all = 15,
  /**
   * 未新增的数据
   */
  unadded = 16,
}

/**
 * 控件有效显示模式
 */
export enum ControlDisplayModeEnum {
  /** 控件始终不可显示 */
  none = 0, // 0
  /** 页面新增模式可显示 */
  add = 1, // 1
  /** 页面修改模式可显示 */
  modify = 2, // 2
  /** 页面查看模式可显示 */
  look = 4, // 4
  /** 页面新增、修改模式可显示 */
  'add&modify' = 3, // 3
  /** 页面新增、查看模式可显示 */
  'add&look' = 5, // 5
  /** 页面修改、查看模式可显示 */
  'modify&look' = 6, // 6
  /** 控件始终可显示 */
  all = 7, // 7
}

/**
 * 控件有效可编辑模式
 */
export enum ControlModifyModeEnum {
  /** 控件始终不可编辑 */
  none = 0, // 0
  /** 页面新增模式可编辑 */
  add = 1, // 1
  /** 页面修改模式可编辑 */
  modify = 2, // 2
  /** 页面查看模式可编辑 */
  look = 4, // 4
  /** 页面新增、修改模式可编辑 */
  'add&modify' = 3, // 3
  /** 页面新增、查看模式可编辑 */
  'add&look' = 5, // 5
  /** 页面修改、查看模式可编辑 */
  'modify&look' = 6, // 6
  /** 控件始终可编辑 */
  all = 7, // 7
}

/**
 * 控件在什么模式下显示成摘要模式
 */
export enum ControlDigestDisplayModeEnum {
  /** 控件始终不显示成摘要模式 */
  none = 0, // 0
  /** 页面新增模式显示成摘要模式 */
  add = 1, // 1
  /** 页面修改模式显示成摘要模式 */
  modify = 2, // 2
  /** 页面查看模式显示成摘要模式 */
  look = 4, // 4
  /** 页面新增、修改模式显示成摘要模式 */
  'add&modify' = 3, // 3
  /** 页面新增、查看模式显示成摘要模式 */
  'add&look' = 5, // 5
  /** 页面修改、查看模式显示成摘要模式 */
  'modify&look' = 6, // 6
  /** 控件始终显示成摘要模式 */
  all = 7, // 7
}

/**
 * 控件禁用状态模式
 */
export enum ControlDisabledModeEnum {
  /** 控件始终不会禁用 */
  none = 0, // 0
  /** 页面新增模式下控件禁用 */
  add = 1, // 1
  /** 页面修改模式下控件禁用 */
  modify = 2, // 2
  /** 页面查看模式下控件禁用 */
  look = 4, // 4
  /** 页面新增、修改模式下控件禁用 */
  'add&modify' = 3, // 3
  /** 页面新增、查看模式下控件禁用 */
  'add&look' = 5, // 5
  /** 页面修改、查看模式下控件禁用 */
  'modify&look' = 6, // 6
  /** 控件始终禁用 */
  all = 7, // 7
}
