import BraftEditor from 'braft-editor';
import Store from '@mjcloud/redux';
import { ValueReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import {
  IRichTextboxConfig,
  IRichTextboxState,
  IRichTextboxInitialStateParams,
  IRichTextboxUpdateEditorStateParams,
} from './typings';

export class RichTextboxReduce extends ValueReduceBase<string, IRichTextboxState> {
  initialState(
    store: Store<IRichTextboxState>,
    params: IRichTextboxInitialStateParams,
  ): IRichTextboxState {
    const { initConfig, pageMode } = params,
      { disabledMode, displayMode, title, modifyMode } = initConfig,
      { placeholder = `请输入${title || ''}` } = initConfig;
    const state: IRichTextboxState = {
      title,
      value: '',
      placeholder,
      tabIndex: 0,
      config: initConfig,
      configIsFetching: false,
      // 创建一个空的editorState作为初始值
      editorState: BraftEditor.createEditorState(null),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    return state;
  }

  updateValue(store: Store<IRichTextboxState>, params) {
    const { value, text } = params;
    let state = store.state;
    state.value = value;
    state.text = text;
    return state;
  }

  updateEditorState(store: Store<IRichTextboxState>, params: IRichTextboxUpdateEditorStateParams) {
    let state = store.state;
    state.editorState = params.editorState;
    // 通过判断_actionSourceSign存在与否来判断更新源
    if (state.editorState._actionSourceSign) {
      return state;
    }
    state.editorState._actionSourceSign = true;
    return { ...state };
  }
}

export default new RichTextboxReduce();
