import Iframe from './iframe/controlDefaults';
import Modal from './modal/controlDefaults';
import Workbench from './workbench/controlDefaults';
import AutoComplete from './auto-complete/controlDefaults';
import Button from './button/controlDefaults';
import Buttons from './buttons/controlDefaults';
import Cascader from './cascader/controlDefaults';
import ComplexSearch from './complex-search/controlDefaults';
import Datetime from './datetime/controlDefaults';
import DateTimeRange from './datetime-range/controlDefaults';
import FileManager from './filemanager/controlDefaults';
import Form from './form/controlDefaults';
import Grid from './grid/controlDefaults';
import GridEdit from './gridedit/controlDefaults';
import GridView from './gridview/controlDefaults';
import Hyperlink from './hyperlink/controlDefaults';
import Label from './label/controlDefaults';
import Chart from './chart/controlDefaults';
import Number from './number/controlDefaults';
import Statistics from './statistics/controlDefaults';
import NumberRange from './number-range/controlDefaults';
// import Password from './password/controlDefaults';
import Radio from './radio/controlDefaults';
import RadioButtons from './radio-buttons/controlDefaults';
import RadioList from './radiolist/controlDefaults';
import RichTextbox from './rich-textbox/controlDefaults';
import Select from './select/controlDefaults';
import SelectTree from './select-tree/controlDefaults';
import Separate from './separate/controlDefaults';
import SimpleSearch from './simple-search/controlDefaults';
import Switch from './switch/controlDefaults';
import Tabs from './tabs/controlDefaults';
import TextArea from './textarea/controlDefaults';
import Textbox from './textbox/controlDefaults';
import Toolbar from './toolbar/controlDefaults';
import Timeline from './timeline/controlDefaults';
import TreeList from './treelist/controlDefaults';
import TreeView from './treeview/controlDefaults';
import TreeEdit from './treeedit/controlDefaults';

const controlTypes = {
  Iframe,
  Modal,
  AutoComplete,
  Workbench,
  Button,
  Buttons,
  Cascader,
  ComplexSearch,
  Datetime,
  DateTimeRange,
  FileManager,
  Form,
  Grid,
  Chart,
  GridEdit,
  GridView,
  Hyperlink,
  Label,
  Number,
  NumberRange,
  // Password,
  Radio,
  RadioButtons,
  RadioList,
  RichTextbox,
  Select,
  SelectTree,
  Separate,
  SimpleSearch,
  Switch,
  Tabs,
  TextArea,
  Textbox,
  Toolbar,
  Timeline,
  TreeList,
  TreeView,
  TreeEdit,
  Statistics,
  Search: SimpleSearch,
};

export default controlTypes;
