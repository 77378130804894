import Chart from '.';
import { style, setColors } from './util';
import { DataExtendStoreBase } from '@mjcloud/extend-store';

export default class ChartExtendStore extends DataExtendStoreBase<Chart> {
  protected isNeedNotificationPage = true;

  handleInitialStateBefore(e) {
    e.params.chartId = this.instance.id;
  }

  handleInitialStateAfter(e) {
    super.handleInitialStateAfter(e);
    const { styleName, themeName } = e.newState.state;
    const styleFn = style[styleName];
    if (styleFn && typeof styleFn === 'function') {
      styleFn();
    }
    if (themeName) {
      setColors(themeName.split(';'));
    }
  }

  handleLoadedAfter(e) {
    super.handleLoadedAfter(e);
    if (this.instance.chart) {
      //  chart重新渲染
      this.instance.__drawChart();
    }
  }
}
