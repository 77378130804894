import Store from '@mjcloud/redux';
import { applyMixins } from '@mjcloud/utils';
import DataReduceBase from '../dataReduceBase';
import ValueReduceBase from '../valueReduceBase';
import { IValueDataState } from '@mjcloud/instance/dist/valueDataInstanceBase';
import {
  IDataControlLoadedParams,
  IDataControlStartLoadParams,
  IDataControlLoadErrorParams,
} from '@mjcloud/instance/dist/dataInstanceBase';

abstract class ValueDataReduceBase<
  TValue = string,
  S extends IValueDataState<TValue> = IValueDataState<TValue>
> extends ValueReduceBase<TValue, S> implements DataReduceBase {
  public startLoad(store: Store<S>, params: IDataControlStartLoadParams): S {
    throw new Error('Method not implemented.');
  }
  public loaded(store: Store<S>, params: IDataControlLoadedParams): S {
    throw new Error('Method not implemented.');
  }
  public loadError(store: Store<S>, params: IDataControlLoadErrorParams): S {
    throw new Error('Method not implemented.');
  }
}

applyMixins(ValueDataReduceBase, DataReduceBase);
export default ValueDataReduceBase;
