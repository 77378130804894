import React from 'react';
import { Tabs } from 'antd-mobile';
import WorkbenchPart from '../part';
import { IControlBaseProps } from '@mjcloud/types';
import { IWorkbenchGroup } from '@mjcloud/workbench/dist/typings';

import styles from './index.less';

interface WorkbenchGroupProps extends IControlBaseProps {
  group: IWorkbenchGroup;
}
const tabBarTextStyle: React.CSSProperties = {
  padding: 0,
  display: 'block',
  overflow: 'hidden',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};
const WorkbenchGroup: React.FC<WorkbenchGroupProps> = ({ group, controlId, parentId, pageKey }) => {
  if (group) {
    const { items, page = 5 } = group,
      tabs = items.map(({ title }) => ({ title }));
    return (
      <Tabs
        tabs={tabs}
        swipeable={false}
        prerenderingSiblingsNumber={0}
        tabBarTextStyle={tabBarTextStyle}
        renderTabBar={props => <Tabs.DefaultTabBar {...props} page={page} />}
      >
        {items.map((item, j) => {
          return (
            <div key={j}>
              <div className={styles.dyWorkbenchGroupInterval}></div>
              {item.parts.map(({ id, name, size, width, height }, key) => {
                const partProps = { id, name, size, width, height, controlId, parentId, pageKey };
                return <WorkbenchPart {...partProps} key={key} />;
              })}
            </div>
          );
        })}
      </Tabs>
    );
  }
  return null;
};
export default WorkbenchGroup;
