import Iframe from '.';
import Page from '@mjcloud/page';
import Service from '@mjcloud/service';
import ControlBase from '../base';
import { IDictionary } from '@mjcloud/types';
import globalData from '@mjcloud/global-data';
import PageHelper from '@mjcloud/page-helper';
import { ActionAfterEventArg } from '@mjcloud/redux';
import { ExceptionHelper } from '@mjcloud/exception';
import { ExtendStoreBase } from '@mjcloud/extend-store';
import {
  IIframeState,
  IframeInitialStateParams,
  IframeActionType,
  IframeStartLoadParams,
  IframeLoadedParams,
  IframeLoadErrorParams,
  IframePushParams,
  IframeReplaceParams,
  IframeConfigLoadedParams,
  IframeBackParams,
} from './typings';

class IframeExtendStore extends ExtendStoreBase<Iframe> {
  public async handleConfigLoadedAfter(
    e: ActionAfterEventArg<IIframeState, IframeActionType, IframeConfigLoadedParams>,
  ) {
    let params: IDictionary = {};
    if (e.params.initConfig.pageInfo) {
      const { params: _params } = e.params.initConfig.pageInfo;
      if (_params) {
        for (const param of _params) {
          params[param.name] = param.value;
        }
        params = await PageHelper.getDynamicParamValues(this.instance.page, params, {});
      }
    }
    e.newState.dispatch<IframeInitialStateParams>('initialState', {
      ...e.params,
      params,
      pageMode: this.instance.page.pageMode,
    });
  }

  public handleInitialStateAfter(
    e: ActionAfterEventArg<IIframeState, IframeActionType, IframeInitialStateParams>,
  ) {
    super.handleInitialStateAfter(e);
    e.newState.dispatch<IframeStartLoadParams>('startLoad', {});
  }

  public async handleStartLoadAfter(
    e: ActionAfterEventArg<IIframeState, IframeActionType, IframeStartLoadParams>,
  ) {
    try {
      const info = this.instance.store.state.info;
      const Template = await Service.fetchTemplatePage<typeof ControlBase>(info.address);
      const pageKey = globalData.rbCore.nextPageKey();
      // TODO： 缺失针对_moduleCode逐层传递的逻辑
      const page = new Page(pageKey, this.instance.id, this.instance.page, info);
      globalData.rbCore.registerPage(pageKey, page);
      e.newState.dispatch<IframeLoadedParams>('loaded', {
        Template,
        pageKey,
        refreshType: e.params.refreshType,
        getTemplate: globalData.rbCore.getPage.bind(globalData.rbCore),
        destroyTemplate: globalData.rbCore.destroyPage.bind(globalData.rbCore),
      });
    } catch (error) {
      e.newState.dispatch<IframeLoadErrorParams>('loadError', {
        errorMessage: '加载页面错误',
      });

      if (!ExceptionHelper.isException(error)) {
        error.message = `Iframe-${this.instance.id}加载页面错误!`;
      }
      ExceptionHelper.dispose(error);
    }
  }

  public handleLoadedAfter() {
    this.instance.eventManager.trigger('ready', {});
  }

  public handleLoadErrorAfter() {
    this.instance.eventManager.trigger('ready', {});
  }

  public handleBackAfter(e: ActionAfterEventArg<IIframeState, IframeActionType, IframeBackParams>) {
    const { pageKey } = e.newState.state,
      { param } = e.params;
    if (pageKey) {
      const page = globalData.rbCore.getPage(pageKey);
      page.eventManager.trigger('back', param);
    }
    this.instance.eventManager.trigger('back', param);
  }

  public handleReplaceAfter(
    e: ActionAfterEventArg<IIframeState, IframeActionType, IframeReplaceParams>,
  ) {
    e.newState.dispatch<IframeStartLoadParams>('startLoad', {
      refreshType: 'replace',
    });
  }

  public handlePushAfter(e: ActionAfterEventArg<IIframeState, IframeActionType, IframePushParams>) {
    e.newState.dispatch<IframeStartLoadParams>('startLoad', {
      refreshType: 'push',
    });
  }
}

export default IframeExtendStore;
