import TreeEdit from '..';
import React from 'react';
import Icon from '@mjcloud/icon';
import { Size } from '@mjcloud/types';
import { WhiteSpace } from '@mjcloud/ui';
import classNames from 'classnames';
import ControlBase from '../../base';
import { findDOMNode } from 'react-dom';
import globalData from '@mjcloud/global-data';
import { IViewModelRow } from '@mjcloud/data-model';
import { Button, Popconfirm, Tooltip } from 'antd';
import ResizeObserver from 'resize-observer-polyfill';
import Table from '../../../components/VirtualizedTable';
import { PARENTMINHEIGHT, SCROLLMINHEIGHT, SPACING } from '../../constant';
import OperationContentControl from '../../common/table/operationContent/pc';
import EditableComplexCellControl from '../treeedit-complex-cell/pc';
import EditableCellControl from '../treeedit-cell/pc';
import {
  ITreeEditState,
  ITreeEditUpdateExpandedRowKeysParams,
  ITreeEditUpdateSelectedRowsParams,
} from '../typings';
import {
  ITableComponents,
  ITableLocale,
  TableRowSelection,
  SelectionSelectFn,
} from '../../../components/VirtualizedTable/interface';

import styles from './index.less';

export class TableCell extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  updateSize(size: Size) {
    this.setState({ size });
  }

  render() {
    const { record, children, style = {}, isVirtualizedCell, ...restProps } = this.props,
      { size } = this.state;
    let height = style.height;
    if (size && size.height) {
      height = size.height;
    }
    return (
      <div style={{ ...style, height }} {...restProps}>
        {children}
      </div>
    );
  }
}

class TreeEditControl extends ControlBase<ITreeEditState, TreeEdit> {
  private container: Element | null = null;
  private resizeObserver: ResizeObserver | null = null;

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.resizeObserver && this.container) {
      this.resizeObserver.unobserve(this.container);
      this.resizeObserver.disconnect();
      this.container = null;
    }
  }

  private onResize = () => {
    if (this.container) {
      const parentElementHeight = this.container.clientHeight,
        scrollHeight =
          parentElementHeight > PARENTMINHEIGHT ? parentElementHeight - SPACING : SCROLLMINHEIGHT;
      this.instance.store.dispatch('updateScrollHeight', { scrollHeight });
    }
  };

  private readyContainer = (instance: any | null) => {
    const container = findDOMNode(instance);
    if (container instanceof Element && container.parentElement) {
      const parentElementHeight = container.parentElement.clientHeight;
      if (parentElementHeight > PARENTMINHEIGHT) {
        this.container = container.parentElement;
        this.resizeObserver = new ResizeObserver(this.onResize);
        this.resizeObserver.observe(container.parentElement);
      }
    }
  };

  private TableCell: React.FC<any> = props => {
    const { rowId, cellId, record, cellType, store, children, ...restProps } = props;

    if (cellId === '_operation') {
      return <OperationContentControl {...props} />;
    } else if (cellType) {
      if (cellType === 'simple') {
        return <EditableCellControl {...props} />;
      } else {
        return <EditableComplexCellControl {...props} />;
      }
    } else {
      const cellRef = (container: TableCell | null) => {
        if (container && record && record._rid && cellId) {
          this.instance.__registerTableCell2Pure(cellId, record._rid, container);
        }
      };
      return (
        <TableCell ref={cellRef} record={record} {...restProps}>
          {children}
        </TableCell>
      );
    }
  };

  private handleAddClick = event => {
    if (event) event.preventDefault();
    if (this.state.addRowButton.loading) return;
    this.instance.store.dispatch('addClickAfter', {});
  };

  private handleSelectChange: SelectionSelectFn<IViewModelRow> = (
    record,
    selected,
    selectedRowKeys,
    halfSelectedKeys,
  ) => {
    this.instance.store.dispatch<ITreeEditUpdateSelectedRowsParams>('updateSelectedRows', {
      row: record,
      checked: selected,
      selectedRowKeys,
      halfSelectedKeys,
    });
  };

  private handleExpandedRowsChange = (
    expandedRowKeys: string[] | number[],
    expandLevel: number,
  ) => {
    this.instance.store.dispatch<ITreeEditUpdateExpandedRowKeysParams>('updateExpandedRowKeys', {
      expandLevel,
      expandedRowKeys,
    });
  };

  private renderButton = (onClick?: (e: any) => void) => {
    const { id, size, tip, icon, theme, title, disabled, loading } = this.state.addRowButton;
    const iconType = loading ? 'loading' : icon;
    const iconNode = iconType ? <Icon type={iconType} /> : null;
    const classes = classNames({
      ['ant-btn-loading']: theme === 'anchor' ? false : !!loading,
      [styles.loading]: !!loading,
      [styles[theme]]: true,
    });
    const _button = (
      <Button
        block
        key={id}
        size={size}
        type="dashed"
        onClick={onClick}
        disabled={disabled}
        className={classes}
      >
        {iconNode}
        {title}
      </Button>
    );
    if (tip) {
      return <Tooltip title={tip}>{_button}</Tooltip>;
    }
    return _button;
  };

  renderAddButton() {
    const { confirm } = this.state.addRowButton;
    if (!confirm) {
      return this.renderButton(this.handleAddClick);
    }
    return (
      <Popconfirm
        title={confirm.title}
        onConfirm={this.handleAddClick}
        okText="确定"
        cancelText="取消"
      >
        {this.renderButton()}
      </Popconfirm>
    );
  }

  renderContent() {
    const {
        modify,
        columns,
        _dataSource,
        isFetching,
        showSummary,
        scrollHeight,
        errorMessage,
        addRowButton,
        rowSelection,
        selectedRows = [],
        halfSelectedRows = [],
        summaryDataSource = [],
        configErrorMessage,
        expandLevel,
        expandedRowKeys,
        expandLevel2Keys,
      } = this.state,
      isShowAddRowButton = modify && addRowButton.display,
      { formatMessage } = globalData,
      locale: ITableLocale = {
        emptyText: errorMessage ? errorMessage : formatMessage({ id: 'table.locale.emptyText' }),
      };
    if (configErrorMessage) return <>{configErrorMessage}</>;

    const components: ITableComponents = {
      body: {
        cell: this.TableCell,
      },
    };
    const _rowSelection: TableRowSelection<IViewModelRow> | undefined = rowSelection
      ? {
          ...rowSelection,
          strictly: true,
          halfSelectedKeys: halfSelectedRows.map(row => row._rid),
          selectedRowKeys: selectedRows.map(row => row._rid),
          onSelect: this.handleSelectChange,
        }
      : undefined;
    return (
      <>
        <Table<IViewModelRow>
          ref={this.readyContainer}
          bordered
          rowHeight={45}
          locale={locale}
          columns={columns}
          loading={isFetching}
          components={components}
          dataSource={_dataSource}
          pagination={false}
          expandLevel={expandLevel}
          expandedRowKeys={expandedRowKeys}
          expandLevel2Keys={expandLevel2Keys}
          showSummary={showSummary}
          rowSelection={_rowSelection}
          summaryDataSource={summaryDataSource}
          // onExpand={this.handleExpand}
          onExpandedRowsChange={this.handleExpandedRowsChange}
          scroll={{ x: 'max-content', y: scrollHeight }}
          childrenColumnName="_children"
          rowKey="_rid"
          size="small"
        />
        {isShowAddRowButton ? (
          <>
            <WhiteSpace />
            {this.renderAddButton()}
            <WhiteSpace />
          </>
        ) : null}
      </>
    );
  }
}

export default TreeEditControl;
