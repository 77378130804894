import SystemPage from '..';
import React from 'react';
import ControlBase from '../../base';
import zhCN from 'antd/es/locale/zh_CN';
import { ISystemPageState } from '../typings';
import { Drawer, Modal as AntdModal, ConfigProvider } from 'antd';
import WPSFilePage from '../pages/wps-file';

import styles from './index.less';
import ModalHelper from '@mjcloud/modal-helper';

class SystemPageControl extends ControlBase<ISystemPageState, SystemPage> {
  private resizeTimeout: any = null;

  UNSAFE_componentWillMount() {
    window.addEventListener(
      'orientationchange' in window ? 'orientationchange' : 'resize',
      this.resizeThrottler,
      false,
    );
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener(
      'orientationchange' in window ? 'orientationchange' : 'resize',
      this.resizeThrottler,
      false,
    );
  }

  resizeThrottler = () => {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this.actualResizeHandler();
        // The actualResizeHandler will execute at a rate of 15fps
      }, 66);
    }
  };

  actualResizeHandler = () => {
    this.instance.store.dispatch('updateStyles', {});
  };

  private onCancel = () => {
    ModalHelper.closeModal(this.state.id, { oper: 'cancel' });
  };

  private onClose = () => {
    this.onCancel()
    setTimeout(() => {
      if (this.state.afterClose) this.state.afterClose()
    }, 0);
  }

  renderContent() {
    const { id, url, top, title, visible, width, height, isDrawer, afterClose } = this.state;
    if (isDrawer) {
      return (
        <Drawer
          title={title}
          width={width}
          placement="right"
          style={{ top }}
          visible={visible}
          className={styles.tier}
          onClose={this.onClose}
          destroyOnClose
        >
          <ConfigProvider locale={zhCN}>
            <div id={id} className={styles.iframe} style={{ height }}>
              <WPSFilePage url={url} />
            </div>
          </ConfigProvider>
        </Drawer>
      );
    }
    return (
      <AntdModal
        title={title}
        width={width}
        style={{ top }}
        visible={visible}
        className={styles.tier}
        onCancel={this.onCancel}
        afterClose={afterClose}
        maskClosable={false}
        keyboard={false}
        destroyOnClose
        footer={null}
      >
        <ConfigProvider locale={zhCN}>
          <div id={id} className={styles.iframe} style={{ height }}>
            <WPSFilePage url={url} />
          </div>
        </ConfigProvider>
      </AntdModal>
    );
  }
}

export default SystemPageControl;
