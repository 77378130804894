import JsApiHelper from '@mjcloud/jsapi';
import { IDictionary } from '@mjcloud/types';

interface IRequestJsOptions {
  headers?: IDictionary<string>;
  timeout?: number;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param url       The URL we want to request
 * @param method
 * @param data
 * @param [options] The options we want to pass to "fetch"
 * @param source
 * @return           An object containing either "data" or "err"
 */
export default async function requestJs<T>(
  url: string,
  method: 'GET' | 'POST' = 'GET',
  data: IDictionary = {},
  options: IRequestJsOptions = {},
  source: IDictionary = {},
) {
  let {
      headers = { 'Content-Type': 'application/x-www-form-urlencoded' },
      // timeout = 30000,
      // TODO: 临时加长超时时间
      timeout = 60000,
    } = options,
    // isStatic = url.search(/[a-zA-z]+:\/\/[^\s]*(\.json|\.js)$/) === 0,
    // method = isStatic ? 'GET' : 'POST',
    _data = { data: JSON.stringify(data) };
  headers = { ...headers, 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' };
  return JsApiHelper.request<T>({ url, method, data: _data, headers, timeout }, source);
}
