interface IDictionary<T = any> {
  [key: string]: T;
}

export type EventFn<D = any, S = any> = (e: IEventArg<S, D>) => void;

export interface IEventArg<S = any, D = any> {
  sender: S;
  eventType: string;
  eventSourceSign?: any;
  data: D;
  /**
   * 取消当前操作
   */
  cancel(): void;
  /**
   * 暂停当前操作，执行该方法会挂起当前操作
   */
  suspend(): void;
  /**
   * 继续当前操作
   */
  continue(): void;
}

export default class EventListening {
  private _lastEventArg: any;
  private _handlers: any[] = [];
  private _count = 0;

  constructor(private eventType: string) {}

  get count(): number {
    return this._count;
  }

  add(handler: EventFn) {
    this._handlers.push(handler);
    this._count++;

    if (this._lastEventArg) {
      handler(this._lastEventArg);
    }
  }

  insert(index: number, handler: EventFn) {
    this._handlers.splice(index, 0, handler);
    this._count++;

    if (this._lastEventArg) {
      handler(this._lastEventArg);
    }
  }


  remove(handler: EventFn) {
    let index = this._handlers.indexOf(handler);
    if (index >= 0) {
      this._handlers[index] = null;
      this._count--;
    }
  }

  /**
   * 获取要执行函数的e值，以保证使用的动态脚本参数e一致性
   * @param sender 发起该事件的实例
   * @param data 发起该事件需要的数据
   * @param extra 发起该事件需要的额外属性和方法
   * @param eventSourceSign
   * @param eventType
   */
  static getHandlerArg<S = any, D = any>(
    sender: S,
    data: D,
    extra: IDictionary = {},
    eventSourceSign?: any,
    eventType = 'customizeEventType',
  ): IEventArg<S, D> {
    const e: IEventArg<S, D> = {
      sender,
      eventSourceSign,
      data,
      eventType,
      cancel() {
        const ex = new Error('');
        ex.name = 'passException';
        throw ex;
      },
      suspend() {
        const ex = new Error('不支持当前操作！');
        ex.name = 'notSupportException';
        throw ex;
      },
      continue() {
        const ex = new Error('不支持当前操作！');
        ex.name = 'notSupportException';
        throw ex;
      },
      ...extra,
    };
    return e;
  }

  /**
   * 执行该事件
   * @param sender 发起该事件的实例
   * @param data 发起该事件需要的数据
   * @param extra 发起该事件需要的额外属性和方法
   * @param eventSourceSign
   * @param lastAutoTrigger
   */
  async trigger(
    sender: object,
    data: any,
    extra: IDictionary = {},
    eventSourceSign?: any,
    lastAutoTrigger?: boolean,
  ) {
    var e = EventListening.getHandlerArg(sender, data, extra, eventSourceSign, this.eventType),
      i = -1;
    this._lastEventArg = null;
    while (i++ < this._handlers.length) {
      let handler = this._handlers[i];
      if (handler) {
        await handler(e);
      }
    }
    if (lastAutoTrigger) {
      this._lastEventArg = e;
    }
  }
}
