import Store from '@mjcloud/redux';
import Reduce from './reduce';
import SelectorsExtendStore from './extendStore';
import { InstanceBase, ContainerInstanceBase } from '@mjcloud/instance';
import { Filter, FilterTypeEnum } from '@mjcloud/utils';

export default class Selectors extends ContainerInstanceBase {
  private _expressions: any = {};
  constructor(id: string, parent: ContainerInstanceBase, workbenchPart?: any) {
    super(id, parent, workbenchPart);
    this.page.eventManager.add('inited', this.pageInited.bind(this));
  }

  pageInited(e) {
    for (const key in this.controls) {
      const control = this.controls[key];
      if (control instanceof InstanceBase) {
        control.eventManager.add('onValueChange', e => {
          this._expressions[key] = {
            ...this._expressions[key],
            value: e.data.value,
          };
          const filter = new Filter(FilterTypeEnum.or);
          for (const _key in this._expressions) {
            const { value: right, op } = this._expressions[_key];
            filter.addCondition({
              op,
              right,
              left: _key,
            });
          }
          if (this.eventManager.getHandlerCount('find') > 0) {
            this.eventManager.trigger('find', { filter });
          }
        });
      }
    }
  }

  __createStore() {
    return new Store({
      id: this.id,
      reduce: Reduce,
      extendStore: new SelectorsExtendStore(this),
    });
  }

  createControl(id, op, config) {
    config.id = id;
    if (config.nodeName === 'select' || config.nodeName === 'datetime') {
      config.theme = 'normal';
      config.needDefaultValue = true;
    }
    this._expressions[id] = { op };
    return this.__createControl(config.id, config);
  }

  initialState(initConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}
