import DataSource from '@mjcloud/data-source-helper';
import { ValueDataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';

class SelectReduce extends ValueDataReduceBase {
  initialState(store, params) {
    const { initConfig, pageMode, isCustomizeMode } = params;
    const config = initConfig;
    const {
      alwaysLoad,
      textFieldName,
      displayMode,
      disabledMode,
      multiSelect,
      tokenSeparators,
      textTokenSeparator,
      vaueleTokenSeparator,
      ...restLayout
    } = config;
    const {
      title,
      theme = 'default',
      needDefaultValue = false,
      autoFocus,
      modifyMode,
    } = initConfig;
    let placeholder = restLayout.placeholder || `请选择${title || ''}`;
    let iconfont = 'icon-right';
    if (theme !== 'default' && !isCustomizeMode) {
      iconfont = 'icon-caret-down';
    }
    let state = {
      title,
      theme,
      text: '',
      iconfont,
      value: '',
      autoFocus,
      alwaysLoad,
      placeholder,
      tabIndex: -1,
      rowIdCount: 1,
      dataSource: [],
      isValid: false,
      isCustomizeMode,
      needDefaultValue,
      originalData: [],
      selectedRows: [],
      index: undefined,
      config: initConfig,
      // TODO: defaultOpen配置值待实现
      defaultOpen: false,
      configIsFetching: false,
      multiSelect: !!multiSelect,
      textFieldName: textFieldName || '',
      tokenSeparators: tokenSeparators || [',', '、'],
      textTokenSeparator: textTokenSeparator || '、',
      vaueleTokenSeparator: vaueleTokenSeparator || ',',
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    state.tokenSeparators = state.tokenSeparators
      .concat([state.textTokenSeparator, state.vaueleTokenSeparator])
      .filter((tokenSeparator, index, self) => self.indexOf(tokenSeparator) === index);
    return state;
  }

  loaded(store, params) {
    let { rowIdCount, value, text, index } = store.state;
    const dataSource = DataSource.formatDataSource(
      store.state.config.data,
      params.dataSource.rows,
      row => false,
      row => ({ _rid: ++rowIdCount }),
    );
    if (value) {
      // TODO: 修复list-edit中下拉框text值不对的问题，注意，该写法并没有完成
      dataSource.map((row, i) => {
        if (row.id === value) {
          index = i;
          text = row._text;
        }
      });
    }

    let state = {
      ...store.state,
      index,
      rowIdCount,
      dataSource,
      isFetching: false,
      originalData: dataSource,
    };
    return state;
  }

  updateValue(store, params) {
    let selectedRows: any[] = [],
      { value, index, text, rows } = params,
      state = super.updateValue(store, { ...params, noUpdate: true }) as any,
      { vaueleTokenSeparator, isCustomizeMode } = state,
      rowIds = params.rowIds;
    if (value == null) text = value;
    if (isCustomizeMode) {
      params.selectedRows = rows;
    } else {
      for (let i = 0; i < state.dataSource.length; i++) {
        const row = state.dataSource[i];
        if (row._value == value) {
          index = i;
          text = row._text;
        }
        if (rowIds) {
          for (const rowId of rowIds) {
            if (row._rid == rowId) {
              selectedRows.push(row);
            }
          }
        } else {
          if (typeof value === 'string') {
            for (const rowVal of value.split(vaueleTokenSeparator)) {
              if (row._value == rowVal) {
                selectedRows.push(row);
              }
            }
          }
        }
      }
      params.selectedRows = selectedRows;
    }
    return { ...state, selectedRows, index, text };
  }
}

export default new SelectReduce();
