import Store from "@mjcloud/redux";
import Reduce from "./reduce";
import { DataModeEnum } from "@mjcloud/types";
import CarouselExtendStore from "./extendStore";
import { DataInstanceBase } from "@mjcloud/instance";

export default class Carousel extends DataInstanceBase {

  __createStore() {
    return new Store({
      id: this.id,
      reduce: Reduce,
      extendStore: new CarouselExtendStore(this)
    });
  }

  getDataMode() {
    return DataModeEnum.all;
  }

  initialState(initConfig) {
    this.store.dispatch("configStartLoad", {
      initConfig
    });
  }

}
