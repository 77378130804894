import { ICascaderConfig } from './typings';

const config: ICascaderConfig = {
  title: '',
  autoFocus: false,
  alwaysLoad: false,
  textFieldName: 'text',
  multiSelect: false,
  isAutoSelection: false,
  textTokenSeparator: '、',
  vaueleTokenSeparator: ',',
  tokenSeparators: [',', '、'],
  modifyMode: undefined,
  placeholder: undefined,
  displayMode: undefined,
  disabledMode: undefined,
  disabledControl: undefined,
};

export default config;
