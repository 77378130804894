import Datetime from '.';
import { ValueExtendStoreBase } from '@mjcloud/extend-store';

export default class DatetimeExtendStore extends ValueExtendStoreBase<Date | null, Datetime> {
  handleInitialStateAfter(e) {
    super.handleInitialStateAfter(e);
    let { needDefaultValue, value, text } = e.newState.state;
    if (needDefaultValue) {
      value = new Date();
      e.newState.dispatch('updateValue', { value, text });
    }
  }
}
