import Accordion from '.';
import { Filter } from '@mjcloud/utils';
import { InstanceBase, DataInstanceBase, ContainerDataInstanceBase } from '@mjcloud/instance';
import { ActionBeforeEventArg } from '@mjcloud/redux';
import { ContainerDataExtendStoreBase } from '@mjcloud/extend-store';
import { IComplexItem, IAccordionInitialStateParams, IAccordionLoadedParams } from './typings';

class AccordionItemExtendStore {
  constructor(private instance: InstanceBase, private filter: Filter) {}
  handleInitialStateAfter(e) {
    if (
      this.instance instanceof DataInstanceBase ||
      this.instance instanceof ContainerDataInstanceBase
    ) {
      this.instance.refresh({ filter: this.filter });
    }
  }
}

export default class AccordionExtendStore extends ContainerDataExtendStoreBase<Accordion> {
  protected isNeedNotificationPage = true;

  handleInitialStateBefore(e: ActionBeforeEventArg<IAccordionInitialStateParams>) {
    const { initConfig } = e.params;
    let content: IComplexItem[] = [],
      collapse: IComplexItem[] = [];
    if (initConfig) {
      if (initConfig.content) content = initConfig.content.items;
      if (initConfig.collapse) collapse = initConfig.collapse.items;
    }
    e.params.content = content;
    e.params.collapse = collapse;
  }

  handleLoadedBefore(e: ActionBeforeEventArg<IAccordionLoadedParams>) {
    e.params.createControl = (id, filter) => {
      const instance = this.instance.createControl(id);
      if (instance) {
        instance.store.bindExtendStore(new AccordionItemExtendStore(instance, filter));
      }
      return instance;
    };
  }
}
