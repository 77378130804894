/*
 * @title: 国际化配置
 * @description: 中文
 * @author: smk17
 * @LastEditors: Do not edit
 * @Date: 2019-03-19 17:29:07
 * @LastEditTime: 2019-05-28 11:23:48
 */
import exception from './zh-CN/exception';
export default {
  'navBar.lang': '语言',
  'layout.user.link.help': '帮助',
  'layout.user.link.privacy': '隐私',
  'layout.user.link.terms': '条款',
  'global.loading': '加载中...',
  'global.data.loading': '数据加载中...',
  'global.add': '添加',

  'table.operation.title': '操作',
  'table.locale.emptyText': '暂无数据',
  ...exception,
};
