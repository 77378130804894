import Store from '@mjcloud/redux';
import Reduce from './reduce';
import { DataModeEnum } from '@mjcloud/types';
import StatisticsExtendStore from './extendStore';
import { ContainerDataInstanceBase } from '@mjcloud/instance';
import { ITimelineConfig, ITimelineState, TimelineActionType, TimelineEventType } from './typings';

export default class Timeline extends ContainerDataInstanceBase<
  ITimelineState,
  TimelineEventType,
  TimelineActionType
> {
  __createStore() {
    return new Store<ITimelineState>({
      id: this.id,
      reduce: Reduce,
      extendStore: new StatisticsExtendStore(this),
    });
  }

  getDataMode() {
    return DataModeEnum.all;
  }

  initialState(initConfig: ITimelineConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }
}
