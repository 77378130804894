import Reduce from './reduce';
import ToolBar from '../toolbar';
import { InstanceBase } from '@mjcloud/instance';
import { IControlBaseProps } from '../base';
import { ExtendStoreBase } from '@mjcloud/extend-store';
import Store, { ActionBeforeEventArg } from '@mjcloud/redux';
import { ButtonsConfig, IButtonsState, IButtonsInitialStateParams } from './typings';

class ButtonsExtendStore extends ExtendStoreBase<Buttons> {
  handleInitialStateBefore(e: ActionBeforeEventArg<IButtonsInitialStateParams>) {
    const ChildrenControls: IControlBaseProps[] = [];
    if (this.instance.parent instanceof ToolBar) {
      const { buttons } = e.params.initConfig;
      for (const button of buttons) {
        button['theme'] = 'anchor';
        const instance = this.instance.parent.createControl(button);
        if (instance) {
          ChildrenControls.push({
            id: instance.id,
            parentId: this.instance.parent.id,
            pageKey: this.instance.page.id,
          });
        }
      }
    }
    e.params.ChildrenControls = ChildrenControls;
  }
}

class Buttons extends InstanceBase<IButtonsState> {
  __createStore() {
    return new Store<IButtonsState>({
      reduce: Reduce,
      id: this.id,
      extendStore: new ButtonsExtendStore(this),
    });
  }

  initialState(initConfig: ButtonsConfig) {
    this.store.dispatch('configStartLoad', {
      initConfig,
    });
  }

  get disable() {
    return this.store.state.disabled;
  }

  set disable(disabled: boolean) {
    this.store.dispatch('updateDisabled', { disabled });
  }
}

export default Buttons;
