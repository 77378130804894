import Store from "@mjcloud/redux";
import Reduce from "./reduce";
import FilterbarExtendStore from "./extendStore";
import { ContainerInstanceBase } from "@mjcloud/instance";

export default class Filterbar extends ContainerInstanceBase {
  __createStore() {
    return new Store({
      id: this.id,
      reduce: Reduce,
      extendStore: new FilterbarExtendStore(this)
    });
  }

  createControl(config, index) {
    if (config.nodeName === "select" || config.nodeName === "datetime") {
      if (index === 0) {
        config.theme = "filterLeft";
      } else {
        config.theme = "filterRight";
      }
    }
    return this.__createControl(config.id, config);
  }

  initialState(initConfig) {
    this.store.dispatch("configStartLoad", {
      initConfig
    });
  }
}
