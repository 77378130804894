import React from 'react';
import { Tabs } from 'antd-mobile';
import classNames from 'classnames';
import ControlBase from '../../core/base';
import { IRadioButtonsState } from '@mjcloud/mcontrols/dist/controls/radio-buttons/typings';

import styles from './index.less';
const tabBarTextStyle: React.CSSProperties = {
  padding: 0,
  display: 'block',
  overflow: 'hidden',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};
class RadioButtonsControl extends ControlBase<IRadioButtonsState> {
  private handleChange = (activeIndex: number) => {
    this.instance.store.dispatch('updateActiveIndex', { activeIndex });
  };
  private handleTabsChange = (_: any, activeIndex: number) => {
    this.instance.store.dispatch('updateActiveIndex', { activeIndex });
  };

  renderContent() {
    if (this.state.readonly) return <>{this.state.text || ''}</>;
    if (this.state.errorMessage) return <>{this.state.errorMessage}</>;
    const { tabs, dataSource, activeIndex, theme = 'default' } = this.state;
    if (tabs) {
      return (
        <Tabs
          swipeable={false}
          page={activeIndex}
          prerenderingSiblingsNumber={0}
          onChange={this.handleTabsChange}
          tabBarTextStyle={tabBarTextStyle}
          tabs={dataSource.map(row => ({ title: row._text }))}
          renderTabBar={props => <Tabs.DefaultTabBar {...props} page={tabs.page} />}
        />
      );
    }
    return (
      <div className={styles.radioContainer}>
        <div
          className={classNames({
            [styles.radioButtons]: true,
            [styles.radioDefaultTheme]: theme === 'default',
            [styles.radioText]: theme === 'text',
          })}
        >
          {dataSource.map((row, index) => {
            const itemClassName = classNames({
              [styles.radioButtonsItem]: true,
              [styles.radioActive]: index === activeIndex,
            });
            return (
              <div
                key={row._value}
                className={itemClassName}
                onClick={() => this.handleChange(index)}
              >
                {row._text}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default RadioButtonsControl;
