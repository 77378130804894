import Store from '@mjcloud/redux';
import { DataReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { ArrayHelper, NumberHelper, DateHelper } from '@mjcloud/utils';
import {
  IStatisticsInfo,
  IStatisticsItem,
  IStatisticsState,
  IStatisticsColumn,
  IStatisticsBriefItem,
  IStatisticsInfoBriefItem,
  IStatisticsInitialStateParams,
  IStatisticsLoadedParams,
  handleColorCommandFn,
} from './typings';

const colors = ['#7a93e9', '#ecad6b', '#90c186', '#a498ee'],
  length = colors.length;

function formatStatisticsItem(value: string, type: string, config: any) {
  const item: IStatisticsBriefItem = { style: {}, styleText: '', text: value };
  switch (type) {
    case 'datetime':
      const { format: dateFormat } = config;
      item.text = DateHelper.format(value, dateFormat);
      break;
    case 'number':
      const { format: numberFormat = '#,##0.00' } = config;
      if (typeof value === 'number' || typeof value === 'string') {
        value = NumberHelper.format(value, numberFormat);
        item.text = value;
      }
      item.text = value;
      break;
    case 'label':
      const { fontColor, fontSize, fontWeight, format2Number, format2Date } = config;
      if (format2Number) {
        item.text = NumberHelper.format(value, format2Number);
      } else if (format2Date) {
        item.text = DateHelper.format(value, format2Date);
      }
      item.style = { color: fontColor, fontSize, fontWeight };
      break;

    default:
      break;
  }
  if (item.text == null || item.text === '') item.text = '-';
  return item;
}

function format2info(
  _item: IStatisticsItem,
  info: IStatisticsInfo,
  columns: IStatisticsColumn[],
  handleColorCommand: handleColorCommandFn,
) {
  let _i = 0;
  const { cols, title = [], tags = [] } = info,
    _infoBriefList: IStatisticsInfoBriefItem[] = [];
  _item._tags = [];
  _item._rTitle = [];
  _item._title = [];
  for (const {
    id: columnId,
    title: columnTitle,
    icon = 'question-circle',
    iconColor,
    control,
    colorCommand,
    field,
  } of columns) {
    const type = control && control.nodeName;
    let isConfig = false,
      color = colorCommand && handleColorCommand(colorCommand, _item),
      _row: IStatisticsInfoBriefItem = {
        icon,
        iconColor,
        id: columnId,
        title: columnTitle,
        ...formatStatisticsItem(_item[field], type, control),
      };
    _row.style = { ..._row.style, color };
    for (const _title of title) {
      if (columnId === _title.id) {
        isConfig = true;
        if (_title.position === 'right') {
          _item._rTitle.push(_row);
        } else {
          _item._title.push(_row);
        }
      }
    }
    for (const _tags of tags) {
      if (columnId === _tags.id) {
        isConfig = true;
        _item._tags.push(_row);
      }
    }
    if (!isConfig) _infoBriefList.push(_row);
  }
  _item.__infoBriefList = _infoBriefList;
  _item._infoBriefList = ArrayHelper.oneArray2twoArray(
    _infoBriefList.map((brief, index) => {
      if (!brief.iconColor) {
        if (index >= length) {
          _i = index - Math.floor(index / length) * length;
        } else {
          _i = index;
        }
        brief.iconColor = colors[_i];
      }
      return brief;
    }),
    cols,
  );
  return _item;
}

export class StatisticsReduce extends DataReduceBase<IStatisticsState> {
  initialState(store: Store<IStatisticsState>, params: IStatisticsInitialStateParams) {
    const { initConfig, pageMode } = params,
      { size, displayMode, info, quota, cols, showLine, showRows } = initConfig;
    let columns: IStatisticsColumn[] = [];
    if (initConfig.items.items) {
      for (const item of initConfig.items.items) {
        const { id, title, control, colorCommand } = item,
          { field = id, icon, iconColor } = item;
        if (field)
          columns.push({
            id,
            icon,
            title,
            field,
            control,
            iconColor,
            colorCommand,
          });
      }
    }
    const state: IStatisticsState = {
      cols,
      info,
      size,
      columns,
      showLine,
      showRows,
      tabIndex: -1,
      collapse: true,
      dataSource: [],
      _dataSource: [],
      config: initConfig,
      showCollapse: false,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
    return state;
  }

  loaded(store: Store<IStatisticsState>, params: IStatisticsLoadedParams) {
    let rowIdCount = 0,
      { handleColorCommand } = params,
      { columns = [], info, cols, showRows, collapse, showCollapse } = store.state,
      { rows } = params.dataSource,
      _dataSource: IStatisticsItem[][] = [];
    const dataSource = rows.map(item => {
      const _item: IStatisticsItem = {
        ...item,
        _briefList: [],
        _infoBriefList: [],
        __infoBriefList: [],
        _quotaBriefList: [],
        _quotaBriefListBak: [],
        _rid: ++rowIdCount,
      };
      if (info) {
        return format2info(_item, { ...info, cols }, columns, handleColorCommand);
      } else {
        return format2info(_item, { cols }, columns, handleColorCommand);
      }
    });

    if (info) {
      showCollapse = false;
    } else {
      if (showRows != null && collapse) {
        const showLength = cols * showRows;
        if (dataSource.length > showLength) {
          showCollapse = true;
          _dataSource = ArrayHelper.oneArray2twoArray(
            dataSource.filter((_, index) => index < showLength),
            cols,
          );
        } else {
          showCollapse = false;
          _dataSource = ArrayHelper.oneArray2twoArray(dataSource, cols);
        }
      } else {
        showCollapse = false;
        _dataSource = ArrayHelper.oneArray2twoArray(dataSource, cols);
      }
    }
    return {
      ...store.state,
      isFetching: false,
      collapse: true,
      showCollapse,
      dataSource,
      _dataSource,
    };
  }

  updateCollapse(store: Store<IStatisticsState>, params: { collapse: boolean }) {
    // if (params.collapse === store.state.collapse) return store.state;
    const state = { ...store.state, collapse: params.collapse },
      { cols, collapse, dataSource, showRows } = state;
    if (showRows != null) {
      const showLength = cols * showRows;
      if (dataSource.length > showLength) {
        state.showCollapse = true;
        state._dataSource = !collapse
          ? ArrayHelper.oneArray2twoArray(dataSource, cols)
          : ArrayHelper.oneArray2twoArray(
              dataSource.filter((_, index) => index < showLength),
              cols,
            );
      } else {
        state.showCollapse = false;
        state._dataSource = ArrayHelper.oneArray2twoArray(dataSource, cols);
      }
    } else {
      state.showCollapse = false;
      state._dataSource = ArrayHelper.oneArray2twoArray(dataSource, cols);
    }
    return state;
  }
}

export default new StatisticsReduce();
