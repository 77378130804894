import { ReduceBase } from '@mjcloud/reduce';
import PageModeHelper from '@mjcloud/page-mode-helper';

class SelectorsReduce extends ReduceBase {
  initialState(store, params) {
    let { initConfig, items, pageMode } = params,
      length = 0,
      { displayMode } = initConfig;
    if (items && items.length > 0) {
      length = items.length;
    }
    return {
      items,
      tabIndex: -1,
      config: initConfig,
      maxWidth: 750 / length,
      configIsFetching: false,
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
    };
  }
}

export default new SelectorsReduce();
