import React from 'react';
import { SPACING } from './constant';
import styled, { StyledComponent } from 'styled-components';

const Layout = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  margin: 0 ${SPACING}px;
  margin-bottom: ${SPACING * 3}px;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  max-width: 250px;
  flex: 1;
  > * {
    margin-right: ${SPACING}px;
  }
  > *:last-child {
    flex: 1;
    width: 0px !important;
    margin-right: 0px;
  }
`;

const Middle = styled.div`
  /* max-width: 450px; */
  margin-left: ${SPACING}px;
  flex: 2;
`;

const LeftMiddleLayout: StyledComponent<'div', any, {}, any> & {
  Left: StyledComponent<'div', any, {}, any>;
  Middle: StyledComponent<'div', any, {}, any>;
} = Layout as any;

LeftMiddleLayout.Left = Left;
LeftMiddleLayout.Middle = Middle;

export default LeftMiddleLayout;
