import Store from '@mjcloud/redux';
import { ExceptionHelper } from '@mjcloud/exception';
import { DataStateEnum, PageModeEnum, ControlModifyModeEnum, RequiredTypeEnum } from '@mjcloud/types';
import { IEditableCellBaseState, IEditableCellBaseInitialStateParams } from './typings';

function modifyMode2boolean(
  dataState: DataStateEnum = DataStateEnum.autoNew,
  modifyMode: ControlModifyModeEnum = ControlModifyModeEnum['add&modify'],
): boolean {
  // TODO: 根据行的dataState和配置的modifyMode判断单元格是否可修改
  // console.log(`dataState: ${dataState}; modifyMode: ${modifyMode}`);

  if (dataState === DataStateEnum.unchanged) {
    if (modifyMode === ControlModifyModeEnum['add&modify']) return true;
  }
  if (modifyMode === 0) return false;
  if (modifyMode === 7) return true;
  if (dataState === 0) return true;
  return (modifyMode & dataState) === dataState;
}

export default abstract class TreeEditCellBaseReduce {
  __initialState(params: IEditableCellBaseInitialStateParams) {
    const { initConfig, pageMode, dataState, rowId } = params;
    const { id, title, value, text, modifyMode, requiredType } = initConfig,
      { message, max, min, validationType, whitespace, len, enums, pattern } = initConfig,
      modify = pageMode === PageModeEnum.look ? false : modifyMode2boolean(dataState, modifyMode);
    let _enum: string | string[] | undefined, _pattern: RegExp | undefined;
    if (enums) {
      try {
        _enum = JSON.parse(enums.replace(/'/g, '"'));
      } catch (error) {
        const ex = ExceptionHelper.jsonParseException(
          `TreeEditCellReduce ${enums} 不是json字符串`,
          error.stack,
        );
        ExceptionHelper.dispose(ex);
      }
    }
    if (pattern) {
      try {
        _pattern = new RegExp(JSON.parse(pattern.replace(/'/g, '"')));
      } catch (error) {
        const ex = ExceptionHelper.jsonParseException(
          `TreeEditCellReduce ${pattern} 不是json字符串`,
          error.stack,
        );
        ExceptionHelper.dispose(ex);
      }
    }
    const state: IEditableCellBaseState = {
      max,
      min,
      len,
      text,
      rowId,
      value,
      modify,
      message,
      whitespace,
      enum: _enum,
      tabIndex: -1,
      display: true,
      help: undefined,
      pattern: _pattern,
      readonly: !modify,
      title: title || id,
      type: validationType,
      isAddReadonly: false,
      visibleTooltip: false,
      configIsFetching: false,
      validateStatus: undefined,
      requiredType: requiredType || RequiredTypeEnum.normal,
      config: initConfig,
    };
    return state;
  }

  updateColor(store: Store<IEditableCellBaseState>, params) {
    const { color } = params;
    if (store.state.color === color) return store.state;
    return { ...store.state, color };
  }

  updateSize(store: Store<IEditableCellBaseState>, params) {
    const { width, height } = params.size,
      { size } = store.state;
    if (size) {
      const { width: oldwidth, height: oldheight } = size;
      if (oldwidth === width && oldheight === height) return store.state;
    }
    if (params.noUpdate) {
      store.state.size = params.size;
      return store.state;
    }
    return { ...store.state, size: params.size };
  }

  updateValue(store: Store<IEditableCellBaseState>, params) {
    const { value, text } = params;
    return { ...store.state, value, text };
  }

  updateReadonly(store: Store<IEditableCellBaseState>, params) {
    const state = store.state,
      // TODO: 当前单元格初始化时是否可修改 && 更新其能不能修改
      modify = state.modify && !params.readonly,
      readonly = !modify,
      { isAddReadonly } = params;
    if (isAddReadonly != null) state.isAddReadonly = isAddReadonly;
    if (state.readonly === readonly) {
      return state;
    } else {
      state.readonly = readonly;
      return { ...state };
    }
  }

  updateRequiredType(
    store: Store<IEditableCellBaseState>,
    params: { requiredType: RequiredTypeEnum },
  ) {
    if (params.requiredType === store.state.requiredType) {
      return store.state;
    }
    const state: IEditableCellBaseState = { ...store.state, requiredType: params.requiredType };
    if (params.requiredType !== RequiredTypeEnum.required) {
      state.help = undefined;
      state.validateStatus = undefined;
    }
    return state;
  }

  validError(store: Store<IEditableCellBaseState>, params) {
    const { errorHelp, actionSourceSign } = params;
    const state: IEditableCellBaseState = {
      ...store.state,
      validateStatus: 'error',
      help: errorHelp,
      actionSourceSign,
    };
    return state;
  }

  validSuccess(store: Store<IEditableCellBaseState>, params: {}) {
    let state: IEditableCellBaseState = store.state;
    if (state.validateStatus === undefined) return state;
    state = { ...state, validateStatus: undefined, help: undefined, actionSourceSign: null };
    return state;
  }

  visibleTooltip(store: Store<IEditableCellBaseState>, params: { visibleTooltip: boolean }) {
    return { ...store.state, visibleTooltip: params.visibleTooltip };
  }
}
