import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from 'E:/workSpace/UIPlatform/src/pages/.umi-production/LocaleWrapper.jsx';

const Router = DefaultRouter;

const routes = [
  {
    path: '/',
    component: require('../select').default,
    title: '选择应用',
    exact: true,
    Routes: [require('./TitleWrapper.jsx').default],
    _title: '选择应用',
    _title_default: '加载中...',
  },
  {
    path: '/:appId',
    component: require('../app/_layout').default,
    routes: [
      {
        path: '/:appId',
        component: require('../app/index').default,
        title: '加载中...',
        exact: true,
        Routes: [require('./TitleWrapper.jsx').default],
        _title: '加载中...',
        _title_default: '加载中...',
      },
      {
        path: '/:appId/devlogin',
        component: require('../app/devlogin').default,
        title: '登录',
        exact: true,
        Routes: [require('./TitleWrapper.jsx').default],
        _title: '登录',
        _title_default: '加载中...',
      },
      {
        path: '/:appId/tmlogin',
        component: require('../app/tmlogin').default,
        title: '登录',
        exact: true,
        Routes: [require('./TitleWrapper.jsx').default],
        _title: '登录',
        _title_default: '加载中...',
      },
      {
        path: '/:appId/lslogin',
        component: require('../app/lslogin').default,
        title: '大屏登录',
        exact: true,
        Routes: [require('./TitleWrapper.jsx').default],
        _title: '大屏登录',
        _title_default: '加载中...',
      },
      {
        path: '/:appId/filelist',
        component: require('../app/filelist').default,
        title: '附件列表',
        exact: true,
        Routes: [require('./TitleWrapper.jsx').default],
        _title: '附件列表',
        _title_default: '加载中...',
      },
      {
        path: '/:appId/scanlogin',
        component: require('../app/scanlogin').default,
        title: '扫码登录',
        exact: true,
        Routes: [require('./TitleWrapper.jsx').default],
        _title: '扫码登录',
        _title_default: '加载中...',
      },
      {
        path: '/:appId/filedetail',
        component: require('../app/filedetail').default,
        title: '预览文件',
        exact: true,
        Routes: [require('./TitleWrapper.jsx').default],
        _title: '预览文件',
        _title_default: '加载中...',
      },
      {
        path: '/:appId/filedetail',
        component: require('../app/filedetail').default,
        title: '预览文件',
        exact: true,
        Routes: [require('./TitleWrapper.jsx').default],
        _title: '预览文件',
        _title_default: '加载中...',
      },
      {
        path: '/:appId/:moduleIds*',
        component: require('../app/index').default,
        title: '加载中...',
        exact: true,
        Routes: [require('./TitleWrapper.jsx').default],
        _title: '加载中...',
        _title_default: '加载中...',
      },
    ],
    _title: '加载中...',
    _title_default: '加载中...',
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
