import Form from '..';
import Store from '@mjcloud/redux';
import { ActionBeforeEventArg, ActionAfterEventArg } from '@mjcloud/redux';
import { ControlModifyModeEnum } from '@mjcloud/types';
import PageModeHelper from '@mjcloud/page-mode-helper';
import { FormSectionInitialStateParams, FormItemList, IFormSectionState } from './typings';

export default class FormSectionExtendStore {
  constructor(private id: string, private instance: Form) {}

  handleInitialStateBefore(e: ActionBeforeEventArg<FormSectionInitialStateParams>) {
    const { initConfig, pageMode } = e.params,
      { digestDisplayMode } = initConfig,
      digestDisplay = PageModeHelper.digestDisplayMode2boolean(pageMode, digestDisplayMode),
      items: FormItemList = [];

    let itemConfigList = initConfig.items || [];
    itemConfigList = itemConfigList.map((item, i) => {
      if (item.index == null) item.index = 10;
      item.oldIndex = i;
      return item;
    });
    itemConfigList = itemConfigList.sort((a, b) => {
      return a.index - b.index || a.oldIndex - b.oldIndex;
    });
    itemConfigList.map((itemConfig, i) => {
      if (itemConfig.nodeName === 'item' || itemConfig.nodeName === 'complexItem') {
        const { id } = itemConfig;
        if (id) {
          if (digestDisplay) itemConfig.modifyMode = ControlModifyModeEnum.none;
          const itemInstance = this.instance.__createFormItem(id, itemConfig);
          items.push(itemInstance.store);
        } else {
          console.error(`容器控件Form-${this.instance.id} 下的item节点中的 id 不存在`);
        }
      } else if (itemConfig.nodeName === 'blank') {
        if (itemConfig.id) {
          items.push({ id: itemConfig.id });
        } else {
          console.error(`容器控件Form-${this.instance.id} 下的blank节点中的 id 不存在`);
        }
      }
    });
    e.params.items = items;
  }

  handleUpdateReadonlyAfter(
    e: ActionAfterEventArg<IFormSectionState, string, { readonly: boolean }>,
  ) {
    const { items } = e.newState.state;
    for (const item of items) {
      if (item instanceof Store) {
        item.dispatch('updateReadonly', { readonly: e.params.readonly });
      }
    }
  }
}
