import Store, { IState } from '@mjcloud/redux';
import globalData from '@mjcloud/global-data';
// export { WorkbenchPart } from '@mjcloud/workbench';
import ValueInstanceBase from '../valueInstanceBase';
import { StringHelper, ConsoleHelper } from '@mjcloud/utils';
import { IDictionary, IBuildFileParamsItem, ControlConfig } from '@mjcloud/types';
import InstanceBase, { ControlActionType, ControlEventType } from '../base';

export type ContainerControlEventType = ControlEventType;
export type ContainerControlActionType = ControlActionType;

export interface IContainerState<TConfig = IDictionary> extends IState<TConfig> {
  activeTabControlId?: string;
  activeTabIndex?: number;
}

interface IControlInfo {
  control: InstanceBase;
  tabIndex: number;
}

export default abstract class ContainerInstanceBase<
  S extends IContainerState = IContainerState,
  TEventType extends string = string,
  TDispatchType extends string = string
> extends InstanceBase<S, TEventType, TDispatchType> {
  protected controls: IDictionary<InstanceBase | InstanceBase[]> = {};
  protected _controls: IDictionary<IControlInfo> = {};
  public __instanceInited = false;
  private controlInitCount = 0;
  protected controlLoadCount = 0;
  private controlCount = 0;
  protected dataControlCount = 0;
  /**
   *  生成导出文件所需的配置
   * @param title 工作区标题
   */
  buildExportFileConfig?(title: string): Promise<IBuildFileParamsItem | undefined>;

  public __controlInitStart(controlId: string) {
    this.controlInitCount++;
    // ConsoleHelper.log2reduce(this.id, '__controlInitStart', controlId, this.controlInitCount);
  }

  public __controlInitComplete(controlId: string) {
    this.controlInitCount--;
    // ConsoleHelper.log2reduce(this.id, '__controlInitComplete', controlId, this.controlInitCount);
    if (this.isControlInited() && this.__instanceInited) {
      this.parent.__controlInitComplete(this.id);
    }
  }

  public __controlLoadStart(controlId: string) {
    this.controlLoadCount++;
    ConsoleHelper.log2reduce(this.id, '__controlLoadStart', controlId, this.controlLoadCount);
  }

  public __controlLoadComplete(controlId: string) {
    this.controlLoadCount--;
    ConsoleHelper.log2reduce(this.id, '__controlLoadComplete', controlId, this.controlLoadCount);
    if (this.isControlLoad()) {
      this.parent.__controlLoadComplete(this.id);
    }
  }

  public isControlInited() {
    return this.controlInitCount === 0;
  }

  public isControlLoad() {
    return this.controlLoadCount === 0;
  }

  public findControl<T extends InstanceBase>(controlId: string): T {
    if (controlId) {
      let info = this._controls[controlId];
      if (info) return info.control as T;
    }
    return null as any;
  }

  public __createControl<TConfig extends ControlConfig>(
    controlId: string,
    config: TConfig,
    workbenchPart?: any,
  ) {
    if (!globalData.controlTypes) {
      console.error(`容器下不存在 controlTypes, 请在入口文件设置 controlTypes 值`);
      return null;
    }
    const nodeName = StringHelper.captureName(config.nodeName);
    const Control: typeof InstanceBase = globalData.controlTypes[nodeName];
    if (typeof Control != 'function') {
      console.error(`容器控件下的子控件 ${controlId} 中使用了平台不支持的控件类型: ${nodeName}`);
      return null;
    }
    let controlInstance: InstanceBase = new Control(controlId, this, workbenchPart);
    this.registerControl({ controlId, controlInstance });
    controlInstance.initialStore(config);
    return controlInstance;
  }

  private registerControl(params: {
    controlId: string;
    controlInstance: InstanceBase;
    tabIndex?: number;
  }) {
    this.controlCount++;
    const { controlId, controlInstance, tabIndex } = params;
    this.controls[controlId] = controlInstance;
    this._controls[controlId] = {
      control: controlInstance,
      tabIndex: tabIndex == null ? this.controlCount : tabIndex,
    };
    if (controlInstance['getDataMode']) {
      this.dataControlCount++;
    }
  }

  private updateTabIndex(type: 'next' | 'prev') {
    const store = this.store as Store<S, string>;
    const { activeTabIndex, activeTabControlId } = this.store.state;
    const controls = Object.getOwnPropertyNames(this._controls)
      .map(k => this._controls[k])
      .sort((a, b) => a.tabIndex - b.tabIndex);
    if (activeTabControlId == null) {
      for (const { control, tabIndex } of controls) {
        if (control instanceof ContainerInstanceBase) {
          if (control._nextTab()) {
            store.dispatch('updateTabIndex', {
              activeTabIndex: tabIndex,
              activeTabControlId: control.id,
            });
          }
        } else if (control instanceof ValueInstanceBase) {
          if (control.focus()) {
            store.dispatch('updateTabIndex', {
              activeTabIndex: tabIndex,
              activeTabControlId: control.id,
            });
            return true;
          }
        }
      }
    } else {
      const control = this.findControl(activeTabControlId);
      if (control instanceof ContainerInstanceBase) {
        if (control._nextTab()) {
          // store.dispatch('updateTabIndex', {
          //   activeTabIndex: tabIndex,
          //   activeTabControlId: control.id,
          // });
          return true;
        } else {
        }
      } else if (control instanceof ValueInstanceBase) {
        control.blur();
      }
    }
    return false;
  }

  public _nextTab() {
    return this.updateTabIndex('next');
  }

  public _prevTab() {
    return this.updateTabIndex('prev');
  }
}
