import React from 'react';
import { IDictionary } from '@mjcloud/types';
import Icon, { IconFontProps } from '@mjcloud/icon';

interface ITreeNodeIconProps extends IDictionary {
  row: IDictionary;
  iconFn?: (row: IDictionary) => Promise<string | undefined>;
}

class TreeNodeIcon extends React.Component<ITreeNodeIconProps, IconFontProps> {
  constructor(props: ITreeNodeIconProps) {
    super(props);
    const { row, iconFn, ...restProps } = props;
    this.state = { ...restProps, type: 'icon-blank' };
  }
  async componentDidMount() {
    const { row, iconFn } = this.props;
    if (iconFn) {
      const type = await iconFn(row);
      if (type) {
        this.setState({ type });
      }
    }
  }
  render() {
    const { ref, ...iconProps } = this.state;
    return <Icon {...iconProps} />;
  }
}

export default TreeNodeIcon;
