import { ReactNode, useRef, useEffect } from 'react';
import { utils } from '../util';
import createNode from '../util/createNode';
import G6, { Graph, GraphAnimateConfig, GraphOptions, LayoutConfig, ModelStyle, Modes, Padding, ShapeStyle, StateStyles } from '@antv/g6';
import { chnSubstr } from '../util/utils';
import { PageHelper } from '@mjcloud/core';
import { EventListening } from '@mjcloud/utils';
import { any } from 'prop-types';

// export interface Tooltip extends Omit<G2PlotTooltip, 'custom'> {
//   custom?: {
//     container?: ReactNode;
//     customContent?: (title: string, data: any[]) => ReactNode;
//     onChange?: (tooltipDom: HTMLElement, cfg: CustomTooltipConfig) => void;
//   };
// }

// 默认配置
const defaultConfig = {
  modes: {
    default: ['zoom-canvas', 'drag-canvas'],
  },
  fitView: true,
  animate: true,
  defaultNode: {
    type: 'flow-rect',
  },
  defaultEdge: {
    type: 'cubic-horizontal',
    style: {
      stroke: '#CED4D9',
    },
  },
  layout: {
    type: 'indented',
    direction: 'LR',
    dropCap: false,
    indent: 300,
    getHeight: () => {
      return 60;
    },
  },
};

export interface G6Config {
  width?: number,
  height?: number,
  /**
     * renderer canvas or svg
     */
  renderer?: string;
  fitView?: boolean;
  fitCenter?: boolean;
  layout?: LayoutConfig;
  /**
   * 图适应画布时，指定四周的留白。
   * 可以是一个值, 例如：fitViewPadding: 20
   * 也可以是一个数组，例如：fitViewPadding: [20, 40, 50,20]
   * 当指定一个值时，四边的边距都相等，当指定数组时，数组内数值依次对应 上，右，下，左四边的边距。
   */
  fitViewPadding?: Padding;
  /**
   * 各种元素是否在一个分组内，决定节点和边的层级问题，默认情况下所有的节点在一个分组中，所有的边在一个分组中，当这个参数为 false 时，节点和边的层级根据生成的顺序确定。
   * 默认值：true
   */
  groupByTypes?: boolean;
  directed?: boolean;
  groupStyle?: {
    style?: {
      [key: string]: ShapeStyle;
    };
  };
  /**
   * 当图中元素更新，或视口变换时，是否自动重绘。建议在批量操作节点时关闭，以提高性能，完成批量操作后再打开，参见后面的 setAutoPaint() 方法。
   * 默认值：true
   */
  autoPaint?: boolean;
  /**
   * 设置画布的模式。详情可见G6中的Mode文档。
   */
  modes?: Modes;
  /**
   * 默认状态下节点的配置，比如 type, size, color。会被写入的 data 覆盖。
   */
  defaultNode?: Partial<{
    type: string;
    size: number | number[];
    color: string;
  }> & ModelStyle;
  /**
   * 默认状态下边的配置，比如 type, size, color。会被写入的 data 覆盖。
   */
  defaultEdge?: Partial<{
    type: string;
    size: number | number[];
    color: string;
  }> & ModelStyle;
  /**
   * Combo 默认配置
   */
  defaultCombo?: Partial<{
    type: string;
    size: number | number[];
    color: string;
  }> & ModelStyle;
  nodeStateStyles?: StateStyles;
  edgeStateStyles?: StateStyles;
  comboStateStyles?: StateStyles;
  /**
   * 向 graph 注册插件。插件机制请见：plugin
   */
  plugins?: any[];
  /**
   * 是否启用全局动画。
   */
  animate?: boolean;
  /**
   * 动画配置项，仅在animate为true时有效。
   */
  animateCfg?: GraphAnimateConfig;
  /**
   * 最小缩放比例
   * 默认值 0.2
   */
  minZoom?: number;
  /**
   * 最大缩放比例
   * 默认值 10
   */
  maxZoom?: number;
  groupType?: string;
  /**
   * Edge 是否连接到节点中间
   */
  linkCenter?: boolean;
  /**
   * 是否启用 stack，即是否开启 redo & undo 功能
   */
  enabledStack?: boolean;
  /**
   * redo & undo 最大步数, 只有当 enabledStack 为 true 时才起作用
   */
  maxStep?: number;
  /**
   * 存储图上的 tooltip dom，方便销毁
   */
  tooltips?: [];

  memoData?: string | number | any[];
  data?: any;
  onlyChangeData?: boolean;

  onNodeClick?: (e: any, data: any) => void;
}

export interface Base extends Graph {
  __proto__?: any;
}

const spacing = 8;
export default function useInit<T extends Base, U extends G6Config>(ChartClass: any, config: U) {
  const chart = useRef<T>();

  const container = useRef<HTMLDivElement>(null);

  const processConfig = () => {
    const colors = {
      B: '#5B8FF9', // BLUE
      '9': '#FF0000', // RED
      '4': '#F5222D', // RED
      "1": '#FBAB18', // YELLOW
      '2': '#52C41A', // GREEN
      '5': '#52C41A', // GREEN
      '0': '#C1C1C1', // GRAY
    };

    const set: any = config.layout?.set;
    const rows: any = config.layout?.rows;
    // 自定义节点、边
    G6.registerNode(
      'flow-rect',
      {
        shapeType: 'flow-rect',
        draw(cfg: any, group: any) {
          const {
            collapsed,
          } = cfg;

          const rectConfig = {
            width: set.width + set.border * 2,
            height: set.height + set.border * 2,
            lineWidth: set.border,
            fontSize: 12,
            fill: '#fff',
            radius: 4,
            opacity: 1,
          };

          const nodeOrigin = {
            x: -rectConfig.width / 2,
            y: -rectConfig.height / 2,
          };

          const textConfig = {
            textAlign: 'left',
            textBaseline: 'middle',
            fill: '#ccc',
          };

          const rect = group.addShape('rect', {
            attrs: {
              x: nodeOrigin.x,
              y: nodeOrigin.y,
              stroke: set.borderColor,
              ...rectConfig,
              cursor: 'pointer',
            },
          });

          const rectBBox = rect.getBBox();
          let row = { height: 16 };

          for (let r = 0, rlen = rows.length; r < rlen; r++) {
            const row = rows[r];
            const columns = row.columns;
            for (let c = 0, clen = columns.length; c < clen; c++) {
              const column = columns[c];

              // const { backgroundColorCommand } = column;
              // if (backgroundColorCommand) {
              //   const fn = PageHelper.createPageScriptFunction(
              //     null,
              //     backgroundColorCommand,
              //     true,
              //   );
              //   // TODO: 修复因QueueCallback去掉异步所引起的Bug
              //   return row ? fn(EventListening.getHandlerArg(instance, { rowId, row })) : undefined;
              // }

            }
          }

          // row 1
          // 状态
          const statusText = group.addShape('text', {
            attrs: {
              ...textConfig,
              x: spacing + nodeOrigin.x + spacing,
              y: spacing + nodeOrigin.y + row.height / 2,
              text: cfg['statusText'],
              opacity: 0.85,
              fill: '#fff',
            },
            name: 'status-text',
          });
          const statusRect = group.addShape('rect', {
            attrs: {
              ...rectConfig,
              x: spacing + nodeOrigin.x,
              y: spacing + nodeOrigin.y,
              width: statusText.getBBox().width + spacing * 2,
              height: row.height - 1,
              opacity: 0.85,
              fill: colors[cfg['status']],
            },
            name: 'status-rect',
          });
          const statusText2 = group.addShape('text', {
            attrs: {
              ...textConfig,
              x: spacing + nodeOrigin.x + spacing,
              y: spacing + nodeOrigin.y + row.height / 2,
              text: cfg['statusText'],
              opacity: 0.85,
              fill: '#fff',
            },
            name: 'status-text2',
          });

          // 完成时间
          const finishText = group.addShape('text', {
            attrs: {
              ...textConfig,
              x: statusRect.getBBox().maxX + spacing,
              y: spacing + nodeOrigin.y + row.height / 2,
              text: cfg['finish'],
              opacity: 0.85,
            },
            name: 'finish-text',
          });

          // 数量
          const numberRect = group.addShape('rect', {
            attrs: {
              ...rectConfig,
              x: rectBBox.maxX - spacing - 16,
              y: spacing + nodeOrigin.y,
              width: 16,
              height: row.height - 1,
              opacity: 0.85,
              radius: 8,
              fill: '#5B8FF9',
            },
            name: 'number-rect',
          });
          const numberText = group.addShape('text', {
            attrs: {
              ...textConfig,
              x: rectBBox.maxX - spacing - 16 / 2,
              y: spacing + nodeOrigin.y + row.height / 2,
              textAlign: 'center',
              text: cfg['numberText'],
              opacity: 0.85,
              fill: '#fff',
            },
            name: 'number-text',
          });

          // row 2
          // 标题
          const titleText = group.addShape('text', {
            attrs: {
              ...textConfig,
              x: spacing + nodeOrigin.x,
              y: statusRect.getBBox().maxY + spacing * 2, // 8 + 16 + 8 + 8
              text: chnSubstr(cfg['name'], 26),
              fontWeight: 700,
              opacity: 0.85,
              fill: '#000',
            },
            name: 'title-text',
          });

          // row 3
          // 用户
          const userText = group.addShape('text', {
            attrs: {
              ...textConfig,
              x: spacing + nodeOrigin.x,
              y: titleText.getBBox().maxY + spacing * 2,
              text: cfg['userName'],
              opacity: 0.85,
            },
            name: 'user-text',
          });

          // 部门
          const deptText = group.addShape('text', {
            attrs: {
              ...textConfig,
              x: rectBBox.maxX - spacing,
              y: titleText.getBBox().maxY + spacing * 2,
              text: cfg['deptName'],
              textAlign: 'right',
              opacity: 0.85,
            },
            name: 'dept-text',
          });

          // row 4
          // 进度
          const percentRect = group.addShape('rect', {
            attrs: {
              x: nodeOrigin.x,
              y: rectBBox.maxY - 4,
              width: cfg['rate'] * rectBBox.width,
              height: 4,
              radius: cfg['rate'] < 1.0 ? [0, 0, 0, 4] : [0, 0, 4, 4],
              fill: colors[cfg['status']],
            },
            name: 'percent-rect',
          });

          // collapse rect
          if (cfg.children && cfg.children.length) {
            group.addShape('rect', {
              attrs: {
                x: rectConfig.width / 2 - 8,
                y: -8,
                width: 16,
                height: 16 - 1,
                stroke: 'rgba(0, 0, 0, 0.25)',
                cursor: 'pointer',
                fill: '#fff',
              },
              name: 'collapse-back',
              modelId: cfg.id,
            });

            // collpase text
            group.addShape('text', {
              attrs: {
                ...textConfig,
                x: rectConfig.width / 2,
                y: 0,
                text: collapsed ? '+' : '-',
                textAlign: 'center',
                fontSize: 16,
                cursor: 'pointer',
                fill: 'rgba(0, 0, 0, 0.25)',
              },
              name: 'collapse-text',
              modelId: cfg.id,
            });
          }

          (this as Partial<any>).drawLinkPoints(cfg, group);
          return rect;
        },
        update(cfg, item) {
          const group = item.getContainer();
          (this as Partial<any>).updateLinkPoints(cfg, group);
        },
        setState(name, value, item) {
          if (name === 'collapse') {
            const group = item?.getContainer();
            const collapseText = group?.find((e) => e.get('name') === 'collapse-text');
            if (collapseText) {
              if (!value) {
                collapseText.attr({
                  text: '-',
                });
              } else {
                collapseText.attr({
                  text: '+',
                });
              }
            }
          }
        },
        getAnchorPoints() {
          return [
            [0, 0.5],
            [1, 0.5],
          ];
        },
      },
      'rect',
    );
  };

  const processEvent = () => {
    const handleCollapse = (e) => {
      const target = e.target;
      const id = target.get('modelId');
      if (chart.current) {
        const item = chart.current.findById(id);
        const nodeModel = item.getModel();
        nodeModel.collapsed = !nodeModel.collapsed;
        chart.current.layout();
        chart.current.setItemState(item, 'collapse', !!nodeModel.collapsed);
      }
    };
    chart.current?.on('collapse-text:click', (e) => {
      handleCollapse(e);
    });
    chart.current?.on('collapse-back:click', (e) => {
      handleCollapse(e);
    });
    chart.current?.on('node:click', (e) => {
      console.info('node:click', e)
      config.onNodeClick?.call(chart.current, e.originalEvent, e.item?._cfg?.model);
    });
  }

  const formatData: any = (data: Array<any>, parentId: string = '0') => {
    return data.filter(d => d['parentId'] == parentId)
              .map(d => { 
                return {...d, children: formatData(data, d.id)};
               });
  }

  useEffect(() => {
    console.info(formatData(config?.data)[0])
    if (chart.current) {
      if (config.onlyChangeData) {
        chart.current.changeData(formatData(config?.data)[0]);
      } else {
        processConfig();
        for (let key in config) {
          chart.current.set(key, config[key]);
        }
        chart.current.render();
      }
    }
  }, [config?.data ? config.data : JSON.stringify(config)]);

  useEffect(() => {
    if (!container.current) {
      return () => null;
    }
    processConfig();
    const chartInstance: T = new (ChartClass as any)({
      container: container.current,
      ...defaultConfig,
      width: container.current.scrollWidth,
      height: container.current.scrollHeight || 500,
    });
    chart.current = chartInstance;
    processEvent();
    chartInstance.data(formatData(config?.data)[0]);
    chartInstance.render();
    return () => chartInstance.destroy();
  }, []);

  return {
    chart,
    container,
  };
}
