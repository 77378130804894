export default class FormItemControlExtendStore {
  constructor(private instance) {}

  handleUpdateValueBefore(e) {
    e.params.row = this.instance.parent.dataModel;
  }

  handleUpdateValueAfter(e) {
    const { actionSourceSign } = e.params;
    const { value, text } = e.newState.state;
    const textFieldName = e.newState.state['textFieldName'];
    let updateData = { [this.instance.store.state.field]: value };
    // _isObject 是针对区间控件做的多值处理
    if (value && typeof value === 'object' && value._isObject) {
      const { _isObject, ...val } = value;
      updateData = { ...updateData, ...val };
    }
    if (textFieldName && text) {
      updateData[textFieldName] = text;
    }
    if (actionSourceSign) {
      if (actionSourceSign == this.instance.store.state.children) {
        this.instance.parent.__updateValues(updateData, actionSourceSign);
      }
    }
    this.instance.parent.dataModel.update(updateData, this.instance.parent);
    if (this.instance.parent != actionSourceSign) this.instance.valid();
  }
}
