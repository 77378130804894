import NumberHelper from '../number-helper';
import ExpressionHelper, { BaseExpression } from '../expression-helper';

interface IDictionary<T = any> {
  [key: string]: T;
}

export class Formula {
  private static s_formulaRegular = new RegExp('(^|[^a-zA-Z0-9_]{1})data.([a-zA-Z0-9_]+)', 'g');

  private _partake: Array<string>;
  private _unround: boolean;
  private _precision: number;
  private _expression: BaseExpression;

  constructor(
    protected formula: string,
    precision: number = 2,
    unround: boolean = false,
    additionObject?: IDictionary,
  ) {
    if ('number' != typeof precision) {
      this._precision = 2;
    } else if (precision < 0) {
      this._precision = 0;
    } else {
      this._precision = precision;
    }

    this._unround = !!unround;

    Formula.s_formulaRegular.lastIndex = 0;
    let partake: Array<string> = [],
      matchItem = Formula.s_formulaRegular.exec(formula),
      itemName;

    while (matchItem) {
      if ((itemName = matchItem[2])) {
        if (partake.indexOf(itemName) < 0) {
          partake.push(itemName);
        }
      }
      matchItem = Formula.s_formulaRegular.exec(formula);
    }
    this._partake = partake;

    this._expression = ExpressionHelper.create(formula, 'data', additionObject);
  }

  /**
   * 用指定的数据进行公式计算
   * @param data 主要数据
   * @param addition 附加数据。当主要数据中不存在指定的数据时，从附加数据中获取
   * @returns 返回计算结果
   */
  calc(data: IDictionary, addition?: any, additionObject: IDictionary = {}): number {
    let result = this._expression.exec(Formula.s_createModel(data, addition), additionObject);
    if (result === Infinity) {
      return 0;
    }
    if ('number' === typeof result) {
      return this._unround
        ? NumberHelper.unround(result, this._precision)
        : NumberHelper.round(result, this._precision);
    } else {
      return result;
    }
  }

  /**
   * 获取依赖信息
   */
  getPartake(): string[] {
    return this._partake;
  }

  get precision(): number {
    return this._precision;
  }

  get unround(): boolean {
    return this._unround;
  }

  private static s_createModel(data: IDictionary, addition?: any) {
    let proxyHandler: ProxyHandler<IDictionary> = {
      get: function(target, key: string) {
        let value = target.data[key];
        if (value === undefined && target.addition) {
          value = target.addition[key];
        }
        value = NumberHelper.parse(value);
        return value;
      },
    };

    return new Proxy({ data, addition }, proxyHandler);
  }
}

export default class FormulaHelper {
  /**
   * 创建PC端的编译型公式计算
   * @param formula 要执行的公式
   * @param precision 要保留的小数位数
   * @param unround 是否不四舍五入
   *
   * 示例：
   *
   * var f = new Formulas.Formula('data.number * data.unitPrice', 1);
   *
   * console.log(f.calc({ number: 2, unitPrice: 2 }))  // 4.0
   */
  static create(
    formula: string,
    precision: number = 2,
    unround: boolean = false,
    additionObject?: IDictionary,
  ) {
    return new Formula(formula, precision, unround, additionObject);
  }
}
