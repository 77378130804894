import React from 'react';
import { Input } from 'antd';
import ControlBase from '../../base';
import { ITextAreaState } from '../typings';

import styles from './index.less';

class TextAreaControl extends ControlBase<ITextAreaState> {
  private handChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    this.instance.store.dispatch('updateValue', {
      value: event.currentTarget.value,
      text: event.currentTarget.value,
      actionSourceSign: this.instance.store,
    });

  private handleClearClick = () =>
    this.instance.store.dispatch('updateValue', {
      value: '',
      text: undefined,
    });

  private handleRightIconClick = () => {
    // TODO: 右icon单击事件
  };

  renderContent() {
    const { id } = this.props;
    if (this.state.readonly) return <div className={styles.readonly}>{this.state.text || ''}</div>;
    const { value, placeholder, minLength, maxLength, autoFocus } = this.state;
    return (
      <Input.TextArea
        id={id}
        value={value}
        maxLength={maxLength}
        minLength={minLength}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onChange={this.handChange}
      />
    );
  }
}

export default TextAreaControl;
