import Store from '@mjcloud/redux';
import PageModeHelper from '@mjcloud/page-mode-helper';
import SelectTreeReduceBase from '../common/select/reduceTree';
import { ISelectTreeState, ISelectTreeConfig } from './typings';

export class SelectTreeReduce extends SelectTreeReduceBase {
  initialState(store: Store<ISelectTreeState>, params) {
    const { initConfig, pageMode, iconFn } = params,
      { textFieldName, tokenSeparators, textTokenSeparator, vaueleTokenSeparator } = initConfig,
      { title, autoFocus, modifyMode, showIcon, iconControl, multiSelect } = initConfig,
      { isAutoSelection, alwaysLoad } = initConfig,
      { expandLevel, valueFieldName, displayMode, disabledMode, disabledControl } = initConfig,
      { value = store.state.value, placeholder = `请选择${title || ''}` } = initConfig;
    let state: ISelectTreeState = {
      title,
      value,
      iconFn,
      autoFocus,
      alwaysLoad,
      placeholder,
      iconControl,
      expandLevel,
      tabIndex: 0,
      textFieldName,
      rowIdCount: 1,
      dataSource: [],
      valueFieldName,
      isValid: false,
      disabledControl,
      originalData: [],
      selectedRows: [],
      config: initConfig,
      showIcon: !!showIcon,
      expandedKeys: [],
      // TODO: defaultOpen配置值待实现
      defaultOpen: false,
      originalDataSource: [],
      configIsFetching: false,
      multiSelect: !!multiSelect,
      isAutoSelection: !!isAutoSelection,
      tokenSeparators: tokenSeparators || [',', '、'],
      textTokenSeparator: textTokenSeparator || '、',
      vaueleTokenSeparator: vaueleTokenSeparator || ',',
      readonly: !PageModeHelper.modifyMode2boolean(pageMode, modifyMode),
      display: PageModeHelper.displayMode2boolean(pageMode, displayMode),
      disabled: PageModeHelper.disabledMode2boolean(pageMode, disabledMode),
    };
    state.tokenSeparators = state.tokenSeparators
      .concat([state.textTokenSeparator, state.vaueleTokenSeparator])
      .filter((tokenSeparator, index, self) => self.indexOf(tokenSeparator) === index);
    return state;
  }

  updateTreeExpandedKeys(store: Store<ISelectTreeState>, params) {
    const state: ISelectTreeState = { ...store.state, expandedKeys: params.expandedKeys };
    return state;
  }

  updateSearchValue(store: Store<ISelectTreeState>, params) {
    const state: ISelectTreeState = { ...store.state, searchValue: params.searchValue };
    return state;
  }
}

export default new SelectTreeReduce();
