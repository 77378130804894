import Page from '@mjcloud/page';
import Service from '@mjcloud/service';
import JsApiHelper from '@mjcloud/jsapi';
import globalData from '@mjcloud/global-data';
import PageHelper from '@mjcloud/page-helper';
import { ControllerBase, IControllerInitData, IRequest } from './base';
import { IDictionary, Omit, PageModeEnum, OpenModeEnum, XmlParam } from '@mjcloud/types';

/**
 * 打开窗口服务请求对象类型
 */
export interface IOpenWindowRequest extends IRequest {
  /**
   * 获取当前请求的控制器id
   */
  id: string;
  pageInfo: Omit<IOpenWindowInfo, 'params'>;
  params: IDictionary;
}

export interface IOpenWindowInfo {
  pageId: string;
  moduleId: string;
  appId: string;
  width: string | number;
  height: string | number;
  pageMode: PageModeEnum;
  title: string;
  openModal: OpenModeEnum;
  params: XmlParam[];
}

export interface OpenWindowControllerInitData extends IControllerInitData {
  pageInfo: IOpenWindowInfo;
}

export default class OpenWindowController extends ControllerBase<OpenWindowControllerInitData> {
  /**
   * 创建执行请求对象
   */
  public async createRequest(args: IDictionary): Promise<IOpenWindowRequest> {
    const { params, ...otherPageInfo } = this.initData.pageInfo;
    var request: IOpenWindowRequest = { id: this.id, params: {}, pageInfo: { ...otherPageInfo } };
    const values: IDictionary<string> = {};
    if (params) {
      params.forEach(item => {
        values[item.name] = item.value;
      });
    }
    request.params = await PageHelper.getDynamicParamValues(this.page, values, args);

    if (args._reviseId != null) {
      request.params._reviseId = args._reviseId;
    }
    // if (args._isWorkflow != null) {
    //   request.params._isWorkflow = String(args._isWorkflow);
    // }
    request.params = JSON.parse(JSON.stringify(request.params));

    return request;
  }

  /**
   * 执行控制器
   * @param request 执行时的请求对象
   */
  public async execute(request: IOpenWindowRequest): Promise<any> {
    const { moduleId, appId = globalData.appId } = request.pageInfo;
    request.pageInfo.appId = appId;
    request.pageInfo.moduleId = moduleId || this.page.address.moduleId;
    await Service.permissionInfo(request.pageInfo.appId);
    switch (request.pageInfo.openModal) {
      case OpenModeEnum.openWindow:
        return this.openWindow(request);
      case OpenModeEnum.tier:
      case OpenModeEnum.drawer:
        return this.tier(request);
      case OpenModeEnum.top:
        return this.top(request);
      case OpenModeEnum.parent:
        return this.parent(request);
      case OpenModeEnum.self:
      default:
        return this.self(request);
    }
  }

  private top(request: IOpenWindowRequest) {
    const { pageInfo, params } = request,
      { pageId, pageMode, moduleId, appId, title } = pageInfo;
    params.__iframePageKey = this.page.id;
    globalData.rbCore.top.refresh({ title, pageId, appId, moduleId, pageMode, params });
    return null;
  }

  private parent(request: IOpenWindowRequest) {
    const parent = this.page.parent.page instanceof Page ? this.page.parent.page : this.page;
    const { pageInfo, params } = request,
      { pageId, pageMode, moduleId, appId, title } = pageInfo;
    parent.refresh({ title, pageId, appId, moduleId, pageMode, params });
    return null;
  }

  private self(request: IOpenWindowRequest) {
    const { pageInfo, params } = request,
      { pageId, pageMode, moduleId, appId, title } = pageInfo;
    this.page.refresh({ title, pageId, appId, moduleId, pageMode, params });
    return null;
  }

  private tier(request: IOpenWindowRequest) {
    const { pageInfo, params } = request;
    return this.page.popupModal({ pageInfo, params: { ...params, isTier: 'true' } });
  }

  private openWindow(request: IOpenWindowRequest) {
    const { pageInfo, params } = request,
      { pageId, pageMode, moduleId, appId } = pageInfo,
      src = PageHelper.formatMemuPath({ pageId, moduleId, appId, pageMode, params });
    JsApiHelper.openLink(`${window.location.origin}#${src}`);
    return null;
  }
}
